import React, { useEffect, useRef, useState } from 'react';
import { FleetManagementRow } from '../../fleet-management-row';
import TCell from '../../../../frontend-common-libs/src/components/common/tables/TCell';
import Checkbox from '../../../../frontend-common-libs/src/common/checkbox';
import styles from './styles/instrument-control-row.module.scss';
import { InstrumentStatusEnum } from '../../../common/InstrumentFacade';
import InstrumentReservation from '../../InstrumentReservation';
import { ImmutableMap, InstrumentItem } from '../../../../frontend-common-libs/src/common/types';
import ReservationFacade from '../../ReservationFacade';

export type Props = {
  instrument: ImmutableMap<InstrumentItem>;
  reservation: InstrumentReservation;
  selectAllCheckbox: boolean;
  updateSelectedInstruments: (id: string, select: boolean) => void;
  numberOfSelectedInstruments: number;
};

export default function InstrumentControlRow(props: Readonly<Props>) {
  const loadingProtocol = 'loading ...';

  const {
    instrument,
    selectAllCheckbox,
    updateSelectedInstruments,
    reservation,
    numberOfSelectedInstruments
  } = props;

  const offlineTimeout = useRef<any>();

  const [isOffline, setIsOffline] = useState(false);
  const [checkbox, setCheckbox] = useState<boolean>(selectAllCheckbox);
  const initialRender = useRef(true);
  const [protocolNameText, setProtocolNameText] = useState<string>(loadingProtocol);

  const instrumentId = instrument.get('id');
  const { desiredJobId, reportedJobId } = reservation;

  const reservationFacade = new ReservationFacade({
    instrument,
    reservation,
    userId: reservation.userId,
    reservationShadowLoaded: true
  });

  const { instrumentFacade } = reservationFacade;

  const updateProtocolNameText = async () => {
    const updatedText = await reservationFacade.getProtocolNameText();
    setProtocolNameText(updatedText);
  };

  useEffect(() => {
    updateProtocolNameText();
  }, [desiredJobId, reportedJobId]);

  const {
    instrumentStatus,
    statusIcon,
    lidStatus,
    runCurrentStep,
    runTotalSteps,
    runCurrentCycle,
    runTotalCycles,
    runSampleTemp
  } = instrumentFacade;
  const statusText = instrumentStatus ?? 'No status';

  let stepColumnText = '-';
  let cycleColumnText = '-';
  let sampleTempTex = '-';

  if (
    instrumentStatus === InstrumentStatusEnum.Running ||
    instrumentStatus === InstrumentStatusEnum.InfiniteHold ||
    instrumentStatus === InstrumentStatusEnum.Paused
  ) {
    if (runCurrentStep && runTotalSteps) {
      stepColumnText = `${runCurrentStep} of ${runTotalSteps}`;
    }
    if (runCurrentCycle && runTotalCycles) {
      cycleColumnText = `${runCurrentCycle} of ${runTotalCycles}`;
    }
    if (runSampleTemp) {
      sampleTempTex = `${(runSampleTemp as number).toFixed(1)}º`;
    }
  }

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else {
      setCheckbox(selectAllCheckbox);
      updateSelectedInstruments(instrumentId, selectAllCheckbox);
    }
  }, [selectAllCheckbox]);

  useEffect(() => {
    const timeout = instrumentFacade.timeoutUntilExpiration();

    if (timeout > 0) {
      setIsOffline(false);
      offlineTimeout.current = setTimeout(() => {
        setIsOffline(true);
      }, timeout);
    }
    return () => {
      if (offlineTimeout.current) {
        setIsOffline(false);
        clearTimeout(offlineTimeout.current);
      }
    };
  }, [instrumentStatus, isOffline]);

  useEffect(() => {
    if (numberOfSelectedInstruments === 0 && checkbox) {
      setCheckbox(!checkbox);
    }
  }, [numberOfSelectedInstruments]);

  const handleSelectInstrument = (): void => {
    setCheckbox(!checkbox);
    updateSelectedInstruments(instrumentId, !checkbox);
  };

  const renderCheckboxCell = () => (
    <TCell>
      <Checkbox
        id={`checkbox_${instrumentId}`}
        name={`checkbox_${instrumentId}`}
        value={checkbox}
        checked={checkbox}
        onChange={handleSelectInstrument}
      />
    </TCell>
  );

  const renderAdditionalColumns = () => (
    <>
      <TCell className={`${styles.commonColumn} ${styles.cellText}`} title={statusText}>
        <img src={statusIcon ?? ''} alt={statusText} className={styles.statusImg} />
        {statusText}
      </TCell>
      <TCell className={`${styles.commonColumn} ${styles.cellText}`} title={protocolNameText}>
        {protocolNameText}
      </TCell>
      <TCell
        className={`${styles.commonColumn} ${styles.lidStatusColumn} ${styles.cellText}`}
        title={lidStatus}
      >
        {lidStatus}
      </TCell>
      <TCell className={`${styles.commonColumn} ${styles.cellText}`} title={cycleColumnText}>
        {cycleColumnText}
      </TCell>
      <TCell className={`${styles.commonColumn} ${styles.cellText}`} title={stepColumnText}>
        {stepColumnText}
      </TCell>
      <TCell className={`${styles.commonColumn} ${styles.cellText}`} title={sampleTempTex}>
        {sampleTempTex}
      </TCell>
    </>
  );

  return (
    <FleetManagementRow instrument={instrument} reservation={reservation}>
      {renderCheckboxCell()}
      {renderAdditionalColumns()}
    </FleetManagementRow>
  );
}
