import React, { ReactElement } from 'react';
import AriaAutoSuggestContainer from './AriaAutoSuggestContainer';
import SearchStringFormatter from './SearchStringFormatter';
import styles from '../styles/auto-suggest.module.scss';

export interface AutoSuggestContainerProps {
  searchStringFormatter: typeof SearchStringFormatter;
}

export default function AutoSuggestContainer({
  searchStringFormatter
}: AutoSuggestContainerProps): ReactElement<AutoSuggestContainerProps> {
  return (
    <>
      <div className={styles['auto-suggest-container']}>
        <AriaAutoSuggestContainer searchStringFormatter={searchStringFormatter} />
      </div>
    </>
  );
}
