import { DispatchType, LOGIN_TOKEN_LOADED, LOGIN_TOKEN_LOADING } from './action-types';
import { refreshOrgAccessToken } from '../api/org-api';
import { getLoginRefreshToken, getLoginUserToken } from '../selectors/selectors';
import { GetState } from '../../types';
import { CbWithToken, WithRefreshToken } from './WithRefreshToken';

export class WithRefreshLoginToken extends WithRefreshToken {
  constructor(protected dispatch: DispatchType, protected getState: GetState) {
    super(dispatch, getState);
  }

  getToken(): string {
    return getLoginUserToken(this.getState()) as string;
  }

  getRefreshToken(): string {
    return getLoginRefreshToken(this.getState()) as string;
  }

  dispatchTokenLoading() {
    this.dispatch({
      type: LOGIN_TOKEN_LOADING,
      payload: {}
    });
  }

  dispatchTokenLoaded(token: any) {
    this.dispatch({
      type: LOGIN_TOKEN_LOADED,
      payload: { loginInfo: token }
    });
  }

  // eslint-disable-next-line class-methods-use-this
  async refreshAccessToken(refreshToken: string, accessToken: string) {
    return refreshOrgAccessToken(refreshToken, accessToken);
  }
}

export async function withRefreshLoginToken(
  dispatch: DispatchType,
  getState: GetState,
  cb: CbWithToken
) {
  return new WithRefreshLoginToken(dispatch, getState).withRefreshToken(cb);
}
