import IOTTopic from './iot-topic';
import CredentialsRetryProvider from './credentials/credentials-retry-provider';
import { MessageCallback } from './types';
import IOTCredentialsProvider from './IOTCredentialsProvider';

export default class StartTopicAction {
  messageCallback: MessageCallback;

  reconnectCallback: (...args: Array<any>) => any;

  credentialsRetryProvider: CredentialsRetryProvider;

  constructor(
    messageCallback: MessageCallback,
    reconnectCallback: (...args: Array<any>) => any,
    iotCredentialsProvider: IOTCredentialsProvider
  ) {
    this.messageCallback = messageCallback;
    this.reconnectCallback = reconnectCallback;
    this.credentialsRetryProvider = new CredentialsRetryProvider(iotCredentialsProvider);
  }

  cancel() {
    this.credentialsRetryProvider.cancel();
  }

  async execute() {
    const credentials = await this.credentialsRetryProvider.resolve();
    const { topicCredentials } = credentials;
    const userTopic = new IOTTopic(topicCredentials, this.messageCallback, this.reconnectCallback);
    return { userTopic, credentials };
  }
}
