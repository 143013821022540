import React from 'react';
import RunReport from './run-report/RunReport';
import RunParser from './run-report/sections-parsing/RunParser';
import Loader from '../../../../frontend-common-libs/src/components/common/Loader';
import { LinkButton } from '../../../../frontend-common-libs/src/components/common/buttons';
import { exportPdfPages } from '../../../../frontend-common-libs/src/common/pdf/jsPdf';
import { setRubikFontWeight } from './run-report/common/styles';
import pdfIcon from '../../../../frontend-common-libs/src/img/pdf-icon.svg';
import '../styles/run-report-page.scss';
import {
  TrackedFilesEvents,
  trackEvent
} from '../../../../frontend-common-libs/src/user-analytics/trackedEvents';

type State = {
  generatingReport: boolean;
};

export type Props = {
  entityId: string;
  runParser: RunParser;
  fileName: string;
};

export default class RunReportPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      generatingReport: false
    };
  }

  exportRunPdf = async () => {
    setRubikFontWeight('bold'); // the report library don't work with font weight 500, so we set it to bold
    this.setState({ generatingReport: true });
    const { fileName } = this.props;
    await exportPdfPages(['#run-report'], `${fileName}.pdf`);
    setRubikFontWeight('500');
    this.setState({ generatingReport: false });
    trackEvent(TrackedFilesEvents.PtcExportRunReport, { fileName });
  };

  renderButtonPanel() {
    const { generatingReport } = this.state;
    return (
      <div className="buttons-panel">
        <LinkButton
          type="button"
          onClick={this.exportRunPdf}
          id="export-run-pdf-btn"
          isInProgress={generatingReport}
        >
          <div className="download-pdf-button">
            <img src={pdfIcon} alt="pdf" className="download-pdf-img" />
            <span className="download-pdf-txt">Download PDF</span>
          </div>
        </LinkButton>
      </div>
    );
  }

  // eslint-disable-next-line react/require-render-return
  render() {
    const { generatingReport } = this.state;
    const { runParser } = this.props;
    return (
      <div className="run-report-page">
        {this.renderButtonPanel()}
        <div className="run-report-content">
          {generatingReport && <Loader />}
          <RunReport runParser={runParser} />
        </div>
      </div>
    );
  }
}
