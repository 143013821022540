// @ts-nocheck
import { createStore, applyMiddleware, compose } from 'redux';
import { composeWithDevTools } from 'remote-redux-devtools';
import reduxThunk from 'redux-thunk';
import reducers from './reducers/index';

const middleware = [reduxThunk];
const composeEnhancers =
  process.env.NODE_ENV !== 'production'
    ? (!!window &&
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
          name: 'Nimbus'
        })) ||
      composeWithDevTools({
        name: 'Nimbus',
        hostname: 'localhost',
        port: 8000,
        realtime: true
      })
    : compose;
const enhancers = composeEnhancers(applyMiddleware(...middleware));

export default createStore(reducers, enhancers);
