import { List, Map } from 'immutable';
import { AnalysisModeEnum } from '../../../../../types';
import { intlCollator } from '../../../../../frontend-common-libs/src/utils/commonUtils';
import CycleRangeWell, { StartOrEnd } from './CycleRangeWell';
import CycleRangeMultiEdit from './CycleRangeMultiEdit';

export type CyclesRangeTableProps = {
  analysisMode: AnalysisModeEnum;
  computationsPerWell: Map<string, any>;
  plateWells: Map<string, any>;
  excludedWells: List<string>;
  perStepAnalysisSettingsForStepGroupMode: Map<string, any>;
  numberOfCycles: number | undefined;
};

export type EditedNumber = string | number | null | undefined;

export class CycleRangeTable {
  wells: CycleRangeWell[] = [];

  multiEdit: CycleRangeMultiEdit = new CycleRangeMultiEdit();

  private analysisMode!: AnalysisModeEnum;

  private selectedFluorOrTarget!: string;

  private computationsPerWell!: Map<string, any>;

  private plateWells!: Map<string, any>;

  private excludedWells!: List<string>;

  private perStepAnalysisSettingsForStepGroupMode!: Map<string, any>;

  private numberOfCycles!: number | undefined;

  private customStartEndEditChanges: Map<string, any>;

  constructor(props: CyclesRangeTableProps, selectedFluorOrTarget: string) {
    this.customStartEndEditChanges = Map<string, any>();
    this.updateProps(props, selectedFluorOrTarget);
  }

  updateProps(props: CyclesRangeTableProps, selectedFluorOrTarget: string) {
    const shouldRecreateWellRows = this.shouldRecreateWellRows(props, selectedFluorOrTarget);
    // reset all selections when changing target of fluor
    if (this.selectedFluorOrTarget !== selectedFluorOrTarget) this.multiEdit.reset();
    this.selectedFluorOrTarget = selectedFluorOrTarget;
    this.analysisMode = props.analysisMode;
    this.computationsPerWell = props.computationsPerWell;
    this.plateWells = props.plateWells;
    this.excludedWells = props.excludedWells;
    this.perStepAnalysisSettingsForStepGroupMode = props.perStepAnalysisSettingsForStepGroupMode;
    this.numberOfCycles = props.numberOfCycles;
    if (shouldRecreateWellRows) this.createWellRows();
  }

  shouldRecreateWellRows(props: CyclesRangeTableProps, selectedFluorOrTarget: string) {
    return (
      this.selectedFluorOrTarget !== selectedFluorOrTarget ||
      this.computationsPerWell !== props.computationsPerWell ||
      this.perStepAnalysisSettingsForStepGroupMode !== props.perStepAnalysisSettingsForStepGroupMode
    );
  }

  createWellRows = () => {
    let wells: CycleRangeWell[];
    if (this.analysisMode === AnalysisModeEnum.fluor) {
      wells = this.getWellsOfFluor();
    } else {
      wells = this.getWellsOfTarget();
    }
    wells = wells.sort((val1: any, val2: any) => intlCollator.compare(val1.wellId, val2.wellId));
    this.wells = wells;
  };

  getWellsOfFluor(): CycleRangeWell[] {
    const groupComp = this.computationsPerWell.get(this.selectedFluorOrTarget);
    if (groupComp == null) return [];
    return groupComp
      .entrySeq()
      .map((entry: [string, Map<string, any>]) => {
        const wellEntry = new CycleRangeWell(
          entry[0],
          // @ts-ignore
          entry[1].getIn(['baseline', 'start']),
          entry[1].getIn(['baseline', 'end'])
        );
        this.setCustomRangeValues(wellEntry);
        return wellEntry;
      })
      .toArray();
  }

  getWellsOfTarget(): CycleRangeWell[] {
    const wells: any[] = [];
    this.plateWells.forEach((well: Map<string, any>, wellName: string) => {
      if (!this.excludedWells.includes(wellName))
        well.get('fluors').forEach((fluor: Map<string, any>) => {
          const fluorName = fluor.get('name');
          const targetName = fluor.get('target') || fluorName; // if no target use the fluor as the target name
          if (targetName === this.selectedFluorOrTarget) {
            const wellComp = this.computationsPerWell.getIn([fluorName, wellName, 'baseline']);
            const wellEntry = new CycleRangeWell(
              wellName,
              // @ts-ignore
              wellComp.getIn(['start']),
              // @ts-ignore
              wellComp.getIn(['end'])
            );
            this.setCustomRangeValues(wellEntry);
            wells.push(wellEntry);
          }
        });
    });
    return wells;
  }

  setCustomRangeValues(well: CycleRangeWell) {
    // eslint-disable-next-line no-param-reassign
    well.customStart = this.getCustomCycleRangePerWell(well.wellId, StartOrEnd.start);
    // eslint-disable-next-line no-param-reassign
    well.customEnd = this.getCustomCycleRangePerWell(well.wellId, StartOrEnd.end);
    // eslint-disable-next-line no-param-reassign
    well.isSelected = this.multiEdit.isWellSelected(well.wellId);
  }

  getWellPathForCyclesPerWell(wellId: string) {
    return [this.selectedFluorOrTarget, 'cyclesPerWell', wellId];
  }

  getStoredCustomCyclePerWell(wellId: string, startOrEnd: StartOrEnd) {
    const wellPath = this.getWellPathForCyclesPerWell(wellId);
    const customStoredPerWell = this.perStepAnalysisSettingsForStepGroupMode.getIn(wellPath);
    if (customStoredPerWell != null) {
      // @ts-ignore
      return customStoredPerWell.get(startOrEnd);
    }
    return undefined;
  }

  getEditedCustomCyclePerWell(wellId: string, startOrEnd: StartOrEnd) {
    const wellPath = this.getWellPathForCyclesPerWell(wellId);
    const customEditedPerWell = this.customStartEndEditChanges.getIn(wellPath);
    if (customEditedPerWell != null) {
      // @ts-ignore
      return customEditedPerWell.get(startOrEnd);
    }
    return undefined;
  }

  getCustomCycleRangePerWell(wellId: string, startOrEnd: StartOrEnd) {
    let customStartOrEnd = this.getEditedCustomCyclePerWell(wellId, startOrEnd);
    if (customStartOrEnd === undefined)
      customStartOrEnd = this.getStoredCustomCyclePerWell(wellId, startOrEnd);
    return customStartOrEnd;
  }

  hasCustomCyclePerWellChanged(
    wellId: string,
    startOrEnd: StartOrEnd,
    editValue: EditedNumber
  ): boolean {
    const storedCustomValue = this.getStoredCustomCyclePerWell(wellId, startOrEnd);
    let changed = true;
    if (storedCustomValue == null && editValue == null) changed = false;
    if (storedCustomValue === editValue) changed = false;
    return changed;
  }

  updateCustomCycleRangeEdits = (
    wellId: string,
    startOrEnd: StartOrEnd,
    value: EditedNumber,
    isSubmit: boolean // if false editing value before submit
  ) => {
    let validValue: EditedNumber = value;
    if (isSubmit) {
      validValue = this.fixSubmittedCycleRange(wellId, startOrEnd, validValue);
      this.fixDeletedCycleRangeForOtherValue(wellId, startOrEnd, validValue);
    } else {
      validValue = this.fixEditedCycleRange(validValue);
    }
    this.updateCustomCycleRangeValue(wellId, startOrEnd, validValue);
  };

  fixDeletedCycleRangeForOtherValue = (
    wellId: string,
    startOrEnd: StartOrEnd,
    value: EditedNumber
  ) => {
    if (value != null) return; // value is not deleted, no need to fix other value
    const otherStartOrEnd = startOrEnd === StartOrEnd.start ? StartOrEnd.end : StartOrEnd.start;
    const well = this.findWell(wellId);
    if (well == null) return;
    const otherValue = otherStartOrEnd === StartOrEnd.start ? well.customStart : well.customEnd;
    if (otherValue == null) return;
    well.setCustomStartOrEnd(startOrEnd, value);
    const newValidValue = this.fixSubmittedCycleRange(wellId, otherStartOrEnd, otherValue);
    if (newValidValue !== otherValue)
      this.updateCustomCycleRangeValue(wellId, otherStartOrEnd, newValidValue);
  };

  fixSubmittedCycleRange = (wellId: string, startOrEnd: StartOrEnd, value: EditedNumber) => {
    let validValue: EditedNumber = this.getValidCycleRange(value);
    const well = this.findWell(wellId);
    if (well != null) {
      validValue =
        startOrEnd === StartOrEnd.start
          ? this.getValidStartForWell(well, validValue)
          : this.getValidEndForWell(well, validValue);
    }
    return validValue;
  };

  fixEditedCycleRange = (value: EditedNumber) => {
    let validValue: string | number | null | undefined = this.convertCycleRangeValueToInt(value);
    validValue = validValue != null ? validValue.toString() : null;
    return validValue;
  };

  updateCustomCycleRangeValue = (wellId: string, startOrEnd: StartOrEnd, value: EditedNumber) => {
    const valueChanged = this.hasCustomCyclePerWellChanged(wellId, startOrEnd, value);
    let newCustomEditChanges;
    const keyPath = [this.selectedFluorOrTarget, 'cyclesPerWell', wellId, startOrEnd];
    // check if value has changed, if not remove it from the changes
    if (valueChanged) newCustomEditChanges = this.customStartEndEditChanges.setIn(keyPath, value);
    else newCustomEditChanges = this.customStartEndEditChanges.deleteIn(keyPath);
    const well = this.findWell(wellId);
    if (well != null) well.setCustomStartOrEnd(startOrEnd, value as number | null);
    this.customStartEndEditChanges = newCustomEditChanges;
  };

  findWell = (wellId: string): CycleRangeWell | undefined => {
    return this.wells.find(x => x.wellId === wellId);
  };

  getValidEndForWell = (well: CycleRangeWell, value?: EditedNumber) => {
    let validValue = value;
    const minEnd = well.getCustomEndMinValue();
    if (minEnd != null && validValue != null && validValue < minEnd) validValue = minEnd;
    return validValue;
  };

  getValidStartForWell = (well: CycleRangeWell, value?: EditedNumber) => {
    let validValue = value;
    const maxStart = well.getCustomStartMaxValue();
    if (maxStart != null && validValue != null && validValue > maxStart) validValue = maxStart;
    return validValue;
  };

  convertCycleRangeValueToInt = (value: EditedNumber): number | null => {
    // @ts-ignore
    let validValue: number | null = value;
    if (typeof value === 'string') {
      let trimmed = value;
      const maxChars = 6;
      if (trimmed.length > maxChars) trimmed = trimmed.substring(0, maxChars);
      validValue = Number.parseInt(trimmed, 10);
    }
    if (Number.isNaN(validValue)) return null;
    return validValue;
  };

  getValidCycleRange = (value?: EditedNumber) => {
    let validValue = value != null && value !== '' ? value : null;
    if (validValue != null) {
      if (typeof value === 'string') validValue = Number.parseInt(value, 10);
      if (validValue <= 0) validValue = 1;
      if (this.numberOfCycles && validValue > this.numberOfCycles) validValue = this.numberOfCycles;
    }
    return validValue;
  };

  hasCustomCycleRangeChanges = (): boolean => {
    if (this.customStartEndEditChanges.size <= 0) return false;
    let hasChanges = false;
    this.customStartEndEditChanges.forEach((fluorOrTargetSettings: Map<string, any>) => {
      const cyclesPerWell = fluorOrTargetSettings.get('cyclesPerWell');
      if (cyclesPerWell != null) {
        cyclesPerWell.forEach((wellSettings: Map<string, any>) => {
          if (wellSettings.size > 0) hasChanges = true;
        });
      }
    });
    return hasChanges;
  };

  resetCustomCycleRangeChanges = () => {
    this.customStartEndEditChanges = Map<string, any>();
  };

  getCustomCycleRangeEditChanges = () => this.customStartEndEditChanges;

  selectWell = (checked: boolean, wellId: string) => {
    const well = this.findWell(wellId);
    if (well != null) well.isSelected = checked;
    this.multiEdit.selectWell(checked, wellId);
  };

  selectAllWells = (value: boolean) => {
    const allWells = this.wells.map(well => well.wellId);
    this.wells.forEach(well => {
      // eslint-disable-next-line no-param-reassign
      well.isSelected = value;
    });
    this.multiEdit.selectAll(value, allWells);
  };

  resetSelectedWells = () => {
    this.multiEdit.selectedWells.forEach(wellId => {
      this.updateCustomCycleRangeEdits(wellId, StartOrEnd.start, null, true);
      this.updateCustomCycleRangeEdits(wellId, StartOrEnd.end, null, true);
    });
  };

  updateSelectedCycleRangeEdits = (
    startOrEnd: StartOrEnd,
    value: EditedNumber,
    isSubmit: boolean // if false editing value before submit
  ) => {
    let validValue: EditedNumber = value;
    if (isSubmit) validValue = this.fixSubmittedAllCycleRange(startOrEnd, validValue);
    else validValue = this.fixEditedCycleRange(validValue);
    if (validValue == null) validValue = '';
    if (startOrEnd === StartOrEnd.start) this.multiEdit.allStart = validValue;
    if (startOrEnd === StartOrEnd.end) this.multiEdit.allEnd = validValue;
  };

  fixSubmittedAllCycleRange = (startOrEnd: StartOrEnd, value: EditedNumber) => {
    let validValue: EditedNumber = this.getValidCycleRange(value);
    if (startOrEnd === StartOrEnd.start) {
      let maxStart;
      if (this.numberOfCycles != null) maxStart = this.numberOfCycles - 1;
      if (typeof this.multiEdit.allEnd === 'number') maxStart = this.multiEdit.allEnd - 1;
      if (maxStart != null && validValue != null && validValue >= maxStart) validValue = maxStart;
    } else {
      let minEnd = 2;
      if (typeof this.multiEdit.allStart === 'number') minEnd = this.multiEdit.allStart + 1;
      if (minEnd != null && validValue != null && validValue <= minEnd) validValue = minEnd;
    }
    return validValue;
  };

  setSelectedWells = () => {
    this.multiEdit.selectedWells.forEach(wellId => {
      const { allStart, allEnd } = this.multiEdit;
      const well = this.findWell(wellId);
      if (well != null) {
        if (typeof allStart === 'number') well.customStart = allStart;
        if (typeof allEnd === 'number') well.customEnd = allEnd;
      }
      if (typeof allStart === 'number') {
        this.updateCustomCycleRangeEdits(wellId, StartOrEnd.start, allStart, true);
      }
      if (typeof allEnd === 'number') {
        this.updateCustomCycleRangeEdits(wellId, StartOrEnd.end, allEnd, true);
      }
    });
  };
}
