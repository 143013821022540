import React, { PureComponent, ComponentType, ReactNode } from 'react';
import getOriginalReferrer from '../user-analytics/referrer';
import { getRoute, getRouteName, RoutePath } from '../core/routes';
import { SystemEventBroker } from '../frontend-common-libs/src/system-event';
import PageViewedSystemEvent, { pageViewedSystemEventName } from './PageViewedSystemEvent';

type Props = {
  location: {
    [key: string]: any;
  };
  children: ReactNode;
};

export class PageViewSystemEventPublisher extends PureComponent<Props> {
  fromPath: string | undefined;

  private getFromPath(): RoutePath {
    return (this.isFromReferrer() ? getOriginalReferrer() : this.fromPath) as RoutePath;
  }

  private setLastPageLoaded(path: RoutePath): void {
    this.fromPath = path;
  }

  private isFromReferrer(): boolean {
    // determine if this is the original page view linked from the referrer
    return this.fromPath === undefined && getOriginalReferrer() !== undefined;
  }

  private generateSystemEvent(
    fromPath: RoutePath | null | undefined,
    toPath: RoutePath
  ): PageViewedSystemEvent {
    const toRouteName = getRouteName(toPath);
    let systemEvent: PageViewedSystemEvent = {
      name: pageViewedSystemEventName,
      toPath,
      toRouteName,
      toRoute: getRoute(toRouteName)
    };
    if (fromPath) {
      if (this.isFromReferrer()) {
        // if the fromPath is the referrer - typically from an external site -
        // only include fromPath value since fromRoute and fromRouteName are not applicable
        // ie: link in an ad or email campaign
        systemEvent = { ...systemEvent, fromPath };
      } else {
        // typical scenario where page view events are triggered by user navigating within the app
        const fromRouteName = getRouteName(fromPath);
        systemEvent = {
          ...systemEvent,
          fromPath,
          fromRouteName,
          fromRoute: getRoute(fromRouteName)
        };
      }
    }
    // ELSE... if there is no fromPath then params don't need to be changed
    // ie: link from bookmark, manual update url, etc

    return systemEvent;
  }

  private publishSystemEvent() {
    const {
      location: { pathname: toPath }
    } = this.props;
    const fromPath = this.getFromPath();
    const systemEvent = this.generateSystemEvent(fromPath, toPath);

    SystemEventBroker.getInstance().notify(systemEvent);

    this.setLastPageLoaded(toPath);
  }

  private pathChanged() {
    const {
      location: { pathname: toPath }
    } = this.props;
    const fromPath = this.getFromPath();
    return !fromPath || fromPath.toLowerCase() !== toPath.toLowerCase();
  }

  render() {
    const { children } = this.props;
    if (this.pathChanged()) {
      this.publishSystemEvent();
    }
    return children;
  }
}

const withPageViewSystemEventPublisher = (WrappedComponent: ComponentType<any>) =>
  function (props: any) {
    const { location } = props;

    return (
      <PageViewSystemEventPublisher location={location}>
        <WrappedComponent {...props} />
      </PageViewSystemEventPublisher>
    );
  };

export default withPageViewSystemEventPublisher;
