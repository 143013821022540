import React, { PureComponent } from 'react';
import { LinkButton } from '../../../frontend-common-libs/src/components/common/buttons';
import { Step } from '../LinkInstrument';
import * as commonUtils from '../../../frontend-common-libs/src/utils/commonUtils';
import { GATEWAY } from '../../../frontend-common-libs/src/config/config';
import PrimaryButton from '../../../frontend-common-libs/src/common/buttons';
import styles from './styles/link-instrument-confirmation.module.scss';
import DeviceLinkingTrackingEvent, {
  DeviceLinkingEvent
} from '../../user-analytics/DeviceLinkingTrackingEvent';

export type Props = {
  userCode: string;
  instrumentName: string;
  serialNumber: string;
  instrumentId: string;
  deviceType: string;
  handleStepChange: (step: Step) => void;
  clearDeviceFlowDetails: () => void;
};

export default class LinkInstrumentConfirmation extends PureComponent<Props> {
  handleCancel = () => {
    const { clearDeviceFlowDetails, handleStepChange } = this.props;
    clearDeviceFlowDetails();
    handleStepChange(Step.FORM);
  };

  setUserCodeCookie = () => {
    const { userCode } = this.props;
    commonUtils.setCookie('usercode', userCode, 60, '/link');
  };

  setInstrumentNameCookie = () => {
    const { instrumentName } = this.props;
    commonUtils.setCookie('instrumentName', instrumentName, 60, '/link');
  };

  handleConfirm = () => {
    this.setUserCodeCookie();
    this.setInstrumentNameCookie();
    this.trackConfirmDetails();
    const url = GATEWAY.COGNITO_HOSTED_UI_PATHS.INSTRUMENT_LOGIN;
    window.location.assign(url);
  };

  trackConfirmDetails = () => {
    const { instrumentId, deviceType } = this.props;
    DeviceLinkingTrackingEvent.track(DeviceLinkingEvent.CONFIRM_DETAILS, instrumentId, deviceType);
  };

  render() {
    const { userCode, instrumentName, serialNumber } = this.props;
    return (
      <div className={styles.container}>
        <div className={`${styles.headerContainer} headerContainer`}>
          <p>Confirm the following information:</p>
        </div>
        <div className={styles.confirmationInfoWrapper}>
          <div className={styles.infoWrapper}>
            <div className={styles.instrumentLabel}>Code:</div>
            <div
              className={`${styles.instrumentAttribute} instrumentAttribute`}
              id="link-instrument-code-label"
            >
              {userCode}
            </div>
          </div>
          <div className={styles.infoWrapper}>
            <div className={styles.instrumentLabel}>Instrument Name:</div>
            <div
              className={`${styles.instrumentAttribute} instrumentAttribute`}
              id="link-instrument-name-label"
            >
              {instrumentName}
            </div>
          </div>
          <div className={styles.infoWrapper}>
            <div className={styles.instrumentLabel}>Serial Number:</div>
            <div
              className={`${styles.instrumentAttribute} instrumentAttribute`}
              id="link-instrument-serial-number-label"
            >
              {serialNumber}
            </div>
          </div>
          <div className={styles.buttonsWrapper}>
            <PrimaryButton
              id="link-instrument-confirm-btn"
              type="button"
              className={styles.confirmButton}
              onClick={this.handleConfirm}
            >
              Confirm &amp; Link
            </PrimaryButton>
            <LinkButton
              id="link-instrument-cancel-btn"
              type="button"
              className={styles.cancelButton}
              onClick={this.handleCancel}
            >
              Cancel
            </LinkButton>
          </div>
        </div>
      </div>
    );
  }
}
