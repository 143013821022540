import { jsPDF as JsPDF } from 'jspdf';
import { rubikFont400, rubikFont500 } from './fonts';

export function addFonts(pdf: JsPDF) {
  pdf.addFileToVFS('Rubik-400.ttf', rubikFont400);
  pdf.addFont('Rubik-400.ttf', 'Rubik', 'normal');

  pdf.addFileToVFS('Rubik-500.ttf', rubikFont500);
  pdf.addFont('Rubik-500.ttf', 'Rubik', 'bold');
}

export async function exportPdfPages(selectors: string[], fileName: string) {
  const firstPAge = (await document.querySelector(selectors[0])) as HTMLElement;
  const pdf = new JsPDF('portrait', 'pt', [firstPAge.offsetWidth, firstPAge.offsetHeight]);
  addFonts(pdf);

  for (let i = selectors.length - 1; i >= 0; i -= 1) {
    const selector = selectors[i];
    // eslint-disable-next-line no-await-in-loop
    const htmlElement = (await document.querySelector(selector)) as HTMLElement;
    if (htmlElement != null) {
      // eslint-disable-next-line no-await-in-loop
      await pdf.html(htmlElement);
      if (i !== 0) pdf.insertPage(1);
    }
  }

  // Delete the extra pages
  for (let i = selectors.length + 1; i < pdf.internal.pages.length; i += 1) pdf.deletePage(i);

  pdf.save(fileName);
}
