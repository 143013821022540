import { OrderedMap, fromJS, Set } from 'immutable';
import { Map } from 'immutable';
import {
  FILEUPLOAD_STARTED,
  FILEUPLOAD_INCOMPATIBLE,
  FILEUPLOAD_FAILED,
  FILEUPLOAD_CLEAR,
  FILEUPLOAD_CANCELLED,
  FILEUPLOAD_PROCESSING,
  FILEUPLOAD_UNSUPPORTED,
  FILEUPLOAD_SIZE_EXCEEDED,
  FILEUPLOAD_PROCESSING_COMPLETE,
  EXPORT_FILE_STARTED,
  EXPORT_FILE_COMPLETE,
  EXPORT_FILE_FAILED,
  FILEUPLOAD_NETWORK_ERROR,
  FileUploadPayload
} from '../frontend-common-libs/src/file-operations/file_operation_types';
import {
  FILE_OPERATION_COMPLETE,
  FILE_OPERATION_FAILED,
  UPLOAD_CANCELLED,
  FILE_OPERATION_PROCESSING,
  UPLOAD_UNSUPPORTED,
  UPLOAD_COMPLETE_UNRECOGNIZED,
  UPLOAD_SIZE_EXCEEDED,
  FileOperationType,
  FILE_NETWORK_OPERATION_FAILED
} from '../frontend-common-libs/src/common/userfiles_common';
import { UNAUTH_USER } from '../auth/actions/auth_types';
import { jsArrayToOrderedMap } from '../frontend-common-libs/src/utils/immutableUtils';

import { ApiAction } from '../types';
import { USERFILES_PROCESSING_COMPLETE } from '../file-management/actions/action-types';
// @ts-ignore
const INITIAL_STATE: Map<string, any> = fromJS({
  uploading: Set(),
  processing: Set(),
  failed: Set(),
  handling: OrderedMap({})
});

const onFileUploadStarted = (state: Map<string, any>, action: any) => {
  const newUploads = jsArrayToOrderedMap(action.payload.uploads, 'id');
  const newState = {
    uploading: state.get('uploading').union(newUploads.keys()),
    handling: newUploads.merge(state.get('handling'))
  };
  return state.merge(newState);
};

const isHandling = (state: Map<string, any>, id: string) => state.get('handling').has(id);
const isHandlingUploading = (state: Map<string, any>, id: string) =>
  state.get('handling').has(id) && state.get('uploading').has(id);

const onFileOperationSuccess = (state: Map<string, any>, action: any) => {
  if (action.payload.id) {
    const { id } = action.payload;
    if (isHandling(state, id)) {
      // @ts-ignore
      const newFile = state.getIn(['handling', id]).set('status', FILE_OPERATION_COMPLETE);
      const newState = {
        processing: state.get('processing').delete(id),
        failed: state.get('failed').delete(id),
        handling: state.get('handling').set(id, newFile)
      };
      return state.merge(newState);
    }
  }
  return state;
};

// File upload completed before processing
const onUploadSuccessUnrecognizedFile = (state: Map<string, any>, action: any) => {
  if (action.payload.id) {
    const { id } = action.payload;
    if (isHandling(state, id)) {
      // @ts-ignore
      const newFile = state.getIn(['handling', id]).set('status', UPLOAD_COMPLETE_UNRECOGNIZED);
      const newState = {
        uploading: state.get('uploading').delete(id),
        processing: state.get('processing').delete(id),
        failed: state.get('failed').add(id),
        handling: state.get('handling').set(id, newFile)
      };
      return state.merge(newState);
    }
  }
  return state;
};

const onFileOperationFailed = (state: Map<string, any>, action: any) => {
  if (action.payload.id) {
    const { id, errorMessage } = action.payload;
    if (isHandling(state, id)) {
      // @ts-ignore
      let newFile = state.getIn(['handling', id]).set('status', FILE_OPERATION_FAILED);
      if (errorMessage != null) newFile = newFile.set('errorMessage', errorMessage);
      const newState = {
        uploading: state.get('uploading').delete(id),
        processing: state.get('processing').delete(id),
        failed: state.get('failed').add(id),
        handling: state.get('handling').set(id, newFile)
      };
      return state.merge(newState);
    }
  }

  return state;
};

const onFileNetworkOperationFailed = (state: Map<string, any>, action: any) => {
  if (action.payload.id) {
    const { id } = action.payload;
    if (isHandling(state, id)) {
      // @ts-ignore
      const newFile = state.getIn(['handling', id]).set('status', FILE_NETWORK_OPERATION_FAILED);
      const newState = {
        uploading: state.get('uploading').delete(id),
        processing: state.get('processing').delete(id),
        failed: state.get('failed').add(id),
        handling: state.get('handling').set(id, newFile)
      };
      return state.merge(newState);
    }
  }

  return state;
};

const onFileUploadCancelled = (
  state: Map<string, any>,
  { payload: { ids } }: FileUploadPayload
) => {
  let newState = state;
  ids.forEach(id => {
    if (isHandlingUploading(newState, id)) {
      newState = newState
        .set('uploading', newState.get('uploading').delete(id))
        .setIn(['handling', id, 'status'], UPLOAD_CANCELLED);
    }
  });
  return newState;
};

const onFileUploadUnsupported = (state: Map<string, any>, { payload }: FileUploadPayload) => {
  let newState = state;
  const { ids, errorMessage } = payload;
  ids.forEach(id => {
    if (isHandlingUploading(newState, id)) {
      newState = newState
        .set('uploading', newState.get('uploading').delete(id))
        .set('processing', newState.get('processing').delete(id))
        .set('failed', newState.get('failed').add(id))
        .setIn(['handling', id, 'status'], UPLOAD_UNSUPPORTED);
      if (errorMessage != null)
        newState = newState.setIn(['handling', id, 'errorMessage'], errorMessage);
    }
  });
  return newState;
};

const onFileUploadSizeExceeded = (state: Map<string, any>, { payload }: FileUploadPayload) => {
  let newState = state;
  const { ids, errorMessage } = payload;
  ids.forEach(id => {
    if (isHandlingUploading(newState, id)) {
      newState = newState
        .set('uploading', newState.get('uploading').delete(id))
        .set('processing', newState.get('processing').delete(id))
        .set('failed', newState.get('failed').add(id))
        .setIn(['handling', id, 'status'], UPLOAD_SIZE_EXCEEDED);
      if (errorMessage != null)
        newState = newState.setIn(['handling', id, 'errorMessage'], errorMessage);
    }
  });
  return newState;
};

const onFileUploadProcessing = (state: Map<string, any>, action: any) => {
  const { id } = action.payload;
  if (isHandling(state, id)) {
    return state
      .set('uploading', state.get('uploading').delete(id))
      .set('processing', state.get('processing').add(id))
      .setIn(['handling', id, 'status'], FILE_OPERATION_PROCESSING);
  }
  return state;
};

const onExportFileStarted = (state: Map<string, any>, action: any) => {
  const { id, fileName } = action.payload;
  const file = new File([''], fileName);
  const newExportObj = {
    id,
    file,
    status: FILE_OPERATION_PROCESSING,
    fileOperationType: FileOperationType.exportPcrd
  };
  let newExport = OrderedMap({});
  newExport = newExport.set(id, fromJS(newExportObj));

  const newState = {
    processing: state.get('processing').add(id),
    handling: newExport.merge(state.get('handling'))
  };
  return state.merge(newState);
};

const resetState = () => INITIAL_STATE;

const actionMap = {
  [FILEUPLOAD_STARTED]: onFileUploadStarted, // File upload initiated
  [FILEUPLOAD_INCOMPATIBLE]: onUploadSuccessUnrecognizedFile, // File upload completed but the file type is unrecognized
  [FILEUPLOAD_NETWORK_ERROR]: onFileNetworkOperationFailed, // File upload failed due to network error
  [FILEUPLOAD_FAILED]: onFileOperationFailed, // File upload failed
  [FILEUPLOAD_CANCELLED]: onFileUploadCancelled, // File upload cancelled
  [FILEUPLOAD_PROCESSING]: onFileUploadProcessing, // File upload processing
  [FILEUPLOAD_CLEAR]: resetState, // clear File uploads
  [FILEUPLOAD_UNSUPPORTED]: onFileUploadUnsupported, // unsupported file type
  [FILEUPLOAD_SIZE_EXCEEDED]: onFileUploadSizeExceeded, // unsupported file size
  [USERFILES_PROCESSING_COMPLETE]: onFileOperationSuccess, // File upload completed and the file type is recognized
  [FILEUPLOAD_PROCESSING_COMPLETE]: onFileOperationSuccess, // File upload completed
  [UNAUTH_USER]: resetState, // User logged out
  [EXPORT_FILE_STARTED]: onExportFileStarted, // export file initiated
  [EXPORT_FILE_COMPLETE]: onFileOperationSuccess, // export file completed successfully
  [EXPORT_FILE_FAILED]: onFileOperationFailed // export file failed
};

export default function fileOperationReducer(
  state: Map<string, any> = INITIAL_STATE,
  action: ApiAction<any, any> | undefined = undefined
): Map<string, any> {
  if (action) {
    return actionMap[action.type] ? actionMap[action.type](state, action) : state;
  }
  return state;
}
