import { Map } from 'immutable';
import GeneratePcrd from './generate_pcrd';
import GeneratePcrdRequestNotFoundError from './GeneratePcrdRequestNotFoundError';

export default class GeneratePcrdRepository {
  private generating: Map<string, GeneratePcrd>;

  constructor() {
    // @ts-ignore
    this.generating = Map();
  }

  public add(generatePcrd: GeneratePcrd, requestId: string): void {
    this.generating = this.generating.set(requestId, generatePcrd);
  }

  public delete(requestId: string): void {
    this.generating = this.generating.delete(requestId);
  }

  public get(requestId: string): GeneratePcrd {
    const request = this.generating.get(requestId);
    if (request) {
      return request;
    }
    throw new GeneratePcrdRequestNotFoundError(
      `Could not find GeneratePcrd Task with request id: ${requestId} `
    );
  }
}
