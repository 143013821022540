import { ReactElement } from 'react';
import { HelpLinkProps, HelpLinkRenderProps } from '@biorad-lsg-tsc/instrument-family-plugin';
import HelpPageViewedTrackingEvent from '../../frontend-common-libs/src/user-analytics/HelpPageViewedTrackingEvent';
import HelpCenter from './HelpCenter';
import CommonMenuItemLink from '../../frontend-common-libs/src/components/common-help-link/CommonMenuItemLink';

export default function HelpLink({
  path,
  coreHelpCenterDomain,
  render
}: HelpLinkProps): ReactElement<typeof CommonMenuItemLink> {
  const helpUrl = new HelpCenter(coreHelpCenterDomain).getHelpUrl(path);
  let props: HelpLinkRenderProps = {
    to: helpUrl
  };

  if (helpUrl) {
    props = { ...props, onSelection: () => new HelpPageViewedTrackingEvent(helpUrl).track() };
  }

  return render(props);
}
