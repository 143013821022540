import { DispatchType, PROJECT_RENAMED } from './action-types';
import { renameProject as renameProjectApi } from '../api/project-api';
import { GetState, ReduxState } from '../../types';
import { getProjectOrgIdIfLoaded } from '../selectors/selectors';
import notification from '../../frontend-common-libs/src/utils/notifications';
import {
  TrackedOrganizationEvents,
  trackEvent
} from '../../frontend-common-libs/src/user-analytics/trackedEvents';
import { withRefreshProjectToken } from './with-refresh-project-token';
import { CbWithToken } from '../../organization-management/actions/WithRefreshToken';

export function dispatchProjectRenamed(
  dispatch: DispatchType,
  state: ReduxState,
  projectId: string,
  name: string
) {
  const orgId = getProjectOrgIdIfLoaded(state, projectId);
  if (!orgId) return;
  dispatch({
    type: PROJECT_RENAMED,
    payload: { projectId, name, orgId }
  });
}

export function renameProject(projectId: string, name: string) {
  return async (dispatch: DispatchType, getState: GetState) => {
    try {
      const state = getState();
      const renameProjectCb: CbWithToken = async (accessToken: string) => {
        return renameProjectApi(projectId, name, accessToken);
      };
      await withRefreshProjectToken(dispatch, getState, projectId, renameProjectCb);
      dispatchProjectRenamed(dispatch, state, projectId, name);
      trackEvent(TrackedOrganizationEvents.RenameProject, { name });
    } catch (error) {
      notification.error('Error renaming project.');
    }
  };
}
