import React, { Component } from 'react';
import { Field, formValueSelector, InjectedFormProps, reduxForm } from 'redux-form';
import { Link } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { connect } from 'react-redux';
import { RenderField } from '../../../frontend-common-libs/src/common/input-fields';
import routes from '../../../core/routes';
import FeatureFlags, {
  FeatureFlagKeys
} from '../../../frontend-common-libs/src/components/feature_flags';
import {
  emailValidForAuth,
  textRequired
} from '../../../frontend-common-libs/src/common/form_validators';
import { ReduxState } from '../../../types';
import { loginExistingUser } from '../../actions/auth_actions';
import {
  PrimaryButton,
  SecondaryButton
} from '../../../frontend-common-libs/src/components/common/buttons';
import styles from './styles/login-form.module.scss';
import authStyles from '../styles/auth.module.scss';
import commonStyles from '../../../frontend-common-libs/src/scss/auth.module.scss';

type AdditionalProps = {
  emailValue: string;
  emailError: string;
  pwdError: string;
  submitThunkCreator: typeof loginExistingUser;
};

export type Props = InjectedFormProps<any, any> & AdditionalProps;

export class LoginForm extends Component<Props> {
  emailRequired = (value: string) => textRequired(value);

  emailFormValid = (value: string) => emailValidForAuth(value);

  pwdRequired = (value: string) => textRequired(value);

  signInWithSSO = async (): Promise<void> => {
    await Auth.federatedSignIn({ customProvider: 'IdP' });
  };

  render() {
    const {
      emailValue,
      submitThunkCreator,
      handleSubmit,
      emailError,
      pwdError,
      dirty,
      submitting
    } = this.props;
    return (
      <div className={authStyles.formContainer}>
        {/* @ts-ignore */}
        <form onSubmit={handleSubmit(submitThunkCreator)}>
          <Field
            label="EMAIL"
            name="email"
            type="email"
            additionalClass={commonStyles.inputField}
            extraError={!dirty ? '' : emailError}
            component={RenderField}
            validate={[this.emailRequired, this.emailFormValid]}
          />
          <Field
            label="PASSWORD"
            name="password"
            type="password"
            autoComplete="off"
            additionalClass={commonStyles.inputField}
            extraError={!dirty ? '' : pwdError}
            component={RenderField}
            validate={[this.pwdRequired]}
          />
          <PrimaryButton
            id="login-button"
            className={styles.loginButton}
            type="submit"
            isInProgress={submitting}
          >
            Sign In
          </PrimaryButton>
        </form>
        {FeatureFlags().get(FeatureFlagKeys.OKTA_SSO) ? (
          <SecondaryButton
            type="button"
            id="sso-login"
            className={styles.ssoLoginButton}
            onClick={this.signInWithSSO}
          >
            Sign In with SSO
          </SecondaryButton>
        ) : null}
        <div className={styles.forgotPasswordContainer}>
          <Link
            to={{
              pathname: routes.FORGOT_PASSWORD,
              // @ts-ignore
              query: emailValue
            }}
            id="forgot-pwd"
            className={styles.forgotPassword}
          >
            Forgot your password?
          </Link>
        </div>
      </div>
    );
  }
}

const selector = formValueSelector('login');
function mapStateToProps(state: ReduxState) {
  const emailValue = selector(state, 'email');

  let emailError = '';
  let pwdError = '';

  if (state.auth.get('error')) {
    // @ts-ignore
    emailError = state.auth.getIn(['error', 'login', 'email', 'msg'], '');
    // @ts-ignore
    pwdError = state.auth.getIn(['error', 'login', 'pwd', 'msg'], '');
  }

  return {
    emailValue,
    emailError,
    pwdError
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    form: 'login'
    // @ts-ignore
  })(LoginForm)
);
