import { fromJS, Map, OrderedMap } from 'immutable';
import {
  PROTOCOL_TEMPLATES_ADD,
  PROTOCOL_TEMPLATES_ARCHIVED,
  PROTOCOL_TEMPLATES_RESTORED,
  PROTOCOL_TEMPLATES_DELETED,
  PROTOCOL_TEMPLATES_ERROR,
  PROTOCOL_TEMPLATES_LOADED,
  PROTOCOL_TEMPLATES_LOADING,
  PROTOCOL_TEMPLATES_RENAMED
} from '../actions/protocolTemplate_types';
import { UNAUTH_USER } from '../auth/actions/auth_types';
import { jsArrayToOrderedMap } from '../frontend-common-libs/src/utils/immutableUtils';
import { ApiAction } from '../types';
import { sortEntitiesByLastUpdated } from '../frontend-common-libs/src/utils/rowEntityUtils';
import { PROJECT_SELECTED } from '../project-management';
import { UserFile } from '../frontend-common-libs/src/common/types';
import { DEFAULT_PROJECT_ID } from '../frontend-common-libs/src/common/project-management-types';

// @ts-ignore
const INITIAL_STATE_FOR_PROJECT: Map<string, any> = fromJS({
  isLoading: false,
  staleData: true,
  protocols: OrderedMap({}),
  errorMessage: '',
  lastSeen: '',
  loaded: false
});
// @ts-ignore
const INITIAL_STATE: Map<string, any> = fromJS({
  projects: {
    [DEFAULT_PROJECT_ID]: INITIAL_STATE_FOR_PROJECT
  }
});

function projectExistsInState(state: Map<string, any>, projectId: string): boolean {
  return state.getIn(['projects', projectId]) != null;
}

function getProtocols(state: Map<string, any>, projectId: string): OrderedMap<string, any> {
  return state.getIn(['projects', projectId, 'protocols']) as OrderedMap<string, any>;
}

const onProtocolsLoading = (state: Map<string, any>, action: any) => {
  const { projectId } = action.payload;
  return state
    .setIn(['projects', projectId, 'isLoading'], true)
    .setIn(['projects', projectId, 'errorMessage'], '');
};

const onProtocolsError = (state: Map<string, any>, action: any) => {
  const { projectId, message } = action.payload;
  return state
    .setIn(['projects', projectId, 'isLoading'], false)
    .setIn(['projects', projectId, 'errorMessage'], message);
};

const onProtocolsLoaded = (state: Map<string, any>, action: any) => {
  const protocols = action.payload.entities;
  const { projectId } = action.payload;
  // add the protocols to end of the list
  const newProtocols = getProtocols(state, projectId).merge(jsArrayToOrderedMap(protocols, 'id'));
  return state
    .setIn(['projects', projectId, 'isLoading'], false)
    .setIn(['projects', projectId, 'loaded'], true)
    .setIn(['projects', projectId, 'staleData'], false)
    .setIn(['projects', projectId, 'protocols'], sortEntitiesByLastUpdated(newProtocols))
    .setIn(['projects', projectId, 'errorMessage'], '')
    .setIn(
      ['projects', projectId, 'lastSeen'],
      action.payload.lastSeen ? action.payload.lastSeen : ''
    );
};

const addProtocol = (entity: UserFile, state: Map<string, any>) => {
  const projectId = entity.parent_id;
  const protocols = getProtocols(state, projectId);
  if (protocols == null) return state;
  return state.setIn(
    ['projects', projectId, 'protocols'],
    jsArrayToOrderedMap([entity], 'id').merge(protocols)
  );
};

const onProtocolAdded = (state: Map<string, any>, action: any) => {
  const { entity } = action.payload;
  return addProtocol(entity, state);
};

const onProtocolRenamed = (state: Map<string, any>, action: any) => {
  const { id, faEntity } = action.payload;
  const projectId = faEntity.parent_id;
  const protocols = getProtocols(state, projectId);
  if (protocols == null) return state;
  const renamedProtocol = fromJS(faEntity);
  // add the renamedProtocol to the top of the list
  const protocolsNew = OrderedMap({}).set(id, renamedProtocol).merge(protocols.delete(id));
  return state.setIn(['projects', projectId, 'protocols'], protocolsNew);
};

const onProtocolsDeleted = (state: Map<string, any>, action: any) => {
  const { fileList: deletedList, projectId } = action.payload;
  let protocols = getProtocols(state, projectId);
  if (protocols == null) return state;
  deletedList.forEach((id: string) => {
    protocols = protocols.delete(id);
  });
  return state.setIn(['projects', projectId, 'protocols'], protocols);
};

const deleteProtocol = (entity: UserFile, state: Map<string, any>) => {
  const projectId = entity.parent_id;
  let protocols = getProtocols(state, projectId);
  if (protocols == null) return state;
  protocols = protocols.delete(entity.id);
  return state.setIn(['projects', projectId, 'protocols'], protocols);
};

const onProtocolsArchived = (state: Map<string, any>, action: any) => {
  const { entities: archivedList } = action.payload;
  let newState = state;
  archivedList.forEach((entity: UserFile) => {
    newState = deleteProtocol(entity, newState);
  });
  return newState;
};

const onArchiveRestored = (state: Map<string, any>, action: any) => {
  const { entities } = action.payload;
  let newState = state;
  entities.forEach((entity: UserFile) => {
    newState = addProtocol(entity, newState);
  });
  return newState;
};

const resetState = () => INITIAL_STATE;

function onChangeProjectSelection(state: Map<string, any>, action: any) {
  const { projectId } = action.payload;
  const projectExists = projectExistsInState(state, projectId);
  if (!projectExists) return state.setIn(['projects', projectId], INITIAL_STATE_FOR_PROJECT);
  return state;
}

const actionMap = {
  [PROTOCOL_TEMPLATES_LOADING]: onProtocolsLoading, // Querying protocol template list
  [PROTOCOL_TEMPLATES_LOADED]: onProtocolsLoaded, // Protocol list query returned successfully
  [PROTOCOL_TEMPLATES_ERROR]: onProtocolsError, // Protocol query failed
  [PROTOCOL_TEMPLATES_ADD]: onProtocolAdded, // New protocol added
  [PROTOCOL_TEMPLATES_RENAMED]: onProtocolRenamed, // Protocol renamed
  [PROTOCOL_TEMPLATES_DELETED]: onProtocolsDeleted, // Protocols deleted
  [PROTOCOL_TEMPLATES_ARCHIVED]: onProtocolsArchived, // Protocols archived
  [PROTOCOL_TEMPLATES_RESTORED]: onArchiveRestored, // Entity restored from archive
  [UNAUTH_USER]: resetState, // User logged out
  [PROJECT_SELECTED]: onChangeProjectSelection
};

export default function protocolTemplateReducer(
  state: Map<string, any> = INITIAL_STATE,
  action: ApiAction<any, any> | undefined = undefined
): Map<string, any> {
  if (action) {
    return actionMap[action.type] ? actionMap[action.type](state, action) : state;
  }
  return state;
}
