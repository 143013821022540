import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import DeprecatedDataLoader from '../../common/DeprecatedDataLoader';
import PCRData from './PCRData';
import {
  dispatchRunClosed as dispatchRunClosedAction,
  getQPCRDataIfNeeded as getQPCRDataIfNeededAction
} from '../../../actions/qpcrdata_actions';
import {
  clearCurrentCfxRun as clearCurrentCfxRunAction,
  initCurrentCfxRun as initCurrentCfxRunAction
} from '../../../actions/currentCfxRun_actions';
import changeProjectSelectionAction from '../../../project-management/actions/change-project-selection';

type Props = {
  newRun: boolean;
  entityId: string;
  pcrData?: Map<string, any>;
  currentCfxRun?: Map<string, any>;
  getQPCRDataIfNeeded: typeof getQPCRDataIfNeededAction;
  initCurrentCfxRun: typeof initCurrentCfxRunAction;
  clearCurrentCfxRun: typeof clearCurrentCfxRunAction;
  dispatchRunClosed: typeof dispatchRunClosedAction;
  changeProjectSelection: any;
};

export class PCRDataPageImpl extends Component<Props> {
  static defaultProps = {
    pcrData: undefined,
    currentCfxRun: undefined,
    changeProjectSelection: undefined
  };

  componentDidUpdate(prevProps: Props) {
    const { pcrData, changeProjectSelection } = this.props;
    if (prevProps.pcrData && !pcrData) {
      // This check is used to handle when pcrData network state has changed.
      // There was pcrData (ie: when the component was loaded) and now it is gone
      // indicating there is new pcrData that needs to be retrieved.
      this.getData();
    }
    if (!prevProps.pcrData && pcrData) {
      const parentId = pcrData.get('parent_id');
      if (parentId) changeProjectSelection(parentId);
    }
  }

  componentWillUnmount() {
    const { clearCurrentCfxRun, dispatchRunClosed, entityId } = this.props;
    if (entityId) dispatchRunClosed(entityId);
    clearCurrentCfxRun();
  }

  getData = async () => {
    const { getQPCRDataIfNeeded, entityId } = this.props;
    await getQPCRDataIfNeeded(entityId);
  };

  initCurrentCfxRun = () => {
    const { initCurrentCfxRun, entityId, pcrData } = this.props;
    initCurrentCfxRun(entityId, pcrData);
  };

  render() {
    const { entityId, pcrData, newRun, currentCfxRun } = this.props;
    if (newRun)
      return (
        <DeprecatedDataLoader
          shouldLoad={!currentCfxRun}
          getData={this.initCurrentCfxRun}
          displayName="New PCR run"
        >
          <PCRData />
        </DeprecatedDataLoader>
      );

    return (
      <DeprecatedDataLoader
        displayName="PCR run" // When qpcrdata (network state) is updated by IOT, pcrData may become undefined.
        // If the user is on the run page when the related pcrData becomes undefined,
        // currentCfxRun data is used which prevents a forced refresh and preserves user changes.
        shouldLoad={!pcrData && !currentCfxRun}
        getData={this.getData}
      >
        <DeprecatedDataLoader
          displayName="PCR run view"
          shouldLoad={!currentCfxRun}
          getData={this.initCurrentCfxRun}
        >
          {/* @ts-ignore */}
          {() => currentCfxRun && <PCRData entityId={entityId} />}
        </DeprecatedDataLoader>
      </DeprecatedDataLoader>
    );
  }
}

export function mapStateToProps(
  state: {
    [key: string]: any;
  },
  ownProps: {
    [key: string]: any;
  }
) {
  const { entityId } = ownProps.match.params;
  const { currentCfxRun } = state;
  if (entityId) {
    const pcrData = state.qpcrdata.getIn([entityId, 'data']);

    return { entityId, pcrData, newRun: false, currentCfxRun };
  }

  return { newRun: true, currentCfxRun };
}

export default connect(mapStateToProps, {
  getQPCRDataIfNeeded: getQPCRDataIfNeededAction,
  initCurrentCfxRun: initCurrentCfxRunAction,
  clearCurrentCfxRun: clearCurrentCfxRunAction,
  dispatchRunClosed: dispatchRunClosedAction,
  changeProjectSelection: changeProjectSelectionAction
  // @ts-ignore
})(PCRDataPageImpl);
