// @ts-nocheck
import { getRunPlate } from '../../../../api/pcrData';

export type RunInfo = {
  barcode?: string;
  protocolName?: string;
  runStartTime?: string;
  runEndTime?: string;
  baseSerialNumber?: string;
  username?: string;
  createdBy?: string;
  instrumentModel?: string;
  instrumentName?: string;
};

export type Plate = {
  layout: {
    rows: number;
    columns: number;
  };
  plateType: string;
  scanMode: string;
  channelMap: {
    [key: string]: any;
  };
  wells: {
    [key: string]: any;
  };
};

export default class QPCRRun {
  _protocol: string;

  _runInfo: RunInfo;

  _name: string;

  _id: string;

  _created: string;

  _updated: string;

  _plate: Plate;

  constructor(
    protocol: string,
    runInfo: RunInfo,
    name: string,
    id: string,
    created: string,
    updated: string
  ) {
    this._protocol = protocol || '';
    this._runInfo = runInfo || {};
    this._name = name || '';
    this._id = id || '';
    this._created = created || '';
    this._updated = updated || '';
  }

  get protocol(): string {
    return this._protocol;
  }

  get runInfo(): RunInfo {
    return this._runInfo;
  }

  get name(): string {
    return this._name;
  }

  get id(): string {
    return this._id;
  }

  get created(): string {
    return this._created;
  }

  get updated(): string {
    return this._updated;
  }

  async getPlate(): Promise<Plate> {
    if (!this._plate) {
      const plateRes = await getRunPlate(this._id);
      this._plate = plateRes.data;
    }
    return this._plate;
  }

  static create(run: { [key: string]: any }) {
    const { protocol, runInfo, name, id, created, updated } = run;
    return new QPCRRun(protocol, runInfo, name, id, created, updated);
  }
}
