import UserAnalyticsClient from '../../frontend-common-libs/src/user-analytics/UserAnalyticsClient';

export type ActionEventParams = {
  numberOfInstruments: number;
  deviceTypes: string[];
  models: string[];
  action: 'startRun' | 'stopRun' | 'skipStep' | 'closeLid' | 'openLid';
};

export default class ActionButtonClickEvent {
  private static readonly ACTION_BUTTON_CLICK_EVENT_NAME = 'fleet-brio-action-button-clicked';

  // eslint-disable-next-line class-methods-use-this
  public track(params: ActionEventParams) {
    UserAnalyticsClient.getInstance().track(ActionButtonClickEvent.ACTION_BUTTON_CLICK_EVENT_NAME, {
      ...params
    });
  }
}
