import React from 'react';
import RectangularSkeletonLoader, { SkeletonLoaderProps } from './RectangularSkeletonLoader';

export default function RectangularOverflowSkeletonLoader(props: Readonly<SkeletonLoaderProps>) {
  const { style, viewBox, height, width } = props;

  const overrideProps = {
    ...props,
    style: style ?? {
      maxHeight: '12px',
      height: '12px',
      minHeight: '12px',
      minWidth: '150px'
    },
    viewBox: viewBox ?? '0 0 50 10',
    height: height ?? '5',
    width: width ?? '75%'
  };

  return <RectangularSkeletonLoader {...overrideProps} />;
}
