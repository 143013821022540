import requests from '../../../frontend-common-libs/src/utils/httpUtils';
import { GATEWAY } from '../../../frontend-common-libs/src/config/config';

const domain = GATEWAY.CPCR_SERVICE_PATHS.BASE_URL;

export default async function createCpcrJob(
  userId: string,
  instrumentId: string,
  entityId: string
): Promise<any> {
  const path = GATEWAY.CPCR_SERVICE_PATHS.CREATE_JOB_URL;
  const headers = { CognitoIdentityId: userId };
  const body = { userId, instrumentId, entityId };

  try {
    const res = await requests.post(domain, headers, path, {}, body);
    return { data: res.data, status: res.status };
  } catch (error) {
    console.log(`Error calling API ${path} for user ${userId}, error is ${error}`);
    throw error;
  }
}

export async function getCpcrJobProtocol(userId: string, jobId: string): Promise<any> {
  const path = GATEWAY.CPCR_SERVICE_PATHS.GET_JOB_PROTOCOL_URL(jobId);
  const headers = { CognitoIdentityId: userId };
  const res = await requests.get(domain, {}, path, headers);
  return { data: res.data, status: res.status };
}

export async function deleteCpcrJob(userId: string, jobId: string): Promise<any> {
  const path = GATEWAY.CPCR_SERVICE_PATHS.DELETE_JOB_URL(jobId);
  const headers = { CognitoIdentityId: userId };

  try {
    const res = await requests.delete(domain, {}, path, headers);
    return { status: res.status };
  } catch (error) {
    console.log(`Error calling API ${path} for user ${userId}, error is ${error}`);
    throw error;
  }
}
