import React, { PureComponent } from 'react';
// @ts-ignore
import { Match, Location } from 'react-router-dom';
import classnames from 'classnames';
import { Redirect, Route, Switch, withRouter, BrowserRouterProps } from 'react-router-dom';
import { matchPath } from 'react-router';
import { connect } from 'react-redux';
import { fromJS, Map, List } from 'immutable';
import AnalysisToolbar from './analysis_toolbar';
import PCRResults from './PCRResults';
import PCRRunDetails from './PCRRunDetails';
import FooterNavBar from '../../../frontend-common-libs/src/components/common/FooterNav';
import PCRRunProtocol from './PCRRunProtocol';
import PCRPlatePage from '../PCRPlatePage';
import {
  createPCRRun as createPCRRunAction,
  dispatchRunClosed as dispatchRunClosedAction,
  editPCRRun as editPCRRunAction,
  cloneAndUpdateRun as cloneAndUpdateRunAction
} from '../../../actions/qpcrdata_actions';
import {
  updateAmpSelection,
  updateMeltSelection,
  setSelectedStep,
  clearCurrentCfxRun as clearCurrentCfxRunAction
} from '../../../actions/currentCfxRun_actions';
import createTemplateAction from '../../../actions/cfx_run_template_actions';
import {
  getEntityId,
  getRunName,
  runHasLocalEdits,
  isPlateValid,
  isRunDetailsValid,
  hasValidLocalEdits,
  hasDataSteps as getHasDataSteps,
  hasStepGroupData as getHasStepGroupData,
  isCompletedRun,
  isProtocolValid,
  getShouldRedirect,
  getRun,
  selectedStepLoading as getSelectedStepLoading,
  selectedStepHasErrors as getSelectedStepHasErrors,
  meltStepSelected,
  ampStepSelected,
  getMeltSteps,
  getSteps as getAmpSteps,
  getSelectedStepNumber,
  getCqData,
  getMeltData,
  getMeltMode,
  getRunHasNewerVersion,
  getIsRunSaving
} from '../../../selectors/selectors';
import { pcrPageRegex } from '../../../frontend-common-libs/src/common/routes/regex';
import NavigationBlockPrompt from '../../../frontend-common-libs/src/components/common/NavigationBlockPrompt';
import notification from '../../../frontend-common-libs/src/utils/notifications';
import realTimePcrRoutes from '../../../real-time-pcr/routes';
import coreRoutes from '../../../core/routes';
import CqData from '../../../selectors/CqData';
import MeltData from '../../../selectors/MeltData';
import {
  errorCode,
  isVersionUpdateConflict
} from '../../../frontend-common-libs/src/utils/errorUtils';
import { ReduxState } from '../../../types';
import RunTemplate from './models/RunTemplate';
import WorkflowPage from '../../../pcr/components/workflow-layout/WorkflowPage';
import WorkflowView from '../../../pcr/components/workflow-layout/WorkflowView';
import WorkflowHeader from '../../../pcr/components/workflow-layout/WorkflowHeader';
import WorkflowArea from '../../../pcr/components/workflow-layout/WorkflowArea';
import { ProjectId } from '../../../frontend-common-libs/src/common/project-management-types';
import { getSelectedProjectId } from '../../../project-management';
import NewVersionIcon from '../../../frontend-common-libs/src/components/files/NewVersionIcon';
import VersionConflictPrompt from '../../../frontend-common-libs/src/components/files/conflict-dialog/VersionConflictPrompt';
import VersionConflictPromptVm from '../../../frontend-common-libs/src/components/files/conflict-dialog/VersionConflictPromptVm';
import Loader from '../../../frontend-common-libs/src/components/common/Loader';
import {
  canEditFilesInProject,
  isSelectedProjectTokenLoaded
} from '../../../project-management/selectors/selectors';
import ErrorAlert from '../../../frontend-common-libs/src/components/common/ErrorAlert';

export type Props = {
  entityId: string;
  match: Match;
  runName: string;
  hasDataSteps: boolean;
  hasStepGroupData: boolean;
  hasChanges: boolean;
  run: Map<string, unknown>;
  originalRun: Map<string, unknown>;
  createPCRRun: typeof createPCRRunAction;
  createRunTemplate: (runTemplate: RunTemplate) => void;
  editPCRRun: typeof editPCRRunAction;
  cloneAndUpdateRun: typeof cloneAndUpdateRunAction;
  canSave: boolean;
  runDetailsValid: boolean;
  plateValid: boolean;
  isCompleted: boolean;
  protocolValid: boolean;
  selectedStepIsLoading: boolean;
  selectedStepHasErrors: boolean;
  isAnalysisView: boolean;
  isMeltStepSelected: boolean;
  isAmpStepSelected: boolean;
  displayAmpData: typeof updateAmpSelection;
  displayMeltData: typeof updateMeltSelection;
  ampSteps: List<number>;
  meltSteps: List<number>;
  selectedStep: number | null | undefined;
  changeSelectedStep: typeof setSelectedStep;
  dataTableSortedData: Map<string, unknown>;
  dataTableHeaders: List<string>;
  csvDataString: string;
  isExportButtonDisabled: boolean;
  fileName: string;
  resultsData: CqData | MeltData;
  meltMode: string;
  projectId: ProjectId;
  canEditProjectFiles?: boolean;
  runHasNewerVersion?: boolean;
  isRunSaving?: boolean;
  clearCurrentCfxRun: typeof clearCurrentCfxRunAction;
  dispatchRunClosed: typeof dispatchRunClosedAction;
  isProjectTokenLoaded: boolean;
} & BrowserRouterProps;

export type State = {
  versionConflict: VersionConflictPromptVm;
  saveAsPressed: boolean;
};

export class PCRDataImpl extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      saveAsPressed: false,
      versionConflict: new VersionConflictPromptVm(this.onReload, this.onSaveAs)
    };
  }

  getDefaultRedirect = () => {
    const { entityId, isCompleted, match } = this.props;
    const { path } = match;
    if (!entityId) return `${path}/protocol`;
    if (isCompleted) return `${path}/results`;
    return `${path}/details`;
  };

  save = async (saveSource: string, shouldRedirect = false) => {
    const { versionConflict } = this.state;
    const { entityId, runName, run, originalRun, editPCRRun, createPCRRun } = this.props;
    try {
      if (entityId) {
        await editPCRRun(entityId, runName, run, originalRun, shouldRedirect);
      } else {
        await createPCRRun(runName, run, shouldRedirect, saveSource);
      }
    } catch (err) {
      const status = errorCode(err);
      if (status === 409) {
        if (isVersionUpdateConflict(err)) {
          versionConflict.showVersionConflictDialog(runName);
          return;
        }
        notification.error('Archived PCR run cannot be updated.');
      } else {
        notification.error('Failed to save run.');
      }
    }
  };

  createTemplate = async () => {
    const { createRunTemplate, run, projectId } = this.props;
    const runTemplate = RunTemplate.createFromRun(run);
    runTemplate.projectId = projectId;
    try {
      await createRunTemplate(runTemplate);
    } catch (error) {
      notification.error('Failed to save run template.');
    }
  };

  disableNextTab = (tab: string) => {
    const { protocolValid, runDetailsValid, plateValid, canSave } = this.props;

    let nextTabIsDisabled;
    switch (tab) {
      case 'protocol':
        nextTabIsDisabled = !protocolValid;
        break;
      case 'plate':
        nextTabIsDisabled = !plateValid || !protocolValid;
        break;
      case 'details':
        nextTabIsDisabled = !runDetailsValid || !plateValid;
        break;
      default:
        nextTabIsDisabled = !canSave;
        break;
    }
    return nextTabIsDisabled;
  };

  onReload = async () => {
    const { clearCurrentCfxRun, dispatchRunClosed, entityId } = this.props;
    if (entityId) dispatchRunClosed(entityId);
    clearCurrentCfxRun();
  };

  onSaveAs = async (fileName: string) => {
    const { entityId, run, originalRun, cloneAndUpdateRun } = this.props;
    const { versionConflict } = this.state;
    this.setState({ saveAsPressed: true });
    versionConflict.hideDialog();
    try {
      await cloneAndUpdateRun(entityId, fileName, run, originalRun);
    } catch (err) {
      notification.error('Failed to save run.');
      this.setState({ saveAsPressed: false });
      versionConflict.showVersionConflictDialog(fileName);
    }
  };

  renderNewerVersionIconIfNeeded = () => {
    const { runHasNewerVersion, isRunSaving } = this.props;
    return (
      <NewVersionIcon
        hasNewerVersion={runHasNewerVersion}
        tooltip="This file has a newer version"
        iconStyle="newer-version-icon"
        isRunSaving={isRunSaving}
      />
    );
  };

  renderAnalysisToolbar = () => {
    const {
      hasStepGroupData,
      selectedStepIsLoading,
      selectedStepHasErrors,
      isMeltStepSelected,
      isAmpStepSelected,
      displayAmpData,
      displayMeltData,
      ampSteps,
      meltSteps,
      selectedStep,
      changeSelectedStep,
      csvDataString,
      isExportButtonDisabled,
      fileName,
      meltMode
    } = this.props;

    const stepFailedToLoad: boolean = !hasStepGroupData && selectedStepHasErrors;
    const isToolbarDisabled =
      !stepFailedToLoad && (!hasStepGroupData || selectedStepIsLoading || selectedStepHasErrors);

    return (
      <div
        id="analysis-toolbar"
        className={classnames('button-group right', {
          'in-progress': selectedStepIsLoading
        })}
      >
        <AnalysisToolbar
          disabled={isToolbarDisabled}
          isMeltStepSelected={isMeltStepSelected}
          isAmpStepSelected={isAmpStepSelected}
          handleAmpButtonClick={displayAmpData}
          handleMeltButtonClick={displayMeltData}
          ampSteps={ampSteps}
          meltSteps={meltSteps}
          selectedStep={selectedStep}
          changeSelectedStep={changeSelectedStep}
          csvDataString={csvDataString}
          isExportButtonDisabled={isExportButtonDisabled}
          fileName={fileName}
          meltMode={meltMode}
        />
      </div>
    );
  };

  renderResults = () => {
    const {
      entityId,
      hasDataSteps,
      canSave,
      isAnalysisView,
      isAmpStepSelected,
      dataTableSortedData,
      dataTableHeaders,
      resultsData
    } = this.props;

    return hasDataSteps ? (
      <PCRResults
        entityId={entityId}
        saveAction={canSave ? this.save : undefined}
        isAnalysisView={isAnalysisView}
        isAmpStepSelected={isAmpStepSelected}
        dataTableSortedData={dataTableSortedData}
        dataTableHeaders={dataTableHeaders}
        resultsData={resultsData}
      />
    ) : (
      <div className="pcr-no-data-message">This CFX run does not contain data.</div>
    );
  };

  renderPlate = () => {
    const {
      protocolValid,
      entityId,
      canSave,
      match: { path }
    } = this.props;
    if (!protocolValid) return <Redirect to={`${path}/protocol`} />;
    const redirectUrl = entityId
      ? `${coreRoutes.APP}${realTimePcrRoutes.QPCR}/${entityId}/details`
      : `${coreRoutes.APP}${realTimePcrRoutes.QPCR}/create/details`;

    return (
      <PCRPlatePage
        isEditable
        // @ts-ignore
        saveAction={canSave ? this.save : undefined}
        redirectUrl={redirectUrl}
      />
    );
  };

  renderProtocol = () => {
    const { entityId } = this.props;
    return <PCRRunProtocol entityId={entityId} />;
  };

  renderDetails = () => {
    const {
      isCompleted,
      canSave,
      protocolValid,
      plateValid,
      match: { path }
    } = this.props;
    if (!protocolValid) return <Redirect to={`${path}/protocol`} />;
    if (!plateValid) return <Redirect to={`${path}/plate`} />;
    return (
      <PCRRunDetails
        isCompletedRun={isCompleted}
        disableSaveButton={!canSave}
        saveAction={this.save}
        createTemplateAction={this.createTemplate}
      />
    );
  };

  renderFooterNav = ({ match }: { match: Match }) => {
    const {
      // @ts-ignore
      params: { activeTab }
    } = match;
    if (!activeTab) return null;

    const {
      match: { url },
      isCompleted
    } = this.props;

    let links: List<Map<string, any>> = fromJS([
      {
        id: 'protocol',
        pageId: 1,
        title: 'Protocol',
        isDisabled: false
      },
      {
        id: 'plate',
        pageId: 2,
        title: 'Plate',
        isDisabled: this.disableNextTab('protocol')
      },
      {
        id: 'details',
        pageId: 3,
        title: 'Run',
        isDisabled: this.disableNextTab('plate')
      }
    ]) as List<Map<string, any>>;
    if (isCompleted)
      links = links.push(
        fromJS({
          id: 'results',
          pageId: 4,
          title: 'Analysis',
          isDisabled: this.disableNextTab('details')
        }) as Map<string, any>
      );
    // @ts-ignore
    const lastTabId = links.last().get('id');
    const navButtons = fromJS({
      prevBtn: {
        // @ts-ignore
        isDisabled: activeTab === links.first().get('id'),
        title: 'Previous'
      },
      nextBtn: {
        isDisabled: this.disableNextTab(activeTab),
        title: 'Next'
      }
    }) as Map<string, any>;
    return activeTab ? (
      <FooterNavBar
        links={links}
        baseUrl={url}
        activeTab={activeTab}
        navButtons={navButtons}
        showNextButton={activeTab !== lastTabId}
      />
    ) : null;
  };

  render() {
    const {
      match,
      runName,
      hasChanges,
      isCompleted,
      isAnalysisView,
      originalRun,
      entityId,
      canEditProjectFiles,
      isProjectTokenLoaded
    } = this.props;
    const { versionConflict, saveAsPressed } = this.state;
    if (!isProjectTokenLoaded) return <Loader />;
    if (!entityId && !canEditProjectFiles) {
      return (
        <ErrorAlert
          error="Reviewers cannot create runs within a project."
          errorId="cfx-run-create-permissions-error"
        />
      );
    }

    const pcrRoutes = [
      { route: 'protocol', render: this.renderProtocol },
      { route: 'details', render: this.renderDetails },
      { route: 'plate', render: this.renderPlate }
    ];

    if (isCompleted)
      pcrRoutes.push({
        route: 'results',
        render: this.renderResults
      });
    const fileName = originalRun != null ? originalRun.get('name') : runName;

    const navigationMsg = canEditProjectFiles
      ? 'Your CFX run contains unsaved changes. Are you sure you want to leave?'
      : 'You are assigned as a Reviewer for this project and are not authorized to save file changes.';

    return (
      <>
        <VersionConflictPrompt versionConflictPromptVm={versionConflict} />
        <NavigationBlockPrompt
          shouldBlock={hasChanges}
          whiteListURLPattern={new RegExp(pcrPageRegex)}
          confirmationMessage={navigationMsg}
          title="Discard changes?"
          okBtnText="Leave"
        />
        <WorkflowPage>
          <WorkflowArea className={classnames({ analysis: isAnalysisView })}>
            <WorkflowHeader>
              <span id="pcr-run-name" className="pcr-run-name">
                {fileName}
              </span>
              {this.renderNewerVersionIconIfNeeded()}
              {isAnalysisView && this.renderAnalysisToolbar()}
            </WorkflowHeader>
            <WorkflowView>
              {saveAsPressed && <Loader />}
              {!saveAsPressed && (
                <Switch>
                  {pcrRoutes.map(({ route, render }) => (
                    <Route key={route} exact path={`${match.path}/${route}`} render={render} />
                  ))}
                  <Redirect to={this.getDefaultRedirect()} />
                </Switch>
              )}
            </WorkflowView>
          </WorkflowArea>

          <Route path={`${match.path}/:activeTab`} render={this.renderFooterNav} />
        </WorkflowPage>
      </>
    );
  }
}

const getSubView = (location: Location) => {
  const { pathname: path } = location;
  return path.substring(path.lastIndexOf('/') + 1);
};

export function RedirectSavedRun(
  props: Props & {
    currentCfxRunId?: string;
    location: Location;
    shouldRedirect?: boolean;
  }
) {
  const { currentCfxRunId, entityId, shouldRedirect, ...rest } = props;
  if (shouldRedirect) {
    return <Redirect to={coreRoutes.FILE_LIST} push />;
  }
  if (!entityId && currentCfxRunId) {
    const { location } = props;
    const subView = getSubView(location);
    return (
      <Redirect to={`${coreRoutes.APP}${realTimePcrRoutes.QPCR}/${currentCfxRunId}/${subView}`} />
    );
  }
  return <PCRDataImpl entityId={entityId} {...rest} />;
}

RedirectSavedRun.defaultProps = {
  currentCfxRunId: undefined,
  shouldRedirect: undefined
};

export const mapStateToProps = (
  state: ReduxState,
  ownProps: {
    [key: string]: any;
  }
) => {
  const runName = getRunName(state) || 'New CFX Run';
  const run = getRun(state);
  const protocolValid = isProtocolValid(state);
  const plateValid = isPlateValid(state);
  const hasChanges = runHasLocalEdits(state);
  const { entityId } = ownProps;
  const currentCfxRunId = getEntityId(state);
  const shouldRedirect = getShouldRedirect(state);
  const projectId = getSelectedProjectId(state);
  const canEditProjectFiles = canEditFilesInProject(state, projectId);
  const hasEdits = hasValidLocalEdits(state);
  const canSave = hasEdits && canEditProjectFiles;
  const runDetailsValid = isRunDetailsValid(state);
  const isCompleted = isCompletedRun(state);
  const hasDataSteps = getHasDataSteps(state);
  const hasStepGroupData = getHasStepGroupData(state);
  const selectedStepIsLoading = getSelectedStepLoading(state);
  const selectedStepHasErrors = getSelectedStepHasErrors(state);
  const isAnalysisView = matchPath(ownProps.location.pathname, {
    path: `${ownProps.match.path}/results`,
    exact: true
  });
  const isMeltStepSelected = meltStepSelected(state);
  const isAmpStepSelected = ampStepSelected(state);
  const meltSteps = getMeltSteps(state);
  const ampSteps = getAmpSteps(state);
  const selectedStep = getSelectedStepNumber(state);
  let resultsData;

  if (isAmpStepSelected || isMeltStepSelected) {
    resultsData = isAmpStepSelected ? getCqData(state) : getMeltData(state);
  }

  const tableHeaders = resultsData ? resultsData.tableHeaders : List();
  const dataTableSortedData = resultsData ? resultsData.getTableData() : Map();
  const csvDataString = resultsData && resultsData.getCsvDataString();
  const isExportButtonDisabled = csvDataString === undefined;

  const fileName = resultsData && resultsData.getFileName(runName);
  const meltMode = getMeltMode(state);

  const runHasNewerVersion = getRunHasNewerVersion(state, entityId);
  const isRunSaving = getIsRunSaving(state, entityId);

  const props = {
    entityId,
    currentCfxRunId,
    runName,
    hasDataSteps,
    hasStepGroupData,
    hasChanges,
    run,
    canSave,
    runDetailsValid,
    plateValid,
    isCompleted,
    shouldRedirect,
    protocolValid,
    selectedStepIsLoading,
    selectedStepHasErrors,
    isAnalysisView,
    isMeltStepSelected,
    isAmpStepSelected,
    meltSteps,
    ampSteps,
    selectedStep,
    dataTableSortedData,
    dataTableHeaders: tableHeaders,
    csvDataString,
    isExportButtonDisabled,
    fileName,
    resultsData,
    meltMode,
    projectId,
    runHasNewerVersion,
    isRunSaving,
    canEditProjectFiles,
    isProjectTokenLoaded: isSelectedProjectTokenLoaded(state)
  };

  if (!entityId) return props;

  const originalRun = state.qpcrdata.getIn([entityId, 'data']);
  return { ...props, originalRun };
};

export default withRouter(
  // @ts-ignore
  connect(mapStateToProps, {
    createPCRRun: createPCRRunAction,
    editPCRRun: editPCRRunAction,
    cloneAndUpdateRun: cloneAndUpdateRunAction,
    displayAmpData: updateAmpSelection,
    displayMeltData: updateMeltSelection,
    changeSelectedStep: setSelectedStep,
    createRunTemplate: createTemplateAction,
    clearCurrentCfxRun: clearCurrentCfxRunAction,
    dispatchRunClosed: dispatchRunClosedAction
    // @ts-ignore
  })(RedirectSavedRun)
);
