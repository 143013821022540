import React from 'react';
import { Link, Redirect, withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { fromJS, Map } from 'immutable';
import { connect } from 'react-redux';
import { PcrProtocolEditor } from '../../frontend-common-libs/src/components/pcr/pcr-protocols/PcrProtocolEditor';
import './styles/pcr-protocol.scss';
import QPcrProtocol from '../../frontend-common-libs/src/components/pcr/pcr-protocols/models/QPcrProtocol';
import {
  createProtocol as createProtocolAction,
  editProtocol as editProtocolAction
} from './actions/protocol-actions';
import conventionalPcrRoutes from '../routes';
import coreRoutes from '../../core/routes';
import notification from '../../frontend-common-libs/src/utils/notifications';
import ProtocolRepository from './repository/ProtocolRepository';
import NewVersionIcon from '../../frontend-common-libs/src/components/files/NewVersionIcon';
import VersionConflictPrompt from '../../frontend-common-libs/src/components/files/conflict-dialog/VersionConflictPrompt';
import VersionConflictPromptVm from '../../frontend-common-libs/src/components/files/conflict-dialog/VersionConflictPromptVm';
import {
  commonErrorMessage,
  errorCode,
  isVersionUpdateConflict
} from '../../frontend-common-libs/src/utils/errorUtils';
import ErrorAlert from '../../frontend-common-libs/src/components/common/ErrorAlert';
import SetSelectedProjectEvent from '../../frontend-common-libs/src/system-event/project-management/SetSelectedProjectEvent';
import { ConnectViewModel } from '../../frontend-common-libs/src/common/mvvm/ConnectViewModel';
import { ReduxState } from '../../types';
import { getSelectedProjectId } from '../../project-management';
import {
  canEditFilesInProject,
  isSelectedProjectTokenLoaded
} from '../../project-management/selectors/selectors';
import Loader from '../../frontend-common-libs/src/components/common/Loader';

type State = {
  done: boolean;
  error: string;
  loading: boolean;
  versionConflict: VersionConflictPromptVm;
  reloadCounter: number;
};
export type Props = {
  match?: RouteComponentProps['match'];
  history?: RouteComponentProps['history'];
  protocolRepository: ProtocolRepository;
  canEditProjectFiles?: boolean;
  createProtocol?: (pcrProtocol: QPcrProtocol) => any;
  editProtocol?: (pcrProtocol: QPcrProtocol) => any;
  isProjectTokenLoaded: boolean;
};

export class EditProtocolPageImpl extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      done: false,
      error: '',
      loading: false,
      versionConflict: new VersionConflictPromptVm(this.onReload, this.onSaveAs),
      reloadCounter: 1
    };
  }

  get entityId() {
    const { match } = this.props as Record<string, any>;
    const { entityId } = match.params;
    return entityId;
  }

  // @ts-ignore
  // eslint-disable-next-line consistent-return
  loadPcrProtocol = async () => {
    try {
      this.setState({ loading: true });
      const { file, protocol } = await ProtocolRepository.instance.getProtocol(this.entityId);
      this.setState({ loading: false });
      const options = {
        name: file.name,
        protocol: fromJS(protocol) as Map<string, any>,
        templateId: file.id,
        versionNumber: file.versionNumber,
        projectId: file.parent_id
      };
      SetSelectedProjectEvent.notify(file.parent_id);
      return new QPcrProtocol(options);
    } catch (e) {
      let errorMessage = e instanceof Error && e.message ? e.message : 'Failed to load';
      const status = errorCode(e);
      if (status === 404) {
        errorMessage = commonErrorMessage(404, 'Protocol');
      }
      this.setState({
        error: errorMessage,
        loading: false
      });
    }
  };

  goToProtocolListPage = () => {
    const { history } = this.props;
    if (history) {
      history.replace(`${coreRoutes.APP}${conventionalPcrRoutes.PCR_PROTOCOL_LIST}`);
    }
  };

  editProtocol = async (pcrProtocol: QPcrProtocol) => {
    const { versionConflict } = this.state;
    const { editProtocol } = this.props;
    if (!editProtocol) return;
    versionConflict.beforeSave(pcrProtocol);
    try {
      this.setState({ loading: true });
      await editProtocol(pcrProtocol);
      this.setState({ done: true, loading: false });
    } catch (e) {
      this.setState({ loading: false });
      const errorMessage = 'Error editing protocol';
      if (isVersionUpdateConflict(e)) versionConflict.showVersionConflictDialog(pcrProtocol.name);
      else notification.error(errorMessage);
    }
  };

  onReload = async () => {
    const { reloadCounter, versionConflict } = this.state;
    versionConflict.hideDialog();
    this.setState({
      reloadCounter: reloadCounter + 1
    });
  };

  onSaveAs = async (fileName: string) => {
    const { versionConflict } = this.state;
    const { createProtocol } = this.props;
    if (!createProtocol) return;
    try {
      const protocol = (versionConflict.savedObject as QPcrProtocol).updateName(fileName);
      await createProtocol(protocol);
      this.setState({ done: true });
    } catch (e) {
      const errorMessage = 'Error creating protocol';
      notification.error(errorMessage);
    }
  };

  renderProtocol() {
    const { versionConflict, error, reloadCounter } = this.state;
    const { canEditProjectFiles, isProjectTokenLoaded } = this.props;
    if (!isProjectTokenLoaded) return <Loader />;
    if (error) return <ErrorAlert error={error} errorId="protocol-archived-error" />;
    return (
      <>
        <VersionConflictPrompt versionConflictPromptVm={versionConflict} />
        <PcrProtocolEditor
          reloadCounter={reloadCounter}
          loadProtocol={this.loadPcrProtocol}
          applyButtonText="Save"
          onApplyPressed={this.editProtocol}
          onCancelPressed={this.goToProtocolListPage}
          isPcr
          disabled={!canEditProjectFiles}
        />
      </>
    );
  }

  render() {
    const { protocolRepository, isProjectTokenLoaded } = this.props;
    const { done, loading, error } = this.state;
    if (done) {
      return <Redirect to={`${coreRoutes.APP}${conventionalPcrRoutes.PCR_PROTOCOL_LIST}`} />;
    }
    const hasNewerVersion =
      !loading && isProjectTokenLoaded && protocolRepository.hasNewVersion(this.entityId);
    return (
      <div className="pcr-protocol-page">
        <div className="top-row">
          <Link
            id="protocols"
            to={`${coreRoutes.APP}${conventionalPcrRoutes.PCR_PROTOCOL_LIST}`}
            className="back-link"
          >
            <span className="chevron left">Back to protocols</span>
          </Link>
          <NewVersionIcon
            hasNewerVersion={hasNewerVersion && !error}
            tooltip="This protocol has a newer version"
            iconStyle="protocol-newer-version-icon"
          />
        </div>
        {this.renderProtocol()}
      </div>
    );
  }
}

export function EditProtocolPageView(props: Readonly<Props>) {
  return (
    <ConnectViewModel vm={ProtocolRepository.instance} vmPropKey="protocolRepository">
      <EditProtocolPageImpl {...props} />
    </ConnectViewModel>
  );
}

export function mapStateToProps(state: ReduxState) {
  const projectId = getSelectedProjectId(state);
  return {
    canEditProjectFiles: canEditFilesInProject(state, projectId),
    isProjectTokenLoaded: isSelectedProjectTokenLoaded(state)
  };
}

export default withRouter(
  // @ts-ignore
  connect(mapStateToProps, {
    createProtocol: createProtocolAction,
    editProtocol: editProtocolAction
  })(EditProtocolPageView)
);
