import { useEffect, useRef } from 'react';
import notification from '../../../../frontend-common-libs/src/utils/notifications';
import InstrumentReservation from '../../InstrumentReservation';

export type Props = {
  reservation: InstrumentReservation;

  isProtocolAssignmentPending: boolean;

  isProtocolUnassignmentPending: boolean;

  protocolAssignmentTimeout: number | null;

  desiredJobIdTimestampStale: boolean;
};
export default function ProtocolAssignmentTimer({
  reservation,
  desiredJobIdTimestampStale,
  isProtocolAssignmentPending,
  isProtocolUnassignmentPending,
  protocolAssignmentTimeout
}: Props) {
  const assignProtocolTimer = useRef<any>();
  const unassignProtocolTimer = useRef<any>();

  const assignProtocolTimeoutCallback = async () => {
    try {
      await reservation.rollbackAssignProtocol();
      notification.info('Protocol assignment failed. Try again.', {
        autoClose: false
      });
    } catch (error) {
      console.error('Error on Protocol assignment', error);
    }
  };

  const createAssignProtocolTimer = () => {
    assignProtocolTimer.current = setTimeout(async () => {
      await assignProtocolTimeoutCallback();
    }, protocolAssignmentTimeout as number);
  };

  const clearAssignProtocolTimer = () => {
    if (assignProtocolTimer.current) {
      clearTimeout(assignProtocolTimer.current);
    }
  };

  const shouldCreateProtocolAssignmentTimer =
    isProtocolAssignmentPending && !desiredJobIdTimestampStale && protocolAssignmentTimeout != null;

  useEffect(() => {
    if (shouldCreateProtocolAssignmentTimer) {
      createAssignProtocolTimer();
    } else {
      clearAssignProtocolTimer();
    }

    return clearAssignProtocolTimer;
  }, [isProtocolAssignmentPending, desiredJobIdTimestampStale]);

  const unassignProtocolTimeoutCallback = async () => {
    try {
      await reservation.rollbackUnassignProtocol();
      notification.info('Assignment removal failed. Try again.', {
        autoClose: false
      });
    } catch (error) {
      console.error('Error on Unassigning protocol', error);
    }
  };
  const createUnassignProtocolTimer = () => {
    unassignProtocolTimer.current = setTimeout(async () => {
      await unassignProtocolTimeoutCallback();
    }, protocolAssignmentTimeout as number);
  };

  const clearUnassignProtocolTimer = () => {
    if (unassignProtocolTimer.current) {
      clearTimeout(unassignProtocolTimer.current);
    }
  };

  const shouldCreateUnassignProtocolTimer =
    isProtocolUnassignmentPending && protocolAssignmentTimeout != null;

  useEffect(() => {
    if (shouldCreateUnassignProtocolTimer) {
      createUnassignProtocolTimer();
    } else {
      clearUnassignProtocolTimer();
    }

    return clearUnassignProtocolTimer;
  }, [isProtocolUnassignmentPending]);

  return null;
}
