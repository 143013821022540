import React, { Component } from 'react';
import { select } from 'd3-selection';
// eslint-disable-next-line import/no-extraneous-dependencies
import { ScaleLinear } from '@visx/vendor/d3-scale';
import { axisLeft } from 'd3-axis';

import { Margins, AxisPadding } from './common';

type Props = {
  yScale: ScaleLinear<number, number>;
  chartHeight: number;
  margins: Margins;
  padding: AxisPadding;
};

export default class TemperatureAxis extends Component<Props> {
  node!: Element;

  componentDidMount() {
    const { node } = this;
    const { yScale } = this.props;

    const {
      chartHeight,
      margins: { top: marginTop, bottom: marginBottom, left: marginLeft },
      padding: { bottom: padBottom, top: padTop }
    } = this.props;

    const axisY = axisLeft(yScale).ticks(5);
    const axisElement = select(node);

    const yAxis = axisElement
      .append('g')
      .call(axisY)
      .attr('class', 'axis')
      .attr('transform', `translate(${marginLeft} ${marginTop})`)
      .attr('font-family', 'Rubik, sans-serif')
      .attr('font-size', '12px');

    // add the y-label
    yAxis
      .append('text')
      .attr('transform', 'rotate(-90)')
      .attr('y', -55)
      .attr('dy', '1.5em')
      .attr('x', -(padTop + (chartHeight - marginTop - padTop - marginBottom - padBottom) / 2))
      .attr('class', 'axis-label')
      .text('Temperature, ºC');
  }

  setRef = (node: Element | null | undefined) => {
    if (node) {
      this.node = node;
    }
  };

  render() {
    return <g ref={this.setRef} />;
  }
}
