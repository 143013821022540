import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import AutoSizer, { VerticalSize } from 'react-virtualized-auto-sizer';
import { Map, Seq } from 'immutable';
import { InstrumentFamilyPluginRepository } from '../../instrument-family-plugin';
import withCanOpenFile from './withCanOpenFile';

export type Props = {
  list: Seq.Indexed<Map<string, any>>;
  errorMessage: string;
  isLoading: boolean;
  getUserFilesIfNeeded: (...args: Array<any>) => any;
};

export default class RecentFilesTable extends PureComponent<Props> {
  fileRowHeight = 76;

  defaultHeight = 0;

  heightToSubtract = 46; // 36 - header, 10px padding bottom

  contentHeight = (size = 0, height = this.defaultHeight) => {
    return Math.max(
      this.fileRowHeight,
      Math.min(height - this.heightToSubtract, size * this.fileRowHeight)
    );
  };

  handleRender = () => {
    const { isLoading, list, errorMessage } = this.props;
    if (isLoading) return this.renderLoader();
    if (errorMessage) return this.renderErrorMessage();
    if (!list || list.size === 0) return this.renderNoRecentFileMessage();
    return this.renderRecentFilesTable(list);
  };

  renderFiles = (list: Seq.Indexed<Map<string, any>>, height = this.defaultHeight) => {
    const rowsToShow = parseInt(String((height - this.heightToSubtract) / this.fileRowHeight), 10);
    const maxNumberOfRows = Math.max(6, rowsToShow);
    const filesToShow = list.slice(0, maxNumberOfRows);

    return filesToShow.map(item => {
      const plugin =
        InstrumentFamilyPluginRepository.getInstance().getInstrumentFamilyPluginForFile(item);
      if (plugin) {
        const RecentFilesRow = plugin.name?.includes('image-lab-touch')
          ? withCanOpenFile(withRouter(plugin.getRecentFileRow()))
          : plugin.getRecentFileRow();

        // @ts-ignore
        return <RecentFilesRow key={item.get('id')} item={item} />;
      }
      return null;
    });
  };

  renderRecentFilesTable = (list: Seq.Indexed<Map<string, any>>) => (
    <AutoSizer disableWidth>
      {({ height }: VerticalSize) => (
        <div
          style={{
            height: this.contentHeight(list.size, height)
          }}
          className="autosizer-content"
        >
          <table id="recent-file-table">
            <tbody className="file-table-content">{this.renderFiles(list, height)}</tbody>
          </table>
        </div>
      )}
    </AutoSizer>
  );

  renderErrorMessage = () => {
    const { getUserFilesIfNeeded } = this.props;
    return (
      <div className="error-files flex-bullseye">
        <span>Error retrieving recent files</span>
        <button type="button" className="new-btn primary" onClick={getUserFilesIfNeeded}>
          Try Again
        </button>
      </div>
    );
  };

  renderNoRecentFileMessage = () => (
    <div id="no-files-message" className="flex-bullseye">
      No files
    </div>
  );

  renderLoader = () => (
    <div className="loader flex-bullseye">
      <i className="fa fa-spinner fa-spin fa-3x fa-fw" />
      <span className="sr-only">Loading...</span>
    </div>
  );

  render() {
    return (
      <div className="recent-files-table-container">
        <div className="files-titles">
          <h4 className="name-col">RECENT FILES</h4>
          <h4 className="type-col">TYPE</h4>
          <h4 className="last-updated-col">UPDATED</h4>
        </div>
        {this.handleRender()}
      </div>
    );
  }
}
