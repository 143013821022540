import React, { KeyboardEvent } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import ModalDialog from '../../../frontend-common-libs/src/components/common/dialogs/ModalDialog';
import MoveFileToProjectVm from './MoveFileToProjectVm';
import {
  ProjectWithRole,
  USER_FILES
} from '../../../frontend-common-libs/src/common/project-management-types';
import '../../styles/move-file.scss';
import projectIcon from '../../../../img/folder.svg';
import warningIcon from '../../../settings-page/img/warning-icon.svg';
import { ReduxState } from '../../../types';
import { getProjectsWithRoles } from '../../selectors/selectors';
import { getActiveOrg } from '../../../organization-management/selectors/selectors';
import { modalStyleV2 } from '../../../frontend-common-libs/src/components/common/dialogs/custom-modal-styles';
import { ConnectViewModel } from '../../../frontend-common-libs/src/common/mvvm/ConnectViewModel';
import ProjectRole from '../project-dropdown/ProjectRole';
import { getProjectTokenIfNeeded as getProjectTokenIfNeededAction } from '../../actions/get-project-token';
import notification from '../../../frontend-common-libs/src/utils/notifications';

export type Props = {
  moveFileToProjectVm: MoveFileToProjectVm;
  projects?: ProjectWithRole[];
  getProjectTokenIfNeeded?: (projectId: string) => any;
};

export class MoveFileToProjectImpl extends React.Component<Props> {
  componentDidUpdate() {
    const { projects, moveFileToProjectVm } = this.props;
    if (!projects || projects === moveFileToProjectVm.projects) return;
    moveFileToProjectVm.projects = projects;
  }

  async selectProject(project: ProjectWithRole) {
    const { moveFileToProjectVm, getProjectTokenIfNeeded } = this.props;
    moveFileToProjectVm.selectedProjectId = project.id;
    if (getProjectTokenIfNeeded) {
      try {
        await getProjectTokenIfNeeded(project.id);
      } catch {
        notification.error(`Error getting project's role.`);
      }
    }
  }

  renderProjectRow(project: ProjectWithRole, index: number) {
    const { moveFileToProjectVm } = this.props;
    const isRowDisabled = project.projectRole && !project.canEditProjectFiles;
    return (
      <div
        onClick={async () => {
          await this.selectProject(project);
        }}
        className={classNames('project-row', {
          'project-row-selected': moveFileToProjectVm.selectedProjectId === project.id
        })}
        role="button"
        tabIndex={0}
        onKeyDown={async (event: KeyboardEvent) => {
          if (event.key === 'Spacebar' || event.key === ' ') await this.selectProject(project);
        }}
        key={project.id}
        id={project.name}
      >
        <img
          src={projectIcon}
          alt="project"
          className={classNames('project-icon', {
            'project-icon-disabled': isRowDisabled
          })}
        />
        <span
          className={classNames('project-name with-ellipsis', {
            'project-row-disabled': isRowDisabled
          })}
          title={project.name}
          id={`project-name-${index}`}
        >
          {project.name}
        </span>
        <ProjectRole
          selectedProjectId={project.id}
          isTokenLoading={project.isTokenLoading}
          projectRole={project.projectRole}
          id={`project-role-${index}`}
        />
      </div>
    );
  }

  render() {
    const { moveFileToProjectVm } = this.props;
    const { title, okBtnText, shouldBlock, onCancel, onMove, projects, file, saveInProgress } =
      moveFileToProjectVm;
    if (!shouldBlock) return null;
    const topRow = `${USER_FILES} > ${file.name}`;
    const customModalStyle = { ...modalStyleV2, modalStyle: 'move-file-modal' };
    const warnMsg = moveFileToProjectVm.getWarningMessage();
    return (
      <ModalDialog
        show={shouldBlock}
        title={title}
        onSuccess={onMove}
        onCancel={onCancel}
        okBtnText={okBtnText}
        okBtnDisabled={!moveFileToProjectVm.canMove()}
        customModalStyle={customModalStyle}
        inProgress={saveInProgress}
      >
        <div className="move-file-panel">
          <span className="with-ellipsis top-row" title={topRow}>
            {topRow}
          </span>
          <div className="project-list">
            {projects.map((project: ProjectWithRole, index: number) =>
              this.renderProjectRow(project, index)
            )}
          </div>
          <div className="warn-msg">
            {warnMsg && (
              <>
                <img src={warningIcon} alt="alert" className="warn-icon" />
                <div id="warning-msg">{warnMsg}</div>
              </>
            )}
          </div>
        </div>
      </ModalDialog>
    );
  }
}

export function mapStateToProps(state: ReduxState) {
  const orgId = getActiveOrg(state);
  const projectsWithRoles: ProjectWithRole[] = getProjectsWithRoles(state, orgId);
  return {
    projects: projectsWithRoles
  };
}

export function MoveFileToProjectView(props: Readonly<Props>) {
  const { moveFileToProjectVm } = props;
  return (
    <ConnectViewModel vm={moveFileToProjectVm} vmPropKey="moveFileToProjectVm">
      <MoveFileToProjectImpl {...props} />
    </ConnectViewModel>
  );
}

// @ts-ignore
export default connect(mapStateToProps, { getProjectTokenIfNeeded: getProjectTokenIfNeededAction })(
  MoveFileToProjectView
);
