import { parseFromUTCString } from '../../../../../../frontend-common-libs/src/common/display-formats';
import DateFnsFactory from '../../../../../../frontend-common-libs/src/common/DateFnsFactory';

export type RunReport = {
  runName: string;
  startDateTime: string;
};

export default class HeaderParser {
  private readonly report: RunReport;

  public readonly runName: string;

  public readonly date: string;

  constructor(runReport: RunReport) {
    this.report = runReport;
    this.runName = this.report.runName;
    if (this.runName == null) throw Error('Missing run name');
    this.date = this.parseAndFormatDate();
  }

  private parseAndFormatDate(): string {
    const { startDateTime } = this.report;
    const date = parseFromUTCString(startDateTime);

    return date == null ? '' : DateFnsFactory.format(date, 'dd-LLLL-yyyy');
  }
}
