import UserAnalyticsClient from './UserAnalyticsClient';

export enum TrackedFilesEvents {
  PtcCreateProtocol = 'create-ptc-protocol',
  PtcEditProtocol = 'edit-ptc-protocol',
  PtcUploadProtocol = 'upload-ptc-protocol',
  PtcExportProtocol = 'export-ptc-protocol',
  PtcExportRunReport = 'export-ptc-run-report',
  CfxCreateProtocol = 'create-cfx-protocol',
  CfxEditProtocol = 'edit-cfx-protocol',
  CfxUploadProtocol = 'upload-cfx-protocol',
  CfxExportProtocol = 'export-cfx-protocol',
  CfxExportPcrd = 'export-cfx-pcrd'
}

export enum TrackedOrganizationEvents {
  CreateOrg = 'create-org',
  AddUserToOrg = 'add-user-to-org',
  RemoveUserFromOrg = 'remove-user-from-org',
  AssignOrgRole = 'assign-org-role',
  RenameOrg = 'rename-org',
  CreateProject = 'create-project',
  AddUserToProject = 'add-user-to-project',
  AssignProjectRole = 'assign-project-role',
  RenameProject = 'rename-project',
  RemoveUserFromProject = 'remove-user-from-project',
  MoveFileToProject = 'move-file-to-project'
}

export type TrackedEvents = TrackedFilesEvents | TrackedOrganizationEvents;

export function trackEvent(
  event: TrackedEvents,
  params?: {
    [key: string]: any;
  }
) {
  UserAnalyticsClient.getInstance().track(event, params);
}
