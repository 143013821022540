import { Map, Seq } from 'immutable';
import FileUpload from './fileupload';
import FileUploadDoesNotExistError from './FileUploadDoesNotExistError';

export default class FileUploadRepository {
  private uploads: Map<number, FileUpload>;

  constructor() {
    // @ts-ignore
    this.uploads = Map();
  }

  public add(fileUpload: FileUpload, queueId: number): void {
    this.uploads = this.uploads.set(queueId, fileUpload);
  }

  public delete(queueId: number): void {
    this.uploads = this.uploads.delete(queueId);
  }

  public get(queueId: number): FileUpload {
    const upload = this.uploads.get(queueId);
    if (upload) {
      return upload;
    }
    throw new FileUploadDoesNotExistError(`Could not find upload ${queueId}`);
  }

  public getByEntityId(entityId: string): FileUpload {
    const foundUpload = this.getAll().find((upload: FileUpload) => upload.entityId === entityId);
    if (foundUpload) {
      return foundUpload;
    }
    throw new FileUploadDoesNotExistError(`Could not find upload with entity ID: ${entityId}`);
  }

  public getAll(): Seq<number, FileUpload> {
    return this.uploads.valueSeq();
  }
}
