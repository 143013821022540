import React, { useEffect, useRef, useState } from 'react';
import {
  InstrumentId,
  InstrumentName,
  InstrumentType
} from '../../frontend-common-libs/src/instruments/types';
import CommonInstrumentTile from '../../frontend-common-libs/src/instruments/components/recent-instruments/instrument-tile/InstrumentTile';
import InstrumentBlockTile from './InstrumentBlockTile';
import InstrumentFacade, {
  BlockEnum,
  InstrumentModelEnum,
  InstrumentStatusEnum
} from '../common/InstrumentFacade';
import { ImmutableMap, InstrumentItem } from '../../frontend-common-libs/src/common/types';

export type Props = {
  deviceId: InstrumentId;
  deviceType: InstrumentType;
  name: InstrumentName;
  status: string;
  model: string;
  instrument: ImmutableMap<InstrumentItem>;
};

function InstrumentTile(props: Props) {
  const { deviceId, name, deviceType, instrument } = props;
  const instrumentFacade = new InstrumentFacade(instrument);
  const { instrumentModel, modelName, instrumentIcon, instrumentStatus } = instrumentFacade;
  const iconAlt = instrumentStatus ? `${deviceType} ${instrumentStatus}` : `${deviceType}`;
  const icon = { src: instrumentIcon, alt: iconAlt };

  const renderBlock = (block: BlockEnum) => {
    return <InstrumentBlockTile instrument={instrument} block={block} />;
  };

  const offlineTimeout = useRef<any>();
  const [instrumentState, setInstrumentState] = useState(``);

  // After 5 minutes of no shadow/IoT message from the instrument, the
  // InstrumentFacade will change instrumentStatus to Offline.
  // The useEffect timeout renders the instrument row after the timeout period
  // to reflect updated state of the InstrumentFacade.
  useEffect(() => {
    let timeout = instrumentFacade.timeoutUntilExpiration();
    if (instrumentModel === InstrumentModelEnum.PTCTempo4848) {
      timeout = instrumentFacade.dualBlockInstrumentTimeout(timeout);
    }
    if (timeout > 0) {
      offlineTimeout.current = setTimeout(() => {
        setInstrumentState(`${InstrumentStatusEnum.Offline}`);
      }, timeout);
    }
    return () => {
      if (offlineTimeout.current) {
        setInstrumentState(``);
        clearTimeout(offlineTimeout.current);
      }
    };
  }, [instrumentStatus, instrumentState]);

  if (instrumentModel === InstrumentModelEnum.PTCTempo4848) {
    return (
      <>
        {renderBlock(BlockEnum.BlockA)}
        {renderBlock(BlockEnum.BlockB)}
      </>
    );
  }

  return (
    <CommonInstrumentTile
      id={deviceId}
      name={name}
      status={instrumentStatus ?? ''}
      modelName={modelName}
      icon={icon}
    />
  );
}
export default InstrumentTile;
