import React, { RefObject, ReactNode } from 'react';
import classnames from 'classnames';

export type ButtonAttributeType = 'submit' | 'button' | 'reset';

export interface ButtonProps {
  id: string;
  onClick?: (...args: Array<any>) => any;
  onKeyDown?: (...args: Array<any>) => any;
  children: ReactNode;
  disabled?: boolean;
  isInProgress?: boolean;
  className?: string;
  buttonRef?: RefObject<HTMLButtonElement>;
  isSelected?: boolean;
  tabIndex?: number;
  type: ButtonAttributeType;
}

function Button({
  id,
  className,
  onClick,
  onKeyDown,
  disabled,
  isInProgress,
  children,
  buttonRef,
  isSelected,
  tabIndex,
  type
}: ButtonProps) {
  return (
    <button
      id={id}
      type={type}
      className={classnames(`new-btn ${className}`, {
        'in-progress': isInProgress,
        selected: isSelected
      })}
      onClick={onClick}
      onKeyDown={onKeyDown}
      disabled={disabled || isInProgress}
      ref={buttonRef}
      tabIndex={tabIndex}
    >
      {children}
    </button>
  );
}

function SecondaryButton(props: ButtonProps) {
  const { className, children } = props;
  return (
    <Button {...props} className={classnames('secondary', className)}>
      {children}
    </Button>
  );
}

const defaultProps = {
  disabled: false,
  isInProgress: false,
  onClick: undefined,
  onKeyDown: undefined,
  className: undefined,
  buttonRef: undefined,
  isSelected: false,
  tabIndex: 0
};

Button.defaultProps = defaultProps;
SecondaryButton.defaultProps = defaultProps;

export { SecondaryButton, Button };
