import React from 'react';
import { Map, OrderedMap } from 'immutable';
import Table from '../../../frontend-common-libs/src/components/common/tables/Table';
import THead from '../../../frontend-common-libs/src/components/common/tables/THead';
import TCell from '../../../frontend-common-libs/src/components/common/tables/TCell';
import LoadableTBody from '../../../frontend-common-libs/src/components/common/tables/LoadableTBody';
import { ImmutableMap, InstrumentItem } from '../../../frontend-common-libs/src/common/types';
import { InstrumentFamilyPluginRepository } from '../../../instrument-family-plugin';
import { LoadableTBodyList } from '../../../frontend-common-libs/src/components/common/tables/Types';

export type Props = {
  instruments: OrderedMap<string, Map<string, any>>;
  statuses: Map<string, Map<string, any>>;
  fetchMoreData: (...args: Array<any>) => any;
  isLoading: boolean;
  isError: boolean;
};

function InstrumentTable(props: Props) {
  const { instruments, statuses, fetchMoreData, isLoading, isError } = props;
  const instrumentList = instruments.valueSeq().map(instrument => {
    const deviceId = instrument.get('deviceId');
    const deviceType = instrument.get('deviceType');
    const model = instrument.get('model');
    const name = instrument.get('name');
    const serial = instrument.get('serialNumber');
    const softwareVersion = instrument.get('ver');
    const details = instrument.get('details');
    // check to see if service has returned deviceStatus
    const deviceStatus = statuses && statuses.get(deviceId);
    let runName;
    let steps;
    let instrumentState;
    if (deviceStatus) {
      runName = deviceStatus.get('runName');
      steps = deviceStatus.get('steps');
      instrumentState = deviceStatus.getIn(['statusObject', 'state']);
    }

    return Map({
      id: deviceId,
      type: deviceType,
      model,
      name,
      serial,
      softwareVersion,
      details,
      runName,
      steps,
      instrumentState,
      deviceStatus
    }) as ImmutableMap<InstrumentItem>;
  });

  function renderInstrumentList(): LoadableTBodyList {
    return instrumentList.map((instrument: ImmutableMap<InstrumentItem>) => {
      const plugin = InstrumentFamilyPluginRepository.getInstance().getPluginByInstrumentType(
        instrument.get('type')
      );
      if (plugin) {
        const InstrumentRow = plugin.getInstrumentRow();
        return <InstrumentRow key={instrument.get('id')} instrument={instrument} />;
      }
      return null;
    });
  }

  return (
    <div className="flex-column-container" id="instruments-table-container">
      <Table className="flex-column-container base-table" id="instruments-table">
        <THead>
          <TCell>TYPE</TCell>
          <TCell>MODEL</TCell>
          <TCell>NAME</TCell>
          <TCell>STATUS</TCell>
          <TCell>TIME REMAINING</TCell>
          <TCell />
        </THead>
        <LoadableTBody
          fetchMoreData={fetchMoreData}
          hasMoreData={false}
          isLoading={isLoading}
          isError={isError}
          errorMsg="Error retrieving instrument information."
        >
          {renderInstrumentList()}
        </LoadableTBody>
      </Table>
    </div>
  );
}

export default InstrumentTable;
