import { DispatchType, PROJECT_DETAILS_LOADING, PROJECT_DETAILS_LOADED } from './action-types';
import { ProjectId } from '../../frontend-common-libs/src/common/project-management-types';
import { GetState } from '../../types';
import notification from '../../frontend-common-libs/src/utils/notifications';
import { isProjectDetailsLoaded, getIsProjectDetailsLoading } from '../selectors/selectors';
import { getProjectDetails as getProjectDetailsEndpoint } from '../api/project-api';
import { CbWithToken } from '../../organization-management/actions/WithRefreshToken';
import { withRefreshProjectToken } from './with-refresh-project-token';

function getProjectsDetails(projectId: ProjectId) {
  return async (dispatch: DispatchType, getState: GetState) => {
    dispatch({
      type: PROJECT_DETAILS_LOADING,
      payload: { projectId }
    });

    try {
      const getProjectDetailsCb: CbWithToken = async (accessToken: string) => {
        return getProjectDetailsEndpoint(projectId, accessToken);
      };
      const details = await withRefreshProjectToken(
        dispatch,
        getState,
        projectId,
        getProjectDetailsCb
      );
      dispatch({
        type: PROJECT_DETAILS_LOADED,
        payload: { projectId, details }
      });
    } catch (error) {
      dispatch({
        type: PROJECT_DETAILS_LOADED,
        payload: { projectId, details: undefined }
      });
      notification.error('Error retrieving project details. Please try again.');
    }
  };
}

// eslint-disable-next-line import/prefer-default-export
export function getProjectsDetailsIfNeeded(projectId: ProjectId) {
  return async (dispatch: DispatchType, getState: GetState) => {
    const state = getState();
    if (
      !isProjectDetailsLoaded(state, projectId) &&
      getIsProjectDetailsLoading(state, projectId) !== true
    ) {
      await getProjectsDetails(projectId)(dispatch, getState);
    }
  };
}
