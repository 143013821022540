import {
  PluginLink,
  PluginCreateExperimentCardProperties
} from '@biorad-lsg-tsc/instrument-family-plugin';

export default class PluginCreateExperimentCard {
  private readonly pluginCreateExperimentCardProperties: PluginCreateExperimentCardProperties;

  constructor(pluginCreateExperimentCardProperties: PluginCreateExperimentCardProperties) {
    this.pluginCreateExperimentCardProperties = pluginCreateExperimentCardProperties;
  }

  get title(): string {
    return this.pluginCreateExperimentCardProperties.title;
  }

  get instrumentThumbnail(): string {
    return this.pluginCreateExperimentCardProperties.instrumentThumbnail;
  }

  get primaryLinks(): PluginLink[] {
    return this.pluginCreateExperimentCardProperties.primaryLinks;
  }

  get secondaryLinks(): PluginLink[] {
    return this.pluginCreateExperimentCardProperties.secondaryLinks;
  }
}
