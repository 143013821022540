import React, { ReactElement, useEffect, useState } from 'react';
import SettingsNavBar from './SettingsNavBar';
import styles from '../style/setting-page.module.scss';
import ApiPortal from './ApiPortal';
import FeatureFlags, {
  FeatureFlagKeys
} from '../../frontend-common-libs/src/components/feature_flags';

export type MenuListItem = {
  title: string;
  id: string;
  component: ReactElement;
};

export default function Settings(): ReactElement {
  const [selectedSettingMenuItem, setSelectedSettingMenuItem] = useState('');
  const menuItems: MenuListItem[] = [];

  if (FeatureFlags().get(FeatureFlagKeys.BRIO_API)) {
    menuItems.push({
      title: 'API Portal',
      id: 'setting-api-portal',
      component: <ApiPortal />
    });
  }

  useEffect(() => {
    if (!selectedSettingMenuItem && menuItems.length > 0) {
      setSelectedSettingMenuItem(menuItems[0].id);
    }
  }, []);

  const renderComponent = (): ReactElement | null => {
    const menuItem = menuItems.find(({ id }) => id === selectedSettingMenuItem) as MenuListItem;
    return menuItem?.component;
  };

  return (
    <div className={styles.settingsWrapper} data-testid="setting-menu-page">
      <SettingsNavBar menuItems={menuItems} selectedMenuItem={selectedSettingMenuItem} />
      {renderComponent()}
    </div>
  );
}
