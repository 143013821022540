import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import authReducer from './auth_reducer';
import userFilesReducer from '../file-management/reducers/userfiles_reducer';
import qpcrdataReducer from './qpcrdata_reducer';
import { instrumentReducer } from '../instruments';
import protocolReducer from './protocol_reducer';
import fileOperationReducer from './file_operation_reducer';
import archiveReducer from './archive_reducer';
import protocolTemplateReducer from './protocolTemplate_reducer';
import currentCfxRunReducer from './currentCfxRun_reducer';
import userPreferencesReducer from './user_preferences_reducer';
import maintenanceMessageReducer from './maintenance_message_reducer';
import completeRegistrationReducer from './complete_registration_reducer';
import projectsReducer from '../project-management/reducers/projects_reducer';
import organizationReducer from '../organization-management/reducers/org_reducer';

const rootReducer = combineReducers({
  auth: authReducer,
  form: formReducer,
  userFiles: userFilesReducer,
  qpcrdata: qpcrdataReducer,
  protocols: protocolReducer,
  instruments: instrumentReducer,
  fileOperations: fileOperationReducer,
  archive: archiveReducer,
  protocolTemplates: protocolTemplateReducer,
  currentCfxRun: currentCfxRunReducer,
  userPreferences: userPreferencesReducer,
  maintenanceMessage: maintenanceMessageReducer,
  completeRegistration: completeRegistrationReducer,
  projects: projectsReducer,
  organizations: organizationReducer
});

export default rootReducer;
