import GradientTemperature from './gradient-temperature/GradientTemperature';
import InvalidGradient from './InvalidGradient';
import GradientTemperatureInvalid from './gradient-temperature/GradientTemperatureInvalid';

export type GradientLowerTemperature = number | null | undefined;
export type GradientRange = number | null | undefined;

export default class Gradient {
  constructor(
    rowCount: number,
    lowerTemp: GradientLowerTemperature,
    range: GradientRange,
    isValid: boolean,
    offsetTable: Array<Array<number>>,
    scTable: Array<Array<number>>
  ) {
    this.isValid = isValid;
    this.range = range;
    this.lowerTemperature = lowerTemp;
    this.rowCount = rowCount;
    this.offsetTable = offsetTable;
    this.scTable = scTable;
  }

  private readonly isValid: boolean;

  private readonly range: GradientRange;

  private readonly lowerTemperature: GradientLowerTemperature;

  private readonly rowCount: number;

  private readonly offsetTable: Array<Array<number>>;

  private readonly scTable: Array<Array<number>>;

  private static readonly meanTempVector: Array<number> = [30, 40, 50, 60, 70, 80, 90, 100];

  public getTemperatures(): GradientTemperature[] {
    if (this.isValid && this.lowerTemperature != null && this.range != null) {
      return this.calculateGradientTemperatureValues(this.lowerTemperature, this.range).map(
        (gradientTempValue: number, index: number) =>
          this.createGradientTemperature(index, gradientTempValue)
      );
    }
    return Array(this.rowCount).fill(new GradientTemperatureInvalid());
  }

  // eslint-disable-next-line class-methods-use-this
  protected createGradientTemperature(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _index: number,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _temperatureValue: number
  ): GradientTemperature {
    throw Error('Unimplemented Function. Override in child class');
  }

  private calculateGradientTemperatureValues(lowerTemperature: number, range: number): number[] {
    const firstRowTemp = lowerTemperature + range;
    const lastRowTemp = lowerTemperature;
    const lastRowIndex = this.rowCount - 1;
    const meanTemp = (firstRowTemp + lastRowTemp) / 2;
    const gradientRange = firstRowTemp - lastRowTemp;

    if (meanTemp > 100 || meanTemp < 30) {
      throw new InvalidGradient('Invalid temperature');
    }

    if (gradientRange > 24 || gradientRange <= 0) {
      throw new InvalidGradient('Invalid gradient range');
    }

    const j = Math.floor((meanTemp - 30) / 10);
    const scale: Array<number> = Array(this.rowCount).fill(0);
    const offset: Array<number> = Array(this.rowCount).fill(0);

    for (let i = 0; i < this.rowCount; i += 1) {
      scale[i] =
        ((this.scTable[i][j + 1] - this.scTable[i][j]) / 10) *
          (meanTemp - Gradient.meanTempVector[j]) +
        this.scTable[i][j];
      offset[i] =
        ((this.offsetTable[i][j + 1] - this.offsetTable[i][j]) / 10) *
          (meanTemp - Gradient.meanTempVector[j]) +
        this.offsetTable[i][j];
    }

    const rearGradLevel: number = (firstRowTemp - offset[0]) / scale[0];
    const frontGradLevel: number = (lastRowTemp - offset[lastRowIndex]) / scale[lastRowIndex];

    const rowTemps: Array<number> = Array(this.rowCount).fill(0);
    for (let i = 0; i < this.rowCount / 2; i += 1) {
      rowTemps[i] = offset[i] + scale[i] * rearGradLevel;
    }
    for (let i = this.rowCount / 2; i < this.rowCount; i += 1) {
      rowTemps[i] = offset[i] + scale[i] * frontGradLevel;
    }

    for (let i = 0; i < this.rowCount; i += 1) {
      if (rowTemps[i] > firstRowTemp) rowTemps[i] = firstRowTemp;
      if (rowTemps[i] < lastRowTemp) rowTemps[i] = lastRowTemp;
    }

    return rowTemps;
  }
}
