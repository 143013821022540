import React, { Component } from 'react';
import { Alert } from 'react-bootstrap';

export type Props = {
  error: string;
  errorId: string;
};

// eslint-disable-next-line react/prefer-stateless-function
export default class ErrorAlert extends Component<Props> {
  render() {
    const { error, errorId } = this.props;
    if (!error) return null;
    return (
      <div className="container margin-top">
        <Alert id={errorId} bsStyle="danger">
          {error}
        </Alert>
      </div>
    );
  }
}
