export type QPCRDataActionType =
  | 'qpcrdata_loaded'
  | 'qpcrdata_error'
  | 'qpcrdata_loading'
  | 'qpcrdata_save_loading'
  | 'qpcrdata_step_loaded'
  | 'qpcrdata_step_error'
  | 'qpcrdata_step_loading'
  | 'qpcrdata_run_added'
  | 'qpcrdata_run_edited'
  | 'qpcrdata_run_closed'
  | 'qpcrdata_run_is_saving';

export const QPCRDATA_LOADED = 'qpcrdata_loaded';
export const QPCRDATA_ERROR = 'qpcrdata_error';
export const QPCRDATA_LOADING = 'qpcrdata_loading';
export const QPCRDATA_SAVE_LOADING = 'qpcrdata_save_loading';

export const QPCRDATA_STEP_LOADED = 'qpcrdata_step_loaded';
export const QPCRDATA_STEP_ERROR = 'qpcrdata_step_error';
export const QPCRDATA_STEP_LOADING = 'qpcrdata_step_loading';

export const QPCRDATA_RUN_ADDED = 'qpcrdata_run_added';
export const QPCRDATA_RUN_EDITED = 'qpcrdata_run_edited';
export const QPCRDATA_RUN_CLOSED = 'qpcrdata_run_closed';
export const QPCRDATA_RUN_IS_SAVING = 'qpcrdata_run_is_saving';
