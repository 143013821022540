import React, { Component } from 'react';
import '../../styles/search-box.scss';
import SearchIcon from './assets/search-icon.svg';
import SearchIconDisabled from './assets/search-icon-disabled.svg';
import ClearSearchIconDisabled from './assets/search-delete-x-disabled.svg';

export type SearchFileCallback = (searchTerm: string) => any;

export type Props = {
  id: string;
  placeholder: string;
  onSearchInputChanged: SearchFileCallback;
  searchText?: string;
  onSearch: () => void;
  onClearSearch: () => void;
};

type State = {
  isFocus: boolean;
};

class SearchBox extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isFocus: false
    };
  }

  onInputChange = (e: React.SyntheticEvent) => {
    const target = e.target as HTMLInputElement;
    const { onSearchInputChanged } = this.props;
    onSearchInputChanged(target.value);
  };

  onInputFocus = () => {
    this.setState({
      isFocus: true
    });
  };

  onInputBlur = () => {
    this.setState({
      isFocus: false
    });
  };

  onClearClicked = () => {
    const { onClearSearch } = this.props;
    onClearSearch();
  };

  handleSearchClicked = () => {
    const { onSearch, searchText } = this.props;
    if (searchText) {
      onSearch();
    }
  };

  renderSearchIcon = () => {
    const { searchText } = this.props;
    return searchText ? (
      <img src={SearchIcon} alt="search-icon" />
    ) : (
      <img src={SearchIconDisabled} alt="search-icon-disabled" />
    );
  };

  onSearchKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      this.handleSearchClicked();
    }
  };

  renderClearIcon = () => <img src={ClearSearchIconDisabled} alt="search-clear-icon" />;

  render() {
    const { id, placeholder, searchText } = this.props;
    const { isFocus } = this.state;
    return (
      <div
        className={isFocus ? 'search-input-focus' : 'search-input'}
        onFocus={this.onInputFocus}
        onBlur={this.onInputBlur}
      >
        <input
          id={id}
          placeholder={placeholder}
          type="text"
          tabIndex={0}
          value={searchText}
          onChange={this.onInputChange}
          onKeyDown={this.onSearchKeyDown}
        />
        <button id="search-icon" tabIndex={0} onClick={this.handleSearchClicked}>
          {this.renderSearchIcon()}
        </button>
        <button id="clear-search-icon" tabIndex={0} onClick={this.onClearClicked}>
          {this.renderClearIcon()}
        </button>
      </div>
    );
  }
}

export default SearchBox;
