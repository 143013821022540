import ProtocolListRepository from '../repository/ProtocolListRepository';
import ProtocolRepository from '../repository/ProtocolRepository';
import { entityActionEnum } from '../../../frontend-common-libs/src/iot/entityActionEnum';

export default class ProtocolIotHandler {
  static onIotMessage(entityWithAction: any) {
    const { action, ...entity } = entityWithAction;
    ProtocolIotHandler.updateProtocolRepository(entity);
    ProtocolIotHandler.updateProtocolListRepository(entity, action);
  }

  static updateProtocolRepository(entity: any) {
    ProtocolRepository.instance.protocolUpdated(entity);
  }

  static updateProtocolListRepository(entity: any, action: string) {
    const projectId = entity.parent_id;
    if (!ProtocolListRepository.instance.isLoaded(projectId)) return;

    const projectProtocols = ProtocolListRepository.instance.getProjectProtocols(projectId);
    if (action === entityActionEnum.archiveEntity) projectProtocols.protocolsArchived([entity.id]);
    else if (
      action === entityActionEnum.updateEntity ||
      action === entityActionEnum.addEntity ||
      action === entityActionEnum.restoreEntity
    )
      projectProtocols.protocolUpdated(entity);
  }
}
