import _ from 'lodash';

const makeRandomString = (length: number): string =>
  _.times(length, () => _.random(35).toString(36)).join('');

export const normalizeNewlines = (text: string): string => text.replace(/(\r\n|\r)/gm, '\n');

export const replaceWhitespace = (value: string, delimiter = '_'): string =>
  value.trim().replace(/\s+/g, delimiter);

export const toHtmlId = (value: string): string => replaceWhitespace(value, '-');

export default makeRandomString;
