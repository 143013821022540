import { matchPath } from 'react-router';

const uuidRegex = '[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}';

type RoutePattern = string;
export type RouteTitle = string;
export type RoutePath = string;
export type RouteName = string;

const QPCR = '/qpcr';
const QPCR_PROTOCOL = `${QPCR}/protocol`;
const QPCR_PROTOCOL_LIST = `${QPCR_PROTOCOL}/list`;
const CREATE_QPCR_PROTOCOL = `${QPCR_PROTOCOL}/create`;
const VIEW_QPCR_PROTOCOL = `${QPCR_PROTOCOL}/:entityId(${uuidRegex})`;
const CREATE_QPCR_RUN_FROM_TEMPLATE = `${QPCR}/create-run-from-template`;
const CREATE_QPCR = `${QPCR}/create`;
const VIEW_QPCR = `${QPCR}/:entityId(${uuidRegex})`;

const namedRoutes: { [key: string]: RoutePattern } = {
  QPCR,
  VIEW_QPCR,
  QPCR_PROTOCOL,
  QPCR_PROTOCOL_LIST,
  CREATE_QPCR_PROTOCOL,
  CREATE_QPCR_RUN_PROTOCOL: `${CREATE_QPCR}/protocol`,
  CREATE_QPCR_RUN_FROM_TEMPLATE,
  CREATE_QPCR,
  CREATE_QPCR_RUN_DETAILS: `${CREATE_QPCR}/details`,
  CREATE_QPCR_RUN_PLATE: `${CREATE_QPCR}/plate`,
  VIEW_QPCR_PROTOCOL,
  VIEW_QPCR_RUN_PROTOCOL: `${VIEW_QPCR}/protocol`,
  VIEW_QPCR_RUN_DETAILS: `${VIEW_QPCR}/details`,
  VIEW_QPCR_RUN_PLATE: `${VIEW_QPCR}/plate`,
  VIEW_QPCR_RUN_RESULTS: `${VIEW_QPCR}/results`
};

export const ROUTE_NOT_FOUND = '/*';

export function getRouteName(path: RoutePath): RouteName {
  return (
    Object.keys(namedRoutes).find(
      name =>
        matchPath(path, {
          path: namedRoutes[name],
          exact: true,
          strict: false
        }) !== null
    ) || 'ROUTE_NOT_FOUND'
  );
}

export const routeTitles: { [key: string]: RouteTitle } = {
  VIEW_QPCR: 'CFX Run',
  VIEW_QPCR_PROTOCOL: 'CFX Protocol',
  VIEW_QPCR_RUN_PROTOCOL: 'CFX Run',
  QPCR_PROTOCOL_LIST: 'CFX Protocols',
  CREATE_QPCR_PROTOCOL: 'Create CFX Protocol',
  CREATE_QPCR_RUN_PROTOCOL: 'Create CFX Run',
  CREATE_QPCR_RUN_DETAILS: 'Create CFX Run',
  CREATE_QPCR_RUN_PLATE: 'Create CFX Run',
  VIEW_QPCR_RUN_DETAILS: 'CFX Run',
  VIEW_QPCR_RUN_PLATE: 'CFX Run',
  VIEW_QPCR_RUN_RESULTS: 'CFX Run',
  CREATE_QPCR_RUN_FROM_TEMPLATE: 'Create CFX Run'
};

export function getRoute(routeName: RouteName): RoutePattern {
  return namedRoutes[routeName] || ROUTE_NOT_FOUND;
}

export function getRouteTitle(routePath: RoutePath): RouteTitle {
  return routeTitles[getRouteName(routePath)] || '';
}

export default namedRoutes;
