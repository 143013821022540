import React from 'react';
import { MenuItem } from 'react-aria-menubutton';
import './styles/textMenuItem.scss';

export type Props = {
  text: string;
  id: string;
  onSelection: (...args: Array<any>) => any;
  notification?: any;
};

export default function TextMenuItem(props: Props) {
  const { text, onSelection, id, notification } = props;

  return (
    <MenuItem className="buttonmenu-item buttonmenu-text" id={id} value={onSelection}>
      <span role="presentation">{text}</span>
      {notification}
    </MenuItem>
  );
}
