import { InstrumentModelEnum, getMajorVersion } from '../common/InstrumentFacade';
import { ImmutableMap, InstrumentItem } from '../../frontend-common-libs/src/common/types';

export const MinimumSupportedMajorVersion = 3;

const devSupportedMajorVersion = 0;

export default class FleetManagementSupport {
  private static supportsReleaseReservation(softwareVersion: string | undefined): boolean {
    return getMajorVersion(softwareVersion) >= MinimumSupportedMajorVersion;
  }

  private static supportsDevelopmentReservation(softwareVersion: string | undefined): boolean {
    return getMajorVersion(softwareVersion) === devSupportedMajorVersion;
  }

  private static softwareVersionSupportsReservations(softwareVersion: string | undefined): boolean {
    return (
      FleetManagementSupport.supportsReleaseReservation(softwareVersion) ||
      FleetManagementSupport.supportsDevelopmentReservation(softwareVersion)
    );
  }

  private static modelSupportsReservations(model: string | undefined): boolean {
    return !!model && model !== InstrumentModelEnum.PTCTempo4848;
  }

  public static isInstrumentSupported(instrument: ImmutableMap<InstrumentItem>): boolean {
    const softwareVersion = instrument.get('softwareVersion');
    const model = instrument.get('model');
    return (
      FleetManagementSupport.softwareVersionSupportsReservations(softwareVersion) &&
      FleetManagementSupport.modelSupportsReservations(model)
    );
  }
}
