import UserAnalyticsClient from '../../frontend-common-libs/src/user-analytics/UserAnalyticsClient';

export type AssignProtocolEventParams = {
  numberOfInstruments: number;
  deviceTypes: string[];
  models: string[];
  protocolId: string;
};

export default class AssignProtocolButtonClickEvent {
  private static readonly ASSIGN_PROTOCOL_BUTTON_CLICK_EVENT_NAME =
    'fleet-brio-protocol-assignment-button-clicked';

  // eslint-disable-next-line class-methods-use-this
  public track(params: AssignProtocolEventParams) {
    UserAnalyticsClient.getInstance().track(
      AssignProtocolButtonClickEvent.ASSIGN_PROTOCOL_BUTTON_CLICK_EVENT_NAME,
      {
        ...params
      }
    );
  }
}
