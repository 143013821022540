export default class RunConditionsParser {
  report: any;

  public readonly protocolName: string;

  public readonly plateId: string;

  public readonly volume: string;

  public readonly method: string;

  public readonly lidTemp: string;

  constructor(runReport: any) {
    this.report = runReport;
    this.protocolName = this.getProtocolName();
    if (!this.protocolName) throw Error('Missing protocol name');
    this.plateId = this.getPlateId();
    this.volume = this.getVolume();
    this.method = this.getMethod();
    this.lidTemp = this.getLidTemp();
  }

  private getProtocolName() {
    return this.report.protocol.protocolName;
  }

  private getPlateId() {
    return this.report.plateID || '- -';
  }

  private getVolume() {
    return `${this.report.protocol.vol} ul`;
  }

  private getLidTemp() {
    const { lidTemp } = this.report.protocol;
    if (lidTemp.mode === 'custom') return `${Number(lidTemp.temp)}°C`;
    if (lidTemp.mode === 'off') return 'OFF';
    return 'default';
  }

  private getMethod() {
    if (this.report.protocol.vol === 0) return 'BLOCK';
    return 'CALC';
  }
}
