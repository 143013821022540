import { Map } from 'immutable';
import {
  makeFileNameCharacterValidator,
  makeMaximumText,
  makeTextRequired
} from '../../../../common/form_validators';
import { isNumberInRange } from '../PCREditorValidateInput';
import { LidSettingCustom } from '../../pcr_protocol_types';

type ProtocolError = string | null | undefined;

export default class ProtocolValidator {
  private validationErrors: Map<string, any>;

  private NAME_VALIDATORS = [
    makeTextRequired('Name may not be empty'),
    makeMaximumText('Name is too long', 255),
    makeFileNameCharacterValidator('Name may not include:')
  ];

  private invalidLidErrorMessage = 'Invalid lid temperature.';

  private readonly maxSteps: number;

  private readonly minReactionVolumeValue = 0;

  private readonly maxReactionVolumeValue = 125;

  private readonly minLidSettingValue = 30;

  private readonly maxLidSettingValue = 110;

  constructor(maxSteps: number) {
    this.validationErrors = Map({ errors: Map<string, any>() });
    this.maxSteps = maxSteps;
  }

  public get nameError(): ProtocolError {
    return this.validationErrors.get('nameError');
  }

  public get volumeError(): ProtocolError {
    return this.validationErrors.getIn(['errors', 'volError']) as ProtocolError;
  }

  public get lidError(): ProtocolError {
    return this.validationErrors.getIn(['errors', 'lidError']) as ProtocolError;
  }

  public get protocolError(): ProtocolError {
    return this.validationErrors.getIn(['errors', 'protocolError']) as ProtocolError;
  }

  public get errors(): Map<string, any> {
    return this.validationErrors.get('errors').entrySeq();
  }

  public validateName(name: string): void {
    let invalidNameErrorMessage: string | undefined;
    this.NAME_VALIDATORS.forEach(nameValidator => {
      invalidNameErrorMessage = invalidNameErrorMessage || nameValidator(name);
    });

    this.validationErrors = invalidNameErrorMessage
      ? this.validationErrors.set('nameError', invalidNameErrorMessage)
      : this.validationErrors.delete('nameError');
  }

  public validateVolume(reactionVolume: number | undefined): void {
    const isReactionVolumeValid = isNumberInRange(
      reactionVolume,
      this.minReactionVolumeValue,
      this.maxReactionVolumeValue
    );
    this.validationErrors = isReactionVolumeValid
      ? this.validationErrors.deleteIn(['errors', 'volError'])
      : this.validationErrors.setIn(['errors', 'volError'], 'Invalid reaction volume.');
  }

  public validateLidSetting(ledSetting: string): void {
    this.validationErrors =
      ledSetting === LidSettingCustom
        ? this.validationErrors.setIn(['errors', 'lidError'], this.invalidLidErrorMessage)
        : this.validationErrors.deleteIn(['errors', 'lidError']);
  }

  public validateLidTemperature(lidTemperature: number | undefined): void {
    const isValidLidTemperature = isNumberInRange(
      lidTemperature,
      this.minLidSettingValue,
      this.maxLidSettingValue
    );
    this.validationErrors = isValidLidTemperature
      ? this.validationErrors.deleteIn(['errors', 'lidError'])
      : this.validationErrors.setIn(['errors', 'lidError'], this.invalidLidErrorMessage);
  }

  public validateStepsSize(stepsSize: number, isPcr = false): void {
    let invalidStepsErrorMessage;
    if (stepsSize === 0) {
      invalidStepsErrorMessage = isPcr
        ? 'A protocol must contain at least one temperature or gradient step.'
        : 'A protocol must contain at least one temperature, melt, or gradient step.';
    }

    if (stepsSize > this.maxSteps) {
      invalidStepsErrorMessage = `Maximum number of protocol steps exceeded. A maximum of ${this.maxSteps} steps is allowed.`;
    }

    this.validationErrors = invalidStepsErrorMessage
      ? this.validationErrors.setIn(['errors', 'protocolError'], invalidStepsErrorMessage)
      : this.validationErrors.deleteIn(['errors', 'protocolError']);
  }

  public hasErrors(): boolean {
    return !!this.nameError || !this.errors.isEmpty();
  }
}
