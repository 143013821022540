import { Dispatch, GetState } from '../../types';
import {
  UserPreferencesType,
  NOTIFICATIONS_SETTINGS_LOADING,
  NOTIFICATIONS_SETTINGS_LOADED,
  NOTIFICATIONS_SETTINGS_ERROR,
  SET_DEVICE_TYPE,
  ADD_NOTIFICATION_SUBSCRIPTION_STARTED,
  ADD_NOTIFICATION_SUBSCRIPTION_ERROR,
  ADD_NOTIFICATION_SUBSCRIPTION_SUCCEEDED,
  DELETE_NOTIFICATION_SUBSCRIPTION_STARTED,
  DELETE_NOTIFICATION_SUBSCRIPTION_SUCCEEDED,
  DELETE_NOTIFICATION_SUBSCRIPTION_ERROR
} from './user_preferences_types';
import {
  getNotificationsSettingsList,
  getUserSubscriptions,
  subscribeToNotification as requestToSubscribeToNotification,
  unsubscribeFromNotification as requestToUnsubscribeFromNotification
} from '../../api/notifications';
import UserSubscribedToNotificationTrackingEvent from '../../user-analytics/UserSubscribedToNotificationTrackingEvent';
import UserUnsubscribedFromNotificationTrackingEvent from '../../user-analytics/UserUnsubscribedFromNotificationTrackingEvent';

type DispatchType = Dispatch<UserPreferencesType, Record<string, any> | string>;

function getNotificationSettingFromStateById(settingId: string, getState: GetState): any {
  const { userPreferences } = getState();
  const notificationsSettings = userPreferences.get('notificationsSettings');

  return (<any[]>notificationsSettings).find(setting => setting.id === settingId);
}

export function getNotificationSettingsAndUserSubscriptions() {
  return async (dispatch: DispatchType) => {
    dispatch({
      type: NOTIFICATIONS_SETTINGS_LOADING,
      payload: {}
    });

    try {
      const notificationsSettingsResponse = await getNotificationsSettingsList();
      const userSubscriptionsResponse = await getUserSubscriptions();
      dispatch({
        type: NOTIFICATIONS_SETTINGS_LOADED,
        payload: {
          notificationsSettings: notificationsSettingsResponse.data,
          userSubscriptions: userSubscriptionsResponse.data
        }
      });
    } catch (error) {
      const message = error instanceof Error ? error.message : 'Unknown error';
      dispatch({
        type: NOTIFICATIONS_SETTINGS_ERROR,
        payload: message
      });
    }
  };
}

export function getNotificationsSettingsIfNeeded() {
  return (dispatch: DispatchType, getState: GetState) => {
    const state = getState();
    if (
      !state.userPreferences.get('isLoading') &&
      state.userPreferences.get('notificationsSettings').size === 0
    ) {
      dispatch(getNotificationSettingsAndUserSubscriptions());
    }
  };
}

export function setDeviceType(notificationSettingId: string) {
  return {
    type: SET_DEVICE_TYPE,
    payload: notificationSettingId
  };
}

export function subscribeToNotification(notificationSettingId: string, userEmail: string) {
  return async (
    dispatch: Dispatch<UserPreferencesType, Record<string, any>>,
    getState: GetState
  ) => {
    try {
      dispatch({
        type: ADD_NOTIFICATION_SUBSCRIPTION_STARTED,
        payload: { notificationSettingId }
      });
      await requestToSubscribeToNotification({ notificationId: notificationSettingId, userEmail });

      const { displayText: notificationName, deviceType: instrumentType } =
        getNotificationSettingFromStateById(notificationSettingId, getState);

      new UserSubscribedToNotificationTrackingEvent(notificationName, instrumentType).track();

      dispatch({
        type: ADD_NOTIFICATION_SUBSCRIPTION_SUCCEEDED,
        payload: {}
      });
    } catch (error) {
      dispatch({
        type: ADD_NOTIFICATION_SUBSCRIPTION_ERROR,
        payload: { notificationSettingId }
      });
    }
  };
}

export function unsubscribeFromNotification(notificationSettingId: string) {
  return async (
    dispatch: Dispatch<UserPreferencesType, Record<string, any>>,
    getState: GetState
  ) => {
    try {
      dispatch({
        type: DELETE_NOTIFICATION_SUBSCRIPTION_STARTED,
        payload: { notificationSettingId }
      });
      await requestToUnsubscribeFromNotification(notificationSettingId);

      const { displayText: notificationName, deviceType: instrumentType } =
        getNotificationSettingFromStateById(notificationSettingId, getState);

      new UserUnsubscribedFromNotificationTrackingEvent(notificationName, instrumentType).track();

      dispatch({
        type: DELETE_NOTIFICATION_SUBSCRIPTION_SUCCEEDED,
        payload: {}
      });
    } catch (error) {
      dispatch({
        type: DELETE_NOTIFICATION_SUBSCRIPTION_ERROR,
        payload: { notificationSettingId }
      });
    }
  };
}
