import { fromJS, Map } from 'immutable';
import {
  INSTRUMENT_ADDED,
  INSTRUMENTS_LOADING,
  INSTRUMENTS_LOADED,
  INSTRUMENTS_ERROR,
  INSTRUMENT_STATUS_UPDATED,
  UNLINKED_INSTRUMENT
} from '../actions/instrument.types';
import { UNAUTH_USER } from '../../auth/actions/auth_types';
import { ApiAction } from '../../types';
import { jsArrayToOrderedMap } from '../../frontend-common-libs/src/utils/immutableUtils';
import {
  updateIotStateDetails,
  updateIotMetadataDetails,
  missingBlkInStatusObject
} from '../utils/instrumentUtils';

const INITIAL_STATE: Map<string, any> = fromJS({
  isLoading: false,
  instrumentsLoaded: false,
  instruments: {},
  statuses: {},
  error: ''
}) as Map<string, any>;

const onInstrumentAdded = (state: Map<string, any>, action: any) => {
  const { device } = action.payload;
  if (device) {
    const { deviceId } = device;
    // if deviceId is null or undefined, do not add the instrument
    if (deviceId) {
      return state.set(
        'instruments',
        jsArrayToOrderedMap([device], 'deviceId').merge(state.get('instruments').delete(deviceId))
      );
    }
  }
  return state;
};

const onInstrumentsLoading = (state: Map<string, any>) =>
  state.set('isLoading', true).set('error', '');

const onInstrumentsLoaded = (state: Map<string, any>, action: any) =>
  state.set('instrumentsLoaded', true).set('isLoading', false).set('instruments', action.payload);

const onInstrumentsError = (state: Map<string, any>, action: any) =>
  state.set('isLoading', false).set('error', action.payload.msg);

const onInstrumentStatusUpdated = (state: Map<string, any>, action: any) => {
  const { payload } = action;
  const newVersion = payload.getIn(['statusObject', 'version']);
  const instrumentId = payload.get('instrumentId');
  const currentVersion = state.getIn(['statuses', instrumentId, 'statusObject', 'version']) as
    | number
    | undefined;
  // If current version is greater than new version then no update is needed
  if (currentVersion && newVersion && currentVersion > newVersion) {
    return state;
  }

  const newState = state
    .setIn(['statuses', instrumentId, 'isStale'], payload.get('isStale'))
    .setIn(['statuses', instrumentId, 'runName'], payload.get('runName'))
    .setIn(['statuses', instrumentId, 'steps'], payload.get('steps'));
  let statusObject = payload.get('statusObject'); // IoT

  // previous Redux State
  const prvStatusObject = newState.getIn(['statuses', instrumentId, 'statusObject']) as Map<
    string,
    any
  >;

  if (prvStatusObject && statusObject) {
    const prvStatusObjectDetails = prvStatusObject.getIn(['state', 'reported', 'details']) as Map<
      string,
      any
    >;

    const statusObjectDetails = statusObject.getIn(['state', 'reported', 'details']) as Map<
      string,
      any
    >;

    const missingBlock = missingBlkInStatusObject(prvStatusObjectDetails, statusObjectDetails);

    if (missingBlock) {
      const updatedIotMetadataObjectDetails = updateIotMetadataDetails(
        prvStatusObject,
        statusObject,
        missingBlock
      );

      // update IoT status Object with the missing block timestamp
      statusObject = statusObject.setIn(
        ['metadata', 'reported', 'details'],
        updatedIotMetadataObjectDetails
      );

      const updatedIotStateObjectDetails = updateIotStateDetails(
        prvStatusObject,
        statusObject,
        missingBlock
      );

      // update IoT status Object with the missing block status
      statusObject = statusObject.setIn(
        ['state', 'reported', 'details'],
        updatedIotStateObjectDetails
      );
    }
  }
  if (!statusObject) return newState;
  return newState.setIn(['statuses', instrumentId, 'statusObject'], statusObject);
};

const onInstrumentUnlinked = (
  state: Map<string, any>,
  action: {
    [key: string]: any;
  }
) => {
  const { instrumentId } = action.payload;
  const updatedInstruments = state.get('instruments').delete(instrumentId);
  const newStatuses = state.get('statuses').delete(instrumentId);
  const newState = state.set('instruments', updatedInstruments).set('statuses', newStatuses);
  return newState;
};

const resetState = () => INITIAL_STATE;

const actionMap = {
  [INSTRUMENT_ADDED]: onInstrumentAdded,
  [INSTRUMENTS_LOADING]: onInstrumentsLoading,
  [INSTRUMENTS_LOADED]: onInstrumentsLoaded,
  [INSTRUMENTS_ERROR]: onInstrumentsError,
  [INSTRUMENT_STATUS_UPDATED]: onInstrumentStatusUpdated,
  [UNLINKED_INSTRUMENT]: onInstrumentUnlinked,
  [UNAUTH_USER]: resetState
};

export default function instrumentReducer(
  state: Map<string, any> = INITIAL_STATE,
  action: ApiAction<any, any> | undefined = undefined
): Map<string, any> {
  if (!action) return state;
  return actionMap[action.type] ? actionMap[action.type](state, action) : state;
}
