import React, { Component } from 'react';
import { fromJS, List } from 'immutable';
import LoadableTBody from '../../../frontend-common-libs/src/components/common/tables/LoadableTBody';

export type Props = {
  tableRows: List<{
    [key: string]: any;
  }>;
  chunkSize?: number;
};
export type State = {
  loadedRows: List<{
    [key: string]: any;
  }>;
  hasMoreData: boolean;
};

const StartChunkSize = 500;

export default class RunLogTableImpl extends Component<Props, State> {
  chunkSize = StartChunkSize;

  static defaultProps = {
    chunkSize: StartChunkSize
  };

  constructor(props: Props) {
    super(props);
    if (props.chunkSize != null) this.chunkSize = props.chunkSize;
    this.state = {
      loadedRows: fromJS([]) as List<any>,
      hasMoreData: true
    };
  }

  componentDidMount() {
    this.fetchMoreData();
  }

  makeHeaders = () => {
    return (
      <>
        <th key="0" className="date-col" id="date-header">
          Date
        </th>
        <th key="1" className="message-col" id="message-header">
          Message
        </th>
        <th key="2" className="severity-col" id="severity-header">
          Severity
        </th>
        <th key="3" className="log-col" id="log-header">
          Log
        </th>
        <th key="4" className="level-col" id="level-header">
          Log Level
        </th>
      </>
    );
  };

  makeRow = (rowData: List<any>, rowIndex: number) => {
    const id = `log-tr-${rowIndex + 1}`;

    return (
      <tr className="br-table-row" id={id} key={rowIndex}>
        <td id={`${id}-date`} key={`${id}-date`} className="date-col">
          {rowData.getIn(['ts']) as string}
        </td>
        <td id={`${id}-message`} key={`${id}-message`} className="message-col">
          {rowData.getIn(['msg']) as string}
        </td>
        <td id={`${id}-severity`} key={`${id}-severity`} className="severity-col">
          {rowData.getIn(['sev']) as string}
        </td>
        <td id={`${id}-log`} key={`${id}-log`} className="log-col">
          {rowData.getIn(['lgNm']) as string}
        </td>
        <td id={`${id}-level`} key={`${id}-level`} className="level-col">
          {rowData.getIn(['level']) as string}
        </td>
      </tr>
    );
  };

  fetchMoreData = () => {
    const { tableRows } = this.props;
    const { loadedRows } = this.state;
    const currRowNum = loadedRows.size;
    const hasMoreData = currRowNum < tableRows.size;
    const newRows = tableRows.slice(0, currRowNum + this.chunkSize);
    this.chunkSize *= 2;
    this.setState({ loadedRows: newRows, hasMoreData });
  };

  render() {
    const { loadedRows, hasMoreData } = this.state;

    return (
      <div className="br-data-table log-table">
        <table>
          <thead>
            <tr>{this.makeHeaders()}</tr>
          </thead>
          <LoadableTBody
            fetchMoreData={this.fetchMoreData}
            hasMoreData={hasMoreData}
            isLoading={false}
            isError={false}
            errorMsg="Error retrieving log."
          >
            {/* @ts-ignore */}
            {loadedRows.map((rowData, index) => this.makeRow(rowData, index))}
          </LoadableTBody>
        </table>
      </div>
    );
  }
}
