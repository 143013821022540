import React from 'react';
import ContentLoader from 'react-content-loader';

export type SkeletonLoaderProps = {
  readonly y?: string;
  readonly x?: string;
  readonly rx?: string;
  readonly ry?: string;
  readonly width?: string;
  readonly height?: string;
  readonly viewBox?: string;
  readonly style?: React.CSSProperties;
};

export default function RectangularSkeletonLoader(props: SkeletonLoaderProps) {
  const { viewBox, style, y, x, rx, ry, width, height } = props;

  const viewBoxStyle = viewBox ?? '0 -2 50 5';
  const containerStyle = style ?? {};
  const rectStyle = {
    y: y ?? '0',
    x: x ?? '0',
    rx: rx ?? '0',
    ry: ry ?? '0',
    width: width ?? '50%',
    height: height ?? '3'
  };

  return (
    <div style={containerStyle}>
      <ContentLoader viewBox={viewBoxStyle}>
        <rect {...rectStyle} />
      </ContentLoader>
    </div>
  );
}
