import uuidRegex from '../../frontend-common-libs/src/common/routes/regex';
import { RoutePattern, RouteTitle, USER } from '../../core/routes';

export enum OrgTab {
  members = 'members',
  projects = 'projects'
}

export const orgTabsRegex = (Object.values(OrgTab) as string[]).reduce(
  (accumulator, val) => `${accumulator}|${val}`
);

export const organizationsRoutes: { [key: string]: RoutePattern } = {
  USER_ORGANIZATIONS: `${USER}/organization/list`,
  EDIT_ORGANIZATION: `${USER}/organization/:orgId(${uuidRegex})/:orgTab(${orgTabsRegex})`
};

export default organizationsRoutes;

export const orgRouteTitles: { [key: string]: RouteTitle } = {
  USER_ORGANIZATIONS: 'Organizations',
  EDIT_ORGANIZATION: 'Organization'
};
