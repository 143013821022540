import React, { PureComponent } from 'react';
import { Set } from 'immutable';
import { Typeahead, TypeaheadMenu, Highlighter } from 'react-bootstrap-typeahead';
import { intlCollator } from '../../../frontend-common-libs/src/utils/commonUtils';
import makeRandomString from '../../../frontend-common-libs/src/common/strings';

export type Props = {
  onChange: (...args: Array<any>) => any;
  value?: string;
  keywords: Set<string>;
  id?: string;
  placeholder?: string;
  maxResults?: number;
  className?: string;
};

class AutoSuggest extends PureComponent<Props> {
  static defaultProps = {
    id: undefined,
    value: undefined,
    keywords: Set(),
    placeholder: undefined
  };

  static positionalSort = (value: string) => (a: string, b: string) => {
    const aLower = a.toLowerCase();
    const bLower = b.toLowerCase();
    const valueLower = value.toLowerCase();
    const queryPosA = aLower.indexOf(valueLower);
    const queryPosB = bLower.indexOf(valueLower);
    if (queryPosA !== queryPosB) {
      return queryPosA - queryPosB;
    }
    return intlCollator.compare(aLower, bLower);
  };

  maxResults = 25;

  typeaheadRef: React.RefObject<Typeahead<any>> = React.createRef();

  handleChange = (value: string) => {
    const { onChange } = this.props;
    onChange(value);
  };

  handleSelect = (val: string[]) => {
    if (val.length) {
      const { onChange } = this.props;
      onChange(val[0]);
    }
  };

  // The typeahead input field has an arrow icon to the right of it (added via css)
  // When clicking the arrow icon focus is sent to the typeahead input field
  // using focusInput method
  focusInput = () => {
    const ref = this.typeaheadRef;
    if (ref && ref.current) {
      // @ts-ignore
      ref.current.getInstance().focus();
    }
  };

  renderMenu = (
    results: string[],
    menuProps: {
      [key: string]: any;
    }
  ) => {
    const { keywords } = this.props;
    // Don't display the menu if there are keywords but no results
    if (results && !results.length && keywords && keywords.size) return null;
    const paginate = results.splice(this.maxResults); // item at maxResults is a pagination object
    const { value } = this.props;
    const sortedResults = value ? results.sort(AutoSuggest.positionalSort(value)) : results;
    return (
      <TypeaheadMenu
        id="typeahead-menu"
        className="br-select-menu"
        {...menuProps}
        options={sortedResults.concat(paginate)}
      />
    );
  };

  // show tooltip in case the option is too long and ellipses
  renderMenuItemChildren = (
    option: string,
    props: {
      [key: string]: any;
    },
    index: number
  ) => (
    <span id={`suggestion-item_${index}`} title={option}>
      <Highlighter search={props.text}>{option}</Highlighter>
    </span>
  );

  render() {
    const {
      value,
      id,
      placeholder,
      keywords,
      onChange,
      maxResults: maxResultsProp,
      className,
      ...rest
    } = this.props;
    const defaultPlaceholder = keywords && keywords.size ? 'Enter/Select' : 'Enter';
    return (
      <div aria-hidden="true" onClick={this.focusInput}>
        <Typeahead
          ref={this.typeaheadRef}
          id={id && `${id}-suggestions`}
          className={className || 'br-select'}
          inputProps={{
            id,
            className: 'br-select-display',
            autoComplete: 'off',
            name: makeRandomString(10)
          }}
          onInputChange={this.handleChange}
          onChange={this.handleSelect}
          options={keywords && keywords.toArray()}
          selected={value ? [value] : []}
          placeholder={placeholder || defaultPlaceholder}
          renderMenu={this.renderMenu}
          renderMenuItemChildren={this.renderMenuItemChildren}
          maxResults={maxResultsProp || this.maxResults}
          emptyLabel={keywords && keywords.size ? false : ' '} // space used to show empty menu
          {...rest}
        />
      </div>
    );
  }
}

export default AutoSuggest;
