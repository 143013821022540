import React from 'react';
import { RouteTitle } from '../../../../plugin-types';
import FeatureFlags, { FeatureFlagKeys } from '../../feature_flags';

export type Props = {
  routeTitle: RouteTitle;
  className: string;
};

export default function PageTitle({ routeTitle, className }: Props) {
  if (routeTitle === 'Fleet' && !FeatureFlags().get(FeatureFlagKeys.FLEET_MANAGEMENT)) {
    return null;
  }

  return routeTitle ? (
    <h1 id="page-title" className={className}>
      {routeTitle}
    </h1>
  ) : null;
}
