import React, { Component } from 'react';

export type Props = {
  observeElement: (...args: Array<any>) => any;
  fetchMoreData: (...args: Array<any>) => any;
  hasMoreData: boolean;
  isLoading: boolean;
  isError: boolean;
  errorMsg: string;
};

export default class LoadingRow extends Component<Props> {
  // eslint-disable-next-line react/no-unused-class-component-methods
  onIntersectionObserved(): void {
    const { fetchMoreData } = this.props;
    if (this.showErrorCondition()) return;
    fetchMoreData();
  }

  showErrorCondition() {
    const { isLoading, isError } = this.props;
    return !isLoading && isError;
  }

  renderLoading() {
    const { isLoading, observeElement } = this.props;
    return isLoading ? (
      <td className="fa fa-spinner fa-spin fa-fw" id="spinner-row-loader" />
    ) : (
      <td ref={observeElement} />
    );
  }

  renderErrorRetry() {
    const { fetchMoreData, errorMsg } = this.props;
    return (
      <td>
        <div className="error-row">
          <p>{errorMsg}</p>
          <button id="reload-files" type="button" className="button-link" onClick={fetchMoreData}>
            Retry
          </button>
        </div>
      </td>
    );
  }

  render() {
    const { hasMoreData } = this.props;
    const errorLoadingFiles = this.showErrorCondition();
    const showRow = hasMoreData || errorLoadingFiles;
    return (
      showRow && (
        <tr className="spinner-row">
          {errorLoadingFiles && this.renderErrorRetry()}
          {hasMoreData && !errorLoadingFiles && this.renderLoading()}
        </tr>
      )
    );
  }
}
