import { fromJS, Map } from 'immutable';
import { UserPermissions } from '@biorad-lsg-tsc/organization';
import { ReduxState } from '../../types';
import { NullableBoolean, Nullable } from '../../frontend-common-libs/src/common/nullable-types';

export function isLoginTokenLoading({ organizations }: ReduxState): boolean {
  return organizations.get('loadingLoginAccessToken') === true;
}

export function getLoginInfo({ organizations }: ReduxState): any {
  return organizations.getIn(['loginInfo']);
}

export function getLoginUserToken({ organizations }: ReduxState): any {
  return organizations.getIn(['loginInfo', 'userToken', 'accessToken']);
}

export function getLoginRefreshToken({ organizations }: ReduxState): string | undefined {
  return organizations.getIn(['loginInfo', 'userToken', 'refreshToken']) as string | undefined;
}

export function isOrgTokenLoading({ organizations }: ReduxState, orgId: string): boolean {
  return organizations.getIn(['orgDetails', orgId, 'loadingOrgAccessToken']) === true;
}

export function getOrgUserToken({ organizations }: ReduxState, orgId: string): string | undefined {
  return organizations.getIn(['orgDetails', orgId, 'userToken', 'accessToken']) as
    | string
    | undefined;
}

export function getOrgRefreshToken(
  { organizations }: ReduxState,
  orgId: string
): string | undefined {
  return organizations.getIn(['orgDetails', orgId, 'userToken', 'refreshToken']) as
    | string
    | undefined;
}

export function getOrgUserPermissions(
  { organizations }: ReduxState,
  orgId: string
): Nullable<UserPermissions> {
  return organizations.getIn(['orgDetails', orgId, 'userPermissions']) as Nullable<UserPermissions>;
}

export function getIsOrganizationsLoading({ organizations }: ReduxState): NullableBoolean {
  return organizations.getIn(['organizations', 'loading']) as NullableBoolean;
}

export function isOrganizationsLoaded({ organizations }: ReduxState): boolean {
  return organizations.getIn(['organizations', 'organizations']) != null;
}

export function getOrganizations({ organizations }: ReduxState): Map<string, any> {
  return (
    (organizations.getIn(['organizations', 'organizations']) as Map<string, any>) || fromJS([])
  );
}

export function getActiveOrg({ organizations }: ReduxState): string {
  return organizations.getIn(['organizations', 'activeOrgId']) as string;
}

export function getIsOrgDetailsLoading(
  { organizations }: ReduxState,
  orgId: string
): NullableBoolean {
  return organizations.getIn(['orgDetails', orgId, 'loading']) as NullableBoolean;
}

export function isOrgDetailsLoaded({ organizations }: ReduxState, orgId: string): boolean {
  return organizations.getIn(['orgDetails', orgId, 'details']) != null;
}

export function getOrgDetails({ organizations }: ReduxState, orgId: string): Map<string, any> {
  return organizations.getIn(['orgDetails', orgId, 'details']) as Map<string, any>;
}
