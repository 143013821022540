import AWSIot from 'aws-iot-device-sdk';
import IOTCredentialsProvider from './IOTCredentialsProvider';
import CredentialsRetryProvider from './credentials/credentials-retry-provider';
import InstrumentGroupCredentials from './instrument-group-credentials';
import { IOT_MESSAGE_BROKER } from '../config/config';
import { guid } from '../utils/commonUtils';

type InstrumentWebSocket = {
  instrumentId: string;
  expired: number;
  accessKeyId: string | null | undefined;
  secretAccessKey: string | null | undefined;
  sessionToken: string | null | undefined;
  ws?: AWSIot.thingShadow;
};

export default class ReservationShadowWebsocketRepository {
  private static _instance: ReservationShadowWebsocketRepository | undefined = undefined;

  private instrumentWebsocket: InstrumentWebSocket[] = [];

  public static get instance(): ReservationShadowWebsocketRepository {
    if (this._instance == null) this._instance = new ReservationShadowWebsocketRepository();
    return this._instance;
  }

  public findInstrument(instrumentId: string): InstrumentWebSocket | undefined {
    return this.instrumentWebsocket.find(instrument => instrument.instrumentId === instrumentId);
  }

  public findInstrumentIndex(instrumentId: string): number {
    return this.instrumentWebsocket.findIndex(
      instrument => instrument.instrumentId === instrumentId
    );
  }

  public removeExpiredInstruments(): InstrumentWebSocket[] {
    return this.instrumentWebsocket.filter(instrument => {
      if (
        instrument.expired &&
        instrument.ws !== undefined &&
        ReservationShadowWebsocketRepository.isWsExpired(instrument.expired)
      ) {
        instrument?.ws.end();
        return false;
      }
      return true;
    });
  }

  public async getInstrumentWebsocket(instrumentId: string): Promise<AWSIot.thingShadow> {
    const instrumentSocket = this.findInstrument(instrumentId);

    if (instrumentSocket === undefined) {
      return this.createInstrumentWebsocket(instrumentId, true);
    }

    if (ReservationShadowWebsocketRepository.isWsExpired(instrumentSocket.expired)) {
      this.instrumentWebsocket = this.removeExpiredInstruments();
      return this.createInstrumentWebsocket(instrumentId, true);
    }

    if (instrumentSocket.ws === undefined) {
      return this.createInstrumentWebsocket(instrumentId, false);
    }

    return instrumentSocket?.ws as AWSIot.thingShadow;
  }

  public async closeInstrumentWebsocket(instrumentId: string): Promise<void> {
    const instrumentSocket = this.findInstrument(instrumentId);
    if (instrumentSocket?.ws) {
      instrumentSocket?.ws.end();
      const instrumentIndex = this.findInstrumentIndex(instrumentId);
      if (instrumentIndex !== -1) {
        this.instrumentWebsocket[instrumentIndex].ws = undefined;
      }
    }
  }

  public static isWsExpired(expired: number): boolean {
    return Date.now() >= expired;
  }

  private setInstrumentWebsocket(instrumentId: string, ws: AWSIot.thingShadow): void {
    const index = this.findInstrumentIndex(instrumentId);

    if (index !== -1) {
      this.instrumentWebsocket[index].ws = ws;
    }
  }

  private static async getCredentials(): Promise<InstrumentGroupCredentials[]> {
    const iotCredentialsProvider: IOTCredentialsProvider = new IOTCredentialsProvider();
    const credentialsRetryProvider = new CredentialsRetryProvider(iotCredentialsProvider, 1);
    const credentials = await credentialsRetryProvider.resolve();
    const { instrumentGroupCredentialsCollection } = credentials;
    return instrumentGroupCredentialsCollection as InstrumentGroupCredentials[];
  }

  private static createWebsocket(
    accessKeyId: string | null | undefined,
    secretKey: string | null | undefined,
    sessionToken: string | null | undefined
  ): AWSIot.thingShadow {
    const clientId = guid();
    // @ts-ignore
    return AWSIot.thingShadow({
      host: IOT_MESSAGE_BROKER.ENDPOINT,
      clientId,
      protocol: 'wss',
      maximumReconnectTimeMs: 8000,
      accessKeyId,
      secretKey,
      sessionToken
    });
  }

  public storeAllInstrumentCredentials(instrumentCredentials: InstrumentGroupCredentials[]): void {
    const oneHourInMs = 60 * 60 * 1000;
    const expired = Date.now() + oneHourInMs;
    instrumentCredentials.forEach((instrumentCredential: InstrumentGroupCredentials) => {
      const { accessKeyId, secretAccessKey, sessionToken } = instrumentCredential.credentials;
      instrumentCredential.credentials.instrumentIds.forEach((instrument: string) => {
        if (this.findInstrument(instrument) === undefined) {
          this.instrumentWebsocket.push({
            instrumentId: instrument,
            expired,
            accessKeyId,
            secretAccessKey,
            sessionToken
          });
        }
      });
    });
  }

  private async createInstrumentWebsocket(
    instrumentId: string,
    storeCredentials: boolean
  ): Promise<AWSIot.thingShadow> {
    if (storeCredentials) {
      const credentials = await ReservationShadowWebsocketRepository.getCredentials();
      this.storeAllInstrumentCredentials(credentials);
    }

    const instrumentObj = this.findInstrument(instrumentId) as InstrumentWebSocket;
    const thingShadow = ReservationShadowWebsocketRepository.createWebsocket(
      instrumentObj.accessKeyId,
      instrumentObj.secretAccessKey,
      instrumentObj.sessionToken
    );
    this.setInstrumentWebsocket(instrumentId, thingShadow);

    return thingShadow;
  }
}
