import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/run-details.scss';
import RunParser from '../report/run-report/sections-parsing/RunParser';
import DetailsHeaderValue from '../../../../frontend-common-libs/src/components/common/run/DetailsHeaderValue';
import Instrument from '../../../../frontend-common-libs/src/components/common/run/Instrument';
import { FileName } from '../../../../frontend-common-libs/src/components/common/run/FileName';
import { RunBarcode } from '../../../../frontend-common-libs/src/components/common/run/RunBarcode';
import { RunNotes } from '../../../../frontend-common-libs/src/components/common/run/RunNotes';
import { SecondaryButton } from '../../../../frontend-common-libs/src/components/common/buttons';
import RunRepository from '../../repository/RunRepository';
import Loader from '../../../../frontend-common-libs/src/components/common/Loader';
import notification from '../../../../frontend-common-libs/src/utils/notifications';
import { RunStatus } from './RunStatus';
import RunEdits from './RunEdits';
import conventionalPcrRoutes from '../../../routes';
import coreRoutes from '../../../../core/routes';
import { getInstrumentIcon } from '../../../common/PTCTempoInstrumentIcon';

export type Props = {
  entityId: string;
  runParser: RunParser;
  runEdits: RunEdits;
  updateRun?: (entityId: string, body: any) => any;
  disabled?: boolean;
};

export type State = {
  isSaveInProgress: boolean;
};

export default class RunDetails extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isSaveInProgress: false
    };
  }

  saveRun = async () => {
    const { entityId, runEdits, updateRun } = this.props;
    const { fileName, runBarcode, runNotes } = runEdits;
    if (!updateRun) return;
    try {
      this.setState({ isSaveInProgress: true });
      const runInfo = { plateId: runBarcode, notes: runNotes };
      const body = {
        name: fileName,
        ...runInfo
      };

      const file = await updateRun(entityId, body);

      runEdits.runSaved();
      this.setState({
        isSaveInProgress: false
      });

      RunRepository.instance.runSaved(file, runInfo);
    } catch (e) {
      notification.error('Error saving run');
      this.setState({ isSaveInProgress: false });
    }
  };

  renderLeftPanel() {
    const { runParser, runEdits, disabled } = this.props;
    const {
      fileName,
      fileNameError,
      runBarcode,
      runBarcodeError,
      originalNotes,
      runNotesError,
      runNotes
    } = runEdits;
    const { protocolName, volume, method, lidTemp } = runParser.runConditionsParser;
    return (
      <div className="left-panel">
        <FileName
          fileName={fileName}
          setFileName={runEdits.setFileName}
          fileNameError={fileNameError}
          disabled={disabled}
        />
        <DetailsHeaderValue header="Protocol" value={protocolName} headerStyle="control-label" />
        <div className="row-content">
          <RunBarcode
            runBarcode={runBarcode}
            setRunBarcode={runEdits.setBarcode}
            runBarcodeError={runBarcodeError}
            disabled={disabled}
          />
        </div>
        <div className="row-content">
          <DetailsHeaderValue header="Method" value={method} headerStyle="control-label" />
          <DetailsHeaderValue header="Sample Volume" value={volume} headerStyle="control-label" />
          <DetailsHeaderValue
            header="Lid-Temperature"
            value={lidTemp}
            headerStyle="control-label"
          />
        </div>
        <div className="run-notes">
          <RunNotes
            originalNotes={originalNotes}
            setRunNotes={runEdits.setNotes}
            runNotesError={runNotesError}
            runNotes={runNotes}
            disabled={disabled}
          />
        </div>
        {this.renderSaveButton()}
      </div>
    );
  }

  renderRightPanel = () => {
    const { entityId, runParser } = this.props;
    const { startDateTime, endDateTime, duration, userName, runStatus, runErrorState } =
      runParser.infoParser;
    const {
      serialNumber,
      instrumentName,
      instrumentType,
      softwareVersion,
      firmwareVersion,
      blockName
    } = runParser.thermalCyclerInfoParser;

    const instrumentIcon = getInstrumentIcon(instrumentType);

    return (
      <div className="right-panel">
        <div className="column-content">
          <RunStatus runStatus={runStatus} runErrorState={runErrorState} />
          <DetailsHeaderValue header="Time Started" value={startDateTime} />
          <DetailsHeaderValue header="Time Ended" value={endDateTime} />
          <DetailsHeaderValue header="Time Elapsed" value={duration} />
          <DetailsHeaderValue header="User" value={userName} />
          <Link
            id="run-report"
            to={`${coreRoutes.APP}${conventionalPcrRoutes.PCR}/${entityId}/report`}
            className="run-report-link"
            target="_blank"
          >
            Run Report
          </Link>
        </div>
        <div className="column-content">
          <DetailsHeaderValue
            header="Error Summary"
            value={runParser.errorSummaryParser.errorText}
          />
        </div>
        <div className="column-content last-column-content">
          <Instrument image={instrumentIcon} model={instrumentType} name={instrumentName} />
          <DetailsHeaderValue header="Serial Number" value={serialNumber} />
          <DetailsHeaderValue header="Block Name" value={blockName} />
          <DetailsHeaderValue header="Software Version" value={softwareVersion} />
          <DetailsHeaderValue header="Main Firmware Version" value={firmwareVersion} />
        </div>
      </div>
    );
  };

  renderSaveButton = () => {
    const { isSaveInProgress } = this.state;
    const { runEdits, disabled } = this.props;
    return (
      <>
        <SecondaryButton
          id="save-run"
          type="button"
          className="save-button"
          onClick={this.saveRun}
          disabled={disabled || runEdits.isSaveDisable()}
          isInProgress={isSaveInProgress}
        >
          Save
        </SecondaryButton>
        {isSaveInProgress && <Loader />}
      </>
    );
  };

  render() {
    return (
      <div className="run-details">
        {this.renderLeftPanel()}
        <div className="vertical-separator" />
        {this.renderRightPanel()}
      </div>
    );
  }
}
