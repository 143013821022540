import UserAnalyticsClient from '../../frontend-common-libs/src/user-analytics/UserAnalyticsClient';

export type UnreserveInstrumentsEventParams = {
  numberOfInstruments: number;
  deviceTypes: string[];
  models: string[];
};

export default class UnreserveInstrumentsButtonClickEvent {
  private static readonly UNRESERVE_INSTRUMENTS_BUTTON_CLICK_EVENT_NAME =
    'fleet-brio-unreserve-button-clicked';

  // eslint-disable-next-line class-methods-use-this
  public track(params: UnreserveInstrumentsEventParams) {
    UserAnalyticsClient.getInstance().track(
      UnreserveInstrumentsButtonClickEvent.UNRESERVE_INSTRUMENTS_BUTTON_CLICK_EVENT_NAME,
      {
        ...params
      }
    );
  }
}
