import UserAnalyticsClient from '../frontend-common-libs/src/user-analytics/UserAnalyticsClient';

export default class QPcrRunCreatedTrackingEvent {
  public constructor(
    runId: string,
    plateSize: number,
    scanMode: string,
    saveSource: string,
    isRunFromTemplate = false
  ) {
    this.runId = runId;
    this.plateSize = plateSize;
    this.scanMode = scanMode;
    this.saveSource = saveSource;
    this.isRunFromTemplate = isRunFromTemplate;
  }

  private readonly runId: string;

  private readonly plateSize: number;

  private readonly scanMode: string;

  private readonly saveSource: string;

  private readonly isRunFromTemplate: boolean;

  public track() {
    const params = {
      runId: this.runId,
      plateSize: this.plateSize,
      scanMode: this.scanMode,
      saveSource: this.saveSource,
      isRunFromTemplate: this.isRunFromTemplate
    };
    UserAnalyticsClient.getInstance().track('qpcr-run-created', params);
  }
}
