import UserAnalyticsClient from '../../frontend-common-libs/src/user-analytics/UserAnalyticsClient';

export default class UserIsVerifiedEvent {
  private static EVENT_NAME = 'user-is-verified';

  private userDomain: string;

  public constructor(userDomain: string) {
    this.userDomain = userDomain;
  }

  // eslint-disable-next-line class-methods-use-this
  public track(): void {
    UserAnalyticsClient.getInstance().track(UserIsVerifiedEvent.EVENT_NAME, {
      userDomain: this.userDomain
    });
  }
}
