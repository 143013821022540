import React, { ReactElement, ReactNode } from 'react';

interface SearchStringFormatterProps {
  children: ReactNode;
}

export default function SearchStringFormatter({
  children
}: SearchStringFormatterProps): ReactElement {
  return <div>{children}</div>;
}
