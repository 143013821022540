import { fromJS, Map } from 'immutable';
import Plate from '../../../../helpers/Plate';
import { numToChar } from '../../../../frontend-common-libs/src/utils/commonUtils';
import { WellType } from '../../../../utils/microplateUtils';

export type SamplePlaceholder = {
  name: string;
  wellIds: string[];
};

export default class SamplePlaceholderGenerator {
  private readonly plate: Map<string, any>;

  private sampleMapping: Map<string, number>;

  private readonly samplePlaceholders: SamplePlaceholder[] = [];

  constructor(plate: Map<string, any>) {
    this.plate = plate;
    // @ts-ignore
    this.sampleMapping = Map();
    this.samplePlaceholders = [];
  }

  public generate() {
    for (let row = 1; row <= this.rows; row += 1) {
      for (let col = 1; col <= this.columns; col += 1) {
        const wellName = SamplePlaceholderGenerator.getWellName(row, col);
        const well = this.getWell(wellName);
        if (well && SamplePlaceholderGenerator.isWellTypeUnknown(well)) {
          this.addWellToPlaceholder(wellName, well);
        }
      }
    }
    return fromJS(this.samplePlaceholders);
  }

  private get rows() {
    return Plate.rows(this.plate);
  }

  private get columns() {
    return Plate.columns(this.plate);
  }

  private getWell(wellName: string): Map<string, any> {
    // @ts-ignore
    return this.plate.getIn(['wells', wellName]);
  }

  private addWellToPlaceholder(wellName: string, well: Map<string, any>) {
    const sampleName: string = well.get('sample');
    const samplePlaceholder = this.getPlaceHolder(sampleName);
    samplePlaceholder.wellIds.push(wellName);
  }

  private getPlaceHolder(sampleName: string) {
    if (!this.sampleMapping.has(sampleName)) {
      this.addSamplePlaceholder(sampleName);
    }
    const samplePlaceholderIndex: number = this.sampleMapping.get(sampleName) as number;
    return this.samplePlaceholders[samplePlaceholderIndex];
  }

  private addSamplePlaceholder(sampleName: string) {
    const index = this.samplePlaceholders.length;
    this.samplePlaceholders.push({
      name: `sample-${index + 1}`,
      wellIds: []
    });
    this.sampleMapping = this.sampleMapping.set(sampleName, index);
  }

  private static getWellName(row: number, col: number) {
    return numToChar(row) + col;
  }

  private static isWellTypeUnknown(well: Map<string, any>) {
    return well.get('type') === WellType.UNKNOWN;
  }
}
