import React from 'react';
import Spinner from './Spinner';

const Loader = () => (
  <div className="row">
    <div className="col-md-12">
      <Spinner className="fa-spin-center fa-3x fa-fw" />
      <span className="sr-only">Loading...</span>
    </div>
  </div>
);

export default Loader;
