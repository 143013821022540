import React, { HTMLProps, ReactElement, ReactNode } from 'react';
import styles from '../styles/aria-auto-suggest-input.modules.scss';

export interface InputLabelProps {
  labelProps: HTMLProps<HTMLLabelElement>;
  children: ReactNode;
}

export default function InputLabel({ labelProps, children }: InputLabelProps): ReactElement {
  return (
    <label className={styles['auto-suggest-input-label']} {...labelProps}>
      {children}
    </label>
  );
}
