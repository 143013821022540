import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Map, Seq } from 'immutable';
import Loader from '../../frontend-common-libs/src/components/common/Loader';
import {
  getArchivedEntitiesIfNeeded as getArchivedEntitiesIfNeededAction,
  restoreUserEntities as restoreUserEntitiesAction
} from '../../actions/archive_actions';
import ArchiveTable from './ArchiveTable';
import { ReduxState } from '../../types';
import { getSelectedProjectId } from '../../project-management';
import { ProjectId } from '../../frontend-common-libs/src/common/project-management-types';
import FileBreadcrumb from '../../frontend-common-libs/src/components/common/context-bar/FileBreadcrumb';
import {
  canEditFilesInProject,
  getSelectedProjectName,
  isOrgProjectsLoaded
} from '../../project-management/selectors/selectors';

export interface Props {
  isLoading: boolean;
  entities: Seq.Indexed<Map<string, Object>>;
  lastSeen: string;
  errorMessage: string;
  getArchivedEntitiesIfNeeded: typeof getArchivedEntitiesIfNeededAction;
  restoreUserEntities: typeof restoreUserEntitiesAction;
  staleData: boolean;
  projectId: ProjectId;
  projectName?: string;
  canEditProjectFiles?: boolean;
  orgProjectsLoaded: boolean;
}

export class ArchiveImpl extends Component<Props> {
  componentDidMount() {
    const { entities, staleData } = this.props;
    if (!entities.size || staleData) {
      this.fetchMore();
    }
  }

  componentDidUpdate() {
    const { errorMessage, isLoading, staleData } = this.props;
    if (errorMessage === '' && !isLoading && staleData) this.fetchMore();
  }

  private fetchMore = () => {
    const { getArchivedEntitiesIfNeeded, lastSeen, orgProjectsLoaded } = this.props;
    if (!orgProjectsLoaded) return;
    getArchivedEntitiesIfNeeded(lastSeen);
  };

  render() {
    const {
      isLoading,
      entities,
      lastSeen,
      errorMessage,
      restoreUserEntities,
      staleData,
      projectName,
      canEditProjectFiles,
      orgProjectsLoaded
    } = this.props;
    if (!orgProjectsLoaded || (isLoading && staleData)) {
      return <Loader />;
    }

    return (
      <>
        <div className="table-breadcrumb" id="breadcrumb-archive">
          <FileBreadcrumb id="my-archive" filePath="Archive" projectName={projectName} />
        </div>
        <ArchiveTable
          entities={entities}
          fetchMoreData={this.fetchMore}
          hasMoreData={!!lastSeen}
          isLoading={isLoading}
          isError={!!errorMessage}
          restoreUserEntities={restoreUserEntities}
          canEditProjectFiles={canEditProjectFiles}
        />
      </>
    );
  }
}

function mapStateToProps(state: ReduxState) {
  const projectId = getSelectedProjectId(state);
  const projectArchive = state.archive.getIn(['projects', projectId]) as Map<string, any>;
  return {
    isLoading: projectArchive.get('isLoading'),
    entities: projectArchive.get('entities').valueSeq(),
    lastSeen: projectArchive.get('lastSeen'),
    errorMessage: projectArchive.get('errorMessage'),
    staleData: projectArchive.get('staleData'),
    projectId,
    projectName: getSelectedProjectName(state),
    canEditProjectFiles: canEditFilesInProject(state, projectId),
    orgProjectsLoaded: isOrgProjectsLoaded(state)
  };
}

export default connect(mapStateToProps, {
  getArchivedEntitiesIfNeeded: getArchivedEntitiesIfNeededAction,
  restoreUserEntities: restoreUserEntitiesAction
})(ArchiveImpl as any);
