// @ts-nocheck
import { Map } from 'immutable';

export default {
  rows: (plate: Map) => plate.getIn(['layout', 'rows']),
  columns: (plate: Map) => plate.getIn(['layout', 'columns']),
  size: (plate: Map) => plate.getIn(['layout', 'rows']) * plate.getIn(['layout', 'columns']),
  scanMode: (plate: Map) => plate.get('scanMode'),
  wells: (plate: Map) => plate.get('wells')
};
