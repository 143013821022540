import React from 'react';
import Table from '../../frontend-common-libs/src/components/common/tables/Table';
import THead from '../../frontend-common-libs/src/components/common/tables/THead';
import TCell from '../../frontend-common-libs/src/components/common/tables/TCell';
import TBody from '../../frontend-common-libs/src/components/common/tables/TBody';
import TRow from '../../frontend-common-libs/src/components/common/tables/TRow';
import request from '../../frontend-common-libs/src/utils/httpUtils';
import styles from '../style/api-portal.module.scss';
import DeleteIcon from '../img/delete-icon.svg';
import { GATEWAY } from '../../frontend-common-libs/src/config/config';
import notifications from '../../frontend-common-libs/src/utils/notifications';
import ModalDialog from '../../frontend-common-libs/src/components/common/dialogs/ModalDialog';

const authServiceBaseURL = GATEWAY.AUTH_SERVICE_PATHS.BASE_URL;
const apiTokensPath = GATEWAY.AUTH_SERVICE_PATHS.API_TOKENS;

export type ApiToken = {
  id: string;
  description: string;
  issuedDate: string;
  expirationDate: string;
  status: 'Active' | 'Expired';
};

export type Props = {
  apiTokens: ApiToken[];
  onApiTokenDeleted: () => void;
};

type State = {
  isConfirmDeleteModalOpen: boolean;
  tokenIdToDelete: string;
};

export default class ApiTokensTable extends React.Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      isConfirmDeleteModalOpen: false,
      tokenIdToDelete: ''
    };
  }

  private deleteApiToken = async () => {
    const { tokenIdToDelete } = this.state;
    try {
      await request.delete(authServiceBaseURL, {}, `${apiTokensPath}/${tokenIdToDelete}`, {});

      const { onApiTokenDeleted } = this.props;

      onApiTokenDeleted();
    } catch (e) {
      console.error(e);
      notifications.error('Failed to delete API token. Please try again.');
    }
  };

  private handleDeleteApiToken = (tokenId: string) => {
    this.setState({ isConfirmDeleteModalOpen: true, tokenIdToDelete: tokenId });
  };

  private handleDeleteApiTokenCancel = () => {
    this.setState({ isConfirmDeleteModalOpen: false, tokenIdToDelete: '' });
  };

  public render() {
    const { apiTokens } = this.props;
    const { isConfirmDeleteModalOpen } = this.state;

    return (
      <div className={styles.apiTokensTableWrapper}>
        <Table className={styles.apiTokensTable}>
          <THead>
            <TCell>DESCRIPTION</TCell>
            <TCell>ISSUE DATE</TCell>
            <TCell>EXPIRATION DATE</TCell>
            <TCell>STATUS</TCell>
          </THead>
          <TBody>
            {apiTokens.map(apiToken => {
              return (
                <TRow key={apiToken.id} id={`api-token-item_${apiToken.id}`}>
                  <TCell title={apiToken.description}>{apiToken.description}</TCell>
                  <TCell title={apiToken.issuedDate}>{apiToken.issuedDate}</TCell>
                  <TCell title={apiToken.expirationDate}>{apiToken.expirationDate}</TCell>
                  <TCell title={apiToken.status}>{apiToken.status}</TCell>
                  <TCell className="api-tokens-table-delete-button-cell">
                    <button
                      id={`btn-delete-api-token-${apiToken.id}`}
                      className={styles.deleteButton}
                      onClick={() => this.handleDeleteApiToken(apiToken.id)}
                    >
                      <img
                        src={DeleteIcon}
                        alt="Delete"
                        id={`img-delete-api-token_${apiToken.id}`}
                      />
                    </button>
                  </TCell>
                </TRow>
              );
            })}
          </TBody>
        </Table>
        <ModalDialog
          show={isConfirmDeleteModalOpen}
          title="Delete API Token"
          message="Are you sure you want to delete this item?"
          okBtnText="Delete"
          onCancel={this.handleDeleteApiTokenCancel}
          onSuccess={this.deleteApiToken}
        />
      </div>
    );
  }
}
