import React, { ReactElement } from 'react';
import { PluginComponentGetter } from '@biorad-lsg-tsc/instrument-family-plugin';

const withPluginComponentFactory = (
  getComponent: PluginComponentGetter,
  props: Object
): ((futureProps: Object) => ReactElement) => {
  return function (futureProps: Object): ReactElement {
    const Component = getComponent();
    const mergedProps = { ...futureProps, ...props };
    return <Component {...mergedProps} />;
  };
};

export default withPluginComponentFactory;
