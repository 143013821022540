import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Jumbotron } from 'react-bootstrap';
import { Field, reduxForm, InjectedFormProps } from 'redux-form';
import { ContactMessage } from '../../api/customer-support';
import submitUserIssue from '../../api/customer-support';
import notification from '../../frontend-common-libs/src/utils/notifications';
import {
  emailValid,
  textRequired,
  lengthTest
} from '../../frontend-common-libs/src/common/form_validators';
import { RenderField } from '../../frontend-common-libs/src/common/input-fields';
import ProgressButton from '../../frontend-common-libs/src/components/common/ProgressButton';
import CountryDropdown, { countryRequired } from './CountryDropdown';
import CountriesRepository from '../../utils/CountriesRepository';
import { ReduxState } from '../../types';
import { getUserEmail } from '../../selectors/auth-selectors';

export type Props = {
  handleSubmit: (...args: Array<any>) => any;
} & InjectedFormProps;

export type State = {
  wasSubmitted: boolean;
};

const MIN_SUBJECT_LENGTH = 1;
const MAX_SUBJECT_LENGTH = 100;
const MIN_MESSAGE_LENGTH = 1;
const MAX_MESSAGE_LENGTH = 2048;

export class CustomerSupportImpl extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      wasSubmitted: false
    };
  }

  onSubmit = async (values: ContactMessage) => {
    try {
      await submitUserIssue({
        ...values,
        country: CountriesRepository.getCode(values.country)
      });
      this.setState({ wasSubmitted: true });
    } catch (err) {
      notification.error('Failed to submit form');
    }
  };

  subjectLength = (value: string) =>
    lengthTest('Subject', value, MIN_SUBJECT_LENGTH, MAX_SUBJECT_LENGTH);

  messageLength = (value: string) =>
    lengthTest('Message', value, MIN_MESSAGE_LENGTH, MAX_MESSAGE_LENGTH);

  render() {
    const { handleSubmit, submitting, invalid } = this.props;
    const { wasSubmitted } = this.state;
    if (wasSubmitted) {
      return (
        <Jumbotron
          style={{ textAlign: 'center', backgroundColor: 'inherit' }}
          id="submission-message"
        >
          <h1>Thank you for your feedback!</h1>
        </Jumbotron>
      );
    }
    return (
      <div className="container">
        <form>
          <Field
            label="COUNTRY"
            name="country"
            component={CountryDropdown}
            validate={[countryRequired]}
          />
          <Field
            label="PHONE NUMBER (OPTIONAL)"
            name="phone"
            type="phone"
            extraError=""
            component={RenderField}
            disabled={false}
          />
          <Field
            label="EMAIL"
            name="sender"
            type="email"
            extraError=""
            component={RenderField}
            disabled
            validate={[textRequired, emailValid]}
          />
          <Field
            label="SUBJECT"
            name="subject"
            type="text"
            extraError=""
            component={RenderField}
            disabled={false}
            validate={[textRequired, this.subjectLength]}
          />
          <Field
            label="MESSAGE"
            name="message"
            type="textarea"
            className="message-area"
            extraError=""
            component={RenderField}
            disabled={false}
            cols={30}
            rows={10}
            validate={[textRequired, this.messageLength]}
          />
          <div className="pull-right">
            <ProgressButton
              id="send-button"
              type="button"
              className="form-btn btn-primary text-center"
              inProgress={submitting}
              onClick={handleSubmit(this.onSubmit)}
              disabled={invalid}
            >
              Send
            </ProgressButton>
          </div>
        </form>
      </div>
    );
  }
}

function mapStateToProps(state: ReduxState) {
  return {
    initialValues: {
      sender: getUserEmail(state)
    }
  };
}
export default connect(
  mapStateToProps,
  null
)(
  reduxForm({
    form: 'contact',
    enableReinitialize: true
  })(CustomerSupportImpl)
);
