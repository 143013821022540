import React from 'react';
import { Link } from 'react-router-dom';
import { LinkContent } from './CreateExperimentCard';

export type Props = {
  id: string;
  links: LinkContent[];
  hasEditPermissions?: boolean;
};

export default function CreateExperimentCardLinks({
  id,
  links,
  hasEditPermissions
}: Readonly<Props>) {
  return (
    <>
      {links.map(({ id: linkContentId, displayText, path, isCreate }: LinkContent) => {
        const linkId = `${id}-${linkContentId}`;
        const disabled = isCreate === true && !hasEditPermissions;
        if (disabled)
          return (
            <span key={linkId} id={linkId} className="disabled-link">
              {displayText}
            </span>
          );
        return (
          <Link key={linkId} id={linkId} to={path}>
            {displayText}
          </Link>
        );
      })}
    </>
  );
}
