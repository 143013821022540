import React, { ChangeEvent, SyntheticEvent } from 'react';
import ModalDialog from '../../../frontend-common-libs/src/components/common/dialogs/ModalDialog';
import EditOrgDialogVm from './EditOrgDialogVm';
import './styles/edit-org-dialog.scss';
import { modalStyleV2 } from '../../../frontend-common-libs/src/components/common/dialogs/custom-modal-styles';
import { ConnectViewModel } from '../../../frontend-common-libs/src/common/mvvm/ConnectViewModel';

export type Props = {
  editOrgDialogVm: EditOrgDialogVm;
};

const editOrgStyle = { ...modalStyleV2, modalStyle: 'edit-org-dialog' };

export class EditOrgDialogImpl extends React.Component<Props> {
  updateOrgName = (e: SyntheticEvent<HTMLInputElement>) => {
    const { editOrgDialogVm } = this.props;
    editOrgDialogVm.orgName = e.currentTarget.value;
  };

  updateOrgDescription = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const { editOrgDialogVm } = this.props;
    editOrgDialogVm.orgDescription = e.currentTarget.value;
  };

  stopEnterPropagation = (e: React.KeyboardEvent<HTMLElement>) => {
    if (e.key === 'Enter') e.stopPropagation();
  };

  renderModalContent() {
    const { editOrgDialogVm } = this.props;
    const { orgName, orgNameError, orgDescription, orgDescriptionError } = editOrgDialogVm;
    return (
      <div className="edit-org-dialog-panel">
        <div>Name</div>
        <input
          className="org-name"
          id="org-name"
          type="text"
          value={orgName}
          onChange={this.updateOrgName}
          onKeyDown={this.stopEnterPropagation}
        />
        <span id="org-name-error" className="edit-error with-ellipsis">
          {orgNameError}
        </span>
        <div>Description (Optional)</div>
        <textarea
          id="org-description"
          value={orgDescription || ''}
          onChange={this.updateOrgDescription}
          onKeyDown={this.stopEnterPropagation}
          className="org-description"
        />
        <span id="org-description-error" className="edit-error with-ellipsis">
          {orgDescriptionError}
        </span>
      </div>
    );
  }

  render() {
    const { editOrgDialogVm } = this.props;
    const { shouldBlock, onCancel, okBtnDisabled, onOk, dialogTitle, okBtnText, saveInProgress } =
      editOrgDialogVm;
    if (!shouldBlock) return null;
    return (
      <ModalDialog
        title={dialogTitle}
        show={shouldBlock}
        onCancel={onCancel}
        onSuccess={onOk}
        okBtnText={okBtnText}
        okBtnDisabled={okBtnDisabled}
        customModalStyle={editOrgStyle}
        inProgress={saveInProgress}
      >
        {this.renderModalContent()}
      </ModalDialog>
    );
  }
}

export default function EditOrgDialog(props: Readonly<Props>) {
  const { editOrgDialogVm } = props;
  return (
    <ConnectViewModel vm={editOrgDialogVm} vmPropKey="editOrgDialogVm">
      <EditOrgDialogImpl {...props} />
    </ConnectViewModel>
  );
}
