import RunTemplate from '../components/pcr/analysis/models/RunTemplate';
import { GATEWAY } from '../frontend-common-libs/src/config/config';
import requests from '../frontend-common-libs/src/utils/httpUtils';
import { getParamsWithAccessTokenHeader } from '../organization-management/api/access-control';

const domain = GATEWAY.QPCRDATA_SERVICE_PATHS.BASE_URL;

export async function postRunTemplate(runTemplate: RunTemplate, projectAccessToken?: string) {
  const path = GATEWAY.QPCRDATA_SERVICE_PATHS.CREATE_RUN_TEMPLATE;
  const body = runTemplate.toJS();
  const params = getParamsWithAccessTokenHeader(projectAccessToken);
  const response = await requests.post(domain, {}, path, params, body);
  const { id } = response;
  return id;
}

export async function getRunTemplate(entityId: string) {
  const path = GATEWAY.QPCRDATA_SERVICE_PATHS.GET_RUN_TEMPLATE(entityId);
  return requests.get(domain, {}, path, {});
}
