import React, { ComponentProps, HTMLProps, ReactElement } from 'react';
import classnames from 'classnames';
import DropdownToggle from './DropdownToggle';
import AutoSuggestInput from './AutoSuggestInput';
import styles from '../styles/aria-auto-suggest-input.modules.scss';
import InputLabel from './InputLabel';

export interface AriaAutoSuggestInputProps {
  labelProps: HTMLProps<HTMLLabelElement>;
  toggleButtonProps: ComponentProps<'button'>;
  inputProps: HTMLProps<HTMLInputElement>;
  inputWrapperProps: HTMLProps<HTMLDivElement>;
  labelText: string;
}

export default function AriaAutoSuggestInput({
  labelProps,
  toggleButtonProps,
  inputProps,
  inputWrapperProps,
  labelText
}: AriaAutoSuggestInputProps): ReactElement {
  const AriaAutoSuggestInputStyles = classnames(`${styles['aria-auto-suggest-input']}`);
  return (
    <>
      <InputLabel labelProps={labelProps}>{labelText}</InputLabel>
      <div className={AriaAutoSuggestInputStyles} {...inputWrapperProps}>
        <AutoSuggestInput inputProps={inputProps} />
        <DropdownToggle toggleButtonProps={toggleButtonProps} />
      </div>
    </>
  );
}
