import { RoutePath, RouteName, RoutePattern } from '../frontend-common-libs/plugin-types';
import { SystemEvent } from '../frontend-common-libs/src/system-event';

export const pageViewedSystemEventName = 'page-viewed';

export default interface PageViewedSystemEvent extends SystemEvent {
  name: typeof pageViewedSystemEventName;
  toPath: RoutePath;
  toRouteName: RouteName;
  toRoute: RoutePattern;
  fromPath?: RoutePath;
  fromRouteName?: RouteName;
  fromRoute?: RoutePattern;
}
