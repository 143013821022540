import AWS, { AWSError, CognitoIdentityCredentials } from 'aws-sdk';
import {
  CognitoUserPool,
  CognitoUser,
  CognitoUserAttribute,
  AuthenticationDetails,
  CognitoUserSession
} from 'amazon-cognito-identity-js';
import apiGatewayClientFactory from 'aws-api-gateway-client';
import axios from 'axios';
import { PromiseResult } from 'aws-sdk/lib/request';
import { ResendConfirmationCodeResponse } from 'aws-sdk/clients/cognitoidentityserviceprovider';
import { COGNITO, GATEWAY } from '../config/config';
import NotLoggedInError from './NotLoggedInError';
import ResendVerificationCodeEvent from '../user-analytics/email-verification/ResendVerificationCodeEvent';

const cognitoPoolConfig = {
  IdentityPoolId: COGNITO.IDENTITYPOOL_ID
};

export const cognitoPoolData = {
  UserPoolId: COGNITO.USERPOOL_ID,
  ClientId: COGNITO.APPCLIENT_ID
};

function hasLoggedInCredentials(): boolean {
  return !!AWS.config.credentials;
}

function getLoggedInCredentials(): CognitoIdentityCredentials {
  if (!hasLoggedInCredentials()) {
    throw new NotLoggedInError();
  }
  return AWS.config.credentials as CognitoIdentityCredentials;
}

export function createCognitoUser(email: string): CognitoUser {
  const userData = {
    Username: email.toLowerCase(),
    Pool: new CognitoUserPool(cognitoPoolData)
  };
  return new CognitoUser(userData);
}

export function authenticateUser(email: string, password: string): Promise<string> {
  const cognitoUser = createCognitoUser(email);
  const username = cognitoUser.getUsername(); // username is email
  const authenticationDetails = new AuthenticationDetails({
    Username: username,
    Password: password
  });
  return new Promise((resolve, reject) => {
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: () => resolve(username),
      onFailure: err => reject(err)
    });
  });
}

export function getCurrentUser(): CognitoUser | null {
  const userPool = new CognitoUserPool({
    UserPoolId: COGNITO.USERPOOL_ID,
    ClientId: COGNITO.APPCLIENT_ID
  });

  return userPool.getCurrentUser();
}

/* eslint consistent-return: "off" */
export function getUserSession(): Promise<any> {
  return new Promise((resolve, reject) => {
    const currentUser = getCurrentUser();
    if (currentUser === null) {
      reject({ message: 'user not logged in' });
      return;
    }
    currentUser.getSession(async (err: Error | null, data: CognitoUserSession | null) => {
      const username = currentUser.getUsername();
      if (err || !data) {
        return reject(err);
      }
      return resolve({
        email: username,
        idToken: data.getIdToken().getJwtToken(),
        accessToken: data.getAccessToken().getJwtToken(),
        currentUser
      });
    });
  });
}

export function clearUser(): void {
  if (hasLoggedInCredentials()) {
    getLoggedInCredentials().clearCachedId();
  }
  AWS.config.credentials = null;
}

function initCredentials(idToken: string): CognitoIdentityCredentials {
  const credentialsConfig = {
    ...cognitoPoolConfig,
    Logins: {
      [`cognito-idp.${COGNITO.REGION}.amazonaws.com/${COGNITO.USERPOOL_ID}`]: idToken
    }
  };
  const credentials = new AWS.CognitoIdentityCredentials(credentialsConfig);
  AWS.config.update({
    region: COGNITO.REGION
  });
  return credentials;
}

export function getCurrentUserIdentityId() {
  if (!hasLoggedInCredentials()) {
    throw new Error('User not logged in');
  }
  return getLoggedInCredentials().identityId;
}

export function updateCredentials(): Promise<CognitoIdentityCredentials> {
  return new Promise((resolve, reject) => {
    // if credentials is null or expired
    if (!hasLoggedInCredentials() || getLoggedInCredentials().needsRefresh()) {
      getUserSession()
        .then(result => {
          const { idToken } = result;
          const newCredentials = initCredentials(idToken);
          newCredentials.get(err => {
            if (err) {
              clearUser();
              return reject(err);
            }
            AWS.config.credentials = newCredentials;
            resolve(newCredentials);
          });
        })
        .catch(err => {
          reject(err);
        });
    } else {
      resolve(getLoggedInCredentials());
    }
  });
}

export async function makeApiGatewayCall(
  callback: (...args: Array<any>) => any,
  baseURL: string = GATEWAY.FILEARBITER_PATHS.BASE_URL
) {
  const credentials = await updateCredentials();
  const apiGatewayClient = apiGatewayClientFactory.newClient({
    accessKey: credentials.accessKeyId,
    secretKey: credentials.secretAccessKey,
    sessionToken: credentials.sessionToken,
    region: COGNITO.REGION,
    invokeUrl: baseURL
  });
  callback(apiGatewayClient);
}

export async function changePassword(
  userName: string,
  oldPass: string,
  newPass: string,
  accessToken: string,
  idToken: string
) {
  const url = GATEWAY.AUTH_SERVICE_PATHS.BASE_URL + GATEWAY.AUTH_SERVICE_PATHS.CHANGEPWD_URL;
  await axios.post<unknown, unknown>(url, {
    username: userName,
    password: oldPass,
    newpassword: newPass,
    accesstoken: accessToken,
    idtoken: idToken
  });
}

export function forgotPassword(email: string): Promise<string> {
  const cognitoUser = createCognitoUser(email);
  return new Promise((resolve, reject) => {
    cognitoUser.forgotPassword({
      onSuccess: () => resolve(cognitoUser.getUsername()), // username = email
      onFailure: err => reject(err)
    });
  });
}

export function getUserProfile(currentUser: CognitoUser): Promise<CognitoUserAttribute[]> {
  return new Promise((resolve, reject) => {
    currentUser.getUserAttributes(
      (err: Error | undefined, attributes: CognitoUserAttribute[] | undefined) => {
        if (err || !attributes) {
          reject(err);
        } else {
          resolve(attributes);
        }
      }
    );
  });
}

export function setUserProfile(
  currentUser: CognitoUser,
  attributes: Array<CognitoUserAttribute>
): Promise<Array<CognitoUserAttribute>> {
  return new Promise((resolve, reject) => {
    currentUser.updateAttributes(attributes, err => {
      if (err) {
        reject(err);
      } else {
        resolve(attributes);
      }
    });
  });
}

export function resendVerificationCode(
  userName: string
): Promise<PromiseResult<ResendConfirmationCodeResponse, AWSError>> {
  const cognitoIdentityServiceProvider = new AWS.CognitoIdentityServiceProvider({
    region: COGNITO.REGION
  });
  const params = {
    ClientId: cognitoPoolData.ClientId,
    Username: userName
  };
  new ResendVerificationCodeEvent().track();
  return cognitoIdentityServiceProvider.resendConfirmationCode(params).promise();
}
