import getEnvironmentConfig from '../../frontend-common-libs/src/config/EnvironmentConfiguration';

const { apiDomain } = getEnvironmentConfig();

export const baseURL = `https://access-control.${apiDomain}`;

export const accessControlBasePath = '/api/access-control';

export function getUserIdRequestHeader(userSubId: string) {
  return { headers: { 'user-sub-id': userSubId } };
}

export function getUserTokenHeader(accessToken: string) {
  return { headers: { 'user-token': accessToken } };
}

export function getParamsWithAccessTokenHeader(accessToken: string | undefined) {
  return accessToken ? getUserTokenHeader(accessToken) : {};
}

export function addAccessTokenToHeaders(
  accessToken: string | undefined,
  params: Record<string, any>
) {
  if (!accessToken) return params;
  const accessTokenHeaders = getUserTokenHeader(accessToken);
  if (!params) return accessTokenHeaders;
  const paramsWithToken = { ...params };
  paramsWithToken.headers = { ...params.headers, ...accessTokenHeaders.headers };
  return paramsWithToken;
}
