import { DispatchType, ORG_DETAILS_LOADING, ORG_DETAILS_LOADED } from './action-types';
import { GetState } from '../../types';
import { isOrgDetailsLoaded, getIsOrgDetailsLoading } from '../selectors/selectors';
import { getOrgDetails as getOrgDetailsEndpoint } from '../api/org-api';
import { withRefreshOrgToken } from './with-refresh-org-token';
import { CbWithToken } from './WithRefreshToken';

function getOrgDetails(orgId: string) {
  return async (dispatch: DispatchType, getState: GetState) => {
    dispatch({
      type: ORG_DETAILS_LOADING,
      payload: { orgId }
    });
    try {
      const getOrgDetailsCb: CbWithToken = async (accessToken: string) => {
        return getOrgDetailsEndpoint(orgId, accessToken);
      };
      const details = await withRefreshOrgToken(dispatch, getState, orgId, getOrgDetailsCb);
      dispatch({
        type: ORG_DETAILS_LOADED,
        payload: { orgId, details }
      });
    } catch (error) {
      dispatch({
        type: ORG_DETAILS_LOADED,
        payload: { orgId, details: { error } }
      });
    }
  };
}

// eslint-disable-next-line import/prefer-default-export
export function getOrgDetailsIfNeeded(orgId: string) {
  return async (dispatch: DispatchType, getState: GetState) => {
    const state = getState();
    if (!isOrgDetailsLoaded(state, orgId) && getIsOrgDetailsLoading(state, orgId) !== true) {
      await getOrgDetails(orgId)(dispatch, getState);
    }
  };
}
