import React from 'react';
import GroupModeToggle from '../GroupModeToggle';
import MeltModeToggle from './MeltModeToggle';

type Props = {
  analysisMode: string;
  onUpdateAnalysisMode: (...args: Array<any>) => any;
  meltMode: string;
  onUpdateMeltMode: (...args: Array<any>) => any;
};

export default ({ analysisMode, onUpdateAnalysisMode, meltMode, onUpdateMeltMode }: Props) => (
  <div className="pcr-chart-controls">
    <GroupModeToggle analysisMode={analysisMode} onUpdateAnalysisMode={onUpdateAnalysisMode} />
    <MeltModeToggle mode={meltMode} onUpdateMode={onUpdateMeltMode} />
  </div>
);
