import { Component } from 'react';
import { connect } from 'react-redux';
import { Map, Seq } from 'immutable';
import { ReduxState } from '../../types';
import getProtocolTemplatesIfNeededAction from '../../actions/protocolTemplate_actions';
import { getSelectedProjectId } from '../../project-management';

type Props = {
  isLoading: boolean;
  loaded: boolean;
  protocols: Seq.Indexed<Map<string, any>>;
  lastSeen: string;
  errorMessage: string;
  getProtocolTemplatesIfNeeded: typeof getProtocolTemplatesIfNeededAction;
  render: any;
};

export class ProtocolTemplatesLoaderImpl extends Component<Props> {
  fetchMore = () => {
    const { getProtocolTemplatesIfNeeded, lastSeen } = this.props;
    getProtocolTemplatesIfNeeded(lastSeen);
  };

  componentDidMount() {
    const { loaded, isLoading, lastSeen } = this.props;
    if ((!loaded && !isLoading) || lastSeen === '') this.fetchMore();
  }

  render() {
    const { render, isLoading, loaded, protocols, lastSeen, errorMessage } = this.props;
    const hasMoreData = !loaded || !!lastSeen;
    return render(isLoading, protocols, hasMoreData, errorMessage, this.fetchMore);
  }
}

function mapStateToProps(state: ReduxState) {
  const projectId = getSelectedProjectId(state);
  const projectProtocols = state.protocolTemplates.getIn(['projects', projectId]) as Map<
    string,
    any
  >;
  return {
    loaded: projectProtocols.get('loaded'),
    isLoading: projectProtocols.get('isLoading'),
    protocols: projectProtocols.get('protocols').valueSeq(),
    lastSeen: projectProtocols.get('lastSeen'),
    errorMessage: projectProtocols.get('errorMessage')
  };
}

export default connect(mapStateToProps, {
  getProtocolTemplatesIfNeeded: getProtocolTemplatesIfNeededAction
  // @ts-ignore
})(ProtocolTemplatesLoaderImpl);
