import React from 'react';

import { ToggleButtonGroup, ToggleButton, Tooltip, OverlayTrigger } from 'react-bootstrap';

type Props = {
  id: string;
  value: boolean;
  onLabel: string;
  offLabel: string;
  onChange: (...args: Array<any>) => any;
  toolTipString: string;
};

export default class RadioButton extends React.Component<Props> {
  onChange = (e: boolean) => {
    const { value, onChange } = this.props;
    if (value !== e) {
      onChange(e);
    }
  };

  render() {
    const { id, toolTipString, value, offLabel, onLabel } = this.props;
    const tooltip = <Tooltip id={`tooltip-${id}`}>{toolTipString}</Tooltip>;
    return (
      <OverlayTrigger placement="bottom" overlay={tooltip}>
        <ToggleButtonGroup
          name={`toggle-${id}`}
          className="radio-button-toggle"
          type="radio"
          value={value}
          onChange={this.onChange}
        >
          {/* @ts-ignore */}
          <ToggleButton id={`${id}-off`} value={false}>
            {offLabel}
          </ToggleButton>
          {/* @ts-ignore */}
          <ToggleButton id={`${id}-on`} value>
            {onLabel}
          </ToggleButton>
        </ToggleButtonGroup>
      </OverlayTrigger>
    );
  }
}
