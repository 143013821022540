import UserAnalyticsClient from '../../frontend-common-libs/src/user-analytics/UserAnalyticsClient';

export default class UserAccessedEmailVerificationPageEvent {
  private static EVENT_NAME = 'user-accessed-email-verification-page';

  // eslint-disable-next-line class-methods-use-this
  public track(): void {
    UserAnalyticsClient.getInstance().track(UserAccessedEmailVerificationPageEvent.EVENT_NAME);
  }
}
