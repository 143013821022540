import InfoParser from './InfoParser';
import ThermalCyclerInfoParser from './ThermalCyclerInfoParser';
import RunConditionsParser from './RunConditionsParser';
import ErrorSummaryParser from './ErrorSummaryParser';
import ProtocolStepsParser from './ProtocolStepsParser';
import RunDetailsParser from './RunDetailsParser';
import HeaderParser from './HeaderParser';

export default class RunParser {
  report: any;

  infoParser: InfoParser;

  thermalCyclerInfoParser: ThermalCyclerInfoParser;

  runConditionsParser: RunConditionsParser;

  errorSummaryParser: ErrorSummaryParser;

  protocolStepsParser: ProtocolStepsParser;

  runDetailsParser: RunDetailsParser;

  headerParser: HeaderParser;

  constructor(runReport: any) {
    this.report = runReport;
    this.infoParser = new InfoParser(runReport);
    this.thermalCyclerInfoParser = new ThermalCyclerInfoParser(runReport);
    this.runConditionsParser = new RunConditionsParser(runReport);
    this.errorSummaryParser = new ErrorSummaryParser(runReport);
    this.protocolStepsParser = new ProtocolStepsParser(runReport);
    this.runDetailsParser = new RunDetailsParser(runReport);
    this.headerParser = new HeaderParser(runReport);
  }
}
