import { fromJS, Map } from 'immutable';
import { ApiAction } from '../types';
import { MAINTENANCE_MESSAGE_LOADED } from '../actions/maintenance_message/maintenance_message_actions_types';
import { UNAUTH_USER } from '../auth/actions/auth_types';

// @ts-ignore
const INITIAL_STATE: Map<string, any> = fromJS({
  message: undefined
});

const onMaintenanceMessageLoaded = (state: Map<string, any>, action: any) => {
  const { message } = action.payload;
  return state.set('message', message);
};

const onHandleLogout = (state: Map<string, any>) => state.set('message', undefined);

const actionMap = {
  [MAINTENANCE_MESSAGE_LOADED]: onMaintenanceMessageLoaded,
  [UNAUTH_USER]: onHandleLogout
};

export default function maintenanceMessageReducer(
  state: Map<string, any> = INITIAL_STATE,
  action: ApiAction<any, any> | undefined = undefined
): Map<string, any> {
  if (action) {
    return actionMap[action.type] ? actionMap[action.type](state, action) : state;
  }
  return state;
}
