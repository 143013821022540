import { Map, Set, fromJS } from 'immutable';
import { numToChar, intlCollator } from '../frontend-common-libs/src/utils/commonUtils';
import { parseFloatIfNot } from '../frontend-common-libs/src/common/numbers';
import getColors, { getColorsList } from './colors';
import { ImmutableMap } from '../frontend-common-libs/src/common/types';

type ColorIndex = number;
type FluorName = string;
type TargetName = string;
type WellId = string;
type ChannelId = string;
type ChannelDetailsKeys = {
  name: FluorName;
  target?: TargetName;
};
type ChannelDetails = ImmutableMap<ChannelDetailsKeys>;
type WellKeys = {
  fluors: Map<ChannelId, ChannelDetails>;
  type: string;
  replicate?: number;
  sample?: string;
  biogroup?: string;
};
type Well = ImmutableMap<WellKeys>;
type WellMap = Map<WellId, Well>;

export const getPlateTargets = (wells: WellMap): Map<TargetName, ColorIndex> => {
  const plateTargets = wells.reduce(
    (targets, well) =>
      targets.union(
        well
          .get('fluors')
          .valueSeq()
          .reduce((wellTargets: Set<TargetName>, channelDetails: ChannelDetails) => {
            const value = channelDetails.get('target');
            if (value) return wellTargets.add(value);
            return wellTargets;
          }, Set<TargetName>())
      ),
    Set<TargetName>()
  );

  const list = plateTargets.toList();
  return list.sort(intlCollator.compare).reduce((targets, target: TargetName) => {
    const index = targets.size;
    return targets.set(target, index);
  }, Map<TargetName, ColorIndex>());
};

export const getValuesInWells = (wells: WellMap, key: keyof WellKeys | 'target'): Set<any> =>
  wells
    .reduce((suggestionsSet, well) => {
      if (key === 'target') {
        return well
          .get('fluors')
          .valueSeq()
          .reduce((targets: Set<any>, channels) => {
            const value = channels.get('target');
            return value ? targets.add(value) : targets;
          }, suggestionsSet);
      }
      let value: string | undefined;
      if (typeof well.get(key) === 'number') {
        value = `${well.get(key)}`;
      } else {
        value = well.get(key) as string | undefined;
      }
      return value ? suggestionsSet.add(value) : suggestionsSet;
    }, Set<string>())
    .sort(intlCollator.compare);

const sampleTypeColorGetter: { [key: string]: any } = {
  unknown: () => getColors().getIn(['blue', '4']),
  standard: () => getColors().getIn(['green', '4']),
  ntc: () => getColors().getIn(['yellow', '1']),
  'positive control': () => getColors().getIn(['purple', '4']),
  'negative control': () => getColors().getIn(['red', '4']),
  nrt: () => getColors().getIn(['orange', '4'])
};

export function getSampleTypeColor(sampleType: string): Map<string, any> {
  return sampleTypeColorGetter[sampleType.toLowerCase()]();
}

const flourColorGetter: { [key: string]: any } = {
  fam: () => getColors().getIn(['blue', '2']),
  sybr: () => getColors().getIn(['green', '4']),
  'cal gold 540': () => getColors().getIn(['orange', '6']),
  vic: () => getColors().getIn(['yellow', '1']),
  hex: () => getColors().getIn(['orange', '6']),
  'cal orange 560': () => getColors().getIn(['dark_orange', '3']),
  rox: () => getColors().getIn(['dark_orange', '1']),
  'texas red': () => getColors().getIn(['red', '1']),
  'cal red 610': () => getColors().getIn(['red', '1']),
  'tex 615': () => getColors().getIn(['red', '2']),
  cy5: () => getColors().getIn(['blue', '1']),
  'quasar 670': () => getColors().getIn(['dark_blue', '3']),
  'quasar 705': () => getColors().getIn(['purple', '1']),
  'cy5-5': () => getColors().getIn(['purple', '2'])
};

const channelColorGetter: { [key: string]: any } = {
  '1': () => getColors().getIn(['green', '4']),
  '2': () => getColors().getIn(['yellow', '1']),
  '3': () => getColors().getIn(['red', '2']),
  '4': () => getColors().getIn(['blue', '1']),
  '5': () => getColors().getIn(['purple', '2'])
};

export function getFluorColor(fluor: string, channel?: string): Map<string, any> {
  let colorGetter = flourColorGetter[fluor.toLocaleLowerCase()];
  if (colorGetter) return colorGetter();
  if (channel) {
    colorGetter = channelColorGetter[channel];
    if (colorGetter) return colorGetter();
  }
  // @ts-ignore
  return Map();
}

export function getTargetColorByIndex(index: number): Map<string, any> {
  if (index < 0) throw new Error('Invalid argument');
  const colorList = getColorsList();
  // @ts-ignore
  return colorList.get(index % colorList.size);
}

export function padNumberWithZero(number: number, paddingAmount: number): string {
  if (paddingAmount === 0) return number.toString();
  return Array(paddingAmount).fill(0).join('') + number;
}

export const wellSortByColumn = (a: string, b: string) => {
  const aCol = a.substring(1) + a[0];
  const bCol = b.substring(1) + b[0];
  return intlCollator.compare(aCol, bCol);
};

// map data from server to desired display value
const wellTypeDisplayValues: { [key: string]: string } = {
  unknown: 'Unknown',
  standard: 'Standard',
  NTC: 'NTC',
  'positive control': 'Positive',
  'negative control': 'Negative',
  NRT: 'NRT'
};

export function formatWellDisplayContent(
  type: string | null | undefined,
  formattedReplicate: string | null | undefined
): string {
  if (!type) return 'N/A';
  const displayType = wellTypeDisplayValues[type] || wellTypeDisplayValues.unknown;
  return formattedReplicate ? `${displayType}-${formattedReplicate}` : displayType;
}

export function getSortedWellFluorMap(well: Map<string, any>): Map<string, any> {
  return (well.get('fluors') && well.get('fluors').sortBy((v: string, k: string) => k)) || Map();
}

export function getSortedFluorContent(
  well: Map<string, any>,
  useNA = false
): {
  targets: Array<string>;
  fluors: Array<string>;
} {
  const targets: Array<string> = [];
  const fluors: Array<string> = [];
  const sortedFluorsMap = getSortedWellFluorMap(well);

  sortedFluorsMap.forEach((v: Map<string, any>) => {
    targets.push(v.get('target') || (useNA ? 'N/A' : v.get('name')));
    fluors.push(v.get('name') || 'N/A');
  });

  return { targets, fluors };
}

type WellTypes = {
  name: string;
  abbr: string;
  descr: string;
};

export function getSelectedWells(
  plate: Map<string, any>,
  selectedWellIds: Set<string>
): Map<string, any> {
  // @ts-ignore
  return selectedWellIds.reduce((selectedWells, wellId) => {
    // @ts-ignore
    const well = plate.getIn(['wells', wellId]);
    // @ts-ignore
    return well ? selectedWells.set(wellId, well) : selectedWells;
  }, Map());
}

export enum WellType {
  UNKNOWN = 'unknown',
  STANDARD = 'standard',
  NTC = 'NTC',
  POSITIVE_CONTROL = 'positive control',
  NEGATIVE_CONTROL = 'negative control',
  NRT = 'NRT'
}

export function getWellTypes(): Array<WellTypes> {
  return [
    {
      name: WellType.UNKNOWN,
      abbr: 'Unk',
      descr: 'Unknown'
    },
    {
      name: WellType.STANDARD,
      abbr: 'Std',
      descr: 'Standard'
    },
    {
      name: WellType.NTC,
      abbr: 'NTC',
      descr: 'No Template Control'
    },
    {
      name: WellType.POSITIVE_CONTROL,
      abbr: 'Pos',
      descr: 'Positive Control'
    },
    {
      name: WellType.NEGATIVE_CONTROL,
      abbr: 'Neg',
      descr: 'Negative Control'
    },
    {
      name: WellType.NRT,
      abbr: 'NRT',
      descr: 'No RT Control'
    }
  ];
}

export const getAvailableFluors = (plateSize: number, scanMode: string): Map<string, string[]> => {
  if (scanMode === 'first') {
    // @ts-ignore
    return fromJS({ '0': ['FAM', 'SYBR'] });
  }
  if (scanMode === 'fret') {
    // @ts-ignore
    return fromJS({ '5': ['FRET'] });
  }
  // @ts-ignore
  const default96Fluors = fromJS({
    '0': ['FAM', 'SYBR'],
    '1': ['HEX', 'Cal Orange 560', 'Cal Gold 540', 'VIC'],
    '2': ['ROX', 'Texas Red', 'Cal Red 610', 'Tex 615'],
    '3': ['Cy5', 'Quasar 670'],
    '4': ['Quasar 705', 'Cy5-5']
  }).toOrderedMap();
  return plateSize === 384 ? default96Fluors.delete('4') : default96Fluors;
};

export function defaultWell() {
  return fromJS({
    type: 'unknown',
    fluors: {}
  });
}

export const fluorToChannelMapping: { [key: string]: any } = {
  FAM: '0',
  SYBR: '0',
  HEX: '1',
  'Cal Orange 560': '1',
  'Cal Gold 540': '1',
  VIC: '1',
  'Texas Red': '2',
  ROX: '2',
  'Cal Red 610': '2',
  'Tex 615': '2',
  Cy5: '3',
  'Quasar 670': '3',
  'Quasar 705': '4',
  'Cy5-5': '4',
  FRET: '5'
};

export const updateChannelMap = (plate: Map<string, any>) => {
  const wells = plate.get('wells');
  let fluorsInPlate = Set();
  wells.forEach((well: Map<string, any>) => {
    well.get('fluors').forEach((fluor: Map<string, any>) => {
      fluorsInPlate = fluorsInPlate.add(fluor.get('name'));
    });
  });
  const channelMap = fluorsInPlate.reduce(
    // @ts-ignore
    (map, fluor) => map.set(fluor, parseInt(fluorToChannelMapping[fluor], 10)),
    Map()
  );
  return plate.set('channelMap', channelMap);
};

const defaultWellFirst = fromJS({
  type: 'unknown',
  fluors: {
    '0': {
      name: 'SYBR'
    }
  }
});

const defaultWellFret = fromJS({
  type: 'unknown',
  fluors: {
    '5': {
      name: 'FRET'
    }
  }
});
const defaultWellAll96 = fromJS({
  type: 'unknown',
  fluors: {
    '0': {
      name: 'FAM'
    },
    '1': {
      name: 'HEX'
    },
    '2': {
      name: 'Texas Red'
    },
    '3': {
      name: 'Cy5'
    },
    '4': {
      name: 'Quasar 705'
    }
  }
});
const defaultWellAll384 = fromJS({
  type: 'unknown',
  fluors: {
    '0': {
      name: 'FAM'
    },
    '1': {
      name: 'HEX'
    },
    '2': {
      name: 'Texas Red'
    },
    '3': {
      name: 'Cy5'
    }
  }
});

const defaultWellMap: { [key: string]: any } = {
  first: { '96': defaultWellFirst, '384': defaultWellFirst },
  all: { '96': defaultWellAll96, '384': defaultWellAll384 },
  fret: { '96': defaultWellFret, '384': defaultWellFret }
};

export function getDefaultWell(size: number, scanMode: string) {
  return defaultWellMap[scanMode][size];
}

const defaultChannelMapFirst = fromJS({ SYBR: 0 });
const defaultChannelMapFret = fromJS({ FRET: 5 });
const defaultChannelMapAll96 = fromJS({
  FAM: 0,
  HEX: 1,
  'Texas Red': 2,
  Cy5: 3,
  'Quasar 705': 4
});
const defaultChannelMapAll384 = fromJS({
  FAM: 0,
  HEX: 1,
  'Texas Red': 2,
  Cy5: 3
});

const defaultChannelMapMapping: { [key: string]: any } = {
  first: { '96': defaultChannelMapFirst, '384': defaultChannelMapFirst },
  all: { '96': defaultChannelMapAll96, '384': defaultChannelMapAll384 },
  fret: { '96': defaultChannelMapFret, '384': defaultChannelMapFret }
};

export function getDefaultChannelMap(size: number, scanMode: string) {
  return defaultChannelMapMapping[scanMode][size];
}

export const getDefaultPlate = (size: number, scanMode: string, plateType: string) => {
  const well = getDefaultWell(size, scanMode);
  const channelMap = getDefaultChannelMap(size, scanMode);
  const [rows, columns] = size === 96 ? [8, 12] : [16, 24];

  const wells: {
    [key: string]: any;
  } = {};
  for (let row = 1; row <= rows; row += 1) {
    for (let col = 1; col <= columns; col += 1) {
      wells[numToChar(row) + col] = well;
    }
  }

  return fromJS({
    layout: { rows, columns },
    channelMap,
    plateType,
    scanMode,
    wells
  });
};

export const scanModes: { [key: string]: string } = {
  first: 'SYBR/FAM only',
  all: 'All Channels',
  fret: 'FRET'
};

/* START Plate validation functions */

const validateForFluors = (newPlate: Map<string, any>) => {
  const wells = newPlate.get('wells');
  return wells.reduce((errors: Map<string, any>, well: Map<string, any>, wellKey: string) => {
    const fluors = well.get('fluors');
    return fluors && fluors.isEmpty() && well.size > 1
      ? errors.setIn(['noFluorsError', wellKey], true)
      : errors;
  }, Map());
};

const validateReplicates = (plate: Map<string, any>) => {
  const wells = plate.get('wells');
  const replicateGroups = wells.reduce(
    (groups: Map<string, any>, well: Map<string, any>, wellKey: string) => {
      if (well.get('replicate') === undefined) return groups;
      return groups.setIn([well.get('replicate'), well.get('type'), wellKey], well);
    },
    Map()
  );

  return replicateGroups.reduce(
    (errors: Map<string, any>, replicateGroup: Map<string, any>, replicate: string) => {
      if (replicate === undefined) return errors;
      return replicateGroup.reduce((errorByType, replicateGroupByType, type) => {
        const wellsInGroup = replicateGroupByType.valueSeq();
        const firstWell = wellsInGroup.first();
        if (!wellsInGroup.every((well: Map<string, any>) => firstWell.equals(well))) {
          return errorByType.setIn(
            ['replicateGroupError', replicate, type],
            replicateGroupByType.keySeq()
          );
        }
        return errorByType;
      }, errors);
    },
    Map()
  );
};

const validateMaxLengths = (newPlate: Map<string, any>): Map<string, any> => {
  const wells = newPlate.get('wells');
  let plateErrors = Map<string, any>();

  const isLengthInvalid = (stringToCheck: string | null | undefined): boolean =>
    (stringToCheck && stringToCheck.length > 15) || false;

  const maxLengthError = 'Max. character length met';
  const replicateValueError = 'Must be between 1 & 999';
  wells.forEach((well: Map<string, any>, wellId: string) => {
    if (isLengthInvalid(well.get('biogroup')))
      plateErrors = plateErrors.setIn(['biogroup', wellId], maxLengthError);

    if (isLengthInvalid(well.get('sample')))
      plateErrors = plateErrors.setIn(['sample', wellId], maxLengthError);

    const replicate = well.get('replicate');
    if ((replicate && replicate > 999) || replicate <= 0) {
      plateErrors = plateErrors.setIn(['replicate', wellId], replicateValueError);
    }

    const fluors = well.get('fluors');
    if (fluors && fluors.size > 0) {
      plateErrors = fluors
        .keySeq()
        .reduce(
          (accum: Map<string, any>, channel: Map<string, any>) =>
            isLengthInvalid(fluors.getIn([channel, 'target']))
              ? accum.setIn(['target', channel, wellId], maxLengthError)
              : accum,
          plateErrors
        );
      if (well.get('type') === 'standard') {
        fluors.forEach((channel: Map<string, any>, key: string) => {
          const conc = parseFloatIfNot(channel.get('concentration'));
          if (conc === undefined || !(conc >= Number.MIN_VALUE && conc <= Number.MAX_VALUE)) {
            plateErrors = plateErrors.setIn(
              ['concentration', wellId, key],
              'Must be between 5E-324 & 1.79E+308'
            );
          }
        });
      }
    }
  });
  return plateErrors;
};

const isPlateEmpty = (plate: Map<string, any>) => {
  const wells = plate.get('wells');
  if (!wells || wells.size === 0) {
    return true;
  }
  return wells.every((well: Map<string, any>) => {
    const fluors = well.get('fluors');
    return !fluors || !fluors.size;
  });
};

export const getPlateErrors = (newPlate: Map<string, any>): Map<string, any> => {
  let plateErrors = Map<string, any>();
  if (isPlateEmpty(newPlate)) {
    plateErrors = plateErrors.set('plateEmpty', true);
  }
  plateErrors = plateErrors.merge(validateMaxLengths(newPlate));
  plateErrors = plateErrors.merge(validateReplicates(newPlate));
  plateErrors = plateErrors.merge(validateForFluors(newPlate));
  return plateErrors;
};

/* END Plate validation functions */
