import React from 'react';
import kabob from '../../../../img/icons-table-options.svg';
import ButtonMenu, { TextMenuItem } from '../../../common/button_menu';

type Props = {
  handlePrint: (...args: Array<any>) => any;
  handleCSVDownload: (...args: Array<any>) => any;
  handlePNGDownload: (...args: Array<any>) => any;
};

export default ({ handlePrint, handleCSVDownload, handlePNGDownload }: Props) => (
  <ButtonMenu menuId="pcr-chart-buttonmenu" btnImage={kabob}>
    <TextMenuItem text="Print chart" onSelection={handlePrint} id="print-chart-link" />
    <TextMenuItem
      text="Download PNG image"
      onSelection={handlePNGDownload}
      id="download-png-link"
    />
    <TextMenuItem text="Download CSV" onSelection={handleCSVDownload} id="download-csv-link" />
  </ButtonMenu>
);
