import React from 'react';
import RadioButtonGroup, { Props as RadioButtonGroupProps } from './RadioButtonGroup';

type Props = {
  id: string;
} & RadioButtonGroupProps;

export default function ({ id, name, onChange, checkedValue, options }: Props) {
  return (
    <div id={id} className="br-toggle">
      <RadioButtonGroup
        name={name}
        onChange={onChange}
        checkedValue={checkedValue}
        options={options}
      />
    </div>
  );
}
