import request from '../frontend-common-libs/src/utils/httpUtils';
import { GATEWAY } from '../frontend-common-libs/src/config/config';

export interface ContactMessage {
  sender: string;
  subject: string;
  message: string;
  country: string;
  phone?: string;
}

const domain = GATEWAY.CUSTOMER_SUPPORT_SERVICE.BASE_URL;

export default function submitUserIssue(attributes: ContactMessage): Promise<any> {
  const path = GATEWAY.CUSTOMER_SUPPORT_SERVICE.POST_CUSTOMER_SUPPORT;
  return request.post(domain, {}, path, {}, attributes);
}

export function getMaintenanceMessage(): Promise<any> {
  const path = GATEWAY.CUSTOMER_SUPPORT_SERVICE.GET_MAINTENANCE_MESSAGE;
  return request.get(domain, {}, path, {});
}
