import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Set, Map, Collection } from 'immutable';
import classnames from 'classnames';
import AutoSuggest from './AutoSuggest';
import FloatField from '../../../frontend-common-libs/src/components/common/FloatField';
import {
  formatId,
  Select,
  SelectMenuItem
} from '../../../frontend-common-libs/src/components/common/dropdown';
import Swatch from './Swatch';
import { getFluorColor, getTargetColorByIndex } from '../../../utils/microplateUtils';
import { updatePlateTargets as updatePlateTargetsAction } from '../../../actions/currentCfxRun_actions';
import { getTargets } from '../../../selectors/selectors';
import { ReduxState } from '../../../types';

export type Props = {
  selectedFluor?: string;
  targetName?: string;
  targetPlaceHolder?: string;
  concentration?: number;
  inputChange: (...args: Array<any>) => any;
  showConcentration: boolean;
  isFirstEntry: boolean;
  canEditConcentration?: boolean;
  concentrationPlaceHolder?: string;
  handleConcentrationChange: (fluor: string, concentration?: number | string) => any;
  removeFluors: (...args: Array<any>) => any;
  fluorsOptions: Collection<any, any>;
  index: number | string;
  targetError?: string;
  concentrationError?: string;
  keywords?: Set<string>;
  updatePlateTargets: (newTarget: string, oldTarget: string) => void;
  plateTargets: Map<string, any>;
};

export class FluorophoreSelectorImpl extends PureComponent<Props> {
  static defaultProps = {
    selectedFluor: '',
    targetName: '',
    targetPlaceHolder: undefined,
    concentration: undefined,
    canEditConcentration: true,
    concentrationPlaceHolder: undefined,
    targetError: undefined,
    concentrationError: undefined,
    keywords: undefined
  };

  initTarget = '';

  getSwatchColor = (selectedFluor?: string): string | null | undefined => {
    const flourColor = selectedFluor && getFluorColor(selectedFluor);
    return flourColor ? flourColor.get('primary') : undefined;
  };

  updateFluor = (value: string) => {
    const { inputChange, selectedFluor, targetName } = this.props;
    inputChange(selectedFluor, value, targetName);
  };

  handleTargetNameChange = (targetName: string) => {
    const { selectedFluor, inputChange } = this.props;
    inputChange(selectedFluor, selectedFluor, targetName);
  };

  handleConcentrationChange = (name: string, value?: number | string) => {
    const { handleConcentrationChange, selectedFluor } = this.props;
    if (selectedFluor) {
      handleConcentrationChange(selectedFluor, value);
    }
  };

  removeFluors = () => {
    const { selectedFluor, removeFluors } = this.props;
    removeFluors(selectedFluor);
  };

  updateTargets = (e: React.SyntheticEvent<HTMLInputElement>) => {
    // @ts-ignore
    const { value } = e.target;
    if (value) {
      const { updatePlateTargets } = this.props;
      updatePlateTargets(value, this.initTarget);
    }
  };

  saveInitTarget = () => {
    const { targetName } = this.props;
    this.initTarget = targetName || '';
  };

  renderRemoveOption = () => {
    const { selectedFluor, index } = this.props;
    if (selectedFluor) {
      return (
        <button
          id={`remove-fluor_${index}`}
          type="button"
          onClick={this.removeFluors}
          title="Remove fluorophore"
          className="btn-noborder plate-remove-btn"
        />
      );
    }
    return null;
  };

  renderTargetName = () => {
    const {
      index,
      targetError,
      targetName,
      targetPlaceHolder,
      keywords,
      selectedFluor,
      plateTargets
    } = this.props;

    if (selectedFluor) {
      let targetColor;
      if (plateTargets) {
        // @ts-ignore
        const targetColorIndex = plateTargets.get(targetName);
        if (targetColorIndex !== undefined) {
          const colors = getTargetColorByIndex(targetColorIndex);
          targetColor = colors.get('primary');
        }
      }

      return (
        <div className="flex-item control-content">
          <div className={classnames('form-group', { 'has-error': targetError })}>
            <div className="swatch-wrapper">
              <Swatch id={`target_swatch_${index}`} backgroundColor={targetColor} />
              <AutoSuggest
                id={`target_${index}`}
                value={targetName}
                onChange={this.handleTargetNameChange}
                // @ts-ignore
                onBlur={this.updateTargets}
                onFocus={this.saveInitTarget}
                placeholder={targetPlaceHolder}
                keywords={keywords}
              />
            </div>
            {targetError && (
              <div id={`target_${index}-error`} className="error-message">
                {targetError}
              </div>
            )}
          </div>
        </div>
      );
    }
    return null;
  };

  renderConcentration = () => {
    const {
      canEditConcentration,
      isFirstEntry,
      concentrationError,
      index,
      concentration,
      concentrationPlaceHolder
    } = this.props;
    const inputDisabled = !canEditConcentration || !isFirstEntry;
    return (
      <div className="flex-item control-content">
        <div
          className={classnames('form-group', {
            'has-error': concentrationError
          })}
        >
          <FloatField
            id={`concentration_${index}`}
            name="concentration"
            // @ts-ignore
            className={inputDisabled ? 'display-as-label' : null}
            defaultValue={concentration}
            onChange={this.handleConcentrationChange}
            onBlur={this.handleConcentrationChange}
            allowNegative
            maxDecimalLength={100}
            disabled={inputDisabled}
            placeholder={concentrationPlaceHolder}
            allowScientificNotation
            displayInScientificNotationOnly
          />
          {!inputDisabled && concentrationError && (
            <div id={`concentration_${index}-error`} className="error-message">
              {concentrationError}
            </div>
          )}
        </div>
      </div>
    );
  };

  renderFluorsOptions(selectId: string) {
    const { fluorsOptions } = this.props;
    return fluorsOptions.map<any>(option => {
      const formattedId = formatId(selectId, option);
      return (
        <SelectMenuItem id={formattedId} value={option} key={formattedId}>
          {option}
        </SelectMenuItem>
      );
    });
  }

  renderFluorsRow = () => {
    const { index, selectedFluor, showConcentration } = this.props;
    const flourColors = this.getSwatchColor(selectedFluor);
    const fluorsSelectId = `fluor_${index}`;
    return (
      <div className="flex-wrapper nopad">
        <div className="flex-item control-content">
          <div className="swatch-wrapper">
            <Swatch id={`fluor_swatch_${index}`} backgroundColor={flourColors} />
            <Select
              id={fluorsSelectId}
              value={selectedFluor}
              placeholder="Select Fluorophore"
              handleSelection={this.updateFluor}
            >
              {this.renderFluorsOptions(fluorsSelectId)}
            </Select>
          </div>
        </div>
        {this.renderTargetName()}
        {showConcentration && this.renderConcentration()}
        {this.renderRemoveOption()}
      </div>
    );
  };

  render() {
    return this.renderFluorsRow();
  }
}

const mapStateToProps = (state: ReduxState) => {
  const plateTargets = getTargets(state);
  return { plateTargets };
};

export default connect(mapStateToProps, { updatePlateTargets: updatePlateTargetsAction })(
  FluorophoreSelectorImpl
);
