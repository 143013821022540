import IOTTopic from './iot-topic';
import IOTCredentialsProvider from './IOTCredentialsProvider';
import StartTopicAction from './start-topic-action';
import InstrumentShadowGroup from './InstrumentShadowGroup';
import StartInstrumentGroupAction from './start-instrumentgroup-action';
import { InstrumentStatusCallback, MessageCallback } from './types';
import IOTCredentials from './iot-credentials';

export default class IoTConnection {
  private userTopic: IOTTopic | null | undefined = null;

  private iotCredentialsProvider: IOTCredentialsProvider | null | undefined = null;

  private startTopicAction: StartTopicAction | null | undefined = null;

  private instrumentShadowGroupCollection: InstrumentShadowGroup[] = [];

  private startShadowGroupAction: StartInstrumentGroupAction | null | undefined = null;

  private restartTimeoutId: NodeJS.Timeout | null | undefined = null;

  private getIOTCredentialsProvider = (reset = false): IOTCredentialsProvider => {
    if (reset || !this.iotCredentialsProvider) {
      this.iotCredentialsProvider = new IOTCredentialsProvider();
    }
    return this.iotCredentialsProvider;
  };

  public stop = (): void => {
    this.cancelStartTopic();
    this.stopTopic();
    this.cancelStartShadow();
    this.stopShadow();

    this.clearRestartTimeout();

    this.iotCredentialsProvider = null;
  };

  public stopShadow = (): void => {
    if (this.instrumentShadowGroupCollection.length) {
      this.instrumentShadowGroupCollection.forEach(x => x.end());
      this.instrumentShadowGroupCollection = [];
    }
  };

  public startTopic = async (
    messageCallback: MessageCallback,
    onReconnect: (...args: Array<any>) => any
  ): Promise<void> => {
    this.cancelStartTopic();
    this.startTopicAction = new StartTopicAction(
      messageCallback,
      onReconnect,
      this.getIOTCredentialsProvider()
    );
    try {
      const { userTopic, credentials } = await this.startTopicAction.execute();
      this.stopTopic();
      this.userTopic = userTopic;
      if (!this.restartTimeoutId) {
        this.setRestartTimeout(credentials);
      }
    } catch (err) {
      // ignore error
    }
  };

  public startShadow = async (
    instrumentStatusCallback: InstrumentStatusCallback
  ): Promise<void> => {
    this.cancelStartShadow();
    this.startShadowGroupAction = new StartInstrumentGroupAction(
      instrumentStatusCallback,
      this.getIOTCredentialsProvider(true)
    );

    try {
      const { instrumentShadowGroupCollection, credentials } =
        await this.startShadowGroupAction.execute();
      this.stopShadow();
      this.instrumentShadowGroupCollection = instrumentShadowGroupCollection;
      if (!this.restartTimeoutId) {
        this.setRestartTimeout(credentials);
      }
    } catch (err) {
      // ignore error
    }
  };

  private restart = async (): Promise<void> => {
    this.restartTimeoutId = null;
    if (this.startShadowGroupAction) {
      await this.startShadow(this.startShadowGroupAction.instrumentStatusCallback);
    }
    if (this.startTopicAction) {
      this.startTopic(
        this.startTopicAction.messageCallback,
        this.startTopicAction.reconnectCallback
      );
    }
  };

  private cancelStartShadow(): void {
    if (this.startShadowGroupAction) {
      this.startShadowGroupAction.cancel();
    }
  }

  private cancelStartTopic(): void {
    if (this.startTopicAction) {
      this.startTopicAction.cancel();
    }
  }

  private stopTopic(): void {
    if (this.userTopic) {
      this.userTopic.end();
    }
  }

  private setRestartTimeout(credentials: IOTCredentials): void {
    this.restartTimeoutId = setTimeout(this.restart, credentials.validFor());
  }

  private clearRestartTimeout(): void {
    if (this.restartTimeoutId) {
      clearTimeout(this.restartTimeoutId);
      this.restartTimeoutId = null;
    }
  }
}
