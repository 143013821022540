import React from 'react';
import CommonInstrumentTile from '../../frontend-common-libs/src/instruments/components/recent-instruments/instrument-tile/InstrumentTile';
import { ImmutableMap, InstrumentItem } from '../../frontend-common-libs/src/common/types';
import InstrumentFacade, { BlockEnum, BlockIdentifierEnum } from '../common/InstrumentFacade';

export enum BlockLabelEnum {
  BlockA = '(A)' as any,
  BlockB = '(B)' as any
}

type InstrumentBlockTileProps = {
  block: BlockEnum;
  instrument: ImmutableMap<InstrumentItem>;
};

function InstrumentBlockTile(props: InstrumentBlockTileProps) {
  const { instrument, block } = props;
  const { modelName, instrumentIcon, instrumentStatus } = new InstrumentFacade(instrument, block);
  const deviceType = instrument.get('deviceType');
  const name = instrument.get('name');
  const deviceId = instrument.get('deviceId');
  const iconAlt = instrumentStatus ? `${deviceType} ${instrumentStatus}` : `${deviceType}`;
  const icon = { src: instrumentIcon, alt: iconAlt };

  function getTileIdentifier() {
    switch (block) {
      case BlockEnum.BlockA:
        return `${deviceId}${BlockIdentifierEnum.BlockA}`;
      case BlockEnum.BlockB:
        return `${deviceId}${BlockIdentifierEnum.BlockB}`;
      default:
        return '';
    }
  }

  const blockTileIdentifier = getTileIdentifier();

  const displayBlockLabel = BlockLabelEnum[block as any];

  return (
    <CommonInstrumentTile
      id={blockTileIdentifier}
      name={name}
      status={instrumentStatus ?? ''}
      modelName={modelName}
      icon={icon}
      block={displayBlockLabel}
    />
  );
}

export default InstrumentBlockTile;
export type { InstrumentBlockTileProps };
