export type CompleteRegistrationActionType =
  | 'complete_registration_in_progress'
  | 'complete_registration_success'
  | 'complete_registration_error'
  | 'hide_verified_account_message_on_login';

export const COMPLETE_REGISTRATION_IN_PROGRESS: CompleteRegistrationActionType =
  'complete_registration_in_progress';
export const COMPLETE_REGISTRATION_SUCCESS: CompleteRegistrationActionType =
  'complete_registration_success';
export const COMPLETE_REGISTRATION_ERROR: CompleteRegistrationActionType =
  'complete_registration_error';
export const HIDE_VERIFIED_ACCOUNT_MESSAGE_ON_LOGIN: CompleteRegistrationActionType =
  'hide_verified_account_message_on_login';
