export const FleetToggleOptions = [
  { id: 'reservation-create', label: 'Create Reservation', value: 'create', disabled: false },
  {
    id: 'reservation-manage',
    label: 'Manage Reservations',
    value: 'manage',
    disabled: false
  },
  {
    id: 'instrument-control',
    label: 'Instrument Control',
    value: 'control',
    disabled: false
  }
];
export const FleetToggleOptionsEnum = {
  Create: 'create',
  Manage: 'manage',
  Control: 'control'
};
