import React from 'react';
import HeroGraphicUpdated from '../../../img/hero_graphic_updated.svg';

export default function () {
  const bannerGraphic = HeroGraphicUpdated;

  const captionParagraph =
    'The innovative BR.io cloud platform enables researchers to design and manage experiments from anywhere at any time.';

  return (
    <div className="hero-banner">
      <div className="caption">
        <h1>Connecting the Laboratory</h1>
        <p>{captionParagraph}</p>
      </div>
      <div className="banner-images">
        <img src={bannerGraphic} className="banner-img" alt="hero graphic" />
        <img src="img/early_access.png" className="early-access-label" alt="early access" />
      </div>
    </div>
  );
}
