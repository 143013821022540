import BaseViewModel from '../../../common/mvvm/BaseViewModel';

export default class ModalDialogViewModel extends BaseViewModel {
  protected _shouldBlock = false;

  public get shouldBlock() {
    return this._shouldBlock;
  }

  protected showDialog() {
    this._shouldBlock = true;
    this.fireStateChanged();
  }

  hideDialog() {
    this._shouldBlock = false;
    this.fireStateChanged();
  }

  onCancel = () => {
    this.hideDialog();
  };

  protected _saveInProgress = false;

  public get saveInProgress() {
    return this._saveInProgress;
  }

  public set saveInProgress(value: boolean) {
    this.notifyIfChanged('_saveInProgress', value);
  }
}
