import { ReactElement } from 'react';
import HelpCenter from './HelpCenter';
import CommonMenuItemLink from '../../frontend-common-libs/src/components/common-help-link/CommonMenuItemLink';
import HelpPageViewedTrackingEvent from '../../frontend-common-libs/src/user-analytics/HelpPageViewedTrackingEvent';

type HelpLinkProps = {
  path: string;
  coreHelpCenterDomain: string;
  render: (path: RenderProps) => React.ReactElement<typeof CommonMenuItemLink>;
};

export type RenderProps = {
  to: string | undefined;
  onSelection?: Function;
};

export default function HelpLink({
  path,
  coreHelpCenterDomain,
  render
}: HelpLinkProps): ReactElement<typeof CommonMenuItemLink> {
  const helpUrl = new HelpCenter(coreHelpCenterDomain).getHelpUrl(path);
  let props: RenderProps = { to: helpUrl };
  if (helpUrl) {
    props = { ...props, onSelection: () => new HelpPageViewedTrackingEvent(helpUrl).track() };
  }

  return render(props);
}
