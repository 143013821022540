import { DispatchType, ORG_MEMBER_ROLE_CHANGED, ORG_TOKEN_REVOKED } from './action-types';
import { setOrgMemberRole } from '../api/org-api';
import notification from '../../frontend-common-libs/src/utils/notifications';
import { GetState, ReduxState } from '../../types';
import { getUserEmail } from '../../selectors/auth-selectors';
import {
  TrackedOrganizationEvents,
  trackEvent
} from '../../frontend-common-libs/src/user-analytics/trackedEvents';
import { isAtLeastOneAdminError } from '../api/api-errors';
import { CbWithToken } from './WithRefreshToken';
import { withRefreshOrgToken } from './with-refresh-org-token';

export function dispatchOrgRoleChanged(
  dispatch: DispatchType,
  state: ReduxState,
  orgId: string,
  userName: string,
  role: string
) {
  dispatch({
    type: ORG_MEMBER_ROLE_CHANGED,
    payload: { orgId, userName, role }
  });
  const email = getUserEmail(state);
  if (userName === email) {
    dispatch({
      type: ORG_TOKEN_REVOKED,
      payload: { orgId }
    });
  }
}

export function setOrganizationRole(orgId: string, userName: string, role: string) {
  return async (dispatch: DispatchType, getState: GetState) => {
    try {
      const state = getState();
      const setOrgMemberRoleCb: CbWithToken = async (accessToken: string) => {
        return setOrgMemberRole(orgId, userName, role, accessToken);
      };
      await withRefreshOrgToken(dispatch, getState, orgId, setOrgMemberRoleCb);
      dispatchOrgRoleChanged(dispatch, state, orgId, userName, role);
      trackEvent(TrackedOrganizationEvents.AssignOrgRole, {
        userName,
        role
      });
    } catch (error) {
      let errMsg = 'Error changing member role in the organization';
      if (isAtLeastOneAdminError(error))
        errMsg = 'An organization must have at least one administrator';
      notification.error(errMsg);
    }
  };
}
