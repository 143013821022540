import React, { CSSProperties } from 'react';
import { Colors, rowStyle } from './styles';
import { NullableString } from '../../../../../../frontend-common-libs/src/common/nullable-types';
import { toHtmlId } from '../../../../../../frontend-common-libs/src/common/strings';

export class TableModel {
  header1: NullableString;

  header2: NullableString;

  header3: NullableString;

  header4: NullableString;

  data1: NullableString;

  data2: NullableString;

  data3: NullableString;

  data4: NullableString;
}

export type Props = { tableModel: TableModel; tableStyle?: any; tableName: string };

function getKeyId(key: NullableString) {
  if (key) return toHtmlId(key);
  return '';
}

export default function ReportTable(props: Props) {
  const { tableModel, tableStyle, tableName } = props;
  const headerStyle = { ...rowStyle, color: Colors.headerText, paddingBottom: '1px' };
  const dataStyle = {
    ...rowStyle,
    color: Colors.dataText,
    paddingTop: '1px',
    paddingRight: '6px',
    wordBreak: 'break-word',
    verticalAlign: 'top'
  } as CSSProperties;
  const keyIds = [
    getKeyId(tableModel.header1),
    getKeyId(tableModel.header2),
    getKeyId(tableModel.header3),
    getKeyId(tableModel.header4)
  ];
  return (
    <table id={`${tableName}-report-table`} style={{ marginLeft: '24px', ...tableStyle }}>
      <thead>
        <tr>
          <th id={`${tableName}-header-${keyIds[0]}`} style={{ ...headerStyle, width: '308px' }}>
            {tableModel.header1}
          </th>
          {tableModel.header2 && (
            <th id={`${tableName}-header-${keyIds[1]}`} style={{ ...headerStyle, width: '308px' }}>
              {tableModel.header2}
            </th>
          )}
          {tableModel.header3 && (
            <th id={`${tableName}-header-${keyIds[2]}`} style={{ ...headerStyle, width: '234px' }}>
              {tableModel.header3}
            </th>
          )}
          {tableModel.header4 && (
            <th id={`${tableName}-header-${keyIds[3]}`} style={{ ...headerStyle, width: '234px' }}>
              {tableModel.header4}
            </th>
          )}
        </tr>
      </thead>
      <tbody>
        <tr>
          <td id={`${tableName}-data-${keyIds[0]}`} style={dataStyle}>
            {tableModel.data1}
          </td>
          {tableModel.header2 && (
            <td id={`${tableName}-data-${keyIds[1]}`} style={dataStyle}>
              {tableModel.data2}
            </td>
          )}
          {tableModel.header3 && (
            <td id={`${tableName}-data-${keyIds[2]}`} style={dataStyle}>
              {tableModel.data3}
            </td>
          )}
          {tableModel.header4 && (
            <td id={`${tableName}-data-${keyIds[3]}`} style={dataStyle}>
              {tableModel.data4}
            </td>
          )}
        </tr>
      </tbody>
    </table>
  );
}
