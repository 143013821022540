import { OrderedMap, Map, Set, fromJS, isImmutable, Seq, OrderedSet } from 'immutable';
import { ImmutableMap } from '../common/types';

export function jsArrayToIndexedSeq<T extends Record<string, any>>(
  list: Array<T>,
  key: string
): Seq.Indexed<Map<string, Object>> {
  let map = OrderedMap({});
  list.forEach((item: T) => {
    map = map.set(item[key], fromJS(item));
  });
  return map.toIndexedSeq() as Seq.Indexed<Map<string, Object>>;
}

export function jsArrayToOrderedMap<T extends Record<string, any>>(
  list: Array<T>,
  key: string
): OrderedMap<string, ImmutableMap<T>> {
  let map: OrderedMap<string, ImmutableMap<T>> = OrderedMap({});
  list.forEach((item: T) => {
    // @ts-ignore
    map = map.set(item[key], fromJS(item));
  });
  return map;
}

export function diffMapForKeys(
  newMap: Map<any, any>,
  oldMap: Map<any, any>,
  keys: Array<string>
): Map<any, any> {
  return keys.reduce((res: Map<any, any>, key: string) => {
    if (isImmutable(newMap.get(key))) {
      if (!newMap.get(key).equals(oldMap.get(key))) {
        return res.set(key, newMap.get(key));
      }
    } else if (newMap.get(key) !== oldMap.get(key)) {
      return res.set(key, newMap.get(key) || null);
    }

    return res;
  }, Map());
}

export function diffMap(
  newMap: Map<any, any>,
  oldMap: Map<any, any>,
  excludeKeys?: Array<string>
): Map<any, any> {
  let keys = Set.fromKeys(newMap).union(Set.fromKeys(oldMap));
  if (excludeKeys) {
    keys = keys.subtract(excludeKeys);
  }
  return diffMapForKeys(newMap, oldMap, keys.toArray());
}

// returns the value if falsy else returns hashcode of the value
export function maybeHash(value?: Map<any, any> | any) {
  return value && value.hashCode();
}

// replaces the entry in an OrderedMap keeping the same order
export function replaceOrderedMapEntry(
  orderedMap: OrderedMap<any, any>,
  oldKey: string,
  newKey: string,
  newEntry: string | number | Map<any, any> | OrderedMap<any, any> | Set<any> | OrderedSet<any>
) {
  if (oldKey === newKey) return orderedMap.set(newKey, newEntry);
  // oldkey is not the same as new key
  // make sure newkey does not already exist
  if (orderedMap.has(newKey)) throw new Error('bad newKey');
  return orderedMap.mapEntries(([key, entry]) =>
    key === oldKey ? [newKey, newEntry] : [key, entry]
  );
}
