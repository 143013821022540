import React from 'react';
import { RouteComponentProps } from 'react-router';
import { match as Match, Route } from 'react-router-dom';
import { fromJS, List, Map } from 'immutable';
import FooterNavBar from '../../../frontend-common-libs/src/components/common/FooterNav';

export type Props = {
  match: RouteComponentProps['match'];
};

export default class RunFooterNavBar extends React.Component<Props> {
  renderFooterNav = ({ match }: { match: Match }) => {
    const {
      // @ts-ignore
      params: { activeTab }
    } = match;
    if (!activeTab) return null;

    const {
      match: { url }
    } = this.props;

    const links = fromJS([
      {
        id: 'protocol',
        pageId: 1,
        title: 'Protocol',
        isDisabled: false
      },
      {
        id: 'details',
        pageId: 2,
        title: 'Run',
        isDisabled: false
      }
    ]) as List<Map<string, any>>;
    // @ts-ignore
    const lastTabId = links.last().get('id');
    const navButtons = fromJS({
      prevBtn: {
        // @ts-ignore
        isDisabled: activeTab === links.first().get('id'),
        title: 'Previous'
      },
      nextBtn: {
        // @ts-ignore
        isDisabled: activeTab === links.last().get('id'),
        title: 'Next'
      }
    }) as Map<string, any>;

    return (
      <FooterNavBar
        links={links}
        baseUrl={url}
        activeTab={activeTab}
        navButtons={navButtons}
        showNextButton={activeTab !== lastTabId}
      />
    );
  };

  render() {
    const { match } = this.props;
    return <Route path={`${match.path}/:activeTab`} render={this.renderFooterNav} />;
  }
}
