import PlateSize from '../PlateSize';
import Gradient, { GradientLowerTemperature, GradientRange } from '../../Gradient';
import Gradient96 from './Gradient96';
import Gradient96D from './Gradient96D';
import Gradient48 from '../gradient-48';
import Gradient384 from '../gradient-384';

export default class GradientFactory {
  public static getGradient(
    plateSize: PlateSize,
    lowerTemperature: GradientLowerTemperature,
    gradientRange: GradientRange,
    isValid: boolean
  ): Gradient {
    if (plateSize === PlateSize.PlateSize96) {
      return new Gradient96(lowerTemperature, gradientRange, isValid);
    }

    if (plateSize === PlateSize.PlateSize96D) {
      return new Gradient96D(lowerTemperature, gradientRange, isValid);
    }

    if (plateSize === PlateSize.PlateSize48) {
      return new Gradient48(lowerTemperature, gradientRange, isValid);
    }

    if (plateSize === PlateSize.PlateSize384) {
      return new Gradient384(lowerTemperature, gradientRange, isValid);
    }

    throw new Error('Unknown Plate Size');
  }
}
