import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { ReduxState } from '../../types';
import { confirmRegistration } from '../../actions/complete-registration/complete-registration-actions';
import UnAuthedFooter from './UnAuthedFooter';
import WithBioradIcons from './WithBioradIcons';
import AccountVerificationLoader from './AccountVerificationLoader';
import AccountConfirmationError from './AccountConfirmationError';
import UserIsVerifiedEvent from '../../user-analytics/email-verification/UserIsVerifiedEvent';
import PrimaryButton from '../../frontend-common-libs/src/common/buttons';

export type Props = {
  isLoading: boolean;
  errorMessage: string | undefined;
  completeRegistrationSucceeded: boolean;
  username: string;
  code: string;
  confirmRegistrationAction: typeof confirmRegistration;
};

export class CompleteRegistrationImpl extends PureComponent<Props> {
  completeAccountRegistration = () => {
    const { confirmRegistrationAction, username, code } = this.props;
    confirmRegistrationAction(code, username);
  };

  renderConfirmationButton() {
    const { isLoading } = this.props;
    return (
      <div id="complete-registration-container">
        <p>
          You are almost there! To complete the registration process and start using BR.io click the
          button below.
        </p>
        <div className="center-content">
          <PrimaryButton
            id="complete-registration-button"
            type="submit"
            isInProgress={isLoading}
            onClick={this.completeAccountRegistration}
          >
            Complete Registration
          </PrimaryButton>
        </div>
      </div>
    );
  }

  renderLoader() {
    const { username } = this.props;
    return (
      <AccountVerificationLoader
        title={`Verifying ${username}`}
        contentMessage="Please wait while we verify your email address."
      />
    );
  }

  renderError() {
    const { username } = this.props;
    return (
      <AccountConfirmationError
        title="Error"
        errorDescription="Something went wrong."
        username={username}
      />
    );
  }

  render() {
    const { isLoading, errorMessage, completeRegistrationSucceeded, username } = this.props;

    if (completeRegistrationSucceeded) {
      const userDomain = username.split('@')[1];
      new UserIsVerifiedEvent(userDomain).track();
      return <Redirect to="/login?registered" />;
    }

    return (
      <>
        <div className="complete-registration-container">
          <WithBioradIcons>
            {(isLoading && this.renderLoader()) ||
              (errorMessage && this.renderError()) ||
              this.renderConfirmationButton()}
          </WithBioradIcons>
        </div>
        <UnAuthedFooter className="content-container" />
      </>
    );
  }
}

const mapStateToProps = (state: ReduxState, ownProps: any) => {
  const { location } = ownProps;
  return {
    isLoading: state.completeRegistration.get('inProgress'),
    errorMessage: state.completeRegistration.get('errorMessage'),
    completeRegistrationSucceeded: state.completeRegistration.get('succeeded'),
    code: location ? new URLSearchParams(ownProps.location.search).get('code') : undefined,
    username: location ? new URLSearchParams(ownProps.location.search).get('username') : undefined
  };
};

export default connect(mapStateToProps, { confirmRegistrationAction: confirmRegistration })(
  CompleteRegistrationImpl as any
);
