import React from 'react';
import styles from './styles/no-reserved-instruments-message.module.scss';

export default function NoReservedInstrumentsMessage() {
  return (
    <div
      className={`flex-column-container ${styles.noReservationMessage}`}
      data-testid="no-reservation-message"
    >
      <p>No instruments are currently reserved.</p>
      <p>
        Go to the <strong>Create Reservation</strong> tab to reserve instruments.
      </p>
    </div>
  );
}
