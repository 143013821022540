import { CognitoUserAttribute } from 'amazon-cognito-identity-js';
import { getUserProfile, getUserSession } from '../../../frontend-common-libs/src/utils/authUtils';
import BackupUserDataRequest, {
  BackupUserDataRequestParams
} from '../../api/requests/BackupUserDataRequest';

export default class CurrentUserBackupTask {
  private session?: any;

  public async execute(): Promise<void> {
    const email = await this.getEmail();
    const customAttributes = await this.getUserAttributes();
    const idToken = await this.getIdToken();
    const accessToken = await this.getAccessToken();

    const params: BackupUserDataRequestParams = {
      email,
      customAttributes,
      idToken,
      accessToken
    };

    const backupUserDataRequest = new BackupUserDataRequest(params);
    await backupUserDataRequest.send();
  }

  public async getUserSubId(): Promise<string | null> {
    const customAttributes = await this.getUserAttributes();
    const subAttribute = customAttributes.find(
      (attr: CognitoUserAttribute) => attr.getName() === 'sub'
    );
    if (subAttribute) {
      return subAttribute.getValue();
    }
    return null;
  }

  private async getEmail(): Promise<string> {
    const { email } = await this.getUserSession();
    return email;
  }

  private async getUserAttributes(): Promise<CognitoUserAttribute[]> {
    const { currentUser } = await this.getUserSession();
    return getUserProfile(currentUser);
  }

  private async getIdToken(): Promise<string> {
    const { idToken } = await this.getUserSession();
    return idToken;
  }

  private async getAccessToken(): Promise<string> {
    const { accessToken } = await this.getUserSession();
    return accessToken;
  }

  private async getUserSession(): Promise<any> {
    if (!this.session) {
      this.session = await getUserSession();
    }
    return this.session;
  }
}
