import ModalDialogViewModel from '../../common/dialogs/ModalDialogViewModel';
import { validateFileName } from '../../common/run/validations/run-validation';

export enum ConflictOption {
  reload = 'reload',
  saveAs = 'saveAs'
}

export default class VersionConflictPromptVm extends ModalDialogViewModel {
  savedObject: any;

  protected _selectedOption: ConflictOption | undefined;

  public get selectedOption() {
    return this._selectedOption;
  }

  public set selectedOption(opt: ConflictOption | undefined) {
    this.notifyIfChanged('_selectedOption', opt);
  }

  protected _fileName: string | undefined;

  public get fileName() {
    return this._fileName;
  }

  public set fileName(name: string | undefined) {
    const changed = this.notifyIfChanged('_fileName', name);
    if (changed) this.validateFileName();
  }

  protected _fileNameError: string | undefined;

  public get fileNameError() {
    return this._fileNameError;
  }

  public set fileNameError(fileNameError: string | undefined) {
    this.notifyIfChanged('_fileNameError', fileNameError);
  }

  public get okBtnDisabled() {
    if (this.selectedOption == null) return true;
    if (this.selectedOption === ConflictOption.saveAs && this.fileNameError) return true;
    return false;
  }

  onReload: () => Promise<void>;

  onSaveAs: (fileName: string) => Promise<void>;

  constructor(onReload: () => Promise<void>, onSaveAs: (fileName: string) => Promise<void>) {
    super();
    this.onReload = onReload;
    this.onSaveAs = onSaveAs;
  }

  beforeSave(savedObject: any) {
    this.savedObject = savedObject;
  }

  showVersionConflictDialog(fileName: string) {
    this.selectedOption = undefined;
    this.fileName = fileName;
    this.showDialog();
  }

  onOk = async () => {
    this.saveInProgress = true;
    if (this.selectedOption === ConflictOption.reload) await this.onReload();
    else if (this.selectedOption === ConflictOption.saveAs)
      await this.onSaveAs(this.fileName as string);
    this.saveInProgress = false;
  };

  validateFileName() {
    this.fileNameError = validateFileName(this.fileName as string);
  }
}
