import UploadHandler from './UploadHandler';
import BaseViewModel from '../../common/mvvm/BaseViewModel';

export default class UploadHandlerRepository extends BaseViewModel {
  private handlers: Record<string, UploadHandler>;

  private constructor() {
    super();
    this.handlers = {};
  }

  private static _instance: UploadHandlerRepository | undefined = undefined;

  public static get instance(): UploadHandlerRepository {
    if (this._instance == null) this._instance = new UploadHandlerRepository();
    return this._instance;
  }

  getHandler(pathname: string) {
    return this.handlers[pathname];
  }

  addHandler(handler: UploadHandler) {
    this.handlers[handler.pathname] = handler;
    this.fireStateChanged();
  }
}
