import { ORG_TOKEN_LOADED, ORG_TOKEN_LOADING } from './action-types';
import { refreshOrgAccessToken } from '../api/org-api';
import { getOrgRefreshToken, getOrgUserToken } from '../selectors/selectors';
import { GetState } from '../../types';
import { CbWithToken, DispatchType, WithRefreshToken } from './WithRefreshToken';

export class WithRefreshOrgToken extends WithRefreshToken {
  constructor(
    protected dispatch: DispatchType,
    protected getState: GetState,
    protected orgId: string
  ) {
    super(dispatch, getState);
  }

  getToken(): string {
    return getOrgUserToken(this.getState(), this.orgId) as string;
  }

  getRefreshToken(): string {
    return getOrgRefreshToken(this.getState(), this.orgId) as string;
  }

  dispatchTokenLoading() {
    this.dispatch({
      type: ORG_TOKEN_LOADING,
      payload: { organizationId: this.orgId }
    });
  }

  dispatchTokenLoaded(token: any) {
    this.dispatch({
      type: ORG_TOKEN_LOADED,
      payload: { loginInfo: token, organizationId: this.orgId }
    });
  }

  // eslint-disable-next-line class-methods-use-this
  async refreshAccessToken(refreshToken: string, accessToken: string) {
    return refreshOrgAccessToken(refreshToken, accessToken);
  }
}

export async function withRefreshOrgToken(
  dispatch: DispatchType,
  getState: GetState,
  orgId: string,
  cb: CbWithToken
) {
  return new WithRefreshOrgToken(dispatch, getState, orgId).withRefreshToken(cb);
}
