import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import {
  setBaselineSubtraction,
  setDriftCorrection
} from '../../../../../actions/currentCfxRun_actions';
import {
  getSubtractBaseline,
  getDriftCorrection,
  hasCustomThreshold as getHasCustomThreshold
} from '../../../../../selectors/selectors';
import Checkbox from '../../../../../frontend-common-libs/src/common/checkbox';
import { LinkButton } from '../../../../../frontend-common-libs/src/components/common/buttons';
import ThresholdPanel from './ThresholdPanel';
import CyclesPanel from './CyclesPanel';
import { State } from '../../../../../types';

export type AmplificationSettingsProps = {
  subtractBaseline: boolean;
  toggleBaselineSubtraction: typeof setBaselineSubtraction;
  hasCustomThreshold: boolean;
  onPanelToggle: (...args: Array<any>) => any;
  onTabOut: (...args: Array<any>) => any;
  driftCorrection: boolean;
  toggleDriftCorrection: typeof setDriftCorrection;
};

type AmplificationSettingsState = {
  panel: string | null | undefined;
};

export class AmplificationSettingsImpl extends PureComponent<
  AmplificationSettingsProps,
  AmplificationSettingsState
> {
  static THRESHOLD_PANEL = 'threshold_panel';

  static CYCLES_PANEL = 'cycles_panel';

  constructor(props: AmplificationSettingsProps) {
    super(props);
    this.state = { panel: undefined };
  }

  onBaselineSubtractionChange = ({ target: { checked } }: React.ChangeEvent<HTMLInputElement>) => {
    const { subtractBaseline, toggleBaselineSubtraction } = this.props;
    if (subtractBaseline !== checked) {
      toggleBaselineSubtraction(checked);
    }
  };

  onDriftCorrectionChange = ({ target: { checked } }: React.ChangeEvent<HTMLInputElement>) => {
    const { driftCorrection, toggleDriftCorrection } = this.props;
    if (driftCorrection !== checked) {
      toggleDriftCorrection(checked);
    }
  };

  openThresholdPanel = () => {
    const { onPanelToggle } = this.props;
    this.setState({ panel: AmplificationSettingsImpl.THRESHOLD_PANEL }, onPanelToggle(true));
  };

  closePanel = () => {
    const { onPanelToggle } = this.props;
    this.setState({ panel: undefined }, onPanelToggle(false));
  };

  openBaselineCyclesPanel = () => {
    const { onPanelToggle } = this.props;
    this.setState({ panel: AmplificationSettingsImpl.CYCLES_PANEL }, onPanelToggle(true));
  };

  render() {
    const { subtractBaseline, hasCustomThreshold, onTabOut, driftCorrection } = this.props;
    const { panel } = this.state;
    if (panel === AmplificationSettingsImpl.THRESHOLD_PANEL)
      return <ThresholdPanel closePanel={this.closePanel} onTabOut={onTabOut} />;
    if (panel === AmplificationSettingsImpl.CYCLES_PANEL)
      return <CyclesPanel closePanel={this.closePanel} onTabOut={onTabOut} />;

    return (
      <>
        <div className="popover-section">
          <div className="section-header">Baseline Subtraction</div>
          <div className="sub-section">
            <Checkbox
              id="baseline-checkbox"
              name="baseline-checkbox"
              // @ts-ignore
              value={subtractBaseline}
              checked={subtractBaseline}
              onChange={this.onBaselineSubtractionChange}
            >
              Baseline Subtraction
            </Checkbox>
          </div>
          <div className="sub-section">
            <Checkbox
              id="drift-correction"
              name="drift-correction"
              // @ts-ignore
              value={driftCorrection}
              checked={driftCorrection}
              onChange={this.onDriftCorrectionChange}
            >
              Fluorescence Drift Correction
            </Checkbox>
          </div>
          <div className="sub-section">
            <div className="popover-label">
              <LinkButton
                className="popover-link"
                id="custom-cycles-button"
                onClick={this.openBaselineCyclesPanel}
                type="button"
              >
                Set Baseline Cycles {'>'}
              </LinkButton>
            </div>
          </div>
        </div>
        <div className="popover-section">
          <div className="section-header">Threshold</div>
          <div className="sub-section">
            <div id="current-settings" className="popover-label">
              Current Settings:
              <span className="popover-value" id="threshold-settings-value">
                {hasCustomThreshold ? 'Custom' : 'Default'}
              </span>
            </div>
            <div className="popover-label">
              <LinkButton
                className="popover-link"
                id="custom-threshold-button"
                onClick={this.openThresholdPanel}
                type="button"
              >
                Set Custom Thresholds {'>'}
              </LinkButton>
            </div>
          </div>
        </div>
      </>
    );
  }
}
function mapStateToProps(state: State) {
  return {
    subtractBaseline: getSubtractBaseline(state),
    hasCustomThreshold: getHasCustomThreshold(state),
    driftCorrection: getDriftCorrection(state)
  };
}

export default connect(mapStateToProps, {
  toggleBaselineSubtraction: setBaselineSubtraction,
  toggleDriftCorrection: setDriftCorrection
  // @ts-ignore
})(AmplificationSettingsImpl);
