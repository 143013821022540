import React from 'react';
import UtilityButton from '../../../frontend-common-libs/src/components/common/buttons/UtilityButton';

type Props = {
  children: React.ReactNode;
  onClose: (...args: Array<any>) => any;
  handleTabOut: (...args: Array<any>) => any;
};

function UserPreferenceDetails({ children, onClose, handleTabOut }: Props) {
  return (
    <>
      {children}
      <div className="popover-footer">
        <div className="popover-footer">
          <UtilityButton
            id="toolbar-popover-close"
            type="button"
            onClick={onClose}
            onKeyDown={handleTabOut}
          >
            Close
          </UtilityButton>
        </div>
      </div>
    </>
  );
}

export default UserPreferenceDetails;
