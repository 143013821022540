import React from 'react';
import { connect } from 'react-redux';
import { Map, Seq } from 'immutable';
import { Panel, ListGroup, Button } from 'react-bootstrap';
import Scrollbars from 'react-custom-scrollbars';
import FileOperationStatusItem from './FileOperationStatusItem';
import NavigationBlockPrompt from '../../frontend-common-libs/src/components/common/NavigationBlockPrompt';
import {
  clearUploads as clearUploadsAction,
  cancelAllUploads as cancelAllUploadsAction,
  dispatchFileOperation as dispatchFileOperationAction
} from '../../actions/file_upload/fileupload_actions';
import { ReduxState } from '../../types';
import FileOperationSubscriber from './FileOperationSubscriber';

type Props = {
  uploading: Map<string, any>;
  processing: Map<string, any>;
  failed: Map<string, any>;
  handling: Seq.Indexed<Map<string, any>>;
  clearUploads: typeof clearUploadsAction;
  cancelAllUploads: typeof cancelAllUploadsAction;
  dispatchFileOperation: typeof dispatchFileOperationAction;
};
type State = { open: boolean };

export class FileOperationStatusPanelImpl extends React.Component<Props, State> {
  readonly fileOperationSubscriber: FileOperationSubscriber;

  constructor(props: Props) {
    super(props);

    this.state = {
      open: true
    };
    this.fileOperationSubscriber = new FileOperationSubscriber(props.dispatchFileOperation);
    this.fileOperationSubscriber.subscribe();
  }

  componentWillReceiveProps(nextProps: Props) {
    const { open } = this.state;
    const { handling } = this.props;
    if (!open && nextProps.handling.size !== handling.size) {
      this.setState({ open: true });
    }
  }

  componentWillUnmount() {
    // Cancel all uploads on unmount
    // The NavigationBlocker should prevent us from unmounting without user approval
    const { cancelAllUploads } = this.props;
    cancelAllUploads();
    this.fileOperationSubscriber.unsubscribe();
  }

  toggleState = () => {
    const { open } = this.state;
    this.setState({ open: !open });
  };

  dismiss = (e: React.SyntheticEvent<HTMLButtonElement>) => {
    e.stopPropagation(); // stop propagation to header div
    const { clearUploads } = this.props;
    clearUploads();
  };

  headerText = () => {
    const { uploading, failed, processing } = this.props;
    if (uploading.size === 0 && processing.size === 0 && failed.size === 0) {
      return 'Done';
    }
    const msg = [];
    if (uploading.size > 0) {
      msg.push(`${uploading.size} Uploading`);
    }
    if (processing.size > 0) {
      msg.push(`${processing.size} Processing`);
    }
    if (failed.size > 0) {
      msg.push(`${failed.size} Failed`);
    }
    return msg.join(', ');
  };

  renderHeaderSpan = () => {
    const hText = this.headerText();
    return (
      <span
        id="upload-status-header"
        className="margin-left-10 text-bold text-ellipsis status-header"
        title={hText}
      >
        {hText}
      </span>
    );
  };

  renderHeader = () => (
    <div className="parent-flex">
      <div className="left-flex flex-grow-1">
        <i className={this.renderExpandIcon()} />
        {this.renderHeaderSpan()}
      </div>
      <div className="right-flex">{this.renderDismiss()}</div>
    </div>
  );

  renderDismiss = () => {
    const { uploading, processing } = this.props;
    if (uploading.size === 0 && processing.size === 0) {
      return (
        // @ts-ignore
        <Button id="upload-status-dismiss" bsClass="btn btn-circle" onClick={this.dismiss}>
          <i className="fa fa-times" />
        </Button>
      );
    }
    return null;
  };

  renderExpandIcon() {
    const { open } = this.state;
    return open ? 'fa fa-angle-down' : 'fa fa-angle-up';
  }

  render() {
    const { uploading, handling } = this.props;
    const { open } = this.state;
    if (handling.size === 0) {
      return null;
    }
    return (
      <div className="upload-status-container" id="upload-status-panel">
        <NavigationBlockPrompt
          shouldBlock={uploading.size > 0}
          confirmationMessage="File uploads in progress"
          whiteListURLPattern={/^\/app\/.*/}
        />
        <Panel id="collapsible-panel" expanded={open} onToggle={this.toggleState}>
          <Panel.Heading onClick={this.toggleState} id="upload-status-header-container">
            <Panel.Title>{this.renderHeader()}</Panel.Title>
          </Panel.Heading>
          <Panel.Collapse>
            <Scrollbars hideTracksWhenNotNeeded autoHeight>
              <ListGroup>
                {handling.map(item => (
                  <FileOperationStatusItem key={item.get('id')} item={item} />
                ))}
              </ListGroup>
            </Scrollbars>
          </Panel.Collapse>
        </Panel>
      </div>
    );
  }
}

function mapStateToProps(state: ReduxState) {
  return {
    uploading: state.fileOperations.get('uploading'),
    processing: state.fileOperations.get('processing'),
    failed: state.fileOperations.get('failed'),
    handling: state.fileOperations.get('handling').valueSeq()
  };
}

export default connect(mapStateToProps, {
  clearUploads: clearUploadsAction,
  cancelAllUploads: cancelAllUploadsAction,
  dispatchFileOperation: dispatchFileOperationAction
  // @ts-ignore
})(FileOperationStatusPanelImpl);
