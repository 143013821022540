import React from 'react';
import { Toggle } from '../../../frontend-common-libs/src/components/common/radio_button_group';

type Props = {
  analysisMode: string;
  onUpdateAnalysisMode: (...args: Array<any>) => any;
};

export default function ({ analysisMode, onUpdateAnalysisMode }: Props) {
  const modeOptions = [
    {
      id: 'pcr-chart-mode-fluor',
      label: 'Fluorophore',
      value: 'fluor',
      disabled: false
    },
    {
      id: 'pcr-chart-mode-target',
      label: 'Target',
      value: 'target',
      disabled: false
    }
  ];

  return (
    <Toggle
      id="pcr-chart-mode-options"
      name="pcr-chart-mode"
      onChange={onUpdateAnalysisMode}
      options={modeOptions}
      checkedValue={analysisMode}
    />
  );
}
