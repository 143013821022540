import ModalDialogViewModel from '../../../frontend-common-libs/src/components/common/dialogs/ModalDialogViewModel';
import { validateFileName } from '../../../frontend-common-libs/src/components/common/run/validations/run-validation';
import { makeMaximumText } from '../../../frontend-common-libs/src/common/form_validators';

export default class EditOrgDialogVm extends ModalDialogViewModel {
  public dialogTitle = 'Edit Organization';

  public okBtnText = 'Save Changes';

  protected origOrgDescription: string | undefined;

  protected origOrgName: string | undefined;

  protected _orgDescription: string | undefined;

  public get orgDescription() {
    return this._orgDescription;
  }

  public set orgDescription(orgDescription: string | undefined) {
    const changed = this.notifyIfChanged('_orgDescription', orgDescription);
    if (changed) this.validateOrgDescription();
  }

  protected _orgName: string | undefined;

  public get orgName() {
    return this._orgName;
  }

  public set orgName(name: string | undefined) {
    const changed = this.notifyIfChanged('_orgName', name);
    if (changed) this.validateOrgName();
  }

  protected _orgNameError: string | undefined;

  public get orgNameError() {
    return this._orgNameError;
  }

  public set orgNameError(orgNameError: string | undefined) {
    this.notifyIfChanged('_orgNameError', orgNameError);
  }

  protected _orgDescriptionError: string | undefined;

  public get orgDescriptionError() {
    return this._orgDescriptionError;
  }

  public set orgDescriptionError(orgDescriptionError: string | undefined) {
    this.notifyIfChanged('_orgDescriptionError', orgDescriptionError);
  }

  public get hasNameEdits() {
    return this.orgName !== this.origOrgName;
  }

  public get hasDescriptionEdits() {
    return this.orgDescription !== this.origOrgDescription;
  }

  public get hasEdits() {
    return this.hasNameEdits || this.hasDescriptionEdits;
  }

  public get okBtnDisabled() {
    if (!this.hasEdits || this.orgNameError || this.orgDescriptionError) return true;
    return false;
  }

  onSave: (orgName?: string, orgDescription?: string) => Promise<any>;

  constructor(onSave: (orgName?: string, orgDescription?: string) => Promise<any>) {
    super();
    this.onSave = onSave;
  }

  showEditOrgDialog(orgName: string, orgDescription?: string) {
    this.origOrgName = orgName;
    this.orgName = orgName;
    this.origOrgDescription = orgDescription;
    this.orgDescription = orgDescription;
    this.showDialog();
  }

  onOk = async () => {
    const editedName = this.hasNameEdits ? this.orgName : undefined;
    const editedDescription = this.hasDescriptionEdits ? this.orgDescription : undefined;
    this.saveInProgress = true;
    await this.onSave(editedName, editedDescription);
    this.saveInProgress = false;
    this.hideDialog();
  };

  validateOrgName() {
    this.orgNameError = validateFileName(this.orgName as string);
  }

  validateOrgDescription() {
    this.orgDescriptionError = makeMaximumText(
      'Organization description is too long',
      300
    )(this.orgDescription as string);
  }
}
