import RunRepository from '../repository/RunRepository';

export default class RunIotHandler {
  static onIotMessage(entityWithAction: any) {
    const { action, ...entity } = entityWithAction;
    RunIotHandler.updateRunRepository(entity);
  }

  static updateRunRepository(entity: any) {
    RunRepository.instance.runUpdated(entity);
  }
}
