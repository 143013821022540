import './styles/eap.scss';
import React, { Component, lazy } from 'react';
import { Field, reduxForm, InjectedFormProps } from 'redux-form';
import { connect } from 'react-redux';
import { RenderField } from '../frontend-common-libs/src/common/input-fields';
import { emailValid, textRequired } from '../frontend-common-libs/src/common/form_validators';
import CountryDropdown, { countryRequired } from '../components/contact/CountryDropdown';
import ProgressButton from '../frontend-common-libs/src/components/common/ProgressButton';
import { State as AppState } from '../types';
import JoinEAPRequest, { JoinEAPRequestBody } from './requests/JoinEAPRequest';

export type Props = {
  handleSubmit: (...args: Array<any>) => any;
} & InjectedFormProps;

export type State = {
  wasSubmitted: boolean;
};

export interface JoinEAPFormValues {
  phone: string;
  email: string;
  country: string;
}

export class EarlyAccessProgramImpl extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      wasSubmitted: false
    };
    // lazy load PDF so that webpack can include file in compilation and don't break unit tests
    lazy(async () => import('./resources/eap-info.pdf'));
  }

  onSubmit = async (values: JoinEAPFormValues) => {
    await new JoinEAPRequest(values as JoinEAPRequestBody).send();
    this.setState({
      wasSubmitted: true
    });
  };

  render() {
    const { handleSubmit, submitting, invalid } = this.props;
    const { wasSubmitted } = this.state;

    const { origin } = window.location;
    const eapPDFUrl = `${origin}/resources/eap-info.pdf`;

    if (wasSubmitted) {
      return (
        <div id="eap-submission-message">
          <h3>Thank you for your interest in BR.io!</h3>
          We appreciate your willingness to help us improve the <b>BR.io</b> application. If you
          meet our <b>Early Access Program</b> criteria, we will send an email with more
          information.
        </div>
      );
    }
    return (
      <div className="container">
        <div id="eap-info">
          Want to stay informed about new features that will be added to the product? Want to share
          your feedback about the feature function and direction? Join the{' '}
          <b>Early Access Program</b>! Find out more about the EAP{' '}
          <a id="eap-info-link" href={eapPDFUrl} target="_blank" rel="noreferrer">
            here.
          </a>
        </div>
        <form onSubmit={handleSubmit(this.onSubmit)}>
          <Field
            label="PHONE NUMBER"
            name="phone"
            type="tel"
            extraError=""
            component={RenderField}
            validate={[textRequired]}
          />
          <Field
            label="EMAIL"
            name="email"
            type="email"
            extraError=""
            component={RenderField}
            disabled
            validate={[textRequired, emailValid]}
          />
          <Field
            label="COUNTRY"
            name="country"
            component={CountryDropdown}
            validate={[countryRequired]}
          />
          <div className="pull-right">
            <ProgressButton
              id="eap-sign-button"
              type="submit"
              className="form-btn btn-primary text-center"
              inProgress={submitting}
              disabled={invalid}
            >
              Sign me up
            </ProgressButton>
          </div>
        </form>
      </div>
    );
  }
}

function mapStateToProps(state: AppState) {
  return {
    initialValues: {
      'email': state.auth.get('username')
    }
  };
}
export default connect(
  mapStateToProps,
  null
)(
  reduxForm({
    form: 'eap',
    enableReinitialize: true
  })(EarlyAccessProgramImpl)
);
