import React from 'react';
import HorizontalLine from '../common/HorizontalLine';
import SectionHeader from '../common/SectionHeader';
import ProtocolStepRow from '../common/ProtocolStepRow';
import ProtocolStepsParser from '../sections-parsing/ProtocolStepsParser';

type Step = { step: string; lines: string[] };
type Props = { steps: ProtocolStepsParser };

export default function ProtocolSteps(props: Props) {
  const { steps } = props;
  return (
    <div id="protocol-steps">
      <SectionHeader text="Protocol Steps" />
      {steps.protocolSteps.map((step: Step, index: number) => (
        <ProtocolStepRow step={step.step} lines={step.lines} key={index} />
      ))}
      <HorizontalLine styleOverride={{ marginTop: '44px' }} />
    </div>
  );
}
