import React from 'react';

import {
  endTemps,
  makePath,
  makeTextAboveLine,
  makeTextBelowLine,
  makeCamera,
  StepProps,
  Point
} from './common';
import QPcrStep from '../models/QPcrStep';

function MeltStep(props: StepProps) {
  const { step, yScale, stepWidth, prev, index } = props;
  const { temp, tempAsString, end, endAsString, intervalAsString, incAsString } = new QPcrStep(
    step
  );

  const y1 = yScale(temp);
  const y2 = yScale(end);
  const x1 = 0.3;
  const x2 = (0.2 * stepWidth) / 2;
  const x3 = stepWidth / 2;
  const x4 = x3 + (0.2 * stepWidth) / 2;
  const x5 = x3 + stepWidth / 2;

  const prevPoints = endTemps(prev).map(yScale);
  const lineData: Point[][] = [];
  prevPoints.forEach(p => {
    lineData.push([
      { x: x1, y: p },
      { x: x2, y: y1 },
      { x: x3, y: y1 },
      { x: x4, y: y2 },
      { x: x5, y: y2 }
    ]);
  });
  return (
    <>
      {lineData.map(makePath)}
      {makeTextAboveLine(stepWidth / 4, y1, `${tempAsString} ºC`, `graph-steptemp-${index + 1}`)}
      {makeTextBelowLine(
        stepWidth / 4,
        y1,
        `Int: ${intervalAsString}`,
        `graph-stepint-${index + 1}`
      )}
      {makeTextAboveLine(x3 + stepWidth / 4, y2, `${endAsString} ºC`, `graph-stepend-${index + 1}`)}
      {makeTextBelowLine(
        x3 + stepWidth / 4,
        y2,
        `Inc: ${incAsString} ºC`,
        `graph-stepinc-${index + 1}`
      )}
      {makeCamera(index, end, x3 + stepWidth / 4, yScale, 'camera-melt')}
    </>
  );
}

MeltStep.defaultProps = {
  prev: undefined
};

MeltStep.displayName = 'MeltStep';

export default MeltStep;
