import { Component } from 'react';

type Props = {
  render: (refCallback: (elementToObserve: HTMLElement | null | undefined) => void) => any;
  onViewportEntered: (...args: Array<any>) => any;
};

export default class ViewportIntersectionObserver extends Component<Props> {
  _observer: IntersectionObserver;

  _observedElementRef!: HTMLElement;

  constructor(props: Props) {
    super(props);

    const options = {
      root: null,
      threshold: 0
    };

    this._observer = new IntersectionObserver(this.onIntersectionObserved, options);
  }

  componentWillUnmount() {
    this._observer.disconnect();
  }

  setRef = (elementToObserve: HTMLElement | null | undefined) => {
    if (this._observedElementRef) {
      this._observer.unobserve(this._observedElementRef);
    }
    if (elementToObserve) {
      this._observedElementRef = elementToObserve;
      this._observer.observe(elementToObserve);
    }
  };

  onIntersectionObserved = (entries: Array<IntersectionObserverEntry>) => {
    const firstEntry = entries[0];
    if (firstEntry && firstEntry.isIntersecting) {
      const { onViewportEntered } = this.props;
      onViewportEntered();
    }
  };

  render() {
    const { render } = this.props;
    return render(this.setRef);
  }
}
