import { createSelector } from 'reselect';
import { ReduxState } from '../types';

const getNotificationsSettings = (state: ReduxState) =>
  state.userPreferences.get('notificationsSettings');

const getDeviceType = (state: ReduxState) => state.userPreferences.get('deviceType');

const getNotificationsSettingsByDeviceType = createSelector(
  [getNotificationsSettings, getDeviceType],
  (notificationSettings, deviceType) =>
    notificationSettings.filter(
      (notificationSetting: any) => notificationSetting.deviceType === deviceType
    )
);

export default getNotificationsSettingsByDeviceType;
