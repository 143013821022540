import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getOrgTokenIfNeeded as getOrgTokenIfNeededAction } from '../actions/select-org';
import { ReduxState } from '../../types';
import { getLoginUserToken, getOrgUserToken } from '../selectors/selectors';
import notification from '../../frontend-common-libs/src/utils/notifications';
import LoginToken from './LoginToken';

export type Props = {
  orgId: string;
  loginAccessToken?: string;
  getOrgToken?: (orgId: string) => any;
  orgAccessToken?: string;
};

export class OrgTokenImpl extends Component<Props> {
  async componentDidMount() {
    await this.getTokenIfNeeded();
  }

  async componentDidUpdate() {
    await this.getTokenIfNeeded();
  }

  async getTokenIfNeeded() {
    const { loginAccessToken, getOrgToken, orgId, orgAccessToken } = this.props;
    if (!getOrgToken || !loginAccessToken || orgAccessToken) return;
    try {
      await getOrgToken(orgId);
    } catch {
      notification.error('Error getting organization access token');
    }
  }

  render() {
    return <LoginToken />;
  }
}
export function mapStateToProps(
  state: ReduxState,
  ownProps: Props
): {
  [key: string]: any;
} {
  const { orgId } = ownProps;
  return {
    loginAccessToken: getLoginUserToken(state),
    orgAccessToken: getOrgUserToken(state, orgId)
  };
}

// @ts-ignore
export default connect(mapStateToProps, { getOrgToken: getOrgTokenIfNeededAction })(OrgTokenImpl);
