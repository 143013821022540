import React, { PureComponent } from 'react';
import { scaleLinear } from '@visx/scale';
import { Map, List } from 'immutable';
import TemperatureAxis from './TemperatureAxis';
import StepBox, { HEADER_HEIGHT } from './StepBox';
import TempStep from './TempStep';
import GradStep from './GradStep';
import MeltStep from './MeltStep';
import GotoStep from './GotoStep';
import EndBar from './EndBar';
import { GRADIENT, MELT, GOTO } from '../../../../utils/protocolUtils';
import { isMelt, meltStepCount, Margins, AxisPadding } from './common';

export type Props = {
  steps: List<Map<string, any>>;
  selectedIndex?: number;
  setSelectedStepIndex?: (...args: Array<any>) => any;
};

type State = {
  stepWidth: number;
  width: number;
  steps: List<Map<string, any>>;
};

export const MARGINS: Margins = { top: 10, right: 60, bottom: 10, left: 60 };
export const AXIS_PADDING: AxisPadding = {
  top: HEADER_HEIGHT + 20,
  bottom: 30
};
export const MIN_STEP_SIZE = 105;
export const DEFAULT_WIDTH = 1220;

const HEIGHT = 280;
const BOX_HEIGHT = HEIGHT - MARGINS.top - MARGINS.bottom;

const Y_MAX = 100;
const Y_MIN = 0;

// @ts-ignore
const Y_SCALE: scaleLinear<number, number> = scaleLinear()
  .domain([Y_MIN, Y_MAX])
  .range([HEIGHT - MARGINS.bottom - AXIS_PADDING.bottom - MARGINS.top, AXIS_PADDING.top]);

export default class ProtocolChart extends PureComponent<Props, State> {
  static calcStepWidth(defaultWidth: number, minStepSize: number, divider: number) {
    return Math.max(minStepSize, (defaultWidth - MARGINS.left - MARGINS.right) / divider);
  }

  static defaultProps = {
    selectedIndex: undefined,
    setSelectedStepIndex: undefined
  };

  constructor(props: Props) {
    super(props);
    this.state = ProtocolChart.getNewState(props.steps);
  }

  static getDerivedStateFromProps(nextProps: Props, prevState: State) {
    if (nextProps.steps === prevState.steps) {
      return null;
    }
    return ProtocolChart.getNewState(nextProps.steps);
  }

  static getNewState(steps: List<Map<string, any>>) {
    let stepWidth = MIN_STEP_SIZE;
    let width = DEFAULT_WIDTH;

    if (steps.size !== 0) {
      const divisions = steps.size + meltStepCount(steps);
      stepWidth = ProtocolChart.calcStepWidth(DEFAULT_WIDTH, MIN_STEP_SIZE, divisions);
      width = stepWidth * divisions + MARGINS.left + MARGINS.right;
    }

    return {
      stepWidth,
      width,
      steps
    };
  }

  static getPrevStep(steps: List<Map<string, any>>, i: number) {
    if (i === 0) {
      return undefined;
    }
    const step = steps.get(i - 1);
    // @ts-ignore
    if (step.get('type') !== GOTO) {
      return step;
    }
    return steps.get(i - 2);
  }

  drawSteps = (): List<JSX.Element> => {
    const { steps, selectedIndex, setSelectedStepIndex } = this.props;
    const { stepWidth } = this.state;
    let xPos = MARGINS.left;
    const drawStep = (step: Map<string, any>, i: number) => {
      const boxPos = xPos;
      const boxWidth = isMelt(step) ? 2 * stepWidth : stepWidth;
      xPos += boxWidth;
      return (
        <StepBox
          key={i}
          height={BOX_HEIGHT}
          width={boxWidth}
          index={i}
          yPos={MARGINS.top}
          xPos={boxPos}
          isSelected={i === selectedIndex}
          setSelectedStepIndex={setSelectedStepIndex}
        >
          {/* @ts-ignore */}
          {this.renderStep(step, ProtocolChart.getPrevStep(steps, i), boxWidth, i)}
        </StepBox>
      );
    };

    // Selected step should be last in list so the selection stoke overlaps neighboring step strokes
    return steps.map(drawStep).sortBy(step => step.props.isSelected);
  };

  renderStep = (
    step: Map<string, any>,
    prev: Map<string, any>,
    stepWidth: number,
    index: number
  ) => {
    const stepProps = { step, prev, stepWidth, yScale: Y_SCALE, index };
    switch (step.get('type')) {
      case GOTO:
        return <GotoStep {...stepProps} />;
      case GRADIENT:
        return <GradStep {...stepProps} />;
      case MELT:
        return <MeltStep {...stepProps} />;
      default:
        return <TempStep {...stepProps} />;
    }
  };

  render() {
    const { width } = this.state;
    /* eslint-disable react/no-unknown-property */
    return (
      <div className="protocol-chart" style={{ minHeight: HEIGHT }}>
        <svg id="protocol-graph" width={width} height={HEIGHT}>
          <defs>
            <marker
              id="marker-arrow"
              markerHeight="13"
              markerWidth="11"
              markerUnits="strokeWidth"
              refX="2"
              refY="8"
              viewBox="-5 -5 20 25"
            >
              <path d="M1.22 7.894l10.12 6.043a.42.42 0 0 0 .437.003.469.469 0 0 0 .223-.397V1.457a.469.469 0 0 0-.223-.397.42.42 0 0 0-.438.003L1.22 7.106A.456.456 0 0 0 1 7.5c0 .164.084.315.22.394z" />
            </marker>
          </defs>
          <TemperatureAxis
            yScale={Y_SCALE}
            chartHeight={HEIGHT}
            margins={MARGINS}
            padding={AXIS_PADDING}
          />
          {this.drawSteps()}
          <EndBar xPos={width - MARGINS.right} yPos={MARGINS.top} height={BOX_HEIGHT} />
        </svg>
      </div>
    );
  }
}
