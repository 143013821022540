import React, { PureComponent } from 'react';
import routes from '../../../core/routes';
import Alert from '../../../../img/alert.svg';
import { Step } from '../LinkInstrument';
import PrimaryButton from '../../../frontend-common-libs/src/common/buttons';
import styles from './styles/link-instrument-finished.module.scss';
import HelpCenter from '../../../utils/HelpCenter';

export type Props = {
  isError: boolean;
  handleStepChange: (step: Step) => void;
  clearDeviceFlowDetails: () => void;
};

export default class LinkInstrumentFinished extends PureComponent<Props> {
  handleOk = () => {
    const { clearDeviceFlowDetails, handleStepChange } = this.props;
    clearDeviceFlowDetails();
    handleStepChange(Step.FORM);
  };

  getLinkInstrumentHelpUrl = () => {
    const helpCenter = new HelpCenter();
    return helpCenter.getHelpUrl(routes.LINK_INSTRUMENT);
  };

  renderAuthCompleteMessage = () => {
    return (
      <div className={styles.container}>
        <p id="auth-complete-message" className={styles.completeMessage}>
          User authorization is complete. When the linking process succeeds, the instrument will
          appear on your <a href={routes.INSTRUMENT_LIST}>BR.io Instruments page</a>.
        </p>
      </div>
    );
  };

  renderError = () => {
    return (
      <div className={styles.container}>
        <div className={styles.headerContainer}>
          <img src={Alert} alt="Error icon" className={styles.errorIcon} />
          <h1 id="link-error-header" className={styles.errorHeader}>
            Instrument Not Linked
          </h1>
        </div>
        <p id="error-message" className={styles.errorMessage}>
          An error occurred and the instrument was not linked to your account.
          <br />
          Repeat the linking process on the instrument.
        </p>
        <PrimaryButton
          id="link-instrument-ok-btn"
          type="button"
          className={styles.button}
          onClick={this.handleOk}
        >
          OK
        </PrimaryButton>
        <p className={styles.help}>
          For linking issues, click{' '}
          <a
            id="instrument-troubleshooting-link"
            target="_blank"
            href={this.getLinkInstrumentHelpUrl()}
            rel="noreferrer"
          >
            here
          </a>
        </p>
      </div>
    );
  };

  render() {
    const { isError } = this.props;
    return isError ? this.renderError() : this.renderAuthCompleteMessage();
  }
}
