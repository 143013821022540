import UserAnalyticsClient from '../../frontend-common-libs/src/user-analytics/UserAnalyticsClient';

export type ReserveInstrumentsEventParams = {
  numberOfInstruments: number;
  deviceTypes: string[];
  models: string[];
};

export default class ReserveInstrumentsButtonClickEvent {
  private static readonly RESERVE_INSTRUMENTS_BUTTON_CLICK_EVENT_NAME =
    'fleet-brio-reservation-button-clicked';

  // eslint-disable-next-line class-methods-use-this
  public track(params: ReserveInstrumentsEventParams) {
    UserAnalyticsClient.getInstance().track(
      ReserveInstrumentsButtonClickEvent.RESERVE_INSTRUMENTS_BUTTON_CLICK_EVENT_NAME,
      {
        ...params
      }
    );
  }
}
