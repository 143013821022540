export enum StartOrEnd {
  start = 'start',
  end = 'end'
}

export default class CycleRangeWell {
  wellId: string;

  start: number;

  end: number;

  customStart?: number | null = null;

  customEnd?: number | null = null;

  isSelected = false;

  constructor(wellId: string, start: number, end: number) {
    this.wellId = wellId;
    this.start = start;
    this.end = end;
  }

  getCustomStartMaxValue = () => {
    if (this.customEnd != null) return this.customEnd - 1;
    if (this.end != null) return this.end - 1;
    return undefined;
  };

  getCustomEndMinValue = () => {
    if (this.customStart != null) return this.customStart + 1;
    if (this.start != null) return this.start + 1;
    return undefined;
  };

  setCustomStartOrEnd = (startOrEnd: StartOrEnd, value: number | null | undefined) => {
    if (startOrEnd === StartOrEnd.start) this.customStart = value;
    if (startOrEnd === StartOrEnd.end) this.customEnd = value;
  };
}
