import React, { Component } from 'react';
import './styles/organization-page.scss';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Redirect } from 'react-router-dom';
import { ReduxState } from '../../types';
import {
  getActiveOrg,
  getLoginInfo,
  getOrganizations,
  isLoginTokenLoading as isLoginTokenLoadingSelector,
  isOrganizationsLoaded
} from '../selectors/selectors';
import { createOrg as createOrgAction } from '../actions/create-org';
import Loader from '../../frontend-common-libs/src/components/common/Loader';
import { getOrganizationsIfNeeded as getOrganizationsIfNeededAction } from '../actions/get-orgs';
import OrgList from './OrgList';
import { Organization } from '../actions/action-types';
import { selectOrg } from '../actions/select-org';
import LoginToken from './LoginToken';
import GhostButton from '../../frontend-common-libs/src/components/common/buttons/GhostButton';
import { OrgTab } from '../router/OrgRoutes';
import EditOrgDialogVm from './edit-org-dialog/EditOrgDialogVm';
import EditOrgDialog from './edit-org-dialog/EditOrgDialog';

export type Props = {
  loginInfo: any;
  isLoginTokenLoading?: boolean;
  createOrg: typeof createOrgAction;
  getOrganizationsIfNeeded: typeof getOrganizationsIfNeededAction;
  loading: boolean;
  orgs: any[];
  activeOrgId: string;
  setSelectedOrganization: typeof selectOrg;
};

export type State = {
  createOrgDialogVm: EditOrgDialogVm;
  selectedOrganization?: Organization;
  redirectToOrg: boolean;
};

export class OrganizationsPageImpl extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const createOrgDialogVm = new EditOrgDialogVm(this.createOrg);
    createOrgDialogVm.dialogTitle = 'Create Organization';
    createOrgDialogVm.okBtnText = 'Create';
    this.state = { createOrgDialogVm, redirectToOrg: false };
  }

  async componentDidMount() {
    await this.init();
  }

  async componentDidUpdate(prevProps: Props) {
    const { orgs, loading } = this.props;
    if ((!loading && prevProps.loading) || orgs !== prevProps.orgs) {
      await this.init();
    }
  }

  async init() {
    await this.getOrganizationsIfNeeded();
    this.setSelectedOrganization();
  }

  setSelectedOrganization() {
    const { orgs, activeOrgId, setSelectedOrganization } = this.props;
    const { redirectToOrg } = this.state;
    if (redirectToOrg || orgs == null || orgs.length === 0) return;
    let selectedOrg = orgs.find(org => org.orgId === activeOrgId);
    if (selectedOrg == null) {
      selectedOrg = orgs.at(0);
      setSelectedOrganization(selectedOrg.orgId);
    }
    this.setState({ selectedOrganization: { id: selectedOrg.orgId, name: selectedOrg.name } });
  }

  async getOrganizationsIfNeeded() {
    const { loginInfo, getOrganizationsIfNeeded } = this.props;
    if (loginInfo) getOrganizationsIfNeeded();
  }

  onCreateOrg = () => {
    const { createOrgDialogVm } = this.state;
    createOrgDialogVm.showEditOrgDialog('');
    createOrgDialogVm.orgNameError = undefined;
  };

  createOrg = async (orgName?: string, orgDescription?: string) => {
    const { createOrg } = this.props;
    await createOrg(orgName as string, orgDescription);
  };

  renderCreateOrgPopup() {
    const { createOrgDialogVm } = this.state;
    return <EditOrgDialog editOrgDialogVm={createOrgDialogVm} />;
  }

  onOrganizationRowClick = async (organization: Organization) => {
    const { setSelectedOrganization, activeOrgId } = this.props;
    if (!_.isEqual(activeOrgId, organization.id)) {
      this.setState({ selectedOrganization: organization });
      setSelectedOrganization(organization.id);
    }
    this.setState({ redirectToOrg: true });
  };

  renderOrgList() {
    const { orgs, activeOrgId } = this.props;
    const organization: Organization[] = orgs.map(o => {
      return { id: o.orgId, name: o.name };
    });
    return (
      <OrgList
        title="Organizations"
        orgs={organization}
        selectedOrganizationId={activeOrgId}
        onRowClick={this.onOrganizationRowClick}
      />
    );
  }

  // eslint-disable-next-line class-methods-use-this
  renderTopToolBar() {
    return (
      <div className="organizations-page-breadcrumb">
        <div>
          <GhostButton
            type="button"
            id="create-org-button"
            onClick={this.onCreateOrg}
            className="ghost create-org-button"
          >
            Create New Organization
          </GhostButton>
        </div>
      </div>
    );
  }

  render() {
    const { loading, isLoginTokenLoading } = this.props;
    if (loading || isLoginTokenLoading) {
      return (
        <div>
          <LoginToken />
          <Loader />
        </div>
      );
    }

    const { redirectToOrg, selectedOrganization } = this.state;
    if (redirectToOrg) {
      return <Redirect push to={`${selectedOrganization?.id}/${OrgTab.members}`} />;
    }

    return (
      <div className="profile-wrapper organizations-page">
        {this.renderTopToolBar()}
        {this.renderCreateOrgPopup()}
        {this.renderOrgList()}
      </div>
    );
  }
}

export function mapStateToProps(state: ReduxState): {
  [key: string]: any;
} {
  return {
    loginInfo: getLoginInfo(state),
    isLoginTokenLoading: isLoginTokenLoadingSelector(state),
    loading: !isOrganizationsLoaded(state),
    orgs: getOrganizations(state).toJS(),
    activeOrgId: getActiveOrg(state)
  };
}

export default connect(mapStateToProps, {
  createOrg: createOrgAction,
  getOrganizationsIfNeeded: getOrganizationsIfNeededAction,
  setSelectedOrganization: selectOrg
  // @ts-ignore
})(OrganizationsPageImpl);
