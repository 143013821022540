import { DispatchType, ORG_REMOVED, ORG_USER_REMOVED } from './action-types';
import {
  TrackedOrganizationEvents,
  trackEvent
} from '../../frontend-common-libs/src/user-analytics/trackedEvents';
import { Dispatch, GetState } from '../../types';
import { removeUserFromOrganization } from '../api/org-api';
import { withRefreshOrgToken } from './with-refresh-org-token';
import { CbWithToken } from './WithRefreshToken';
import {
  isAtLeastOneAdminError,
  isLastProjectAdminError,
  isRemoveLastAdminError
} from '../api/api-errors';
import notification from '../../frontend-common-libs/src/utils/notifications';
import { getUserEmail } from '../../selectors/auth-selectors';
import { getActiveOrg } from '../selectors/selectors';
import changeProjectSelection from '../../project-management/actions/change-project-selection';
import { DEFAULT_PROJECT_ID } from '../../frontend-common-libs/src/common/project-management-types';
import { getSelectedProjectId } from '../../project-management';
import { login } from './login';

export async function dispatchOrgRemoved(
  dispatch: DispatchType,
  getState: GetState,
  orgId: string
) {
  const state = getState();
  const activeOrgId = getActiveOrg(state);
  if (activeOrgId === orgId) {
    await login()(dispatch, getState);
    const selectedProjectId = getSelectedProjectId(state);
    if (selectedProjectId !== DEFAULT_PROJECT_ID) {
      await changeProjectSelection(DEFAULT_PROJECT_ID)(dispatch as Dispatch<any, any>);
    }
  }
  dispatch({
    type: ORG_REMOVED,
    payload: { orgId }
  });
}

export async function dispatchOrgUserRemoved(
  dispatch: DispatchType,
  getState: GetState,
  orgId: string,
  userName: string
) {
  dispatch({
    type: ORG_USER_REMOVED,
    payload: { orgId, userName }
  });
  const state = getState();
  const email = getUserEmail(state);
  if (userName !== email) return;
  await dispatchOrgRemoved(dispatch, getState, orgId);
}

export function removeUserFromOrg(orgId: string, userName: string) {
  return async (dispatch: DispatchType, getState: GetState) => {
    try {
      const removeUserCb: CbWithToken = async (accessToken: string) => {
        return removeUserFromOrganization(orgId, userName, accessToken);
      };
      await withRefreshOrgToken(dispatch, getState, orgId, removeUserCb);
      await dispatchOrgUserRemoved(dispatch, getState, orgId, userName);
      trackEvent(TrackedOrganizationEvents.RemoveUserFromOrg, {
        userName
      });
    } catch (error) {
      let errMsg = 'Error removing user from organization';
      if (isAtLeastOneAdminError(error) || isRemoveLastAdminError(error))
        errMsg = 'An organization must have at least one administrator';
      else if (isLastProjectAdminError(error))
        errMsg = 'A project admin cannot be removed if they are the only admin in the project.';
      notification.error(errMsg);
    }
  };
}
