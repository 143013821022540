import React from 'react';
import { List } from 'immutable';
import StepDropdown from './StepDropdown';
import ExportIconButton from './ExportIconButton';
import TargetOrFluorsSelector from './TargetsOrFluorsSelector';
import AnalysisSetup from './analysis_setup';
import GhostButton from '../../../../frontend-common-libs/src/components/common/buttons/GhostButton';
import { MeltModeEnum } from '../../../../selectors/MeltData';

export type Props = {
  disabled: boolean;
  isMeltStepSelected: boolean;
  isAmpStepSelected: boolean;
  handleAmpButtonClick: (...args: Array<any>) => any;
  handleMeltButtonClick: (...args: Array<any>) => any;
  ampSteps: List<number>;
  meltSteps: List<number>;
  selectedStep: number | null | undefined;
  changeSelectedStep: (...args: Array<any>) => any;
  csvDataString: string;
  isExportButtonDisabled: boolean;
  fileName: string;
  meltMode: string;
};

const getStepList = (
  isAmpStepSelected: boolean,
  isMeltStepSelected: boolean,
  ampSteps: List<number>,
  meltSteps: List<number>
) => {
  if (!isAmpStepSelected && !isMeltStepSelected) {
    return List();
  }

  return isAmpStepSelected ? ampSteps : meltSteps;
};

export default function ({
  disabled,
  isMeltStepSelected,
  isAmpStepSelected,
  handleAmpButtonClick,
  handleMeltButtonClick,
  ampSteps,
  meltSteps,
  selectedStep,
  changeSelectedStep,
  csvDataString,
  isExportButtonDisabled,
  fileName,
  meltMode
}: Props) {
  const steps = getStepList(isAmpStepSelected, isMeltStepSelected, ampSteps, meltSteps);
  const meltAnalysisDisabled = isMeltStepSelected && meltMode !== MeltModeEnum.MeltPeak;
  return (
    <>
      <div className="button-group">
        {ampSteps.size > 0 ? (
          <GhostButton
            id="amplification-button"
            type="button"
            isSelected={isAmpStepSelected}
            disabled={disabled}
            onClick={!isAmpStepSelected ? handleAmpButtonClick : undefined}
            tabIndex={isAmpStepSelected ? -1 : 0}
          >
            Amplification
          </GhostButton>
        ) : null}
        {meltSteps.size > 0 ? (
          <GhostButton
            id="melt-curve-button"
            type="button"
            isSelected={isMeltStepSelected}
            disabled={disabled}
            onClick={!isMeltStepSelected ? handleMeltButtonClick : undefined}
            tabIndex={isMeltStepSelected ? -1 : 0}
          >
            Melt Curve
          </GhostButton>
        ) : null}
      </div>
      <AnalysisSetup
        disabled={disabled || meltAnalysisDisabled}
        isMeltStepSelected={isMeltStepSelected}
      />
      <TargetOrFluorsSelector disabled={disabled} />
      <ExportIconButton
        csvDataString={csvDataString}
        fileName={fileName}
        disabled={disabled || isExportButtonDisabled}
      />
      <StepDropdown
        disabled={disabled}
        // @ts-ignore
        steps={steps}
        changeStep={changeSelectedStep}
        selectedStep={selectedStep}
      />
    </>
  );
}
