export default class Credentials {
  credentials: any;

  constructor(credentials: any) {
    this.credentials = credentials;
  }

  get accessKeyId() {
    return this.credentials.accessKeyId;
  }

  get secretKey() {
    return this.credentials.secretAccessKey;
  }

  get sessionToken() {
    return this.credentials.sessionToken;
  }

  get expiration() {
    return Date.parse(this.credentials.expiration);
  }
}
