import { Map, fromJS } from 'immutable';
import {
  USERFILES_DELETED,
  USERFILES_ARCHIVED,
  USERFILES_PROCESSING_COMPLETE,
  USERFILES_RENAMED
} from '../file-management/actions/action-types';
import { UNAUTH_USER } from '../auth/actions/auth_types';
import {
  QPCRDATA_LOADED,
  QPCRDATA_LOADING,
  QPCRDATA_ERROR,
  QPCRDATA_STEP_LOADED,
  QPCRDATA_RUN_ADDED,
  QPCRDATA_RUN_EDITED,
  QPCRDATA_RUN_CLOSED,
  QPCRDATA_RUN_IS_SAVING
} from '../actions/qpcrdata_types';
import { ApiAction } from '../types';
import { maybeHash } from '../frontend-common-libs/src/utils/immutableUtils';
import { getWindowUrl } from '../utils/windowUtils';
import coreRoutes from '../core/routes';
import realTimePcrRoutes from '../real-time-pcr/routes';
import {
  FILETYPE_IN_PROGRESS_CFX_RUN,
  FILETYPE_PENDING_CFX_RUN
} from '../frontend-common-libs/src/common/userfiles_common';
import { NullableNumber } from '../frontend-common-libs/src/common/nullable-types';
import { UserFile } from '../frontend-common-libs/src/common/types';
// @ts-ignore
const INITIAL_STATE: Map<string, any> = Map();

function isNewerVersion(state: Map<string, any>, entity: UserFile): boolean {
  const { id, versionNumber } = entity;
  const storedVersionNumber = state.getIn([id, 'data', 'versionNumber']) as NullableNumber;
  return (
    versionNumber != null && storedVersionNumber != null && versionNumber > storedVersionNumber
  );
}

export function isEditingCfxRun(entityId: string): boolean {
  const currentUrl = getWindowUrl();
  return currentUrl.includes(`${coreRoutes.APP}${realTimePcrRoutes.QPCR}/${entityId}`);
}

const onPcrDataDeleted = (state: Map<string, any>, action: any) => {
  const { fileList: deletedList } = action.payload;
  let newState = state;
  deletedList.forEach((id: string) => {
    newState = newState.delete(id);
  });
  return newState;
};

const onPcrDataArchived = (state: Map<string, any>, action: any) => {
  const { entities: archivedList } = action.payload;
  let newState = state;
  archivedList.forEach((entity: UserFile) => {
    if (isEditingCfxRun(entity.id) && isNewerVersion(state, entity))
      newState = newState.setIn([entity.id, 'hasNewerVersion'], true);
    else newState = newState.delete(entity.id);
  });
  return newState;
};

function getIdFromPayload(id: string, faEntity: any): string {
  if (id) return id;
  return faEntity.id;
}

const onPcrDataLoaded = (
  state: Map<string, any>,
  { payload: { faId, faEntity, data } }: { payload: { faId: string; faEntity: any; data: any } }
) => {
  const id = getIdFromPayload(faId, faEntity);
  let runData = data;
  const existingStepData = state.getIn([id, 'data', 'stepData']);
  // @ts-ignore
  if (existingStepData && existingStepData.size) {
    // @ts-ignore
    const mergedStepData = existingStepData.merge(runData.get('stepData'));
    runData = runData.set('stepData', mergedStepData);
  }
  return state
    .setIn([id, 'loading'], false)
    .setIn([id, 'data'], runData)
    .setIn([id, 'hasNewerVersion'], false);
};
const onPcrDataError = (state: Map<string, any>, action: any) => {
  const { faId } = action.payload;
  return state.delete(faId);
};

const onPcrDataLoading = (state: Map<string, any>, action: any) => {
  const { faId } = action.payload;
  return state.setIn([faId, 'loading'], true);
};

const onPcrStepLoaded = (state: Map<string, any>, action: any) => {
  const { faId, step, groupMode, stepData, plate, perStepAnalysisSettings } = action.payload;
  if (state.has(faId)) {
    const data = fromJS(stepData);
    const selectedStepStr = step.toString();
    const originalPlate = state.getIn([faId, 'data', 'plate']);
    const originalPerStepAnalysisSettings = state.getIn([
      faId,
      'data',
      'settings',
      'analysis',
      'perStep',
      selectedStepStr,
      groupMode === 'target' ? 'targets' : 'fluors'
    ]);
    if (
      // @ts-ignore
      maybeHash(originalPlate) === maybeHash(plate) &&
      // @ts-ignore
      maybeHash(originalPerStepAnalysisSettings) === maybeHash(perStepAnalysisSettings)
    ) {
      return state.setIn([faId, 'data', 'stepData', selectedStepStr, groupMode], data);
    }
  }
  return state;
};

export function shouldReloadRun(currentType: string, newType: string): boolean {
  if (currentType === newType) return false;
  if (currentType === FILETYPE_IN_PROGRESS_CFX_RUN && newType === FILETYPE_PENDING_CFX_RUN)
    return false;
  return true;
}

const onPcrRunCompleted = (state: Map<string, any>, action: any) => {
  const { id, type } = action.payload.entity;
  const currentType: string = state.getIn([id, 'data', 'type']) as string;
  if (shouldReloadRun(currentType, type) || !isEditingCfxRun(id)) return state.delete(id);
  if (isNewerVersion(state, action.payload.entity)) {
    return state.setIn([id, 'hasNewerVersion'], true);
  }

  return state;
};

const onRunClosed = (state: Map<string, any>, action: any) => {
  const { faId } = action.payload;
  const hasNewerVersion = state.getIn([faId, 'hasNewerVersion']);
  if (hasNewerVersion) return state.delete(faId);
  return state;
};

const onRunIsSaving = (state: Map<string, any>, action: any) => {
  const { faId, isSaving } = action.payload;
  return state.setIn([faId, 'isRunSaving'], isSaving);
};

const onUserFileRenamed = (state: Map<string, any>, action: any) => {
  const { faEntity } = action.payload;
  return state.has(faEntity.id) ? state.delete(faEntity.id) : state;
};

const resetState = () => INITIAL_STATE;

const actionMap = {
  [QPCRDATA_LOADING]: onPcrDataLoading, // Querying pcr-data
  [QPCRDATA_ERROR]: onPcrDataError, // pcr-data query failed
  [QPCRDATA_LOADED]: onPcrDataLoaded, // pcr-data loaded
  [QPCRDATA_RUN_ADDED]: onPcrDataLoaded, // new pending run
  [QPCRDATA_RUN_EDITED]: onPcrDataLoaded, // pending run edited
  [USERFILES_PROCESSING_COMPLETE]: onPcrRunCompleted, // pending run completed
  [QPCRDATA_STEP_LOADED]: onPcrStepLoaded, // step data loaded
  [USERFILES_DELETED]: onPcrDataDeleted, // user files deleted,
  [USERFILES_ARCHIVED]: onPcrDataArchived, // user files archived,
  [USERFILES_RENAMED]: onUserFileRenamed, // user file renamed,
  [UNAUTH_USER]: resetState, // User logged out
  [QPCRDATA_RUN_CLOSED]: onRunClosed, // the run is closed (not displayed)
  [QPCRDATA_RUN_IS_SAVING]: onRunIsSaving // the run is closed (not displayed)
};

export default function qpcrdataReducer(
  state: Map<string, any> = INITIAL_STATE,
  action: ApiAction<any, any> | undefined = undefined
): Map<string, any> {
  if (action) {
    return actionMap[action.type] ? actionMap[action.type](state, action) : state;
  }
  return state;
}
