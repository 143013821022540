import React from 'react';
import TRow from '../../../frontend-common-libs/src/components/common/tables/TRow';
import TCell from '../../../frontend-common-libs/src/components/common/tables/TCell';
import ConventionalPCRInstrumentTypeIcon from '../../instrument-row/ConventionalPCRInstrumentTypeIcon';
import styles from './styles/fleet-management-row.module.scss';
import ReservationTimer from '../ReservationTimer';
import { ImmutableMap, InstrumentItem } from '../../../frontend-common-libs/src/common/types';
import InstrumentReservation from '../InstrumentReservation';
import ReservationFacade from '../ReservationFacade';

export type Props = {
  instrument: ImmutableMap<InstrumentItem>;
  reservation: InstrumentReservation;
  children: [React.ReactNode, React.ReactNode];
  title?: string | null;
};
export default function FleetManagementRow(props: Readonly<Props>) {
  const { instrument, reservation, children, title } = props;
  const instrumentId = instrument.get('id');
  const instrumentType = instrument.get('type');
  const instrumentName = instrument.get('name');

  const reservationFacade = new ReservationFacade({
    instrument,
    reservation,
    userId: reservation?.userId,
    reservationShadowLoaded: !!reservation?.isLoaded
  });

  const { instrumentFacade, displayedReservationStatus } = reservationFacade;
  const { modelName } = instrumentFacade;

  const [checkbox, additionalColumns] = children;

  return (
    <TRow id={`inst-row_${instrumentId}`} tabIndex={-1} title={title}>
      {checkbox}
      <TCell className={styles.modelColumn}>
        <ConventionalPCRInstrumentTypeIcon type={instrumentType} />
        <span>{modelName}</span>
      </TCell>
      <TCell className={`${styles.nameCell} ${styles.cellText}`}>{instrumentName}</TCell>
      {additionalColumns}
      <ReservationTimer
        instrument={instrument}
        reservation={reservation}
        reservationStatus={displayedReservationStatus}
        reservationTimeout={reservationFacade.reservationPendingTimeout()}
        cancellationTimeout={reservationFacade.cancellationPendingTimeout()}
      />
    </TRow>
  );
}
