import * as React from 'react';
import { Button } from 'react-bootstrap';
import classnames from 'classnames';

type Props = {
  className: string;
  id: string;
  iconSrc: string;
  alt: string;
  onClick: (...args: Array<any>) => any;
  disabled?: boolean;
  title?: string;
  children: any;
};

function IconButton(props: Props) {
  const { className, id, iconSrc, alt, onClick, disabled, title, children } = props;
  return (
    <Button
      className={classnames('new-btn-icon', className)}
      id={id}
      disabled={disabled}
      onClick={onClick}
      title={title}
    >
      <img className="icon" src={iconSrc} alt={alt} />
      {children}
    </Button>
  );
}

function VerticalIconButton(props: Props) {
  const { className, ...rest } = props;
  return <IconButton className={classnames('vertical', className)} {...rest} />;
}

IconButton.defaultProps = {
  disabled: false
};

VerticalIconButton.defaultProps = {
  disabled: false
};

export { VerticalIconButton, IconButton };
