export default class CycleRangeMultiEdit {
  allSelected = false;

  allStart: string | number = '';

  allEnd: string | number = '';

  selectedWells: string[] = [];

  reset() {
    this.allSelected = false;
    this.selectedWells = [];
    this.allStart = '';
    this.allEnd = '';
  }

  get selectedCount(): number {
    return this.selectedWells.length;
  }

  isWellSelected = (wellId: string) => {
    return this.selectedWells.includes(wellId);
  };

  selectAll = (value: boolean, allWells: string[]) => {
    this.allSelected = value;
    if (value) {
      this.selectedWells = allWells;
    } else this.selectedWells = [];
  };

  selectWell = (value: boolean, wellId: string) => {
    if (value) this.selectedWells.push(wellId);
    else this.selectedWells = this.selectedWells.filter(well => well !== wellId);
  };
}
