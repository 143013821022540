import React, { PureComponent } from 'react';
import PCRProtocolStepEditorModal from '../PCRProtocolStepEditorModal';
import EditStepContainer from '../EditStepContainer';
import IntFieldGroup from '../../../common/IntFieldGroup';
import { isValidStep, isValidCycles } from '../PCREditorValidateInput';
import QPcrStep from '../models/QPcrStep';
import { StepValue } from '../../pcr_protocol_types';
import { ProtocolStepProps } from '../PCRProtocolStepTypes';
import PCRProtocolSteps from '../PCRProtocolSteps';

export type Props = ProtocolStepProps & {
  minGotoIndex: number;
};
export type State = {
  editStep: QPcrStep;
};

export class PCRProtocolGotoSteps extends PureComponent<Props, State> {
  static defaultProps = {
    selectedIndex: 0,
    minGotoIndex: 0
  };

  constructor(props: Props) {
    super(props);
    const { editing } = this.props;
    if (editing) {
      this.state = { editStep: new QPcrStep(props.step) };
    }
  }

  componentWillReceiveProps(nextProps: Props) {
    const { editing } = this.props;
    if (nextProps.editing && !editing) {
      this.setState({ editStep: new QPcrStep(nextProps.step) });
    }
  }

  onEditSave = () => {
    const { index, saveEditAction } = this.props;
    const { editStep } = this.state;
    saveEditAction(index, editStep.map);
  };

  onEditCancel = () => {
    const { cancelEditAction } = this.props;
    cancelEditAction();
  };

  onFieldChanged = (key: string, value?: StepValue) => {
    const { editStep } = this.state;
    let val = value;
    if (key === 'step' && typeof value === 'number') {
      val = value - 1;
    }
    const newStep = editStep.set(key, val);
    this.setState({ editStep: newStep });
  };

  selectIndex = () => {
    const { setSelectedStepIndex, index } = this.props;
    setSelectedStepIndex(index);
  };

  renderViewStep() {
    const { step } = this.props;
    const { gotoStepNumber, cycles } = new QPcrStep(step);

    const gotoStr = `GOTO Step #${gotoStepNumber} (${cycles} more times)`;
    return (
      <div role="presentation" onClick={this.selectIndex}>
        {gotoStr}
      </div>
    );
  }

  renderEditStep() {
    const { minGotoIndex, index, isAdd, stepsList, editStepTypeChangeAction } = this.props;
    const { editStep } = this.state;
    const { gotoStepIndex, gotoStepNumber, cycles } = editStep;

    const stepValid = isValidStep(gotoStepIndex, minGotoIndex, index - 1);
    const cyclesValid = isValidCycles(cycles, 9999);

    const saveValid = stepValid.valid && cyclesValid.valid;

    return (
      <PCRProtocolStepEditorModal
        onSave={saveValid ? this.onEditSave : undefined}
        onCancel={this.onEditCancel}
        isAdd={isAdd}
        index={index}
      >
        <EditStepContainer
          currentType="goto"
          stepsList={stepsList}
          onEditStepTypeChange={editStepTypeChangeAction}
        >
          <li>
            <div className="col-xs-12 form-group ">
              <IntFieldGroup
                id="step-edit"
                name="step"
                label="Step"
                error={stepValid.helpString}
                value={gotoStepNumber}
                onChange={this.onFieldChanged}
                validationState={stepValid.valid ? null : 'error'}
                allowNegative={false}
              />
            </div>
          </li>
          <li>
            <div className="col-xs-12 form-group ">
              <IntFieldGroup
                id="cycles-edit"
                name="cycles"
                label="Count"
                error={cyclesValid.helpString}
                value={cycles}
                onChange={this.onFieldChanged}
                validationState={cyclesValid.valid ? null : 'error'}
                allowNegative={false}
              />
            </div>
          </li>
        </EditStepContainer>
      </PCRProtocolStepEditorModal>
    );
  }

  render() {
    const { editing, index, selectedIndex, deleteAction, editAction } = this.props;
    if (editing) {
      return this.renderEditStep();
    }
    return (
      <PCRProtocolSteps
        index={index}
        selectedIndex={selectedIndex}
        deleteAction={deleteAction}
        editAction={editAction}
      >
        {this.renderViewStep()}
      </PCRProtocolSteps>
    );
  }
}

export default PCRProtocolGotoSteps;
