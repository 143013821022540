import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { WrappedFieldProps } from 'redux-form';
import { FORM_ERROR_CLASS } from '../../form_validators';

export type Props = {
  label: string;
  type: string;
  additionalClass: string;
  extraError: string;
  fieldInit: (...args: Array<any>) => any;
  cols: number;
  rows: number;
  disabled: boolean;
  autoComplete?: string;
} & WrappedFieldProps;

class RenderField extends React.PureComponent<Props> {
  private inputRef: HTMLInputElement | HTMLTextAreaElement | undefined = undefined;

  componentDidMount() {
    const { fieldInit } = this.props;
    if (fieldInit) {
      fieldInit(this.inputRef);
    }
  }

  private inputGetRef = (ref: (HTMLInputElement | null | undefined) | HTMLTextAreaElement) => {
    if (ref) {
      this.inputRef = ref;
    }
  };

  render() {
    const {
      type,
      rows,
      cols,
      disabled,
      additionalClass,
      extraError,
      label,
      input,
      autoComplete,
      meta: { error, touched }
    } = this.props;
    let fullError = extraError || undefined;
    fullError = error || fullError || undefined;

    let fieldClass = 'form-field';
    if (touched && fullError) {
      fieldClass = `${fieldClass} ${FORM_ERROR_CLASS}`;
    }
    return (
      <div>
        <Row>
          <Col md={6} xs={6} className="form-label">
            {label}
          </Col>
          <Col md={6} xs={6} id={`${input.name}-error`} className="form-label-error">
            {touched && fullError && <span>{fullError}</span>}
          </Col>
        </Row>
        {type !== 'textarea' ? (
          <input
            {...input}
            id={`${input.name}-field`}
            type={type}
            className={`${fieldClass} ${additionalClass}`}
            disabled={disabled}
            ref={this.inputGetRef}
            autoComplete={autoComplete}
          />
        ) : (
          <textarea
            {...input}
            id={`${input.name}-field`}
            className={`${fieldClass} ${additionalClass}`}
            ref={this.inputGetRef}
            disabled={disabled}
            cols={cols}
            rows={rows}
          />
        )}
      </div>
    );
  }
}

export default RenderField;
