import InstrumentFamilyPlugin from './InstrumentFamilyPlugin';
import RemotePluginManifest from './RemotePluginManifest';
import RemotePluginComponents from './RemotePluginComponents';
import BaseInstrumentFamilyPlugin from './BaseInstrumentFamilyPlugin';
import PluginCreateExperimentCard from './PluginCreateExperimentCard';

export default class RemoteInstrumentFamilyPlugin
  extends BaseInstrumentFamilyPlugin
  implements InstrumentFamilyPlugin
{
  protected readonly manifest: RemotePluginManifest;

  public constructor(manifest: RemotePluginManifest) {
    super(manifest, new RemotePluginComponents(manifest));

    this.manifest = manifest;
  }

  public get createExperimentCard(): PluginCreateExperimentCard | undefined {
    return this.manifest.createExperimentCard;
  }

  public createInstrumentThumbnailUrl(instrumentThumbnailRelativePath: string): string {
    return this.manifest.createAbsoluteArtifactPath(instrumentThumbnailRelativePath);
  }
}
