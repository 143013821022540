import { DispatchType, LOGIN_TOKEN_LOADED, LOGIN_TOKEN_LOADING } from './action-types';
import notification from '../../frontend-common-libs/src/utils/notifications';
import { login as loginApi } from '../api/org-api';
import { GetState } from '../../types';
import { getUserEmail, getUserSubId } from '../../selectors/auth-selectors';
import { getLoginInfo, isLoginTokenLoading } from '../selectors/selectors';

export function login() {
  return async (dispatch: DispatchType, getState: GetState) => {
    const state = getState();
    const userSubId = getUserSubId(state);
    const email = getUserEmail(state);
    try {
      dispatch({
        type: LOGIN_TOKEN_LOADING,
        payload: {}
      });
      const loginInfo = await loginApi(email, userSubId);
      dispatch({
        type: LOGIN_TOKEN_LOADED,
        payload: { loginInfo, setActiveOrg: true }
      });
    } catch (e) {
      notification.error('Error: Organization logins temporarily unavailable.');
    }
  };
}

export function getLoginTokenIfNeeded() {
  return async (dispatch: DispatchType, getState: GetState) => {
    const state = getState();
    const isLoginTokenLoaded = getLoginInfo(state) != null;
    if (!isLoginTokenLoaded && !isLoginTokenLoading(state)) {
      await login()(dispatch, getState);
    }
  };
}
