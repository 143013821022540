// @ts-nocheck
import React, { PureComponent } from 'react';
import { Map, List } from 'immutable';
import { Set } from 'immutable';
import ResultsChart from './ResultsChart';
import AmpChartOptions from './AmpChartOptions';
import {
  setAmpChartScaleType as setAmpChartScaleTypeAction,
  setAnalysisMode
} from '../../../actions/currentCfxRun_actions';
import { AnalysisResultsStep } from '../../../selectors/current-cfx-run-types';

export type Props = {
  step: AnalysisResultsStep;
  targetsOrFluorsToShow: Set;
  wellsToShow: Set;
  subtractBaseline: boolean;
  scale: string;
  setAmpChartScaleType: typeof setAmpChartScaleTypeAction;
  analysisMode: string;
  changeAnalysisMode: typeof setAnalysisMode;
  currentThresholds: Map;
  resultsData: any;
};

export default class AmplificationChart extends PureComponent<Props> {
  getYAxisOptions = () => {
    const { scale, subtractBaseline, currentThresholds, targetsOrFluorsToShow } = this.props;

    let min;

    if (scale === 'linear') {
      return {};
    }

    if (subtractBaseline) {
      const minThreshold =
        targetsOrFluorsToShow
          .reduce((thresholds, targetOrFluor) => {
            const threshold = currentThresholds.get(targetOrFluor);
            if (threshold > 0) return thresholds.push(threshold);
            return thresholds;
          }, List())
          .min() * 0.8;
      min = minThreshold < 100 ? minThreshold : 100;
    }
    return {
      labels: {
        useHTML: true,
        formatter: (value: { value: number }) => {
          const pow = Math.log10(value.value);
          return Number.isInteger(pow) ? `${10}<sup>${Math.log10(value.value)}<sup>` : '';
        }
      },
      type: 'logarithmic',
      min,
      startOnTick: !subtractBaseline,
      tickInterval: 1
    };
  };

  updateAnalysisMode = (e: React.MouseEvent<HTMLButtonElement>) => {
    const { changeAnalysisMode } = this.props;
    changeAnalysisMode(e.currentTarget.value);
  };

  updateScale = (e: React.MouseEvent<HTMLButtonElement>) => {
    const { setAmpChartScaleType } = this.props;
    setAmpChartScaleType(e.currentTarget.value);
  };

  render() {
    const {
      targetsOrFluorsToShow,
      wellsToShow,
      step: { mode },
      scale,
      analysisMode,
      resultsData
    } = this.props;

    const chartData = resultsData.getChartData();

    return (
      <div className="pcr-chart-container">
        <ResultsChart
          fileName="amplification"
          chartTitle="Amplification"
          yAxisLabel="RFU"
          xAxisLabel="Cycles"
          yAxisOptions={this.getYAxisOptions()}
          resultsData={resultsData}
          targetsOrFluorsToShow={targetsOrFluorsToShow}
          wellsToShow={wellsToShow}
          groupMode={mode}
        />
        {chartData.stepData.keySeq().size > 0 ? (
          <AmpChartOptions
            analysisMode={analysisMode}
            scale={scale}
            onUpdateAnalysisMode={this.updateAnalysisMode}
            onUpdateScale={this.updateScale}
          />
        ) : null}
      </div>
    );
  }
}
