import { CognitoUserAttribute } from 'amazon-cognito-identity-js';
import axios from 'axios';
import { GATEWAY } from '../../../frontend-common-libs/src/config/config';
import { convertCognitoAttributesArrayToUserProfile } from '../../actions/auth_actions';

export interface BackupUserDataRequestParams {
  email: string;
  customAttributes: CognitoUserAttribute[];
  idToken: string;
  accessToken: string;
}

export default class BackupUserDataRequest {
  private readonly url =
    GATEWAY.AUTH_SERVICE_PATHS.BASE_URL + GATEWAY.AUTH_SERVICE_PATHS.BACKUP_USER_DATA;

  private readonly email: string;

  private readonly customAttributes: CognitoUserAttribute[];

  private readonly idToken: string;

  private readonly accessToken: string;

  constructor(params: BackupUserDataRequestParams) {
    this.email = params.email;
    this.customAttributes = params.customAttributes;
    this.idToken = params.idToken;
    this.accessToken = params.accessToken;
  }

  public async send(): Promise<any> {
    try {
      const params = {
        email: this.email,
        customAttributes: convertCognitoAttributesArrayToUserProfile(this.customAttributes),
        idToken: this.idToken,
        accessToken: this.accessToken
      };
      await axios.post(this.url, params);
    } catch (err) {
      throw new Error('Error while backing up user');
    }
  }
}
