import notification from '../../utils/notifications';
import IOTCredentialsProvider from '../IOTCredentialsProvider';

export default class CredentialsRetryProvider {
  attempts = 0;

  maxAttempts: number;

  retryDelay = 5000;

  cancelled = false;

  credentialsProvider: IOTCredentialsProvider;

  constructor(credentialsProvider: IOTCredentialsProvider, maxAttempts = 3) {
    this.credentialsProvider = credentialsProvider;
    this.maxAttempts = maxAttempts;
  }

  cancel = () => {
    this.cancelled = true;
  };

  throwIfCancelled = () => {
    if (this.cancelled) throw new Error('IOT connection cancelled');
  };

  async resolve(): Promise<any> {
    try {
      const credentials = await this.credentialsProvider.resolve();
      this.throwIfCancelled();
      return credentials;
    } catch (err) {
      this.attempts += 1;
      if (!this.cancelled && this.attempts < this.maxAttempts) {
        notification.info(
          `Could not connect to server, attempting to connect in ${this.retryDelay / 1000} seconds`
        );
        // eslint-disable-next-line no-promise-executor-return
        await new Promise(r => setTimeout(r, this.retryDelay));
        this.throwIfCancelled();
        return this.resolve();
      }
      notification.error('Failed to connect to server');
      throw err;
    }
  }
}
