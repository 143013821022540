import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { Set, Map, List } from 'immutable';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { ReduxState } from '../../types';
import PlateEditor from './wellediting/PlateEditor';
import Toolbar from './wellediting/Toolbar';
import SelectionPlate from './plateview/SelectionPlate';
import PlateSetup from './wellediting/PlateSetup';
import { ModifierKeys } from './plateview/SelectionBox';
import { showPlateSetup as updateShowPlateSetupAction } from '../../actions/currentCfxRun_actions';
import {
  isCompletedRun as getIsCompletedRun,
  isInProgressRun as getIsInProgressRun,
  isPlateValid as getIsPlateValid,
  getShowPlateSetup,
  getWellReplicateNumberFormatter,
  getPlate,
  getIsRunFromTemplate,
  getSamplePlaceholders
} from '../../selectors/selectors';
import {
  PrimaryButton,
  SecondaryButton
} from '../../frontend-common-libs/src/components/common/buttons';
import { ReplicateFormatterFunction } from '../../selectors/current-cfx-run-selectors';
import { WithSamplePlaceholders } from './WithSamplePlaceholders';

export type PCRPlatePageProps = {
  isEditable?: boolean;
  isRunFromTemplate: boolean;
  plate: Map<string, any>;
  saveAction: (arg0: string) => Promise<void>;
  isPendingRun: boolean;
  redirectUrl: string;
  isPlateValid: boolean;
  showPlateSetup: boolean;
  updateShowPlateSetup: (showPlate: boolean) => void;
  isAnalysisView: boolean;
  wellReplicateNumberFormatter: ReplicateFormatterFunction;
  samplePlaceholders?: List<Map<string, any>>;
};

export type PCRPlatePageState = {
  wellsToEdit: Set<string>;
  redirectToRun: boolean;
};

export class PCRPlatePageImpl extends Component<PCRPlatePageProps, PCRPlatePageState> {
  static defaultProps = { isEditable: false };

  constructor(props: PCRPlatePageProps) {
    super(props);
    this.state = { wellsToEdit: Set(), redirectToRun: false };
  }

  handleRedirectToRun = () => {
    this.setState({ redirectToRun: true });
  };

  handleWellSelect = (newSelectedWells: Set<string>, modifierKeys: ModifierKeys) => {
    // If all wells or all wells in a column/row are selected, then they should be deselected
    const { wellsToEdit } = this.state;
    let selectedWells = wellsToEdit.equals(newSelectedWells) ? Set() : newSelectedWells;
    const { ctrlKey, metaKey } = modifierKeys;
    // metaKey = command key on Mac, ctrlKey for other OSs
    if (ctrlKey || metaKey) {
      const { wellsToEdit: currentSelectedWells } = this.state;
      const areAllSelected = newSelectedWells.isSubset(currentSelectedWells);
      if (areAllSelected) {
        // if all new selected wells are already selected then they should be unselected
        selectedWells = currentSelectedWells.subtract(newSelectedWells);
      } else {
        selectedWells = currentSelectedWells.union(newSelectedWells);
      }
    }
    // @ts-ignore
    this.setState({ wellsToEdit: selectedWells });
  };

  showHideSetup = () => {
    const { showPlateSetup, updateShowPlateSetup } = this.props;
    updateShowPlateSetup(!showPlateSetup);
  };

  renderToolbar = () => {
    const { plate, isRunFromTemplate, saveAction } = this.props;
    const { wellsToEdit } = this.state;
    return (
      !isRunFromTemplate && (
        <Toolbar plate={plate} selectedWellIds={wellsToEdit} saveAction={saveAction} />
      )
    );
  };

  renderSelectionPlate = (plate: Map<string, any>) => {
    const { isAnalysisView, wellReplicateNumberFormatter } = this.props;
    const { wellsToEdit } = this.state;

    const wells = plate.get('wells');
    const plateClass = 'plate-table';
    return (
      <SelectionPlate
        editMode
        // @ts-ignore
        rows={plate.getIn(['layout', 'rows'])}
        // @ts-ignore
        cols={plate.getIn(['layout', 'columns'])}
        wells={wells}
        selectedWells={wellsToEdit}
        wellReplicateNumberFormatter={wellReplicateNumberFormatter}
        changeSelectedWells={this.handleWellSelect}
        plateWrapperSelector={`.${plateClass}`}
        isAnalysisView={isAnalysisView}
        colHeaderOffset={20}
        rowHeaderOffset={20}
        tableSpacingLeft={12}
        tableSpacingTop={0}
      />
    );
  };

  renderWithSamplePlaceholders = () => {
    const { plate, samplePlaceholders } = this.props;

    return (
      samplePlaceholders && (
        <WithSamplePlaceholders
          plate={plate}
          renderPlate={this.renderSelectionPlate}
          samplePlaceholders={samplePlaceholders}
        />
      )
    );
  };

  renderPlateDisplay = () => {
    const { isRunFromTemplate, plate } = this.props;
    const plateClass = 'plate-table';
    return (
      <div id="plate-display">
        {this.renderToolbar()}
        <div className={`${plateClass}${isRunFromTemplate ? ' template-run' : ''}`}>
          {isRunFromTemplate
            ? this.renderWithSamplePlaceholders()
            : this.renderSelectionPlate(plate)}
        </div>
      </div>
    );
  };

  renderPlateSetup = () => (
    <div>
      <h4>Select Plate Details</h4>
      <PlateSetup />
    </div>
  );

  renderEditorContent = () => {
    const { showPlateSetup, isEditable, plate } = this.props;
    const { wellsToEdit } = this.state;
    if (showPlateSetup) return this.renderPlateSetup();
    if (isEditable) return <PlateEditor plate={plate} selectedWellIds={wellsToEdit} />;
    return null;
  };

  renderSetupEditPanel = () => (
    <div className="plate-edit-panel flex-column-container">
      <div className="plate-edit-content">{this.renderEditorContent()}</div>
      {this.renderSetupEditToggle()}
    </div>
  );

  renderSetupEditToggle = () => {
    const { showPlateSetup, isPendingRun, isPlateValid, isRunFromTemplate } = this.props;
    const showToRunButton = !showPlateSetup && isPendingRun;
    return (
      <div
        className={classNames('plate-edit-footer', {
          'align-right': showPlateSetup
        })}
      >
        {showPlateSetup ? (
          <PrimaryButton type="button" id="setup-edit-toggle" onClick={this.showHideSetup}>
            Next Step
          </PrimaryButton>
        ) : (
          !isRunFromTemplate && (
            <SecondaryButton type="button" id="setup-edit-toggle" onClick={this.showHideSetup}>
              Previous Step
            </SecondaryButton>
          )
        )}
        {showToRunButton && (
          <div className="button-group right">
            <PrimaryButton
              type="button"
              id="continue-to-run"
              onClick={this.handleRedirectToRun}
              disabled={!isPlateValid}
            >
              Continue to Run
            </PrimaryButton>
          </div>
        )}
      </div>
    );
  };

  render() {
    const { redirectUrl } = this.props;
    const { redirectToRun } = this.state;
    if (redirectToRun) return <Redirect push to={redirectUrl} />;

    return (
      <div className="pcr-run-plate">
        {this.renderPlateDisplay()}
        {this.renderSetupEditPanel()}
      </div>
    );
  }
}
export function mapStateToProps(state: ReduxState) {
  return {
    plate: getPlate(state),
    showPlateSetup: getShowPlateSetup(state),
    isPendingRun: !getIsCompletedRun(state) && !getIsInProgressRun(state),
    isPlateValid: getIsPlateValid(state),
    isRunFromTemplate: getIsRunFromTemplate(state),
    wellReplicateNumberFormatter: getWellReplicateNumberFormatter(state),
    samplePlaceholders: getSamplePlaceholders(state)
  };
}
export default connect(mapStateToProps, { updateShowPlateSetup: updateShowPlateSetupAction })(
  PCRPlatePageImpl
);
