import { Map } from 'immutable';
import noneProtocolItem from '../noneProtocolItem';
import InstrumentReservation from '../InstrumentReservation';
import { ImmutableMap, InstrumentItem } from '../../../frontend-common-libs/src/common/types';
import AssignProtocolButtonClickEvent from '../../../user-analytics/fleet-management/AssignProtocolButtonClickEvent';
import getUniqueInstrumentModels from '../../../user-analytics/fleet-management/util';
import UnreserveInstrumentsButtonClickEvent from '../../../user-analytics/fleet-management/UnreserveInstrumentsButtonClickEvent';

export function trackUnreserveEvent(
  selectedInstruments: string[],
  instruments: ImmutableMap<InstrumentItem>[]
): void {
  const models = getUniqueInstrumentModels(selectedInstruments, instruments) as string[];
  new UnreserveInstrumentsButtonClickEvent().track({
    numberOfInstruments: selectedInstruments.length,
    deviceTypes: ['PTCTempo'],
    models
  });
}

export default async function unreserveSelectedInstruments(
  selectedInstruments: string[],
  instruments: ImmutableMap<InstrumentItem>[],
  reservations: Map<string, InstrumentReservation>
): Promise<void> {
  const unreservePromises = selectedInstruments.map(instrumentId => {
    const reservation = reservations.get(instrumentId);
    return reservation?.unreserve();
  });
  trackUnreserveEvent(selectedInstruments, instruments);
  await Promise.all(unreservePromises);
}

export function trackEvent(
  selectedInstruments: string[],
  instruments: ImmutableMap<InstrumentItem>[],
  selectedProtocolId: string
): void {
  const models = getUniqueInstrumentModels(selectedInstruments, instruments) as string[];
  new AssignProtocolButtonClickEvent().track({
    numberOfInstruments: selectedInstruments.length,
    deviceTypes: ['PTCTempo'],
    models,
    protocolId: selectedProtocolId
  });
}

export async function assignProtocolHandler(
  selectedInstruments: string[],
  instruments: ImmutableMap<InstrumentItem>[],
  reservations: Map<string, InstrumentReservation>,
  selectedProtocolName: string,
  selectedProtocolId: string
): Promise<void> {
  if (selectedProtocolId === noneProtocolItem.id) {
    const unassignProtocolPromises = selectedInstruments.map(instrumentId => {
      const reservation = reservations.get(instrumentId);
      return reservation?.unassignProtocol();
    });
    await Promise.all(unassignProtocolPromises);
  } else {
    const assignProtocolPromises = selectedInstruments.map(instrumentId => {
      const reservation = reservations.get(instrumentId);
      return reservation?.assignProtocol(selectedProtocolId, selectedProtocolName);
    });
    trackEvent(selectedInstruments, instruments, selectedProtocolId);
    await Promise.all(assignProtocolPromises);
  }
}
