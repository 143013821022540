import React from 'react';
import { Route } from 'react-router-dom';
import routes, { orgRouteTitles } from './OrgRoutes';
import OrganizationsPage from '../organizations/OrganizationsPage';
import EditOrganization from '../organizations/EditOrganization';
import { addExternalRouteTitles } from '../../core/routes';

export default class OrgRouter {
  private constructor() {
    OrgRouter.addRoutsBreadcrumbs();
  }

  private static _instance: OrgRouter | undefined = undefined;

  public static get instance(): OrgRouter {
    if (this._instance == null) this._instance = new OrgRouter();
    return this._instance;
  }

  private static addRoutsBreadcrumbs() {
    addExternalRouteTitles(routes, orgRouteTitles);
  }

  private readonly orgRoutes = [
    <Route exact path={routes.USER_ORGANIZATIONS} component={OrganizationsPage} key="1" />,
    <Route exact path={routes.EDIT_ORGANIZATION} component={EditOrganization} key="2" />
  ];

  public getOrganizationRouts() {
    return this.orgRoutes;
  }
}
