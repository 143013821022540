export default class TemplateEntity {
  constructor(private readonly _id: string, private readonly _name: string) {}

  public get id(): string {
    return this._id;
  }

  public get name(): string {
    return this._name;
  }
}
