import { OrgRoles } from '@biorad-lsg-tsc/organization';
import { DispatchType, ORG_USER_ADDED } from './action-types';
import {
  TrackedOrganizationEvents,
  trackEvent
} from '../../frontend-common-libs/src/user-analytics/trackedEvents';
import { GetState } from '../../types';
import { addUserToOrganization } from '../api/org-api';
import { withRefreshOrgToken } from './with-refresh-org-token';
import { CbWithToken } from './WithRefreshToken';

export function dispatchOrgUserAdded(
  dispatch: DispatchType,
  orgId: string,
  userName: string,
  userRole: string
) {
  dispatch({
    type: ORG_USER_ADDED,
    payload: { orgId, userName, userRole }
  });
}

export function orgUserAdded(orgId: string, userName: string, userRole: string) {
  return async (dispatch: DispatchType) => {
    dispatchOrgUserAdded(dispatch, orgId, userName, userRole);
    trackEvent(TrackedOrganizationEvents.AddUserToOrg, {
      userName,
      role: userRole
    });
  };
}

export function addUserToOrg(orgId: string, userName: string, userRole: string) {
  return async (dispatch: DispatchType, getState: GetState) => {
    const addUserCb: CbWithToken = async (accessToken: string) => {
      return addUserToOrganization(orgId, userName, accessToken, userRole as OrgRoles);
    };
    await withRefreshOrgToken(dispatch, getState, orgId, addUserCb);
    await orgUserAdded(orgId, userName, userRole)(dispatch);
  };
}
