import React from 'react';
import { Map } from 'immutable';
import {
  Wrapper,
  Button,
  Menu,
  MenuItem,
  openMenu,
  closeMenu,
  WrapperState
} from 'react-aria-menubutton';
import classnames from 'classnames';
import EditFileModal, { EditModalFormValues } from '../dialogs/EditFileModal';
import notification from '../../../utils/notifications';
import ModalDialog from '../dialogs/ModalDialog';
import PopupMenuItem from './PopupMenuItem';
import { editFileStyleV2, okCancelStyleV2 } from '../dialogs/custom-modal-styles';

export const popupMenuCommands = {
  rename: 'rename',
  move: 'move',
  download: 'download',
  export: 'export',
  delete: 'delete',
  archive: 'archive',
  restore: 'restore',
  fileHistory: 'fileHistory',
  separator: 'separator'
};

export type PopupMenuItem = {
  menuItemKey: string;
  menuItemCallback: ((...args: Array<any>) => any | null | undefined) | null;
  menuItemTitle?: string;
  menuItemText?: string;
  menuItemDisabled?: boolean;
};

export type Props = {
  linkedItem: Map<string, any>;
  linkedItemEntityName: string;
  tableBodyRef: React.RefObject<any>;
  menuItems: Array<PopupMenuItem>;
};

export enum MenuLocation {
  top = 'top',
  middle = 'middleToggle',
  bottom = 'bottom'
}

export type State = {
  confirming: boolean;
  editing: boolean;
  menuOpen: boolean;
  menuPosition: MenuLocation;
};

export default class PopupMenuImpl extends React.Component<Props, State> {
  props!: Props;

  menuOptions: any;

  toggleRef: React.RefObject<any>;

  menuRef: React.RefObject<any>;

  toggleEdgeToMenuEdge: number;

  tableTop!: number;

  eventListeners: any;

  ignoredKeys: Array<string>;

  constructor(props: Props) {
    super(props);
    this.validateNoDuplicates(props.menuItems);
    this.state = {
      confirming: false,
      editing: false,
      menuOpen: false,
      menuPosition: MenuLocation.middle
    };
    this.menuRef = React.createRef();
    this.toggleRef = React.createRef();
    this.onToggleComponentUpdate = false;
    this.toggleEdgeToMenuEdge = 0;
    this.ignoredKeys = ['PageUp', 'PageDown', 'Home', 'End'];
    const linkedItemEntityName = props.linkedItemEntityName.toLowerCase();
    this.menuOptions = {
      rename: {
        callback: this.beginEdit,
        menuItemId: `${popupMenuCommands.rename}-${linkedItemEntityName}`,
        imgClass: 'popup-menu-rename-item',
        text: 'Rename'
      },
      move: {
        callback: this.moveFile,
        menuItemId: `${popupMenuCommands.move}-${linkedItemEntityName}`,
        imgClass: 'popup-menu-move-item',
        text: 'Move'
      },
      export: {
        callback: this.exportPcrd,
        menuItemId: `${popupMenuCommands.export}-${linkedItemEntityName}`,
        imgClass: 'popup-menu-download-item',
        text: 'Export PCRD'
      },
      download: {
        callback: this.download,
        menuItemId: `${popupMenuCommands.download}-${linkedItemEntityName}`,
        imgClass: 'popup-menu-download-item',
        text: 'Download'
      },
      delete: {
        callback: this.confirmDelete,
        menuItemId: `${popupMenuCommands.delete}-${linkedItemEntityName}`,
        imgClass: 'popup-menu-delete-item',
        textClass: 'popup-menu-text-red',
        text: 'Delete'
      },
      archive: {
        callback: this.confirmArchive,
        menuItemId: `${popupMenuCommands.archive}-${linkedItemEntityName}`,
        imgClass: 'popup-menu-archive-item',
        text: 'Archive'
      },
      restore: {
        callback: this.restoreEntity,
        menuItemId: `${popupMenuCommands.restore}-${linkedItemEntityName}`,
        imgClass: 'popup-menu-restore-item',
        text: 'Restore'
      },
      fileHistory: {
        callback: this.fileHistory,
        menuItemId: `${popupMenuCommands.fileHistory}-${linkedItemEntityName}`,
        imgClass: 'popup-menu-move-item', // need to change icon
        text: 'File History'
      },
      separator: {
        menuItemId: popupMenuCommands.separator
      }
    };
    this.eventListeners = {
      scroll: this.onTableScroll,
      keydown: this.handleKeyDown
    };
  }

  componentDidUpdate() {
    const { menuOpen } = this.state;
    if (menuOpen && this.onToggleComponentUpdate) {
      this.onToggleComponentUpdate = false;
      this.onTableScroll();
    }
  }

  onToggleComponentUpdate: boolean;

  onMenuToggle = (e: WrapperState) => {
    if (e.isOpen) {
      this.onToggleComponentUpdate = true;
      this.addEventsListeners();
    } else {
      this.removeEventsListeners();
    }
    this.setState({
      menuOpen: e.isOpen
    });
  };

  onToggleKeyPress = (e: React.KeyboardEvent<HTMLButtonElement>) => {
    if (e.key === 'Enter') {
      this.handleMenuOpenClose(e);
    }
  };

  onButtonClick = (e: React.SyntheticEvent<HTMLButtonElement>) => {
    this.handleMenuOpenClose(e);
  };

  onTableScroll = () => {
    const { menuOpen } = this.state;
    const { tableBodyRef } = this.props;
    if (menuOpen) {
      const tableRect = tableBodyRef.current.getBoundingClientRect();
      const toggleRect = this.toggleRef.current.getBoundingClientRect();
      const toggleBottom = toggleRect.bottom - tableRect.top;
      const toggleTop = toggleRect.top - tableRect.top;
      const menuHeight = this.menuRef.current.getBoundingClientRect().height;

      this.toggleEdgeToMenuEdge = (menuHeight - toggleRect.height) / 2;
      this.tableTop = tableRect.top;
      this.setMenuState(this.toggleEdgeToMenuEdge, toggleTop, toggleBottom, tableRect.height);
    }
  };

  setMenuState = (
    toggleEdgeToMenuEdge: number,
    toggleTop: number,
    toggleBottom: number,
    tableHeight: number
  ) => {
    if (toggleTop < toggleEdgeToMenuEdge) {
      // menu top going out of view
      this.setState({
        menuPosition: MenuLocation.top
      });
    } else if (tableHeight - toggleBottom < toggleEdgeToMenuEdge) {
      // menu bottom going out of view
      this.setState({
        menuPosition: MenuLocation.bottom
      });
    } else {
      // menu is in view
      this.setState({
        menuPosition: MenuLocation.middle
      });
    }
  };

  getMenuPositionStyles = (position: MenuLocation) => {
    if (position === MenuLocation.top) {
      return {
        style: {
          top: this.tableTop + 1
        },
        class: 'popup-menu-fixed'
      };
    }
    if (position === MenuLocation.bottom) {
      return {
        style: {
          bottom: 0
        },
        class: 'popup-menu-fixed'
      };
    }

    return {
      style: {
        top: -1 * this.toggleEdgeToMenuEdge
      },
      class: 'popup-menu-absolute'
    };
  };

  getMenuItemCallback = (command: string) => {
    const { menuItems } = this.props;
    for (let i = 0; i < menuItems.length; i += 1) {
      if (menuItems[i].menuItemKey === command) {
        return menuItems[i].menuItemCallback;
      }
    }
    return undefined;
  };

  findMenuItem = (menuItemKey: string): PopupMenuItem | undefined => {
    const { menuItems } = this.props;
    return menuItems.find(item => item.menuItemKey === menuItemKey);
  };

  getMenuItemTitle = (command: string): string => {
    const { linkedItemEntityName } = this.props;
    const menuItem = this.findMenuItem(command);
    if (menuItem && menuItem.menuItemTitle) {
      return String(menuItem.menuItemTitle);
    }
    return `${this.menuOptions[command].text} ${linkedItemEntityName}`;
  };

  handleKeyDown = (e: any) => {
    if (this.ignoredKeys.includes(e.key)) {
      e.preventDefault();
    }
  };

  addEventsListeners = () => {
    const { tableBodyRef } = this.props;
    Object.keys(this.eventListeners).forEach(key => {
      tableBodyRef.current.addEventListener(key, this.eventListeners[key]);
    });
  };

  removeEventsListeners = () => {
    const { tableBodyRef } = this.props;
    Object.keys(this.eventListeners).forEach(key => {
      tableBodyRef.current.removeEventListener(key, this.eventListeners[key]);
    });
  };

  handleMenuOpenClose = (e: React.SyntheticEvent<HTMLButtonElement>) => {
    const { linkedItem } = this.props;
    const { menuOpen } = this.state;
    const id = linkedItem.get('id');
    const menuWrapperId = `popup-menu-wrapper-${id}`;
    if (menuOpen) {
      closeMenu(menuWrapperId);
    } else {
      openMenu(menuWrapperId);
    }
    e.stopPropagation();
    e.preventDefault();
  };

  validateNoDuplicates = (menuItems: Array<PopupMenuItem>) => {
    // remove separators first because they are allowed to be duplicate
    const filteredList = menuItems.filter(
      menuItem => menuItem.menuItemKey !== popupMenuCommands.separator
    );

    const commands: { [key: string]: number } = {};

    // set array of zeros per unique command
    filteredList.forEach(item => {
      if (commands[item.menuItemKey] === undefined) commands[item.menuItemKey] = 0;
    });

    // count all commands instances
    filteredList.forEach(item => {
      commands[item.menuItemKey] += 1;
    });

    // if any command count is greater than 1 it means duplicates ! throw exception
    filteredList.forEach(item => {
      if (commands[item.menuItemKey] > 1) {
        throw new Error('PopupMenu Error: Can not create a menu with duplicate commands');
      }
    });
  };

  confirmDelete = () => {
    this.setState({
      confirming: true
    });
  };

  // eslint-disable-next-line react/no-unused-class-component-methods
  deleteFile = () => {
    const { linkedItem } = this.props;
    const callback = this.getMenuItemCallback(popupMenuCommands.delete);
    if (callback !== null && callback !== undefined) {
      callback([linkedItem.get('id')]);
      this.setState({
        confirming: false
      });
    }
  };

  // eslint-disable-next-line react/no-unused-class-component-methods
  deleteCancelled = () => {
    this.setState({
      confirming: false
    });
  };

  confirmArchive = () => {
    this.setState({
      confirming: true
    });
  };

  archiveEntity = () => {
    try {
      const { linkedItem } = this.props;
      const callback = this.getMenuItemCallback(popupMenuCommands.archive);
      if (callback !== null && callback !== undefined) {
        callback([linkedItem.get('id')]);
        this.setState({
          confirming: false
        });
      }
    } catch (e) {
      const errorMessage = e instanceof Error ? e.message : 'Unknown Error';
      notification.error(errorMessage);
    }
  };

  archiveCancelled = () => {
    this.setState({
      confirming: false
    });
  };

  restoreEntity = () => {
    try {
      const { linkedItem } = this.props;
      const callback = this.getMenuItemCallback(popupMenuCommands.restore);
      if (callback !== null && callback !== undefined) {
        callback([linkedItem.get('id')]);
        this.setState({
          confirming: false
        });
      }
    } catch (e: unknown) {
      const errorMessage = e instanceof Error ? e.message : 'Unknown Error';
      notification.error(errorMessage);
    }
  };

  beginEdit = () => {
    this.setState({
      editing: true
    });
  };

  editFile = async (formValues: EditModalFormValues) => {
    try {
      const { linkedItem } = this.props;
      const name = linkedItem.get('name');
      const callback = this.getMenuItemCallback(popupMenuCommands.rename);

      if (callback !== null && callback !== undefined) {
        // Only do the change request if something actually changed
        if (name !== formValues.name) {
          await callback(linkedItem.get('id'), formValues.name);
        }
      }
      this.setState({
        editing: false
      });
    } catch (e) {
      const errorMessage = e instanceof Error ? e.message : 'Unknown Error';
      notification.error(errorMessage);
    }
  };

  editCancelled = () => {
    this.setState({
      editing: false
    });
  };

  download = () => {
    const { linkedItem } = this.props;
    const callback = this.getMenuItemCallback(popupMenuCommands.download);
    if (callback !== null && callback !== undefined) {
      callback(linkedItem.get('id'), linkedItem.get('name'));
    }
  };

  exportPcrd = async () => {
    const { linkedItem } = this.props;
    const callback = this.getMenuItemCallback(popupMenuCommands.export);
    if (callback !== null && callback !== undefined) {
      await callback(linkedItem.get('id'), linkedItem.get('name'));
    }
  };

  passItemToCommands = async (command: string) => {
    const { linkedItem } = this.props;
    const callback = this.getMenuItemCallback(command);
    if (callback !== null && callback !== undefined) {
      await callback(linkedItem.toJS());
    }
  };

  moveFile = async () => {
    await this.passItemToCommands(popupMenuCommands.move);
  };

  fileHistory = async () => {
    await this.passItemToCommands(popupMenuCommands.fileHistory);
  };

  handleMenuSelection = (element: any, event: React.SyntheticEvent<HTMLButtonElement>) => {
    const menuItem = this.findMenuItem(element.props.itemKey);
    if (menuItem?.menuItemDisabled) {
      event.stopPropagation();
      return;
    }
    this.menuOptions[element.props.itemKey].callback(); // call relevant callback according to cmd key (rename, download, delete etc...)
    event.stopPropagation();
  };

  renderPopupMenuItem = (
    menuItemKey: string,
    menuItemText: string | undefined,
    id: string,
    textClassname: string,
    imgClassName: string,
    menuItemDisabled?: boolean
  ) => {
    const { linkedItem } = this.props;
    const text = menuItemText ?? this.menuOptions[menuItemKey].text;
    if (menuItemDisabled)
      return (
        <button
          className={`popup-menu-item ${imgClassName} ${textClassname} popup-menu-item-disabled`}
          onClick={e => {
            e.stopPropagation();
          }}
          disabled
        >
          <PopupMenuItem text={text} itemKey={menuItemKey} />
        </button>
      );

    return (
      <MenuItem className={`popup-menu-item ${imgClassName} ${textClassname}`}>
        <PopupMenuItem
          text={text}
          itemKey={menuItemKey}
          // @ts-ignore
          onClick={this.menuOptions[menuItemKey].callback}
          item={linkedItem}
          id={`${this.menuOptions[menuItemKey].menuItemId}_cmd-${id}`}
        />
      </MenuItem>
    );
  };

  renderListItems = () => {
    const { linkedItem, menuItems } = this.props;
    const linkedItemId = linkedItem.get('id');
    const items: any[] = [];
    let sepIndex = 0; // index for multiple separator IDs

    menuItems.forEach(menuItem => {
      if (menuItem.menuItemKey === popupMenuCommands.separator) {
        items.push(
          <li key={`sep${sepIndex}`}>
            <div className="popup-menu-sep" />
          </li>
        );
        sepIndex += 1;
      } else {
        items.push(
          <li
            key={menuItem.menuItemKey}
            id={`${this.menuOptions[menuItem.menuItemKey].menuItemId}_${linkedItemId}`}
            title=""
          >
            {this.renderPopupMenuItem(
              menuItem.menuItemKey,
              menuItem.menuItemText,
              linkedItemId,
              this.menuOptions[menuItem.menuItemKey].textClass,
              this.menuOptions[menuItem.menuItemKey].imgClass,
              menuItem.menuItemDisabled
            )}
          </li>
        );
      }
    });
    return items;
  };

  render() {
    const { linkedItem } = this.props;
    const { menuPosition, menuOpen, confirming, editing } = this.state;
    const name = linkedItem.get('name');
    const linkedItemId = linkedItem.get('id');
    const menuPositionStyles = this.getMenuPositionStyles(menuPosition);

    return (
      <Wrapper
        id={`popup-menu-wrapper-${linkedItemId}`}
        onSelection={this.handleMenuSelection}
        onMenuToggle={this.onMenuToggle}
        className="popup-menu-wrapper"
      >
        <div ref={this.toggleRef}>
          <Button
            className={classnames(
              { visible: menuOpen },
              menuOpen ? 'popup-menu-button-with-outline popup-menu-button' : 'popup-menu-button'
            )}
            id={`options_${linkedItemId}`}
            // @ts-ignore
            onClick={this.onButtonClick}
            onKeyDownCapture={this.onToggleKeyPress}
          >
            <svg
              type="image"
              // @ts-ignore
              alt="options"
              className="popup-menu-button-img"
              ref={this.toggleRef}
              visibility={menuOpen ? 'visible' : 'hidden'}
            />
          </Button>
        </div>
        <Menu id={`menu-${name}`}>
          <ul
            role="menu"
            className={menuPositionStyles.class}
            style={menuPositionStyles.style}
            id={`popup-${linkedItemId}`}
            ref={this.menuRef}
          >
            {this.renderListItems()}
          </ul>
        </Menu>
        <ModalDialog
          show={confirming}
          title={this.getMenuItemTitle(popupMenuCommands.archive)}
          message="Are you sure you want to archive this item?"
          okBtnText="Archive"
          onCancel={this.archiveCancelled}
          onSuccess={this.archiveEntity}
          customModalStyle={okCancelStyleV2}
        />
        <EditFileModal
          show={editing}
          // @ts-ignore
          title={this.getMenuItemTitle(popupMenuCommands.rename)}
          onSuccess={this.editFile}
          onCancel={this.editCancelled}
          name={name}
          initialValues={{ name }}
          customModalStyle={editFileStyleV2}
        />
      </Wrapper>
    );
  }
}
