import { Map, List, fromJS } from 'immutable';
import { sprintf } from 'sprintf-js';

type StepType = 'temp' | 'gradient' | 'melt' | 'goto';

export const TEMP: StepType = 'temp';
export const GRADIENT: StepType = 'gradient';
export const MELT: StepType = 'melt';
export const GOTO: StepType = 'goto';

export function secondsToTimeString(secs?: number) {
  if (secs === undefined) {
    return '';
  }
  const secNum = parseInt(secs.toString(), 10);
  if (secNum === 0) {
    return 'Forever';
  }
  let min = Math.floor(secNum / 60);
  const sec = secNum % 60;
  if (min < 59) {
    return sprintf('%d:%02d', min, sec);
  }
  min %= 60;
  const hr = Math.floor(secNum / 3600);
  return sprintf('%d:%02d:%02d', hr, min, sec);
}

export function getDefaultProtocol(): any {
  return fromJS({
    vol: 20,
    lidTemp: { mode: 'default' },
    steps: [
      {
        type: TEMP,
        temp: 95,
        time: 180
      },
      {
        type: TEMP,
        temp: 95,
        time: 10
      },
      {
        type: TEMP,
        temp: 55,
        time: 30,
        read: true
      },
      {
        type: GOTO,
        step: 1,
        cycles: 39
      }
    ]
  });
}

export function defaultNewStep(type: string): Map<any, any> {
  switch (type) {
    case TEMP:
      return Map({
        type: TEMP,
        temp: 50,
        time: 30
      });
    case GOTO:
      return Map({
        type: GOTO,
        step: 0,
        cycles: 39
      });
    case GRADIENT:
      return Map({
        type: GRADIENT,
        temp: 65,
        time: 60,
        grd: 10
      });
    case MELT:
      return Map({
        type: MELT,
        temp: 65,
        end: 95,
        interval: 5,
        inc: 0.5
      });
    default:
      return Map({ type });
  }
}

export type StepItem = {
  type: StepType;
  name: string;
  disable?: string;
};

export function stepsForIndex(protocol: List<any>, index: number, isPcr?: boolean): StepItem[] {
  const gotoStep: StepItem = {
    type: GOTO,
    name: 'GoTo'
  };

  const meltStep: StepItem = {
    type: MELT,
    name: 'Melt'
  };

  const stepList: Array<StepItem> = [
    {
      type: TEMP,
      name: 'Temperature'
    },
    {
      type: GRADIENT,
      name: 'Gradient'
    },
    meltStep,
    gotoStep
  ];
  if (index === 0) {
    gotoStep.disable = 'GOTO cannot be the first step.';
  } else if (protocol.getIn([index - 1, 'type']) === GOTO) {
    gotoStep.disable = 'GOTO cannot be immediately after a GOTO step.';
  } else if (protocol.getIn([index - 1, 'type']) === MELT) {
    gotoStep.disable = 'GOTO cannot be immediately after a MELT step.';
  }

  for (let i = index + 1; i < protocol.size; i += 1) {
    if (
      protocol.getIn([i, 'type']) === GOTO &&
      typeof protocol.getIn([i, 'step']) !== 'undefined' &&
      // @ts-ignore
      protocol.getIn([i, 'step']) <= index
    ) {
      gotoStep.disable = `GOTO cannot be inside loop from STEP ${i + 1} to STEP ${
        // @ts-ignore
        protocol.getIn([i, 'step']) + 1
      }.`;
      meltStep.disable = `MELT cannot be inside loop from STEP ${i + 1} to STEP ${
        // @ts-ignore
        protocol.getIn([i, 'step']) + 1
      }.`;
      break;
    }
  }
  if (isPcr) stepList.splice(stepList.indexOf(meltStep), 1);
  return stepList;
}

export function minGotoForIndex(protocol: List<any>, index: number) {
  for (let i = index - 1; i >= 0; i -= 1) {
    if (protocol.getIn([i, 'type']) === GOTO || protocol.getIn([i, 'type']) === MELT) {
      return i + 1;
    }
  }
  return 0;
}

function hasRead(step: any) {
  return step.get('read') || step.get('type') === MELT;
}

export function plateReads(protocol: List<any> = List()) {
  let count = 0;
  protocol.forEach(step => {
    if (hasRead(step)) {
      count += 1;
    }
  });
  return count;
}

export function changeInReads(prev: Map<any, any>, next?: Map<any, any>) {
  const before = hasRead(prev) ? 1 : 0;
  const after = next && hasRead(next) ? 1 : 0;
  return after - before;
}
