import UserAnalyticsClient from '../../frontend-common-libs/src/user-analytics/UserAnalyticsClient';

export default class EmailVerificationErrorOccurredEvent {
  private static readonly EMAIL_VERIFICATION_ERROR_EVENT_NAME = 'email-verification-error';

  private errorMessage: string;

  constructor(errorMessage: string) {
    this.errorMessage = errorMessage;
  }

  public track() {
    UserAnalyticsClient.getInstance().track(
      EmailVerificationErrorOccurredEvent.EMAIL_VERIFICATION_ERROR_EVENT_NAME,
      { errorMessage: this.errorMessage }
    );
  }
}
