import React, { PureComponent } from 'react';
import { Wrapper, Button, Menu } from 'react-aria-menubutton';
import './styles/buttonMenu.scss';

export type Props = {
  children: any;
  menuId: string;
  btnImage: string;
  notification?: any;
};

export default class ButtonMenu extends PureComponent<Props> {
  handleMenuSelection = (
    onSelection: (...args: Array<any>) => any,
    event: React.SyntheticEvent<HTMLButtonElement>
  ) => {
    event.stopPropagation();
    onSelection();
  };

  render() {
    const { menuId, btnImage, children, notification } = this.props;
    return (
      <Wrapper id={menuId} onSelection={this.handleMenuSelection} className="buttonmenu">
        <Button id="buttonmenu-button">
          <img alt="options" src={btnImage} />
          {notification}
        </Button>
        <Menu id="buttonmenu-menu">{children}</Menu>
      </Wrapper>
    );
  }
}
