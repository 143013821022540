import axios, { AxiosError } from 'axios';
import { changePassword, getUserSession } from '../frontend-common-libs/src/utils/authUtils';
import notification from '../frontend-common-libs/src/utils/notifications';
import IncorrectPasswordError from './IncorrectPasswordError';

export type ChangePwdInfo = {
  oldPwd: string;
  newPwd: string;
};

export default async function changePwd({ oldPwd, newPwd }: ChangePwdInfo): Promise<void> {
  const { email, idToken, accessToken } = await getUserSession();
  try {
    await changePassword(email, oldPwd, newPwd, accessToken, idToken);
  } catch (err) {
    if (axios.isAxiosError(err)) {
      const axiosError = err as unknown as AxiosError;
      if (axiosError.response && axiosError.response.status === 403) {
        throw new IncorrectPasswordError();
      }
    }
    throw new Error('Unknown Error while changing password');
  }

  notification.success('Password change successful!');
}
