import { useEffect, useState } from 'react';

export type UseCheckboxesReturns = {
  selectedInstruments: string[];
  selectAllCheckbox: boolean;
  clearSelectedInstruments: boolean;
  updateSelectedInstruments: (id: string, select: boolean) => void;
  handleSelectAll: () => void;
  clearSelection: () => void;
};

export default function useCheckboxes(): UseCheckboxesReturns {
  const [selectedInstruments, setSelectedInstruments] = useState<Array<string>>([]);
  const [selectAllCheckbox, setSelectAllCheckbox] = useState<boolean>(false);
  const [clearSelectedInstruments, setClearSelectedInstruments] = useState<boolean>(false);

  const updateSelectedInstruments = (id: string, select: boolean): void => {
    const isInstrumentSelected = selectedInstruments.includes(id);
    setSelectedInstruments(previousSelection => {
      if (select && !isInstrumentSelected) {
        return [...previousSelection, id];
      }
      if (!select) {
        return previousSelection.filter(instrumentId => instrumentId !== id);
      }
      return previousSelection;
    });
  };

  const handleSelectAll = () => {
    setSelectAllCheckbox(!selectAllCheckbox);
  };

  const clearSelection = () => {
    setClearSelectedInstruments(true);

    if (selectAllCheckbox) {
      setSelectAllCheckbox(false);
    }
  };

  useEffect(() => {
    if (clearSelectedInstruments && selectedInstruments.length === 0) {
      setClearSelectedInstruments(false);
    }
  }, [selectedInstruments, clearSelectedInstruments]);

  return {
    selectedInstruments,
    selectAllCheckbox,
    clearSelectedInstruments,
    updateSelectedInstruments,
    handleSelectAll,
    clearSelection
  };
}
