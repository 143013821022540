import axios from 'axios';
import { GATEWAY } from '../../frontend-common-libs/src/config/config';
import requests from '../../frontend-common-libs/src/utils/httpUtils';

interface QueryParams {
  step?: number;
}

export default class GetPCRDataRequest {
  constructor(private entityId: string, private step?: number) {
    this.domain = GATEWAY.QPCRDATA_SERVICE_PATHS.BASE_URL;
    this.path = GATEWAY.QPCRDATA_SERVICE_PATHS.GETPCRDATA_URL(this.entityId);
  }

  private readonly domain: string;

  private readonly path: string;

  private get queryParams(): QueryParams {
    let queryParams: QueryParams = {};
    if (this.step) {
      queryParams = { ...queryParams, step: this.step };
    }
    return queryParams;
  }

  public send(): Promise<any> {
    return this.requestPCRDataFromS3SavedResponse();
  }

  private requestPCRData(queryParams: QueryParams): Promise<any> {
    return requests.get(this.domain, {}, this.path, { queryParams });
  }

  private async requestPCRDataFromS3SavedResponse(): Promise<any> {
    const presignedUrl = await this.getPresignedUrlFromS3SavedResponse();
    return axios.get(presignedUrl);
  }

  private async getPresignedUrlFromS3SavedResponse(): Promise<string> {
    const { data: presignedUrl } = await this.requestPCRData(this.queryParams);
    return presignedUrl;
  }
}
