import requests from '../../../frontend-common-libs/src/utils/httpUtils';
import { GATEWAY } from '../../../frontend-common-libs/src/config/config';
import { PcrProtocolContent } from '../../../frontend-common-libs/src/components/pcr/pcr_protocol_types';
import {
  ProjectId,
  DEFAULT_PROJECT_ID
} from '../../../frontend-common-libs/src/common/project-management-types';
import { getParamsWithAccessTokenHeader } from '../../../organization-management/api/access-control';

const domain = GATEWAY.CPCR_SERVICE_PATHS.BASE_URL;

export async function creatCpcrProtocol(
  name: string,
  protocol: PcrProtocolContent,
  projectId: ProjectId = DEFAULT_PROJECT_ID,
  projectAccessToken?: string
): Promise<any> {
  const path = GATEWAY.CPCR_SERVICE_PATHS.CREATE_PROTOCOL_URL;
  const body = { ...protocol, name, parentId: projectId };
  const params = getParamsWithAccessTokenHeader(projectAccessToken);
  const res = await requests.post(domain, {}, path, params, body);
  return res.data;
}

export async function creatCpcrProtocolFromFile(
  name: string,
  file: Record<string, any>,
  projectId: ProjectId = DEFAULT_PROJECT_ID,
  projectAccessToken: string | undefined = undefined
): Promise<any> {
  const path = GATEWAY.CPCR_SERVICE_PATHS.CREATE_PROTOCOL_FROM_FILE_URL;
  const body = { file, name, parentId: projectId };
  const params = getParamsWithAccessTokenHeader(projectAccessToken);
  const res = await requests.post(domain, {}, path, params, body);
  return res.data;
}

export async function getCpcrProtocol(entityId: string, rundef = false): Promise<any> {
  const path = GATEWAY.CPCR_SERVICE_PATHS.GET_PROTOCOL_URL(entityId);
  let additionalParams = {};
  if (rundef) additionalParams = { queryParams: { f: 'rundef' } };
  const res = await requests.get(domain, {}, path, additionalParams);
  return res.data;
}

export async function editCpcrProtocol(
  entityId: string,
  name: string,
  protocol: PcrProtocolContent,
  versionNumber: number,
  projectAccessToken?: string
): Promise<any> {
  const path = GATEWAY.CPCR_SERVICE_PATHS.GET_PROTOCOL_URL(entityId);
  const body = { ...protocol, name, versionNumber };
  const params = getParamsWithAccessTokenHeader(projectAccessToken);
  const res = await requests.put(domain, {}, path, params, body);
  return res.data;
}
