import { Component } from 'react';
import './styles/organization-page.scss';
import { connect } from 'react-redux';
import { getLoginTokenIfNeeded as getLoginTokenIfNeededAction } from '../actions/login';
import { ReduxState } from '../../types';
import { getUserEmail } from '../../selectors/auth-selectors';

export type Props = {
  email?: string;
  loginIfNeeded?: typeof getLoginTokenIfNeededAction;
};

export class LoginTokenImpl extends Component<Props> {
  componentDidMount() {
    this.loginIfNeeded();
  }

  componentDidUpdate() {
    this.loginIfNeeded();
  }

  loginIfNeeded() {
    const { email, loginIfNeeded } = this.props;
    if (email && loginIfNeeded) loginIfNeeded();
  }

  render() {
    return null;
  }
}
export function mapStateToProps(state: ReduxState): {
  [key: string]: any;
} {
  return { email: getUserEmail(state) };
}

export default connect(mapStateToProps, { loginIfNeeded: getLoginTokenIfNeededAction })(
  // @ts-ignore
  LoginTokenImpl
);
