import React from 'react';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './style/date-picker.scss';

export type Props = {
  onDateChange: (date: Date) => void;
  id: string;
  minDate?: Date;
  maxDate?: Date;
};

type DatePickerState = {
  selectedDate: Date;
};

/**
 * A component that renders a date picker with a custom input.
 * @param {Props} props - The props that component receives.
 * @returns {JSX.Element} - A JSX element that renders the date picker.
 */
export default class DatePicker extends React.Component<Props, DatePickerState> {
  static defaultProps = {
    minDate: new Date(),
    maxDate: new Date(new Date().getFullYear() + 5, new Date().getMonth(), new Date().getDate())
  };

  public constructor(props: Props) {
    super(props);
    this.state = {
      selectedDate: props.minDate as Date
    };
  }

  componentDidMount(): void {
    const { onDateChange } = this.props;
    const { selectedDate } = this.state;
    onDateChange(selectedDate);
  }

  private handleDateChange = (date: Date) => {
    const { minDate: minDateProp } = this.props;
    const minDate = minDateProp || date;
    const dateToSet = date || minDate;
    const { onDateChange } = this.props;
    this.setState({ selectedDate: dateToSet });
    onDateChange(dateToSet);
  };

  render() {
    const { selectedDate } = this.state;
    const { minDate, maxDate, id } = this.props;
    return (
      <ReactDatePicker
        id={id}
        selected={selectedDate}
        minDate={minDate}
        maxDate={maxDate}
        dayClassName={datePickerDate =>
          datePickerDate.toLocaleDateString() === selectedDate.toLocaleDateString()
            ? 'datePickerSelectedDay'
            : ''
        }
        onChange={(newDate: Date) => this.handleDateChange(newDate)}
        dateFormat="MM/dd/yyyy"
        className="datePickerCustomInput"
      />
    );
  }
}
