import React, { Component } from 'react';
import './styles/org-people.scss';
import { connect } from 'react-redux';
import {
  MaxOrgUsers,
  OrgPermissions,
  OrgRoles,
  orgRolesDisplayNames
} from '@biorad-lsg-tsc/organization';
import { toWords } from 'number-to-words';
import { emailValid } from '../../frontend-common-libs/src/common/form_validators';
import { ReduxState } from '../../types';
import { getOrgDetails, getOrgUserPermissions } from '../selectors/selectors';
import { setOrganizationRole as setOrganizationRoleAction } from '../actions/set-org-role';
import MemberRow from './MemberRow';
import { addUserToOrg as addUserToOrgAction } from '../actions/add-user-to-org';
import {
  isAddUserMaxOrgUsersError,
  isAddUserNotInEmailDomainError,
  isUserAlreadyInOrganizationError,
  isUserMustLoginError
} from '../api/api-errors';
import AddUserToTenant from './AddUserToTenant';
import { removeUserFromOrg as removeUserFromOrgAction } from '../actions/remove-user-from-org';

export type Props = {
  orgId: string;
  details?: any;
  setOrganizationRole?: (orgId: string, userName: string, role: string) => any;
  hasEditPermissions?: boolean;
  addUserToOrg: (orgId: string, userName: string, userRole: string) => any;
  removeUserFromOrg?: (orgId: string, userName: string) => any;
};

export type State = {
  emailError: string | undefined;
};

export class OrgPeopleImpl extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = this.initialState;
  }

  private readonly initialState = {
    emailError: undefined
  };

  invitedUser = async (invitedUserEmail: string, invitedUserRole: OrgRoles) => {
    const { orgId, addUserToOrg } = this.props;
    if (!invitedUserEmail) {
      this.setState({ emailError: 'Email address cannot be empty.' });
      return false;
    }
    const emailError = emailValid(invitedUserEmail);
    this.setState({ emailError });
    if (emailError != null) return false;
    try {
      await addUserToOrg(orgId, invitedUserEmail, invitedUserRole);
      return true;
    } catch (e) {
      let error = 'Error adding user.';
      if (isAddUserNotInEmailDomainError(e)) {
        error = `The email domain must be the same for all users added to this organization. Enter a valid email address.`;
      } else if (isAddUserMaxOrgUsersError(e)) {
        error = `Limit exceeded. You can add up to ${toWords(MaxOrgUsers)} users.`;
      } else if (isUserAlreadyInOrganizationError(e)) {
        error = 'This user has already been added to the organization. Enter a valid user.';
      } else if (isUserMustLoginError(e)) {
        error = 'This user must login to brio before being added to an organization.';
      }
      this.setState({ emailError: error });
      return false;
    }
  };

  onChangeMemberRole = async (userName: string, role: string) => {
    const { setOrganizationRole, orgId } = this.props;
    if (!setOrganizationRole) return;
    await setOrganizationRole(orgId, userName, role);
  };

  onRemoveUser = async (userName: string) => {
    const { removeUserFromOrg, orgId } = this.props;
    if (!removeUserFromOrg) return;
    await removeUserFromOrg(orgId, userName);
  };

  renderMembers() {
    const { details, hasEditPermissions } = this.props;
    const users = details?.users;
    const orgName = details?.name;
    return (
      <div className="members-table">
        <div className="members-header">
          <span className="name-header">Name</span>
          <span className="role-header">Role</span>
        </div>

        <div className="member-rows">
          {users?.map((user: any, index: number) => (
            <MemberRow
              name={user.userName}
              role={user.userRole}
              index={index}
              key={user.userName}
              onChangeRole={this.onChangeMemberRole}
              disabled={!hasEditPermissions}
              showRemoveUser={hasEditPermissions}
              onRemoveUser={this.onRemoveUser}
              removeUserMessage={`Are you sure you want to remove ${user.userName} from organization ${orgName}?`}
            />
          ))}
        </div>
      </div>
    );
  }

  renderInviteUser() {
    const { emailError } = this.state;
    const { hasEditPermissions } = this.props;
    return (
      <AddUserToTenant
        onAddUser={this.invitedUser}
        initialInvitedUserRole={OrgRoles.Member}
        addButtonEnabled={hasEditPermissions}
        emailError={emailError}
        roleEnum={OrgRoles}
        displayNames={orgRolesDisplayNames}
      />
    );
  }

  render() {
    return (
      <div className="org-people">
        {this.renderInviteUser()}
        {this.renderMembers()}
      </div>
    );
  }
}

export function mapStateToProps(
  state: ReduxState,
  ownProps: Props
): {
  [key: string]: any;
} {
  const { orgId } = ownProps;
  const details = getOrgDetails(state, orgId)?.toJS();
  const orgUserPermissions = getOrgUserPermissions(state, orgId);
  const hasEditPermissions = orgUserPermissions?.hasPermissions([OrgPermissions.EditOrg]);
  return {
    orgId,
    details,
    hasEditPermissions
  };
}

export default connect(mapStateToProps, {
  setOrganizationRole: setOrganizationRoleAction,
  addUserToOrg: addUserToOrgAction,
  removeUserFromOrg: removeUserFromOrgAction
})(OrgPeopleImpl);
