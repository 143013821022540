import { List, Map } from 'immutable';
import rootReducer from './reducers';

export type ApiAction<A, T> = {
  type: A;
  payload: T;
};

export type DialogState = {
  isOpenTermsOfService: boolean;
};

export type UserFilesState = {
  isLoading: boolean;
  files: Array<File>;
  errorMessage: string;
  lastSeen: string;
};

export type ReduxState = ReturnType<typeof rootReducer>;
export type State = ReduxState; // Deprecated type. Use ReduxState

export type RunLogEntry = {
  ts: string;
  msg: string;
  sev: string;
  lgNm: string;
  level: string;
};

export type RunLog = {
  showingLog: boolean;
  logsEntries?: List<RunLogEntry> | null;
  loadingLog: boolean;
};

/* eslint-disable no-use-before-define */
export type Dispatch<A, T> = (
  action: ApiAction<A, T> | ThunkAction<A, T> | PromiseAction<A, T> | Array<ApiAction<A, T>>
) => any;
export type GetState = () => ReduxState;
export type ThunkAction<A, T> = (dispatch: Dispatch<A, T>, getState: GetState) => any;
export type PromiseAction<A, T> = Promise<ApiAction<A, T>>;

/* eslint-enable no-use-before-define */

export enum AnalysisModeEnum {
  fluor = 'fluor',
  target = 'target'
}

export type StepAnalysisCompute = {
  entityId: string;
  step: number;
  groupMode: string;
  plate: Map<string, any>;
  excludedWells: List<string>;
  perStepAnalysisSettings: Map<string, any>;
  isAmpStepSelected: boolean;
  driftCorrection: boolean;
};
