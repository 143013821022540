import React from 'react';
import { CommonInstrumentRow } from '../../frontend-common-libs/instrument-row';
import InstrumentFacade, {
  BlockEnum,
  BlockIdentifierEnum,
  InstrumentModelNameEnum,
  isRunStatusDisplayed
} from '../common/InstrumentFacade';
import ConventionalPCRInstrumentTypeIcon from './ConventionalPCRInstrumentTypeIcon';
import { ImmutableMap, InstrumentItem } from '../../frontend-common-libs/src/common/types';
import { detailsUnavailable } from './InstrumentRow';
import InstrumentDetails from './InstrumentDetails';

export type Props = {
  block: BlockEnum;
  instrument: ImmutableMap<InstrumentItem>;
  expandedRowTopPosition: string;
};

export enum BlockLabelEnum {
  BlockA = '(Block A)' as any,
  BlockB = '(Block B)' as any
}

export default function InstrumentBlockRow({ block, instrument, expandedRowTopPosition }: Props) {
  function getRowIdentifier(): string {
    let blockId;
    switch (block) {
      case BlockEnum.BlockA:
        blockId = BlockIdentifierEnum.BlockA;
        break;
      case BlockEnum.BlockB:
        blockId = BlockIdentifierEnum.BlockB;
        break;
      default:
        blockId = '';
        break;
    }
    return `${instrument.get('id')}${blockId}`;
  }

  const blockRowIdentifier = getRowIdentifier();
  const instrumentName = instrument.get('name');
  const instrumentFacade = new InstrumentFacade(instrument, block);
  const {
    modelName,
    statusIcon,
    instrumentStatus,
    timeRemaining,
    details,
    instrumentModel,
    runCurrentCycle,
    runTotalCycles,
    runCurrentStep,
    runTotalSteps,
    runId,
    runUserId
  } = instrumentFacade;
  const softwareVersion = details.softwareVersion ?? detailsUnavailable;
  const displayBlockLabel = BlockLabelEnum[BlockEnum[block] as any];
  const instrumentIcon = (
    <ConventionalPCRInstrumentTypeIcon
      type={instrument.get('type')}
      modelName={modelName as InstrumentModelNameEnum}
      block={block}
    />
  );

  return (
    <CommonInstrumentRow
      id={blockRowIdentifier}
      displayModel={modelName}
      expandedRowTopPosition={expandedRowTopPosition}
      instrumentName={instrumentName}
      instrumentNameLine2={displayBlockLabel}
      instrumentStatus={instrumentStatus}
      timeRemaining={timeRemaining}
      statusIcon={statusIcon}
      instrumentIcon={instrumentIcon}
    >
      <InstrumentDetails
        serialNumber={details.serialNumber}
        instrumentId={blockRowIdentifier}
        displayModel={modelName || detailsUnavailable}
        instrumentName={instrumentName}
        instrumentNameLine2={displayBlockLabel}
        softwareVersion={softwareVersion}
        mainFirmwareVersion={details.mainFirmwareVersion || detailsUnavailable}
        powerFirmwareVersion={details.powerFirmwareVersion || detailsUnavailable}
        lidFirmwareVersion={details.lidFirmwareVersion || detailsUnavailable}
        apiVersion={details.apiVersion || detailsUnavailable}
        imageVersion={details.imageVersion || detailsUnavailable}
        instrumentModel={instrumentModel || detailsUnavailable}
        runCurrentCycle={runCurrentCycle}
        runTotalCycles={runTotalCycles}
        runCurrentStep={runCurrentStep}
        runTotalSteps={runTotalSteps}
        isRunStatusDisplayed={isRunStatusDisplayed(instrumentStatus, softwareVersion)}
        runId={runId}
        runUserId={runUserId}
      />
    </CommonInstrumentRow>
  );
}
