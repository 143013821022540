import React from 'react';
import classnames from 'classnames';

type Props = {
  header: string;
  id: string;
  className: string;
  onClick: (...args: Array<any>) => any;
  children: any;
};

function TableHeader(props: Props) {
  const { header, id, className, onClick, children } = props;
  /* eslint-disable react/no-unknown-property */
  return (
    <th key={id} className={classnames(`${header}-column`)}>
      <button
        id={id}
        type="button"
        sort-key={header}
        className={`${className} selectable`}
        onClick={onClick}
      >
        {children}
      </button>
    </th>
  );
}

export default TableHeader;
