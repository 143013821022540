export type AuthActionType =
  | 'auth_user'
  | 'unauth_user'
  | 'auth_error'
  | 'user_session_not_found'
  | 'user_session_found'
  | 'auth_in_progress'
  | 'forget_pwd_error'
  | 'forget_pwd_confirm'
  | 'load_profile'
  | 'submit_profile'
  | 'user_not_verified_error';

export const AUTH_USER: AuthActionType = 'auth_user';
export const UNAUTH_USER: AuthActionType = 'unauth_user';
export const AUTH_ERROR: AuthActionType = 'auth_error';
export const USER_SESSION_NOT_FOUND: AuthActionType = 'user_session_not_found';
export const USER_SESSION_FOUND: AuthActionType = 'user_session_found';
export const AUTH_IN_PROGRESS: AuthActionType = 'auth_in_progress';
export const FORGET_PWD_ERROR: AuthActionType = 'forget_pwd_error';
export const FORGET_PWD_CONFIRM: AuthActionType = 'forget_pwd_confirm';
export const LOAD_PROFILE: AuthActionType = 'load_profile';
export const SUBMIT_PROFILE: AuthActionType = 'submit_profile';
export const USER_NOT_VERIFIED_ERROR: AuthActionType = 'user_not_verified_error';
