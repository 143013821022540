import React from 'react';
import QPcrStep from '../models/QPcrStep';
import { makeTextAboveLine, StepProps } from './common';

function GotoStep(props: StepProps) {
  const { step, stepWidth, yScale, index } = props;
  const { gotoStepIndex, gotoStepNumber, cycles } = new QPcrStep(step);
  return (
    <>
      <text x={stepWidth / 2} y={yScale(59)} className="goto-label">
        GOTO
      </text>
      <text
        id={`graph-stepgoto-${index + 1}`}
        x={stepWidth / 2}
        y={yScale(47)}
        className="goto-label"
      >
        Step {gotoStepNumber}
      </text>
      <line
        className="temperature-path"
        x1={stepWidth - 1}
        y1={yScale(0) + 18}
        x2={(gotoStepIndex - index) * stepWidth + 5}
        y2={yScale(0) + 18}
        markerEnd="url(#marker-arrow)"
      />
      {makeTextAboveLine(
        stepWidth / 2,
        yScale(0) + 12,
        `${cycles}x`,
        `graph-stepcycles-${index + 1}`
      )}
    </>
  );
}

GotoStep.displayName = 'GotoStep';

export default GotoStep;
