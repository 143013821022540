import axios, { AxiosResponse } from 'axios';
import {
  AdditionalParams,
  ApiGatewayClient,
  HeadersParam,
  ApiGatewayResponse,
  ApiGatewayError,
  Method
} from 'aws-api-gateway-client';
import RetryRequestError from './RetryRequestError';

import FeatureFlags, { FeatureFlagKeys } from '../components/feature_flags';
import { makeApiGatewayCall } from './authUtils';

export type RequestMethodTypes = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';
export type RequestBodyType = Record<string, any> | any[];

type RequestType = [string, Record<string, any>, string, Record<string, any>, RequestBodyType?];
type UnauthType = [string, RequestBodyType?];

type RequestParams = [
  method: Method,
  domain: string,
  params: Record<string, any>,
  pathTemplate: string,
  additionalParams: Record<string, any>,
  body?: RequestBodyType
];

type UnauthParams = [url: string, body?: RequestBodyType];

interface Request {
  (...args: RequestParams): Promise<any>;
}

interface UnauthRequest {
  (...args: UnauthParams): Promise<AxiosResponse<any, any>>;
}

function makeHeaderParams(headers: Record<string, any> = {}): HeadersParam {
  const integrationTestId = FeatureFlags().get(FeatureFlagKeys.INTEGRATION_TEST_ID);
  if (integrationTestId === undefined) {
    return { headers };
  }
  return {
    headers: { [FeatureFlagKeys.INTEGRATION_TEST_ID]: integrationTestId, ...headers }
  };
}

function withRetry(
  wrappedRequest: UnauthRequest | Request,
  maxAttempts = 3
): (...args: UnauthParams | RequestParams) => Promise<AxiosResponse<any, any>> | Promise<any> {
  return async function (...args: UnauthParams | RequestParams | any): Promise<any> {
    let response = null;
    let attempts = 1;
    let errMessage = null;

    while (attempts <= maxAttempts) {
      try {
        // eslint-disable-next-line no-await-in-loop
        response = await wrappedRequest(...args);
        return response;
      } catch (error: any) {
        const errorStatusCode = error.response?.status;
        errMessage = error.message;

        if (errorStatusCode < 500 && errorStatusCode >= 400) {
          throw error;
        }
      }
      attempts += 1;
    }
    throw new RetryRequestError(
      `Max retry attempts of ( ${maxAttempts} ) has been reached. The last error was: ${errMessage}`
    );
  };
}

const request: Request = (method, domain, params, pathTemplate, additionalParams, body = {}) => {
  // eslint-disable-next-line no-unused-vars,@typescript-eslint/naming-convention
  return new Promise((resolve, reject) => {
    const additionalHeaders = additionalParams && additionalParams.headers;
    const headerParams = makeHeaderParams(additionalHeaders);

    const callback = (apigClient: ApiGatewayClient) => {
      apigClient
        .invokeApi(
          {},
          pathTemplate,
          method,
          { ...additionalParams, ...headerParams } as AdditionalParams,
          body
        )
        .then((result: ApiGatewayResponse) => resolve(result))
        .catch((err: ApiGatewayError) => reject(err));
    };

    makeApiGatewayCall(callback, domain);
  });
};

export default {
  get: (...args: RequestType): Promise<any> => request('GET', ...args),
  post: (...args: RequestType): Promise<any> => request('POST', ...args),
  put: (...args: RequestType): Promise<any> => request('PUT', ...args),
  patch: (...args: RequestType): Promise<any> => request('PATCH', ...args),
  delete: (...args: RequestType): Promise<any> => request('DELETE', ...args),
  unauthPatch: (...args: UnauthType): Promise<AxiosResponse<any, any>> => {
    return axios
      .patch(...args)
      .then(results => results)
      .catch(err => {
        throw err;
      });
  },
  unauthGet: (...args: UnauthType): Promise<AxiosResponse<any, any>> => {
    const [url] = args;

    return axios
      .get(url, makeHeaderParams())
      .then(results => results)
      .catch(err => {
        throw err;
      });
  },
  withRetry
};

export async function getUrlAsJson(url: string) {
  const res = await axios.request({
    method: 'get',
    url,
    responseType: 'json'
  });
  return res.data;
}
