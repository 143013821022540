import { SystemEvent, SystemEventBroker } from '../index';
import { ProjectId } from '../../common/project-management-types';

export default class SetSelectedProjectEvent implements SystemEvent {
  readonly projectId: ProjectId;

  public static readonly eventName = 'setSelectedProject';

  public readonly name = SetSelectedProjectEvent.eventName;

  constructor(projectId: ProjectId) {
    this.projectId = projectId;
  }

  public static isSetSelectedProjectEvent(systemEvent: SystemEvent) {
    return systemEvent.name === SetSelectedProjectEvent.eventName;
  }

  public static notify(projectId: ProjectId) {
    SystemEventBroker.getInstance().notify(new SetSelectedProjectEvent(projectId));
  }
}
