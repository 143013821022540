import React from 'react';
import namedRoutes from '../../routes';
import { InstrumentFamilyPluginRepository } from '../../../instrument-family-plugin';
import HelpCenter, { docHelpPaths } from '../../../utils/HelpCenter';
import ContextSensitiveMenuItemLink from './ContextSensitiveMenuItemLink';
import HelpPageViewedTrackingEvent from '../../../frontend-common-libs/src/user-analytics/HelpPageViewedTrackingEvent';
import { MenuItemClassName, PluginHelpLinkComponent, RoutePath } from './types/types';

export type Props = {
  path: RoutePath;
  className: MenuItemClassName;
};

export default function ContextSensitiveHelpLink({ path, className }: Props) {
  const helpCenter = new HelpCenter();

  const pluginRoutePath = path.replace(`${namedRoutes.APP}`, '');
  const plugin = InstrumentFamilyPluginRepository.getInstance().getPluginByPath(pluginRoutePath);

  if (plugin && plugin.getHelpLink) {
    const pluginPath = path.replace(namedRoutes.APP, '');
    const PluginHelpLink = plugin.getHelpLink() as unknown as PluginHelpLinkComponent;
    const defaultToPath = helpCenter.helpUrl(docHelpPaths.default);

    return (
      <PluginHelpLink
        path={pluginPath}
        coreHelpCenterDomain={helpCenter.helpCenterDomain}
        render={({ to, onSelection }) => {
          return (
            <ContextSensitiveMenuItemLink
              to={to || defaultToPath}
              className={className}
              onSelection={onSelection}
            />
          );
        }}
      />
    );
  }

  const coreHelpUrl = helpCenter.getHelpUrl(path);
  const coreHelpPageTrackingEvent = new HelpPageViewedTrackingEvent(coreHelpUrl);
  const coreOnSelection = (): void => {
    coreHelpPageTrackingEvent.track();
  };

  return (
    <ContextSensitiveMenuItemLink
      to={coreHelpUrl}
      className={className}
      onSelection={coreOnSelection}
    />
  );
}
