export type UserFilesActionType =
  | 'userfiles_loading'
  | 'userfiles_loaded'
  | 'userfiles_error'
  | 'userfiles_deleted'
  | 'userfiles_archived'
  | 'userfiles_restored'
  | 'userfiles_renamed'
  | 'userfiles_processing_complete'
  | 'userfiles_updated'
  | 'userfiles_input_search_changed'
  | 'userfiles_searched'
  | 'userfiles_search_cleared'
  | 'userfiles_search_loaded'
  | 'userfiles_search_loading'
  | 'userfiles_search_error'
  | 'userfiles_added';

export const USERFILES_LOADING: UserFilesActionType = 'userfiles_loading';
export const USERFILES_LOADED: UserFilesActionType = 'userfiles_loaded';
export const USERFILES_ERROR: UserFilesActionType = 'userfiles_error';
export const USERFILES_DELETED: UserFilesActionType = 'userfiles_deleted';
export const USERFILES_ARCHIVED: UserFilesActionType = 'userfiles_archived';
export const USERFILES_RESTORED: UserFilesActionType = 'userfiles_restored';
export const USERFILES_ADDED: UserFilesActionType = 'userfiles_added';
export const USERFILES_RENAMED: UserFilesActionType = 'userfiles_renamed';
export const USERFILES_PROCESSING_COMPLETE: UserFilesActionType = 'userfiles_processing_complete';
export const USERFILES_SEARCH_INPUT_CHANGED: UserFilesActionType = 'userfiles_input_search_changed';
export const USERFILES_SEARCHED: UserFilesActionType = 'userfiles_searched';
export const USERFILES_SEARCH_CLEARED: UserFilesActionType = 'userfiles_search_cleared';
export const USERFILES_SEARCH_LOADED: UserFilesActionType = 'userfiles_search_loaded';
export const USERFILES_SEARCH_LOADING: UserFilesActionType = 'userfiles_search_loading';
export const USERFILES_SEARCH_ERROR: UserFilesActionType = 'userfiles_search_error';
