import './styles/gradient-calculator-table.scss';
import React, { ReactElement } from 'react';
import GradientTemperature from '../gradient-temperature/GradientTemperature';
import GradientRow from '../gradient-table-row/GradientRow';

export type Props = {
  temperatures: GradientTemperature[];
};

function GradientCalculatorTable({ temperatures }: Props): ReactElement {
  return (
    <div className="gradient-calculator-items">
      {temperatures.map(
        ({ value, backgroundColor, textColor }: GradientTemperature, index: number) => (
          <GradientRow
            key={`row-${index}-key`}
            rowIndex={index}
            value={value}
            backgroundColor={backgroundColor}
            color={textColor}
          />
        )
      )}
    </div>
  );
}

export default GradientCalculatorTable;
