import Gradient, { GradientLowerTemperature, GradientRange } from '../../Gradient';
import { GradientTemperature, GradientTemperature96 } from '../../gradient-temperature';

export default class Gradient96D extends Gradient {
  constructor(lowerTemp: GradientLowerTemperature, range: GradientRange, isValid: boolean) {
    super(8, lowerTemp, range, isValid, Gradient96D.offsetTable, Gradient96D.scTable);
  }

  private static readonly offsetTable: Array<Array<number>> = [
    [30.077153, 40.07296, 50.068767, 60.083979, 70.034539, 80.024813, 90.019551, 100.014289],
    [30.100051, 40.095842, 50.091632, 60.117224, 70.0795, 80.083324, 90.094068, 100.104813],
    [30.08478, 40.086022, 50.087265, 60.116722, 70.084392, 80.090593, 90.10639, 100.122187],
    [30.085194, 40.085254, 50.085315, 60.110945, 70.06665, 80.066314, 90.079594, 100.092874],
    [30.093088, 40.088859, 50.084629, 60.109757, 70.066342, 80.065699, 90.078814, 100.091929],
    [30.093797, 40.084318, 50.074838, 60.100126, 70.062442, 80.065601, 90.08469, 100.103778],
    [30.09139, 40.074781, 50.058173, 60.081547, 70.036801, 80.040843, 90.059517, 100.078191],
    [30.095372, 40.063522, 50.031672, 60.036605, 69.960096, 79.944684, 89.943226, 99.941769]
  ];

  private static readonly scTable: Array<Array<number>> = [
    [0.586095, 0.587329, 0.588562, 0.588198, 0.586493, 0.585307, 0.583931, 0.582555],
    [0.507587, 0.50952, 0.511454, 0.511555, 0.510803, 0.510211, 0.509277, 0.508344],
    [0.35571, 0.357176, 0.358642, 0.358738, 0.358187, 0.357878, 0.357452, 0.357027],
    [0.122912, 0.124061, 0.125211, 0.125612, 0.125481, 0.125493, 0.12575, 0.126007],
    [-0.149807, -0.147969, -0.146131, -0.144419, -0.143237, -0.142287, -0.140992, -0.139698],
    [-0.373203, -0.371298, -0.369392, -0.368207, -0.367461, -0.366887, -0.365932, -0.364976],
    [-0.51489, -0.513167, -0.511444, -0.511502, -0.511634, -0.511651, -0.510962, -0.510274],
    [-0.585768, -0.584157, -0.582547, -0.583578, -0.583585, -0.583617, -0.582548, -0.581478]
  ];

  // eslint-disable-next-line class-methods-use-this
  protected createGradientTemperature(
    index: number,
    temperatureValue: number
  ): GradientTemperature {
    return new GradientTemperature96(index, temperatureValue);
  }
}
