import React, { ReactNode } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import routes from '../../core/routes';
import { ReduxState } from '../../types';

type Props = {
  children: ReactNode;
  authenticated: boolean;
};

type StateProps = {
  authenticated: boolean;
};

/*
 * This component will redirect to the home page if the user is already authenticated
 * It's useful when creating a page that's intended for people who are NOT authenticated
 */
export function AuthenticatedRedirectImpl({ children, authenticated }: Props) {
  return authenticated ? <Redirect to={routes.HOME} /> : children;
}

function mapStateToProps(state: ReduxState): StateProps {
  return { authenticated: state.auth.get('authenticated') };
}

// @ts-ignore
export default connect(mapStateToProps, null)(AuthenticatedRedirectImpl);
