import React, { PureComponent } from 'react';
import PCRProtocolSteps from '../PCRProtocolSteps';
import PCRProtocolStepSubSection from '../PCRProtocolStepSubSection';
import PCRProtocolPlateRead from '../PCRProtocolPlateRead';
import PCRProtocolExtendTime from '../PCRProtocolExtendTime';
import IntFieldGroup from '../../../common/IntFieldGroup';
import PCRProtocolStepEditorModal from '../PCRProtocolStepEditorModal';
import EditStepContainer from '../EditStepContainer';
import BeepToggle from '../BeepToggle';
import ReadToggle from '../ReadToggle';
import {
  isValidIncrement,
  isValidRampRate,
  isValidTemperature,
  isValidTime
} from '../PCREditorValidateInput';
import QPcrStep from '../models/QPcrStep';
import { StepValue } from '../../pcr_protocol_types';
import { ProtocolStepProps as ProtocolTempStepProps } from '../PCRProtocolStepTypes';
import { parseFloatIfNot } from '../../../../common/numbers';
import FloatFieldGroup from '../../../common/FloatFieldGroup';

export type State = {
  editStep: QPcrStep;
};

export class PCRProtocolTempSteps extends PureComponent<ProtocolTempStepProps, State> {
  static conditionalRenderTempIncrement(increment?: string) {
    if (increment === undefined) {
      return null;
    }

    const incrementStr = `+ Increment temperature by ${increment} \xB0C per cycle`;
    return <PCRProtocolStepSubSection>{incrementStr}</PCRProtocolStepSubSection>;
  }

  static conditionalRenderRampRate(rate?: string) {
    if (rate === undefined) {
      return null;
    }

    const rateStr = `+ Slow Ramp Rate to ${rate} \xB0C per second`;
    return <PCRProtocolStepSubSection>{rateStr}</PCRProtocolStepSubSection>;
  }

  static conditionalRenderBeep(beep: boolean) {
    if (beep) {
      return <PCRProtocolStepSubSection>+ Beep</PCRProtocolStepSubSection>;
    }
    return null;
  }

  static defaultProps = {
    selectedIndex: 0
  };

  constructor(props: ProtocolTempStepProps) {
    super(props);
    const { editing } = this.props;
    if (editing) {
      this.state = { editStep: new QPcrStep(props.step) };
    }
  }

  componentWillReceiveProps(nextProps: ProtocolTempStepProps) {
    const { editing } = this.props;
    if (nextProps.editing && !editing) {
      this.setState({ editStep: new QPcrStep(nextProps.step) });
    }
  }

  onEditSave = () => {
    const { saveEditAction, index } = this.props;
    const { editStep } = this.state;
    if (saveEditAction) {
      saveEditAction(index, editStep.map);
    }
  };

  onEditCancel = () => {
    const { cancelEditAction } = this.props;
    if (cancelEditAction) {
      cancelEditAction();
    }
  };

  onChangeBeep = (value: boolean) => {
    this.onFieldChanged('beep', value);
  };

  onChangeRead = (value: boolean) => {
    this.onFieldChanged('read', value);
  };

  onFieldChanged = (name: string, value?: StepValue) => {
    const { editStep } = this.state;
    const newStep = editStep.set(name, value);
    this.setState({ editStep: newStep });
  };

  selectIndex = () => {
    const { index, setSelectedStepIndex } = this.props;
    setSelectedStepIndex(index);
  };

  renderReadButton() {
    const { isPcr } = this.props;
    const {
      editStep: { read }
    } = this.state;
    if (isPcr) return null;
    return (
      <div className="col-xs-12 form-group ">
        <ReadToggle id="read-check" state={Boolean(read)} onChange={this.onChangeRead} />
      </div>
    );
  }

  renderViewStep() {
    const { step } = this.props;
    const { tempAsString, timeAsString, read, incAsString, rampAsString, ext, beep } = new QPcrStep(
      step
    );
    return (
      <div role="presentation" onClick={this.selectIndex}>
        {`${tempAsString} \xB0C for ${timeAsString}`}
        <PCRProtocolPlateRead render={read} />
        {PCRProtocolTempSteps.conditionalRenderTempIncrement(incAsString)}
        {PCRProtocolTempSteps.conditionalRenderRampRate(rampAsString)}
        <PCRProtocolExtendTime extTime={ext} />
        {PCRProtocolTempSteps.conditionalRenderBeep(beep)}
      </div>
    );
  }

  renderEditStep() {
    const {
      editStep: { temp, inc, ramp, ext, beep, time }
    } = this.state;
    const { isAdd, index, stepsList, editStepTypeChangeAction } = this.props;
    const temperatureValid = isValidTemperature(parseFloatIfNot(temp), 0, 100, true);
    const timeValid = isValidTime(
      time,
      0,
      18 * 60 * 60, // 18 hours
      true
    );
    const incValid = isValidIncrement(parseFloatIfNot(inc), -10.0, 10.0);
    const rampValid = isValidRampRate(parseFloatIfNot(ramp), 0.1, 5);
    const extValid = isValidTime(ext, -60, 60);

    const saveValid =
      temperatureValid.valid &&
      timeValid.valid &&
      incValid.valid &&
      rampValid.valid &&
      extValid.valid;

    return (
      <PCRProtocolStepEditorModal
        onSave={saveValid ? this.onEditSave : undefined}
        onCancel={this.onEditCancel}
        isAdd={isAdd}
        index={index}
      >
        <EditStepContainer
          currentType="temp"
          stepsList={stepsList}
          onEditStepTypeChange={editStepTypeChangeAction}
        >
          <li>
            <div className="col-xs-12 form-group ">
              <FloatFieldGroup
                id="temp-edit"
                name="temp"
                label="Temperature"
                subLabel="(&#176;C)"
                value={temp}
                onChange={this.onFieldChanged}
                onBlur={this.onFieldChanged}
                validationState={temperatureValid.valid ? null : 'error'}
                error={temperatureValid.helpString}
                allowNegative={false}
              />
            </div>
          </li>
          <li>
            <div className="col-xs-12 form-group ">
              <IntFieldGroup
                id="time-edit"
                name="time"
                label="Time"
                subLabel="(sec/cycle)"
                value={time}
                onChange={this.onFieldChanged}
                // @ts-ignore
                onBlur={this.onFieldChanged}
                validationState={timeValid.valid ? null : 'error'}
                error={timeValid.helpString}
                allowNegative={false}
              />
            </div>
          </li>
          <li>
            <div className="col-xs-12 form-group ">
              <FloatFieldGroup
                id="inc-edit"
                name="inc"
                label="Increment"
                subLabel="(&#176;C/cycle)"
                value={inc}
                onChange={this.onFieldChanged}
                onBlur={this.onFieldChanged}
                validationState={incValid.valid ? null : 'error'}
                error={incValid.helpString}
              />
            </div>
          </li>
          <li>
            <div className="col-xs-12 form-group ">
              <FloatFieldGroup
                id="ramp-edit"
                name="ramp"
                label="Ramp rate"
                subLabel="(&#176;C/sec)"
                value={ramp}
                onChange={this.onFieldChanged}
                onBlur={this.onFieldChanged}
                validationState={rampValid.valid ? null : 'error'}
                error={rampValid.helpString}
                allowNegative={false}
              />
            </div>
          </li>

          <li>
            <div className="col-xs-12 form-group ">
              <IntFieldGroup
                id="ext-edit"
                name="ext"
                // @ts-ignore
                type="text"
                label="Extend"
                subLabel="(sec/cycle)"
                value={ext}
                onChange={this.onFieldChanged}
                validationState={extValid.valid ? null : 'error'}
                error={extValid.helpString}
              />
            </div>
          </li>
          <li>
            <div className="col-xs-12 form-group ">
              <BeepToggle id="beep-check" state={Boolean(beep)} onChange={this.onChangeBeep} />
            </div>
          </li>
          <li>{this.renderReadButton()}</li>
        </EditStepContainer>
      </PCRProtocolStepEditorModal>
    );
  }

  render() {
    const { editing, index, selectedIndex, deleteAction, editAction } = this.props;
    if (editing) {
      return this.renderEditStep();
    }
    return (
      <PCRProtocolSteps
        index={index}
        selectedIndex={selectedIndex}
        deleteAction={deleteAction}
        editAction={editAction}
      >
        {this.renderViewStep()}
      </PCRProtocolSteps>
    );
  }
}

export default PCRProtocolTempSteps;
