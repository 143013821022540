import { DispatchType, ORG_DESCRIPTION_CHANGED, ORG_RENAMED } from './action-types';
import { editOrg } from '../api/org-api';
import notification from '../../frontend-common-libs/src/utils/notifications';
import { GetState } from '../../types';
import {
  TrackedOrganizationEvents,
  trackEvent
} from '../../frontend-common-libs/src/user-analytics/trackedEvents';
import { withRefreshOrgToken } from './with-refresh-org-token';
import { CbWithToken } from './WithRefreshToken';

export function dispatchOrgEdited(
  dispatch: DispatchType,
  orgId: string,
  name: string | undefined,
  description: string | undefined
) {
  if (name != null) {
    dispatch({ type: ORG_RENAMED, payload: { orgId, name } });
  }
  if (description != null) {
    dispatch({ type: ORG_DESCRIPTION_CHANGED, payload: { orgId, description } });
  }
}

export function editOrganization(
  orgId: string,
  name: string | undefined,
  description: string | undefined
) {
  return async (dispatch: DispatchType, getState: GetState) => {
    try {
      const editOrgCb: CbWithToken = async (accessToken: string) => {
        return editOrg(orgId, name, description, accessToken);
      };
      await withRefreshOrgToken(dispatch, getState, orgId, editOrgCb);

      dispatchOrgEdited(dispatch, orgId, name, description);
      trackEvent(TrackedOrganizationEvents.RenameOrg, { name, description });
    } catch (error) {
      notification.error('Error renaming organization');
    }
  };
}
