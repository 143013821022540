// @ts-nocheck
import React from 'react';
import GroupModeToggle from './GroupModeToggle';
import ScaleToggle from './ScaleToggle';

type Props = {
  analysisMode: string;
  scale: string;
  onUpdateAnalysisMode: (...args: Array<any>) => any;
  onUpdateScale: (...args: Array<any>) => any;
};
export default ({ analysisMode, scale, onUpdateAnalysisMode, onUpdateScale }: Props) => (
  <div className="pcr-chart-controls">
    <GroupModeToggle analysisMode={analysisMode} onUpdateAnalysisMode={onUpdateAnalysisMode} />
    <ScaleToggle scale={scale} onUpdateScale={onUpdateScale} />
  </div>
);
