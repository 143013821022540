import React, { Component } from 'react';
import { Map, Seq } from 'immutable';
import ProtocolRow from './ProtocolRow';
import Table from '../common/tables/Table';
import THead from '../common/tables/THead';
import TCell from '../common/tables/TCell';
import LoadableTBody from '../common/tables/LoadableTBody';
import { updateSelectedEntities } from '../../utils/rowEntityUtils';
import { UserFile } from '../../common/types';

export type Props = {
  protocols: Seq.Indexed<Map<string, any>>;
  fetchMoreData: (...args: Array<any>) => any;
  hasMoreData: boolean;
  isLoading: boolean;
  isError: boolean;
  openProtocolRoute: string;
  archiveProtocols: (entityIdList: string[]) => Promise<void>;
  renameProtocol: (entityId: string, newName: string) => Promise<void>;
  exportProtocol?: (fileEntity: any) => Promise<void>;
  moveFile?: (entity: UserFile) => unknown;
  fileHistory?: (entity: UserFile) => unknown;
  canEditProjectFiles?: boolean;
};

type State = {
  selectedProtocols: Array<string>;
};

class ProtocolTable extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedProtocols: []
    };
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  onSelectedProtocol = (protocolId: string) => {
    const { selectedProtocols } = this.state;
    this.setState({
      selectedProtocols: updateSelectedEntities(selectedProtocols, protocolId)
    });
  };

  render() {
    const {
      protocols,
      fetchMoreData,
      hasMoreData,
      isLoading,
      isError,
      openProtocolRoute,
      renameProtocol,
      archiveProtocols,
      exportProtocol,
      moveFile,
      fileHistory,
      canEditProjectFiles
    } = this.props;

    return (
      <div className="flex-column-container" id="protocols-table-container">
        <Table className="flex-column-container base-table" id="protocols-table">
          <THead>
            <TCell />
            <TCell>NAME</TCell>
            <TCell>LAST UPDATED</TCell>
            <TCell>TYPE</TCell>
            <TCell />
          </THead>
          <LoadableTBody
            fetchMoreData={fetchMoreData}
            hasMoreData={hasMoreData}
            isLoading={isLoading}
            isError={isError}
            errorMsg="Error retrieving protocols."
          >
            {(tableBodyRef: any) =>
              protocols.map(item => (
                <ProtocolRow
                  key={item.get('id')}
                  item={item}
                  tableBodyRef={tableBodyRef}
                  openProtocolRoute={openProtocolRoute}
                  renameProtocol={renameProtocol}
                  archiveProtocols={archiveProtocols}
                  exportProtocol={exportProtocol}
                  moveFile={moveFile}
                  fileHistory={fileHistory}
                  canEditProjectFiles={canEditProjectFiles}
                />
              ))
            }
          </LoadableTBody>
        </Table>
      </div>
    );
  }
}

export default ProtocolTable;
