import humanizeDuration from 'humanize-duration';
import { Nullable, NullableNumber, NullableString } from './nullable-types';
import DateFnsFactory from './DateFnsFactory';

export type NullableDate = Nullable<Date>;

export function toDateTimeFormat(date: NullableDate): string {
  if (date == null) return '';
  const dateStr = DateFnsFactory.format(date, 'LLLL d, yyyy');
  const timeStr = DateFnsFactory.format(date, 'h:mm a');
  return `${dateStr} ${timeStr}`;
}

export function toDateTimeSecFormat(date: NullableDate): string {
  if (date == null) return '';
  const dateStr = DateFnsFactory.format(date, 'MM/dd/yyyy');
  const timeStr = DateFnsFactory.format(date, 'HH:mm:ss');
  return `${dateStr} ${timeStr}`;
}

export function toDateFormat(date: NullableDate): string {
  if (date == null) return '';
  return DateFnsFactory.format(date, 'L/d/yy');
}

export function parseFromUTCString(dateStr: NullableString): NullableDate {
  return dateStr ? DateFnsFactory.parseISO(dateStr) : undefined;
}

export function utcStringToDateFormat(utcString: NullableString): string {
  const date = parseFromUTCString(utcString);
  return toDateFormat(date);
}

export function fixDateTimezone(date: Date, utcString: string): Date {
  const timezoneRegex = /[+-]\d\d:\d\d/g;
  const timezoneMatches = utcString.match(timezoneRegex);
  if (timezoneMatches == null || timezoneMatches.length < 1) return date;
  const timezoneStr = timezoneMatches[timezoneMatches.length - 1];
  const timezoneOffset = timezoneStr.substring(1, timezoneStr.length);
  const sign = timezoneStr[0];
  const minSec = timezoneOffset.split(':');
  let minutesOffset = parseInt(minSec[1], 10) + parseInt(minSec[0], 10) * 60;
  if (sign === '-') minutesOffset *= -1;
  return new Date(date.getTime() - minutesOffset * 60000);
}

export function formatRunLogTime(utcString: NullableString): string {
  if (utcString == null) return '';
  const date = parseFromUTCString(utcString);
  if (date == null) return '';
  const dateFixed = fixDateTimezone(date, utcString);
  return toDateTimeSecFormat(dateFixed);
}

export function utcStringToDateTimeFormat(utcString: NullableString): string {
  const date = parseFromUTCString(utcString);
  return toDateTimeFormat(date);
}

export function gmtStringToDateTimeFormat(utcString: NullableString): string {
  const date = utcString ? new Date(utcString) : undefined;
  return toDateTimeFormat(date);
}

export function gmtStringToDateTimeSecFormat(utcString: NullableString): string {
  const date = utcString ? new Date(utcString) : undefined;
  return toDateTimeSecFormat(date);
}

export function getTimeSpanMilliSeconds(startTime: string, endTime: string): NullableNumber {
  if (startTime == null || startTime === '' || endTime == null || endTime === '') return null;
  return new Date(endTime).getTime() - new Date(startTime).getTime();
}

export function getHoursMinutesDurationFormat(
  timeSpanMilliSeconds: NullableNumber
): NullableString {
  if (timeSpanMilliSeconds == null || !Number.isInteger(timeSpanMilliSeconds)) return undefined;
  return humanizeDuration(timeSpanMilliSeconds, {
    units: ['h', 'm'],
    delimiter: ' ',
    round: true
  });
}

export function getRunDurationFormat(startTime: string, endTime: string): NullableString {
  const timeElapsedMs = getTimeSpanMilliSeconds(startTime, endTime);
  return getHoursMinutesDurationFormat(timeElapsedMs);
}

export function zeroPad(num: number, places: number) {
  return String(num).padStart(places, '0');
}

export function getDurationFormat(timeSpanSeconds: NullableNumber): NullableString {
  if (timeSpanSeconds == null || !Number.isInteger(timeSpanSeconds)) return undefined;
  if (timeSpanSeconds < 0) return undefined;
  let time: number = timeSpanSeconds;
  const sec = time % 60;
  time = Math.floor(time / 60);
  const min = time % 60;
  const hour = Math.floor(time / 60);
  return `${zeroPad(hour, 2)}:${zeroPad(min, 2)}:${zeroPad(sec, 2)}`;
}

export function toLongDateFormat(utcString: NullableString): string {
  const date = parseFromUTCString(utcString);
  return date ? DateFnsFactory.format(date, 'LLLL d, yyyy') : '';
}
