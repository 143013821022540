import React, { SyntheticEvent } from 'react';
import ModalDialog from '../../common/dialogs/ModalDialog';
import VersionConflictPromptVm, { ConflictOption } from './VersionConflictPromptVm';
import './styles/version-conflict.scss';
import { modalStyleV2 } from '../../common/dialogs/custom-modal-styles';
import RadioButton from '../../common/radio_button_group/RadioButton';
import { ConnectViewModel } from '../../../common/mvvm/ConnectViewModel';

export type Props = {
  versionConflictPromptVm: VersionConflictPromptVm;
};

const versionConflictStyle = { ...modalStyleV2, modalStyle: 'version-conflict' };

export class VersionConflictPromptImpl extends React.Component<Props> {
  public onRadioButtonChange = (event: any) => {
    const { versionConflictPromptVm } = this.props;
    versionConflictPromptVm.selectedOption = event.target.name;
  };

  updateFileName = (e: SyntheticEvent<HTMLInputElement>) => {
    const { versionConflictPromptVm } = this.props;
    versionConflictPromptVm.fileName = e.currentTarget.value;
  };

  renderModalContent() {
    const { versionConflictPromptVm } = this.props;
    const { selectedOption, fileName, fileNameError } = versionConflictPromptVm;
    return (
      <div className="version-conflict-panel">
        <div className="bold-text">Changes to this file have been saved by another user.</div>
        <div className="bold-text">What would you like to do next?</div>
        <RadioButton
          id="save-as-radio-button"
          name={ConflictOption.saveAs}
          checked={selectedOption === ConflictOption.saveAs}
          onChange={this.onRadioButtonChange}
          value={ConflictOption.saveAs}
          disabled={false}
        >
          Save my changes as a new file
        </RadioButton>
        <div className="normal-text">Discard changes made elsewhere.</div>
        <div className="normal-text new-file-label">New File Name</div>
        <input
          className="save-as-file-name"
          id="save-as-file-name"
          type="text"
          value={fileName}
          onChange={this.updateFileName}
        />
        <span id="file-name-error" className="file-name-error with-ellipsis">
          {fileNameError}
        </span>
        <RadioButton
          id="reload-radio-button"
          name={ConflictOption.reload}
          checked={selectedOption === ConflictOption.reload}
          onChange={this.onRadioButtonChange}
          value={ConflictOption.reload}
          disabled={false}
        >
          Load the changes made by the other user.
        </RadioButton>
        <div className="normal-text">Discard my changes and continue editing.</div>
      </div>
    );
  }

  render() {
    const { versionConflictPromptVm } = this.props;
    const { shouldBlock, onCancel, okBtnDisabled, onOk, saveInProgress } = versionConflictPromptVm;
    if (!shouldBlock) return null;
    return (
      <ModalDialog
        title="Alert: File Changed"
        show={shouldBlock}
        onCancel={onCancel}
        onSuccess={onOk}
        okBtnText="OK"
        okBtnDisabled={okBtnDisabled}
        customModalStyle={versionConflictStyle}
        inProgress={saveInProgress}
      >
        {this.renderModalContent()}
      </ModalDialog>
    );
  }
}

export default function VersionConflictPrompt(props: Readonly<Props>) {
  const { versionConflictPromptVm } = props;
  return (
    <ConnectViewModel vm={versionConflictPromptVm} vmPropKey="versionConflictPromptVm">
      <VersionConflictPromptImpl {...props} />
    </ConnectViewModel>
  );
}
