// @ts-nocheck
import { Map } from 'immutable';
import { List } from 'immutable';

/** helper class to read from runData structure */
export const Run = {
  steps: (runData: Map): List => runData.get('steps'),

  stepData: (runData: Map, stepNumber: number): Map =>
    runData.getIn(['stepData', stepNumber.toString()]),

  wells: (runData: Map): Map => runData.getIn(['plate', 'wells'])
};

export const Step = {
  cycles: (step: Map) => step.get('cycles'),
  baseline: (step: Map, fluor: string, well: string) =>
    step.getIn(['computations', 'perWell', fluor, well, 'baseline']),
  cq: (step: Map, fluor: string, well: string) =>
    step.getIn(['computations', 'perWell', fluor, well, 'ct']),
  wellData: (step: Map, fluor: string, well: string) => step.getIn(['data', fluor, well]) || Map()
};
