import React, { ReactElement } from 'react';
import { Button, Modal } from 'react-bootstrap';
import styles from '../style/create-api-token-modal.module.scss';

export interface CreateAPITokenModalProps {
  onClose: () => void;
  show: boolean;
  children: number | string | ReactElement | Array<any>;
  isCreated: boolean;
}

export default function CreateAPITokenModal(props: CreateAPITokenModalProps) {
  const { onClose, show, children, isCreated } = props;

  return (
    <Modal backdrop="static" onHide={onClose} show={show}>
      <Modal.Header className={styles.modalHeader}>
        <Modal.Title id="api-portal-create-new-api-token-modal-title">
          Create a New API Token
        </Modal.Title>
        <button data-dismiss="modal" onClick={onClose} className="close" aria-label="close">
          &times;
        </button>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      <Modal.Footer className={styles.modalFooter}>
        <Button
          id="api-portal-create-new-api-token-modal-cancel-button"
          className={isCreated ? 'btn-primary' : 'btn-secondary'}
          onClick={onClose}
        >
          {isCreated ? 'Yes, I saved my token' : 'Cancel'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
