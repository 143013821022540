import {
  DEFAULT_PROJECT_ID,
  Project,
  ProjectId
} from '../../frontend-common-libs/src/common/project-management-types';
import { getProjects, GetProjectsResponse } from '../api/project-api';

export default class ProjectRepository {
  public static async getProjects(
    orgId: string,
    accessToken: string
  ): Promise<GetProjectsResponse> {
    return getProjects(orgId, accessToken);
  }

  static async getProjectsWithRetry(orgId: string, accessToken: string) {
    try {
      return await ProjectRepository.getProjects(orgId, accessToken);
    } catch (error) {
      // retry one more time
      return ProjectRepository.getProjects(orgId, accessToken);
    }
  }

  static getStoredSelectedProjectId(): ProjectId | null {
    return window.localStorage.getItem('selectedProjectId');
  }

  static setStoredSelectedProjectId(projectId: ProjectId) {
    window.localStorage.setItem('selectedProjectId', projectId);
  }

  static isProjectInProjects(projectId: ProjectId, projects: Project[]): boolean {
    let found = false;
    projects.forEach(project => {
      if (project.id === projectId) found = true;
    });
    return found;
  }

  static getSelectedProjectId(projects: Project[]) {
    const storageSelectedProjectId = ProjectRepository.getStoredSelectedProjectId();
    if (
      storageSelectedProjectId != null &&
      (storageSelectedProjectId === DEFAULT_PROJECT_ID ||
        ProjectRepository.isProjectInProjects(storageSelectedProjectId, projects))
    ) {
      return storageSelectedProjectId;
    }
    return DEFAULT_PROJECT_ID;
  }
}
