import React, { Component, RefObject } from 'react';
import LoadingRow from './LoadingRow';
import ViewportIntersectionObserver from '../ViewportIntersectionObserver';
import { guid } from '../../../utils/commonUtils';
import { LoadableTBodyChildren } from './Types';

export type Props = {
  children: LoadableTBodyChildren;
  fetchMoreData: (...args: Array<any>) => any;
  hasMoreData: boolean;
  isLoading: boolean;
  isError: boolean;
  errorMsg: string;
};

export default class LoadableTBody extends Component<Props> {
  loadingRowRef: RefObject<any>;

  tableBodyRef: RefObject<any>;

  constructor(props: Props) {
    super(props);
    this.tableBodyRef = React.createRef();
    this.loadingRowRef = React.createRef();
  }

  // This method is called at the very first time the loading more files enters the display area
  onIntersectionObserved = (): void => {
    const loadingRow = this.loadingRowRef.current;
    loadingRow.onIntersectionObserved();
  };

  renderLoadingRow = (observeElement: (...args: Array<any>) => any) => {
    const { fetchMoreData, hasMoreData, isLoading, isError, errorMsg } = this.props;
    return (
      <LoadingRow
        key={guid()}
        ref={this.loadingRowRef}
        observeElement={observeElement}
        fetchMoreData={fetchMoreData}
        hasMoreData={hasMoreData}
        isLoading={isLoading}
        isError={isError}
        errorMsg={errorMsg}
      />
    );
  };

  render() {
    const { children, fetchMoreData, hasMoreData, isLoading, isError, errorMsg, ...rest } =
      this.props;
    return (
      <tbody ref={this.tableBodyRef} {...rest}>
        {typeof children === 'function' ? children(this.tableBodyRef) : children}
        <ViewportIntersectionObserver
          render={this.renderLoadingRow}
          onViewportEntered={this.onIntersectionObserved}
        />
      </tbody>
    );
  }
}
