import { Component } from 'react';
import { connect } from 'react-redux';
import { getProjectTokenIfNeeded as getProjectTokenIfNeededAction } from '../actions/get-project-token';
import { ReduxState } from '../../types';
import { getActiveOrg, getOrgUserToken } from '../../organization-management/selectors/selectors';
import { DEFAULT_PROJECT_ID } from '../../frontend-common-libs/src/common/project-management-types';
import notification from '../../frontend-common-libs/src/utils/notifications';
import { getProjectUserToken } from '../selectors/selectors';

export type Props = {
  projectId: string;
  orgAccessToken?: string;
  getProjectTokenIfNeeded?: (projectId: string) => any;
  projectAccessToken?: string;
};

export class ProjectTokenImpl extends Component<Props> {
  async componentDidMount() {
    await this.getTokenIfNeeded();
  }

  async componentDidUpdate() {
    await this.getTokenIfNeeded();
  }

  async getTokenIfNeeded() {
    const { orgAccessToken, getProjectTokenIfNeeded, projectId, projectAccessToken } = this.props;
    if (
      projectId === DEFAULT_PROJECT_ID ||
      !getProjectTokenIfNeeded ||
      !orgAccessToken ||
      projectAccessToken
    )
      return;

    try {
      await getProjectTokenIfNeeded(projectId);
    } catch {
      notification.error('Error getting project access token');
    }
  }

  render() {
    return null;
  }
}
export function mapStateToProps(
  state: ReduxState,
  ownProps: Props
): {
  [key: string]: any;
} {
  const orgId = getActiveOrg(state);
  const { projectId } = ownProps;
  return {
    orgAccessToken: getOrgUserToken(state, orgId),
    projectAccessToken: getProjectUserToken(state, projectId)
  };
}

// @ts-ignore
export default connect(mapStateToProps, { getProjectTokenIfNeeded: getProjectTokenIfNeededAction })(
  ProjectTokenImpl
);
