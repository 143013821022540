/* eslint-disable react/no-unused-state */
import React, { Component, ReactNode } from 'react';
import classNames from 'classnames';

export type SelectionBoxDimensions = {
  left: number;
  top: number;
  width: number;
  height: number;
};

export type ModifierKeys = {
  ctrlKey: boolean | null | undefined;
  metaKey: boolean | null | undefined;
};

type PointCoordinate = {
  x: number;
  y: number;
  scrollLeft: number;
  scrollTop: number;
};

type OrientationCoordinate = {
  vertical: number;
  horizontal: number;
};

type DirectionCoordinate = {
  left: number;
  top: number;
};

type ScrollOrientation = {
  right: boolean;
  left: boolean;
  down: boolean;
  up: boolean;
};

type SelectionWindow = {
  scrollWidth: number;
  scrollHeight: number;
  top: number;
  right: number;
  bottom: number;
  left: number;
  width: number;
  height: number;
  canScroll: { vertical: boolean; horizontal: boolean };
};

export type SelectionBoxProps = {
  /** called when user is dragging selection box (mouseMove) */
  selectionChanging: (arg0: SelectionBoxDimensions) => any;

  /** called when user is done dragging box (mouseUp) */
  selectionComplete: (arg0: SelectionBoxDimensions, arg1: ModifierKeys) => any;
  children: ReactNode;
  selectionWindowSelector: string;
  verticalOffset?: number;
};

export type SelectionBoxState = {
  isMouseDown: boolean;
  startPoint: PointCoordinate | null;
  dimensions: SelectionBoxDimensions | null;
};

export default class SelectionBox extends Component<SelectionBoxProps, SelectionBoxState> {
  static defaultProps = {
    verticalOffset: 0
  };

  selectionWindowElement: HTMLElement | null;

  selectionWindow?: SelectionWindow | null;

  scrollingEndPoint: PointCoordinate | null;

  amountToScroll: OrientationCoordinate;

  constructor(props: SelectionBoxProps) {
    super(props);
    this.state = {
      isMouseDown: false,
      startPoint: null,
      dimensions: null
    };
    this.selectionWindowElement = null;
    // scrollingEndPoint is used to keep track of the end point during scroll
    // since scroll event does not include clientX and clientY properties
    this.scrollingEndPoint = null;
    this.amountToScroll = { vertical: 0, horizontal: 0 };
  }

  componentDidMount() {
    const { selectionWindowSelector } = this.props;
    this.selectionWindowElement = document.querySelector(selectionWindowSelector);
  }

  onMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
    if (event.button !== 0) {
      return;
    }
    const { clientX, clientY } = event;
    const { selectionWindowElement } = this;

    if (selectionWindowElement) {
      const { scrollWidth, scrollHeight, scrollLeft, scrollTop } = selectionWindowElement;

      const nextState = {
        isMouseDown: true,
        startPoint: {
          x: clientX,
          y: clientY,
          scrollLeft,
          scrollTop
        },
        dimensions: {
          left: clientX,
          top: clientY,
          width: 0,
          height: 0
        }
      };
      this.setState(nextState);

      // snapshot of selectionWindow
      const { top, right, bottom, left, width, height } =
        selectionWindowElement.getBoundingClientRect();

      const selectionWindow: SelectionWindow = {
        scrollWidth,
        scrollHeight,
        top,
        right,
        bottom,
        left,
        width,
        height,
        canScroll: {
          horizontal: false,
          vertical: false
        }
      };

      const {
        right: canScrollRight,
        left: canScrollLeft,
        down: canScrollDown,
        up: canScrollUp
      } = this.canScroll(selectionWindowElement, selectionWindow);

      const canScrollHorizontal = canScrollRight || canScrollLeft;
      const canScrollVertical = canScrollDown || canScrollUp;
      this.selectionWindow = {
        ...selectionWindow,
        canScroll: {
          horizontal: canScrollHorizontal,
          vertical: canScrollVertical
        }
      };
      // Only add scoll listener if scrolling is possible
      if (canScrollHorizontal || canScrollVertical) {
        selectionWindowElement.addEventListener('scroll', this.onScroll);
      }

      // attached below listerners to document to support mouse moving outside
      // of the selection window without effecting selection inside the window
      window.document.addEventListener('mousemove', this.onMouseMove);
      window.document.addEventListener('mouseup', this.onMouseUp);
    }
  };

  onMouseMove = (event: MouseEvent) => {
    event.preventDefault();
    const { selectionWindowElement, selectionWindow } = this;
    if (selectionWindowElement && selectionWindow) {
      const {
        canScroll: { horizontal: canScrollHorizontal, vertical: canScrollVertical }
      } = selectionWindow;

      const {
        state: { startPoint, isMouseDown }
      } = this;

      if (isMouseDown && startPoint) {
        const endPoint = {
          x: event.clientX,
          y: event.clientY,
          scrollLeft: selectionWindowElement.scrollLeft,
          scrollTop: selectionWindowElement.scrollTop
        };

        const { top, right, bottom, left } = selectionWindow;

        this.updateSelectionBox(startPoint, endPoint, {
          left,
          top
        });

        let bottomBoundary = bottom;
        const { verticalOffset } = this.props;
        if (verticalOffset) {
          const offsetBottom = window.innerHeight - verticalOffset;
          if (offsetBottom < bottom) {
            bottomBoundary = offsetBottom;
          }
        }

        //  handle scrolling
        const isHorizontalScroll =
          canScrollHorizontal && (endPoint.x >= right || endPoint.x <= left);
        const isVerticalScroll =
          canScrollVertical && (endPoint.y >= bottomBoundary || endPoint.y <= top);
        if (isHorizontalScroll || isVerticalScroll) {
          this.scrollingEndPoint = endPoint;

          if (isHorizontalScroll) {
            if (endPoint.x >= right) {
              this.amountToScroll.horizontal = endPoint.x - right;
            } else {
              this.amountToScroll.horizontal = endPoint.x - left;
            }
          }

          if (isVerticalScroll) {
            if (endPoint.y >= bottomBoundary) {
              this.amountToScroll.vertical = endPoint.y - bottomBoundary;
            } else {
              this.amountToScroll.vertical = endPoint.y - top;
            }
          }

          this.scrollRegion();
        } else {
          this.scrollingEndPoint = null;
          this.amountToScroll = { vertical: 0, horizontal: 0 };
        }
      }
    }
  };

  onScroll = () => {
    const {
      scrollingEndPoint,
      selectionWindow,
      state: { startPoint }
    } = this;
    if (startPoint && selectionWindow && scrollingEndPoint) {
      const { left, top } = selectionWindow;
      this.updateSelectionBox(startPoint, scrollingEndPoint, {
        left,
        top
      });
      this.scrollRegion();
    }
  };

  onMouseUp = (event: MouseEvent) => {
    event.preventDefault();
    window.document.removeEventListener('mousemove', this.onMouseMove);
    window.document.removeEventListener('mouseup', this.onMouseUp);

    const { selectionComplete } = this.props;
    // capture final box dimensions
    const {
      selectionWindowElement,
      selectionWindow,
      state: { startPoint },
      scrollingEndPoint
    } = this;
    if (startPoint && selectionWindowElement && selectionWindow) {
      selectionWindowElement.removeEventListener('scroll', this.onScroll);
      const { scrollLeft, scrollTop } = selectionWindowElement;
      let endPoint;
      if (scrollingEndPoint) {
        // if mouse up while scrolling
        endPoint = scrollingEndPoint;
      } else {
        endPoint = {
          x: event.clientX,
          y: event.clientY,
          scrollLeft,
          scrollTop
        };
      }

      const { left, top } = selectionWindow;
      const { wellSelectionDim } = this.calcDimensions(startPoint, endPoint, {
        left,
        top
      });
      const { ctrlKey, metaKey } = event;
      const modifierKeys = { ctrlKey, metaKey };
      selectionComplete(wellSelectionDim, modifierKeys);
    }
    // reset local variables and state to default values
    this.scrollingEndPoint = null;
    this.amountToScroll = { vertical: 0, horizontal: 0 };
    this.selectionWindow = null;
    this.setState({
      isMouseDown: false,
      startPoint: null,
      dimensions: null
    });
  };

  calcDimensions = (
    startPoint: PointCoordinate,
    endPoint: PointCoordinate,
    offset: DirectionCoordinate
  ) => {
    const { x: aX, y: aY, scrollLeft: startScrollLeft, scrollTop: startScrollTop } = startPoint;
    const { x: bX, y: bY, scrollLeft: endScrollLeft, scrollTop: endScrollTop } = endPoint;

    // Dimension without scroll adjustments
    const dim = {
      left: Math.min(aX, bX),
      top: Math.min(aY, bY),
      width: Math.abs(aX - bX),
      height: Math.abs(aY - bY)
    };

    // ** Horizontal Scroll **
    // Selection Box Dimensions - used to draw selection box
    const hBoxDim = {
      left: dim.left - offset.left,
      width: dim.width
    };
    // Selection Dimensions - used to figure out what wells are in selection box
    const hSelectionDim = { left: dim.left };

    const hDidScroll = startScrollLeft > 0 || endScrollLeft > 0;
    const relativeAx = aX + startScrollLeft;
    const relativeBx = bX + endScrollLeft;
    const hSelectionDirection = relativeAx < relativeBx ? 'right' : 'left';

    if (hDidScroll) {
      if (hSelectionDirection === 'right') {
        const hAdditionalWidth = endScrollLeft - startScrollLeft;
        hBoxDim.left += startScrollLeft;
        if (relativeBx - relativeAx > hAdditionalWidth) {
          hBoxDim.width += hAdditionalWidth;
          hSelectionDim.left -= hAdditionalWidth;
        } else {
          hBoxDim.left += aX - bX;
          hBoxDim.width = hAdditionalWidth - aX + bX;
          hSelectionDim.left += aX - bX - hAdditionalWidth;
        }
      } else if (hSelectionDirection === 'left') {
        const hAdditionalWidth = startScrollLeft - endScrollLeft;
        hBoxDim.left = relativeBx - offset.left;
        if (relativeAx - relativeBx > hAdditionalWidth) {
          hBoxDim.width += hAdditionalWidth;
        } else {
          hBoxDim.width = relativeAx - relativeBx;
          hSelectionDim.left += hAdditionalWidth - relativeAx + relativeBx;
        }
      }
    }

    // ** Vertical Scroll **
    // Selection Box Dimensions - used to draw selection box
    const vBoxDim = {
      top: dim.top - offset.top,
      height: dim.height
    };
    // Selection Dimensions - used to figure out what wells are in selection box
    const vSelectionDim: { top: number; height?: number } = { top: dim.top };
    const vDidScroll = startScrollTop > 0 || endScrollTop > 0;
    const relativeAy = aY + startScrollTop;
    const relativeBy = bY + endScrollTop;
    const vSelectionDirection = relativeAy < relativeBy ? 'down' : 'up';
    if (vDidScroll) {
      if (vSelectionDirection === 'down') {
        const vAdditionalHeight = endScrollTop - startScrollTop;
        vBoxDim.top += startScrollTop;
        if (relativeBy - relativeAy > vAdditionalHeight) {
          vBoxDim.height += vAdditionalHeight;
          vSelectionDim.top -= vAdditionalHeight;
        } else {
          vBoxDim.top += aY - bY;
          vBoxDim.height = vAdditionalHeight - aY + bY;
          vSelectionDim.top += aY - bY - vAdditionalHeight;
          vSelectionDim.height = vAdditionalHeight - aY + bY;
        }
      } else if (vSelectionDirection === 'up') {
        const vAdditionalHeight = startScrollTop - endScrollTop;
        vBoxDim.top = relativeBy - offset.top;
        if (relativeAy - relativeBy > vAdditionalHeight) {
          vBoxDim.height += vAdditionalHeight;
        } else {
          vBoxDim.height = relativeAy - relativeBy;
          vSelectionDim.top += vAdditionalHeight - relativeAy + relativeBy;
        }
      }
    }

    const selectionBoxDim = {
      ...hBoxDim,
      ...vBoxDim
    };

    const wellSelectionDim = {
      ...selectionBoxDim, // width and height
      ...hSelectionDim,
      ...vSelectionDim
    };
    return { selectionBoxDim, wellSelectionDim };
  };

  updateSelectionBox = (
    startPoint: PointCoordinate,
    endPoint: PointCoordinate,
    offset: DirectionCoordinate
  ) => {
    const { selectionChanging } = this.props;
    const { selectionBoxDim, wellSelectionDim } = this.calcDimensions(startPoint, endPoint, offset);
    selectionChanging(wellSelectionDim);
    this.setState({
      dimensions: selectionBoxDim
    });
  };

  canScroll = (
    selectionWindowElement: HTMLElement,
    selectionWindow: SelectionWindow
  ): ScrollOrientation => {
    const { scrollLeft, scrollTop } = selectionWindowElement;
    const { width, height, scrollWidth, scrollHeight } = selectionWindow;
    return {
      right: scrollLeft + width < scrollWidth,
      left: scrollLeft > 0,
      down: scrollTop + height < scrollHeight,
      up: scrollTop > 0
    };
  };

  scrollRegion = () => {
    let {
      amountToScroll: { horizontal: hToScroll, vertical: vToScroll }
    } = this;
    const { selectionWindowElement, selectionWindow } = this;
    if (selectionWindowElement && selectionWindow) {
      const { scrollLeft, scrollTop } = selectionWindowElement;
      const { width, height, scrollWidth, scrollHeight } = selectionWindow;

      const tryingToScrollRight = hToScroll > 0;
      const tryingToScrollLeft = hToScroll < 0;
      const tryingToScrollDown = vToScroll > 0;
      const tryingToScrollUp = vToScroll < 0;

      const tryingToScrollHorizontal = tryingToScrollRight || tryingToScrollLeft;
      const tryingToScrollVertical = tryingToScrollDown || tryingToScrollUp;

      const {
        right: canScrollRight,
        left: canScrollLeft,
        down: canScrollDown,
        up: canScrollUp
      } = this.canScroll(selectionWindowElement, selectionWindow);

      const canScrollHorizontal = canScrollRight || canScrollLeft;
      const canScrollVertical = canScrollDown || canScrollUp;

      const scrollTo: { left?: number; top?: number } = {};
      if (tryingToScrollHorizontal && canScrollHorizontal) {
        // if scroll amount will exceed width, change amount to only scroll to boundary
        if (scrollLeft + width + hToScroll >= scrollWidth) {
          hToScroll = scrollWidth - width - scrollLeft;
        }
        if (tryingToScrollRight && canScrollRight) {
          scrollTo.left = scrollLeft + hToScroll;
        } else if (tryingToScrollLeft && canScrollLeft) {
          scrollTo.left = scrollLeft + hToScroll;
        }
      }
      if (tryingToScrollVertical && canScrollVertical) {
        // if scroll amount will exceed height, change amount to only scroll to boundary
        if (scrollTop + height + vToScroll >= scrollHeight) {
          vToScroll = scrollHeight - height - scrollTop;
        }

        if (tryingToScrollDown && canScrollDown && vToScroll > 0) {
          scrollTo.top = scrollTop + vToScroll;
        } else if (tryingToScrollUp && canScrollUp) {
          scrollTo.top = scrollTop + vToScroll;
        }
      }
      // If there are properties in scrollTo then scrolling should occur
      if (Object.keys(scrollTo).length > 0 && this.scrollingEndPoint) {
        // update scrolling endpoint to sync up with new scrollTo location
        if (scrollTo.left) this.scrollingEndPoint.scrollLeft += hToScroll;
        if (scrollTo.top) this.scrollingEndPoint.scrollTop += vToScroll;
        // scroll selection window
        if (selectionWindowElement.scrollTo) selectionWindowElement.scrollTo({ ...scrollTo });
      }
      // else do nothing, can't scroll in any direction
    }
  };

  render() {
    const { children } = this.props;
    const { isMouseDown, dimensions } = this.state;
    return (
      <div
        role="presentation"
        className={classNames('selection-box-selectable-region', {
          mousedown: isMouseDown
        })}
        onMouseDown={this.onMouseDown}
      >
        {children}
        <div className="selection-box-selection-region" style={dimensions || undefined} />
      </div>
    );
  }
}
