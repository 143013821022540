import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import routes from '../../core/routes';
import orgRoutes from '../../organization-management/router/OrgRoutes';
import { ReduxState } from '../../types';
import ProfileMenu from './ProfileMenu';
import UserPreferencesPanel from './user_preferences/UserPreferencesPanel';
import { loadUserProfile as loadUserProfileAction } from '../../auth/actions/auth_actions';
import notification from '../../frontend-common-libs/src/utils/notifications';
import { getUserEmail } from '../../selectors/auth-selectors';

type Props = {
  email: string;
  profileLoaded: boolean;
  loadUserProfile: typeof loadUserProfileAction;
};

export type ProfileMenuContainerState = {
  redirectRoute: string | undefined;
  showUserPreferencesPanel: boolean;
};

export class ProfileMenuContainerImpl extends PureComponent<Props, ProfileMenuContainerState> {
  constructor(props: Props) {
    super(props);

    this.state = {
      redirectRoute: undefined,
      showUserPreferencesPanel: false
    };
  }

  componentDidMount() {
    const { profileLoaded } = this.props;
    if (!profileLoaded) {
      this.loadUserProfile();
    }
  }

  loadUserProfile = async () => {
    try {
      const { loadUserProfile } = this.props;
      await loadUserProfile();
    } catch (error) {
      const errorMessage =
        error instanceof Error ? error.message : 'Unknown error loading user profile';
      notification.error(errorMessage);
    }
  };

  handleLogoutSelected = () => {
    this.setState({
      redirectRoute: routes.LOGOUT
    });
  };

  handleUserProfileSelected = () => {
    this.setState({
      redirectRoute: routes.USER_PROFILE
    });
  };

  handleUserOrganizationsSelected = () => {
    this.setState({
      redirectRoute: orgRoutes.USER_ORGANIZATIONS
    });
  };

  handleShowUserPreferencesPanel = () => {
    const { showUserPreferencesPanel } = this.state;
    this.setState({
      showUserPreferencesPanel: !showUserPreferencesPanel
    });
  };

  handleSettingsSelected = () => {
    this.setState({
      redirectRoute: routes.SETTINGS
    });
  };

  render() {
    const { email } = this.props;
    const { redirectRoute, showUserPreferencesPanel } = this.state;

    if (redirectRoute) {
      this.setState({
        redirectRoute: undefined
      });

      return <Redirect to={redirectRoute} />;
    }

    return (
      <div className="flex-column-container">
        <ProfileMenu
          email={email}
          onUserProfileSelected={this.handleUserProfileSelected}
          onLogoutSelected={this.handleLogoutSelected}
          onShowUserPreferencesPanel={this.handleShowUserPreferencesPanel}
          onUserOrganizationsSelected={this.handleUserOrganizationsSelected}
          onSettingsSelected={this.handleSettingsSelected}
        />
        {showUserPreferencesPanel && (
          <UserPreferencesPanel
            // @ts-ignore
            onBlur={this.handleShowUserPreferencesPanel}
            onClose={this.handleShowUserPreferencesPanel}
          />
        )}
      </div>
    );
  }
}

function mapStateToProps(state: ReduxState) {
  return {
    email: getUserEmail(state),
    profileLoaded: state.auth.get('profileLoaded')
  };
}

export default connect(mapStateToProps, {
  loadUserProfile: loadUserProfileAction
  // @ts-ignore
})(ProfileMenuContainerImpl);
