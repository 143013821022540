import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Alert, Popover, Button, OverlayTrigger } from 'react-bootstrap';
import { Set, Seq, Map } from 'immutable';
import { intlCollator } from '../../../frontend-common-libs/src/utils/commonUtils';
import { ReduxState } from '../../../types';

type Props = {
  plateErrors: Map<string, any>;
};

export class PlateErrorsImpl extends PureComponent<Props> {
  popoverHoverFocus = () => (
    <Popover id="plate-error-list">
      <ul>
        {this.renderPlateEmptyError()}
        {this.renderReplicateGroupError()}
        {this.renderReplicateError()}
        {this.renderConcentrationError()}
        {this.renderCharacterLimitErrors()}
        {this.renderNoFluorsError()}
      </ul>
    </Popover>
  );

  renderConcentrationError = () => {
    const { plateErrors } = this.props;
    const concentrationError = plateErrors.get('concentration');
    return (
      concentrationError && (
        <li>
          {`${
            concentrationError.size === 1
              ? 'This well does not have a valid concentration: '
              : 'These wells do not have a valid concentration: '
          }${concentrationError.keySeq().sort(intlCollator.compare).join(', ')}`}
        </li>
      )
    );
  };

  renderPlateEmptyError = () => {
    const { plateErrors } = this.props;
    const isEmpty = plateErrors.get('plateEmpty');
    return (
      isEmpty && (
        <li>A plate must have at least one well with a fluorophore and sample type defined.</li>
      )
    );
  };

  renderReplicateGroupError = () => {
    const { plateErrors } = this.props;

    const replicateGroupError = plateErrors.get('replicateGroupError');
    return (
      replicateGroupError && (
        <li>
          {`These wells have invalid replicates: ${replicateGroupError
            .reduce(
              (seq: any, wellsByType: any) =>
                wellsByType.reduce(
                  (seqByType: any, wellSeq: any) => seqByType.concat(wellSeq),
                  seq
                ),
              Seq.Indexed()
            )
            .sort(intlCollator.compare)
            .join(', ')}`}
        </li>
      )
    );
  };

  renderReplicateError = () => {
    const { plateErrors } = this.props;
    const replicateError = plateErrors.get('replicate');
    return (
      replicateError && (
        <li>
          {`${
            replicateError.size === 1
              ? 'This well must have a replicate'
              : 'These wells must have replicates'
          } between 1 and 999: ${Set.fromKeys(replicateError)
            // @ts-ignore
            .sort(intlCollator.compare)
            .join(', ')}`}
        </li>
      )
    );
  };

  renderNoFluorsError = () => {
    const { plateErrors } = this.props;
    const noFluorsError = plateErrors.get('noFluorsError');
    return (
      noFluorsError && (
        <li>
          {`${
            noFluorsError.size === 1 ? 'This well' : 'These wells'
          } must include at least one fluorophore: ${Set.fromKeys(noFluorsError)
            // @ts-ignore
            .sort(intlCollator.compare)
            .join(', ')}`}
        </li>
      )
    );
  };

  renderCharacterLimitErrors = () => {
    const { plateErrors } = this.props;
    // Combine target, sample, and bioGroup character limit erros errors
    const channelErrors = plateErrors.get('target');
    const targetErrors =
      channelErrors &&
      channelErrors
        .valueSeq()
        .reduce((allErrors: any, errors: any) => allErrors.merge(errors), Map());
    const sampleErrors = plateErrors.get('sample');
    const bioGroupErrors = plateErrors.get('biogroup');
    const characterLimitErrors = Map()
      .merge(targetErrors)
      .merge(sampleErrors)
      .merge(bioGroupErrors);
    return (
      !characterLimitErrors.isEmpty() && (
        <li>
          {`${
            characterLimitErrors.size === 1 ? 'This well has' : 'These wells have'
          } a target, sample, and/or biological group that exceeds the character limit: ${Set.fromKeys(
            characterLimitErrors
          )
            // @ts-ignore
            .sort(intlCollator.compare)
            .join(', ')}`}
        </li>
      )
    );
  };

  render() {
    const { plateErrors } = this.props;
    const plateHasErrors =
      plateErrors &&
      (plateErrors.get('plateEmpty') ||
        plateErrors.get('replicateGroupError') ||
        plateErrors.get('replicate') ||
        plateErrors.get('concentration') ||
        plateErrors.get('noFluorsError') ||
        plateErrors.get('biogroup') ||
        plateErrors.get('sample') ||
        plateErrors.get('target'));
    return (
      <div>
        {plateHasErrors && (
          <Alert id="plate-error" bsStyle="danger">
            <OverlayTrigger
              trigger={['hover', 'focus']}
              placement="bottom"
              overlay={this.popoverHoverFocus()}
            >
              <Button bsStyle="link">
                <strong>Error! </strong> Plate is not valid. Hover to see details.
              </Button>
            </OverlayTrigger>
          </Alert>
        )}
      </div>
    );
  }
}

function mapStateToProps(state: ReduxState) {
  const plateErrors = state.currentCfxRun.getIn(['errors', 'plateErrors']);
  return {
    plateErrors
  };
}

// @ts-ignore
export default connect(mapStateToProps)(PlateErrorsImpl);
