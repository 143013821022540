import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import NotificationsSettingsToolbar, {
  DeviceTypeNotificationButton
} from './NotificationsSettingsToolbar';
import { ReduxState } from '../../../types';
import getNotificationsSettingsByDeviceType from '../../../selectors/user_preferences_selectors';
import {
  getNotificationsSettingsIfNeeded as getNotificationSettingsIfNeededAction,
  setDeviceType as setDeviceTypeAction,
  subscribeToNotification as subscribeToNotificationAction,
  unsubscribeFromNotification as unsubscribeFromNotificationAction
} from '../../../actions/user_preferences/user_preferences_actions';
import { InstrumentFamilyPluginRepository } from '../../../instrument-family-plugin';
import { getUserEmail } from '../../../selectors/auth-selectors';

type Props = {
  notificationsSettings: Array<any>;
  deviceTypesNotificationsButtons: Array<DeviceTypeNotificationButton>;
  deviceType: string;
  userSubscriptions: Array<string>;
  isUpdatingSubscriptionStatus: boolean;
  userEmail: string;
  subscribeToNotification: typeof subscribeToNotificationAction;
  unsubscribeFromNotification: typeof unsubscribeFromNotificationAction;
  setDeviceType: typeof setDeviceTypeAction;
  getNotificationSettingsIfNeeded: typeof getNotificationSettingsIfNeededAction;
};

export class NotificationsSettingsContainerImpl extends PureComponent<Props, any> {
  handleOnChange = (event: React.SyntheticEvent<HTMLInputElement>) => {
    const { unsubscribeFromNotification, subscribeToNotification, userEmail } = this.props;
    const { id: notificationId, checked } = event.currentTarget;
    if (checked) {
      subscribeToNotification(notificationId, userEmail);
    } else {
      unsubscribeFromNotification(notificationId);
    }
  };

  componentDidMount() {
    const { getNotificationSettingsIfNeeded } = this.props;
    getNotificationSettingsIfNeeded();
  }

  handleShowNotificationsSettings = (deviceType: string) => {
    const { setDeviceType } = this.props;
    setDeviceType(deviceType);
  };

  renderUserPreferenceDetail = () => {
    const { deviceType, notificationsSettings, userSubscriptions, isUpdatingSubscriptionStatus } =
      this.props;
    const plugin =
      InstrumentFamilyPluginRepository.getInstance().getInstrumentFamilyPluginForNotificationDeviceType(
        deviceType
      );
    if (plugin) {
      const UserPreferencesDetails = plugin.getUserPreferenceDetail();
      return (
        <div id="plugin-container">
          <UserPreferencesDetails
            deviceType={deviceType}
            notificationsSettings={notificationsSettings}
            userSubscriptions={userSubscriptions}
            isUpdatingSubscriptionStatus={isUpdatingSubscriptionStatus}
            handleOnChange={this.handleOnChange}
          />
        </div>
      );
    }
    return null;
  };

  render() {
    const { deviceTypesNotificationsButtons, deviceType } = this.props;

    return (
      <>
        <NotificationsSettingsToolbar
          deviceTypesNotificationsButtons={deviceTypesNotificationsButtons}
          showDeviceNotificationsSettings={this.handleShowNotificationsSettings}
          selectedDeviceType={deviceType}
        />
        {this.renderUserPreferenceDetail()}
      </>
    );
  }
}

function mapStateToProps(state: ReduxState) {
  return {
    notificationsSettings: getNotificationsSettingsByDeviceType(state),
    deviceTypesNotificationsButtons: state.userPreferences.get('deviceTypesNotificationsButtons'),
    deviceType: state.userPreferences.get('deviceType'),
    userSubscriptions: state.userPreferences.get('userSubscriptions'),
    isUpdatingSubscriptionStatus: state.userPreferences.get('isUpdatingSubscriptionStatus'),
    userEmail: getUserEmail(state)
  };
}

export default connect(mapStateToProps, {
  subscribeToNotification: subscribeToNotificationAction,
  unsubscribeFromNotification: unsubscribeFromNotificationAction,
  setDeviceType: setDeviceTypeAction,
  getNotificationSettingsIfNeeded: getNotificationSettingsIfNeededAction
})(NotificationsSettingsContainerImpl as any);
