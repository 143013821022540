import React from 'react';
import AlertErrorVerification from '../../../img/alert.svg';
import ResendVerificationCode from './ResendVerificationCode';

export type Props = {
  title: string;
  errorDescription: string;
  username?: string;
};

function AccountConfirmationError({ title, errorDescription, username }: Props) {
  return (
    <div id="error-verification-container">
      <div className="center-content">
        <img src={AlertErrorVerification} alt="Alert Verification" />
      </div>
      <p id="error-title">{title}</p>
      <p id="error-message">{errorDescription}</p>
      {username && <ResendVerificationCode username={username} />}
    </div>
  );
}

AccountConfirmationError.defaultProps = {
  title: '',
  errorDescription: ''
};

export default AccountConfirmationError;
