export type ProtocolTemplatesActionType =
  | 'protocolTemplates_loading'
  | 'protocolTemplates_loaded'
  | 'protocolTemplates_error'
  | 'protocolTemplates_add'
  | 'protocolTemplates_renamed'
  | 'protocolTemplates_deleted'
  | 'protocolTemplates_archived'
  | 'protocolTemplates_restored';

export const PROTOCOL_TEMPLATES_LOADING: ProtocolTemplatesActionType = 'protocolTemplates_loading';
export const PROTOCOL_TEMPLATES_LOADED: ProtocolTemplatesActionType = 'protocolTemplates_loaded';
export const PROTOCOL_TEMPLATES_ERROR: ProtocolTemplatesActionType = 'protocolTemplates_error';
export const PROTOCOL_TEMPLATES_ADD: ProtocolTemplatesActionType = 'protocolTemplates_add';
export const PROTOCOL_TEMPLATES_RENAMED: ProtocolTemplatesActionType = 'protocolTemplates_renamed';
export const PROTOCOL_TEMPLATES_DELETED: ProtocolTemplatesActionType = 'protocolTemplates_deleted';
export const PROTOCOL_TEMPLATES_ARCHIVED: ProtocolTemplatesActionType =
  'protocolTemplates_archived';
export const PROTOCOL_TEMPLATES_RESTORED: ProtocolTemplatesActionType =
  'protocolTemplates_restored';
