import React from 'react';
import HorizontalLine from '../common/HorizontalLine';
import SectionHeader from '../common/SectionHeader';
import { Colors, flexRows, rowStyle } from '../common/styles';
import ErrorSummaryParser from '../sections-parsing/ErrorSummaryParser';

type Props = { error: ErrorSummaryParser };
export default function ErrorSummary(props: Props) {
  const { error } = props;
  return (
    <div id="error-summary" style={flexRows}>
      <SectionHeader text="Error Summary" />
      <span
        style={{
          ...rowStyle,
          color: Colors.dataText,
          marginBottom: '44px',
          marginLeft: '24px'
        }}
      >
        {error.errorText}
      </span>
      <HorizontalLine />
    </div>
  );
}
