import React, { PureComponent } from 'react';
import styles from './styles/helpMenu.module.scss';
import routes from '../routes';
import helpBtnImg from '../../../img/help.svg';
import openTab from '../../utils/windowUtils';
import ButtonMenu, { Header, Separator, TextMenuItem } from '../../common/button_menu';
import { docHelpPaths } from '../../utils/HelpCenter';
import ContextSensitiveHelpLink from './context-sensitive-help-link/ContextSensitiveHelpLink';
import CoreHelpCenterLink from './core-help-center-link/CoreHelpCenterLink';

export type Props = {
  helpPath: string;
};

export default class HelpMenu extends PureComponent<Props> {
  handleContactSupport = () => {
    openTab(routes.CONTACT);
  };

  renderHelpMenuItems = () => {
    const { helpPath } = this.props;
    return (
      <>
        <Header id="help-buttonmenu-header" text="Help & Feedback" />
        <Separator />
        <ContextSensitiveHelpLink path={helpPath} className={styles.menuItem} />
        <CoreHelpCenterLink
          id="help-center-link"
          className={styles.menuItem}
          helpPath={docHelpPaths.default}
        />
        <TextMenuItem text="Contact Support" id="contact" onSelection={this.handleContactSupport} />
      </>
    );
  };

  render() {
    return (
      <ButtonMenu menuId="help-buttonmenu" btnImage={helpBtnImg}>
        {this.renderHelpMenuItems()}
      </ButtonMenu>
    );
  }
}
