import React, { PureComponent, ReactElement } from 'react';
import { Button, Modal } from 'react-bootstrap';

export type Props = {
  children: number | string | ReactElement | Array<any>;
  onSave?: (...args: Array<any>) => any;
  onCancel: (...args: Array<any>) => any;
  isAdd: boolean;
  index: number;
};

class PCRProtocolStepEditorModal extends PureComponent<Props> {
  static defaultProps = { onSave: undefined };

  render() {
    const { onSave, onCancel, isAdd, index, children } = this.props;

    const renderTitle = () => {
      if (isAdd) {
        return 'Add a Step';
      }
      return `Edit STEP ${index + 1}`;
    };

    return (
      <div>
        <Modal
          id="protocol-step-modal"
          className="modal protocol-step-modal fade"
          backdrop="static"
          onHide={onCancel}
          show
        >
          <Modal.Header className="modal-header-own">
            <Modal.Title id="step-editor-title">
              {renderTitle()}
              <Button id="close-modal" className="close" data-dismiss="modal" onClick={onCancel}>
                &times;
              </Button>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="container-fluid">
              <div className="row">{children}</div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="btn-secondary"
              bsStyle="link"
              id="step-cancel-btn"
              onClick={onCancel}
              role="button"
              tabIndex={0}
            >
              Cancel
            </Button>
            <Button
              className="btn-primary"
              bsStyle="link"
              id="step-save-btn"
              onClick={onSave}
              disabled={onSave === undefined}
              role="button"
              tabIndex={0}
            >
              {isAdd ? 'Add step' : 'Save changes'}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default PCRProtocolStepEditorModal;
