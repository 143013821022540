import { NullableString } from './nullable-types';

export const FORM_ERROR_CLASS = 'form-field-invalid form-field-invalid:focus';

export function removeHangulFillerCharacter(value: string): string {
  return value ? value.replace(/\u3164/g, '') : value;
}

export function makeTextRequired(errMessage: string) {
  return (value: string) => {
    const sanitizedString: string = removeHangulFillerCharacter(value);

    if (sanitizedString?.trim()) {
      return undefined;
    }

    return errMessage;
  };
}

export const textRequired = makeTextRequired('Required');

const validEmailExpression = (value: string) => {
  return !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value);
};

export function emailValid(value: string) {
  if (value && validEmailExpression(value)) {
    return 'Invalid email address';
  }

  return undefined;
}

export function emailValidForAuth(value: string) {
  if (value && validEmailExpression(value)) {
    return 'Invalid email address';
  }

  if (value && !/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]+$/.test(value)) {
    return 'Email must be lowercase';
  }

  return undefined;
}

export function hasLowerCaseLetter(value: NullableString): boolean {
  return (value && value.toUpperCase() !== value) || false;
}

export function hasUpperCaseLetter(value: NullableString): boolean {
  return (value && value.toLowerCase() !== value) || false;
}

export function hasNumber(value: NullableString): boolean {
  return (value && /\d/.test(value)) || false;
}

export function hasSpecialCharacter(value: NullableString): boolean {
  return (value && /[\^$*.[\]{}()?\-"!@#%&/\\,><':;|_~`]/g.test(value)) || false;
}

export function lengthTest(name: string, value: string, minLength: number, maxLength: number) {
  if (value && (value.length < minLength || value.length > maxLength)) {
    return `${name} must be at least ${minLength} and at most ${maxLength} characters long`;
  }
  return undefined;
}

export function meetsMinimumLengthRequirement(
  value: NullableString,
  minimumLength: number
): boolean {
  return (value && value.length >= minimumLength) || false;
}

export function meetsMaximumLengthRequirement(
  value: NullableString,
  maximumLength: number
): boolean {
  return (value && value.length <= maximumLength) || false;
}

export function makeMaximumText(errMessage: string, maxLength: number) {
  return (value: string) => {
    if (value && value.length > maxLength) {
      return errMessage;
    }
    return undefined;
  };
}

export function pwdNotEqual(pwd1: string, pwd2: string, notEqual: boolean) {
  if ((pwd1 === pwd2 && notEqual) || (pwd1 !== pwd2 && !notEqual)) {
    return 'Passwords must not be the same';
  }

  return undefined;
}

export function makeRegexValidator(errMessage: string, regex: RegExp) {
  return (value: string) => {
    if (value) {
      const match = value.match(regex);
      if (match) {
        return `${errMessage} "${match[0]}"`;
      }
    }
    return undefined;
  };
}

export function makeFileNameCharacterValidator(errMessage: string) {
  return makeRegexValidator(errMessage, /([<>:"/\\|?*])/g);
}
