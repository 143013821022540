import React, { Component, ReactElement } from 'react';
import { Redirect } from 'react-router-dom';
import classnames from 'classnames';
import { isMac } from '../src/utils/commonUtils';
import notification from '../src/utils/notifications';
import styles from './styles/common-recent-file-row.module.scss';

export type Props = {
  canOpenFile: boolean;
  displayType: string;
  lastTimeUpdated: string;
  name: string;
  icon: ReactElement;
  path: string;
  id: string;
};

export type State = {
  redirectToFile: boolean;
};

export default class CommonRecentFileRow extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      redirectToFile: false
    };
  }

  onFileKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      this.tryOpenFile(event);
    }
  };

  private tryOpenFile = (event: any) => {
    const { canOpenFile, path } = this.props;
    if (canOpenFile) {
      event.preventDefault();
      event.stopPropagation();
      if ((event.ctrlKey && !isMac()) || (event.metaKey && isMac())) {
        window.open(path, '_blank');
      } else {
        this.setState({ redirectToFile: true });
      }
    } else {
      this.notifyCannotOpen();
    }
  };

  private notifyCannotOpen = () => {
    const { name } = this.props;
    notification.info(`"${this.truncateFileName(name)}" cannot be viewed.`);
  };

  private truncateFileName = (fileName: string): string => {
    const maxLength = 27;
    return fileName.length > maxLength ? `${fileName.substring(0, maxLength)}…` : fileName;
  };

  render() {
    const { id, icon, path, name, canOpenFile, displayType, lastTimeUpdated } = this.props;

    const { redirectToFile } = this.state;
    if (redirectToFile) {
      return <Redirect push to={path} />;
    }

    return (
      <tr
        id={`file-item_${id}`}
        className={classnames(styles.fileRow, { [styles.link]: canOpenFile }, 'file-row link')}
        onClick={this.tryOpenFile}
        tabIndex={0}
        onKeyDown={this.onFileKeyDown}
      >
        <td className={classnames(styles.fileIconCol, 'file-icon-col')}>{icon}</td>
        <td className={classnames(styles.fileNameCol, 'file-name-col')} title={name}>
          {canOpenFile ? (
            <a
              href={path}
              id={`file-link_${id}`}
              className={classnames(styles.fileRowName, 'file-row-name')}
              tabIndex={-1}
              onClick={this.tryOpenFile}
            >
              {name}
            </a>
          ) : (
            name
          )}
        </td>
        <td className={classnames(styles.fileTypeCol, 'file-type-col')}>{displayType}</td>
        <td className={classnames(styles.fileLastUpdatedCol, 'file-last-updated-col')}>
          {lastTimeUpdated}
        </td>
      </tr>
    );
  }
}
