import {
  hasLowerCaseLetter,
  hasUpperCaseLetter,
  hasNumber,
  meetsMinimumLengthRequirement,
  meetsMaximumLengthRequirement,
  hasSpecialCharacter
} from '../../../frontend-common-libs/src/common/form_validators';

// Password validation constants
const MIN_PWD_LENGTH = 8;
const MAX_PWD_LENGTH = 50;

export default class PasswordValidator {
  constructor(password = '') {
    this._password = password;
    this._minimumLength = MIN_PWD_LENGTH;
    this._maximumLength = MAX_PWD_LENGTH;
  }

  _password: string | null | undefined;

  _minimumLength: number;

  _maximumLength: number;

  get minimumLength() {
    return this._minimumLength;
  }

  get maximumLength() {
    return this._maximumLength;
  }

  hasLowerCaseLetter = (): boolean => hasLowerCaseLetter(this._password);

  hasUpperCaseLetter = (): boolean => hasUpperCaseLetter(this._password);

  hasNumber = (): boolean => hasNumber(this._password);

  hasSpecialCharacter = (): boolean => hasSpecialCharacter(this._password);

  meetsMinimumLengthRequirement = (): boolean =>
    meetsMinimumLengthRequirement(this._password, this._minimumLength);

  meetsMaximumLengthRequirement = (): boolean =>
    meetsMaximumLengthRequirement(this._password, this._maximumLength);

  isValid = () =>
    this.hasLowerCaseLetter() &&
    this.hasUpperCaseLetter() &&
    this.hasNumber() &&
    this.hasSpecialCharacter() &&
    this.meetsMinimumLengthRequirement() &&
    this.meetsMaximumLengthRequirement();
}
