import React, { ChangeEvent, PureComponent } from 'react';
import { List, Map } from 'immutable';
import { getPlateErrors } from '../../../utils/microplateUtils';

export type SamplesInputListProps = {
  samplePlaceholders: List<Map<string, any>>;
  updatePlate: (plate: Map<string, any>, plateErrors: Map<string, any>) => void;
  plate: Map<string, any>;
};

type SamplesInputListState = {
  numberOfSamples: number;
};

export default class SamplesInputList extends PureComponent<
  SamplesInputListProps,
  SamplesInputListState
> {
  private readonly defaultValue: string;

  constructor(props: SamplesInputListProps) {
    super(props);
    const samplesInPlate = this.samplesInPlate();
    this.defaultValue = samplesInPlate.join('\n');
    this.state = {
      numberOfSamples: samplesInPlate.length
    };
  }

  private samplesInPlate(): string[] {
    const { plate, samplePlaceholders } = this.props;
    const samples: string[] = [];
    samplePlaceholders.forEach(samplePlaceholder => {
      const wellId = samplePlaceholder.getIn(['wellIds', 0]);
      // @ts-ignore
      samples.push(plate.getIn(['wells', wellId, 'sample']) || '');
    });
    SamplesInputList.removeTrailingEmptyStrings(samples);
    return samples;
  }

  private static removeTrailingEmptyStrings(values: string[]): void {
    while (values.length && !values[values.length - 1]) {
      values.pop();
    }
  }

  private handleSampleListChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const { value: enteredSampleNames } = e.currentTarget;
    const splitSampleNames = SamplesInputList.splitSamplesByNewline(enteredSampleNames);
    const samples = SamplesInputList.trimSamples(splitSampleNames);
    this.setState({
      numberOfSamples: samples.length
    });

    this.updatePlate(samples);
  };

  private updatePlate = (samples: string[]) => {
    const { updatePlate } = this.props;
    const plateWithSamples = this.updatePlateWithSamples(samples);
    updatePlate(plateWithSamples, getPlateErrors(plateWithSamples));
  };

  private updatePlateWithSamples(splitSampleNames: string[]): Map<string, any> {
    const { samplePlaceholders, plate } = this.props;

    let plateWithSamples = plate;
    samplePlaceholders.forEach((samplePlaceholder, i) => {
      const wellIds = samplePlaceholder.get('wellIds');
      wellIds.forEach((wellId: string) => {
        const sample = splitSampleNames[i];
        if (!sample) {
          plateWithSamples = plateWithSamples.deleteIn(['wells', wellId, 'sample']);
        } else {
          plateWithSamples = plateWithSamples.setIn(['wells', wellId, 'sample'], sample);
        }
      });
    });
    return plateWithSamples;
  }

  private static trimSamples(sampleNames: string[]) {
    const trimmedSamples = sampleNames.map(s => s.trim());
    SamplesInputList.removeTrailingEmptyStrings(trimmedSamples);
    return trimmedSamples;
  }

  private static splitSamplesByNewline(sampleNames: string): string[] {
    return sampleNames.split('\n');
  }

  private renderError = () => {
    const { numberOfSamples } = this.state;
    const { samplePlaceholders } = this.props;
    const excessSamplesCount = numberOfSamples - samplePlaceholders.size;
    return (
      excessSamplesCount > 0 && (
        <div id="too-many-samples-error" className="error-message">
          List contains too many samples. {excessSamplesCount}{' '}
          {excessSamplesCount > 1 ? 'samples' : 'sample'} will be omitted from the plate.
        </div>
      )
    );
  };

  render() {
    return (
      <div className="controls-container">
        <p className="label-content">Sample List</p>
        <textarea
          id="samples-input-list"
          onChange={this.handleSampleListChange}
          defaultValue={this.defaultValue}
          placeholder="Enter or paste sample list here"
        />
        {this.renderError()}
      </div>
    );
  }
}
