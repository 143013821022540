// @ts-nocheck
import { Map } from 'immutable';
import { getFluorColor, getTargetColorByIndex } from './microplateUtils';

export default function getTraceColor(
  name: string,
  plateTargets: Map,
  mode: string,
  channel?: string
): string {
  // mode property needed for scenario where target name matches a flour value
  const plateTargetIndex = plateTargets && plateTargets.get(name);
  if (mode === 'target' && plateTargetIndex !== undefined) {
    return getTargetColorByIndex(plateTargetIndex).get('primary');
  }
  return getFluorColor(name, channel && channel.toString()).get('primary');
}
