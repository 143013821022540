import request from '../utils/httpUtils';
import getEnvironmentConfig from '../config/EnvironmentConfiguration';

const { apiDomain } = getEnvironmentConfig();

export const baseURL = `https://access-control.${apiDomain}`;
export const accessControlBasePath = '/api/access-control';

export const basePath = `${accessControlBasePath}/file`;

export async function getFileHistory(entityId: string): Promise<any> {
  const path = `${basePath}/file-history/${entityId}`;
  const response = await request.get(baseURL, {}, path, {});
  return response.data;
}
