import React, { Component } from 'react';
import Loader from '../../frontend-common-libs/src/components/common/Loader';

export type Props = {
  getData: () => any;
  children: (...args: Array<any>) => any;
};

type State = {
  data: any | null | undefined;
  isLoading: boolean;
};

export default class DataLoader extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { isLoading: true, data: undefined };
  }

  componentDidMount() {
    this.getData();
  }

  getData = async () => {
    let data;
    try {
      const { getData } = this.props;
      data = await getData();
    } catch (err) {
      data = undefined;
    }
    this.setState({ data, isLoading: false });
  };

  render() {
    const { isLoading, data } = this.state;
    if (isLoading) {
      return <Loader />;
    }
    const { children } = this.props;
    return children(data);
  }
}
