import IoTConnectionImpl from '../../../frontend-common-libs/src/iot/IoTConnection';

export default class IoTConnection {
  private static _instance: IoTConnectionImpl | undefined = undefined;

  public static get instance(): IoTConnectionImpl {
    if (this._instance == null) this._instance = new IoTConnectionImpl();
    return this._instance;
  }
}
