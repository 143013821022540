import {
  PROTOCOL_TEMPLATES_ADD,
  PROTOCOL_TEMPLATES_ARCHIVED,
  PROTOCOL_TEMPLATES_RENAMED,
  PROTOCOL_TEMPLATES_RESTORED,
  ProtocolTemplatesActionType
} from '../protocolTemplate_types';
import { Dispatch } from '../../types';
import { entityActionEnum } from '../../frontend-common-libs/src/iot/entityActionEnum';

export default class ProtocolIotHandler {
  static onIotMessage(entity: any, dispatch: Dispatch<ProtocolTemplatesActionType, any>) {
    if (entity.action === entityActionEnum.updateEntity)
      ProtocolIotHandler.dispatchProtocolUpdate(entity, dispatch);
    if (entity.action === entityActionEnum.addEntity)
      ProtocolIotHandler.dispatchProtocolAdd(entity, dispatch);
    if (entity.action === entityActionEnum.archiveEntity)
      ProtocolIotHandler.dispatchProtocolArchive(entity, dispatch);
    if (entity.action === entityActionEnum.restoreEntity)
      ProtocolIotHandler.dispatchProtocolRestore(entity, dispatch);
  }

  static dispatchProtocolUpdate(entity: any, dispatch: Dispatch<ProtocolTemplatesActionType, any>) {
    dispatch({
      type: PROTOCOL_TEMPLATES_RENAMED,
      payload: {
        id: entity.id,
        faEntity: entity
      }
    });
  }

  static dispatchProtocolAdd(entity: any, dispatch: Dispatch<ProtocolTemplatesActionType, any>) {
    dispatch({
      type: PROTOCOL_TEMPLATES_ADD,
      payload: {
        entity,
        projectId: entity.parent_id
      }
    });
  }

  static dispatchProtocolArchive(
    entity: any,
    dispatch: Dispatch<ProtocolTemplatesActionType, any>
  ) {
    dispatch({
      type: PROTOCOL_TEMPLATES_ARCHIVED,
      payload: { entities: [entity] }
    });
  }

  static dispatchProtocolRestore(
    entity: any,
    dispatch: Dispatch<ProtocolTemplatesActionType, any>
  ) {
    dispatch({
      type: PROTOCOL_TEMPLATES_RESTORED,
      payload: { entities: [entity] }
    });
  }
}
