import jwt from 'jsonwebtoken';
import InvalidEntityJWTError from './InvalidEntityJWTError';

type TokenPermissionKey = 'w';
type TokenPermission = {
  [permission in TokenPermissionKey]: Array<string>;
};

type TokenContents = {
  iss: string;
  exp: number;
  iat: number;
  kid: string;
  jti: string;
  perm: TokenPermission;
};

export default class EntityJWTCache {
  constructor() {
    this.cache = new Map();
  }

  cache: Map<string, string>;

  private static isExpired(decodedToken: TokenContents): boolean {
    const dateNow = new Date();
    const dateNowSec = Math.floor(dateNow.getTime() / 1000);
    return decodedToken.exp < dateNowSec;
  }

  private static getContainedEntityList(decodedToken: TokenContents): string[] {
    const ids: string[] = [];
    Object.keys(decodedToken.perm).forEach(permKeyString => {
      const permKey = permKeyString as TokenPermissionKey;
      const idList = decodedToken.perm[permKey];
      ids.push(...idList);
    });
    return ids;
  }

  private static decodeToken(token: string): TokenContents {
    const unTypedDecodedToken = jwt.decode(token);
    if (unTypedDecodedToken === null || typeof unTypedDecodedToken === 'string') {
      throw new InvalidEntityJWTError(`JWT was: ${unTypedDecodedToken}`);
    }
    return unTypedDecodedToken as TokenContents;
  }

  addToken(token: string) {
    const decodedToken = EntityJWTCache.decodeToken(token);
    EntityJWTCache.getContainedEntityList(decodedToken).forEach(id => this.cache.set(id, token));
  }

  getToken(entityId: string) {
    const token = this.cache.get(entityId);
    if (!token) {
      return null;
    }

    const decodedToken = EntityJWTCache.decodeToken(token);
    if (EntityJWTCache.isExpired(decodedToken)) {
      this.cleanupExpiredTokens(EntityJWTCache.getContainedEntityList(decodedToken), token);
      return null;
    }
    return token;
  }

  private cleanupExpiredTokens(entityIdList: Array<string>, token: string) {
    entityIdList.forEach(id => {
      const currentToken = this.cache.get(id);
      if (currentToken === token) {
        this.cache.delete(id);
      }
    });
  }
}
