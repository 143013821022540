import {
  getDurationFormat,
  gmtStringToDateTimeFormat,
  gmtStringToDateTimeSecFormat
} from '../../../../../../frontend-common-libs/src/common/display-formats';
import { NullableString } from '../../../../../../frontend-common-libs/src/common/nullable-types';

export enum RunErrorState {
  POWER_FAILURE = '0',
  NO_ERRORS = '1',
  CRITICAL_ERROR = '2',
  RUN_STOPPED_BY_USER = '3'
}

export default class InfoParser {
  public readonly startDateTime: string;

  public readonly endDateTime: string;

  public readonly duration: NullableString;

  public readonly startTimeReport: string;

  public readonly endTimeReport: string;

  public readonly userName: string;

  public runStatus: string[] = [];

  public runErrorState: string;

  constructor(runReport: any) {
    this.startDateTime = gmtStringToDateTimeFormat(runReport.startDateTime);
    this.endDateTime = gmtStringToDateTimeFormat(runReport.endDateTime);
    const durationSec = Number(runReport.elapsedTime);
    this.duration = getDurationFormat(durationSec);
    this.startTimeReport = gmtStringToDateTimeSecFormat(runReport.startDateTime);
    this.endTimeReport = gmtStringToDateTimeSecFormat(runReport.endDateTime);
    this.userName = runReport.userName;
    this.runErrorState = runReport.runErrorState;
    this.runStatus.push(runReport.runStatus);
    if (
      runReport.runStatus2 !== null &&
      runReport.runStatus2 !== undefined &&
      runReport.runStatus2 !== ''
    )
      this.runStatus.push(runReport.runStatus2);
    if (!this.runStatus || !this.userName) throw Error('Missing info properties');
  }
}
