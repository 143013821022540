import React, { PureComponent } from 'react';
import { Map, Set } from 'immutable';
import Hashes from 'jshashes';
import plateHelpers from '../../../helpers/Plate';
import {
  defaultWell,
  getPlateErrors,
  getSelectedWells,
  getValuesInWells,
  updateChannelMap
} from '../../../utils/microplateUtils';
import FluorophoreList from './FluorophoreList';
import WellType from './WellType';
import WellName from './WellName';
import Replicate from './Replicate';

export type WellEditorProps = {
  plate: Map<string, any>;
  selectedWellIds: Set<string>;
  plateErrors: Map<string, any>;
  updatePlate: (plate: Map<string, any>, plateErrors: Map<string, any>) => void;
};

type WellEditorState = {
  selectedWellIds: Set<string>;
  samples: Set<string>;
  targets: Set<string>;
  bioGroups: Set<string>;
};

export default class WellEditor extends PureComponent<WellEditorProps, WellEditorState> {
  constructor(props: WellEditorProps) {
    super(props);
    this.state = {
      ...WellEditor.getSuggestions(props)
    };
  }

  static getSuggestions = (props: WellEditorProps) => {
    const wells = plateHelpers.wells(props.plate);
    return {
      selectedWellIds: props.selectedWellIds,
      samples: getValuesInWells(wells, 'sample'),
      targets: getValuesInWells(wells, 'target'),
      bioGroups: getValuesInWells(wells, 'biogroup')
    };
  };

  static getDerivedStateFromProps(nextProps: WellEditorProps, prevState: WellEditorState) {
    if (!nextProps.selectedWellIds.equals(prevState.selectedWellIds)) {
      return WellEditor.getSuggestions(nextProps);
    }
    return null;
  }

  updatePlate = (plate: Map<string, any>) => {
    const { updatePlate } = this.props;
    updatePlate(plate, getPlateErrors(plate));
  };

  handleDelete = (keyToDelete: string) => {
    const { plate, selectedWellIds } = this.props;
    let modifiedPlate = plate;
    selectedWellIds.forEach(wellID => {
      modifiedPlate = modifiedPlate.deleteIn(['wells', wellID, keyToDelete]);
    });
    this.updatePlate(modifiedPlate);
  };

  handleChanges = (newProperties: Map<string, any>) => {
    const { plate, selectedWellIds } = this.props;
    let newPlate = plate;
    selectedWellIds.forEach(wellID => {
      let wellSettings = plate.getIn(['wells', wellID]) || defaultWell();

      if (
        newProperties.get('type') &&
        newProperties.get('type') !== 'standard' &&
        // @ts-ignore
        wellSettings.get('type') === 'standard'
      ) {
        // if a standard well type is changed then remove concentration
        // @ts-ignore
        wellSettings = wellSettings.set(
          'fluors',
          // @ts-ignore
          wellSettings
            .get('fluors')
            .map((channel: Map<string, any>) => channel.delete('concentration'))
        );
      }
      // @ts-ignore
      wellSettings = wellSettings.merge(newProperties);
      newPlate = newPlate.setIn(['wells', wellID], wellSettings);
    });
    this.updatePlate(newPlate);
  };

  renderReplicate = (selectedWells: Map<string, any>) => {
    const { plateErrors, selectedWellIds } = this.props;
    const replicateErrors = plateErrors && plateErrors.get('replicate');
    return (
      <div className="flex-wrapper margin-bottom-10">
        <div className="flex-item control-content">
          <Replicate
            handleDelete={this.handleDelete}
            selectedWellIds={selectedWellIds}
            selectedWells={selectedWells}
            handleChanges={this.handleChanges}
            replicateErrors={replicateErrors}
          />
        </div>
      </div>
    );
  };

  getSelectionKey = () => {
    // Make the key unique for each selection so that if the selection changes it'll re-construct components
    const { selectedWellIds } = this.props;
    return new Hashes.SHA1().b64(selectedWellIds.join('-'));
  };

  updateWells = (wells: Map<string, any>, shouldUpdateChannelMap = false) => {
    const { plate } = this.props;
    let newPlate = plate.set('wells', plate.get('wells').merge(wells));
    if (shouldUpdateChannelMap) newPlate = updateChannelMap(newPlate);
    this.updatePlate(newPlate);
  };

  public render() {
    const selectionKey = this.getSelectionKey();
    const { plate, plateErrors, selectedWellIds } = this.props;
    if (selectedWellIds.size === 0) {
      return <span id="no-wells-selected">No wells selected.</span>;
    }
    const { size, scanMode: getScanMode } = plateHelpers;
    const plateSize = size(plate);
    const scanMode = getScanMode(plate);
    const selectedWells = getSelectedWells(plate, selectedWellIds);
    const { targets, samples, bioGroups } = this.state;
    return (
      <div className="controls-container" key={selectionKey}>
        <FluorophoreList
          plateSize={plateSize}
          scanMode={scanMode}
          selectedWellIds={selectedWellIds}
          selectedWells={selectedWells}
          updateWells={this.updateWells}
          targetErrors={plateErrors && plateErrors.get('target')}
          concentrationErrors={plateErrors && plateErrors.get('concentration')}
          keywords={targets}
        />
        <hr />
        <div className="flex-wrapper">
          <div className="flex-item control-content">
            <WellType
              selectedWellIds={selectedWellIds}
              selectedWells={selectedWells}
              handleChanges={this.handleChanges}
            />
          </div>
          <WellName
            selectedWellIds={selectedWellIds}
            selectedWells={selectedWells}
            handleChanges={this.handleChanges}
            handleDelete={this.handleDelete}
            wellKey="sample"
            labelName="Sample Name"
            id="sample-name"
            errors={plateErrors && plateErrors.get('sample')}
            keywords={samples}
          />
          <WellName
            selectedWellIds={selectedWellIds}
            selectedWells={selectedWells}
            handleChanges={this.handleChanges}
            handleDelete={this.handleDelete}
            wellKey="biogroup"
            labelName="Biological Group"
            id="biological-group"
            errors={plateErrors && plateErrors.get('biogroup')}
            keywords={bioGroups}
          />
        </div>
        {this.renderReplicate(selectedWells)}
      </div>
    );
  }
}
