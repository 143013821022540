import React, { PureComponent } from 'react';
import { Map, Set } from 'immutable';
import classnames from 'classnames';
import AutoSuggest from './AutoSuggest';

type Props = {
  handleChanges: (...args: Array<any>) => any;
  handleDelete: (...args: Array<any>) => any;
  selectedWellIds: Set<string>;
  selectedWells: Map<string, any>;
  wellKey: string;
  labelName: string;
  id: string;
  errors?: Map<string, any>;
  keywords?: Set<string>;
};

class WellNames extends PureComponent<Props> {
  static defaultProps = {
    errors: undefined,
    keywords: undefined
  };

  static isAllEqual = (wells: Map<string, any>, wellKey: string, value: any) =>
    wells.every(well => well.get(wellKey) === value);

  getValue = () => {
    const { selectedWellIds, selectedWells, wellKey } = this.props;

    if (selectedWells.size === 0) return { allEqual: true, value: undefined };

    if (selectedWellIds.size > selectedWells.size) {
      // there are mix of empty and non-empty wells
      // to be all equal, the non-empty wells should all not have wellKey
      const allUndefined = WellNames.isAllEqual(selectedWells, wellKey, undefined);
      return { allEqual: allUndefined, value: undefined };
    }
    const value = selectedWells.first().get(wellKey);
    const allEqual = WellNames.isAllEqual(selectedWells, wellKey, value);
    return {
      allEqual,
      value: allEqual ? value : undefined
    };
  };

  changeName = (name: string) => {
    const { wellKey, handleDelete, handleChanges } = this.props;
    if (!name) {
      handleDelete(wellKey);
    } else {
      const updatedWellProps = Map({
        [wellKey]: name
      });
      handleChanges(updatedWellProps);
    }
  };

  render() {
    const { id, labelName, wellKey, errors, selectedWellIds, keywords } = this.props;
    const { allEqual, value } = this.getValue();
    const errorMessage = (allEqual && errors && errors.get(selectedWellIds.first())) || undefined;
    return (
      <div className="flex-item control-content">
        <p className="label-content">{labelName}</p>
        <div className={classnames('form-group', { 'has-error': errorMessage })}>
          <AutoSuggest
            id={id}
            placeholder={allEqual ? undefined : 'Multiple Values'}
            value={value}
            onChange={this.changeName}
            keywords={keywords}
          />
          {errorMessage && (
            <div id={`${wellKey}-error`} className="error-message">
              {errorMessage}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default WellNames;
