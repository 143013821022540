import { PluginComponents, PluginManifest } from '@biorad-lsg-tsc/instrument-family-plugin';
import InstrumentFamilyPlugin from './InstrumentFamilyPlugin';
import BaseInstrumentFamilyPlugin from './BaseInstrumentFamilyPlugin';
import PluginCreateExperimentCard from './PluginCreateExperimentCard';

export default class LocalInstrumentFamilyPlugin
  extends BaseInstrumentFamilyPlugin
  implements InstrumentFamilyPlugin
{
  protected readonly manifest: PluginManifest;

  private constructor(manifest: PluginManifest, components: PluginComponents) {
    super(manifest, components);

    this.manifest = manifest;
  }

  public get createExperimentCard(): PluginCreateExperimentCard | undefined {
    const { createExperimentCard } = this.manifest;
    if (!createExperimentCard) return undefined;

    return new PluginCreateExperimentCard(createExperimentCard);
  }

  // eslint-disable-next-line class-methods-use-this
  public createInstrumentThumbnailUrl(instrumentThumbnailRelativePath: string): string {
    return instrumentThumbnailRelativePath;
  }

  public static async create(
    manifest: PluginManifest,
    components: PluginComponents
  ): Promise<LocalInstrumentFamilyPlugin> {
    return new LocalInstrumentFamilyPlugin(manifest, components);
  }
}
