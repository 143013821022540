/* eslint-disable react/jsx-props-no-spreading */

import React, { PureComponent, ComponentType } from 'react';
import { parse } from 'query-string';
import { initializeFeatureFlags } from './FeatureFlags';
import FeatureFlagKeys from './FeatureFlagKeys';
import LocalFeatureFlagClient from './LocalFeatureFlagClient';
import Loader from '../common/Loader';

type Props = {
  location: {
    [key: string]: any;
  };
  children: any;
};

type State = {
  isLoading: boolean;
};

export default class FlagLoader extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { isLoading: true };
  }

  componentDidMount() {
    this.loadFeatureFlags();
  }

  paramValueToFlagValue = (paramValue: any): any => {
    const flagValue = Array.isArray(paramValue) ? paramValue[paramValue.length - 1] : paramValue;
    return ['true', 'false'].includes(flagValue.toLowerCase())
      ? flagValue.toLowerCase() === 'true'
      : flagValue;
  };

  getFlagsFromPath = (): {
    [key: string]: any;
  } => {
    const { location } = this.props;
    const queryParams = (location && location.search && parse(location.search)) || {};
    return Object.entries(queryParams).reduce((accum, [param, value]) => {
      return FeatureFlagKeys.isValid(param)
        ? { ...accum, [param]: this.paramValueToFlagValue(value) }
        : accum;
    }, {});
  };

  loadFeatureFlags = async (): Promise<void> => {
    await initializeFeatureFlags();
    Object.entries(this.getFlagsFromPath()).forEach(([flag, value]) => {
      LocalFeatureFlagClient.save(flag, value);
    });
    this.setState({ isLoading: false });
  };

  render() {
    const { isLoading } = this.state;
    const { children } = this.props;

    return isLoading ? <Loader /> : children;
  }
}

export const withFlagLoader = (WrappedComponent: ComponentType<any>) => (props: any) => {
  const { location } = props;
  return (
    <FlagLoader location={location}>
      <WrappedComponent {...props} />
    </FlagLoader>
  );
};
