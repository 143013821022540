import { CSSProperties } from 'react';

let rubikFontWeight = '500';

export function getRubikFontWeight() {
  return rubikFontWeight;
}

export function setRubikFontWeight(fontWeight: string) {
  rubikFontWeight = fontWeight;
}

export const rowStyle = {
  height: '22px',
  fontSize: '16px',
  lineHeight: 1.38,
  fontWeight: 'normal'
};

export const flexRows = { display: 'flex', flexDirection: 'column' } as CSSProperties;

export enum Colors {
  headerText = '#6a6a6a',
  dataText = '#3d3d3d',
  separator = '#979797',
  newDarkGrey = '#3d3d3d',
  brGreen = '#00a650',
  rowBorder = '#ddd',
  rowBackground = '#f9f9f9'
}

export const statusStyle = {
  marginLeft: '24px',
  color: Colors.headerText,
  paddingBottom: '1px',
  marginTop: '30px',
  fontSize: '16px',
  paddingTop: '1px',
  paddingRight: '6px'
};

export const horizontalLine = {
  height: '1px',
  width: '1318px',
  backgroundColor: Colors.separator,
  margin: '17px 1px 22px 0'
};

export function sectionHeaderStyle() {
  return {
    fontSize: '20px',
    fontWeight: getRubikFontWeight(),
    lineHeight: 1.5,
    color: Colors.newDarkGrey,
    display: 'flex',
    marginLeft: '24px',
    marginBottom: '8px',
    textTransform: 'uppercase'
  } as CSSProperties;
}
