import React, { Component } from 'react';

type Props = {
  id: string;
  onClick?: (e: React.MouseEvent<HTMLElement>, wellGroupId: string) => any;

  /** Column or Row Identifier */
  wellGroup: string;
  isAnalysisView: boolean;
  onMouseDown: (e: React.MouseEvent<HTMLElement>) => any;
};

class PlateHeader extends Component<Props> {
  static defaultProps = { onClick: undefined };

  onClick = (e: React.MouseEvent<HTMLElement>) => {
    const { onClick } = this.props;
    if (onClick) {
      const { wellGroup: wellGroupId } = this.props;
      onClick(e, wellGroupId);
    }
  };

  renderComponent() {
    const { id, onClick, wellGroup: wellGroupId, isAnalysisView, onMouseDown } = this.props;

    if (isAnalysisView) {
      return (
        <button
          className="cell-well table-headers"
          id={id}
          type="button"
          onClick={onClick ? this.onClick : undefined}
          onMouseDown={onMouseDown}
        >
          {wellGroupId}
        </button>
      );
    }

    return (
      <div
        className="cell-well table-headers"
        id={id}
        onClick={onClick ? this.onClick : undefined}
        role="presentation"
        onMouseDown={onMouseDown}
      >
        {wellGroupId}
      </div>
    );
  }

  render() {
    return this.renderComponent();
  }
}

export default PlateHeader;
