import React from 'react';
import bioradLogo from '../../../img/new-logo.svg';
import { ExternalAnchorLink } from '../../frontend-common-libs/src/components/common/buttons';

type Props = {
  children?: any;
  className?: string;
};

function LogoHeader({ children, className }: Props) {
  return (
    <div id="header-container" className={className}>
      <header>
        <ExternalAnchorLink
          id="br-logo"
          href="http://www.bio-rad.com"
          target="_blank"
          rel="noopener noreferrer"
          title="http://www.bio-rad.com"
        >
          <img src={bioradLogo} className="logo" alt="Bio-Rad logo" />
        </ExternalAnchorLink>
        {children}
      </header>
    </div>
  );
}

LogoHeader.defaultProps = {
  children: null,
  className: undefined
};

export default LogoHeader;
