// @ts-nocheck
import React from 'react';
import { List } from 'immutable';
import { DropdownButton, MenuItem } from 'react-bootstrap';

type Props = {
  steps: List<number>;
  selectedStep: number | null | undefined;
  changeStep: (...args: Array<any>) => any;
  disabled: boolean;
};

export const StepDropdownImpl = ({ steps, selectedStep, changeStep, disabled }: Props) => (
  <label htmlFor="show-step" className="dropdown-label step-dropdown" disabled={disabled}>
    Step
    <DropdownButton
      bsStyle="primary"
      id="show-step"
      title={selectedStep != null ? selectedStep + 1 : ''}
      className="pcr-step-dropdown"
      onSelect={changeStep}
      pullRight
      disabled={disabled}
    >
      {steps.map(stepNumber => (
        <MenuItem
          eventKey={stepNumber}
          key={stepNumber}
          active={stepNumber === selectedStep}
          className="pcr-step-value"
        >
          {stepNumber + 1}
        </MenuItem>
      ))}
    </DropdownButton>
  </label>
);

export default StepDropdownImpl;
