import Gradient, { GradientLowerTemperature, GradientRange } from '../../Gradient';
import { GradientTemperature, GradientTemperature48 } from '../../gradient-temperature';

export default class Gradient48 extends Gradient {
  constructor(lowerTemp: GradientLowerTemperature, range: GradientRange, isValid: boolean) {
    super(8, lowerTemp, range, isValid, Gradient48.offsetTable, Gradient48.scTable);
  }

  private static readonly offsetTable: Array<Array<number>> = [
    [29.995079, 40.014469, 50.03386, 60.061754, 70.03471, 80.004092, 90.00327, 100.002449],
    [29.961316, 39.99953, 50.037744, 60.087606, 70.110844, 80.130227, 90.166724, 100.20322],
    [29.923565, 39.981947, 50.040329, 60.102069, 70.149556, 80.193608, 90.245932, 100.298256],
    [29.920759, 39.984318, 50.047877, 60.112421, 70.165836, 80.212725, 90.26879, 100.324855],
    [29.945405, 40.004792, 50.064178, 60.128437, 70.179832, 80.226103, 90.282845, 100.339587],
    [29.955926, 40.014608, 50.07329, 60.137296, 70.187091, 80.232394, 90.289572, 100.34675],
    [29.994578, 40.032333, 50.070088, 60.122659, 70.146962, 80.171288, 90.213995, 100.256703],
    [30.064928, 40.064925, 50.064923, 60.087736, 70.060107, 80.030142, 90.037656, 100.04517]
  ];

  private static readonly scTable: Array<Array<number>> = [
    [0.569355, 0.570492, 0.571629, 0.571725, 0.570264, 0.571562, 0.571529, 0.571495],
    [0.489467, 0.490784, 0.492101, 0.492626, 0.492066, 0.492884, 0.493032, 0.493181],
    [0.342364, 0.342986, 0.343609, 0.343967, 0.343454, 0.343947, 0.344085, 0.344223],
    [0.124225, 0.124288, 0.12435, 0.124405, 0.123661, 0.123962, 0.124007, 0.124052],
    [-0.123261, -0.123115, -0.122969, -0.122921, -0.123558, -0.123051, -0.122854, -0.122657],
    [-0.341398, -0.341444, -0.341491, -0.341735, -0.342502, -0.342273, -0.342386, -0.342499],
    [-0.489494, -0.489097, -0.488699, -0.489143, -0.490207, -0.489901, -0.4901, -0.490298],
    [-0.570728, -0.569445, -0.568161, -0.568191, -0.569454, -0.568085, -0.567777, -0.567468]
  ];

  // eslint-disable-next-line class-methods-use-this
  protected createGradientTemperature(
    index: number,
    temperatureValue: number
  ): GradientTemperature {
    return new GradientTemperature48(index, temperatureValue);
  }
}
