import React from 'react';
import classNames from 'classnames';

export type RadioButtonOption = {
  id: string;
  value: string;
  disabled: boolean;
};

export type Props = {
  name: string;
  checked: boolean;
  onChange: (...args: Array<any>) => any;
  children: any;
} & RadioButtonOption;

function RadioButton({ id, name, children, value, disabled, checked, onChange }: Props) {
  return (
    <label
      htmlFor={id}
      className={classNames('br-radio-label', { 'radio-checked': checked })}
      // @ts-ignore
      disabled={disabled}
      type="button"
      tabIndex={-1}
    >
      <input
        id={id}
        type="radio"
        name={name}
        value={value}
        className="br-radio-input"
        disabled={disabled}
        checked={checked}
        tabIndex={0}
        onChange={onChange}
      />
      {children}
    </label>
  );
}

RadioButton.defaultProp = {
  disabled: false
};

export default RadioButton;
