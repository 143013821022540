import getEnvironmentConfig from './EnvironmentConfiguration';

const { COGNITO_CONFIG, IOT_CONFIG } = getEnvironmentConfig().config;
export const API_DOMAIN = getEnvironmentConfig().apiDomain;

export const COGNITO = COGNITO_CONFIG;
export const IOT_MESSAGE_BROKER = {
  ...IOT_CONFIG
};
export const HELP_BASE_DOMAIN = `https://support.${API_DOMAIN}`;
export const HELP_NEXT_BASE_DOMAIN = `https://support-next.dev.brlabcloud.cloud`;

export const GATEWAY = {
  FILEARBITER_PATHS: {
    BASE_URL: `https://file-arbiter.${API_DOMAIN}`,
    UPLOADPATH_URL: '/api/file-arbiter/uploads',
    USERFILES_URL: '/api/file-arbiter/entities',
    DELETEFILES_URL: '/api/file-arbiter/delete-files',
    GET_DOWNLOAD_URL: (id: string) => `/api/file-arbiter/entities/${id}/download-url`,
    ENTITIES_URL: '/api/file-arbiter/entities',
    GET_ENTITY_INFO: (id: string) => `/api/file-arbiter/entities/${id}`,
    DELETE_ENTITIES_URL: '/api/file-arbiter/delete-entities',
    ENTITY_JWT: (id: string) => `/api/file-arbiter/entities/${id}/jwt`,
    ARCHIVE_ENTITIES_URL: '/api/file-arbiter/entities/archive',
    RESTORE_ENTITIES_URL: '/api/file-arbiter/entities/restore',
    PROJECTS_URL: (projectId: string) => `/api/file-arbiter/projects/${projectId}`
  },
  AUTH_SERVICE_PATHS: {
    BASE_URL: `https://auth-service.${API_DOMAIN}`,
    CHANGEPWD_URL: '/api/auth-service/changepwd',
    DEVICE_FLOW_CODE: (userCode: string) => `/api/auth-service/device-flow/code/${userCode}`,
    BACKUP_USER_DATA: '/api/auth-service/backup-user-data',
    API_TOKENS: '/api-portal/apiTokens'
  },
  COGNITO_HOSTED_UI_PATHS: {
    // cognito logout endpoint redirects to login
    INSTRUMENT_LOGIN: `https://auth.${API_DOMAIN}/logout?response_type=code&client_id=${COGNITO_CONFIG.INSTRUMENT_V2_APPCLIENT_ID}&redirect_uri=https://${API_DOMAIN}/link&scope=aws.cognito.signin.user.admin+openid`
  },
  IOT_AUTH_SERVICE_PATHS: {
    BASE_URL: `https://iot.${API_DOMAIN}`,
    GET_CREDENTIALS: '/api/iot/credentials'
  },
  INSTRUMENT_PATHS: {
    BASE_URL: `https://instruments.${API_DOMAIN}`,
    GET_LIST: '/api/instruments',
    UNLINK_INSTRUMENT: (instrumentId: string) => `/api/instruments/${instrumentId}`
  },
  QPCRDATA_SERVICE_PATHS: {
    BASE_URL: `https://qpcr-api.${API_DOMAIN}`,
    GETPCRDATA_URL: (id: string) => `/api/qpcr-api/${id}`,
    CREATE_RUN_URL: '/api/qpcr-api',
    GET_RUNS: '/api/qpcr-api',
    GET_RUN_PLATE: (runId: string) => `/api/qpcr-api/${runId}/plate`,
    GET_RUN_LOG: (runId: string) => `/api/qpcr-api/${runId}/log`,
    POST_COMPUTE_AMP_STEP_DATA_URL: (entityId: string, stepNumber: number) =>
      `/api/qpcr-api/${entityId}/amp-analysis/${stepNumber}`,
    POST_COMPUTE_MELT_STEP_DATA_URL: (entityId: string, stepNumber: number) =>
      `/api/qpcr-api/${entityId}/melt-analysis/${stepNumber}`,
    GENERATE_PCRD_URL: (id: string) => `/api/qpcr-api/generate-pcrd/${id}`,
    GET_GENERATE_PCRD_POLLING: (requestId: string) => `/api/qpcr-api/get-pcrd/${requestId}`,
    CREATE_RUN_TEMPLATE: '/api/qpcr-api/template',
    GET_RUN_TEMPLATE: (entityId: string) => `/api/qpcr-api/template/${entityId}`,
    CLONE_AND_UPDATE: `/api/qpcr-api/clone-and-update`,
    CREATE_PROTOCOL_FROM_FILE: `/api/qpcr-api/protocol`
  },
  PCR_PROTOCOL_SERVICE_PATHS: {
    BASE_URL: `https://pcr-protocol.${API_DOMAIN}`,
    GET_PROTOCOL_URL: (id: string) => `/api/pcr-protocol/${id}`,
    PUT_PROTOCOL_URL: (id: string) => `/api/pcr-protocol/${id}`,
    CREATE_PROTOCOL_URL: '/api/pcr-protocol'
  },
  CPCR_SERVICE_PATHS: {
    BASE_URL: `https://cpcr-service.${API_DOMAIN}`,
    CREATE_JOB_URL: '/api/cpcr-service/job/protocol',
    CREATE_PROTOCOL_URL: '/api/cpcr-service/protocol',
    CREATE_PROTOCOL_FROM_FILE_URL: '/api/cpcr-service/protocol-file',
    DELETE_JOB_URL: (id: string) => `/api/cpcr-service/job/${id}`,
    GET_JOB_PROTOCOL_URL: (id: string) => `/api/cpcr-service/job/${id}/protocol-name`,
    GET_PROTOCOL_URL: (id: string) => `/api/cpcr-service/protocol/${id}`,
    GET_RUN_URL: (id: string) => `/api/cpcr-service/view-run/${id}`,
    GET_PROTOCOL_FROM_RUN_URL: (id: string) => `/api/cpcr-service/run/${id}/protocol`
  },
  CUSTOMER_SUPPORT_SERVICE: {
    BASE_URL: `https://customer-support.${API_DOMAIN}`,
    POST_CUSTOMER_SUPPORT: '/api/customer-support',
    GET_MAINTENANCE_MESSAGE: '/api/customer-support/maintenance-message'
  },
  EAP_SERVICE: {
    BASE_URL: `https://eap.${API_DOMAIN}`,
    JOIN_EAP: '/api/eap/join'
  },
  NOTIFICATIONS_PATHS: {
    BASE_URL: `https://notifications.${API_DOMAIN}`,
    GET_NOTIFICATIONS_SETTINGS_URL: '/api/notifications/settings',
    POST_NOTIFICATION_SUBSCRIPTION_URL: '/api/notifications/subscriptions',
    DELETE_NOTIFICATION_SUBSCRIPTION_URL: (id: string) => `/api/notifications/subscriptions/${id}`,
    GET_USER_SUBSCRIPTIONS_URL: '/api/notifications/subscriptions'
  }
};
