import InstrumentGroupCredentials from './instrument-group-credentials';
import TopicCredentials from './credentials/topic-credentials';

export default class IOTCredentials {
  static CREDENTIALS_EXPIRATION_WINDOW = 15000;

  response: any;

  constructor(response: any) {
    this.response = response;
  }

  get topicCredentials() {
    return new TopicCredentials(this.response.topicCredentials);
  }

  get instrumentGroupCredentialsCollection(): InstrumentGroupCredentials[] {
    return this.response.deviceCredentials.map((x: any) => new InstrumentGroupCredentials(x));
  }

  get expiration() {
    return Date.parse(this.response.expiration);
  }

  expired() {
    return this.expiration - Date.now() <= IOTCredentials.CREDENTIALS_EXPIRATION_WINDOW;
  }

  validFor() {
    return this.expiration - Date.now() - IOTCredentials.CREDENTIALS_EXPIRATION_WINDOW;
  }
}
