import React from 'react';
import styles from '../style/setting-page.module.scss';

export type Props = {
  text: string;
  id: string;
  isSelected: boolean;
};

export default function SettingsMenuItem(props: Props) {
  const { text, id, isSelected } = props;

  return (
    <div className={styles.settingContainerMenuItem} id={`${id}-link`}>
      <button className={styles.settingMenuButton} name={text}>
        <label aria-selected={isSelected}>{text}</label>
      </button>
    </div>
  );
}
