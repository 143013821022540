import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import LetterSvg from '../../../img/letter.svg';
import { ReduxState } from '../../types';
import UnAuthedFooter from './UnAuthedFooter';
import ResendVerificationCode from './ResendVerificationCode';
import UserAccessedEmailVerificationPageEvent from '../../user-analytics/email-verification/UserAccessedEmailVerificationPageEvent';
import WithBioradIcons from './WithBioradIcons';

interface State {
  isSendingCode: boolean;
}

export type Props = {
  username: string;
  isNewUser: boolean;
};

export class VerifyAccountImpl extends PureComponent<Props, State> {
  private renderVerifyAccountTitle = () => {
    const { isNewUser } = this.props;
    const titleMessage = isNewUser ? 'Welcome to BR.io!' : 'Please Verify Your Email Address';
    return (
      <>
        <span id="verify-account-title">{titleMessage}</span>
        <br />
      </>
    );
  };

  private renderVerifyAccountMessage = (username: string) => {
    const { isNewUser } = this.props;

    return (
      <WithBioradIcons>
        <>
          <img src={LetterSvg} alt="" id="verification-email-img" />
          <div className="description">
            <div className="title-container">{this.renderVerifyAccountTitle()}</div>
            <div id="title-separator" />
            {isNewUser && <div id="thanks-message">Thank you for registering with BR.io!</div>}
            We have sent an email to&nbsp;
            <span className="user-email">{username}</span>&nbsp;with a link to activate your BR.io
            account. When you receive the email, follow the instructions provided.&nbsp;After
            activating your account, you can connect your lab and experiments to the Cloud.&nbsp;
            <div className="notification-title">
              The email should arrive shortly. If you do not receive the email, the following might
              have occurred:
            </div>
            <ul>
              <li>The email was sent to your Spam/Junk folder. If so, move it to your Inbox.</li>
              <li>
                The email was rejected by your email server in accordance with your company email
                policies. Reregister with a different email address.
              </li>
            </ul>
            <br />
            <ResendVerificationCode username={username} />
          </div>
        </>
      </WithBioradIcons>
    );
  };

  componentDidMount() {
    new UserAccessedEmailVerificationPageEvent().track();
  }

  render() {
    const { username } = this.props;

    return (
      <>
        <div id="verify-account">
          <div className="container">
            <div id="content">
              <div className="message-container">{this.renderVerifyAccountMessage(username)}</div>
            </div>
          </div>
        </div>
        <UnAuthedFooter className="content-container" />
      </>
    );
  }
}

const mapStateToProps = (state: ReduxState) => {
  return {
    username: state.auth.get('username'),
    isNewUser: state.auth.get('isNewUser')
  };
};

export default connect(mapStateToProps)(VerifyAccountImpl as any);
