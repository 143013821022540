import React, { FormEvent } from 'react';
import { Alert, Dropdown, FormControl, MenuItem } from 'react-bootstrap';
import { Map } from 'immutable';
import TitleFieldGroup from '../../common/TitleFieldGroup';
import Protocol from './charts/Protocol';
import PCRProtocolText from './PCRProtocolText';
import NavigationBlockPrompt from '../../common/NavigationBlockPrompt';
import Loader from '../../common/Loader';
import ProtocolSettings from './ProtocolSettings';
import ProgressButton from '../../common/ProgressButton';
import { PrimaryButton, SecondaryButton } from '../../common/buttons';
import QPcrProtocol from './models/QPcrProtocol';
import ProtocolValidator from './models/ProtocolValidator';

export type Props = {
  loadProtocol: () => any;
  enableApplyIfNoChanges: boolean;
  applyButtonText?: string;
  onApplyPressed?: (protocol: QPcrProtocol) => Promise<void>;
  onCancelPressed?: () => void;
  disabled: boolean;
  showProtocolName: boolean;
  showNextButton: boolean;
  nextButtonText?: string;
  onNextPressed?: () => void;
  isPcr?: boolean;
  reloadCounter?: number;
};
export type State = {
  selectedIndex: number;
  editingStepIndex: number;
  isAdd: boolean;
  isLoading: boolean;
  wasEdited: boolean;
  pcrProtocol: QPcrProtocol;
};

export class PcrProtocolEditor extends React.Component<Props, State> {
  private readonly protocolValidator: ProtocolValidator;

  static defaultProps = {
    disabled: false,
    enableApplyIfNoChanges: false,
    showProtocolName: true,
    showNextButton: false
  };

  constructor(props: Props) {
    super(props);

    const maxProtocolStepsSize = props.isPcr ? 50 : 97;
    this.protocolValidator = new ProtocolValidator(maxProtocolStepsSize);

    this.state = {
      selectedIndex: 0,
      editingStepIndex: -1,
      isAdd: false,
      wasEdited: false,
      pcrProtocol: QPcrProtocol.empty(),
      isLoading: true
    };
  }

  async componentDidMount() {
    await this.loadProtocol();
  }

  async componentDidUpdate(prevProps: Props) {
    const { reloadCounter } = this.props;
    if (reloadCounter && prevProps.reloadCounter && prevProps.reloadCounter < reloadCounter) {
      await this.reloadProtocol();
    }
  }

  loadProtocol = async () => {
    const { loadProtocol } = this.props;
    this.setState(
      {
        isLoading: true
      },
      async () => {
        const pcrProtocol = await loadProtocol();
        this.setState({
          isLoading: false,
          pcrProtocol
        });
      }
    );
  };

  // eslint-disable-next-line react/no-unused-class-component-methods
  reloadProtocol = async () => {
    await this.loadProtocol();
    this.setState({ wasEdited: false });
  };

  setSelectedStepIndex = (index: number) => {
    const { pcrProtocol, selectedIndex } = this.state;
    if (index >= 0 && index < pcrProtocol.steps.size && index !== selectedIndex) {
      this.setState({
        selectedIndex: index
      });
    }
  };

  nameChanged = (event: FormEvent<FormControl>) => {
    // @ts-ignore
    const newProtocolName = event.target.value as string;

    let { pcrProtocol } = this.state;
    pcrProtocol = pcrProtocol.updateName(newProtocolName);
    this.protocolValidator.validateName(pcrProtocol.name);

    this.setState({
      pcrProtocol,
      wasEdited: pcrProtocol.hasChanges()
    });
  };

  editStep = (index: number) => {
    this.setState({
      selectedIndex: index,
      editingStepIndex: index,
      isAdd: false
    });
  };

  addStepBefore = () => {
    const { selectedIndex } = this.state;
    this.addStepAtIndex(selectedIndex);
  };

  addStepAfter = () => {
    const { selectedIndex } = this.state;
    this.addStepAtIndex(selectedIndex + 1);
  };

  deleteStep = (index: number) => {
    let { pcrProtocol } = this.state;
    const { isPcr } = this.props;

    pcrProtocol = pcrProtocol.deleteStep(index);
    const protocolStepSize = pcrProtocol.steps.size;
    this.protocolValidator.validateStepsSize(protocolStepSize, isPcr);

    const { selectedIndex } = this.state;
    const newSelectedIndex =
      selectedIndex >= protocolStepSize ? protocolStepSize - 1 : selectedIndex;
    this.setState({
      pcrProtocol,
      isAdd: false,
      selectedIndex: newSelectedIndex < 0 ? 0 : newSelectedIndex,
      wasEdited: pcrProtocol.hasChanges()
    });
  };

  applyPressed = async () => {
    const { onApplyPressed } = this.props;
    const { pcrProtocol } = this.state;
    if (onApplyPressed) {
      await onApplyPressed(pcrProtocol);
    }
  };

  addStepAtIndex = (index: number) => {
    let editingStepIndex = index;
    let { pcrProtocol } = this.state;
    if (pcrProtocol.steps.size === 0) {
      editingStepIndex = 0;
    }
    pcrProtocol = pcrProtocol.addStep(editingStepIndex);
    this.setState({
      pcrProtocol,
      editingStepIndex,
      isAdd: true
    });
  };

  cancelEdit = () => {
    let { pcrProtocol } = this.state;
    pcrProtocol = pcrProtocol.cancelEdit();
    this.setState({
      pcrProtocol,
      editingStepIndex: -1,
      isAdd: false
    });
  };

  saveEdit = (index: number, step: Map<string, any>) => {
    let { pcrProtocol } = this.state;
    pcrProtocol = pcrProtocol.saveEdit(index, step);
    this.protocolValidator.validateStepsSize(pcrProtocol.steps.size);
    this.setState({
      pcrProtocol,
      editingStepIndex: -1,
      selectedIndex: index,
      isAdd: false,
      wasEdited: pcrProtocol.hasChanges()
    });
  };

  stepTypeChanged = (eventKey: string) => {
    let { pcrProtocol } = this.state;
    const { editingStepIndex } = this.state;

    pcrProtocol = pcrProtocol.changeStepType(editingStepIndex, eventKey);
    this.setState({
      pcrProtocol
    });
  };

  volumeChanged = (_name: string, value?: number) => {
    let { pcrProtocol } = this.state;
    pcrProtocol = pcrProtocol.setVolume(value);
    this.protocolValidator.validateVolume(pcrProtocol.volume);

    this.setState({
      pcrProtocol,
      wasEdited: pcrProtocol.hasChanges()
    });
  };

  lidSettingChanged = (eventKey: string) => {
    let { pcrProtocol } = this.state;
    pcrProtocol = pcrProtocol.setLidSetting(eventKey);
    this.protocolValidator.validateLidSetting(pcrProtocol.lidSetting);

    this.setState({
      pcrProtocol,
      wasEdited: pcrProtocol.hasChanges()
    });
  };

  lidTempChanged = (_name: string, value?: number) => {
    this.setState(state => {
      let { pcrProtocol } = state;
      pcrProtocol = pcrProtocol.setLidTemp(value);
      this.protocolValidator.validateLidTemperature(pcrProtocol.lidTemp);

      return {
        pcrProtocol,
        wasEdited: pcrProtocol.hasChanges()
      };
    });
  };

  disableApply = () => {
    const { wasEdited } = this.state;
    if (this.protocolValidator.hasErrors()) return true;
    const { enableApplyIfNoChanges } = this.props;
    if (enableApplyIfNoChanges) return false;
    return !wasEdited;
  };

  renderTitle() {
    const { disabled } = this.props;
    const { pcrProtocol } = this.state;
    const { nameError } = this.protocolValidator;
    return (
      <TitleFieldGroup
        disabled={disabled}
        type="text"
        id="protocol-name"
        tooltip={nameError || 'Protocol name'}
        placeholder="Enter protocol name"
        value={pcrProtocol.name}
        onChange={this.nameChanged}
        validationState={nameError ? 'error' : 'success'}
      />
    );
  }

  renderAddStepDropDown() {
    const { pcrProtocol } = this.state;
    const { steps } = pcrProtocol;

    const addButtonID = 'add-step';
    const addButtonClasses = 'btn btn-secondary bold-font';
    const addButtonContents = (
      <>
        <i className="fa fa-plus" aria-hidden="true" id="add-plus-icon" />
        Add a Step
      </>
    );

    if (steps && steps.size === 0) {
      return (
        <SecondaryButton
          type="button"
          id={addButtonID}
          className={addButtonClasses}
          onClick={this.addStepAfter}
        >
          {addButtonContents}
        </SecondaryButton>
      );
    }

    return (
      <Dropdown id={addButtonID} className="br-select">
        <Dropdown.Toggle className="new-btn secondary" noCaret>
          {addButtonContents}
        </Dropdown.Toggle>
        <Dropdown.Menu id="add-step-menu" className="br-select-menu align-right">
          <MenuItem id="before-selected" onClick={this.addStepBefore}>
            Before selected
          </MenuItem>
          <MenuItem id="after-selected" onClick={this.addStepAfter}>
            After selected
          </MenuItem>
        </Dropdown.Menu>
      </Dropdown>
    );
  }

  renderProtocolSettings() {
    const { disabled } = this.props;
    const { pcrProtocol } = this.state;
    const { volume, lidSetting, lidTemp } = pcrProtocol;
    const { volumeError, lidError } = this.protocolValidator;
    return (
      <ProtocolSettings
        volume={volume}
        onVolumeChange={!disabled ? this.volumeChanged : undefined}
        volumeError={!!volumeError}
        lidSetting={lidSetting}
        onLidSettingChange={!disabled ? this.lidSettingChanged : undefined}
        lidTemp={lidTemp}
        onLidTempChange={!disabled ? this.lidTempChanged : undefined}
        lidError={!!lidError}
      />
    );
  }

  renderBottomButtons() {
    const { onCancelPressed, applyButtonText } = this.props;
    return (
      <div className="protocol-bottom-bar border-top-gray">
        <SecondaryButton type="button" id="btn-cancel" onClick={onCancelPressed}>
          Cancel
        </SecondaryButton>
        <ProgressButton
          id="btn-add"
          className="new-btn primary"
          onClick={this.applyPressed}
          disabled={this.disableApply()}
        >
          {applyButtonText}
        </ProgressButton>
        &nbsp;
      </div>
    );
  }

  renderWarning() {
    const { isPcr } = this.props;
    const { pcrProtocol } = this.state;
    const warningMessage = pcrProtocol.protocolWarning;
    if (warningMessage && !isPcr) {
      return (
        <Alert id="alert-warning" bsStyle="warning" className="warning">
          <strong>Warning!</strong> {warningMessage}
        </Alert>
      );
    }
    return null;
  }

  renderError() {
    const { errors } = this.protocolValidator;
    if (errors && errors.size !== 0) {
      return (
        <Alert id="alert-error" bsStyle="danger" className="alert">
          {errors.map((err: [string, string]) => (
            <div key={err[0]}>
              <strong>Error! </strong>
              {err[1]}
            </div>
          ))}
        </Alert>
      );
    }
    return null;
  }

  renderContent() {
    const { disabled, showNextButton, onNextPressed, nextButtonText } = this.props;
    return (
      <div className="protocol-content white-bg">
        <div id="protocol-chart" className="own-protocol-chart">
          {this.renderChart()}
        </div>
        <div id="protocol-settings" className=" white-bg">
          <div className="parent-flex">
            <div className="left-flex flex-grow-1">
              {this.renderError()}
              {this.renderWarning()}
            </div>
            {!disabled && <div className="right-flex">{this.renderAddStepDropDown()}</div>}
          </div>
        </div>
        <div id="protocol-step-list" className="flex-wrapper">
          <div className="left-flex">{this.renderProtocolSettings()}</div>
          <div className="right-flex">{this.renderText()}</div>
          <div className="button-group right">
            {showNextButton && (
              <PrimaryButton type="button" id="continue-to-plate-setup" onClick={onNextPressed}>
                {nextButtonText}
              </PrimaryButton>
            )}
          </div>
        </div>
      </div>
    );
  }

  renderChart() {
    const { pcrProtocol, selectedIndex } = this.state;
    // render current steps only after edit is done
    const steps = pcrProtocol.previousSteps || pcrProtocol.steps;
    return (
      <Protocol
        steps={steps}
        selectedIndex={selectedIndex}
        setSelectedStepIndex={this.setSelectedStepIndex}
      />
    );
  }

  renderText() {
    const { pcrProtocol, selectedIndex, editingStepIndex, isAdd } = this.state;
    const { disabled, isPcr } = this.props;
    return (
      <PCRProtocolText
        protocol={pcrProtocol.steps}
        deleteStep={this.deleteStep}
        editStep={this.editStep}
        editable={!disabled}
        selectedIndex={selectedIndex}
        setSelectedStepIndex={this.setSelectedStepIndex}
        editingStepIndex={editingStepIndex}
        isAdd={isAdd}
        saveEdit={this.saveEdit}
        cancelEdit={this.cancelEdit}
        stepTypeChanged={this.stepTypeChanged}
        isPcr={isPcr}
      />
    );
  }

  renderProtocol() {
    const { wasEdited, isLoading } = this.state;
    const { disabled, showProtocolName } = this.props;
    if (isLoading) {
      return <Loader />;
    }

    return (
      <>
        {!disabled && (
          <NavigationBlockPrompt
            shouldBlock={wasEdited}
            confirmationMessage="Changes made will be lost"
            title="Discard changes?"
            okBtnText="Discard"
          />
        )}
        <div className="protocol-details">
          {showProtocolName && this.renderTitle()}
          {this.renderContent()}
        </div>
        {!disabled && this.renderBottomButtons()}
      </>
    );
  }

  render() {
    return this.renderProtocol();
  }
}
