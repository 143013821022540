import { NullableString } from '../../frontend-common-libs/src/common/nullable-types';
import getProtocolFromRun from './api/protocol';

export default class Protocol {
  private readonly runId: NullableString = null;

  public constructor(runId: NullableString) {
    this.runId = runId;
  }

  public async getProtocolNameFromRun(): Promise<NullableString> {
    if (this.runId === null) {
      return null;
    }

    try {
      const response = await getProtocolFromRun(this.runId as string);
      const { data } = response;
      return data.name as string;
    } catch (error) {
      console.log(`Error fetching protocol name from run ${this.runId}`);
      throw error;
    }
  }
}
