import React, { useState } from 'react';
import { Seq } from 'immutable';
import { ImmutableMap, InstrumentItem } from '../../frontend-common-libs/src/common/types';
import { Toggle } from '../../frontend-common-libs/src/components/common/radio_button_group';
import { FleetToggleOptions, FleetToggleOptionsEnum } from './FleetManagementOptions';
import FleetManagementSupport from './FleetManagementSupport';
import styles from './styles/fleet-management.module.scss';
import InstrumentControl from './instrument-control/InstrumentControl';
import useReservations from './hooks/useReservations';
import ManageReservations from './manage-reservations/ManageReservations';
import CreateReservations from './create-reservations/CreateReservations';

export type Props = {
  instruments: ImmutableMap<InstrumentItem>[];
  projectId: string;
};

export default function FleetManagement(props: Readonly<Props>) {
  const [toggleCheckedValue, setToggleCheckedValue] = useState<string>(
    FleetToggleOptionsEnum.Create
  );

  const onUpdateFleetToggle = (e: React.MouseEvent<HTMLButtonElement>) => {
    setToggleCheckedValue(e.currentTarget.value);
  };

  const updateFleetToggleCallback = (toggleValue: string) => {
    setToggleCheckedValue(toggleValue);
  };

  const { instruments, projectId } = props;

  const supportedInstruments = instruments.filter(instrument => {
    return FleetManagementSupport.isInstrumentSupported(instrument);
  });

  const { reservations, userReservations } = useReservations({
    instruments: Seq(supportedInstruments),
    selectedTab: toggleCheckedValue
  });

  const renderCreateReservations = () => {
    return (
      <CreateReservations
        instruments={supportedInstruments}
        reservations={reservations}
        setFleetToggleCallback={updateFleetToggleCallback}
      />
    );
  };

  const renderInstrumentControl = () => {
    return <InstrumentControl instruments={supportedInstruments} reservations={userReservations} />;
  };

  const renderManageReservations = () => {
    return (
      <ManageReservations
        instruments={supportedInstruments}
        reservations={userReservations}
        projectId={projectId}
      />
    );
  };

  const renderTab = () => {
    if (toggleCheckedValue === FleetToggleOptionsEnum.Control) {
      return renderInstrumentControl();
    }
    if (toggleCheckedValue === FleetToggleOptionsEnum.Manage) {
      return renderManageReservations();
    }
    return renderCreateReservations();
  };

  return (
    <>
      <Toggle
        id={styles['fleet-instrument-toggle-options']}
        name="fleet-instrument-toggle"
        onChange={onUpdateFleetToggle}
        options={FleetToggleOptions}
        checkedValue={toggleCheckedValue}
      />
      <hr />
      {renderTab()}
    </>
  );
}
