import React, { Component } from 'react';
import Checkbox from '../../frontend-common-libs/src/common/checkbox';
import styles from './styles/user-preferences.module.scss';

export type Props = {
  deviceType: string;
  notificationsSettings: Array<any>;
  userSubscriptions: Array<string>;
  isUpdatingSubscriptionStatus: any;
  handleOnChange: any;
};

export default class PTCTempoUserPreferencesDetails extends Component<Props> {
  renderNotificationsSettings = () => {
    const {
      notificationsSettings,
      userSubscriptions,
      isUpdatingSubscriptionStatus,
      handleOnChange
    } = this.props;

    return notificationsSettings.map(({ id, displayText }) => {
      const isNotificationInUserSubscriptions = userSubscriptions.some(
        (userSubscription: string) => userSubscription === id
      );
      return (
        <Checkbox
          key={id}
          onChange={handleOnChange}
          value
          name={displayText}
          id={id}
          checked={isNotificationInUserSubscriptions}
          disabled={isUpdatingSubscriptionStatus}
        >
          {displayText}
        </Checkbox>
      );
    });
  };

  render() {
    const { deviceType } = this.props;
    return (
      <div className={styles['user-preferences-details']}>
        <p>{`Subscribe to ${deviceType} notifications`}</p>
        <div className={styles['popover-content']}>{this.renderNotificationsSettings()}</div>
      </div>
    );
  }
}
