import React from 'react';
import './styles/edit-organization.scss';
import {
  formatId,
  Select,
  SelectMenuItem
} from '../../frontend-common-libs/src/components/common/dropdown';

export type RoleSelectProps<RoleEnum> = {
  selectedValue: RoleEnum;
  disabled?: boolean;
  onRoleSelection: (role: RoleEnum) => any;
  idPostfix: string;
};

export type Props<RoleEnum> = {
  roleEnum: object;
  displayNames?: Record<string, string>;
} & RoleSelectProps<RoleEnum>;

export default function RoleSelect<RoleEnum>(props: Props<RoleEnum>) {
  const { selectedValue, onRoleSelection, disabled, idPostfix, roleEnum } = props;
  const dropdownId = `role-dropdown-${idPostfix}`;
  const roles = Object.values(roleEnum);

  const getDisplayName = (role: string) => {
    const { displayNames } = props;
    if (displayNames) {
      const displayName = displayNames[role];
      if (displayName) return displayName;
    }
    return role;
  };

  return (
    <Select
      id={dropdownId}
      value={getDisplayName(selectedValue as string)}
      handleSelection={onRoleSelection}
      disabled={disabled}
    >
      {roles.map((roleName: string) => {
        const roleDisplayName = getDisplayName(roleName);
        const formattedId = formatId(dropdownId, roleDisplayName);
        return (
          <SelectMenuItem
            id={formattedId}
            key={roleName}
            value={roleName}
            selected={roleName === selectedValue}
          >
            {roleDisplayName}
          </SelectMenuItem>
        );
      })}
    </Select>
  );
}
