import React from 'react';
import PasswordValidator from './PasswordValidator';
import CheckmarkGreen from '../../../../img/checkmark-green.svg';
import XRed from './assets/x-red.svg';

export type Props = {
  validator: PasswordValidator;
};

export const showGoodOrBadIcon = (value: boolean) => {
  if (value) {
    return <img src={CheckmarkGreen} alt="Green checkmark" className="pwd-fb-icon-good" />;
  }
  return <img src={XRed} alt="Red X" className="pwd-fb-icon-bad" />;
};

export default function PasswordFeedback({ validator }: Props) {
  return (
    <div>
      <div className="pwd-fb-left">
        <ul className="list-no-dots pwd-fb-list">
          <li className="list-no-dots">
            <div id="pwd-fb-lc" className="flex-wrapper" key="fb-lc">
              {showGoodOrBadIcon(validator.hasLowerCaseLetter())}
              <div className="pwd-fb-txt">One lowercase letter</div>
            </div>
          </li>
          <li className="list-no-dots">
            <div id="pwd-fb-uc" className="flex-wrapper" key="fb-uc">
              {showGoodOrBadIcon(validator.hasUpperCaseLetter())}
              <div className="pwd-fb-txt">One uppercase letter</div>
            </div>
          </li>
          <li className="list-no-dots">
            <div id="pwd-fb-num" className="flex-wrapper" key="fb-num">
              {showGoodOrBadIcon(validator.hasNumber())}
              <div className="pwd-fb-txt">One number</div>
            </div>
          </li>
        </ul>
      </div>
      <div className="pwd-fb-right">
        <ul className="list-no-dots pwd-fb-list">
          <li className="list-no-dots">
            <div id="pwd-fb-spec" className="flex-wrapper" key="fb-spec">
              {showGoodOrBadIcon(validator.hasSpecialCharacter())}
              <div className="pwd-fb-txt">One special character</div>
            </div>
          </li>
          <li className="list-no-dots">
            <div id="pwd-fb-min" className="flex-wrapper" key="fb-min">
              {showGoodOrBadIcon(validator.meetsMinimumLengthRequirement())}
              <div className="pwd-fb-txt">{`${validator.minimumLength} characters minimum`}</div>
            </div>
          </li>
          <li className="list-no-dots">
            <div id="pwd-fb-max" className="flex-wrapper" key="fb-max">
              {showGoodOrBadIcon(validator.meetsMaximumLengthRequirement())}
              <div className="pwd-fb-txt">{`${validator.maximumLength} characters maximum`}</div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
}
