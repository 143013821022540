import AWSIot from 'aws-iot-device-sdk';
import { guid } from '../utils/commonUtils';
import { IOT_MESSAGE_BROKER } from '../config/config';
import TopicCredentials from './credentials/topic-credentials';

type MessageCallback = (
  arg0: string,
  arg1: {
    [key: string]: any;
  }
) => any;

export default class IOTTopic {
  iotClient: AWSIot.device;

  connected = false;

  messageCallback: MessageCallback;

  reconnectCallback: (...args: Array<any>) => any;

  topic: string;

  constructor(
    credentials: TopicCredentials,
    messageCallback: MessageCallback,
    onReconnect: (...args: Array<any>) => any
  ) {
    this.reconnectCallback = onReconnect;
    this.messageCallback = messageCallback;
    this.topic = credentials.topic;
    // @ts-ignore
    this.iotClient = AWSIot.device({
      // keepalive: 15,
      // debug: true,
      // custom IoT endpoint unique to this AWS account
      host: IOT_MESSAGE_BROKER.ENDPOINT,
      clientId: guid(),
      // 'wss' specifies the connection type as WebSocket
      protocol: 'wss',
      // maximum reconnection time in milliseconds
      maximumReconnectTimeMs: 8000,
      accessKeyId: credentials.accessKeyId,
      secretKey: credentials.secretKey,
      sessionToken: credentials.sessionToken
    });
    this.iotClient.on('connect', this.onConnect);
    this.iotClient.on('message', this.onMessage);
    this.iotClient.on('error', this.onError);
  }

  onConnect = () => {
    this.iotClient.subscribe(this.topic);
    // No need for onReconnect callback on initial connection.
    if (this.connected) {
      this.reconnectCallback();
    }
    this.connected = true;
  };

  onMessage = (topic: string, message: string) => {
    const messageJSON = JSON.parse(message.toString());
    this.messageCallback(topic, messageJSON);
  };

  onError = () => {
    /* err */
  };

  end() {
    if (this.iotClient) {
      this.iotClient.end();
      this.connected = false;
    }
  }
}
