import { Dispatch } from '../../types';
import {
  CustomerSupportActionsTypes,
  MAINTENANCE_MESSAGE_ERROR,
  MAINTENANCE_MESSAGE_LOADED
} from './maintenance_message_actions_types';
import { getMaintenanceMessage as getMaintenanceMessageRequest } from '../../api/customer-support';

type DispatchType = Dispatch<CustomerSupportActionsTypes, Record<string, any> | string>;

// eslint-disable-next-line import/prefer-default-export
export function getMaintenanceMessage() {
  return async (dispatch: DispatchType) => {
    try {
      const result = await getMaintenanceMessageRequest();
      const { message } = result.data;
      dispatch({
        type: MAINTENANCE_MESSAGE_LOADED,
        payload: { message }
      });
    } catch (error) {
      dispatch({
        type: MAINTENANCE_MESSAGE_ERROR,
        payload: {}
      });
    }
  };
}
