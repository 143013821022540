import getIotCredentials from '../api/getIotCredentials';
import IOTCredentials from './iot-credentials';

export default class IOTCredentialsProvider {
  iotCredentials: IOTCredentials | undefined;

  async resolve() {
    if (!this.iotCredentials || this.iotCredentials.expired()) {
      const credentials = await getIotCredentials();
      this.iotCredentials = new IOTCredentials(credentials);
    }
    return this.iotCredentials;
  }
}
