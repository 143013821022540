export type FieldValidationResult = {
  valid: boolean;
  helpString: string;
};

function isUndefined(val?: number): boolean {
  return val === undefined;
}

export function isNumberInRange(val: number | undefined, first: number, second: number): boolean {
  const high = Math.max(first, second);
  const low = Math.min(first, second);
  return val !== undefined && Number.isFinite(val) && low <= val && val <= high;
}

function printTemp(temp: number): string {
  let stringTemp = temp.toString(10);

  if (!Number.isInteger(temp)) {
    stringTemp = temp.toFixed(1);
  }
  return stringTemp;
}

function isInputValid(
  input: number | undefined,
  min: number,
  max: number,
  required = true
): boolean {
  return (!required && isUndefined(input)) || isNumberInRange(input, min, max);
}

export function isValidTemperature(
  temp: number | undefined,
  minTemp: number,
  maxTemp: number,
  required = false
): FieldValidationResult {
  const valid = isInputValid(temp, minTemp, maxTemp, required);

  const minTempPrint = printTemp(minTemp);
  const maxTempPrint = printTemp(maxTemp);

  const helpString = valid ? '' : `Range: ${minTempPrint} to ${maxTempPrint} \xB0C`;
  return { valid, helpString };
}

export function isValidIncrement(
  temp: number | undefined,
  minTemp: number,
  maxTemp: number,
  required = false
): FieldValidationResult {
  const valid = isInputValid(temp, minTemp, maxTemp, required);

  const minTempPrint = printTemp(minTemp);
  const maxTempPrint = printTemp(maxTemp);

  const helpString = valid ? '' : `Range: ${minTempPrint} to ${maxTempPrint} \xB0C/cycle`;
  return { valid, helpString };
}

export function isValidRampRate(
  temp: number | undefined,
  minTemp: number,
  maxTemp: number,
  required = false
): FieldValidationResult {
  const valid = isInputValid(temp, minTemp, maxTemp, required);

  const minTempPrint = printTemp(minTemp);
  const maxTempPrint = printTemp(maxTemp);

  const helpString = valid ? '' : `Range: ${minTempPrint} to ${maxTempPrint} \xB0C/sec`;
  return { valid, helpString };
}

export function isValidTime(
  time: number | undefined,
  minTime: number,
  maxTime: number,
  required = false
): FieldValidationResult {
  const valid = isInputValid(time, minTime, maxTime, required);
  const helpString = valid ? '' : `Range: ${minTime} to ${maxTime} sec/cycle`;
  return { valid, helpString };
}

export function isValidInterval(
  time: number | undefined,
  minTime: number,
  maxTime: number,
  required = false
): FieldValidationResult {
  const valid = isInputValid(time, minTime, maxTime, required);
  const helpString = valid ? '' : `Range: ${minTime} to ${maxTime} sec`;
  return { valid, helpString };
}

export function isValidStep(
  step: number | undefined,
  minStep: number,
  maxStep: number
): FieldValidationResult {
  const valid = isInputValid(step, minStep, maxStep);
  const helpString = valid ? '' : `Range: ${minStep + 1} to ${maxStep + 1}`;
  return { valid, helpString };
}

export function isValidCycles(count: number | undefined, maxCount: number) {
  const valid = isInputValid(count, 1, maxCount);
  const helpString = valid ? '' : `Range: 1 to ${maxCount}`;
  return { valid, helpString };
}

export function isValidEndMeltTemperature(
  endTemp: number | undefined,
  startTemp: number | undefined
) {
  const tempValidation = isValidTemperature(endTemp, 0, 100, true);
  if (tempValidation.valid) {
    if (endTemp === startTemp) {
      return {
        valid: false,
        helpString: 'Must not be equal to the start temperature'
      };
    }
  }
  return tempValidation;
}
