import React, { PureComponent } from 'react';
import { replaceWhitespace } from '../../../frontend-common-libs/src/common/strings';

export type DeviceTypeNotificationButton = {
  id: string;
  title: string;
};

type Props = {
  deviceTypesNotificationsButtons: Array<DeviceTypeNotificationButton>;
  showDeviceNotificationsSettings: (buttonName: string) => void;
  selectedDeviceType: string;
};

export default class NotificationsSettingsToolbar extends PureComponent<Props, any> {
  handleButtonClick = (event: React.SyntheticEvent<HTMLButtonElement>) => {
    const { showDeviceNotificationsSettings } = this.props;
    const buttonName = event.currentTarget.name;
    showDeviceNotificationsSettings(buttonName);
  };

  renderNotificationButtons = () => {
    const { deviceTypesNotificationsButtons, selectedDeviceType } = this.props;

    return deviceTypesNotificationsButtons.map((deviceType, index) => (
      <React.Fragment key={deviceType.id}>
        <button
          className="popover-navigation-button"
          id={`${replaceWhitespace(deviceType.id, '-').toLowerCase()}-notification-settings-button`}
          name={deviceType.id}
          onClick={this.handleButtonClick}
        >
          <label
            className={`${
              deviceTypesNotificationsButtons.length === 1 && 'simple-popover-navigation-button'
            } ${selectedDeviceType === deviceType.id ? 'selected-device-type' : ''}`}
          >
            {deviceType.title}
          </label>
        </button>
        {deviceTypesNotificationsButtons.length > 1 &&
          index < deviceTypesNotificationsButtons.length - 1 && (
            <div className="notification-toolbar-separator" />
          )}
      </React.Fragment>
    ));
  };

  render() {
    return (
      <div className="popover-navbar" id="notifications-popover-navbar">
        {this.renderNotificationButtons()}
      </div>
    );
  }
}
