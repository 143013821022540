import {
  TenantUpdate,
  TenantUpdateEvent,
  RoleAssignedEvent,
  UserRemovedEvent,
  ProjectRoles,
  UserAddedEvent,
  TenantRenamedEvent
} from '@biorad-lsg-tsc/organization';
import { DispatchType } from '../actions/action-types';
import { GetState, ReduxState } from '../../types';
import { dispatchProjectRoleChanged } from '../actions/set-project-role';
import { dispatchProjectUserRemoved } from '../actions/remove-user-from-project';
import { dispatchProjectUserAdded } from '../actions/add-user-to-project';
import { getUserEmail } from '../../selectors/auth-selectors';
import { dispatchProjectAdded } from '../actions/create-project';
import { dispatchProjectRenamed } from '../actions/rename-project';

export default class ProjectIotHandler {
  static onIotMessage(dispatch: DispatchType, getState: GetState, event: TenantUpdateEvent) {
    const state = getState();
    const { action } = event;
    if (action === TenantUpdate.roleAssigned) {
      ProjectIotHandler.dispatchProjectRoleChanged(dispatch, state, event);
    } else if (action === TenantUpdate.userRemoved) {
      ProjectIotHandler.dispatchProjectUserRemoved(dispatch, state, event);
    } else if (action === TenantUpdate.userAdded) {
      ProjectIotHandler.dispatchProjectUserAdded(dispatch, state, event);
    } else if (action === TenantUpdate.renamed) {
      ProjectIotHandler.dispatchProjectRenamed(dispatch, state, event);
    }
  }

  static dispatchProjectRoleChanged(
    dispatch: DispatchType,
    state: ReduxState,
    event: RoleAssignedEvent
  ) {
    const { tenantId, userEmail, userRole } = event;
    dispatchProjectRoleChanged(dispatch, state, tenantId, userEmail, userRole as ProjectRoles);
  }

  static dispatchProjectUserRemoved(
    dispatch: DispatchType,
    state: ReduxState,
    event: UserRemovedEvent
  ) {
    const { tenantId, userEmail } = event;
    dispatchProjectUserRemoved(dispatch, state, tenantId, userEmail);
  }

  static dispatchProjectUserAdded(
    dispatch: DispatchType,
    state: ReduxState,
    event: UserAddedEvent
  ) {
    const { tenantId, userEmail, userRole, tenantName, parentTenantId } = event;
    dispatchProjectUserAdded(dispatch, tenantId, userEmail, userRole as ProjectRoles);
    const email = getUserEmail(state);
    if (userEmail !== email) return;
    const orgId = parentTenantId as string;
    const project = { name: tenantName as string, id: tenantId };
    dispatchProjectAdded(dispatch, orgId, project);
  }

  static dispatchProjectRenamed(
    dispatch: DispatchType,
    state: ReduxState,
    event: TenantRenamedEvent
  ) {
    const { tenantId, name } = event;
    dispatchProjectRenamed(dispatch, state, tenantId, name);
  }
}
