import * as React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

function WithOverlay(props: { id: string; tooltip: string; children: React.ReactNode }) {
  const { id, tooltip, children } = props;
  const tooltipComp = <Tooltip id={`tooltip-${id}`}>{tooltip}</Tooltip>;
  return (
    <OverlayTrigger placement="bottom" overlay={tooltipComp}>
      {children}
    </OverlayTrigger>
  );
}

export default WithOverlay;
