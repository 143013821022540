import requests from '../../frontend-common-libs/src/utils/httpUtils';
import { GATEWAY } from '../../frontend-common-libs/src/config/config';

export interface JoinEAPRequestBody {
  phone: string;
  email: string;
  country: string;
}

export default class JoinEAPRequest {
  constructor(private body: JoinEAPRequestBody) {}

  send(): Promise<void> {
    return requests.post(
      GATEWAY.EAP_SERVICE.BASE_URL,
      {},
      GATEWAY.EAP_SERVICE.JOIN_EAP,
      {},
      this.body
    );
  }
}
