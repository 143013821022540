import Hashes from 'jshashes';

export default class AnonymizedString {
  constructor(value: string) {
    this._value = value;
  }

  _value: string;

  toString(): string {
    return new Hashes.SHA1().b64(this._value);
  }
}
