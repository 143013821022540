import React, { Component } from 'react';
import { connect } from 'react-redux';
import Loader from '../../frontend-common-libs/src/components/common/Loader';
import { Project, ProjectId } from '../../frontend-common-libs/src/common/project-management-types';
import ProjectList from './ProjectList';
import '../styles/user-projects.scss';
import { getProjects, getSelectedProjectId, isProjectsLoaded } from '../selectors/selectors';
import { ReduxState } from '../../types';
import EditFileModal, {
  EditModalFormValues
} from '../../frontend-common-libs/src/components/common/dialogs/EditFileModal';
import { createProject as createProjectAction } from '../actions/create-project';
import createProjectIcon from '../../frontend-common-libs/src/img/icons-add-icon.svg';
import EditProject from './EditProject';
import { getActiveOrg } from '../../organization-management/selectors/selectors';
import { editFileStyleV2 } from '../../frontend-common-libs/src/components/common/dialogs/custom-modal-styles';

export type Props = {
  loading: boolean;
  projects: Project[];
  createProject: typeof createProjectAction;
  activeProjectId?: ProjectId;
};

export type State = {
  creatingProject: boolean;
  selectedProject?: Project;
};

export class UserProjectsImpl extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { creatingProject: false };
  }

  async componentDidMount() {
    this.setSelectedProject();
  }

  componentDidUpdate(prevProps: Props) {
    const { loading } = this.props;
    if (!loading && prevProps.loading) this.setSelectedProject();
    this.updateProjectRenamed(prevProps);
    this.updateProjectRemoved(prevProps);
  }

  setSelectedProject() {
    const { projects, activeProjectId } = this.props;
    let selectedProject;
    if (projects != null && projects.length > 0) {
      selectedProject = projects.find(p => p.id === activeProjectId);
      if (selectedProject == null) {
        const [first] = projects;
        selectedProject = first;
      }
    }
    this.setState({ selectedProject });
  }

  updateProjectRenamed(prevProps: Props) {
    const { projects } = this.props;
    const { selectedProject } = this.state;
    if (selectedProject == null || projects === prevProps.projects) return;
    const prevSelected = prevProps.projects.find(p => p.id === selectedProject.id);
    const newSelected = projects.find(p => p.id === selectedProject.id);
    if (prevSelected && newSelected && prevSelected.name !== newSelected.name)
      this.setState({ selectedProject: newSelected });
  }

  updateProjectRemoved(prevProps: Props) {
    const { projects } = this.props;
    const { selectedProject } = this.state;
    if (!projects || !selectedProject || projects === prevProps.projects) return;
    const newSelected = projects.find(p => p.id === selectedProject.id);
    if (!newSelected) this.setSelectedProject();
  }

  createProject = async (formValues: EditModalFormValues) => {
    const { createProject } = this.props;
    const projectName = formValues.name;
    createProject(projectName);
    this.setState({
      creatingProject: false
    });
  };

  onProjectRowClick = (project: Project) => {
    this.setState({ selectedProject: project });
  };

  renderProjectsButtons() {
    return (
      <div className="projects-buttons">
        <button
          type="button"
          id="create-project-button"
          onClick={() => {
            this.setState({ creatingProject: true });
          }}
          className="create-button hover-image-button"
        >
          <img src={createProjectIcon} alt="+" />
          <span className="create-text">Create Project</span>
        </button>
      </div>
    );
  }

  renderCreateProject() {
    const { creatingProject } = this.state;
    return (
      <EditFileModal
        show={creatingProject}
        // @ts-ignore
        title="Create New Project"
        onSuccess={this.createProject}
        onCancel={() => {
          this.setState({ creatingProject: false });
        }}
        okButtonText="Create"
        customModalStyle={editFileStyleV2}
      />
    );
  }

  renderProjectList() {
    const { selectedProject } = this.state;
    const { projects, activeProjectId } = this.props;
    return (
      <ProjectList
        projects={projects}
        selectedProject={selectedProject}
        onRowClick={this.onProjectRowClick}
        className="tenant-list"
        activeProjectId={activeProjectId}
      />
    );
  }

  renderRightPanel() {
    const { selectedProject } = this.state;
    return (
      <div className="projects-right-panel">
        {this.renderProjectsButtons()}
        {/* @ts-ignore */}
        {selectedProject && <EditProject project={selectedProject} />}
      </div>
    );
  }

  render() {
    const { loading } = this.props;
    if (loading) {
      return <Loader />;
    }

    return (
      <div className="user-projects-page">
        {this.renderCreateProject()}
        <div className="projects-page-content">
          <div className="projects-left-panel">{this.renderProjectList()}</div>
          <div className="vertical-separator" />
          {this.renderRightPanel()}
        </div>
      </div>
    );
  }
}

export function mapStateToProps(state: ReduxState): {
  [key: string]: any;
} {
  const orgId = getActiveOrg(state);
  // @ts-ignore
  const projects = getProjects(state, orgId).toJS() as Project[];
  // @ts-ignore
  return {
    loading: !isProjectsLoaded(state, orgId),
    projects,
    activeProjectId: getSelectedProjectId(state)
  };
}

export default connect(mapStateToProps, {
  createProject: createProjectAction
  // @ts-ignore
})(UserProjectsImpl);
