import React, { PureComponent, RefObject } from 'react';
import UtilityButton from '../../../../../frontend-common-libs/src/components/common/buttons/UtilityButton';
import AmplificationSettings from './AmplificationSettings';
import ThresholdPanel from './ThresholdPanel';

type Props = {
  onClose: (...args: Array<any>) => any;
  onBlur: (...args: Array<any>) => any;
  isMeltStepSelected: boolean;
};

type State = {
  showCloseButton: boolean;
};

export class AnalysisSetupImpl extends PureComponent<Props, State> {
  popoverRef: RefObject<any> = React.createRef();

  constructor(props: Props) {
    super(props);
    this.state = { showCloseButton: true };
  }

  componentDidMount() {
    const { handleBlur } = this;
    document.addEventListener('mousedown', handleBlur, false);
  }

  componentWillUnmount() {
    const { handleBlur } = this;
    document.removeEventListener('mousedown', handleBlur, false);
  }

  handleBlur = (event: MouseEvent) => {
    const {
      popoverRef,
      props: { onBlur }
    } = this;
    const { target } = event;
    if (popoverRef && popoverRef.current && !popoverRef.current.contains(target)) {
      onBlur(event);
    }
  };

  handleTabOut = (event: React.KeyboardEvent<HTMLButtonElement>) => {
    const { key, shiftKey } = event;
    if (key === 'Tab' && !shiftKey) {
      const { onClose } = this.props;
      onClose();
    }
  };

  handleOpenPanel = (isPanelOpen: boolean) => {
    this.setState({ showCloseButton: !isPanelOpen });
  };

  render() {
    const {
      popoverRef,
      handleOpenPanel,
      handleTabOut,
      props: { onClose, isMeltStepSelected },
      state: { showCloseButton }
    } = this;
    return (
      <div className="toolbar-popover" ref={popoverRef}>
        <div className="popover-header">Analysis Setup</div>
        <div className="popover-panel">
          <div className="popover-left-panel">
            {isMeltStepSelected ? 'Melt Curve' : 'Amplification'}
          </div>
          <div className="popover-right-panel">
            <div className="popover-content">
              {!isMeltStepSelected && (
                <AmplificationSettings onPanelToggle={handleOpenPanel} onTabOut={handleTabOut} />
              )}
              {isMeltStepSelected && (
                <ThresholdPanel
                  closePopover={onClose}
                  onTabOut={handleTabOut}
                  isMeltStepSelected={isMeltStepSelected}
                  closePanel={onClose}
                />
              )}
            </div>
            <div className="popover-footer">
              {showCloseButton && !isMeltStepSelected && (
                <UtilityButton
                  id="toolbar-popover-close"
                  type="button"
                  onClick={onClose}
                  onKeyDown={handleTabOut}
                >
                  Close
                </UtilityButton>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AnalysisSetupImpl;
