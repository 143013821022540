import React from 'react';
import styles from './styles/Breadcrumbs.module.scss';

export type Props = {
  breadcrumbs: string[];
  id: string;
  separator?: string;
};

export default function Breadcrumbs(props: Readonly<Props>) {
  const { breadcrumbs, separator, id } = props;
  return (
    <div id={id} className={styles.breadcrumbs}>
      {breadcrumbs.map((crumb: string, index) => {
        const isLast = index === breadcrumbs.length - 1;
        const crumbClass = isLast ? styles.breadcrumbLast : styles.breadcrumbNotLast;
        return [
          <span className={`with-ellipsis ${crumbClass}`} title={crumb} key={`crumb_${crumb}`}>
            {crumb}
          </span>,
          isLast ? null : (
            <span className={styles.breadcrumbSeparator} key={`separator_${crumb}`}>
              {separator ?? '>'}
            </span>
          )
        ];
      })}
    </div>
  );
}
