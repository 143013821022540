import React from 'react';
import { Map } from 'immutable';
import { FleetManagementTable } from '../../fleet-management-table';
import Checkbox from '../../../../frontend-common-libs/src/common/checkbox';
import TCell from '../../../../frontend-common-libs/src/components/common/tables/TCell';
import InstrumentReservation from '../../InstrumentReservation';
import ManageReservationsRow from '../manage-reservations-row/ManageReservationsRow';
import styles from './styles/manage-reservations-table.module.scss';
import { ImmutableMap, InstrumentItem } from '../../../../frontend-common-libs/src/common/types';

export type Props = {
  instruments: ImmutableMap<InstrumentItem>[];
  reservations: Map<string, InstrumentReservation>;
  updateSelectedInstruments: (id: string, select: boolean) => void;
  handleSelectAll: () => void;
  selectAllCheckbox: boolean;
  clearSelectedInstruments: boolean;
};

export default function ManageReservationsTable(props: Readonly<Props>) {
  const {
    instruments,
    reservations,
    updateSelectedInstruments,
    handleSelectAll,
    selectAllCheckbox,
    clearSelectedInstruments
  } = props;

  const renderSelectAllCheckbox = () => (
    <TCell>
      <Checkbox
        id="checkbox-select-all"
        name="checkbox-select-all"
        value={selectAllCheckbox}
        checked={selectAllCheckbox}
        onChange={handleSelectAll}
      />
    </TCell>
  );

  const renderAdditionalHeaders = () => (
    <>
      <TCell>STATUS</TCell>
      <TCell>PROTOCOL NAME</TCell>
    </>
  );

  const renderInstrumentRows = (
    instrumentList: ImmutableMap<InstrumentItem>[]
  ): React.JSX.Element[] => {
    return instrumentList.map((instrument: ImmutableMap<InstrumentItem>) => {
      const instrumentId = instrument.get('id');
      return (
        <ManageReservationsRow
          key={instrumentId}
          instrument={instrument}
          reservation={reservations.get(instrumentId) as InstrumentReservation}
          selectAllCheckbox={selectAllCheckbox}
          updateSelectedInstruments={updateSelectedInstruments}
          clearSelectedInstruments={clearSelectedInstruments}
        />
      );
    });
  };

  const renderTableBody = () => {
    return <tbody>{renderInstrumentRows(instruments)}</tbody>;
  };

  const isHidden = reservations.size === 0;

  return (
    <div className={styles.manageReservationsTable}>
      <FleetManagementTable isHidden={isHidden}>
        {renderSelectAllCheckbox()}
        {renderAdditionalHeaders()}
        {renderTableBody()}
      </FleetManagementTable>
    </div>
  );
}
