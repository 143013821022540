import React from 'react';
import NavigationPrompt from 'react-router-navigation-prompt';

import ModalDialog from './dialogs/ModalDialog';
import { okCancelStyleV2 } from './dialogs/custom-modal-styles';

type Props = {
  shouldBlock: boolean;
  confirmationMessage: string;
  whiteListURLPattern: RegExp | false;
  title: string;
  okBtnText: string;
  cancelBtnText: string;
};

type Location = {
  pathname: string;
};

export default class NavigationBlockPrompt extends React.Component<Props> {
  static defaultProps = {
    whiteListURLPattern: false,
    title: 'Confirm navigation',
    okBtnText: 'OK',
    cancelBtnText: 'Cancel'
  };

  block = (_crntLocation: Location, nextLocation: Location | null | undefined) => {
    const { shouldBlock, whiteListURLPattern } = this.props;
    if (shouldBlock) {
      if (!nextLocation) {
        return true; // closing tab
      }
      if (whiteListURLPattern) {
        // true if path doesn't match whitelist else false
        return !whiteListURLPattern.test(nextLocation.pathname);
      }
      return true;
    }
    return false;
  };

  render() {
    const { confirmationMessage, title, okBtnText, cancelBtnText } = this.props;
    return (
      <NavigationPrompt when={this.block}>
        {({ onConfirm, onCancel }) => (
          <ModalDialog
            title={title}
            show
            message={confirmationMessage}
            onCancel={onCancel}
            onSuccess={onConfirm}
            okBtnText={okBtnText}
            cancelBtnText={cancelBtnText}
            customModalStyle={okCancelStyleV2}
          />
        )}
      </NavigationPrompt>
    );
  }
}
