import * as React from 'react';
import { FormControl, FormGroup } from 'react-bootstrap';
import { FormEvent } from 'react';
import WithOverlay from './WithOverlay';
import { ValidationState } from './fieldValidationState';

type Props = {
  id: string;
  value?: string;
  validationState?: ValidationState;
  tooltip: string;
  disabled: boolean;
  type: string;
  placeholder: string;
  onChange: (event: FormEvent<FormControl>) => void;
};

function TitleFieldGroup(props: Props) {
  const { id, value, validationState, tooltip, type, placeholder, onChange, disabled } = props;

  return (
    <FormGroup
      bsClass="form-control-group title-field"
      controlId={id}
      validationState={validationState}
    >
      <WithOverlay id={id} tooltip={tooltip}>
        <FormControl
          value={value}
          type={type}
          placeholder={placeholder}
          onChange={onChange}
          disabled={disabled}
        />
      </WithOverlay>
    </FormGroup>
  );
}

TitleFieldGroup.defaultProps = {
  value: '',
  validationState: null
};

export default TitleFieldGroup;
