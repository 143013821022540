import UserAnalyticsClient from '../UserAnalyticsClient';

export default class ResendVerificationCodeEvent {
  private static readonly RESEND_VERIFICATION_CODE_EVENT_NAME = 'resend-verification-code';

  // eslint-disable-next-line class-methods-use-this
  public track() {
    UserAnalyticsClient.getInstance().track(
      ResendVerificationCodeEvent.RESEND_VERIFICATION_CODE_EVENT_NAME
    );
  }
}
