import dateformat from 'dateformat';
import { CFX_PROTOCOL, CFX_96_PLATE, CFX_384_PLATE, CFX_RUN } from './creation_types';
import { getCurrentUser } from './authUtils';
import {
  FILETYPE_CFX_RUN,
  FILETYPE_PENDING_CFX_RUN,
  FILETYPE_IN_PROGRESS_CFX_RUN,
  FILETYPE_CFX_RUN_TEMPLATE,
  FILETYPE_PTC_RUN
} from '../common/userfiles_common';
import coreRoutes from '../../../core/routes';
import conventionalPcrRoutes from '../../../conventional-pcr-frontend/routes';
import realTimePcrRoutes from '../../../real-time-pcr/routes';

export const supportedFileExtensions = ['pcrd', 'zpcr'];

export default function getCreatables() {
  // For now this will just return a json description
  // of a set of instruments and related objects that
  // can be created.
  return {
    CFX: {
      Protocol: {
        name: 'Protocol',
        type: CFX_PROTOCOL
      },
      Plate96: {
        name: '96 well Plate',
        type: CFX_96_PLATE
      },
      Plate384: {
        name: '384 well Plate',
        type: CFX_384_PLATE
      },
      Run: {
        name: 'Run',
        type: CFX_RUN
      }
    }
  };
}

export function getFileExtension(fileType?: string): string {
  switch (fileType) {
    case CFX_PROTOCOL:
      return 'prcl';
    case CFX_96_PLATE:
    case CFX_384_PLATE:
      return 'plate';
    case CFX_RUN:
      return 'run';
    default:
      return '';
  }
}

// In general the default file name is UserName-Date-Time.ext
export function getNewFileName(fileType?: string) {
  const extension = getFileExtension(fileType);
  const user = getCurrentUser();
  if (!user) {
    throw Error('User not logged in');
  }
  const userName = user.getUsername();
  const dateTime = dateformat('yyyy-mm-dd-HH-MM-ss');
  if (extension) {
    return `${userName}-${dateTime}.${extension}`;
  }
  return `${userName}-${dateTime}`;
}

export function canOpenFile(fileType: string) {
  return (
    fileType === FILETYPE_CFX_RUN ||
    fileType === FILETYPE_PENDING_CFX_RUN ||
    fileType === FILETYPE_IN_PROGRESS_CFX_RUN ||
    fileType === FILETYPE_PTC_RUN
  );
}

export function iconForFileType(fileType: string): string {
  switch (fileType) {
    case FILETYPE_CFX_RUN:
      return 'completed-run.svg';
    case FILETYPE_IN_PROGRESS_CFX_RUN:
      return 'in-progress-cfx-run.svg';
    case FILETYPE_PENDING_CFX_RUN:
      return 'pending-run.svg';
    case FILETYPE_CFX_RUN_TEMPLATE:
      return 'cfx-run-template.svg';
    default:
      return 'generic-file.svg';
  }
}

export function getPathForFileType(fileType: string, id: number): string {
  switch (fileType) {
    case FILETYPE_CFX_RUN:
    case FILETYPE_IN_PROGRESS_CFX_RUN:
    case FILETYPE_PENDING_CFX_RUN:
      return `${coreRoutes.APP}${realTimePcrRoutes.QPCR}/${id}`;
    case FILETYPE_PTC_RUN:
      return `${coreRoutes.APP}${conventionalPcrRoutes.PCR}/${id}`;
    default:
      return '';
  }
}

export function getExtensionOfFile(fileName?: string | null): string {
  if (!fileName) return '';
  const splitName: string[] = fileName.split('.');
  if (splitName.length <= 1) return '';
  return splitName[splitName.length - 1];
}

export function isSupportedFile(fileName?: string | null): boolean {
  if (!fileName) return false;
  const fileExtension = getExtensionOfFile(fileName);
  return supportedFileExtensions.includes(fileExtension.toLowerCase());
}
