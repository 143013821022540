import React, { Component, ReactElement, RefObject, KeyboardEvent } from 'react';
import { Select } from './index';
import { SelectProps } from '../../../common/dropdown/Select';
import LoadingMenuItem from './LoadingMenuItem';
import RetryMenuItem from './RetryMenuItem';

export type InfiniteScrollSelectProps = {
  children: ReactElement<any> | ReactElement<any>[] | null;
  fetchMoreData: (...args: Array<any>) => any;
  hasMoreData: boolean;
  isLoading: boolean;
  loadingErrored: boolean;
} & SelectProps;

type State = { focussedIndex: number };

class InfiniteScrollSelect extends Component<InfiniteScrollSelectProps, State> {
  menuItemRefToIndex: Map<any, number>;

  indexToMenuItemRef: Map<number, any>;

  constructor(props: InfiniteScrollSelectProps) {
    super(props);
    this.menuItemRefToIndex = new Map();
    this.indexToMenuItemRef = new Map();
    this.state = { focussedIndex: 0 };
  }

  static getDerivedStateFromProps(props: InfiniteScrollSelectProps, state: State) {
    const { focussedIndex } = state;
    const { children, hasMoreData } = props;
    const childrenCount = React.Children.count(children);
    if (hasMoreData || childrenCount === 0 || focussedIndex < childrenCount) {
      return null;
    }
    return { focussedIndex: childrenCount - 1 };
  }

  componentDidUpdate(prevProps: InfiniteScrollSelectProps, prevState: State) {
    const { focussedIndex: prevFocussedIndex } = prevState;
    const { focussedIndex } = this.state;

    const { isLoading: prevIsLoading } = prevProps;
    const { isLoading } = this.props;

    if (focussedIndex !== prevFocussedIndex || isLoading !== prevIsLoading) {
      if (focussedIndex != null && this.indexToMenuItemRef.has(focussedIndex)) {
        const menuItemRef = this.indexToMenuItemRef.get(focussedIndex);
        if (menuItemRef) {
          menuItemRef.focus();
        }
      }
    }
  }

  addMenuItemRef = (index: number) => (ref: RefObject<any>) => {
    if (ref) {
      this.menuItemRefToIndex.set(ref, index);
      this.indexToMenuItemRef.set(index, ref);
    } else if (this.indexToMenuItemRef.has(index)) {
      const menuItemRef = this.indexToMenuItemRef.get(index);
      if (menuItemRef) {
        this.menuItemRefToIndex.delete(menuItemRef);
      }
      this.indexToMenuItemRef.delete(index);
    }
  };

  handleKeyDown = (event: KeyboardEvent<HTMLElement>) => {
    const { key, target: activeNavItem } = event;
    const isArrowDown = key === 'ArrowDown';
    if (isArrowDown || key === 'ArrowUp') {
      const activeIndex = this.menuItemRefToIndex.get(activeNavItem);
      if (activeIndex == null) {
        return;
      }
      let focusItemIndex;
      if (isArrowDown) {
        focusItemIndex = activeIndex === this.menuItemRefToIndex.size - 1 ? 0 : activeIndex + 1;
      } else {
        focusItemIndex = activeIndex === 0 ? this.menuItemRefToIndex.size - 1 : activeIndex - 1;
      }
      this.setState({ focussedIndex: focusItemIndex });
      event.preventDefault();
    }
  };

  retryClick = (retryMenuItemIndex: number) => {
    const { fetchMoreData } = this.props;
    this.setState({ focussedIndex: retryMenuItemIndex });
    fetchMoreData();
  };

  render() {
    const {
      id,
      children,
      hasMoreData,
      isLoading,
      fetchMoreData,
      loadingErrored,
      handleSelection,
      placeholder,
      value,
      disabled
    } = this.props;

    const childrenCount = React.Children.count(children);

    return (
      <Select
        id={id}
        handleSelection={handleSelection}
        placeholder={placeholder}
        value={value}
        disabled={disabled}
        data-loading={isLoading}
      >
        <div onKeyDown={this.handleKeyDown} role="presentation">
          {React.Children.map(children, (menuItem, index) =>
            React.cloneElement(menuItem, { menuItemRef: this.addMenuItemRef(index) })
          )}
          {hasMoreData && !loadingErrored && (
            <LoadingMenuItem
              key={`loading-menu-item-${childrenCount}`}
              onFirstVisible={fetchMoreData}
              isLoading={isLoading}
              menuItemRef={this.addMenuItemRef(childrenCount)}
            />
          )}
          {loadingErrored && (
            <RetryMenuItem
              key={`retry-menu-item-${childrenCount}`}
              menuItemRef={this.addMenuItemRef(childrenCount)}
              retry={this.retryClick}
              index={childrenCount}
            />
          )}
        </div>
      </Select>
    );
  }
}

export default InfiniteScrollSelect;
