import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { Amplify } from 'aws-amplify';

// Common Components
import { Logout, PrivateRoute, VerifyAccount, CompleteRegistration } from './auth';
import { loadUserSession as loadUserSessionAction } from '../auth/actions/auth_actions';
import Loader from '../frontend-common-libs/src/components/common/Loader';
import ExternalRedirect from './common/ExternalRedirect';
import PublicPages from './PublicPages';
import Dashboard from './App';
import amplifyConfig from '../Amplify';
import { ReduxState } from '../types';
import LinkInstrument from '../link-instrument/components';
import HelpCenter, { docHelpPaths } from '../utils/HelpCenter';

// Pcr protocols
import routes from '../core/routes';

export type Props = {
  isLoadingSession: boolean;
  loadUserSession: typeof loadUserSessionAction;
};

export class MainImpl extends Component<Props> {
  componentDidMount() {
    Amplify.configure(amplifyConfig);
    const { loadUserSession } = this.props;
    loadUserSession();
  }

  render() {
    const { isLoadingSession } = this.props;
    if (isLoadingSession) {
      return <Loader />;
    }
    return (
      <>
        <ToastContainer icon={false} theme="colored" />
        <Switch>
          <Route exact path={routes.LOGOUT} component={Logout} />
          <Route exact path={routes.LINK_INSTRUMENT} component={LinkInstrument} />
          {/* App routes */}
          <PrivateRoute path={routes.APP} component={Dashboard} />
          {/* Public routes */}
          <ExternalRedirect
            exact
            path={routes.TOU}
            to={new HelpCenter().helpUrl(docHelpPaths.Terms_Of_Use)}
          />
          <Route exact path={routes.VERIFY_ACCOUNT} component={VerifyAccount} />
          <Route exact path={routes.COMPLETE_REGISTRATION} component={CompleteRegistration} />
          <Route component={PublicPages} />
        </Switch>
      </>
    );
  }
}

function mapStateToProps(state: ReduxState) {
  return {
    isLoadingSession: state.auth.get('isLoadingSession')
  };
}

export default withRouter(
  // @ts-ignore
  connect(mapStateToProps, { loadUserSession: loadUserSessionAction })(MainImpl)
);
