import React from 'react';

export type Props = {
  text: string;
  itemKey: string;
};

export default function PopupMenuItemImpl(props: Props) {
  const { text } = props;
  const { itemKey } = props;
  return (
    <>
      <svg />
      <span key={itemKey}>{text}</span>
    </>
  );
}
