import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Map } from 'immutable';
import EntityRow from '../common/tables/EntityRow';
import { isMac } from '../../utils/commonUtils';
import { PopupMenuItem, popupMenuCommands } from '../common/menus/PopupMenu';
import { UserFile } from '../../common/types';
import FeatureFlags, { FeatureFlagKeys } from '../feature_flags';

export type Props = {
  item: Map<string, any>;
  tableBodyRef: any;
  archiveProtocols: (entityIdList: string[]) => Promise<void>;
  renameProtocol: (entityId: string, newName: string) => Promise<void>;
  openProtocolRoute: string;
  exportProtocol?: (fileEntity: any) => Promise<void>;
  moveFile?: (entity: UserFile) => unknown;
  fileHistory?: (entity: UserFile) => unknown;
  canEditProjectFiles?: boolean;
};

export type State = {
  redirectToProtocol: boolean;
};

export default class ProtocolRow extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      redirectToProtocol: false
    };
  }

  handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      this.openProtocol(event);
    }
  };

  openProtocol = (event: any) => {
    const { item, openProtocolRoute } = this.props;
    event.preventDefault();
    event.stopPropagation();
    if ((event.ctrlKey && !isMac()) || (event.metaKey && isMac())) {
      const id = item.get('id');
      const url = `${openProtocolRoute}/${id}`;
      window.open(url, '_blank');
    } else {
      this.setState({ redirectToProtocol: true });
    }
  };

  createMenuItemsList = () => {
    const {
      renameProtocol,
      archiveProtocols,
      exportProtocol,
      moveFile,
      fileHistory,
      canEditProjectFiles
    } = this.props;
    const menuItems: Array<PopupMenuItem> = [];
    const canEditFile = canEditProjectFiles == null || canEditProjectFiles;

    menuItems.push({
      menuItemKey: popupMenuCommands.rename,
      menuItemCallback: renameProtocol,
      menuItemTitle: 'Edit Protocol',
      menuItemDisabled: !canEditFile
    });

    if (moveFile) {
      menuItems.push({
        menuItemKey: popupMenuCommands.move,
        menuItemCallback: moveFile
      });
    }
    if (exportProtocol != null)
      menuItems.push({
        menuItemKey: popupMenuCommands.export,
        menuItemCallback: this.exportProtocol,
        menuItemTitle: 'Export Protocol',
        menuItemText: 'Export PRCL'
      });

    if (FeatureFlags().get(FeatureFlagKeys.FILE_HISTORY) && fileHistory) {
      menuItems.push({
        menuItemKey: popupMenuCommands.fileHistory,
        menuItemCallback: fileHistory
      });
    }
    menuItems.push({
      menuItemKey: popupMenuCommands.separator,
      menuItemCallback: null
    });
    menuItems.push({
      menuItemKey: popupMenuCommands.archive,
      menuItemCallback: archiveProtocols,
      menuItemTitle: 'Archive Protocol',
      menuItemDisabled: !canEditFile
    });
    return menuItems;
  };

  exportProtocol = async () => {
    const { exportProtocol, item } = this.props;
    if (exportProtocol == null) return;
    await exportProtocol(item);
  };

  render() {
    const { item, tableBodyRef, openProtocolRoute } = this.props;
    const id = item.get('id');

    const { redirectToProtocol } = this.state;
    const protocolPath = `${openProtocolRoute}/${id}`;
    if (redirectToProtocol) {
      return <Redirect push to={protocolPath} />;
    }
    return (
      <EntityRow
        id={`protocol-name_${item.get('id')}`}
        linkId={`protocol-name-link_${item.get('id')}`}
        item={item}
        tableBodyRef={tableBodyRef}
        canOpen
        popupItems={this.createMenuItemsList()}
        linkedItemName="Protocol"
        onClick={this.openProtocol}
        onKeyDown={this.handleKeyDown}
        entityPath={protocolPath}
      />
    );
  }
}
