import React, { PureComponent, ReactElement } from 'react';
import { Popover, OverlayTrigger } from 'react-bootstrap';
import classnames from 'classnames';
import { Set } from 'immutable';
import { Map } from 'immutable';
import {
  formatWellDisplayContent,
  getSortedFluorContent,
  getSortedWellFluorMap,
  getSampleTypeColor,
  getFluorColor,
  getTargetColorByIndex
} from '../../../utils/microplateUtils';
import { toScientificNotation } from '../../../frontend-common-libs/src/common/numbers';
import WellButton from './WellButton';
import { ReplicateFormatterFunction } from '../../../selectors/current-cfx-run-selectors';

const wellKeys = {
  COND1: 'sample',
  COND2: 'biogroup',
  FLUORS: 'fluors',
  QUANTITY: 'quantity',
  REPLICATE: 'replicate',
  TYPE: 'type'
};

type WellProps = {
  well?: Map<string, Map<string, any>>;
  setWellRef: (...args: Array<any>) => any;
  wellId: string;
  wellReplicateNumberFormatter: ReplicateFormatterFunction;
  isSelected?: boolean;
  isHighlighted?: boolean;
  editMode?: boolean;
  plateTargets?: Map<string, any>;
  isExcluded: boolean;
};

function makeKey(idx: number, target: string) {
  return `${idx}-${target}`;
}

class Well extends PureComponent<WellProps> {
  static defaultProps = {
    well: undefined,
    editMode: false,
    isSelected: false,
    isHighlighted: false,
    plateTargets: undefined
  };

  static contentObjectToJSX(content: { [key: string]: any }): ReactElement[] {
    return Object.keys(content).map(key => (
      <span key={key} className="well-table-popover-info">
        <div className="info-item">
          <div className="caption">{key}</div> <div>{content[key]}</div>
        </div>
      </span>
    ));
  }

  setRef = (ref: HTMLElement | null | undefined) => {
    const { setWellRef, wellId } = this.props;
    setWellRef(ref, wellId);
  };

  getConcentration = (well: Map<string, any>): number | typeof undefined => {
    if (well.get('type') === 'standard') {
      const concentrations = well
        .get('fluors')
        .reduce(
          (accum: Set<string>, channel: Map<string, any>) =>
            accum.add(channel.get('concentration')),
          Set()
        );
      if (concentrations.size === 1) {
        return concentrations.first();
      }
    }
    return undefined;
  };

  getContent(well: Map<string, any>) {
    const { wellReplicateNumberFormatter } = this.props;
    const content: Record<string, string> = {};
    const NA = 'N/A';
    const { targets, fluors } = getSortedFluorContent(well, true);
    content['Target(s): '] = targets.join(', ') || NA;
    content['Fluor(s): '] = fluors.join(', ') || NA;
    content['Sample: '] = well.get(wellKeys.COND1) ? well.get(wellKeys.COND1) : NA;

    const formattedReplicate = wellReplicateNumberFormatter(
      well.get(wellKeys.TYPE),
      well.get(wellKeys.REPLICATE)
    );

    content['Content: '] = formatWellDisplayContent(well.get(wellKeys.TYPE), formattedReplicate);
    content['Bio. Group: '] = well.get(wellKeys.COND2) ? well.get(wellKeys.COND2) : NA;
    return content;
  }

  renderPopoverContent = (
    well: Map<string, any>
  ): {
    [key: string]: any;
  } => {
    const content = this.getContent(well);

    return <div>{Well.contentObjectToJSX(content)}</div>;
  };

  renderSampleNameOrConcentration = (well: Map<string, any>) => {
    const type = well.get(wellKeys.TYPE);
    if (type === 'standard') {
      const concentration = this.getConcentration(well);
      if (concentration === undefined) return null;
      return (
        <div className="text-ellipsis">
          {typeof concentration === 'number' ? toScientificNotation(concentration) : concentration}
        </div>
      );
    }
    const sample = well.get(wellKeys.COND1);
    if (!sample) return null;
    return <div className="text-ellipsis">{sample}</div>;
  };

  renderTargets = (well: Map<string, any>) => {
    const { wellId, plateTargets } = this.props;
    const fluorMap = getSortedWellFluorMap(well);
    const maxTargetsWithoutWrap = 3;
    let idx = 0;
    return fluorMap.reduce((targets: ReactElement[], fluor, channel) => {
      const color = getFluorColor(fluor.get('name'), channel);
      let wellTargetStyles = {
        border: `solid 1px ${color.get('primary')}`,
        backgroundColor: color.get('secondary')
      };
      if (plateTargets) {
        const targetIndex = plateTargets.get(fluor.get('target'));
        if (targetIndex !== undefined) {
          const targetColors = getTargetColorByIndex(targetIndex);
          wellTargetStyles = {
            border: `solid 1px ${targetColors.get('primary')}`,
            backgroundColor: targetColors.get('secondary')
          };
        }
      }
      const targetDiv = (
        <div
          id={`well-target-${wellId}-${idx}`}
          className={classnames('well-target', {
            'well-target-50': fluorMap.size > maxTargetsWithoutWrap
          })}
          style={wellTargetStyles}
          key={makeKey(idx, fluor.get('name'))}
        >
          {fluor.get('target') || fluor.get('name')}
        </div>
      );
      idx += 1;
      targets.push(targetDiv);
      return targets;
    }, []);
  };

  renderFilledWellContent = (well: Map<string, any>) => {
    const wellColors = getSampleTypeColor(well.get('type'));
    const wellStyles = {
      border: `solid 1px ${wellColors.get('primary')}`,
      backgroundColor: wellColors.get('secondary')
    };
    const { wellId } = this.props;
    const content = this.getContent(well);
    return (
      <>
        <div id={`well-sample-${wellId}`} className="well-content-box" style={wellStyles}>
          <div className="text-ellipsis">{content['Content: ']}</div>
          {this.renderSampleNameOrConcentration(well)}
        </div>
        {this.renderTargets(well)}
      </>
    );
  };

  renderFilledWell(well: Map<string, any>) {
    const { editMode, wellId, isExcluded, isSelected, isHighlighted } = this.props;
    if (editMode) {
      return this.renderFilledWellContent(well);
    }

    const popoverHoverFocus = (
      <Popover id="popover-trigger-hover-focus" className="wells-popover">
        {this.renderPopoverContent(well)}
      </Popover>
    );

    return (
      <OverlayTrigger trigger={['hover', 'focus']} overlay={popoverHoverFocus}>
        <div className="well-button-wrapper">
          <WellButton
            className={classnames({
              unselected: !isExcluded && !isSelected,
              excluded: isExcluded,
              'well-highlighted': isHighlighted
            })}
            disabled={isExcluded}
            id={wellId}
          >
            {wellId}
          </WellButton>
        </div>
      </OverlayTrigger>
    );
  }

  renderUnfilledWell() {
    const { editMode, wellId } = this.props;
    if (editMode) {
      return null;
    }

    return (
      <div className="well-button-wrapper">
        <WellButton className="no-data" id={wellId} disabled />
      </div>
    );
  }

  render() {
    const { wellId, well, editMode, isSelected } = this.props;
    return (
      <div
        id={`well-${wellId}`}
        className={classnames('well-unit', { active: isSelected })}
        ref={editMode || well ? this.setRef : undefined}
      >
        {well ? this.renderFilledWell(well) : this.renderUnfilledWell()}
      </div>
    );
  }
}

export default Well;
