import request from '../../frontend-common-libs/src/utils/httpUtils';
import { GATEWAY } from '../../frontend-common-libs/src/config/config';

const baseUrl = GATEWAY.AUTH_SERVICE_PATHS.BASE_URL;

export async function lookupUserCode(userCode: string) {
  const path = GATEWAY.AUTH_SERVICE_PATHS.DEVICE_FLOW_CODE(userCode);
  const url = `${baseUrl}${path}`;
  return request.unauthGet(url);
}

export async function addAuthCode(userCode: string, authCode: string) {
  const path = GATEWAY.AUTH_SERVICE_PATHS.DEVICE_FLOW_CODE(userCode);
  const url = `${baseUrl}${path}`;
  return request.unauthPatch(url, { authCode });
}
