import { PcrProtocolContent } from '../../../frontend-common-libs/src/components/pcr/pcr_protocol_types';

// eslint-disable-next-line import/prefer-default-export
export function getDefaultProtocol(): PcrProtocolContent {
  return {
    vol: 20,
    lidTemp: { mode: 'default' },
    steps: [
      { type: 'temp', temp: 95, time: 180 },
      { type: 'temp', temp: 95, time: 10 },
      { type: 'temp', temp: 55, time: 30 },
      { type: 'goto', step: 1, cycles: 39 }
    ]
  };
}
