import React from 'react';
import { USER_FILES } from '../../../common/project-management-types';
import Breadcrumbs from './Breadcrumbs';

export type Props = {
  filePath: string;
  id: string;
  projectName?: string;
};

export default function FileBreadcrumb(props: Readonly<Props>) {
  const { id, filePath, projectName } = props;
  const project = projectName ?? USER_FILES;
  const breadcrumbs = [project, filePath];
  return <Breadcrumbs id={id} breadcrumbs={breadcrumbs} />;
}
