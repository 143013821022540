import { Jwt, ProjectRoles } from '@biorad-lsg-tsc/organization';

export const projectRoleIcon = {
  [ProjectRoles.ProjectAdmin]: '/img/project-role-admin.svg',
  [ProjectRoles.Contributor]: '/img/project-role-contributor.svg',
  [ProjectRoles.Reviewer]: '/img/project-role-reviewer.svg'
};

export function getRole(accessToken: string | undefined) {
  if (!accessToken) return undefined;
  let projectRole;
  try {
    const decoded = Jwt.decodeJwt(accessToken) as any;
    const { roles } = decoded;
    [projectRole] = roles;
    return projectRole;
  } catch {
    return undefined;
  }
}
