import React from 'react';

export type UserPreferencesSidebarItem = {
  displayText: string;
  id: string;
  renderComponent: () => React.ReactNode;
};

type Props = {
  userPreferences: Array<UserPreferencesSidebarItem>;
  onUserPreferenceItemClick: (userPreferenceId: string) => void;
};

const UserPreferencesSidebar = ({ userPreferences, onUserPreferenceItemClick }: Props) => {
  function handleOnUserPreferenceItemClick(event: React.MouseEvent<HTMLButtonElement>) {
    const { currentTarget } = event;
    onUserPreferenceItemClick(currentTarget.id);
  }

  const userPreferencesButtons = userPreferences.map(
    ({ displayText, id }: UserPreferencesSidebarItem) => (
      <button
        className="user-preference-sidebar-item"
        key={id}
        id={id}
        onClick={handleOnUserPreferenceItemClick}
      >
        {displayText}
      </button>
    )
  );

  return <>{userPreferencesButtons}</>;
};

export default UserPreferencesSidebar;
