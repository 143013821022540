import React from 'react';
import './styles/organization-tabs.scss';
import classNames from 'classnames';
import { NavLink, withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import OrgPeople from './OrgPeople';
import UserProjects from '../../project-management/manage-projects/UserProjects';
import { OrgTab } from '../router/OrgRoutes';

export type Props = {
  orgId: string;
  match: RouteComponentProps['match'];
};

export function OrganizationTabsImpl(props: Readonly<Props>) {
  const { orgId, match } = props;
  const selectedTab = (match.params as Record<string, string>).orgTab;

  const renderTabHeader = (orgTab: OrgTab) => {
    const { url } = match;
    const baseUrl = url.substring(0, url.lastIndexOf('/'));
    return (
      <NavLink
        id={orgTab}
        to={`${baseUrl}/${orgTab}`}
        className={classNames('org-resource', {
          'selected-resource': selectedTab === orgTab
        })}
      >
        {orgTab}
      </NavLink>
    );
  };

  const renderTabContent = () => {
    switch (selectedTab) {
      case OrgTab.projects:
        // @ts-ignore
        return <UserProjects />;
      case OrgTab.members:
      default:
        // @ts-ignore
        return <OrgPeople orgId={orgId} />;
    }
  };

  return (
    <div className="org-tabs">
      <div className="org-resources">
        {renderTabHeader(OrgTab.members)}
        {renderTabHeader(OrgTab.projects)}
      </div>
      {renderTabContent()}
    </div>
  );
}

// @ts-ignore
export default withRouter<Props>(OrganizationTabsImpl);
