import AnalyticsEvent from './AnalyticsEvent';
import { PageViewedSystemEvent } from '../page-viewed-system-event-publisher';
import { RouteName, RoutePattern, RoutePath } from '../frontend-common-libs/plugin-types';

type PageViewSystemEventParams = {
  toPath: RoutePath;
  toRouteName: RouteName;
  toRoute: RoutePattern;
  fromPath?: RoutePath;
  fromRouteName?: RouteName;
  fromRoute?: RoutePattern;
};

export default class PageViewAnalyticsEvent implements AnalyticsEvent {
  constructor(event: PageViewedSystemEvent) {
    this.systemEvent = event;
  }

  public readonly name = 'page view';

  private readonly systemEvent: PageViewedSystemEvent;

  public get params(): PageViewSystemEventParams {
    const { toPath, toRouteName, toRoute, fromPath, fromRouteName, fromRoute } = this.systemEvent;
    const eventParams: PageViewSystemEventParams = {
      toPath,
      toRouteName,
      toRoute
    };

    if (fromPath) {
      eventParams.fromPath = fromPath;
    }

    if (fromRouteName) {
      eventParams.fromRouteName = fromRouteName;
    }

    if (fromRoute) {
      eventParams.fromRoute = fromRoute;
    }

    return eventParams;
  }
}
