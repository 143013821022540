import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import stopRunStyles from './styles/stop-run-modal.module.scss';
import instrumentFleetModalStyles from '../create-reservations-tab/instrument-fleet-modal/styles/instrument-fleet-modal.module.scss';
import Alert from '../../../../img/alert.svg';

export type Props = {
  instrumentNames: string[];
  handleStopRun: () => Promise<void>;
  onClose: () => void;
  show: boolean;
  stopRunAllowed: boolean;
};

export default function StopRunModal(props: Readonly<Props>) {
  const { instrumentNames, handleStopRun, onClose, show, stopRunAllowed } = props;
  const displayInstrumentList = () => {
    const { listContainer } = stopRunStyles;
    return (
      <ul className={listContainer} style={{ listStyleType: 'none' }}>
        {instrumentNames.map((name, key) => {
          return <li key={`${key}-${name}`}>{name}</li>;
        })}
      </ul>
    );
  };
  const stopRunModalBody = () => {
    const { centerContent, alertImage, contentHeader, contentMessage, contentInstrumentsList } =
      stopRunStyles;
    const { fleetReservationModalBody } = instrumentFleetModalStyles;
    return (
      <Modal.Body className={fleetReservationModalBody}>
        <div className={centerContent}>
          <div>
            <img className={alertImage} src={Alert} alt="Alert Verification" />
          </div>
          <div className={contentHeader}>Stop Run(s)</div>
          <div className={contentMessage}>Are you sure you want to stop the selected run(s)?</div>
          <div id="stop-run-modal-instrument-list" className={contentInstrumentsList}>
            {displayInstrumentList()}
          </div>
        </div>
      </Modal.Body>
    );
  };

  const stopRunModalFooter = () => {
    const { cancelBtn, stopBtn } = stopRunStyles;
    const { fleetReservationModalFooter } = instrumentFleetModalStyles;
    return (
      <Modal.Footer className={fleetReservationModalFooter}>
        <Button
          className={cancelBtn}
          onClick={onClose}
          aria-label="cancel"
          id="stop-run-modal-cancel-button"
        >
          Cancel
        </Button>
        <Button
          className={stopBtn}
          onClick={handleStopRun}
          disabled={!stopRunAllowed}
          aria-label="stop"
          id="stop-run-modal-stop-button"
        >
          Stop
        </Button>
      </Modal.Footer>
    );
  };

  return (
    <Modal
      backdrop="static"
      className={stopRunStyles.stopButtonModal}
      onHide={onClose}
      show={show}
      data-testid="stop-run-modal"
    >
      {stopRunModalBody()}
      {stopRunModalFooter()}
    </Modal>
  );
}
