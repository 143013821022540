// @ts-nocheck
import React from 'react';

type Props = {
  index: number;
  title: string;
  image: string;
  textContentItems: Array<string>;
};

export default ({ index, title, image, textContentItems }: Props) => {
  const textListItems = textContentItems.map((textItem, listItemIndex) => (
    <li key={`benefitbox-listitem-${listItemIndex + 1}`}>{textItem}</li>
  ));

  return (
    <div className="benefit-box-container">
      <img src={image} alt={`Benefit Icon ${index + 1}`} />
      <div className="benefit-text-wrapper">
        <h3>{title}</h3>
        <ul>{textListItems}</ul>
      </div>
    </div>
  );
};
