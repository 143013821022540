/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import { Route, Link } from 'react-router-dom';
import className from 'classnames';
import { connect } from 'react-redux';
import routes from '../../core/routes';
import FeatureFlags, {
  FeatureFlagKeys
} from '../../frontend-common-libs/src/components/feature_flags';
import OrgProjectSelector from '../../project-management/manage-projects/project-dropdown/OrgProjectSelector';
import {
  canEditFilesInProject,
  getSelectedProjectId
} from '../../project-management/selectors/selectors';
import { ReduxState } from '../../types';

type SideBarLinkProps = {
  id: string;
  title: string;
  to: string;
  imgSrc: string;
  selectedImgSrc: string;
  showText?: boolean;
  disabled?: boolean;
};

const links = [
  {
    id: 'home-page',
    to: routes.HOME,
    title: 'Home',
    imgSrc: '/img/home.svg',
    selectedImgSrc: '/img/home-selected.svg'
  },
  {
    id: 'create-experiment',
    to: routes.CREATE_EXPERIMENT,
    title: 'Create',
    imgSrc: '/img/create-experiment-icon.svg',
    selectedImgSrc: '/img/create-experiment-selected.svg'
  },
  {
    id: 'files',
    to: routes.FILE_LIST,
    title: 'Files',
    imgSrc: '/img/files-icon.svg',
    selectedImgSrc: '/img/files-selected.svg'
  },
  {
    id: 'instruments',
    to: routes.INSTRUMENT_LIST,
    title: 'Instruments',
    imgSrc: '/img/instruments-icon.svg',
    selectedImgSrc: '/img/instruments-selected.svg'
  },
  {
    id: 'archive',
    to: routes.ARCHIVE,
    title: 'Archive',
    imgSrc: '/img/archive.svg',
    selectedImgSrc: '/img/archive-selected.svg'
  }
];

const fleetLink: SideBarLinkProps = {
  id: 'fleet',
  to: routes.FLEET,
  title: 'Fleet',
  imgSrc: '/img/fleet.svg',
  selectedImgSrc: '/img/fleet-selected.svg'
};

export function SideBarLink({
  id,
  title,
  to,
  imgSrc,
  selectedImgSrc,
  showText,
  disabled
}: Readonly<SideBarLinkProps>) {
  return (
    <Route path={to}>
      {({ match }) => (
        <li>
          <Link
            id={id}
            to={to}
            title={title}
            className={className('button-link', { selected: match }, { 'disabled-link': disabled })}
            tabIndex={match || disabled ? -1 : 0}
          >
            <div className="left-nav-item">
              <div className={className('img-container', { 'disabled-color': disabled })}>
                <img src={match ? selectedImgSrc : imgSrc} alt="img" />
              </div>
              {showText && (
                <p
                  className={className(
                    'img-text',
                    { selected: match },
                    { 'disabled-color': disabled }
                  )}
                >
                  {title.toUpperCase()}
                </p>
              )}
            </div>
          </Link>
        </li>
      )}
    </Route>
  );
}

type SideBarProps = {
  canEditProjectFiles?: boolean;
};
type SideBarState = {
  isExpanded: boolean;
};

export class LeftSideBarImpl extends React.Component<SideBarProps, SideBarState> {
  constructor(props: SideBarProps) {
    super(props);
    this.state = { isExpanded: false };
  }

  onExpandButtonClick = () => {
    this.setState(state => ({ isExpanded: !state.isExpanded }));
  };

  renderProjectDropdown() {
    if (FeatureFlags().get(FeatureFlagKeys.PROJECT_REVIEWER)) return null;
    const { isExpanded } = this.state;
    return (
      <li className="left-nav-item">
        {/* @ts-ignore */}
        <OrgProjectSelector showProjectName={isExpanded} />
      </li>
    );
  }

  render() {
    const { isExpanded } = this.state;
    const { canEditProjectFiles } = this.props;
    const expandCollapseImg = isExpanded ? '/img/collapse.svg' : '/img/expand.svg';
    const expandCollapseTitle = isExpanded ? 'collapse' : 'expand';
    fleetLink.disabled = !canEditProjectFiles;
    const renderedLinks = FeatureFlags().get(FeatureFlagKeys.FLEET_MANAGEMENT)
      ? [...links, fleetLink]
      : links;
    return (
      <div className="left-navbar">
        <ul
          className={className('list-unstyled side-menu-list', {
            expanded: isExpanded
          })}
        >
          {this.renderProjectDropdown()}
          {renderedLinks.map(i => (
            <SideBarLink key={i.id} showText={isExpanded} {...i} />
          ))}
          <li className="expand-li">
            <button
              id="expand-collapse-button"
              type="button"
              className="outer-div expand-btn"
              title={expandCollapseTitle}
              onClick={this.onExpandButtonClick}
            >
              <img src={expandCollapseImg} className="expand-img" alt="Expand collapse icon" />
            </button>
          </li>
        </ul>
      </div>
    );
  }
}

export function mapStateToProps(state: ReduxState) {
  const projectId = getSelectedProjectId(state);
  return { canEditProjectFiles: canEditFilesInProject(state, projectId) };
}

export default connect(mapStateToProps, {})(LeftSideBarImpl);
