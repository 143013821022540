import UserAnalyticsClient from './UserAnalyticsClient';

export default class HelpPageViewedTrackingEvent {
  private readonly eventName = 'help-page-viewed';

  private readonly toRoute: string | undefined;

  constructor(toRoute: string | undefined) {
    this.toRoute = toRoute;
  }

  public track(): void {
    UserAnalyticsClient.getInstance().track(this.eventName, { toRoute: this.toRoute });
  }
}
