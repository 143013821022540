import { ComponentType } from 'react';
import RemotePluginManifest from './RemotePluginManifest';
import withNoOpFallback from './NoOp';
import Spinner from '../frontend-common-libs/src/components/common/Spinner';
import withComponentLoader from './PluginComponentLoader';
import ArtifactsLoader from './ArtifactsLoader';
import withComponentRenderLoader from './PluginComponentRenderLoader';
import InstrumentRowSkeletonLoader from './remote-plugin-components/skeleton-loaders/InstrumentRowSkeletonLoader';
import FileRowSkeletonLoader from './remote-plugin-components/skeleton-loaders/FileRowSkeletonLoader';
import RecentFileRowSkeletonLoader from './remote-plugin-components/skeleton-loaders/RecentFileRowSkeletonLoader';

export function EmptyLoader() {
  return null;
}

export default class RemotePluginComponents {
  constructor(manifest: RemotePluginManifest) {
    this.manifest = manifest;
    const { name, artifactURLs } = this.manifest;
    this.artifactsLoader = new ArtifactsLoader(name, artifactURLs);
  }

  private readonly manifest: RemotePluginManifest;

  private readonly artifactsLoader: ArtifactsLoader;

  private async getComponent(componentName: string): Promise<ComponentType<any>> {
    await this.artifactsLoader.load();

    const brioNamespace = window.brio;
    const pluginNamespace = brioNamespace && brioNamespace[this.manifest.namespace];
    const components = pluginNamespace && pluginNamespace.components;

    const component = components && components[componentName];

    return withNoOpFallback(component);
  }

  private async getComponentRenderer(
    componentName: string
  ): Promise<(props: {}) => Promise<string> | undefined> {
    await this.artifactsLoader.load();

    const brioNamespace = window.brio;
    const pluginNamespace = brioNamespace && brioNamespace[this.manifest.namespace];
    const components = pluginNamespace && pluginNamespace.components;
    return components && components[componentName];
  }

  public get app(): ComponentType<any> {
    const getAppPromise = this.getComponentRenderer('app');
    const idPrefix = 'app';
    return withComponentRenderLoader(getAppPromise, EmptyLoader, idPrefix);
  }

  public get instrumentTile(): ComponentType<any> {
    const getInstrumentTilePromise = this.getComponent('instrumentTile');
    return withComponentLoader(getInstrumentTilePromise, Spinner);
  }

  public get instrumentRow(): ComponentType<any> {
    const getInstrumentRowPromise = this.getComponent('instrumentRow');
    return withComponentLoader(getInstrumentRowPromise, InstrumentRowSkeletonLoader);
  }

  public get fileRow(): ComponentType<any> {
    const getFileRowPromise = this.getComponent('fileRow');
    return withComponentLoader(getFileRowPromise, FileRowSkeletonLoader);
  }

  public get recentFileRow(): ComponentType<any> {
    const getRecentFileRow = this.getComponent('recentFileRow');
    return withComponentLoader(getRecentFileRow, RecentFileRowSkeletonLoader);
  }

  public get archiveRow(): ComponentType<any> {
    const getArchiveRowPromise = this.getComponent('archiveRow');
    return withComponentLoader(getArchiveRowPromise, Spinner);
  }

  public get userPreferenceDetail(): ComponentType<any> {
    const getUserPreferenceDetailPromise = this.getComponent('userPreferenceDetail');
    return withComponentLoader(getUserPreferenceDetailPromise, Spinner);
  }

  public get pageTitle(): ComponentType<any> {
    const pageTitle = this.getComponentRenderer('pageTitle');
    const idPrefix = 'page-title';
    return withComponentRenderLoader(pageTitle, EmptyLoader, idPrefix);
  }

  public get helpLink(): ComponentType<any> {
    const helpLink = this.getComponent('helpLink');
    return withComponentLoader(helpLink, Spinner);
  }

  public get fleetManagement(): ComponentType<any> {
    const getFleetManagementPromise = this.getComponent('fleetManagement');
    return withComponentLoader(getFleetManagementPromise, Spinner);
  }
}
