import React, { Component } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import changePwd from '../../actions/changePassword_actions';
import PasswordChangeForm from './PasswordChangeForm';
// @ts-ignore
import { PwdOnlyInfo } from './PasswordChangeForm';
import IncorrectPasswordError from '../../actions/IncorrectPasswordError';
import notification from '../../frontend-common-libs/src/utils/notifications';

type Props = {};

type State = {
  changeComplete: boolean;
  error?: string;
};

export class PasswordChangeImpl extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      changeComplete: false
    };
  }

  handleFormSubmit = async ({ password1, password2 }: PwdOnlyInfo) => {
    try {
      await changePwd({
        oldPwd: password1,
        newPwd: password2
      });
      this.setState({
        changeComplete: true
      });
    } catch (err) {
      if (err instanceof IncorrectPasswordError) {
        this.setState({
          error: 'Incorrect password'
        });
      } else {
        notification.error('Unknown error, please try again');
      }
    }
  };

  handleOldPasswordChanged = () => {
    const { error } = this.state;
    if (error) {
      this.setState({
        error: undefined
      });
    }
  };

  render() {
    const { changeComplete, error } = this.state;
    return changeComplete ? (
      <Redirect to="/" />
    ) : (
      <PasswordChangeForm
        // @ts-ignore
        extraError={error}
        onSubmit={this.handleFormSubmit}
        onOldPasswordChanged={this.handleOldPasswordChanged}
      />
    );
  }
}

// @ts-ignore
export default withRouter(PasswordChangeImpl);
