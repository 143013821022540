import React, { PureComponent } from 'react';
import PCRProtocolSteps from '../PCRProtocolSteps';
import PCRProtocolStepSubSection from '../PCRProtocolStepSubSection';
import PCRProtocolStepEditorModal from '../PCRProtocolStepEditorModal';
import EditStepContainer from '../EditStepContainer';
import IntFieldGroup from '../../../common/IntFieldGroup';
import {
  isValidTemperature,
  isValidEndMeltTemperature,
  isValidIncrement,
  isValidInterval
} from '../PCREditorValidateInput';
import QPcrStep from '../models/QPcrStep';
import { StepValue } from '../../pcr_protocol_types';
import { ProtocolStepProps as ProtocolMeltStepProps } from '../PCRProtocolStepTypes';
import { parseFloatIfNot } from '../../../../common/numbers';
import FloatFieldGroup from '../../../common/FloatFieldGroup';

export type State = {
  editStep: QPcrStep;
};

export class PCRProtocolMeltStepImpl extends PureComponent<ProtocolMeltStepProps, State> {
  static defaultProps = {
    selectedIndex: 0
  };

  constructor(props: ProtocolMeltStepProps) {
    super(props);
    const { editing } = this.props;
    if (editing) {
      this.state = { editStep: new QPcrStep(props.step) };
    }
  }

  componentWillReceiveProps(nextProps: ProtocolMeltStepProps) {
    const { editing } = this.props;
    if (nextProps.editing && !editing) {
      this.setState({ editStep: new QPcrStep(nextProps.step) });
    }
  }

  onEditSave = () => {
    const { saveEditAction, index } = this.props;
    const { editStep } = this.state;
    saveEditAction(index, editStep.map);
  };

  onEditCancel = () => {
    const { cancelEditAction } = this.props;
    cancelEditAction();
  };

  onFieldChanged = (key: string, value?: StepValue) => {
    const { editStep } = this.state;
    const newStep = editStep.set(key, value);
    this.setState({ editStep: newStep });
  };

  selectIndex = () => {
    const { setSelectedStepIndex, index } = this.props;
    setSelectedStepIndex(index);
  };

  renderEditStep() {
    const {
      editStep: { temp, end, inc, interval }
    } = this.state;
    const { isAdd, index, stepsList, editStepTypeChangeAction } = this.props;
    const temperatureValid = isValidTemperature(parseFloatIfNot(temp), 0, 100, true);
    const endTemperatureValid = isValidEndMeltTemperature(
      parseFloatIfNot(end),
      parseFloatIfNot(temp)
    );

    let incrementLow = -10;
    let incrementHigh = 10;
    if (temperatureValid.valid && endTemperatureValid.valid) {
      const startEndDifference = end - temp;
      if (startEndDifference > 0) {
        incrementLow = 0.1;
        incrementHigh = Math.min(startEndDifference, 10);
      } else {
        incrementLow = -0.1;
        incrementHigh = Math.max(startEndDifference, -10);
      }
    }

    const incValid = isValidIncrement(parseFloatIfNot(inc), incrementLow, incrementHigh, true);
    const intervalValid = isValidInterval(
      interval,
      1,
      18 * 60 * 60, // 18 hours
      true
    );

    const saveValid =
      temperatureValid.valid && endTemperatureValid.valid && incValid.valid && intervalValid.valid;

    return (
      <PCRProtocolStepEditorModal
        onSave={saveValid ? this.onEditSave : undefined}
        onCancel={this.onEditCancel}
        isAdd={isAdd}
        index={index}
      >
        <EditStepContainer
          currentType="melt"
          stepsList={stepsList}
          onEditStepTypeChange={editStepTypeChangeAction}
        >
          <li>
            <div className="col-xs-12 form-group ">
              <FloatFieldGroup
                id="start-temp-edit"
                name="temp"
                label="Start Temperature"
                subLabel="(&#176;C)"
                error={temperatureValid.helpString}
                value={temp}
                onChange={this.onFieldChanged}
                onBlur={this.onFieldChanged}
                validationState={temperatureValid.valid ? null : 'error'}
                allowNegative={false}
              />
            </div>
          </li>
          <li>
            <div className="col-xs-12 form-group ">
              <FloatFieldGroup
                id="end-temp-edit"
                name="end"
                label="End Temperature"
                subLabel="(&#176;C)"
                error={endTemperatureValid.helpString}
                value={end}
                onChange={this.onFieldChanged}
                onBlur={this.onFieldChanged}
                validationState={endTemperatureValid.valid ? null : 'error'}
                allowNegative={false}
              />
            </div>
          </li>
          <li>
            <div className="col-xs-12 form-group ">
              <FloatFieldGroup
                id="inc-edit"
                name="inc"
                label="Increment"
                subLabel="(&#176;C/cycle)"
                error={incValid.helpString}
                value={inc}
                onChange={this.onFieldChanged}
                onBlur={this.onFieldChanged}
                validationState={incValid.valid ? null : 'error'}
              />
            </div>
          </li>
          <li>
            <div className="col-xs-12 form-group ">
              <IntFieldGroup
                id="int-edit"
                name="interval"
                label="Interval"
                subLabel="(sec)"
                error={intervalValid.helpString}
                value={interval}
                onChange={this.onFieldChanged}
                validationState={intervalValid.valid ? null : 'error'}
                allowNegative={false}
              />
            </div>
          </li>
        </EditStepContainer>
      </PCRProtocolStepEditorModal>
    );
  }

  renderViewStep() {
    const { step } = this.props;
    const { tempAsString, endAsString, incAsString, intervalAsString } = new QPcrStep(step);
    const meltStr = `Melt Curve ${tempAsString} to ${endAsString} \xB0C`;

    const meltStr2 = `${incAsString} \xB0C Increment for ${intervalAsString} + Plate Read`;
    return (
      <div role="presentation" onClick={this.selectIndex}>
        {meltStr}
        <PCRProtocolStepSubSection>{meltStr2}</PCRProtocolStepSubSection>
      </div>
    );
  }

  render() {
    const { step, editing, index, deleteAction, selectedIndex, editAction } = this.props;
    if (editing) {
      return this.renderEditStep();
    }
    return (
      <PCRProtocolSteps
        // @ts-ignore
        step={step}
        index={index}
        deleteAction={deleteAction}
        selectedIndex={selectedIndex}
        editAction={editAction}
      >
        {this.renderViewStep()}
      </PCRProtocolSteps>
    );
  }
}

export default PCRProtocolMeltStepImpl;
