import QPCRRun from './QPCRRun';
import { getRuns } from '../../../../api/pcrData';
import { NullableString } from '../../../../frontend-common-libs/src/common/nullable-types';

export default class QPCRRunsList {
  _runs: QPCRRun[];

  _lastSeen: NullableString;

  _plateSize: number | null | undefined;

  _scanMode: NullableString;

  _runsLoaded = false;

  _runId: NullableString;

  _projectId: NullableString;

  constructor(
    plateSize: number | null | undefined,
    scanMode: NullableString,
    runId: NullableString,
    projectId: NullableString
  ) {
    this._plateSize = plateSize;
    this._scanMode = scanMode;
    this._runs = [];
    this._runId = runId;
    this._projectId = projectId;
  }

  get runs(): QPCRRun[] {
    return this._runs;
  }

  get length(): number {
    return this._runs.length;
  }

  get hasMore(): boolean {
    return this._runsLoaded ? !!this._lastSeen : true;
  }

  get noRunsAvailable(): boolean {
    return this.length === 0 && !this.hasMore;
  }

  getRunById(id: string): QPCRRun {
    return this._runs.find((run: QPCRRun) => run.id === id) as any as QPCRRun;
  }

  withRuns(runs: QPCRRun[]) {
    this._runs = runs;
    this._runsLoaded = true;
    return this;
  }

  withLastSeen(lasSeen: string) {
    this._lastSeen = lasSeen;
    return this;
  }

  async getRuns() {
    const response = await getRuns(
      this._plateSize,
      this._scanMode,
      this._lastSeen,
      this._projectId
    );
    const { runs, lastSeen } = response.data;
    let filteredRuns = runs;
    if (runs.length && this._runId) {
      filteredRuns = runs.filter((run: QPCRRun) => run.id !== this._runId);
    }
    const updatedRuns = this._runs.concat(filteredRuns.map(QPCRRun.create));
    return new QPCRRunsList(this._plateSize, this._scanMode, this._runId, this._projectId)
      .withLastSeen(lastSeen)
      .withRuns(updatedRuns);
  }
}
