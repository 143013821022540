import { fromJS, Map } from 'immutable';
import _ from 'lodash';
import {
  NOTIFICATIONS_SETTINGS_ERROR,
  NOTIFICATIONS_SETTINGS_LOADED,
  NOTIFICATIONS_SETTINGS_LOADING,
  SET_DEVICE_TYPE,
  UserPreferencesType,
  ADD_NOTIFICATION_SUBSCRIPTION_STARTED,
  ADD_NOTIFICATION_SUBSCRIPTION_SUCCEEDED,
  ADD_NOTIFICATION_SUBSCRIPTION_ERROR,
  DELETE_NOTIFICATION_SUBSCRIPTION_STARTED,
  DELETE_NOTIFICATION_SUBSCRIPTION_SUCCEEDED,
  DELETE_NOTIFICATION_SUBSCRIPTION_ERROR
} from '../actions/user_preferences/user_preferences_types';
import { ApiAction } from '../types';
import { DeviceTypeNotificationButton } from '../components/header/user_preferences/NotificationsSettingsToolbar';
import { UNAUTH_USER } from '../auth/actions/auth_types';

function createDeviceTypesNotificationsButtonsConfig(notificationsSettings: Array<any>) {
  const buttonsConfig = notificationsSettings.reduce(
    (devices: Array<DeviceTypeNotificationButton>, notificationSetting: any) => {
      const devicesContainNotificationSetting = devices.some(
        (device: DeviceTypeNotificationButton) => device.id === notificationSetting.deviceType
      );
      if (!devicesContainNotificationSetting) {
        devices.push({
          id: notificationSetting.deviceType,
          title: notificationSetting.deviceType
        });
      }
      return devices;
    },
    []
  );

  return _.orderBy(buttonsConfig, ['title'], ['asc']);
}

function removeNotificationSubscription(
  notificationSettingId: string,
  userSubscriptions: string[]
) {
  return userSubscriptions.filter((subscription: string) => subscription !== notificationSettingId);
}

function addNotificationSubscription(
  notificationSettingId: string,
  userSubscriptions: string[]
): string[] {
  return [...userSubscriptions, notificationSettingId];
}
// @ts-ignore
const INITIAL_STATE: Map<string, any> = fromJS({
  isLoading: false,
  notificationsSettings: [],
  deviceTypesNotificationsButtons: [],
  errorMessage: '',
  deviceType: '',
  userSubscriptions: [],
  isUpdatingSubscriptionStatus: false
});

const onNotificationsSettingsLoading = (state: Map<string, any>) => state.set('isLoading', true);

const onNotificationsSettingsLoaded = (
  state: Map<string, any>,
  action: ApiAction<UserPreferencesType, any>
) => {
  const { notificationsSettings, userSubscriptions } = action.payload;
  const deviceTypesNotificationsButtons =
    createDeviceTypesNotificationsButtonsConfig(notificationsSettings);
  const defaultDeviceType = deviceTypesNotificationsButtons
    ? deviceTypesNotificationsButtons[0].id
    : '';
  return state
    .set('notificationsSettings', notificationsSettings)
    .set('isLoading', false)
    .set('deviceTypesNotificationsButtons', deviceTypesNotificationsButtons)
    .set('deviceType', defaultDeviceType)
    .set('userSubscriptions', userSubscriptions);
};

const onNotificationsSettingsError = (
  state: Map<string, any>,
  action: ApiAction<UserPreferencesType, any>
) => {
  const { payload } = action;
  return state.set('errorMessage', payload).set('isLoading', false);
};

function setSubscriptionInProgressToFalse(state: Map<string, any>) {
  return state.set('isUpdatingSubscriptionStatus', false);
}

const onSetDeviceType = (state: Map<string, any>, action: ApiAction<UserPreferencesType, any>) => {
  const { payload } = action;
  return state.set('deviceType', payload);
};

const onAddNotificationSubscriptionSucceeded = (state: Map<string, any>) =>
  setSubscriptionInProgressToFalse(state);

const onDeleteNotificationSubscriptionSucceeded = (state: Map<string, any>) =>
  setSubscriptionInProgressToFalse(state);

const onAddNotificationSubscriptionStarted = (
  state: Map<string, any>,
  action: ApiAction<UserPreferencesType, any>
) => {
  const { notificationSettingId } = action.payload;
  const currentUserSubscriptions = state.get('userSubscriptions');
  return state
    .set('isUpdatingSubscriptionStatus', true)
    .set(
      'userSubscriptions',
      addNotificationSubscription(notificationSettingId, currentUserSubscriptions)
    );
};

const onDeleteNotificationSubscription = (
  state: Map<string, any>,
  action: ApiAction<UserPreferencesType, any>
) => {
  const { notificationSettingId } = action.payload;
  const currentUserSubscriptions = state.get('userSubscriptions');
  return state
    .set('isUpdatingSubscriptionStatus', true)
    .set(
      'userSubscriptions',
      removeNotificationSubscription(notificationSettingId, currentUserSubscriptions)
    );
};

const onAddNotificationSubscriptionError = (
  state: Map<string, any>,
  action: ApiAction<UserPreferencesType, any>
) => {
  const { notificationSettingId } = action.payload;
  const currentUserSubscriptions = state.get('userSubscriptions');
  return state
    .set('isUpdatingSubscriptionStatus', false)
    .set(
      'userSubscriptions',
      removeNotificationSubscription(notificationSettingId, currentUserSubscriptions)
    );
};

const onDeleteNotificationSubscriptionError = (
  state: Map<string, any>,
  action: ApiAction<UserPreferencesType, any>
) => {
  const { notificationSettingId } = action.payload;
  const currentUserSubscriptions = state.get('userSubscriptions');
  return state
    .set('isUpdatingSubscriptionStatus', false)
    .set(
      'userSubscriptions',
      addNotificationSubscription(notificationSettingId, currentUserSubscriptions)
    );
};

const onHandleLogout = () => INITIAL_STATE;

const actionMap = {
  [NOTIFICATIONS_SETTINGS_LOADING]: onNotificationsSettingsLoading,
  [NOTIFICATIONS_SETTINGS_LOADED]: onNotificationsSettingsLoaded,
  [NOTIFICATIONS_SETTINGS_ERROR]: onNotificationsSettingsError,
  [SET_DEVICE_TYPE]: onSetDeviceType,
  [ADD_NOTIFICATION_SUBSCRIPTION_STARTED]: onAddNotificationSubscriptionStarted,
  [ADD_NOTIFICATION_SUBSCRIPTION_SUCCEEDED]: onAddNotificationSubscriptionSucceeded,
  [ADD_NOTIFICATION_SUBSCRIPTION_ERROR]: onAddNotificationSubscriptionError,
  [DELETE_NOTIFICATION_SUBSCRIPTION_STARTED]: onDeleteNotificationSubscription,
  [DELETE_NOTIFICATION_SUBSCRIPTION_SUCCEEDED]: onDeleteNotificationSubscriptionSucceeded,
  [DELETE_NOTIFICATION_SUBSCRIPTION_ERROR]: onDeleteNotificationSubscriptionError,
  [UNAUTH_USER]: onHandleLogout
};

export default function userPreferencesReducer(
  state: Map<string, any> = INITIAL_STATE,
  action: ApiAction<any, any> | undefined = undefined
): Map<string, any> {
  if (action) {
    return actionMap[action.type] ? actionMap[action.type](state, action) : state;
  }
  return state;
}
