import React, { Component, KeyboardEvent } from 'react';
import './styles/tenant-list.scss';
import classNames from 'classnames';
import checkmark from '../../../img/checkmark-dark-gray.svg';
import { Tenant } from '../actions/action-types';

export type Props = {
  tenant: Tenant;
  onClick: (tenant: Tenant) => void;
  tenantIcon?: string;
  isSelected?: boolean;
  isActive?: boolean;
};

export default class TenantRow extends Component<Props> {
  onRowClick = () => {
    const { tenant, onClick } = this.props;
    onClick(tenant);
  };

  handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      this.onRowClick();
    }
  };

  render() {
    const { tenant, isSelected, isActive, tenantIcon } = this.props;
    return (
      <div
        id={`tenant_id_${tenant.id}`}
        role="button"
        className={classNames('tenant-row', { 'row-selected': isSelected })}
        onClick={this.onRowClick}
        onKeyDown={this.handleKeyDown}
        tabIndex={0}
      >
        <div className="tenant-active">
          {isActive && <img src={checkmark} alt="active" id={`active_tenant_${tenant.id}`} />}
        </div>
        <img src={tenantIcon} alt="" className="tenant-icon" />
        <span className="with-ellipsis" title={tenant.name}>
          {tenant.name}
        </span>
      </div>
    );
  }
}
