import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import './styles/org-breadcrumb .scss';
import { ReduxState } from '../../../types';
import orgImg from '../../../../img/organization.svg';
import { getActiveOrgName } from '../../../project-management/selectors/selectors';

export type Props = {
  activeOrgName?: string;
};

export class OrgBreadcrumbImpl extends PureComponent<Props> {
  render() {
    const { activeOrgName } = this.props;
    if (!activeOrgName) return null;
    return (
      <div className="org-breadcrumb">
        <img id="org-img" className="org-img" src={orgImg} alt="org" />
        <span id="active-org-name" className="active-org-name with-ellipsis" title={activeOrgName}>
          {activeOrgName}
        </span>
      </div>
    );
  }
}

export function mapStateToProps(state: ReduxState): {
  [key: string]: any;
} {
  return {
    activeOrgName: getActiveOrgName(state)
  };
}

export default connect(mapStateToProps, {})(OrgBreadcrumbImpl);
