import React from 'react';
import RecentFiles from '../files/RecentFiles';
import ExploreSideBar from './ExploreSideBar';
import { RecentInstruments } from '../../instruments';

export default () => (
  <div id="home-container">
    <div id="home-content">
      <RecentFiles />
      <RecentInstruments />
    </div>
    <div id="explore-sidebar">
      <ExploreSideBar />
    </div>
  </div>
);
