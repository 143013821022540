import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import skipStepStyles from './styles/skip-step-modal.module.scss';
import instrumentFleetModalStyles from '../create-reservations-tab/instrument-fleet-modal/styles/instrument-fleet-modal.module.scss';
import Skip from '../assets/skip.svg';

export type Props = {
  handleSkipStep: () => Promise<void>;
  onClose: () => void;
  show: boolean;
  skipStepAllowed: boolean;
};

export default function SkipStepModal(props: Readonly<Props>) {
  const { handleSkipStep, onClose, show, skipStepAllowed } = props;
  const skipStepModalBody = () => {
    const { centerContent, alertImage, contentHeader, contentMessage } = skipStepStyles;
    const { fleetReservationModalBody } = instrumentFleetModalStyles;
    return (
      <Modal.Body className={fleetReservationModalBody}>
        <div className={centerContent}>
          <div>
            <img className={alertImage} src={Skip} alt="Alert Verification" />
          </div>
          <div className={contentHeader}>Confirm Skip</div>
          <div className={contentMessage}>Skip the current step on the selected instrument(s)?</div>
        </div>
      </Modal.Body>
    );
  };

  const skipStepModalFooter = () => {
    const { cancelBtn, skipBtn } = skipStepStyles;
    const { fleetReservationModalFooter } = instrumentFleetModalStyles;
    return (
      <Modal.Footer className={fleetReservationModalFooter}>
        <Button
          className={cancelBtn}
          onClick={onClose}
          aria-label="cancel"
          id="skip-step-modal-cancel-button"
          name="skip-step-modal-cancel-button"
        >
          Cancel
        </Button>
        <Button
          className={skipBtn}
          onClick={handleSkipStep}
          disabled={!skipStepAllowed}
          aria-label="skip"
          id="skip-step-modal-skip-button"
          name="skip-step-modal-skip-button"
        >
          Skip
        </Button>
      </Modal.Footer>
    );
  };

  return (
    <Modal
      backdrop="static"
      className={skipStepStyles.skipButtonModal}
      onHide={onClose}
      show={show}
      data-testid="skip-step-modal"
    >
      {skipStepModalBody()}
      {skipStepModalFooter()}
    </Modal>
  );
}
