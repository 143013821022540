import { DispatchType, PROJECT_REMOVED } from './action-types';
import { ReduxState } from '../../types';
import { getSelectedProjectId } from '../selectors/selectors';
import changeProjectSelection from './change-project-selection';
import { DEFAULT_PROJECT_ID } from '../../frontend-common-libs/src/common/project-management-types';

// eslint-disable-next-line import/prefer-default-export
export function dispatchProjectRemoved(
  dispatch: DispatchType,
  state: ReduxState,
  projectId: string,
  orgId: string
) {
  if (getSelectedProjectId(state) === projectId) {
    changeProjectSelection(DEFAULT_PROJECT_ID)(dispatch);
  }
  dispatch({
    type: PROJECT_REMOVED,
    payload: { orgId, projectId }
  });
}
