import React from 'react';
import { OrgRoles, orgRolesDisplayNames } from '@biorad-lsg-tsc/organization';
import './styles/edit-organization.scss';
import TenantMemberRow, { TenantMemberRowProps } from './TenantMemberRow';

export type Props = TenantMemberRowProps<OrgRoles>;

export default function OrgMemberRow(props: Readonly<Props>) {
  return (
    <TenantMemberRow {...{ ...props, roleEnum: OrgRoles, displayNames: orgRolesDisplayNames }} />
  );
}
