import React from 'react';
import { FILETYPE_PTC_PROTOCOL } from './PTCTempoFileRowType';
import ptcTempoCompletedRunIcon from '../assets/ptc-tempo-completed-run.svg';
import ptcTempoInProgressRunIcon from '../assets/ptc-tempo-in-progress-run.svg';
import {
  FILETYPE_IN_PROGRESS_PTC_TEMPO_RUN,
  FILETYPE_PTC_RUN
} from '../../frontend-common-libs/src/common/userfiles_common';

export type Props = {
  type: string;
};

const icons: { [key: string]: any } = {
  [FILETYPE_IN_PROGRESS_PTC_TEMPO_RUN]: ptcTempoInProgressRunIcon,
  [FILETYPE_PTC_RUN]: ptcTempoCompletedRunIcon,
  [FILETYPE_PTC_PROTOCOL]: '/img/ptc-protocol.svg'
};

export default function PTCTempoFileIcon({ type }: Props) {
  const icon = icons[type] || '/img/generic-file.svg';
  return <img src={icon} alt={type} />;
}
