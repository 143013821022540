import React from 'react';
import { connect } from 'react-redux';
import { ManageProtocols } from '../../frontend-common-libs/src/components/pcr/ManageProtocols';
import conventionalPcrRoutes from '../routes';
import coreRoutes from '../../core/routes';
import { ProjectId } from '../../frontend-common-libs/src/common/project-management-types';
import ProjectProtocols from './repository/ProjectProtocols';
import {
  archiveProtocols as archiveProtocolsAction,
  exportPrcl,
  getProtocolsIfNeeded,
  renameProtocol as renameProtocolAction,
  uploadProtocols as uploadProtocolsAction,
  movePtcProtocolToProject as movePtcProtocolToProjectAction
} from './actions/protocol-actions';
import ProtocolListRepository from './repository/ProtocolListRepository';
import UploadHandler from '../../frontend-common-libs/src/file-operations/upload-files/UploadHandler';
import UploadHandlerRepository from '../../frontend-common-libs/src/file-operations/upload-files/UploadHandlerRepository';
import { ConnectViewModel } from '../../frontend-common-libs/src/common/mvvm/ConnectViewModel';
import { ReduxState } from '../../types';
import { getSelectedProjectId } from '../../project-management';
import {
  canEditFilesInProject,
  canMoveFileToProject,
  isOrgProjectsLoaded
} from '../../project-management/selectors/selectors';
import MoveFileToProjectVm from '../../project-management/manage-projects/move-file-to-project/MoveFileToProjectVm';
import { UserFile } from '../../frontend-common-libs/src/common/types';
import MoveFileToProject from '../../project-management/manage-projects/move-file-to-project/MoveFileToProject';

export type Props = {
  selectedProjectId: ProjectId;
  selectedProjectName?: string;
  projectProtocols: ProjectProtocols;
  canEditProjectFiles?: boolean;
  renameProtocol?: (projectId: ProjectId, entityId: string, newName: string) => any;
  archiveProtocols?: (projectId: ProjectId, entityIdList: string[]) => any;
  uploadProtocols: (files: File[], projectId: ProjectId) => any;
  canMoveFile: boolean;
  movePtcProtocolToProject: (entity: UserFile, projectId: string) => any;
  orgProjectsLoaded: boolean;
};

export type State = {
  moveFileToProjectVm: MoveFileToProjectVm;
};

export class ProtocolListPageImpl extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    UploadHandlerRepository.instance.addHandler(
      new UploadHandler(
        `${coreRoutes.APP}${conventionalPcrRoutes.PCR_PROTOCOL_LIST}`,
        props.uploadProtocols,
        'Upload PTC Protocols',
        '.prcl'
      )
    );
    this.state = { moveFileToProjectVm: new MoveFileToProjectVm(props.movePtcProtocolToProject) };
  }

  onMoveFile = (file: UserFile) => {
    const { moveFileToProjectVm } = this.state;
    moveFileToProjectVm.showMoveFileToProject(file);
  };

  getProtocolsIfNeeded = async (lastSeen: string): Promise<void> => {
    const { selectedProjectId } = this.props;
    await getProtocolsIfNeeded(selectedProjectId, lastSeen);
  };

  renameProtocol = async (entityId: string, newName: string): Promise<void> => {
    const { selectedProjectId, renameProtocol } = this.props;
    if (!renameProtocol) return;
    await renameProtocol(selectedProjectId, entityId, newName);
  };

  archiveProtocols = async (entityIdList: string[]): Promise<void> => {
    const { selectedProjectId, archiveProtocols } = this.props;
    if (!archiveProtocols) return;
    await archiveProtocols(selectedProjectId, entityIdList);
  };

  render() {
    const {
      projectProtocols,
      selectedProjectName,
      canEditProjectFiles,
      canMoveFile,
      uploadProtocols,
      orgProjectsLoaded
    } = this.props;
    const { isLoading, protocols, lastSeen, errorMessage, staleData, projectId } = projectProtocols;
    const { moveFileToProjectVm } = this.state;
    const moveFile = canMoveFile ? this.onMoveFile : undefined;

    const manageProtocolsProps = {
      isLoading,
      protocols: protocols.valueSeq(),
      lastSeen,
      errorMessage,
      getProtocolsIfNeeded: this.getProtocolsIfNeeded,
      staleData,
      projectId,
      projectName: selectedProjectName,
      loaded: true,
      createProtocolRoute: `${coreRoutes.APP}${conventionalPcrRoutes.CREATE_PCR_PROTOCOL}`,
      openProtocolRoute: `${coreRoutes.APP}${conventionalPcrRoutes.PCR_PROTOCOL}`,
      renameProtocol: this.renameProtocol,
      archiveProtocols: this.archiveProtocols,
      exportProtocol: exportPrcl,
      uploadProtocols,
      canEditProjectFiles,
      moveFile,
      orgProjectsLoaded
    };

    return (
      <>
        <ManageProtocols {...manageProtocolsProps} />
        {canMoveFile && <MoveFileToProject moveFileToProjectVm={moveFileToProjectVm} />}
      </>
    );
  }
}

export function ProtocolListPage(props: Readonly<Props>) {
  const { selectedProjectId } = props;
  const vm = ProtocolListRepository.instance;
  return (
    <ConnectViewModel vm={vm} vmPropKey="protocolListRepository">
      <ProtocolListPageImpl
        {...{ ...props, projectProtocols: vm.getProjectProtocols(selectedProjectId) }}
      />
    </ConnectViewModel>
  );
}

export function mapStateToProps(state: ReduxState) {
  const projectId = getSelectedProjectId(state);
  return {
    canEditProjectFiles: canEditFilesInProject(state, projectId),
    canMoveFile: canMoveFileToProject(state),
    orgProjectsLoaded: isOrgProjectsLoaded(state)
  };
}

export default connect(mapStateToProps, {
  renameProtocol: renameProtocolAction,
  archiveProtocols: archiveProtocolsAction,
  uploadProtocols: uploadProtocolsAction,
  movePtcProtocolToProject: movePtcProtocolToProjectAction
})(ProtocolListPage);
