import React, { ReactElement } from 'react';
import { RecentFileRowProps } from '@biorad-lsg-tsc/instrument-family-plugin';
import { canOpenFile, getPathForFileType } from '../../frontend-common-libs/src/utils/fileUtils';
import { CFXOpusFileIcon, CFXOpusFileRowType } from '../common';
import CommonRecentFileRow from '../../frontend-common-libs/recent-file-row';
import { utcStringToDateTimeFormat } from '../../frontend-common-libs/src/common/display-formats';

export default function CFXOpusRecentFileRow(props: RecentFileRowProps): ReactElement {
  const { item } = props;

  const id = item.get('id');
  const type = item.get('type');
  const name = item.get('name');
  const lasTimeUpdated = utcStringToDateTimeFormat(item.get('updated'));
  const displayType = CFXOpusFileRowType.getDisplayedFileType(type);
  const path = getPathForFileType(type, id);
  const icon = <CFXOpusFileIcon type={type} />;

  return (
    <CommonRecentFileRow
      canOpenFile={canOpenFile(type)}
      displayType={displayType}
      lastTimeUpdated={lasTimeUpdated}
      name={name}
      icon={icon}
      path={path}
      id={id}
    />
  );
}
