import React from 'react';
import HorizontalLine from '../common/HorizontalLine';
import ReportTable, { TableModel } from '../common/ReportTable';
import SectionHeader from '../common/SectionHeader';
import ThermalCyclerInfoParser from '../sections-parsing/ThermalCyclerInfoParser';

type Props = { instrumentDetails: ThermalCyclerInfoParser };
export default function ThermalCyclerInfo(props: Props) {
  const { instrumentDetails } = props;
  const tableModel = new TableModel();
  tableModel.header1 = 'Instrument Type';
  tableModel.header2 = 'Serial Number';
  tableModel.header3 = 'Software Version';
  tableModel.header4 = 'Firmware Version';
  tableModel.data1 = instrumentDetails.instrumentType;
  tableModel.data2 = instrumentDetails.serialNumber;
  tableModel.data3 = instrumentDetails.softwareVersion;
  tableModel.data4 = instrumentDetails.firmwareVersion;

  const tableModel2 = new TableModel();
  const { blockName } = instrumentDetails;
  tableModel2.header1 = 'Block Name';
  tableModel2.data1 = blockName;
  const showTable2 = blockName != null && blockName !== '';

  return (
    <div id="thermal-cycler-info">
      <SectionHeader text="Thermal Cycler Info" />
      <ReportTable
        tableModel={tableModel}
        tableStyle={{ marginBottom: '44px' }}
        tableName="thermal-cycler-info"
      />
      {showTable2 && (
        <ReportTable
          tableModel={tableModel2}
          tableStyle={{ marginTop: '-14px', marginBottom: '44px' }}
          tableName="thermal-cycler-info-2"
        />
      )}
      <HorizontalLine />
    </div>
  );
}
