import React from 'react';
import HorizontalLine from '../common/HorizontalLine';
import ReportTable, { TableModel } from '../common/ReportTable';
import SectionHeader from '../common/SectionHeader';
import InfoParser from '../sections-parsing/InfoParser';
import { Colors, statusStyle } from '../common/styles';

export type Props = {
  info: InfoParser;
};
export default function Info(props: Props) {
  const { info } = props;
  const tableModel = new TableModel();
  tableModel.header1 = 'Time Started';
  tableModel.header2 = 'Time Ended';
  tableModel.header3 = 'Time Elapsed';
  tableModel.header4 = 'User';
  tableModel.data1 = info.startTimeReport;
  tableModel.data2 = info.endTimeReport;
  tableModel.data3 = info.duration;
  tableModel.data4 = info.userName;

  const statusTableModel = new TableModel();
  statusTableModel.header1 = 'Status';

  return (
    <div id="info">
      <SectionHeader text="Info" />
      <ReportTable tableModel={tableModel} tableName="info-1" />
      <div id="info2-status" className="status-direction" style={{ ...statusStyle }}>
        <span>Status</span>
        {info.runStatus.map((status: string, index: number) => (
          <span key={index} style={{ color: Colors.dataText }} id={`status-line-${index}`}>
            {status}
          </span>
        ))}
      </div>
      <HorizontalLine />
    </div>
  );
}
