import _ from 'lodash';
import { creatCpcrProtocolFromFile } from '../api/protocols';
import ProtocolUpload from '../../../frontend-common-libs/src/file-operations/upload-protocol/ProtocolUpload';
import { UserFile } from '../../../frontend-common-libs/src/common/types';
import ProtocolListRepository from '../repository/ProtocolListRepository';
import {
  TrackedFilesEvents,
  trackEvent
} from '../../../frontend-common-libs/src/user-analytics/trackedEvents';
import { CbWithToken } from '../../../organization-management/actions/WithRefreshToken';
import { withRefreshProjectToken } from '../../../project-management/actions/with-refresh-project-token';

export default class PcrProtocolUpload extends ProtocolUpload {
  async createProtocolFromFile(buffer: Record<string, any>): Promise<UserFile> {
    const filename = this.getFileNameNoExtension();
    const creatCb: CbWithToken = async (projectAccessToken: string) => {
      return creatCpcrProtocolFromFile(filename, buffer, this.projectId, projectAccessToken);
    };
    return withRefreshProjectToken(this.dispatch, this.getState, this.projectId, creatCb);
  }

  notifyProtocolCreated(entity: UserFile) {
    const projectProtocols = ProtocolListRepository.instance.getProjectProtocols(this.projectId);
    projectProtocols.protocolUpdated(entity);
    trackEvent(TrackedFilesEvents.PtcUploadProtocol, { fileName: entity.name });
  }

  handleError(error: Error) {
    const errMsg = _.get(error, 'response.data');
    if (errMsg != null) {
      if (errMsg === `protocol cannot have more than 50 steps`) {
        this.notifyFailed('Protocol exceeds the 50 step limit');
        return;
      }
      if (errMsg === `protocol 'steps' cannot have a read step`) {
        this.notifyFailed('Plate reads are not allowed in Conventional PCR protocols');
        return;
      }
      if (errMsg === `protocol 'steps' cannot have a melt step`) {
        this.notifyFailed('Melt steps are not allowed in Conventional PCR protocols');
        return;
      }
    }

    super.handleError(error);
  }
}
