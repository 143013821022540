import React from 'react';
import HeroBanner from './HeroBanner';
import { BenefitContainer } from './benefit_box';
import { BlurbFlexContainer } from './blurb_box';

export default function () {
  return (
    <main id="landing-page" className="content-container">
      <HeroBanner />
      <div id="landing-content">
        <BlurbFlexContainer />
        <BenefitContainer />
      </div>
    </main>
  );
}
