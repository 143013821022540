import React from 'react';
import PageTitle from '../../frontend-common-libs/src/components/common/page-title/PageTitle';
import { getPageTitle } from '../routes';

type Props = {
  path: string;
  className: string;
};

export default function PageTitleWithRouteTitle({ path, className }: Props) {
  const pageTitle = getPageTitle(path);

  return <PageTitle routeTitle={pageTitle} className={className} />;
}
