import React from 'react';
import { connect } from 'react-redux';
import { WrappedFieldProps, Field, reduxForm, InjectedFormProps } from 'redux-form';
import { textRequired } from '../../frontend-common-libs/src/common/form_validators';
import { ReduxState } from '../../types';
import { UserProfile } from '../../auth/actions/auth_actions';

export const validateLength = (value: string | null | undefined) => {
  const maxLength = 2048;
  if (value && value.length > maxLength) {
    return `Cannot be longer than ${maxLength} characters`;
  }
  return undefined;
};

export type RenderFieldProps = {
  label: string;
  type: string;
} & WrappedFieldProps;

export function UserProfileRenderField(props: RenderFieldProps) {
  const {
    label,
    type,
    meta: { touched, error },
    input,
    ...passthrough
  } = props;
  return (
    <div>
      <label htmlFor={input.name} className="form-label">
        {label}
        <br />
        <div>
          <input
            {...input}
            {...passthrough}
            type={type}
            size={35}
            className="text-bold color-black"
            id={input.name}
          />
          {touched && error && <span className="form-label-error margin-left-5">{error}</span>}
        </div>
      </label>
    </div>
  );
}

export type Props = InjectedFormProps<UserProfile>;

export function UserProfileFormImpl(props: Props) {
  const { handleSubmit, pristine, submitting } = props;
  return (
    <form onSubmit={handleSubmit}>
      <Field
        type="text"
        label="NAME"
        name="name"
        component={UserProfileRenderField}
        validate={[textRequired, validateLength]}
        autoFocus
      />
      <br />
      <Field
        type="text"
        label="INSTITUTION"
        name="institution"
        component={UserProfileRenderField}
        validate={validateLength}
      />
      <br />
      <Field
        type="text"
        label="LAB"
        name="lab"
        component={UserProfileRenderField}
        validate={validateLength}
      />
      <br />
      <br />
      <button id="save" type="submit" disabled={pristine || submitting} className="btn btn-primary">
        Save Changes
      </button>
    </form>
  );
}

function mapStateToProps(state: ReduxState) {
  return {
    initialValues: {
      name: state.auth.getIn(['attributes', 'name']),
      institution: state.auth.getIn(['attributes', 'institution']),
      lab: state.auth.getIn(['attributes', 'lab'])
    }
  };
}

export default connect(mapStateToProps)(
  // @ts-ignore
  reduxForm<UserProfile>({
    form: 'userProfileForm'
  })(UserProfileFormImpl)
);
