import request from '../utils/httpUtils';
import { GATEWAY } from '../config/config';

const getIotCredentials = async () => {
  const baseURL = GATEWAY.IOT_AUTH_SERVICE_PATHS.BASE_URL;
  const path = GATEWAY.IOT_AUTH_SERVICE_PATHS.GET_CREDENTIALS;

  const response = await request.get(baseURL, {}, path, {});

  return response.data;
};

export default getIotCredentials;
