import React, { useEffect, useState, useRef, ReactElement } from 'react';
import BaseViewModel from './BaseViewModel';

export type Props = {
  children: ReactElement;
  vm: BaseViewModel;
  vmPropKey: string;
};
export const ConnectViewModel = (props: Props) => {
  const { vm, vmPropKey, children, ...otherProps } = props;

  const [viewModel, setViewModel] = useState(vm);
  const [updateCount, setUpdateCount] = useState(0);
  const updateCountRef = useRef(updateCount);
  const setUpdateCountImpl = (data: any) => {
    updateCountRef.current = data;
    setUpdateCount(data);
  };

  const updateState = (viewModel1: BaseViewModel) => {
    setUpdateCountImpl(updateCountRef.current + 1);
    setViewModel(viewModel1);
  };

  useEffect(() => {
    // on component mount.
    vm.registerStateChanged(updateState);

    return () => {
      // on component unmount.
      viewModel.unregisterStateChanged(updateState);
    };
  }, []);

  const mergedProps = {
    ...{
      ...otherProps,
      [vmPropKey]: viewModel,
      updateCount
    }
  };

  return React.cloneElement(children, { ...mergedProps });
};
