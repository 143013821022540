import React from 'react';
import RectangularOverflowSkeletonLoader from './RectangularOverflowSkeletonLoader';
import SquaredSkeletonLoader from './SquaredSkeletonLoader';

export default function () {
  return (
    <tr tabIndex={0}>
      <td>
        <SquaredSkeletonLoader />
      </td>
      <td>
        <RectangularOverflowSkeletonLoader />
      </td>
      <td>
        <RectangularOverflowSkeletonLoader />
      </td>
      <td>
        <RectangularOverflowSkeletonLoader />
      </td>
      <td>
        <RectangularOverflowSkeletonLoader />
      </td>
      <td />
    </tr>
  );
}
