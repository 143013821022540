export default function openTab(url: string) {
  window.open(url, '_blank');
}

export function createBlobURL(csvDataString: string) {
  if (!csvDataString) {
    return null;
  }
  if (window.URL && 'download' in window.document.createElement('a')) {
    return window.URL.createObjectURL(
      new Blob([csvDataString], {
        type: 'data:text/csv;encoding:utf-8;charset=utf-8'
      })
    );
  }
  return `data:application/octet-stream,${encodeURIComponent(csvDataString)}`;
}

export function getWindowUrl(): string {
  return window.location.href;
}
