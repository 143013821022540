import { Map } from 'immutable';
import { matchPath } from 'react-router-dom';
import { InstrumentType } from '@biorad-lsg-tsc/instrument-family-plugin';
import InstrumentFamilyPlugin from './InstrumentFamilyPlugin';
import { RoutePath } from '../core/routes';
import { InstrumentTypeEnum } from './types';

export default class InstrumentFamilyPluginRepository {
  private constructor() {
    this.plugins = [];
  }

  private pluginByInstrumentTypeHashMap: Record<InstrumentType, InstrumentFamilyPlugin> = {};

  private readonly plugins: InstrumentFamilyPlugin[];

  private static singleton: InstrumentFamilyPluginRepository | undefined;

  public static getInstance(): InstrumentFamilyPluginRepository {
    if (!InstrumentFamilyPluginRepository.singleton) {
      InstrumentFamilyPluginRepository.singleton = new InstrumentFamilyPluginRepository();
    }

    return InstrumentFamilyPluginRepository.singleton;
  }

  public getAll(): InstrumentFamilyPlugin[] {
    return this.plugins;
  }

  public getPluginByInstrumentType(
    instrumentType: InstrumentType
  ): InstrumentFamilyPlugin | undefined {
    return this.pluginByInstrumentTypeHashMap[instrumentType];
  }

  public static reset(): void {
    InstrumentFamilyPluginRepository.singleton = undefined;
  }

  public add(plugin: InstrumentFamilyPlugin): void {
    if (plugin.instrumentTypes.length === 0) {
      throw new Error(
        'InstrumentFamilyPluginRepository requires a plugin with at least one instrumentType'
      );
    }
    plugin.instrumentTypes.forEach(instrumentType => {
      if (!this.pluginByInstrumentTypeHashMap[instrumentType]) {
        this.pluginByInstrumentTypeHashMap[instrumentType] = plugin;
      } else {
        throw new Error('Only one InstrumentFamilyPlugin per instrument type allowed');
      }
    });

    this.plugins.push(plugin);
  }

  public getInstrumentFamilyPluginForFile(
    item: Map<string, any>
  ): InstrumentFamilyPlugin | undefined {
    const deviceType = item.get('type');

    const fileInstrumentPrefix = deviceType.split('-')[0].toLowerCase();

    return this.getPluginInstanceByDeviceTypePrefix(fileInstrumentPrefix);
  }

  public getInstrumentFamilyPluginForNotificationDeviceType(
    notificationDeviceType: string
  ): InstrumentFamilyPlugin | undefined {
    const deviceTypePrefix = notificationDeviceType.split(' ')[0].toLowerCase();

    return this.getPluginInstanceByDeviceTypePrefix(deviceTypePrefix);
  }

  private getPluginInstanceByDeviceTypePrefix = (
    deviceTypePrefix: string
  ): InstrumentFamilyPlugin | undefined => {
    let instrumentType = '';

    switch (deviceTypePrefix) {
      case 'qpcr':
      case 'cfx':
        instrumentType = InstrumentTypeEnum.cfx3G;
        break;
      case 'cpcr':
      case 'ptc':
        instrumentType = InstrumentTypeEnum.ptcTempo;
        break;
      case 'cdg':
      case 'cdgo':
      case 'cdpro':
        instrumentType = InstrumentTypeEnum.imageLabTouch;
        break;
      default:
        return undefined;
    }

    return this.getPluginByInstrumentType(instrumentType);
  };

  public getPluginByPath(path: RoutePath): InstrumentFamilyPlugin | undefined {
    return this.plugins.find(plugin => {
      const match = matchPath(path, { path: plugin.basePath });

      if (match) {
        return plugin;
      }

      return undefined;
    });
  }
}
