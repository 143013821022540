// In case more info is needed when the event is fired consider extending this class to Event<Sender, EventArgs> similar to C# event

export type EventHandler<Sender> = (sender: Sender) => void;

export default class Event<Sender> {
  private subscribers: Array<EventHandler<Sender>> = [];

  public register(eventHandler: EventHandler<Sender>) {
    this.subscribers.push(eventHandler);
  }

  public unregister(eventHandler: EventHandler<Sender>) {
    const index = this.subscribers.indexOf(eventHandler);
    if (index > -1) {
      this.subscribers.splice(index, 1);
    }
  }

  public fire = (sender: Sender): void => {
    this.subscribers.forEach(eventHandler => eventHandler(sender));
  };

  public get subscribersCount(): number {
    return this.subscribers.length;
  }

  public clear() {
    this.subscribers = [];
  }
}
