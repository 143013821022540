import React from 'react';
import './styles/instrument.scss';
import { NullableString } from '../../../common/nullable-types';

export type Props = {
  image: string;
  model: NullableString;
  name: NullableString;
};

export default function Instrument(props: Props) {
  const renderInstrumentName = (name: NullableString) => {
    if (name == null) return null;
    return (
      <div className="instrument-name-label" title={name}>
        <span className="with-quotes" id="value-Instrument-Name">
          {name}
        </span>
      </div>
    );
  };

  const { image, model, name } = props;
  return (
    <div className="instrument-name-and-model">
      <img src={image} alt="instrumentIcon" id="icon-instrument" />
      <div className="instrument-labels">
        <div className="instrument-model-label" id="value-Instrument-Model">
          {model}
        </div>
        {renderInstrumentName(name)}
      </div>
    </div>
  );
}
