import UserAnalyticsClient from '../frontend-common-libs/src/user-analytics/UserAnalyticsClient';

export interface NotificationTrackingEventParams {
  notificationName: string;
  instrumentType: string;
}

export default abstract class NotificationTrackingEvent {
  private readonly eventName: string;

  private readonly eventParams: NotificationTrackingEventParams;

  protected constructor(eventName: string, eventParams: NotificationTrackingEventParams) {
    this.eventName = eventName;
    this.eventParams = eventParams;
  }

  public track(): void {
    UserAnalyticsClient.getInstance().track(this.eventName, this.eventParams);
  }
}
