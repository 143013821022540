import { Map, fromJS } from 'immutable';
import {
  AUTH_USER,
  UNAUTH_USER,
  AUTH_ERROR,
  USER_SESSION_FOUND,
  USER_SESSION_NOT_FOUND,
  AUTH_IN_PROGRESS,
  FORGET_PWD_ERROR,
  FORGET_PWD_CONFIRM,
  LOAD_PROFILE,
  SUBMIT_PROFILE,
  USER_NOT_VERIFIED_ERROR,
  AuthActionType
} from '../auth/actions/auth_types';

import { ApiAction } from '../types';

type AuthStateKeys =
  | 'error'
  | 'message'
  | 'content'
  | 'authenticated'
  | 'username'
  | 'userSubId'
  | 'isLoadingSession'
  | 'getConfirmation'
  | 'pwdChanged'
  | 'attributes'
  | 'profileLoaded'
  | 'shouldRedirectToVerifyPage'
  | 'isNewUser';

export type AuthState = Map<AuthStateKeys, any>;

type AuthActionPayload = string | boolean | Map<string, any> | { email: string; userSubId: string };

// @ts-ignore
const INITIAL_STATE: AuthState = fromJS({
  error: {},
  message: '',
  content: '',
  authenticated: false,
  username: undefined,
  isLoadingSession: true,
  getConfirmation: false,
  pwdChanged: false,
  attributes: Map(),
  profileLoaded: false,
  shouldRedirectToVerifyPage: false,
  isNewUser: false
});

export default function authReducer(
  state: AuthState = INITIAL_STATE,
  action: ApiAction<AuthActionType, AuthActionPayload> | undefined = undefined
): AuthState {
  if (!action) return state;
  switch (action.type) {
    case AUTH_USER: {
      const { email, userSubId } = action.payload as { email: string; userSubId: string };
      return state
        .set('authenticated', true)
        .set('error', Map())
        .set('message', '')
        .set('username', email)
        .set('userSubId', userSubId)
        .set('isLoadingSession', false)
        .set('getConfirmation', false);
    }
    case UNAUTH_USER:
      return INITIAL_STATE.set('isLoadingSession', false);
    case USER_SESSION_FOUND: {
      const { email, userSubId } = action.payload as { email: string; userSubId: string };
      return INITIAL_STATE.set('authenticated', true)
        .set('username', email)
        .set('userSubId', userSubId)
        .set('isLoadingSession', false);
    }

    case USER_SESSION_NOT_FOUND:
      return INITIAL_STATE.set('isLoadingSession', false);
    case AUTH_ERROR:
      return state.set('error', action.payload);
    case AUTH_IN_PROGRESS:
      return INITIAL_STATE.set('isLoadingSession', false);
    case FORGET_PWD_CONFIRM:
      return state.set('getConfirmation', true).set('content', action.payload);
    case FORGET_PWD_ERROR:
      return state.set('error', action.payload);
    case LOAD_PROFILE:
      return state.set('attributes', fromJS(action.payload)).set('profileLoaded', true);
    case SUBMIT_PROFILE:
      return state.set('attributes', fromJS(action.payload));
    case USER_NOT_VERIFIED_ERROR:
      return state
        .set('username', (<Map<string, any>>action.payload).get('username'))
        .set('shouldRedirectToVerifyPage', true)
        .set('isNewUser', (<Map<string, any>>action.payload).get('isNewUser'));
    default:
      return state;
  }
}
