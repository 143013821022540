// @ts-nocheck
import countriesData from 'country-list';
import { Set } from 'immutable';

export default class CountriesRepository {
  static getCode(country: string): string {
    return countriesData.getCode(country);
  }

  static getData(): Array<Object> {
    return countriesData.getData();
  }

  static getCountrySet(): Set {
    let countriesSet = new Set();
    CountriesRepository.getData().forEach(country => {
      countriesSet = countriesSet.add(country.name);
    });
    return countriesSet;
  }

  static getOrderedCountrySet(): Set {
    const countriesSet = this.getCountrySet();
    return countriesSet.sort(function (a, b) {
      return a.localeCompare(b);
    });
  }
}
