import React from 'react';

import '../../styles/pcr.scss';
import classnames from 'classnames';

type Props = { children: React.ReactNode; className?: string };

export default ({ children, className }: Props) => {
  return (
    <div className="pcr-data-wrapper">
      <div className={classnames('pcr-data', className)}>{children}</div>
    </div>
  );
};
