import { ProjectRoles } from '@biorad-lsg-tsc/organization';
import { DispatchType, PROJECT_USER_ADDED } from './action-types';
import { addUserToProject as addUserToProjectApi } from '../api/project-api';
import { GetState } from '../../types';
import { getActiveOrg } from '../../organization-management/selectors/selectors';
import {
  TrackedOrganizationEvents,
  trackEvent
} from '../../frontend-common-libs/src/user-analytics/trackedEvents';
import { withRefreshProjectToken } from './with-refresh-project-token';
import { CbWithToken } from '../../organization-management/actions/WithRefreshToken';

export function dispatchProjectUserAdded(
  dispatch: DispatchType,
  projectId: string,
  userName: string,
  userRole: ProjectRoles
) {
  dispatch({
    type: PROJECT_USER_ADDED,
    payload: { projectId, userName, userRole }
  });
}

export function addUserToProject(projectId: string, userName: string, userRole: ProjectRoles) {
  return async (dispatch: DispatchType, getState: GetState) => {
    const state = getState();
    const orgId = getActiveOrg(state);
    const addUserCb: CbWithToken = async (accessToken: string) => {
      return addUserToProjectApi(projectId, orgId, userName, userRole, accessToken);
    };
    await withRefreshProjectToken(dispatch, getState, projectId, addUserCb);
    dispatchProjectUserAdded(dispatch, projectId, userName, userRole);
    trackEvent(TrackedOrganizationEvents.AddUserToProject, {
      userName,
      role: userRole
    });
  };
}
