import {
  makeFileNameCharacterValidator,
  makeMaximumText,
  makeTextRequired
} from '../../../../common/form_validators';

export const NAME_VALIDATORS = [
  makeTextRequired('Name cannot be empty'),
  makeMaximumText('Name is too long', 255),
  makeFileNameCharacterValidator('Name cannot contain')
];

export function validateFileName(name: string) {
  for (let i = 0; i < NAME_VALIDATORS.length; i += 1) {
    const errorMessage = NAME_VALIDATORS[i](name);
    if (errorMessage) {
      return errorMessage;
    }
  }
  return undefined;
}

export const validateRunNotes = (notes: string, originalNotesLength = 0) =>
  makeMaximumText(
    'Run notes are too long',
    originalNotesLength < 5000 ? 5000 : originalNotesLength
  )(notes);

export const validateRunBarcode = (barcode: string) =>
  makeMaximumText('Plate ID is too long', 128)(barcode);
