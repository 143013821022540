import TemplateEntity from './TemplateEntity';
import { FILETYPE_CFX_RUN_TEMPLATE } from '../../../../frontend-common-libs/src/common/userfiles_common';
import {
  ProjectId,
  DEFAULT_PROJECT_ID
} from '../../../../frontend-common-libs/src/common/project-management-types';
import { getEntitiesOfProject } from '../../../../frontend-common-libs/src/api/entities';

type Entity = {
  id: string;
  parent_id: string;
  created: string;
  updated: string;
  status: string;
  name: string;
  type: string;
  meta: { template: string };
};

export default class RunTemplates {
  private _lastSeen?: string;

  private _templateEntities: Array<TemplateEntity>;

  private templatesLoaded = false;

  readonly projectId: ProjectId;

  constructor(projectId: ProjectId = DEFAULT_PROJECT_ID) {
    this._templateEntities = [];
    this.projectId = projectId;
  }

  public get length(): number {
    return this._templateEntities.length;
  }

  public get templateEntities(): Array<TemplateEntity> {
    return this._templateEntities;
  }

  public get noTemplatesAvailable(): boolean {
    return this.length === 0 && !this.hasMore;
  }

  public get hasMore(): boolean {
    return this.templatesLoaded ? !!this._lastSeen : true;
  }

  public get lastSeen(): string | undefined {
    return this._lastSeen;
  }

  private async getTemplatesOfProject() {
    const query = {
      type: FILETYPE_CFX_RUN_TEMPLATE,
      lastSeen: this.lastSeen
    };
    return getEntitiesOfProject(query, this.projectId);
  }

  public async get(): Promise<RunTemplates> {
    const getTemplatesResponse = await this.getTemplatesOfProject();
    const { entities, lastSeen } = getTemplatesResponse;
    const updatedTemplateEntities = this._templateEntities.concat(
      entities.map((e: Entity) => new TemplateEntity(e.id, e.name))
    );

    return new RunTemplates().withLastSeen(lastSeen).withTemplates(updatedTemplateEntities);
  }

  private withLastSeen(lastSeen: string) {
    this._lastSeen = lastSeen;
    return this;
  }

  private withTemplates(runTemplates: Array<TemplateEntity>) {
    this._templateEntities = runTemplates;
    this.templatesLoaded = true;
    return this;
  }
}
