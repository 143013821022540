import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Map, Set } from 'immutable';
import { updateChannelMap, getSelectedWells, getPlateErrors } from '../../../utils/microplateUtils';
import QuickSave from './QuickSave';
import { VerticalIconButton } from '../../../frontend-common-libs/src/components/common/buttons';
import { updatePlate as updatePlateAction } from '../../../actions/currentCfxRun_actions';
import clearImg from '../../../../img/clear-icon-black.svg';

type Props = {
  plate: Map<string, any>;
  selectedWellIds: Set<string>;
  updatePlate: typeof updatePlateAction;
  saveAction: (arg0: string) => Promise<void>;
};

export class ToolbarImpl extends PureComponent<Props> {
  clearWells = () => {
    const { plate, selectedWellIds, updatePlate } = this.props;
    const newWells = plate.get('wells').deleteAll(selectedWellIds);
    let plateWithNewWells = plate.set('wells', newWells);
    plateWithNewWells = updateChannelMap(plateWithNewWells);
    updatePlate(plateWithNewWells, getPlateErrors(plateWithNewWells));
  };

  render() {
    const { plate, selectedWellIds, saveAction } = this.props;
    return (
      <div className="toolbar">
        <div className="left-icons">
          <QuickSave id="quick-save-plate-toolbar" handleClick={saveAction} />
        </div>
        <div className="right-icons">
          {/* @ts-ignore */}
          <VerticalIconButton
            id="clear-well-button"
            iconSrc={clearImg}
            alt="clear selected wells button"
            onClick={this.clearWells}
            disabled={getSelectedWells(plate, selectedWellIds).size === 0}
          >
            Clear
          </VerticalIconButton>
        </div>
      </div>
    );
  }
}

// @ts-ignore
export default connect(null, { updatePlate: updatePlateAction })(ToolbarImpl);
