import { ProjectId } from '../../common/project-management-types';

export type UploadFilesFunction = (files: File[], projectId: ProjectId) => any;

export default class UploadHandler {
  public readonly pathname: string;

  public readonly uploadFiles: UploadFilesFunction;

  public readonly tooltip?: string;

  public readonly acceptFiles?: string;

  constructor(
    pathname: string,
    uploadFiles: UploadFilesFunction,
    tooltip: string,
    acceptFiles?: string
  ) {
    this.pathname = pathname;
    this.uploadFiles = uploadFiles;
    this.tooltip = tooltip;
    this.acceptFiles = acceptFiles;
  }
}
