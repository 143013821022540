import { DispatchType, ORGS_LOADING, ORGS_LOADED } from './action-types';
import { GetState } from '../../types';
import { getUserOrgs } from '../api/org-api';
import notification from '../../frontend-common-libs/src/utils/notifications';
import { getIsOrganizationsLoading, isOrganizationsLoaded } from '../selectors/selectors';
import { CbWithToken } from './WithRefreshToken';
import { withRefreshLoginToken } from './with-refresh-login-token';

function getOrganizations() {
  return async (dispatch: DispatchType, getState: GetState) => {
    dispatch({
      type: ORGS_LOADING,
      payload: {}
    });

    try {
      const getUserOrgsCb: CbWithToken = async (accessToken: string) => {
        return getUserOrgs(accessToken);
      };
      const { orgs: organizations, activeOrg } = await withRefreshLoginToken(
        dispatch,
        getState,
        getUserOrgsCb
      );
      dispatch({
        type: ORGS_LOADED,
        payload: { organizations, activeOrg }
      });
    } catch (error) {
      dispatch({
        type: ORGS_LOADED,
        payload: { organizations: [] }
      });
      notification.error('Error retrieving organizations. Please try again.');
    }
  };
}

// eslint-disable-next-line import/prefer-default-export
export function getOrganizationsIfNeeded() {
  return async (dispatch: DispatchType, getState: GetState) => {
    const state = getState();
    if (!isOrganizationsLoaded(state) && getIsOrganizationsLoading(state) !== true) {
      await getOrganizations()(dispatch, getState);
    }
  };
}
