import GradientTemperature from '../../gradient-temperature/GradientTemperature';
import GradientTemperature96 from '../../gradient-temperature/GradientTemperature96';
import Gradient, { GradientLowerTemperature, GradientRange } from '../../Gradient';

export default class Gradient96D extends Gradient {
  constructor(lowerTemp: GradientLowerTemperature, range: GradientRange, isValid: boolean) {
    super(8, lowerTemp, range, isValid, Gradient96D.offsetTable, Gradient96D.scTable);
  }

  private static readonly offsetTable: Array<Array<number>> = [
    [30.157185, 40.110149, 50.063113, 60.03938, 69.986283, 79.972605, 89.982401, 99.992196],
    [30.110122, 40.07936, 50.048597, 60.034489, 70.010474, 80.017459, 90.043508, 100.069558],
    [30.122383, 40.102594, 50.082806, 60.08083, 70.076358, 80.090969, 90.125987, 100.161006],
    [30.111782, 40.091579, 50.071375, 60.062254, 70.057256, 80.066114, 90.089834, 100.113554],
    [30.115777, 40.096768, 50.07776, 60.066458, 70.06154, 80.071392, 90.090562, 100.109732],
    [30.141426, 40.109992, 50.078558, 60.066892, 70.058995, 80.073812, 90.101249, 100.128686],
    [30.132388, 40.095745, 50.059102, 60.050841, 70.030149, 80.039839, 90.067858, 100.095876],
    [30.146605, 40.097806, 50.049006, 60.02947, 69.970066, 79.952936, 89.961027, 99.969117]
  ];

  private static readonly scTable: Array<Array<number>> = [
    [0.592557, 0.594201, 0.595845, 0.596752, 0.59643, 0.596589, 0.595358, 0.594127],
    [0.515263, 0.516702, 0.518141, 0.519263, 0.519745, 0.520352, 0.519666, 0.51898],
    [0.36363, 0.364409, 0.365189, 0.365732, 0.365369, 0.366167, 0.365777, 0.365388],
    [0.132055, 0.131519, 0.130982, 0.13116, 0.130359, 0.130968, 0.131159, 0.131349],
    [-0.145507, -0.145796, -0.146086, -0.145103, -0.144732, -0.14368, -0.142022, -0.140363],
    [-0.371532, -0.371953, -0.372374, -0.372395, -0.37322, -0.373142, -0.372614, -0.372087],
    [-0.516245, -0.517048, -0.517852, -0.519778, -0.522117, -0.522925, -0.523564, -0.524203],
    [-0.591701, -0.592805, -0.593909, -0.596422, -0.598265, -0.598562, -0.598911, -0.59926]
  ];

  // eslint-disable-next-line class-methods-use-this
  protected createGradientTemperature(
    index: number,
    temperatureValue: number
  ): GradientTemperature {
    return new GradientTemperature96(index, temperatureValue);
  }
}
