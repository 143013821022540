export type PcrProtocolActionType =
  | 'pcr_protocol_loaded'
  | 'pcr_protocol_error'
  | 'pcr_protocol_loading'
  | 'pcr_protocol_closed'
  | 'pcr_protocol_is_saving';

export const PCR_PROTOCOL_LOADED: PcrProtocolActionType = 'pcr_protocol_loaded';
export const PCR_PROTOCOL_ERROR: PcrProtocolActionType = 'pcr_protocol_error';
export const PCR_PROTOCOL_LOADING: PcrProtocolActionType = 'pcr_protocol_loading';
export const PCR_PROTOCOL_CLOSED: PcrProtocolActionType = 'pcr_protocol_closed';
export const PCR_PROTOCOL_IS_SAVING: PcrProtocolActionType = 'pcr_protocol_is_saving';
