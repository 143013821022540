import realTimePcrRoutes, { getRouteName, getRoute } from '../routes';

export default class HelpCenter {
  private readonly baseDomain: string;

  private readonly helpPaths = {
    CFX_Protocol_List: '/Output/CFX_Opus/Content/CloudUA/Protocols.htm',
    CFX_Protocol_Creator: '/Output/CFX_Opus/Content/CloudUA/PCR/Protocols/Protocols_Create.htm',
    CFX_Protocol_Editor: '/Output/CFX_Opus/Content/CloudUA/PCR/Protocols/Protocols_Create.htm',
    CFX_Create_Run_Protocol: '/Output/CFX_Opus/Content/CloudUA/Create_a_Run.htm',
    CFX_Create_Run_Details:
      '/Output/CFX_Opus/Content/CloudUA/PCR/Experiments/Specifying_Run_Details.htm',
    CFX_Create_Run_Plate: '/Output/CFX_Opus/Content/CloudUA/Editing_Plate_Layout.htm',
    CFX_Create_Run_Template: '/Output/CFX_Opus/Content/CloudUA/RunTemplates.htm',
    CFX_Completed_Run_Protocol: '/Output/CFX_Opus/Content/CloudUA/Selecting_a_Protocol.htm',
    CFX_Completed_Run_Details:
      '/Output/CFX_Opus/Content/CloudUA/PCR/Experiments/Specifying_Run_Details.htm',
    CFX_Completed_Run_Plate: '/Output/CFX_Opus/Content/CloudUA/Editing_Plate_Layout.htm',
    CFX_Completed_Run_Results: '/Output/CFX_Opus/Content/CloudUA/AnalyzingData_basic.htm',
    Managing_CFX_Instruments:
      '/Output/CFX_Opus/Content/CloudUA/PCR/Instruments/Managing_CFX_Instruments.htm',
    CFX_Connecting_Instruments: '/Output/CFX_Opus/Content/CloudUA/ConnectingInstruments.htm'
  };

  private readonly routesToHelpPaths = {
    [realTimePcrRoutes.QPCR_PROTOCOL_LIST]: this.helpPaths.CFX_Protocol_List,
    [realTimePcrRoutes.CREATE_QPCR_PROTOCOL]: this.helpPaths.CFX_Protocol_Creator,
    [realTimePcrRoutes.VIEW_QPCR_PROTOCOL]: this.helpPaths.CFX_Protocol_Editor,
    [realTimePcrRoutes.CREATE_QPCR_RUN_PROTOCOL]: this.helpPaths.CFX_Create_Run_Protocol,
    [realTimePcrRoutes.CREATE_QPCR_RUN_DETAILS]: this.helpPaths.CFX_Create_Run_Details,
    [realTimePcrRoutes.CREATE_QPCR_RUN_PLATE]: this.helpPaths.CFX_Create_Run_Plate,
    [realTimePcrRoutes.CREATE_QPCR_RUN_FROM_TEMPLATE]: this.helpPaths.CFX_Create_Run_Template,
    [realTimePcrRoutes.VIEW_QPCR_RUN_PROTOCOL]: this.helpPaths.CFX_Completed_Run_Protocol,
    [realTimePcrRoutes.VIEW_QPCR_RUN_DETAILS]: this.helpPaths.CFX_Completed_Run_Details,
    [realTimePcrRoutes.VIEW_QPCR_RUN_PLATE]: this.helpPaths.CFX_Completed_Run_Plate,
    [realTimePcrRoutes.VIEW_QPCR_RUN_RESULTS]: this.helpPaths.CFX_Completed_Run_Results
  };

  constructor(baseDomain: string) {
    this.baseDomain = baseDomain;
  }

  public getHelpUrl(path: string): string | undefined {
    let helpPath = this.routesToHelpPaths[path];
    if (!helpPath) {
      const routeName = getRouteName(path);
      const route = getRoute(routeName);
      helpPath = this.routesToHelpPaths[route];
    }
    return helpPath ? `${this.baseDomain}${helpPath}` : undefined;
  }
}
