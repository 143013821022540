import React from 'react';

type Props = {
  icon: string;
  children: any;
  imgAlt: string;
};

function ToolbarButton({ icon, children, imgAlt }: Props) {
  return (
    <>
      <img className="icon" src={icon} alt={imgAlt} />
      {children}
    </>
  );
}

export default ToolbarButton;
