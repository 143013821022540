import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm, InjectedFormProps } from 'redux-form';
import { RenderField } from '../../../frontend-common-libs/src/common/input-fields';
import { textRequired } from '../../../frontend-common-libs/src/common/form_validators';
import NewPasswordEntry from '../new-password/NewPasswordEntry';
import { FormData } from '../../../components/auth/PasswordChangeForm';
import authStyles from '../styles/auth.module.scss';

export interface FormInfo {
  password1: string;
}

export interface AdditionalProps {
  extraError: string;
  onSubmit: (x: FormInfo) => any;
}

type InjectedProps = InjectedFormProps<FormData, Props>;
export type Props = AdditionalProps & InjectedProps;

export class PasswordResetFormImpl extends Component<Props> {
  private requiredValidator = (value: string) => textRequired(value);

  private handleFormSubmit = (pwdInfo: FormInfo) => {
    const { onSubmit } = this.props;
    onSubmit(pwdInfo);
  };

  render() {
    const { handleSubmit, submitting, extraError, dirty, invalid } = this.props;
    return (
      <div className={authStyles.changePassword}>
        <div className={authStyles.formContainer}>
          <form onSubmit={handleSubmit(this.handleFormSubmit)}>
            <NewPasswordEntry
              label="NEW PASSWORD"
              key="new-pwd"
              name="password1"
              component={RenderField}
              extraError={!dirty ? '' : extraError}
              validate={[this.requiredValidator]}
              showVerifyPassword
            />
            <button
              id="change-pwd-btn"
              type="submit"
              className="form-btn btn btn-primary text-center"
              disabled={submitting || invalid}
            >
              Reset Password
            </button>
          </form>
        </div>
      </div>
    );
  }
}

export default connect(null)(
  reduxForm<FormData, Props>({
    form: 'passwordOnly'
  })(PasswordResetFormImpl)
);
