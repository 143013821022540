import React, { PureComponent } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import OpenFileDialogButton from '../dialogs/OpenFileDialogButton';
import { ProjectId } from '../../../common/project-management-types';
import UploadHandlerRepository from '../../../file-operations/upload-files/UploadHandlerRepository';
import UploadHandler from '../../../file-operations/upload-files/UploadHandler';

export type Props = {
  location: RouteComponentProps['location'];
  selectedProjectId: ProjectId;
  disabled?: boolean;
};

export type State = { uploadHandler: UploadHandler };

export class UploadFilesButtonImpl extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    UploadHandlerRepository.instance.stateChanged.register(this.updateState);
    this.state = { uploadHandler: this.getHandler() };
  }

  componentWillUnmount() {
    UploadHandlerRepository.instance.stateChanged.unregister(this.updateState);
  }

  updateState = () => {
    this.setState({
      uploadHandler: this.getHandler()
    });
  };

  componentDidUpdate(prevProps: Props) {
    const { location } = this.props;
    if (location !== prevProps.location) {
      this.updateState();
    }
  }

  pathname = () => {
    const { location } = this.props;
    return location.pathname;
  };

  getHandler = () => {
    const pathname = this.pathname();
    return UploadHandlerRepository.instance.getHandler(pathname);
  };

  uploadFilesToProject = (files: File[]) => {
    const { selectedProjectId } = this.props;
    const { uploadHandler } = this.state;
    if (uploadHandler) return uploadHandler.uploadFiles(files, selectedProjectId);
    return undefined;
  };

  isDisabled = () => {
    const { uploadHandler } = this.state;
    const { disabled } = this.props;
    return disabled || uploadHandler == null;
  };

  tooltip = () => {
    const { uploadHandler } = this.state;
    if (uploadHandler) return uploadHandler.tooltip;
    return undefined;
  };

  acceptFiles = () => {
    const { uploadHandler } = this.state;
    if (uploadHandler) return uploadHandler.acceptFiles;
    return undefined;
  };

  render() {
    return (
      <OpenFileDialogButton
        multiple
        onFilesSelected={this.uploadFilesToProject}
        disabled={this.isDisabled()}
        tooltip={this.tooltip()}
        accept={this.acceptFiles()}
      />
    );
  }
}

export default withRouter<any, any>(UploadFilesButtonImpl as any);
