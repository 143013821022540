import { ImmutableMap, InstrumentItem } from '../../frontend-common-libs/src/common/types';

export default function getUniqueInstrumentModels(
  selectedInstruments: string[],
  instruments: ImmutableMap<InstrumentItem>[]
): (string | undefined)[] {
  const listOfModels = selectedInstruments.map(instrumentId => {
    const selectedInstrument = instruments.find(
      instrument => instrument.get('id') === instrumentId
    );

    return selectedInstrument?.get('model');
  });

  return [...new Set(listOfModels)];
}
