import React from 'react';
import { fromJS, Map } from 'immutable';
import '../styles/run-protocol.scss';
import RunParser from '../report/run-report/sections-parsing/RunParser';
import { PcrProtocolEditor } from '../../../../frontend-common-libs/src/components/pcr/pcr-protocols/PcrProtocolEditor';
import QPcrProtocol from '../../../../frontend-common-libs/src/components/pcr/pcr-protocols/models/QPcrProtocol';

export type Props = {
  runParser: RunParser;
};

export default class RunProtocol extends React.Component<Props> {
  public get protocolName() {
    const { runParser } = this.props;
    return runParser.runConditionsParser.protocolName;
  }

  loadPcrProtocol = async () => {
    const { runParser } = this.props;
    const protocol = fromJS(runParser.report.protocol) as Map<string, any>;
    const options = {
      name: this.protocolName,
      protocol
    };
    return new QPcrProtocol(options);
  };

  render() {
    return (
      <div className="run-protocol">
        <div className="protocol-label">PROTOCOL</div>
        <div className="protocol-label protocol-name" id="protocol-name">
          {this.protocolName}
        </div>
        <PcrProtocolEditor
          loadProtocol={this.loadPcrProtocol}
          isPcr
          disabled
          showProtocolName={false}
        />
      </div>
    );
  }
}
