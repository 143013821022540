import { Map } from 'immutable';

import { secondsToTimeString } from '../../../../utils/protocolUtils';
import { StepValue } from '../../pcr_protocol_types';

export default class QPcrStep {
  map: Map<any, any>;

  constructor(map: Map<any, any>) {
    this.map = map;
  }

  set(key: string, val?: StepValue) {
    return QPcrStep.copy(this.map.set(key, val));
  }

  get type(): string {
    return this.map.get('type');
  }

  get temp(): number {
    return this.map.get('temp');
  }

  get tempAsString(): string {
    return this.temp.toFixed(1);
  }

  get time(): number {
    return this.map.get('time');
  }

  get timeAsString(): string {
    return secondsToTimeString(this.time);
  }

  get read(): boolean {
    return this.map.get('read');
  }

  /** the index of the step to go-to */
  get gotoStepIndex(): number {
    return this.map.get('step');
  }

  /** the display number for the step to go-to which is gotoStepIndex + 1 */
  get gotoStepNumber(): number {
    return Number.isFinite(this.gotoStepIndex) ? this.gotoStepIndex + 1 : this.gotoStepIndex;
  }

  get cycles(): number {
    return this.map.get('cycles');
  }

  get grd(): number {
    return this.map.get('grd');
  }

  get grdTempHigh(): number {
    return this.temp + this.grd;
  }

  get grdTempHighAsString(): string {
    return this.grdTempHigh.toFixed(1);
  }

  get ext(): number {
    return this.map.get('ext');
  }

  get end(): number {
    return this.map.get('end');
  }

  get endAsString(): string {
    return this.end.toFixed(1);
  }

  get inc(): number {
    return this.map.get('inc');
  }

  get incAsString(): string {
    return this.inc !== undefined ? this.inc.toFixed(1) : this.inc;
  }

  get interval(): number {
    return this.map.get('interval');
  }

  get intervalAsString(): string {
    return secondsToTimeString(this.interval);
  }

  get ramp(): number {
    return this.map.get('ramp');
  }

  get rampAsString(): string {
    return this.ramp !== undefined ? this.ramp.toFixed(1) : this.ramp;
  }

  get beep(): boolean {
    return this.map.get('beep');
  }

  static copy(map: Map<any, any>): QPcrStep {
    return new QPcrStep(map);
  }
}
