export default class ThermalCyclerInfoParser {
  public blockName: string;

  public firmwareVersion: string;

  public instrumentName: string;

  public instrumentType: string;

  public serialNumber: string;

  public softwareVersion: string;

  constructor(runReport: any) {
    const { instrumentDetails } = runReport;
    this.blockName = instrumentDetails.blockName;
    this.firmwareVersion = instrumentDetails.firmwareVersion;
    this.instrumentName = instrumentDetails.instrumentName;
    this.instrumentType = instrumentDetails.instrumentType;
    this.serialNumber = instrumentDetails.serialNumber;
    this.softwareVersion = instrumentDetails.softwareVersion;
  }
}
