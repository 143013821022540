import { FileOperationActionType } from './file_operation_types';
import { SystemEvent, SystemEventBroker } from '../system-event';

export default class FileOperationEvent implements SystemEvent {
  readonly type: FileOperationActionType;

  readonly payload: any;

  public static readonly eventName = 'fileOperation';

  public readonly name = FileOperationEvent.eventName;

  constructor(type: FileOperationActionType, payload: any) {
    this.type = type;
    this.payload = payload;
  }

  public static isFileOperationEvent(systemEvent: SystemEvent) {
    return systemEvent.name === FileOperationEvent.eventName;
  }

  public static notify(type: FileOperationActionType, payload: any) {
    SystemEventBroker.getInstance().notify(new FileOperationEvent(type, payload));
  }
}
