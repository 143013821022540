import React from 'react';
import { formatId, Select, SelectMenuItem } from '../../common/dropdown';
import FieldGroup from '../../common/FieldGroup';
import IntFieldGroup from '../../common/IntFieldGroup';
import {
  LidSettingDefault,
  LidSettingCustom,
  LidSettingOff,
  LidSetting
} from '../pcr_protocol_types';

const LidSettings: any = {
  [LidSettingDefault]: 'Default Temperature',
  [LidSettingCustom]: 'Custom Temperature',
  [LidSettingOff]: 'Heater Off'
};

type Props = {
  volume?: number;
  volumeError: boolean;
  onVolumeChange?: (name: string, value?: number) => void;
  lidSetting: LidSetting;
  onLidSettingChange?: (eventKey: string) => void;
  lidTemp?: number;
  onLidTempChange?: (name: string, value?: number) => void;
  lidError: boolean;
};

class ProtocolSettings extends React.Component<Props> {
  static defaultProps = {
    volume: undefined,
    lidTemp: undefined,
    onVolumeChange: undefined,
    onLidSettingChange: undefined,
    onLidTempChange: undefined
  };

  onLidSettingChange = (eventKey: string) => {
    const { lidSetting, onLidSettingChange } = this.props;
    if (eventKey !== lidSetting && onLidSettingChange) {
      onLidSettingChange(eventKey);
    }
  };

  getLidSettingDisplay = (lidSetting: string) => LidSettings[lidSetting];

  renderSelectMenuItems(lidSettingsDropdownId: string) {
    const { lidSetting } = this.props;
    return Object.keys(LidSettings).map<any>(lidSettingOption => {
      const optionValue = lidSettingOption;
      const displayValue = LidSettings[lidSettingOption];
      const formattedId = formatId(lidSettingsDropdownId, optionValue);
      return (
        <SelectMenuItem
          id={formattedId}
          key={formattedId}
          value={optionValue}
          selected={lidSetting === optionValue}
        >
          {displayValue}
        </SelectMenuItem>
      );
    });
  }

  render() {
    const {
      volume,
      onVolumeChange,
      volumeError,
      lidSetting,
      lidTemp,
      onLidTempChange,
      onLidSettingChange,
      lidError
    } = this.props;

    const lidSettingsIsDisabled = onLidSettingChange === undefined;
    const lidSettingsDropdownId = 'lid-setting-dropdown';
    const validationState = lidError ? 'error' : undefined;
    return (
      <div className="reaction-vol-panel">
        <div className="reaction-vol-panel-item" id="reaction-vol-panel-item">
          <IntFieldGroup
            name="reaction-vol"
            allowNegative={false}
            label="Reaction Volume"
            subLabel="(µl)"
            // @ts-ignore
            placeholder="0-125"
            customLayoutStyle="reaction-vol-group"
            id="reaction-volume"
            value={volume}
            onChange={onVolumeChange}
            validationState={volumeError ? 'error' : undefined}
            withError={false}
          />
        </div>
        <div className="reaction-vol-panel-item" id="lid-settings-panel-item">
          <FieldGroup
            id="lid-setting"
            label="Lid setting"
            customLayoutStyle="lid-setting-group"
            withError={false}
          >
            <Select
              disabled={lidSettingsIsDisabled}
              id={lidSettingsDropdownId}
              value={this.getLidSettingDisplay(lidSetting)}
              handleSelection={lidSettingsIsDisabled ? undefined : this.onLidSettingChange}
            >
              {this.renderSelectMenuItems(lidSettingsDropdownId)}
            </Select>
          </FieldGroup>
        </div>
        <div className="reaction-vol-panel-item" id="lid-temp-panel-item">
          {lidSetting === LidSettingCustom ? (
            <IntFieldGroup
              name="lid-temp"
              allowNegative={false}
              label="Lid temperature"
              subLabel="(&#176;C)"
              // @ts-ignore
              placeholder="30-110"
              id="lid-temp"
              customLayoutStyle="lid-temp-group"
              value={lidTemp}
              onChange={onLidTempChange}
              validationState={validationState}
              withError={false}
            />
          ) : null}
        </div>
      </div>
    );
  }
}

export default ProtocolSettings;
