import Credentials from './credentials/credentials';

export default class InstrumentGroupCredentials extends Credentials {
  get instrumentIds() {
    return this.credentials.instrumentIds;
  }

  public isInstrumentInGroup(instrumentId: string): boolean {
    return this.instrumentIds.includes(instrumentId);
  }
}
