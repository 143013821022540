import React from 'react';

export type Props = {
  type: string;
};

const icons: { [key: string]: any } = {
  'cfx-run': '/img/completed-run.svg',
  'cfx-pending-run': '/img/pending-run.svg',
  'cfx-in-progress-run': '/img/in-progress-cfx-run.svg',
  'cfx-run-template': '/img/cfx-run-template.svg',
  'qpcr-protocol': '/img/protocol.svg'
};

export default function CFXOpusFileIcon({ type }: Props) {
  const icon = icons[type] || '/img/generic-file.svg';
  return <img src={icon} alt={type} />;
}
