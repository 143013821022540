import { List, Map } from 'immutable';
import cfxIcon from '../instrument-row/assets/cfx-icon.svg';
import cfxRunningIcon from '../assets/opus-icon-green-badge.svg';
import cfxErrorIcon from '../assets/opus-icon-red-badge.svg';
import cfxReadyIcon from '../assets/opus-icon-blue-badge.svg';
import cfxConnectingIcon from '../assets/opus-icon-connecting-badge.svg';
import cfxOfflineIcon from '../assets/opus-icon-offline-badge.svg';
import cfxPausedIcon from '../assets/opus-icon-paused-badge.svg';
import cfxUnknownIcon from '../assets/opus-icon-no-badge.svg';
import {
  InstrumentModelEnum,
  InstrumentStatusEnum,
  InstrumentStatusEnumValue,
  instrumentStatusToEnum
} from '../../instruments/utils/instrumentUtils';
import { NullableString } from '../../frontend-common-libs/src/common/nullable-types';
import runningIcon from '../../instruments/assets/in-progress-status.svg';
import errorIcon from '../../instruments/assets/error-status.svg';
import readyIcon from '../../instruments/assets/ready-status.svg';
import connectingIcon from '../../instruments/assets/connecting-status.svg';
import offlineIcon from '../../instruments/assets/offline-status.svg';
import pausedIcon from '../../instruments/assets/paused-status.svg';
import realTimePcrRoutes from '../routes';
import coreRoutes from '../../core/routes';

export function getTypeIcon(instrumentType: string): string | undefined {
  switch (instrumentType) {
    case 'C1000Touch':
    case 'CFX3G':
      return cfxIcon;
    default:
      return undefined;
  }
}

export function getInstrumentIconByStatus(status: string): string {
  const statusEnum = instrumentStatusToEnum(status);
  switch (statusEnum) {
    case InstrumentStatusEnum.Running:
      return cfxRunningIcon;
    case InstrumentStatusEnum.Error:
      return cfxErrorIcon;
    case InstrumentStatusEnum.Idle:
      return cfxReadyIcon;
    case InstrumentStatusEnum.Connecting:
      return cfxConnectingIcon;
    case InstrumentStatusEnum.Offline:
      return cfxOfflineIcon;
    case InstrumentStatusEnum.Paused:
      return cfxPausedIcon;
    default:
      return cfxUnknownIcon;
  }
}

export function getModelName(type: string, model: string): NullableString {
  if (type === 'CFX3G') {
    if (model === 'CFX96') return InstrumentModelEnum.Opus96;
    if (model === 'CFX384') return InstrumentModelEnum.Opus384;
    if (model === 'CFX96D') return InstrumentModelEnum.Opus96D;
  }
  if (type === 'C1000Touch') {
    if (model === 'CFX96') return InstrumentModelEnum.LegacyTouch96;
    if (model === 'CFX96D') return InstrumentModelEnum.LegacyTouch96D;
    if (model === 'CFX384') return InstrumentModelEnum.LegacyTouch384;
  }

  return null;
}

export function getStatusIcon(status: NullableString): NullableString {
  const statusEnum = instrumentStatusToEnum(status);
  switch (statusEnum) {
    case InstrumentStatusEnum.Running:
      return runningIcon;
    case InstrumentStatusEnum.Error:
      return errorIcon;
    case InstrumentStatusEnum.Idle:
      return readyIcon;
    case InstrumentStatusEnum.Connecting:
      return connectingIcon;
    case InstrumentStatusEnum.Offline:
      return offlineIcon;
    case InstrumentStatusEnum.Paused:
      return pausedIcon;
    default:
      return null;
  }
}

export function getRunDetailsUrl(runId: NullableString): string {
  if (!runId) return '';
  return `${coreRoutes.APP}${realTimePcrRoutes.QPCR}/${runId}/details`;
}

export function getCurrentStepCycles(
  stepNumber: number | undefined | null,
  steps: List<Map<string, any>> | undefined | null
): number {
  if (stepNumber == null || steps == null) return 0;
  const stepIndex = stepNumber - 1;
  if (stepIndex >= 0 && steps.size > stepIndex && steps.getIn([stepIndex, 'type']) !== 'melt') {
    // look for the next goto step and return the cycle number
    for (let index = stepIndex; index < steps.size; index += 1) {
      const currStep = steps.get(index);
      if (currStep) {
        const toStep = currStep.get('step');
        if (
          currStep.get('type') === 'goto' &&
          toStep != null &&
          toStep >= 0 &&
          stepIndex >= toStep
        ) {
          return currStep.get('cycles') + 1;
        }
      }
    }
  }
  return 0;
}

export function isRunInProgressState(status: InstrumentStatusEnumValue | NullableString): boolean {
  const statusEnum = instrumentStatusToEnum(status);
  if (!statusEnum) return false;
  return [InstrumentStatusEnum.Running, InstrumentStatusEnum.Paused].includes(statusEnum);
}
