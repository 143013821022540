import getCoreStatus from '../api/systemStatusService';
import FeatureFlags, {
  FeatureFlagKeys
} from '../frontend-common-libs/src/components/feature_flags';

export default async function isMaintenanceMode(): Promise<boolean> {
  if (FeatureFlags().get(FeatureFlagKeys.DISABLE_SYSTEM_STATUS_CHECK)) return false;
  try {
    const {
      data: { status }
    } = await getCoreStatus();
    return ['maintenance-mode', 'down'].includes(status);
  } catch (err) {
    return false;
  }
}
