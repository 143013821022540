import {
  getExternalTitle,
  getRouteName as getRouteNameGeneric,
  ROUTE_NOT_FOUND,
  RouteName,
  RoutePath,
  RoutePattern,
  RouteTitle,
  addExternalRouteTitles
} from './core-routes';
import uuidRegex from '../../frontend-common-libs/src/common/routes/regex';

export { RoutePattern, RouteName, RouteTitle, RoutePath, ROUTE_NOT_FOUND, addExternalRouteTitles };

/* CAUTION
  Changing route names or paths may effect User Assistance, User Analytics, and/or SQA.
  Review with team before making changes.
*/
export const APP = '/app';
export const USER = `${APP}/user`;
export const CREATE_EXPERIMENT = `${APP}/create-experiment`;
export const ARCHIVE = `${APP}/archive`;

const namedRoutes: { [key: string]: RoutePattern } = {
  LANDING: '/',
  REGISTER: '/register',
  LOGIN: '/login',
  LOGOUT: '/logout',
  TOU: '/terms-of-use',

  /* WARNING!
  If you change RESET_PASSWORD value then
  cpcp-cognito-user-role/cognito-deployment.yml CustomMessage_ForgotPassword
  will need to be updated so the reset email includes the correct url
*/
  RESET_PASSWORD: '/password/reset',
  FORGOT_PASSWORD: '/password/forgot',
  APP,
  HOME: `${APP}/home`,
  INSTRUMENT_LIST: `${APP}/instrument/list`,
  FILE_LIST: `${APP}/file/list`,
  CONTACT: `${APP}/contact`,
  ARCHIVE,
  USER_PROFILE: `${USER}/profile`,
  SETTINGS: `${USER}/settings`,
  CHANGE_PASSWORD: `${USER}/password/change`,
  CREATE_EXPERIMENT,
  EARLY_ACCESS_PROGRAM: `${APP}/join-eap`,
  LINK_INSTRUMENT: '/link',
  VERIFY_ACCOUNT: '/verify-account',
  COMPLETE_REGISTRATION: '/complete-registration',
  FLEET: `${APP}/fleet`,
  ORGANIZATION: `${USER}/organization/list`,
  ORGANIZATION_MEMBERS: `${USER}/organization/:orgId(${uuidRegex})/members`,
  ORGANIZATION_PROJECTS: `${USER}/organization/:orgId(${uuidRegex})/projects`
};

export default namedRoutes;

export function getRouteName(path: RoutePath): RouteName {
  return getRouteNameGeneric(path, namedRoutes);
}

export function getRoute(routeName: RouteName): RoutePattern {
  return namedRoutes[routeName] || ROUTE_NOT_FOUND;
}

export const routeTitles: { [key: string]: RouteTitle } = {
  HOME: 'Home',
  USER_PROFILE: 'Profile',
  CHANGE_PASSWORD: 'Profile',
  CREATE_EXPERIMENT: 'Create',
  FILE_LIST: 'Files',
  INSTRUMENT_LIST: 'Instruments',
  ARCHIVE: 'Archive',
  LINK_INSTRUMENT: 'Link Instrument',
  SETTINGS: 'Settings',
  FLEET: 'Fleet'
};

export function getRouteTitle(routePath: RoutePath): RouteTitle {
  return routeTitles[getRouteName(routePath)] || getExternalTitle(routePath) || '';
}
