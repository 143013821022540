import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, InjectedFormProps } from 'redux-form';
import NewPasswordEntry from '../../auth/components/new-password/NewPasswordEntry';
import { RenderField } from '../../frontend-common-libs/src/common/input-fields';
import { textRequired, pwdNotEqual } from '../../frontend-common-libs/src/common/form_validators';
import commonStyles from '../../frontend-common-libs/src/scss/auth.module.scss';
import authStyles from '../../auth/components/styles/auth.module.scss';
import styles from './styles/auth.module.scss';

export interface FormData {
  password1: string;
  password2: string;
}

interface AdditionalProps {
  extraError: string;
  onSubmit: (x: FormData) => any;
  onOldPasswordChanged: () => void;
}

type InjectedProps = InjectedFormProps<FormData, Props>;
export type Props = AdditionalProps & InjectedProps;

export class PasswordChangeFormImpl extends Component<Props> {
  private requiredValidator = (value: string) => textRequired(value);

  private notEqualValidator = (
    value: string,
    allValues: {
      [key: string]: any;
    }
  ) => pwdNotEqual(value, allValues.password1, true);

  render() {
    const { handleSubmit, onSubmit, dirty, extraError, submitting, invalid, onOldPasswordChanged } =
      this.props;

    return (
      <div className={authStyles.changePassword}>
        <div className={authStyles.formContainer}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Field
              label="OLD PASSWORD"
              key="pwd"
              name="password1"
              type="password"
              autoComplete="off"
              additionalClass={commonStyles.inputField}
              extraError={!dirty ? '' : extraError}
              component={RenderField}
              validate={[this.requiredValidator]}
              onChange={onOldPasswordChanged}
            />
            <NewPasswordEntry
              label="NEW PASSWORD"
              key="new-pwd"
              name="password2"
              component={RenderField}
              validate={[this.requiredValidator, this.notEqualValidator]}
              showVerifyPassword
            />
            <button
              id="change-pwd-btn"
              type="submit"
              className={`form-btn new-btn primary text-center ${styles.resetPasswordButton}`}
              disabled={submitting || invalid || !!extraError}
            >
              Change Password
            </button>
          </form>
        </div>
      </div>
    );
  }
}

export default connect(null)(
  reduxForm<FormData, Props>({
    form: 'passwordOnly'
  })(PasswordChangeFormImpl)
);
