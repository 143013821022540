import React, { Component } from 'react';
import { LinkButton } from '../../../../../frontend-common-libs/src/components/common/buttons';
import UtilityButton from '../../../../../frontend-common-libs/src/components/common/buttons/UtilityButton';

export type BasePanelProps = {
  closePanel: (...args: Array<any>) => any;
  onTabOut: (...args: Array<any>) => any;
  closePopover?: (...args: Array<any>) => any;
  isDataLoading: boolean;
};

export abstract class BasePanel<Props extends BasePanelProps, State> extends Component<
  Props,
  State
> {
  backButtonRef: React.RefObject<HTMLButtonElement> = React.createRef();

  componentDidMount() {
    this.focusBackButton();
  }

  componentDidUpdate(nextProps: Props) {
    const { isDataLoading } = nextProps;
    if (isDataLoading) this.focusBackButton();
  }

  abstract onApply(): void;

  abstract hasValidChanges(): boolean;

  focusBackButton = () => {
    const { current } = this.backButtonRef;
    if (current) current.focus();
  };

  abstract renderPanel(): JSX.Element;

  abstract panelTitle(): string;

  showBackButton = (): boolean => true;

  showCloseButton = (): boolean => false;

  render() {
    const { closePanel, isDataLoading, onTabOut, closePopover } = this.props;
    return (
      <div className="threshold-panel">
        <div className="threshold-panel-toolbar">
          <span className="popover-label">{this.panelTitle()}</span>
          {this.showBackButton() && (
            <LinkButton
              id="threshold-back-button"
              onClick={closePanel}
              buttonRef={this.backButtonRef}
              isInProgress={isDataLoading}
              type="button"
            >
              {'<'} Back
            </LinkButton>
          )}
        </div>
        {this.renderPanel()}
        <div className="popover-footer">
          <UtilityButton
            id="threshold-apply"
            type="button"
            onClick={this.onApply}
            disabled={!this.hasValidChanges()}
            isInProgress={isDataLoading}
            onKeyDown={onTabOut}
          >
            Apply
          </UtilityButton>
          {this.showCloseButton() && (
            <UtilityButton
              id="threshold-close-button"
              type="button"
              className="utility threshold-close-button"
              onClick={closePopover}
              onKeyDown={onTabOut}
            >
              Close
            </UtilityButton>
          )}
        </div>
      </div>
    );
  }
}
