// @ts-nocheck
import React from 'react';
import classNames from 'classnames';

type Props = {
  backgroundColor: string | null | undefined;
  id: string | null | undefined;
};

export default (props: Props) => {
  const { id, backgroundColor } = props;
  return (
    <div
      id={id}
      className={classNames('color-picker', {
        'no-color-icon': !backgroundColor
      })}
      style={backgroundColor && { backgroundColor }}
    />
  );
};
