import React, { Component, SyntheticEvent } from 'react';
import './styles/run-details.scss';
import { NullableString } from '../../../common/nullable-types';

export type Props = {
  runBarcode: string;
  runBarcodeError: NullableString;
  setRunBarcode: (barcode: string) => void;
  disabled?: boolean;
};

export class RunBarcode extends Component<Props> {
  updateRunBarcode = (e: SyntheticEvent<HTMLInputElement>) => {
    const { setRunBarcode } = this.props;
    const newRunBarcode = e.currentTarget.value;
    setRunBarcode(newRunBarcode);
  };

  render() {
    const { runBarcode, runBarcodeError, disabled } = this.props;
    return (
      <div className={`run-plate-id input-group ${runBarcodeError ? 'error' : ''}`}>
        <div className="label-and-error">
          <span className="control-label">Plate ID</span>
          {runBarcodeError && (
            <span id="run-plate-id-error" className="error-message">
              {runBarcodeError}
            </span>
          )}
        </div>
        <input
          id="run-plate-id"
          type="text"
          value={runBarcode}
          placeholder="Enter Plate ID here"
          onChange={this.updateRunBarcode}
          disabled={disabled}
        />
      </div>
    );
  }
}
