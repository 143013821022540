import UserAnalyticsClient from '../frontend-common-libs/src/user-analytics/UserAnalyticsClient';

export default class QPcrRunTemplateCreatedTrackingEvent {
  constructor(plateSize: number, scanMode: string) {
    this._plateSize = plateSize;
    this._scanMode = scanMode;
  }

  _plateSize: number;

  _scanMode: string;

  track() {
    const params = {
      plateSize: this._plateSize,
      scanMode: this._scanMode
    };
    UserAnalyticsClient.getInstance().track('qpcr-run-template-created', params);
  }
}
