import React, { ComponentType } from 'react';

export function NoOp() {
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <></>;
}

const withNoOpFallback = (PluginComponent: ComponentType<any> | undefined): ComponentType<any> =>
  function (props: any) {
    return PluginComponent ? <PluginComponent {...props} /> : <NoOp />;
  };

export default withNoOpFallback;
