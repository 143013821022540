import React from 'react';
import IotHandler from './IotHandler';
import IoT from '../../../frontend-common-libs/src/components/IoT';
import IoTConnection from './IoTConnection';

export default function IoTInitializer() {
  return (
    <IoT
      onMessage={IotHandler.onMessage}
      onReconnect={IotHandler.onReconnect}
      iotConnection={IoTConnection.instance}
    />
  );
}
