import React, { HTMLProps, ReactElement, ReactNode, ReactNodeArray } from 'react';
import classnames from 'classnames';
import styles from '../styles/auto-suggest.module.scss';

export interface AriaAutoSuggestDropdownProps {
  isOpen: boolean;
  children: ReactNode | ReactNodeArray;
  menuProps: HTMLProps<HTMLUListElement>;
}

export default function AriaAutoSuggestDropdown({
  isOpen,
  menuProps,
  children
}: AriaAutoSuggestDropdownProps): ReactElement {
  const ariaDropdownMenuStyles = classnames(`${styles['aria-autosuggest-dropdown']}`, {
    [styles.hide]: !isOpen
  });

  return (
    <ul className={ariaDropdownMenuStyles} {...menuProps}>
      {children}
    </ul>
  );
}
