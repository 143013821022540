import React from 'react';
import Markdown from 'markdown-to-jsx';

type Props = {
  message: string;
  onClose: () => void;
};

const MaintenanceMessage = ({ message, onClose }: Props) => {
  return (
    <div id="scheduled-maintenance-message">
      <Markdown id="markdown-content">{message}</Markdown>
      <button className="btn" onClick={onClose}>
        x
      </button>
    </div>
  );
};

export default MaintenanceMessage;
