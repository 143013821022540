import React from 'react';

import { ControlLabel, FormGroup, Row, Col } from 'react-bootstrap';
import RadioButton from '../../common/RadioButton';

export type Props = {
  id: string;
  state: boolean;
  onChange: (...args: Array<any>) => any;
};

function BeepToggle(props: Props) {
  const { id, state, onChange } = props;

  return (
    <FormGroup controlId={id}>
      <Row>
        <Col md={12}>
          <ControlLabel bsClass="heading-title">Beep</ControlLabel>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <RadioButton
            id={id}
            value={state}
            onLabel="On"
            offLabel="Off"
            onChange={onChange}
            toolTipString="Beep after step"
          />
        </Col>
      </Row>
    </FormGroup>
  );
}

export default BeepToggle;
