// @ts-nocheck
import { Map } from 'immutable';

export const fluorMode = 'fluor';
export const targetMode = 'target';

export const analysisModes = {
  [fluorMode]: 'Fluorophore',
  [targetMode]: 'Target'
};

/** gets the well contents in user displayable format */
export const getWellContent = (well: Map) => ({
  type: well.get('type'),
  sample: well.get('sample'),
  replicate: well.get('replicate')
});

export function getWellTypeReplicateMaxes(wells: Map): Map {
  return wells.reduce((accum, well) => {
    const type = well.get('type');
    const replicate = well.get('replicate') || 0;
    const existingReplicate = accum.get(type);
    if (!existingReplicate || replicate > existingReplicate) return accum.set(type, replicate);
    return accum;
  }, Map());
}
