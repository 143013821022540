import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import ProgressButton from '../ProgressButton';
import showOrNot from '../ShowOrNot';
import ButtonModel from '../../../common/ButtonModel';
import { CustomModalStyle, modalStyleV1 } from './custom-modal-styles';

type Props = {
  title: string;
  message?: string;
  children?: React.ReactNode;
  okBtnText?: string;
  cancelBtnText?: string;
  onSuccess: (...args: Array<any>) => any;
  onCancel: (...args: Array<any>) => any;
  okBtnDisabled?: boolean;
  inProgress?: boolean;
  additionalButtons?: Array<ButtonModel>;
  customModalStyle?: CustomModalStyle;
};

function messageOrChildren(message: string | undefined, children: any) {
  if (message) {
    return <h4>{message}</h4>;
  }

  return children;
}

class ModalDialog extends React.Component<Props> {
  static defaultProps = {
    okBtnText: 'Ok',
    cancelBtnText: 'Cancel',
    message: '',
    children: null,
    okBtnDisabled: false,
    inProgress: false,
    additionalButtons: undefined,
    customModalStyle: modalStyleV1
  };

  stopPropagation = (event: React.SyntheticEvent<HTMLElement>) => {
    event.stopPropagation();
  };

  onKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
    this.stopPropagation(event);
    const { onCancel, okBtnDisabled, onSuccess, additionalButtons } = this.props;
    if (event.key === 'Escape') onCancel();
    const hasAdditionalButtons = additionalButtons && additionalButtons.length > 0;
    if (event.key === 'Enter' && !okBtnDisabled && !hasAdditionalButtons) onSuccess();
  };

  renderAdditionalButtons() {
    const { additionalButtons, customModalStyle } = this.props;
    if (!additionalButtons) return null;
    return additionalButtons.map(button => {
      if (button.isVisible) {
        return (
          <Button
            id={button.id}
            onClick={button.cb}
            className={customModalStyle?.primaryBtnStyle}
            key={button.id}
          >
            {button.name}
          </Button>
        );
      }
      return null;
    });
  }

  render() {
    const {
      title,
      onCancel,
      message,
      cancelBtnText,
      children,
      onSuccess,
      okBtnDisabled,
      inProgress,
      okBtnText,
      customModalStyle
    } = this.props;
    // @ts-ignore
    return (
      <div
        onClick={this.stopPropagation}
        onKeyDown={this.onKeyDown}
        role="presentation"
        // @ts-ignore
        tabIndex="-1"
      >
        {/* @ts-ignore */}
        <Modal show className={customModalStyle?.modalStyle}>
          <Modal.Header className="modal-header-own">
            <Modal.Title id="modal-title">
              {title}
              <Button id="close-modal" className="close" data-dismiss="modal" onClick={onCancel}>
                &times;
              </Button>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div id="modal-body">{messageOrChildren(message, children)}</div>
          </Modal.Body>
          <Modal.Footer>
            <div onKeyDown={this.stopPropagation} role="presentation" className="modal-footer-own">
              <Button
                id="modal-cancel"
                onClick={onCancel}
                className={customModalStyle?.secondaryBtnStyle}
              >
                {cancelBtnText}
              </Button>
              <ProgressButton
                id="modal-ok"
                onClick={onSuccess}
                className={customModalStyle?.primaryBtnStyle}
                disabled={okBtnDisabled}
                inProgress={inProgress}
              >
                {okBtnText}
              </ProgressButton>
              {this.renderAdditionalButtons()}
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default showOrNot(ModalDialog);
