import React from 'react';

type Props = {
  className?: string;
  id?: string;
};

const Spinner = function ({ className, id }: Props) {
  return (
    <i className={`fa fa-spinner fa-spin ${className ?? ''}`} id={id ?? 'loading-screen-spinner'} />
  );
};

Spinner.defaultProps = {
  className: undefined
};

export default Spinner;
