import React, { PureComponent } from 'react';
import { Button, ButtonProps } from 'react-bootstrap';

export type Props = {
  /** renders button disabled with spinner when true
   * if undefined component manages the inProgress state */
  inProgress?: boolean;

  /** If the onClick function returns a promise,
   * the inProgress state will be set to true
   * until the promise is resolved or rejected */
  onClick?: (...args: Array<any>) => any;
} & ButtonProps;

interface State {
  inProgress: boolean;
}

export default class ProgressButton extends PureComponent<Props, State> {
  static defaultProps = {
    inProgress: undefined,
    onClick: undefined
  };

  private readonly controlled: boolean;

  private isComponentMounted = false;

  constructor(props: Props) {
    super(props);
    const { inProgress } = props;
    this.controlled = inProgress !== undefined;
    this.state = { inProgress: false };
  }

  componentDidMount() {
    this.isComponentMounted = true;
  }

  componentWillUnmount() {
    this.isComponentMounted = false;
  }

  private buttonClick = async () => {
    const { onClick } = this.props;
    if (onClick) {
      try {
        this.setState({ inProgress: true });
        await onClick();
      } catch (err) {
        // ignore
      }
      if (this.isComponentMounted) {
        this.setState({ inProgress: false });
      }
    }
  };

  render() {
    const { children, disabled, inProgress: propsProgress, onClick, ...rest } = this.props;
    const { inProgress: stateProgress } = this.state;
    const inProgress = this.controlled ? propsProgress : stateProgress;

    return (
      <Button
        disabled={disabled || inProgress}
        onClick={this.controlled ? onClick : this.buttonClick}
        {...rest}
      >
        {inProgress && <i className="fa fa-spinner fa-spin" />}
        {inProgress && ' '}
        {children}
      </Button>
    );
  }
}
