import React, { ComponentProps, ReactElement } from 'react';
import styles from '../styles/aria-auto-suggest-input.modules.scss';
import DownArrowIcon from '../../../frontend-common-libs/src/img/icons-select-down-arrow-active.svg';

export interface DropdownToggleProps {
  toggleButtonProps: ComponentProps<'button'>;
}

export default function DropdownToggle({ toggleButtonProps }: DropdownToggleProps): ReactElement {
  return (
    <button
      className={styles['dropdown-toggle']}
      type="button"
      {...toggleButtonProps}
      aria-label="toggle menu"
    >
      <img src={DownArrowIcon} alt="down-arrow-icon" />
    </button>
  );
}
