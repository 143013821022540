export type InstrumentActionType =
  | 'instrument_added'
  | 'instruments_loading'
  | 'instruments_loaded'
  | 'instruments_error'
  | 'instrument_status_updated'
  | 'unlinked_instrument';

export const INSTRUMENT_ADDED: InstrumentActionType = 'instrument_added';
export const INSTRUMENTS_LOADING: InstrumentActionType = 'instruments_loading';
export const INSTRUMENTS_LOADED: InstrumentActionType = 'instruments_loaded';
export const INSTRUMENTS_ERROR: InstrumentActionType = 'instruments_error';
export const INSTRUMENT_STATUS_UPDATED: InstrumentActionType = 'instrument_status_updated';
export const UNLINKED_INSTRUMENT: InstrumentActionType = 'unlinked_instrument';
