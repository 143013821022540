import { GATEWAY } from '../../../frontend-common-libs/src/config/config';
import requests from '../../../frontend-common-libs/src/utils/httpUtils';

const domain = GATEWAY.CPCR_SERVICE_PATHS.BASE_URL;

type GetProtocolFromRunResponse = {
  data: Record<string, unknown>;
  status: unknown;
};

export default async function getProtocolFromRun(
  runId: string
): Promise<GetProtocolFromRunResponse> {
  const path = GATEWAY.CPCR_SERVICE_PATHS.GET_PROTOCOL_FROM_RUN_URL(runId);
  const res = await requests.get(domain, {}, path, {});
  return { data: res.data, status: res.status };
}
