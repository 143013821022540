import UserAnalyticsClient from '../frontend-common-libs/src/user-analytics/UserAnalyticsClient';

export interface APITokenCreatedTrackingEventParams {
  expiration: number;
  expirationType: 'predefined' | 'custom';
}

export default class APITokenCreatedTrackingEvent {
  private readonly eventName = 'api-token-created';

  private readonly eventParams: APITokenCreatedTrackingEventParams;

  public constructor(eventParams: APITokenCreatedTrackingEventParams) {
    this.eventParams = eventParams;
  }

  public track(): void {
    UserAnalyticsClient.getInstance().track(this.eventName, this.eventParams);
  }
}
