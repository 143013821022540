import { TenantType, TenantUpdateEvent } from '@biorad-lsg-tsc/organization';
import { DispatchType } from '../actions/action-types';
import { GetState } from '../../types';
import ProjectIotHandler from '../../project-management/iot_handler/ProjectIotHandler';
import OrganizationIotHandler from './OrganizationIotHandler';

export default class AccessControlIotHandler {
  static onIotMessage(dispatch: DispatchType, getState: GetState, message: any) {
    const { event } = message;
    const { tenantType } = event as TenantUpdateEvent;
    if (tenantType === TenantType.project)
      ProjectIotHandler.onIotMessage(dispatch as any, getState, event);
    else if (tenantType === TenantType.organization)
      OrganizationIotHandler.onIotMessage(dispatch as any, getState, event);
  }
}
