import React, { Component } from 'react';
// @ts-ignore
import { Event, Field, Validator, Props as FieldProps } from 'redux-form';
import { RenderField } from '../../../frontend-common-libs/src/common/input-fields';
import PasswordFeedback from './PasswordFeedback';
import PasswordValidator from './PasswordValidator';
import commonStyles from '../../../frontend-common-libs/src/scss/auth.module.scss';

export type Props = {
  label: string;
  extraError?: string;
  validate: Validator[];
  showVerifyPassword: boolean;
} & FieldProps;

export type State = {
  passwordValidator: PasswordValidator;
};

export default class NewPasswordEntry extends Component<Props, State> {
  static VERIFY_NEW_PASSWORD_FIELD_NAME = 'verify-new-password';

  static defaultProps = {
    showVerifyPassword: false,
    extraError: undefined,
    autoComplete: 'off'
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      passwordValidator: new PasswordValidator()
    };
  }

  passwordMatchValidator = (verifyNewPasswordValue: string, allValues: any) => {
    const { name } = this.props;
    return allValues && allValues[name] !== verifyNewPasswordValue
      ? 'Passwords must match'
      : undefined;
  };

  passwordFieldIsValid = (value: string) => {
    // if password is invalid, return a non-empty string so redux-form knows it is invalid
    // but make it only whitespace so it's invisible
    const validator = new PasswordValidator(value);
    return !validator.isValid() ? ' ' : undefined;
  };

  handleChange = (e: Event) => {
    const { value } = e.target;
    this.setState({ passwordValidator: new PasswordValidator(value) });
  };

  render() {
    const { passwordValidator } = this.state;
    const { label, name, extraError, component, validate, showVerifyPassword } = this.props;
    return (
      <div>
        <Field
          label={label}
          name={name}
          type="password"
          autoComplete="off"
          additionalClass={commonStyles.inputField}
          extraError={extraError}
          component={component}
          validate={[...validate, this.passwordFieldIsValid]}
          onChange={this.handleChange}
        />
        <PasswordFeedback validator={passwordValidator} />
        {showVerifyPassword && (
          <Field
            label="VERIFY NEW PASSWORD"
            key={NewPasswordEntry.VERIFY_NEW_PASSWORD_FIELD_NAME}
            name={NewPasswordEntry.VERIFY_NEW_PASSWORD_FIELD_NAME}
            type="password"
            autoComplete="off"
            additionalClass={commonStyles.inputField}
            component={RenderField}
            validate={[this.passwordMatchValidator]}
          />
        )}
      </div>
    );
  }
}
