import { Set, List, Map, OrderedMap, fromJS } from 'immutable';
import { createCSVStringFromData } from '../frontend-common-libs/src/utils/commonUtils';
import { toFixedDecimals } from '../frontend-common-libs/src/common/numbers';
import AnalysisResultsData from './AnalysisResultsData';
import { ChartData } from '../components/pcr/analysis/ResultsChart';
import { ReplicateFormatterFunction } from './current-cfx-run-selectors';
import { Step } from '../components/pcr/analysis/models/helpers';
import { getWellContent } from '../components/pcr/analysis/pcrDataUtils';

export default class CqData extends AnalysisResultsData {
  constructor(
    wellReplicateNumberFormatter: ReplicateFormatterFunction,
    targetsOrFluorsToShow: Set<string>,
    wellsToShow: Set<string>,
    sortOrder: List<any>,
    mode: string,
    baselineSubtraction: boolean,
    currentThresholds: Map<string, any>,
    targetsOrFluorsInRun: Map<string, any>,
    cycles: number,
    channelMap: Map<string, any>,
    plateTargets: Map<string, any>,
    stepGroupData: Map<string, any>,
    plateWells: Map<string, any>,
    excludedWells: List<string>
  ) {
    super(
      wellReplicateNumberFormatter,
      targetsOrFluorsToShow,
      wellsToShow,
      sortOrder.size ? sortOrder : CqData.getDefaultSortOrder(),
      stepGroupData,
      plateWells,
      excludedWells,
      mode
    );

    this.currentThresholds = currentThresholds;
    this.targetsOrFluorsInRun = targetsOrFluorsInRun;
    this.baselineSubtraction = baselineSubtraction;
    this.mode = mode;
    this.cycles = cycles;
    this.channelMap = channelMap;
    this.plateTargets = plateTargets;

    const { cqData, baselinedData, rawData } = this.getStepData();

    this.data = cqData;
    this.baselinedData = baselinedData;
    this.rawData = rawData;
  }

  baselinedData: Map<string, any>;

  baselineSubtraction: boolean | null | undefined;

  currentThresholds: Map<string, any>;

  targetsOrFluorsInRun: Map<string, any>;

  mode: string;

  cycles: number;

  channelMap: Map<string, any>;

  plateTargets: Map<string, any>;

  tableHeaders = OrderedMap({
    well: 'well',
    fluor: 'fluor',
    target: 'target',
    content: 'content',
    sample: 'sample',
    cq: 'cq'
  });

  hasData = (): boolean => {
    const amplifications = this.baselineSubtraction ? this.baselinedData : this.rawData;

    return !!amplifications.find(targetsOrFluours =>
      targetsOrFluours.find((well: any) => well !== null)
    );
  };

  getChartData = (): ChartData => {
    const amplifications = this.baselineSubtraction ? this.baselinedData : this.rawData;

    const thresholds = this.baselineSubtraction ? this.currentThresholds : Map();

    const filteredData = AnalysisResultsData.getFilterData(
      amplifications,
      this.targetsOrFluorsToShow,
      this.wellsToShow
    );

    return {
      stepData: filteredData,
      thresholdData: thresholds,
      targetsOrFluorsInRun: this.targetsOrFluorsInRun,
      mode: this.mode,
      cycles: this.cycles,
      channelMap: this.channelMap,
      plateTargets: this.plateTargets
    };
  };

  getData = (): Map<string, any> => {
    const transformedData = this.transformCQData();

    let sortedData = AnalysisResultsData.sortData(
      // @ts-ignore
      transformedData,
      this.tableHeaders,
      this.sortOrder,
      sortKey => sortKey === 'cq'
    );

    sortedData = sortedData.map(row => {
      const cq = row.get('cq');
      return row.set('cq', !this.baselineSubtraction || cq === null ? 'N/A' : cq);
    });

    return sortedData;
  };

  getStepData = () => {
    const { data, rawData } = this.getResultsData();

    let cqData: Map<string, any> = data;
    let baselinedData = Map<string, any>();
    this.plateWells.forEach((well: Map<string, any>, wellName: string) => {
      if (!this.excludedWells.includes(wellName))
        well.get('fluors').forEach((fluor: Map<string, any>) => {
          const fluorName = fluor.get('name');
          const targetName = fluor.get('target');
          const keyPath =
            this.mode === 'target' ? [targetName || fluorName, wellName] : [fluorName, wellName];
          const cQ = Step.cq(this.stepGroupData, fluorName, wellName);
          cqData = cqData.setIn(
            keyPath,
            fromJS({
              well: wellName,
              fluor: fluorName,
              target: targetName,
              cq: cQ,
              ...getWellContent(well)
            })
          );

          const targetData = Step.wellData(this.stepGroupData, fluorName, wellName);
          baselinedData = baselinedData.setIn(
            keyPath,
            this._doBaselining(targetData, Step.baseline(this.stepGroupData, fluorName, wellName))
          );
        });
    });

    return { baselinedData, cqData, rawData };
  };

  /** performs baselining with an arbitrary offset of 0.5 a MAGIC constant from CFX Maestro */

  /** data: List<number> */

  /** baseline: { slope: number, intercept: number } */
  _doBaselining = (data: List<any>, baseline: Map<string, any>) => {
    if (baseline && baseline.get('intercept') === 'NaN') return null;

    // offset used in maestro
    const offset = 0.5;

    return data.map((val, index) =>
      baseline ? val - (baseline.get('slope') * (index + offset) + baseline.get('intercept')) : val
    );
  };

  getTableData = (): Map<string, any> =>
    this.getData().map(row => {
      const cq = parseFloat(row.get('cq'));
      const cqIsNumber = !Number.isNaN(cq);
      if (cqIsNumber) return row.set('cq', toFixedDecimals(cq, 2));

      return row;
    });

  // @ts-ignore
  getCsvDataString = (): string => createCSVStringFromData(this.tableHeaders, this.getData());

  getFileName = (runName: string) => `${runName.split(/\.(?=[^.]+$)/)[0]}_Cq_data.csv`;
}
