import GeneratePcrdRepository from './generate-pcrd_repository';
import GeneratePcrdRequestNotFoundError from './GeneratePcrdRequestNotFoundError';

export const generatePcrdRepository = new GeneratePcrdRepository();

export interface PcrdIoTMessage {
  event: {
    entityId: string;
    requestId: string;
    fileName: string;
    url?: string;
    error?: string;
  };
  sender: string;
}

export function onIotMessage(message: PcrdIoTMessage): void {
  const { requestId, url } = message.event;
  try {
    const generatePcrd = generatePcrdRepository.get(requestId);
    generatePcrd.downloadGeneratedFile(url);
  } catch (error) {
    if (error instanceof GeneratePcrdRequestNotFoundError) {
      return;
    }
    throw error;
  }
}
