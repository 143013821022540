import React, { PureComponent } from 'react';
import PCRProtocolStepSubSection from './PCRProtocolStepSubSection';

export type Props = { render?: boolean };

export default class PCRProtocolPlateRead extends PureComponent<Props> {
  static defaultProps = {
    render: undefined
  };

  render() {
    const { render } = this.props;
    if (render) {
      return <PCRProtocolStepSubSection>+ Plate Read</PCRProtocolStepSubSection>;
    }
    return null;
  }
}
