import { Component, ReactElement } from 'react';
import { List, Map } from 'immutable';

export type WithSamplePlaceholdersProps = {
  plate: Map<string, any>;
  renderPlate: (plate: Map<string, any>) => ReactElement;
  samplePlaceholders: List<Map<string, any>>;
};

type WithSamplePlaceholdersState = {};

export class WithSamplePlaceholders extends Component<
  WithSamplePlaceholdersProps,
  WithSamplePlaceholdersState
> {
  private applySamplePlaceholdersToWells(): Map<string, any> {
    const { plate, samplePlaceholders } = this.props;
    let plateWithSamples = plate;
    samplePlaceholders.forEach((samplePlaceholder, index) => {
      const wellIds = samplePlaceholder.get('wellIds');
      wellIds.forEach((wellId: string) => {
        const currentSample = plateWithSamples.getIn(['wells', wellId, 'sample']);
        if (!currentSample) {
          const sample = `« ${index + 1} »`;
          plateWithSamples = plateWithSamples.setIn(['wells', wellId, 'sample'], sample);
        }
      });
    });
    return plateWithSamples;
  }

  render() {
    const { renderPlate } = this.props;
    const plateWithSamplePlaceholders = this.applySamplePlaceholdersToWells();
    return renderPlate(plateWithSamplePlaceholders);
  }
}
