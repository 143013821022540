import { MaxOrgsPerUser } from '@biorad-lsg-tsc/organization';
import { DispatchType, ORG_ADDED } from './action-types';
import notification from '../../frontend-common-libs/src/utils/notifications';
import { createOrg as createOrgApi } from '../api/org-api';
import { GetState } from '../../types';
import {
  TrackedOrganizationEvents,
  trackEvent
} from '../../frontend-common-libs/src/user-analytics/trackedEvents';
import { isOrgCreateLimitError } from '../api/api-errors';
import { withRefreshLoginToken } from './with-refresh-login-token';
import { CbWithToken } from './WithRefreshToken';

export function dispatchOrgAdded(dispatch: DispatchType, org: { orgId: string; name: string }) {
  dispatch({
    type: ORG_ADDED,
    payload: { org }
  });
}

export function createOrg(organizationName: string, description?: string) {
  return async (dispatch: DispatchType, getState: GetState) => {
    try {
      const createOrgCb: CbWithToken = async (accessToken: string) => {
        return createOrgApi(organizationName, description, accessToken);
      };
      const org = await withRefreshLoginToken(dispatch, getState, createOrgCb);
      dispatchOrgAdded(dispatch, org);
      trackEvent(TrackedOrganizationEvents.CreateOrg, { orgName: organizationName });
    } catch (e) {
      let errMsg = 'Error creating an organization';
      if (isOrgCreateLimitError(e))
        errMsg = `User can create up to ${MaxOrgsPerUser} organization.`;
      notification.error(errMsg);
    }
  };
}
