import React, { ReactNode } from 'react';
import { Row } from 'react-bootstrap';
import StepTypeSelection from './StepTypeSelection';

export type Props = {
  children: number | string | ReactNode | Array<any>;
  currentType: string;
  onEditStepTypeChange: (...args: Array<any>) => any;
  stepsList: (...args: Array<any>) => any;
};

function EditStepContainer(props: Props) {
  const { children, currentType, stepsList, onEditStepTypeChange } = props;
  return (
    <ul className="list-unstyled equal-list-wrapper form-own-second col-xs-12 nopad">
      <li>
        <div className="col-xs-12 form-group">
          <StepTypeSelection
            currentType={currentType}
            stepsList={stepsList}
            onEditStepTypeChange={onEditStepTypeChange}
          />
        </div>
      </li>
      {children}
      <Row style={{ paddingBottom: '10px' }} />
    </ul>
  );
}

export default EditStepContainer;
