export default class LocalStorageClient {
  private readonly username: string;

  constructor(username: string) {
    this.username = username;
  }

  public setDataToLocalStorage(localStorageKey: string, localStorageData: Record<string, any>) {
    window.localStorage.setItem(
      this.username,
      JSON.stringify({
        ...this.getUserSettings(),
        [localStorageKey]: localStorageData
      })
    );
  }

  public isStale(contentVersion: number, localStorageKey: string): boolean {
    const userSettings = this.getUserSettings();
    const localStorageSettings = userSettings && userSettings[localStorageKey];
    if (localStorageSettings) {
      const { version: userCurrentVersion } = localStorageSettings;
      return userCurrentVersion < contentVersion;
    }
    return false;
  }

  private getUserSettings(): Record<string, any> {
    return JSON.parse(window.localStorage.getItem(this.username) || '{}');
  }

  public getSettings(localStorageKey: string): Record<string, any> {
    return this.getUserSettings()[localStorageKey];
  }
}
