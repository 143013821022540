import React, { SyntheticEvent, useState } from 'react';
import './styles/add-user-to-tenant.scss';
import RoleSelect from './RoleSelect';

export type AddUserToTenantProps<RoleEnum> = {
  initialInvitedUserRole: RoleEnum;
  addButtonEnabled?: boolean;
  emailError?: string;
  onAddUser: (userEmail: string, userRole: RoleEnum) => Promise<boolean>;
};

export type Props<RoleEnum> = {
  roleEnum: object;
  displayNames?: Record<string, string>;
} & AddUserToTenantProps<RoleEnum>;

export default function AddUserToTenant<RoleEnum>(props: Readonly<Props<RoleEnum>>) {
  const { emailError, initialInvitedUserRole, addButtonEnabled, roleEnum, displayNames } = props;

  const [invitedUserEmail, setInvitedUserEmail] = useState('');
  const [invitedUserRole, setInvitedUserRole] = useState(initialInvitedUserRole);
  const [invitingUser, setInvitingUser] = useState(false);

  const updateInvitedUserEmail = (e: SyntheticEvent<HTMLInputElement>) => {
    const userEmail = e.currentTarget.value;
    setInvitedUserEmail(userEmail);
  };

  const onAddUserRoleSelection = async (selectedRole: RoleEnum) => {
    setInvitedUserRole(selectedRole);
  };

  const addUser = async () => {
    const { onAddUser } = props;
    setInvitingUser(true);
    const success = await onAddUser(invitedUserEmail, invitedUserRole);
    setInvitingUser(false);
    if (success) setInvitedUserEmail('');
  };

  const disabled = !addButtonEnabled || invitingUser;

  return (
    <div className="add-user-to-tenant">
      <span className="add-member-header">Add Member</span>
      <div className="columns-row">
        <span className="column-header">User Email</span>
        <span className="column-header column-header-role">Role</span>
      </div>
      <div className="invited-user-row">
        <div className="invited-user">
          <input
            className="invited-user-email"
            id="invited-user-email"
            type="email"
            value={invitedUserEmail}
            onChange={updateInvitedUserEmail}
            disabled={disabled}
          />
          <RoleSelect
            selectedValue={invitedUserRole}
            onRoleSelection={onAddUserRoleSelection}
            roleEnum={roleEnum}
            displayNames={displayNames}
            idPostfix="add-user"
            disabled={disabled}
          />
          <button
            id="add-user-button"
            type="button"
            className="new-btn ghost invite-user-button"
            onClick={addUser}
            disabled={disabled}
          >
            Add
          </button>
        </div>
        <span id="invite-user-error" className="error-message with-ellipsis">
          {emailError}
        </span>
      </div>
    </div>
  );
}
