import UserAnalyticsClient from '../frontend-common-libs/src/user-analytics/UserAnalyticsClient';

export default class SignUpUserClickEvent {
  private static readonly SIGN_UP_USER_CLICK_EVENT_NAME = 'sign-up-user-click';

  // eslint-disable-next-line class-methods-use-this
  public track() {
    UserAnalyticsClient.getInstance().track(SignUpUserClickEvent.SIGN_UP_USER_CLICK_EVENT_NAME, {});
  }
}
