import React from 'react';
import { fromJS, Map } from 'immutable';
import { ImmutableMap, InstrumentItem } from '../../frontend-common-libs/src/common/types';
import { getCurrentStepCycles, getModelName, getStatusIcon } from '../utils/instrumentUtils';
import { CommonInstrumentRow } from '../../frontend-common-libs/instrument-row';
import RealTimePCRInstrumentTypeIcon from './RealTimePCRInstrumentTypeIcon';
import InstrumentDetails from './InstrumentDetails';
import {
  getTimeRemaining,
  TimeRemainingNA
} from '../../frontend-common-libs/utils/instrumentUtils';
import { InstrumentStatusEnum } from '../../instruments/utils/instrumentUtils';

export type Props = {
  instrument: ImmutableMap<InstrumentItem>;
  timeoutAction?: Function;
};

export default function RealTimeInstrumentRow({ instrument }: Props) {
  const instrumentState = instrument.get('instrumentState');
  const deviceStatus = instrument.get('deviceStatus');

  function calculateTimeRemaining(): string {
    return instrumentState
      ? getTimeRemaining(instrumentState.getIn(['reported', 'details', 'blkA', 'etr']) as number)
      : TimeRemainingNA;
  }

  function computeInstrumentStatus(): string {
    let status: string;
    if (deviceStatus) {
      const lastKnownStatus = deviceStatus.getIn([
        'statusObject',
        'state',
        'reported',
        'status'
      ]) as string;
      if (lastKnownStatus) {
        const isStale = deviceStatus.get('isStale');
        status = isStale ? InstrumentStatusEnum.Offline : lastKnownStatus;
      } else {
        status = InstrumentStatusEnum.Unknown;
      }
    } else {
      // if deviceStatus is not available then IOT is still connecting
      status = InstrumentStatusEnum.Connecting;
    }
    return status;
  }

  function getUserId(): string {
    return instrumentState ? (instrumentState.getIn(['reported', 'userID']) as string) : '';
  }

  const instrumentStateBlkA = (
    instrumentState ? instrumentState.getIn(['reported', 'details', 'blkA']) : fromJS({})
  ) as Map<string, any>;
  const protocolSteps = instrument.get('steps');
  const step = instrumentStateBlkA ? instrumentStateBlkA.get('step') : 0;
  const instrumentId = instrument.get('id');
  const instrumentStatus = computeInstrumentStatus();
  const runId = instrumentStateBlkA && instrumentStateBlkA.get('runID');
  const serial = instrument.get('serial');
  const cycles = getCurrentStepCycles(step, protocolSteps);
  const instrumentIcon = <RealTimePCRInstrumentTypeIcon type={instrument.get('type')} />;
  const runName = instrument.get('runName');
  const cycle = instrumentStateBlkA ? instrumentStateBlkA.get('cyc') : 0;

  return (
    <CommonInstrumentRow
      id={instrumentId}
      displayModel={getModelName(instrument.get('type'), instrument.get('model'))}
      instrumentName={instrument.get('name')}
      instrumentStatus={instrumentStatus}
      timeRemaining={calculateTimeRemaining()}
      statusIcon={getStatusIcon(instrumentStatus)}
      instrumentIcon={instrumentIcon}
    >
      <InstrumentDetails
        serial={serial}
        instrumentId={instrumentId}
        status={instrumentStatus}
        userId={getUserId()}
        runId={runId}
        runName={runName}
        step={step}
        steps={protocolSteps ? protocolSteps.size : 0}
        cycle={cycle}
        cycles={cycles}
      />
    </CommonInstrumentRow>
  );
}
