import React from 'react';
import Table from '../../../frontend-common-libs/src/components/common/tables/Table';
import THead from '../../../frontend-common-libs/src/components/common/tables/THead';
import TCell from '../../../frontend-common-libs/src/components/common/tables/TCell';
import styles from './styles/fleet-management-table.module.scss';

export type Props = {
  isHidden: boolean;
  children: React.ReactNode[];
};

export default function FleetManagementTable(props: Readonly<Props>) {
  let classToHideTheTable = '';
  const { isHidden, children } = props;

  if (isHidden) {
    classToHideTheTable = 'hidden';
  }

  const [selectAllCheckbox, additionalHeaders, instrumentRows] = children;
  const renderHeaders = () => {
    return (
      <THead>
        {selectAllCheckbox}
        <TCell>MODEL</TCell>
        <TCell>NAME</TCell>
        {additionalHeaders}
      </THead>
    );
  };

  return (
    <Table
      className={`flex-column-container base-table ${classToHideTheTable} ${styles.fleetManagementTable}`}
    >
      {renderHeaders()}
      {instrumentRows}
    </Table>
  );
}
