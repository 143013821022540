import { fromJS, Map, OrderedMap } from 'immutable';
import { sortEntitiesByLastUpdated } from '../../../frontend-common-libs/src/utils/rowEntityUtils';
import { jsArrayToOrderedMap } from '../../../frontend-common-libs/src/utils/immutableUtils';
import { ProjectId } from '../../../frontend-common-libs/src/common/project-management-types';

export default class ProjectProtocols {
  readonly projectId: ProjectId;

  isLoading!: boolean;

  protocols!: Map<string, any>;

  lastSeen!: string;

  errorMessage!: string;

  staleData!: boolean;

  private readonly publishStateChange: () => void;

  constructor(projectId: ProjectId, onStateChanged: () => void) {
    this.projectId = projectId;
    this.publishStateChange = onStateChanged;
    this.setInitialState();
  }

  setInitialState() {
    this.isLoading = false;
    this.protocols = Map<string, any>({});
    this.lastSeen = '';
    this.errorMessage = '';
    this.staleData = true;
  }

  setLoading = (): void => {
    this.isLoading = true;
    this.publishStateChange();
  };

  setError = (error: string): void => {
    this.errorMessage = error;
    this.isLoading = false;
    this.publishStateChange();
  };

  updateProtocolsList = (payload: any): void => {
    const newProtocols = sortEntitiesByLastUpdated(
      Map<string, any>(this.protocols).merge(jsArrayToOrderedMap(payload.entities, 'id'))
    );
    this.isLoading = false;
    this.protocols = newProtocols;
    this.lastSeen = payload.lastSeen ? payload.lastSeen : '';
    this.errorMessage = '';
    this.staleData = false;
    this.publishStateChange();
  };

  protocolUpdated = (protocolFile: any): void => {
    const entityId = protocolFile.id;
    const newProtocols = OrderedMap<any>({})
      .set(entityId, fromJS(protocolFile))
      .merge(this.protocols.delete(entityId));
    this.protocols = newProtocols;
    this.publishStateChange();
  };

  protocolsArchived = (entityIdList: string[]): void => {
    entityIdList.forEach((id: string) => {
      this.protocols = this.protocols.delete(id);
    });
    this.publishStateChange();
  };
}
