import React, { useEffect, useState } from 'react';
import { Map } from 'immutable';
import InstrumentReservation from '../InstrumentReservation';
import styles from './styles/manage-reservations.module.scss';
import ManageReservationsTable from '../manage-reservations-tab/manage-reservations-table/ManageReservationsTable';
import { NoReservedInstrumentsMessage } from '../no-reserved-instruments-message';
import { ImmutableMap, InstrumentItem } from '../../../frontend-common-libs/src/common/types';
import ProtocolSelectionDropdown, {
  fetchProtocolsIfNeeded
} from '../manage-reservations-tab/protocol-selection-dropdown/ProtocolSelectionDropdown';
import unreserveSelectedInstruments, {
  assignProtocolHandler
} from './ReservationButtonsOnClickHandlers';
import { SecondaryButton } from '../../../frontend-common-libs/src/components/common/buttons';
import PrimaryButton from '../../../frontend-common-libs/src/common/buttons';
import notification from '../../../frontend-common-libs/src/utils/notifications';
import noneProtocolItem from '../noneProtocolItem';
import useCheckboxes from '../hooks/useCheckboxes';

export type Props = {
  instruments: ImmutableMap<InstrumentItem>[];
  reservations: Map<string, InstrumentReservation>;
  projectId: string;
};

type ButtonProps = {
  id: string;
  label: string;
  stylesClassName?: string;
  disabled?: boolean;
  onClickHandler?: (...args: Array<any>) => any;
};

export default function ManageReservations(props: Readonly<Props>) {
  const { instruments, reservations, projectId } = props;
  const hasReservedInstruments = reservations.size > 0;

  const [editReservations, setEditReservations] = useState(false);
  const [selectedProtocolName, setSelectedProtocolName] = useState(noneProtocolItem.name);
  const [selectedProtocolId, setSelectedProtocolId] = useState(noneProtocolItem.id);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  const {
    selectedInstruments,
    selectAllCheckbox,
    clearSelectedInstruments,
    updateSelectedInstruments,
    handleSelectAll,
    clearSelection
  } = useCheckboxes();

  useEffect(() => {}, [hasReservedInstruments]);

  const reservedInstruments = instruments.filter(instrument => {
    const instrumentId = instrument.get('id');
    return reservations.has(instrumentId);
  });

  const protocols = fetchProtocolsIfNeeded(projectId);

  const updateSelectedProtocol = (protocol: { id: string; name: string }) => {
    setSelectedProtocolId(protocol.id);
    setSelectedProtocolName(protocol.name);
  };

  const disableButton = selectedInstruments.length === 0 || isDisabled;

  const renderButton = (buttonProps: ButtonProps) => {
    return (
      <PrimaryButton
        id={buttonProps.id}
        className={buttonProps.stylesClassName}
        type="submit"
        isInProgress={false}
        disabled={buttonProps.disabled}
        onClick={buttonProps.onClickHandler}
      >
        {buttonProps.label}
      </PrimaryButton>
    );
  };

  const renderUnreserveButton = (): React.JSX.Element => {
    return renderButton({
      id: 'unreserve-button',
      label: 'Unreserve',
      stylesClassName: styles.unreserveButton,
      disabled: disableButton,
      onClickHandler: async () => {
        await unreserveSelectedInstruments(selectedInstruments, instruments, reservations);
        clearSelection();
      }
    });
  };

  const renderEditButton = (): React.JSX.Element => {
    return renderButton({
      id: 'edit-button',
      label: 'Edit',
      stylesClassName: styles.editButton,
      disabled: disableButton,
      onClickHandler: () => {
        setEditReservations(true);
      }
    });
  };

  const exitEditMode = () => {
    setSelectedProtocolName(noneProtocolItem.name);
    setSelectedProtocolId(noneProtocolItem.id);
    setEditReservations(false);
    setIsDisabled(false);
  };

  const renderEditReservationButtons = () => {
    const cancelButton = (
      <SecondaryButton
        type="button"
        id="cancel-button"
        className={styles.cancelButton}
        onClick={exitEditMode}
      >
        Cancel
      </SecondaryButton>
    );

    const saveButton = renderButton({
      id: 'save-button',
      label: 'Save',
      stylesClassName: styles.saveButton,
      disabled: disableButton,
      onClickHandler: async () => {
        try {
          setIsDisabled(true);
          await assignProtocolHandler(
            selectedInstruments,
            instruments,
            reservations,
            selectedProtocolName,
            selectedProtocolId
          );
          exitEditMode();
          clearSelection();
        } catch (error) {
          console.error(
            selectedInstruments,
            reservations,
            selectedProtocolName,
            selectedProtocolId
          );
          if (selectedProtocolId === noneProtocolItem.id) {
            notification.error('Protocol unassignment failed. Try again.');
          } else {
            notification.error('Protocol assignment failed. Try again.');
          }

          setIsDisabled(false);
        }
      }
    });
    return (
      <>
        {cancelButton}
        {saveButton}
      </>
    );
  };

  const renderButtons = (): React.JSX.Element => {
    if (editReservations) {
      return renderEditReservationButtons();
    }
    return (
      <>
        {renderUnreserveButton()} {renderEditButton()}
      </>
    );
  };

  return (
    <>
      <div
        className={`flex-column-container ${styles.manageReservationsContainer}`}
        data-testid="manage-reservations-container"
      >
        {hasReservedInstruments ? (
          <div className={styles.manageReservationsTableContainer}>
            <ManageReservationsTable
              instruments={reservedInstruments}
              reservations={reservations}
              updateSelectedInstruments={updateSelectedInstruments}
              handleSelectAll={handleSelectAll}
              selectAllCheckbox={selectAllCheckbox}
              clearSelectedInstruments={clearSelectedInstruments}
            />
            {editReservations ? (
              <ProtocolSelectionDropdown
                // @ts-ignore
                protocols={protocols}
                selectedProtocol={selectedProtocolName}
                updateSelectedProtocol={updateSelectedProtocol}
              />
            ) : null}
          </div>
        ) : (
          <NoReservedInstrumentsMessage />
        )}
      </div>
      <div className={styles.buttonContainer}>{renderButtons()}</div>
    </>
  );
}
