import React from 'react';
import { MenuItem } from 'react-aria-menubutton';

export type Props = {
  id: string;
  className: string;
  toRoute: string;
  openInNewTab?: boolean;
  label: string;
  onSelection?: () => void;
};

export default function CommonMenuItemLink({
  id,
  className,
  toRoute,
  label,
  openInNewTab = false,
  onSelection
}: Props) {
  const handleSelection = () => {
    if (onSelection) {
      onSelection();
    }

    window.open(toRoute, openInNewTab ? '_blank' : undefined);
  };

  return (
    <MenuItem className={className} id={id} value={handleSelection}>
      <span role="presentation">{label}</span>
    </MenuItem>
  );
}
