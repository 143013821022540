import React, { Component } from 'react';
import { Project } from '../../frontend-common-libs/src/common/project-management-types';
import '../styles/user-projects.scss';
import ProjectRow from './ProjectRow';

export type Props = {
  projects: Project[];
  selectedProject: Project | undefined;
  onRowClick: (project: Project) => void;
  className?: string;
  activeProjectId?: string;
};

// eslint-disable-next-line react/prefer-stateless-function
export default class ProjectList extends Component<Props> {
  render() {
    const { projects, selectedProject, onRowClick, className, activeProjectId } = this.props;
    return (
      <div className={className}>
        <div id="project-list-title" className="header-line">
          Name
        </div>
        <div className="tenant-rows">
          {projects.map((project: Project) => (
            <ProjectRow
              project={project}
              key={project.id}
              onClick={onRowClick}
              isSelected={selectedProject != null && project.id === selectedProject.id}
              isActive={project.id === activeProjectId}
            />
          ))}
        </div>
      </div>
    );
  }
}
