import { CPCR_PROTOCOL_ENTITY_TYPE } from '../../../frontend-common-libs/src/conventional-pcr/protocol';
import ProtocolIotHandler from '../../protocol/actions/ProtocolIotHandler';
import { FILETYPE_PTC_RUN } from '../../../frontend-common-libs/src/common/userfiles_common';
import RunIotHandler from '../../completed-run/actions/RunIotHandler';

export default class IotHandler {
  static onMessage(_topic: string, message: any) {
    if (message.sender !== 'file-arbiter') return;
    const { event: entity } = message;
    if (!entity) return;
    const { type } = entity;
    if (type === CPCR_PROTOCOL_ENTITY_TYPE) ProtocolIotHandler.onIotMessage(entity);
    else if (type === FILETYPE_PTC_RUN) RunIotHandler.onIotMessage(entity);
  }

  static onReconnect() {
    // nothing to do
  }
}
