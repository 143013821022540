import { fromJS, Map, OrderedMap } from 'immutable';
import {
  ARCHIVE_LOADING,
  ARCHIVE_LOADED,
  ARCHIVE_ERROR,
  ArchiveActionType
} from '../actions/archive_types';
import { USERFILES_ARCHIVED, USERFILES_RESTORED } from '../file-management/actions/action-types';
import {
  PROTOCOL_TEMPLATES_ARCHIVED,
  PROTOCOL_TEMPLATES_RESTORED
} from '../actions/protocolTemplate_types';
import { UNAUTH_USER } from '../auth/actions/auth_types';
import { jsArrayToOrderedMap } from '../frontend-common-libs/src/utils/immutableUtils';
import { ApiAction } from '../types';
import { sortEntitiesByLastUpdated } from '../frontend-common-libs/src/utils/rowEntityUtils';
import { PROJECT_SELECTED } from '../project-management';
import { UserFile } from '../frontend-common-libs/src/common/types';
import { DEFAULT_PROJECT_ID } from '../frontend-common-libs/src/common/project-management-types';

// @ts-ignore
const INITIAL_STATE_FOR_PROJECT: Map<string, any> = fromJS({
  isLoading: false,
  staleData: true,
  entities: OrderedMap({}),
  errorMessage: '',
  lastSeen: ''
});

// @ts-ignore
const INITIAL_STATE: Map<string, any> = fromJS({
  projects: {
    [DEFAULT_PROJECT_ID]: INITIAL_STATE_FOR_PROJECT
  }
});

function projectExistsInState(state: Map<string, any>, projectId: string): boolean {
  return state.getIn(['projects', projectId]) != null;
}

function getEntities(state: Map<string, any>, projectId: string): OrderedMap<string, any> {
  return state.getIn(['projects', projectId, 'entities']) as OrderedMap<string, any>;
}

const onArchiveLoading = (state: Map<string, any>, action: ApiAction<ArchiveActionType, any>) => {
  const { projectId } = action.payload;
  return state
    .setIn(['projects', projectId, 'isLoading'], true)
    .setIn(['projects', projectId, 'errorMessage'], '');
};

const onArchiveLoaded = (state: Map<string, any>, action: ApiAction<ArchiveActionType, any>) => {
  const { entities, projectId } = action.payload;
  // add the entities to end of the list
  const newEntities = getEntities(state, projectId).merge(jsArrayToOrderedMap(entities, 'id'));
  return state
    .setIn(['projects', projectId, 'isLoading'], false)
    .setIn(['projects', projectId, 'staleData'], false)
    .setIn(['projects', projectId, 'entities'], sortEntitiesByLastUpdated(newEntities))
    .setIn(['projects', projectId, 'errorMessage'], '')
    .setIn(
      ['projects', projectId, 'lastSeen'],
      action.payload.lastSeen ? action.payload.lastSeen : ''
    );
};

const onArchiveError = (state: Map<string, any>, action: ApiAction<ArchiveActionType, any>) => {
  const { projectId, message } = action.payload;
  return state
    .setIn(['projects', projectId, 'isLoading'], false)
    .setIn(['projects', projectId, 'errorMessage'], message);
};

const addArchivedEntity = (entity: UserFile, state: Map<string, any>) => {
  const projectId = entity.parent_id;
  const entities = getEntities(state, projectId);
  if (entities == null) return state;
  return state.setIn(
    ['projects', projectId, 'entities'],
    jsArrayToOrderedMap([entity], 'id').merge(entities)
  );
};

const onEntityArchived = (state: Map<string, any>, action: ApiAction<ArchiveActionType, any>) => {
  const { entities } = action.payload;
  let newState = state;
  entities.forEach((entity: UserFile) => {
    newState = addArchivedEntity(entity, newState);
  });
  return newState;
};

const deleteArchivedEntity = (entity: UserFile, state: Map<string, any>) => {
  const projectId = entity.parent_id;
  let entities = getEntities(state, projectId);
  if (entities == null) return state;
  entities = entities.delete(entity.id);
  return state.setIn(['projects', projectId, 'entities'], entities);
};

const onArchiveRestored = (state: Map<string, any>, action: ApiAction<ArchiveActionType, any>) => {
  const { entities: restoredList } = action.payload;
  let newState = state;
  restoredList.forEach((file: UserFile) => {
    newState = deleteArchivedEntity(file, newState);
  });
  return newState;
};

function onChangeProjectSelection(state: Map<string, any>, action: any) {
  const { projectId } = action.payload;
  const projectExists = projectExistsInState(state, projectId);
  if (!projectExists) return state.setIn(['projects', projectId], INITIAL_STATE_FOR_PROJECT);
  return state;
}

const resetState = () => INITIAL_STATE;

const actionMap = {
  [ARCHIVE_LOADING]: onArchiveLoading,
  [ARCHIVE_LOADED]: onArchiveLoaded,
  [ARCHIVE_ERROR]: onArchiveError,
  [USERFILES_RESTORED]: onArchiveRestored,
  [PROTOCOL_TEMPLATES_RESTORED]: onArchiveRestored,
  [USERFILES_ARCHIVED]: onEntityArchived,
  [PROTOCOL_TEMPLATES_ARCHIVED]: onEntityArchived,
  [PROJECT_SELECTED]: onChangeProjectSelection,
  [UNAUTH_USER]: resetState // User logged out
};

export default function archiveReducer(
  state: Map<string, any> = INITIAL_STATE,
  action: ApiAction<any, any> | undefined = undefined
): Map<string, any> {
  if (action) {
    return actionMap[action.type] ? actionMap[action.type](state, action) : state;
  }
  return state;
}
