import { generatePcrdFile, getGeneratePcrdPolling } from '../../api/pcrData';
import { generatePcrdRepository } from './generate_pcrd_actions';
import { browserDownloadFile } from '../../frontend-common-libs/src/utils/petfUtils';
import {
  EXPORT_FILE_COMPLETE,
  EXPORT_FILE_FAILED,
  EXPORT_FILE_STARTED
} from '../../frontend-common-libs/src/file-operations/file_operation_types';
import { Dispatch } from '../../types';
import GeneratePcrdRequestNotStartedError from './GeneratePcrdRequestNotStartedError';
import GeneratePcrdRequestNotFoundError from './GeneratePcrdRequestNotFoundError';
import { getNextUploadId } from '../../frontend-common-libs/src/file-operations/utils';
import { isNetworkError } from '../../frontend-common-libs/src/utils/errorUtils';
import { DownloadNetworkError } from '../../frontend-common-libs/src/file-operations/messages';
import {
  TrackedFilesEvents,
  trackEvent
} from '../../frontend-common-libs/src/user-analytics/trackedEvents';

export default class GeneratePcrd {
  private readonly entitiyId: string;

  private static readonly timeToStartPollingMs: number = 60000;

  private static readonly timeBetweenRetryMs: number = 30000;

  public retries = 3;

  public requestId: string | undefined;

  public fileOprationId: number = getNextUploadId();

  private readonly fileName: string;

  private timeOutId: NodeJS.Timeout | null = null;

  public fileDownloaded = false;

  private readonly dispatch: Dispatch<any, any>;

  constructor(entitiyId: string, name: string, dispatch: Dispatch<any, any>) {
    this.entitiyId = entitiyId;
    this.fileName = name;
    this.dispatch = dispatch;
  }

  public async startGenerating(): Promise<void> {
    this.dispatchStart();
    const result = await generatePcrdFile(this.entitiyId);
    this.requestId = result.data.requestId;
    if (!this.requestId) {
      throw new GeneratePcrdRequestNotFoundError('Could not generate the pcrd file.');
    }
    generatePcrdRepository.add(this, this.requestId);
  }

  private dispatchStart(): void {
    this.fileOprationId = getNextUploadId();
    this.dispatch({
      type: EXPORT_FILE_STARTED,
      payload: {
        id: this.fileOprationId,
        fileName: this.fileName
      }
    });
  }

  public async waitForFile(): Promise<void> {
    await this.startGenerating();
    // if an IoT message is recived the file will be downloaded
    // if the after a time out the IoT message is not received we start polling the file
    this.startPolling();
  }

  private startPolling(): void {
    this.timeOutId = setTimeout(this.onTimeOut, GeneratePcrd.timeToStartPollingMs);
  }

  public async onTimeOut(): Promise<void> {
    if (!this.requestId) {
      throw new GeneratePcrdRequestNotStartedError(
        'Tried to call onTimeOut before calling startGenerating.'
      );
    }

    try {
      const res = await getGeneratePcrdPolling(this.requestId);
      const { completed } = res.data;
      if (!completed) {
        if (this.retries > 0) {
          this.timeOutId = setTimeout(this.onTimeOut, GeneratePcrd.timeBetweenRetryMs);
          this.retries -= 1;
        } else {
          // could not get the generated file after all the retries
          this.downloadGeneratedFile(null);
        }
      } else {
        const { preSignedUrl } = res.data;
        this.downloadGeneratedFile(preSignedUrl);
      }
    } catch (error) {
      this.downloadGeneratedFile(null);
    }
  }

  private clearTimeout(): void {
    if (this.timeOutId) {
      clearTimeout(this.timeOutId);
    }
  }

  public cleanUp(): void {
    if (!this.requestId) {
      throw new GeneratePcrdRequestNotStartedError(
        'Tried to call onTimeOut before calling startGenerating.'
      );
    }

    this.fileDownloaded = true;
    this.clearTimeout();
    generatePcrdRepository.delete(this.requestId);
  }

  public downloadGeneratedFile(url: string | null | undefined): void {
    if (this.fileDownloaded) return;
    try {
      this.cleanUp();
      if (url == null) {
        this.handleErrorInGenerating();
        return;
      }
      browserDownloadFile(this.fileName, url);
      this.dispatchCompleted();
    } catch (er) {
      this.handleErrorInGenerating(er as Error);
    }
  }

  private dispatchCompleted(): void {
    this.dispatch({
      type: EXPORT_FILE_COMPLETE,
      payload: {
        id: this.fileOprationId
      }
    });
    trackEvent(TrackedFilesEvents.CfxExportPcrd, { fileName: this.fileName });
  }

  public handleErrorInGenerating(error?: Error): void {
    let errorMessage: string | undefined;
    if (isNetworkError(error)) errorMessage = DownloadNetworkError;
    this.dispatchFailed(errorMessage);
  }

  private dispatchFailed(errorMessage?: string): void {
    this.dispatch({
      type: EXPORT_FILE_FAILED,
      payload: {
        id: this.fileOprationId,
        errorMessage
      }
    });
  }
}
