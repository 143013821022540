import { ProjectId } from '../../frontend-common-libs/src/common/project-management-types';
import { Dispatch } from '../../types';

export type ProjectManagementActionTypes =
  | 'project_selected'
  | 'projects_loading'
  | 'projects_loaded'
  | 'project_added'
  | 'project_details_loading'
  | 'project_details_loaded'
  | 'project_user_added'
  | 'project_removed'
  | 'project_user_removed'
  | 'project_token_loading'
  | 'project_token_loaded'
  | 'project_token_revoked'
  | 'project_member_role_changed'
  | 'project_renamed';

export const PROJECT_SELECTED: ProjectManagementActionTypes = 'project_selected';
export const PROJECTS_LOADING: ProjectManagementActionTypes = 'projects_loading';
export const PROJECTS_LOADED: ProjectManagementActionTypes = 'projects_loaded';
export const PROJECT_ADDED: ProjectManagementActionTypes = 'project_added';
export const PROJECT_REMOVED: ProjectManagementActionTypes = 'project_removed';
export const PROJECT_DETAILS_LOADING: ProjectManagementActionTypes = 'project_details_loading';
export const PROJECT_DETAILS_LOADED: ProjectManagementActionTypes = 'project_details_loaded';
export const PROJECT_USER_ADDED: ProjectManagementActionTypes = 'project_user_added';
export const PROJECT_USER_REMOVED: ProjectManagementActionTypes = 'project_user_removed';
export const PROJECT_TOKEN_LOADING: ProjectManagementActionTypes = 'project_token_loading';
export const PROJECT_TOKEN_LOADED: ProjectManagementActionTypes = 'project_token_loaded';
export const PROJECT_TOKEN_REVOKED: ProjectManagementActionTypes = 'project_token_revoked';
export const PROJECT_MEMBER_ROLE_CHANGED: ProjectManagementActionTypes =
  'project_member_role_changed';
export const PROJECT_RENAMED: ProjectManagementActionTypes = 'project_renamed';

export type DispatchType = Dispatch<ProjectManagementActionTypes, Record<string, any> | string>;

export interface ProjectSelectedPayload {
  projectId: ProjectId;
}
