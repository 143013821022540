// @ts-nocheck
import React from 'react';
import { Node } from 'react';
import classnames from 'classnames';

type WellButtonProps = {
  children: Node;
  className?: string;
  id: string;
  disabled?: boolean;
};

const WellButton = ({ children, className, id, disabled }: WellButtonProps): Node => (
  <button
    id={id}
    type="button"
    className={classnames('well-button', className)}
    disabled={disabled}
  >
    {children}
  </button>
);

WellButton.defaultProps = {
  disabled: false,
  className: undefined,
  children: ''
};

export default WellButton;
