import { Set, List, Map, OrderedMap, fromJS } from 'immutable';
import { createCSVStringFromData } from '../frontend-common-libs/src/utils/commonUtils';
import { toFixedDecimals } from '../frontend-common-libs/src/common/numbers';
import AnalysisResultsData from './AnalysisResultsData';
import { ChartData } from '../components/pcr/analysis/ResultsChart';
import { ReplicateFormatterFunction } from './current-cfx-run-selectors';

export const MeltModeEnum = {
  MeltPeak: 'Melt Peak',
  MeltCurve: 'Melt Curve'
};

export default class MeltData extends AnalysisResultsData {
  constructor(
    wellReplicateNumberFormatter: ReplicateFormatterFunction,
    targetsOrFluorsToShow: Set<string>,
    wellsToShow: Set<string>,
    sortOrder: List<any>,
    mode: string,
    cycles: number,
    stepProtocol: Map<string, any>,
    currentThresholds: Map<string, any>,
    targetsOrFluorsInRun: Set<string>,
    channelMap: Map<string, any>,
    plateTargets: Map<string, any>,
    stepGroupData: Map<string, any>,
    plateWells: Map<string, any>,
    excludedWells: List<string>,
    meltMode: string
  ) {
    super(
      wellReplicateNumberFormatter,
      targetsOrFluorsToShow,
      wellsToShow,
      sortOrder.size ? sortOrder : MeltData.getDefaultSortOrder(),
      stepGroupData,
      plateWells,
      excludedWells,
      mode
    );

    this.mode = mode;
    this.cycles = cycles;
    this.meltMode = meltMode;
    this.stepProtocol = stepProtocol;
    this.currentThresholds = currentThresholds;
    if (meltMode === MeltModeEnum.MeltCurve)
      this.currentThresholds = fromJS({}) as Map<string, any>; // no thresholds for melt curve
    this.targetsOrFluorsInRun = targetsOrFluorsInRun;
    this.channelMap = channelMap;
    this.plateTargets = plateTargets;

    const { meltData, chartData } = this.getStepData();
    this.chartData = chartData as Map<string, any>;
    this.data = meltData;
  }

  chartData: Map<string, any>;

  mode: string;

  cycles: number;

  stepProtocol: Map<string, any>;

  currentThresholds: Map<string, any>;

  targetsOrFluorsInRun: Set<string>;

  channelMap: Map<string, any>;

  plateTargets: Map<string, any>;

  meltMode: string;

  tableHeaders = OrderedMap({
    well: 'well',
    fluor: 'fluor',
    target: 'target',
    content: 'content',
    sample: 'sample',
    meltTemp: 'melt temp',
    peakHeight: 'peak height',
    beginTemp: 'begin temp',
    endTemp: 'end temp'
  });

  getChartData = (): ChartData => {
    const startTemp = this.stepProtocol.get('temp');
    const incrementTemp = this.stepProtocol.get('inc');
    const filteredData = AnalysisResultsData.getFilterData(
      this.chartData,
      this.targetsOrFluorsToShow,
      this.wellsToShow
    );

    const getXAxisValue = (cycle: number) => startTemp + cycle * incrementTemp;

    return {
      stepData: filteredData,
      thresholdData: this.currentThresholds,
      targetsOrFluorsInRun: this.targetsOrFluorsInRun,
      mode: this.mode,
      cycles: this.cycles,
      channelMap: this.channelMap,
      plateTargets: this.plateTargets,
      getXAxisValue
    };
  };

  getData = (): Map<string, any> => {
    let transformedData = this.transformCQData();

    transformedData = transformedData.reduce((accum: List<any>, well: Map<string, any>) => {
      const meltPeakData = well.get('meltPeakData');
      const wellWithoutMeltPeakData = well.delete('meltPeakData');

      if (meltPeakData.size === 0) {
        return accum.push(
          wellWithoutMeltPeakData
            .set('beginTemp', null)
            .set('endTemp', null)
            .set('meltTemp', null)
            .set('peakHeight', null)
        );
      }

      const wellWithPeakData = meltPeakData.map((chartData: Map<string, any>) =>
        wellWithoutMeltPeakData.merge(chartData)
      );

      return accum.concat(wellWithPeakData);
    }, List());

    const numericKeys = ['meltTemp', 'peakHeight', 'beginTemp', 'endTemp'];

    let sortedData = AnalysisResultsData.sortData(
      // @ts-ignore
      transformedData,
      this.tableHeaders,
      this.sortOrder,
      sortKey => numericKeys.includes(sortKey)
    );

    sortedData = sortedData.map((row: Map<string, any>) => {
      const beginTemp = row.get('beginTemp');
      const endTemp = row.get('endTemp');
      const meltTemp = row.get('meltTemp');
      const peakHeight = row.get('peakHeight');

      return row
        .set('beginTemp', this._formatSortedData(beginTemp))
        .set('endTemp', this._formatSortedData(endTemp))
        .set('meltTemp', this._formatSortedData(meltTemp))
        .set('peakHeight', this._formatSortedData(peakHeight));
    });

    return sortedData;
  };

  getTableData = (): Map<string, any> =>
    this.getData().map(row => {
      const beginTemp = row.get('beginTemp');
      const endTemp = row.get('endTemp');
      const meltTemp = row.get('meltTemp');
      const peakHeight = row.get('peakHeight');

      return row
        .set('beginTemp', this._formatTableData(beginTemp))
        .set('endTemp', this._formatTableData(endTemp))
        .set('meltTemp', this._formatTableData(meltTemp))
        .set('peakHeight', this._formatTableData(peakHeight));
    });

  getStepData = () => {
    const meltPeaks = this.stepGroupData.getIn(['computations', 'perWell', 'peaks']) as
      | Map<string, any>
      | undefined;

    const meltData = this.data.reduce(
      (accum: Map<string, any>, value: any, targetOrFluor: string) =>
        accum.set(
          targetOrFluor,
          value.reduce((result: Map<string, any>, wellData: Map<string, any>, well: string) => {
            if (meltPeaks) {
              const meltPeakData = meltPeaks.getIn([targetOrFluor, well]);
              return result.set(well, wellData.set('meltPeakData', meltPeakData));
            }
            return result;
          }, Map())
        ),
      Map<string, any>()
    );

    const chartData =
      this.meltMode === MeltModeEnum.MeltCurve
        ? this.stepGroupData.getIn(['data'])
        : this.stepGroupData.getIn(['computations', 'perWell', 'curves']);

    return { meltData, chartData };
  };

  _formatTableData = (data: number): string =>
    // @ts-ignore
    typeof data === 'string' ? data : toFixedDecimals(parseFloat(data), 2);

  _formatSortedData = (data: number | null | undefined): number | string => (!data ? 'None' : data);

  // @ts-ignore
  getCsvDataString = (): string => createCSVStringFromData(this.tableHeaders, this.getData());

  getFileName = (runName: string) => `${runName.split(/\.(?=[^.]+$)/)[0]}_Melt_data.csv`;
}
