import { CognitoUserAttribute, CognitoUserPool } from 'amazon-cognito-identity-js';
import { Map } from 'immutable';
import { Dispatch, ThunkAction } from '../../../../types';
import { AuthActionType } from '../../../actions/auth_types';
import { cognitoPoolData } from '../../../../frontend-common-libs/src/utils/authUtils';
import { loginUser, LoginInfo } from '../../../actions/auth_actions';
import InvalidUserOrPasswordError from './InvalidUserOrPasswordError';
import UserNameExistsError from './UserNameExistsError';

export type RegisterInfo = {
  name: string;
} & LoginInfo;

export default function registerUser(
  registerInfo: RegisterInfo
): ThunkAction<AuthActionType, string | Map<unknown, unknown>> {
  return (dispatch: Dispatch<AuthActionType, string | Map<unknown, unknown>>) => {
    return new Promise<void>((resolve, reject) => {
      const lowerEmail = registerInfo.email.toLowerCase();
      const attributeEmail = new CognitoUserAttribute({
        Name: 'email',
        Value: lowerEmail
      });

      const attributeName = new CognitoUserAttribute({
        Name: 'name',
        Value: registerInfo.name
      });

      const attributeList = [attributeEmail, attributeName];

      const userPool = new CognitoUserPool(cognitoPoolData);
      // @ts-ignore
      userPool.signUp(lowerEmail, registerInfo.password, attributeList, null, err => {
        if (err) {
          // @ts-ignore
          if (err.code === 'InvalidPasswordException' || err.code === 'InvalidParameterException') {
            reject(new InvalidUserOrPasswordError());
          }

          if (
            // @ts-ignore
            err.code === 'UsernameExistsException' ||
            // @ts-ignore
            (err.code === 'UserLambdaValidationException' &&
              err.message.includes('User already exists'))
          ) {
            reject(new UserNameExistsError());
          }

          reject(new Error('Unknown Error while registering'));
        } else {
          dispatch(loginUser(registerInfo, true));
          resolve();
        }
      });
    });
  };
}
