import { Map } from 'immutable';

const commonErrors = Map<number, (title: string) => string>()
  .set(404, (entity: string) => `${entity} does not exist.`)
  .set(409, (entityType: string) => `Archived ${entityType} cannot be updated.`);

export function commonErrorMessage(code: number, title: string): string {
  const createErrorMessage = commonErrors.get(code);
  if (createErrorMessage) {
    return createErrorMessage(title);
  }
  return '';
}

export function getErrorResponse(error: any) {
  return error?.response;
}

export function getStatus(error: any) {
  return getErrorResponse(error)?.status;
}

export function getData(error: any) {
  return getErrorResponse(error)?.data;
}
export function errorCode(error: any) {
  return getStatus(error);
}

export function isVersionUpdateConflict(error: any): boolean {
  return !!(getStatus(error) === 409 && getData(error)?.includes('Newer version already exists'));
}

export function isNetworkError(error?: Error): boolean {
  return error != null && error.message === 'Network Error';
}
