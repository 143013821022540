// @ts-nocheck
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Set, Map } from 'immutable';
import ResultsChart from './ResultsChart';
import MeltChartOptions from './charts/MeltChartOptions';
import { setAnalysisMode, setMeltMode } from '../../../actions/currentCfxRun_actions';
import { AnalysisResultsStep } from '../../../selectors/current-cfx-run-types';
import { MeltModeEnum } from '../../../selectors/MeltData';
import { getMeltMode } from '../../../selectors/selectors';

export type Props = {
  step: AnalysisResultsStep;
  targetsOrFluorsToShow: Set;
  wellsToShow: Set;
  analysisMode: string;
  changeAnalysisMode: typeof setAnalysisMode;
  resultsData: any;
  meltMode: string;
  changeMeltMode: typeof setMeltMode;
};

export class MeltChartImpl extends PureComponent<Props> {
  updateAnalysisMode = (e: React.MouseEvent<HTMLButtonElement>) => {
    const { changeAnalysisMode } = this.props;
    changeAnalysisMode(e.currentTarget.value);
  };

  updateMeltMode = (e: React.MouseEvent<HTMLButtonElement>) => {
    const { changeMeltMode } = this.props;
    changeMeltMode(e.currentTarget.value);
  };

  render() {
    const {
      targetsOrFluorsToShow,
      wellsToShow,
      step: { mode },
      analysisMode,
      resultsData,
      meltMode
    } = this.props;

    const chartData = resultsData.getChartData();

    return (
      <div className="pcr-chart-container">
        <ResultsChart
          fileName="melt"
          chartTitle={meltMode}
          yAxisLabel={meltMode === MeltModeEnum.MeltCurve ? 'RFU' : '-d(RFU)/dT'}
          xAxisLabel="Temperature"
          resultsData={resultsData}
          targetsOrFluorsToShow={targetsOrFluorsToShow}
          wellsToShow={wellsToShow}
          groupMode={mode}
        />
        {chartData.stepData.keySeq().size > 0 ? (
          <MeltChartOptions
            analysisMode={analysisMode}
            onUpdateAnalysisMode={this.updateAnalysisMode}
            meltMode={meltMode}
            onUpdateMeltMode={this.updateMeltMode}
          />
        ) : null}
      </div>
    );
  }
}

function mapStateToProps(state: Map) {
  const meltMode = getMeltMode(state);
  return {
    meltMode
  };
}

export default connect(mapStateToProps, {
  changeMeltMode: setMeltMode
})(MeltChartImpl);
