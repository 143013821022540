import React, { Component, ComponentType, ReactNode, ComponentProps } from 'react';

type State = {
  isLoading: boolean;
  PluginComponent?: ComponentType<ReactNode>;
};

export default function withComponentLoader(
  load: Promise<ComponentType<any>>,
  Loader: ComponentType<any>
): ComponentType<any> {
  return class PluginComponentLoader extends Component<ComponentProps<any>, State> {
    constructor(props: ComponentProps<any>) {
      super(props);

      this.state = {
        isLoading: true
      };
    }

    async componentDidMount() {
      try {
        const PluginComponent = await load;
        this.setState({
          PluginComponent,
          isLoading: false
        });
      } catch (error: unknown) {
        this.setState({
          isLoading: false
        });
      }
    }

    render() {
      const { isLoading, PluginComponent } = this.state;

      if (!isLoading && !PluginComponent) {
        return <p>No component error</p>;
      }

      return PluginComponent ? <PluginComponent {...this.props} /> : <Loader />;
    }
  };
}
