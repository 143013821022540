import React from 'react';
import './styles/header.scss';

export type Props = {
  text: string;
  id: string;
};

export default function Header(props: Props) {
  const { id, text } = props;
  return (
    <div id={id} className="buttonmenu-item buttonmenu-header">
      <span>{text}</span>
    </div>
  );
}
