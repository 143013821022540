import React, { useState } from 'react';
import './styles/members-table.scss';
import RoleSelect from './RoleSelect';
import DeleteIcon from '../../frontend-common-libs/src/img/icons-options-menu-delete.svg';
import ModalDialog from '../../frontend-common-libs/src/components/common/dialogs/ModalDialog';
import { okCancelStyleV2 } from '../../frontend-common-libs/src/components/common/dialogs/custom-modal-styles';

export type TenantMemberRowProps<RoleEnum> = {
  name: string;
  role: RoleEnum;
  index: number;
  onChangeRole: (userName: string, role: string) => any;
  disabled?: boolean;
  showRemoveUser?: boolean;
  onRemoveUser?: (userName: string) => Promise<any>;
  removeUserMessage?: string;
};

export type Props<RoleEnum> = {
  roleEnum: object;
  displayNames?: Record<string, string>;
} & TenantMemberRowProps<RoleEnum>;

export default function TenantMemberRow<RoleEnum>(props: Readonly<Props<RoleEnum>>) {
  const [changingRole, setChangingRole] = useState(false);
  const [removingUser, setRemovingUser] = useState(false);
  const [removingUserInProgress, setRemovingUserInProgress] = useState(false);

  const {
    name,
    role,
    roleEnum,
    index,
    onChangeRole,
    disabled,
    showRemoveUser,
    onRemoveUser,
    displayNames
  } = props;

  const onSelection = async (selectedRole: string) => {
    if (selectedRole === role) return;
    setChangingRole(true);
    await onChangeRole(name, selectedRole);
    setChangingRole(false);
  };
  const roleDropdown = () => {
    const selectDisabled = changingRole || disabled;

    return (
      <RoleSelect
        roleEnum={roleEnum}
        selectedValue={role as string}
        onRoleSelection={onSelection}
        idPostfix={`${index}`}
        disabled={selectDisabled}
        displayNames={displayNames}
      />
    );
  };

  const renderRemoveUser = () => {
    if (!showRemoveUser) return null;
    return (
      <button
        id={`remove_user_${index}`}
        className="remove-user hover-image-button"
        title="Remove"
        onClick={() => {
          setRemovingUser(true);
        }}
      >
        <img src={DeleteIcon} alt="Remove" />
      </button>
    );
  };

  const renderRemoveUserPopup = () => {
    const { removeUserMessage } = props;
    const message = removeUserMessage ?? `Are you sure you want to remove ${name}?`;
    return (
      <ModalDialog
        show={removingUser}
        title="Remove User"
        message={message}
        onSuccess={async () => {
          if (onRemoveUser) {
            setRemovingUserInProgress(true);
            await onRemoveUser(name);
            setRemovingUserInProgress(false);
          }
          setRemovingUser(false);
        }}
        onCancel={() => {
          setRemovingUser(false);
        }}
        okBtnText="Remove"
        customModalStyle={okCancelStyleV2}
        inProgress={removingUserInProgress}
      />
    );
  };

  return (
    <div className="member-row">
      <span className="with-ellipsis" id={`user-name-${index}`} title={name}>
        {name}
      </span>
      {roleDropdown()}
      {renderRemoveUser()}
      {renderRemoveUserPopup()}
    </div>
  );
}
