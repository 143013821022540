import React, { HTMLProps, ReactElement } from 'react';
import classnames from 'classnames';
import styles from '../styles/aria-auto-suggest-input.modules.scss';

export interface AutoSuggestInputProps {
  inputProps: HTMLProps<HTMLInputElement>;
}

export default function AutoSuggestInput({ inputProps }: AutoSuggestInputProps): ReactElement {
  const AutoSuggestInputStyles = classnames(`${styles['auto-suggest-input']}`);
  return <input className={AutoSuggestInputStyles} {...inputProps} />;
}
