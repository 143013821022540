import { ArtifactId, ArtifactURL, PluginName } from '@biorad-lsg-tsc/instrument-family-plugin';
import { ElementCreatorMap } from './types';

export default class ArtifactsLoader {
  constructor(pluginName: PluginName, artifactURL: ArtifactURL[]) {
    this.pluginName = pluginName;
    this.artifactURLs = artifactURL;
    this.artifactIdPrefix = `plugin-artifact-${this.pluginName}`;
  }

  private readonly pluginName: PluginName;

  private readonly artifactURLs: ArtifactURL[];

  private readonly artifactIdPrefix: string;

  private allArtifactsLoadedPromise: Promise<any[]> | undefined;

  private createScriptTag = (url: ArtifactURL, artifactId: ArtifactId): Promise<void> => {
    return new Promise(resolve => {
      const script = document.createElement('script');
      script.id = artifactId;
      script.src = url;
      script.onload = ArtifactsLoader.registerArtifactAsLoaded(resolve);
      document.head.appendChild(script);
    });
  };

  private createStylesheetLinkTag = (url: ArtifactURL, artifactId: ArtifactId): Promise<void> => {
    return new Promise(resolve => {
      const link = document.createElement('link');
      link.id = artifactId;
      link.href = url;
      link.rel = 'stylesheet';
      link.onload = ArtifactsLoader.registerArtifactAsLoaded(resolve);
      document.head.appendChild(link);
    });
  };

  private elementCreatorMap: ElementCreatorMap = {
    js: this.createScriptTag,
    css: this.createStylesheetLinkTag
  };

  private static registerArtifactAsLoaded(doneLoading: () => void) {
    return () => {
      doneLoading();
    };
  }

  private static getFileExtension(url: ArtifactURL): string | undefined {
    const { pathname } = new URL(url.toLowerCase());
    const artifactFileName = pathname.substring(pathname.lastIndexOf('/') + 1) || '';
    const fileNameParts = artifactFileName.split('.');

    if (fileNameParts.length <= 1) {
      return undefined;
    }

    return fileNameParts.pop();
  }

  public load(): Promise<any[]> {
    if (!this.allArtifactsLoadedPromise) {
      const loadArtifactsPromises: Promise<void>[] = [];

      this.artifactURLs.forEach((url, index) => {
        const fileNameExtension = ArtifactsLoader.getFileExtension(url);

        if (fileNameExtension) {
          const elementCreator = this.elementCreatorMap[fileNameExtension];

          if (elementCreator) {
            const artifactId = `${this.artifactIdPrefix}-${index}`;
            const elementCreatorPromise = elementCreator(url, artifactId);
            loadArtifactsPromises.push(elementCreatorPromise);
          }
        }
      });
      this.allArtifactsLoadedPromise = Promise.all(loadArtifactsPromises as Promise<any>[]);
    }

    return this.allArtifactsLoadedPromise;
  }
}
