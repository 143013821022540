import { getCpcrProtocol } from '../api/protocols';
import BaseViewModel from '../../../frontend-common-libs/src/common/mvvm/BaseViewModel';
import { UserFile } from '../../../frontend-common-libs/src/common/types';
import { PcrProtocolContent } from '../../../frontend-common-libs/src/components/pcr/pcr_protocol_types';
import ArchivedEntityException from '../../../frontend-common-libs/src/components/pcr/pcr-protocols/models/ArchivedEntityException';

export type ProtocolFile = {
  file: UserFile;
  protocol: PcrProtocolContent;
};

export default class ProtocolRepository extends BaseViewModel {
  private loadedProtocols: Record<string, ProtocolFile> = {};

  private constructor() {
    super();
    // private constructor for singleton class
  }

  private static _instance: ProtocolRepository | undefined = undefined;

  public static get instance(): ProtocolRepository {
    if (this._instance == null) this._instance = new ProtocolRepository();
    return this._instance;
  }

  private setLoadedProtocol(entityId: string, protocolFile: ProtocolFile) {
    this.loadedProtocols[entityId] = protocolFile;
    this.fireStateChanged();
  }

  private getLoadedProtocol(entityId: string) {
    return this.loadedProtocols[entityId];
  }

  public isLoaded(entityId: string): boolean {
    return this.getLoadedProtocol(entityId) != null;
  }

  public async getProtocol(entityId: string): Promise<ProtocolFile> {
    if (this.isLoaded(entityId)) return this.getLoadedProtocol(entityId);
    const protocol = await getCpcrProtocol(entityId);
    if (protocol.file.status === 'A') {
      throw new ArchivedEntityException('This protocol is already archived.');
    }
    this.setLoadedProtocol(entityId, protocol);
    return protocol;
  }

  public protocolSaved(protocolFile: ProtocolFile) {
    const entityId = protocolFile.file.id;
    this.setLoadedProtocol(entityId, protocolFile);
  }

  public protocolUpdated(file: UserFile) {
    const entityId = file.id;
    if (!this.isLoaded(entityId)) return;
    const currProtocol = this.getLoadedProtocol(entityId);
    if (file.versionNumber > currProtocol.file.versionNumber) {
      delete this.loadedProtocols[entityId];
      this.fireStateChanged();
    }
  }

  public hasNewVersion(entityId: string): boolean {
    return !this.isLoaded(entityId);
  }

  public clear() {
    this.loadedProtocols = {};
    this.stateChanged.clear();
  }
}
