import ReservationShadow from './iot/ReservationShadow';
import { TopicStub } from '../../frontend-common-libs/src/iot/InstrumentNamedShadow';

export type ReservationSectionsDesiredBlock = {
  userId?: string;
  reserved?: boolean;
  jobId?: string | null;
  metadata: {
    userId?: number;
    reserved?: number;
    jobId?: number;
    job?: number;
  };
};

export type ReservationSectionsReportedBlock = {
  userId: string | null;
  reserved: boolean;
  loggedIn: boolean | null;
  jobId: string | null;
};

export type ReservationShadowState = {
  desired?: ReservationSectionsDesiredBlock;
} & ReservationSectionsReportedBlock;

export default class Reservation {
  private reservationShadow: ReservationShadow;

  public constructor() {
    this.reservationShadow = new ReservationShadow();
  }

  public static async getCurrentReservation(instrumentId: string): Promise<ReservationShadowState> {
    const reservationShadow = await new ReservationShadow().get(instrumentId);
    const desiredReservation = Reservation.desiredReservationSections(reservationShadow);
    const reportedReservation = Reservation.reportedReservationSections(reservationShadow);
    return {
      ...reportedReservation,
      desired: desiredReservation
    };
  }

  private static desiredReservationSections(
    reservationShadow: unknown
  ): ReservationSectionsDesiredBlock | undefined {
    const {
      state: { desired },
      metadata
    } = JSON.parse(reservationShadow as string);

    return (
      desired && {
        userId: desired.reservation.userID,
        reserved: desired.reservation.reserved,
        jobId: desired.reservation.job?.jobId,
        metadata: {
          userId: metadata.desired.reservation.userID.timestamp,
          reserved: metadata.desired.reservation.reserved.timestamp,
          jobId: metadata.desired.reservation.job?.jobId?.timestamp
        }
      }
    );
  }

  private static reportedReservationSections(
    reservationShadow: unknown
  ): ReservationSectionsReportedBlock {
    const {
      state: { reported }
    } = JSON.parse(reservationShadow as string);
    return {
      userId: reported.reservation?.userID ?? '',
      reserved: reported.reservation?.reserved ?? false,
      loggedIn: reported.instrument?.loggedIn ?? null,
      jobId: reported.reservation?.job?.jobId ?? null
    };
  }

  public async connect(
    instrumentId: string,
    callback: (topic: string, payload: unknown) => void
  ): Promise<void> {
    await this.reservationShadow.subscribeToUpdateTopic(instrumentId, callback);
  }

  public async disconnect(instrumentId: string, topicStub: TopicStub): Promise<void> {
    await this.reservationShadow.unsubscribeFromTopic(instrumentId, topicStub);
    await this.reservationShadow.closeWebsocketConnection(instrumentId);
  }

  public async create(
    instrumentId: string,
    userId: string,
    job?: { jobId: string }
  ): Promise<void> {
    await this.reservationShadow.updateReservation(instrumentId, true, userId, job ?? null);
  }

  public async unreserve(instrumentId: string): Promise<void> {
    await this.reservationShadow.updateReservation(instrumentId, false, '', null);
  }

  public async addJob(instrumentId: string, jobId: string): Promise<void> {
    await this.reservationShadow.updateJob(instrumentId, jobId);
  }

  public async removeJob(instrumentId: string): Promise<void> {
    await this.reservationShadow.updateJob(instrumentId, null);
  }

  public async startReservationRun(instrumentId: string): Promise<void> {
    await this.reservationShadow.updateAction(instrumentId, 'startRun');
  }

  public async openLid(instrumentId: string): Promise<void> {
    await this.reservationShadow.updateAction(instrumentId, 'openLid');
  }

  public async closeLid(instrumentId: string): Promise<void> {
    await this.reservationShadow.updateAction(instrumentId, 'closeLid');
  }

  public async stopReservationRun(instrumentId: string): Promise<void> {
    await this.reservationShadow.updateAction(instrumentId, 'stopRun');
  }

  public async skipStep(instrumentId: string): Promise<void> {
    await this.reservationShadow.updateAction(instrumentId, 'skipStep');
  }
}
