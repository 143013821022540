import React from 'react';
import CheckmarkGreen from '../../../../../img/checkmark-green.svg';
import DetailsHeaderValue from '../../../../frontend-common-libs/src/components/common/run/DetailsHeaderValue';
import { RunErrorState } from '../report/run-report/sections-parsing/InfoParser';

export type Props = {
  runStatus: string[];
  runErrorState: string;
};

function hasNoErrors(errorState: string) {
  return errorState === RunErrorState.NO_ERRORS || errorState === RunErrorState.RUN_STOPPED_BY_USER;
}

function getStatusColor(props: Props) {
  const { runErrorState } = props;
  if (hasNoErrors(runErrorState)) return undefined;
  return { color: '#D12C2B' };
}

export function getRunStatus(props: Props) {
  const { runErrorState } = props;
  const iconId = 'status-icon';
  if (hasNoErrors(runErrorState))
    return (
      <img
        src={CheckmarkGreen}
        alt="Green checkmark"
        className="pwd-fb-icon-good run-status-success"
        id={iconId}
      />
    );
  return <i id={iconId} className="fa fa-exclamation-triangle text-danger run-status-failure" />;
}

export function RunStatus(props: Props) {
  const { runStatus } = props;
  return (
    <DetailsHeaderValue
      header="Status"
      value={runStatus}
      valueIcon={getRunStatus(props)}
      valueStyle={getStatusColor(props)}
    />
  );
}
