import { createSelector } from 'reselect';
import { Set, fromJS, List, OrderedMap, Map } from 'immutable';
import {
  targetMode,
  fluorMode,
  getWellTypeReplicateMaxes
} from '../components/pcr/analysis/pcrDataUtils';
import { maybeHash } from '../frontend-common-libs/src/utils/immutableUtils';
import { intlCollator } from '../frontend-common-libs/src/utils/commonUtils';
import { AnalysisResultsStep } from './current-cfx-run-types';
import {
  FILETYPE_CFX_RUN,
  FILETYPE_IN_PROGRESS_CFX_RUN,
  FILETYPE_PENDING_CFX_RUN
} from '../frontend-common-libs/src/common/userfiles_common';
import CqData from './CqData';
import MeltData, { MeltModeEnum } from './MeltData';
import { padNumberWithZero } from '../utils/microplateUtils';
import { AnalysisModeEnum } from '../types';
import { ImmutableMap } from '../frontend-common-libs/src/common/types';

export type CqDataColumnSortInfo = ImmutableMap<{
  isAscending: boolean;
  key: string;
}>;
export type CqDataSortOrder = OrderedMap<string, CqDataColumnSortInfo>;

export const getEntityId = (state: Map<string, any>): string | null | undefined => state.get('id');

export const getAmpChartScale = (state: Map<string, any>): string =>
  state.getIn(['run', 'settings', 'view', 'ampScale']) as string;

export const getAnalysisMode = (state: Map<string, any>): AnalysisModeEnum =>
  state.getIn(['run', 'settings', 'analysis', 'groupMode']) as AnalysisModeEnum;

export const getMeltMode = (state: Map<string, any>): string =>
  (state.getIn(['run', 'viewSettings', 'meltMode']) as string) || MeltModeEnum.MeltPeak;

export const getSteps = (state: Map<string, any>): List<number> =>
  (state.getIn(['run', 'steps']) as List<number>) || List();

export const getSelectedStepNumber = (state: Map<string, any>): number => state.get('selectedStep');

export const getSelectedStepNumberStr = (state: Map<string, any>): string | null | undefined =>
  getSelectedStepNumber(state) !== undefined ? getSelectedStepNumber(state).toString() : undefined;

export const getSelectedStepData = (state: Map<string, any>): Map<string, any> =>
  (state.getIn(['run', 'stepData', getSelectedStepNumberStr(state)]) as Map<string, any>) ||
  fromJS({ data: {} });

export const getSelectedStepProtocol = (state: Map<string, any>): Map<string, any> =>
  state.getIn(['run', 'protocol', 'steps', getSelectedStepNumberStr(state)]) as Map<string, any>;

export const getPlateWells = (state: Map<string, any>): Map<string, any> =>
  state.getIn(['run', 'plate', 'wells']) as Map<string, any>;

export const getSubtractBaseline = (state: Map<string, any>): boolean =>
  state.getIn(['run', 'settings', 'view', 'baselineSubtraction']) as boolean;

export const getDriftCorrection = (state: Map<string, any>): boolean =>
  (state.getIn(['run', 'settings', 'analysis', 'driftCorrection']) as boolean) || false;

export const getWellsToHide = (state: Map<string, any>): Set<string> =>
  state.getIn(['run', 'settings', 'view', 'wellsToHide']) as Set<string>;

export const getExcludedWells = (state: Map<string, any>): Set<string> =>
  state.getIn(['run', 'settings', 'analysis', 'excludeWells']) as Set<string>;

export const getSelectedWells = createSelector(
  [getPlateWells, getWellsToHide, getExcludedWells],
  (wells, wellsToHide, excludedWells): Set<string> =>
    Set.fromKeys(wells).subtract(wellsToHide).subtract(excludedWells)
);

export const getPlate = (state: Map<string, any>): Map<string, any> =>
  state.getIn(['run', 'plate']) as Map<string, any>;

export const getRunName = (state: Map<string, any>): string =>
  state.getIn(['run', 'name']) as string;

export const getRunNotes = (state: Map<string, any>): string =>
  state.getIn(['run', 'runInfo', 'notes']) as string;

export const getCompletedRunInfo = (state: Map<string, any>): string =>
  state.getIn(['run', 'runInfo']) as string;

export const getRunLog = (state: Map<string, any>): Map<string, any> =>
  state.getIn(['run', 'log']) as Map<string, any>;

export const getOriginalNotes = (state: Map<string, any>): string =>
  state.getIn(['original', 'notes']) as string;

export const getRunBarcode = (state: Map<string, any>): string =>
  state.getIn(['run', 'runInfo', 'barcode']) as string;

export const getPlateChannelMap = (state: Map<string, any>): Map<string, any> =>
  state.getIn(['run', 'plate', 'channelMap']) as Map<string, any>;

export const getTargetsInRun = createSelector(
  getSelectedStepData,
  getAnalysisMode,
  (stepData: Map<string, any>): Set<string> => {
    const thresholdData = stepData.getIn([
      targetMode,
      'computations',
      'perPlate',
      'thresholds'
    ]) as Map<string, any>;
    return Set.fromKeys(thresholdData).sort(intlCollator.compare);
  }
);

export const getFluorsInRun = createSelector(
  getSelectedStepData,
  getAnalysisMode,
  (stepData: Map<string, any>): Set<string> => {
    const thresholdData = stepData.getIn([
      fluorMode,
      'computations',
      'perPlate',
      'thresholds'
    ]) as Map<string, any>;
    return Set.fromKeys(thresholdData).sort(intlCollator.compare);
  }
);

export const getTargetsOrFluorsInRun = (state: Map<string, any>): Set<string> =>
  getAnalysisMode(state) === targetMode ? getTargetsInRun(state) : getFluorsInRun(state);

const getTargetsToHide = (state: Map<string, any>): List<string> =>
  state.getIn(['run', 'settings', 'view', 'targetsToHide']) as List<string>;

export const getTargetsToShow = createSelector(
  [getTargetsInRun, getTargetsToHide],
  (targetsInRun: Set<string>, targetsToHide: List<string>): Set<string> =>
    targetsInRun.subtract(targetsToHide)
);

const getFluorsToHide = (state: Map<string, any>): List<string> =>
  state.getIn(['run', 'settings', 'view', 'fluorsToHide']) as List<string>;

export const getFluorsToShow = createSelector(
  [getFluorsInRun, getFluorsToHide],
  (fluorsInRun: Set<string>, fluorsToHide: List<string>): Set<string> =>
    fluorsInRun.subtract(fluorsToHide)
);

export const getTargetsOrFluorsToShow = (state: Map<string, any>): Set<string> =>
  getAnalysisMode(state) === targetMode ? getTargetsToShow(state) : getFluorsToShow(state);

export const getResultsStep = createSelector(
  [getAnalysisMode, getSelectedStepData, getPlateWells, getExcludedWells],
  (
    analysisMode: string,
    selectedStep: Map<string, any>,
    plateWells: Map<string, any>,
    excludedWells: Set<string>
  ): AnalysisResultsStep | null | undefined => {
    const stepGroupData = selectedStep.get(analysisMode);
    if (!stepGroupData) return undefined;

    return {
      stepGroupData,
      plateWells,
      excludedWells,
      mode: analysisMode,
      cycles: stepGroupData.get('cycles')
    };
  }
);

export const getAnalysisSettings = (state: Map<string, any>): Map<string, any> =>
  (state.getIn(['run', 'settings', 'analysis']) as Map<string, any>) || Map<string, any>();

export const getNumberOfCyclesInStep = createSelector(
  [getSelectedStepData, getAnalysisMode],
  (stepData: Map<string, any>, analysisMode: string): number | undefined => {
    return stepData.getIn([analysisMode, 'cycles']) as number | undefined;
  }
);

export const getComputationsPerWell = createSelector(
  [getSelectedStepData, getAnalysisMode],
  (stepData: Map<string, any>, analysisMode: string): Map<string, any> =>
    (stepData.getIn([analysisMode, 'computations', 'perWell']) as Map<string, any>) ||
    Map<string, any>()
);

const hasGroupModeSteData = createSelector(
  [getSelectedStepData, getAnalysisMode],
  (stepData, groupMode) => stepData.get(groupMode)
);

export const getPerStepAnalysisSettingsForStepGroupMode = createSelector(
  [getSelectedStepNumberStr, getAnalysisMode, getAnalysisSettings],
  (selectedStep, groupMode, analysisSettings): Map<string, any> =>
    (analysisSettings.getIn([
      'perStep',
      selectedStep,
      groupMode === 'target' ? 'targets' : 'fluors'
    ]) as Map<string, any>) || Map<string, any>()
);

const getCurrentPerStepAnalysisSettingsHashes = (state: { [key: string]: any }): Map<string, any> =>
  state.getIn(['current', 'perStepAnalysisSettingsHash']) || Map<string, any>();

const getCurrentPerStepAnalysisSettingsHashForStepGroupMode = createSelector(
  [getSelectedStepNumberStr, getAnalysisMode, getCurrentPerStepAnalysisSettingsHashes],
  (selectedStep, groupMode, analysisSettingsHashes) =>
    analysisSettingsHashes.getIn([selectedStep, groupMode])
);

const analysisSettingsHaveChanged = createSelector(
  [
    getCurrentPerStepAnalysisSettingsHashForStepGroupMode,
    getPerStepAnalysisSettingsForStepGroupMode
  ],
  (currentAnalysisHash, analysisSettings) => currentAnalysisHash !== maybeHash(analysisSettings)
);

const getCurrentPlateHash = (state: Map<string, any>): number =>
  state.getIn(['current', 'plateHash']) as number;

const plateHasChanged = createSelector(
  [getPlate, getCurrentPlateHash],
  (plate: Map<string, any>, currentPlateHash: number) => {
    const newPlateHash = maybeHash(plate);
    return currentPlateHash !== newPlateHash;
  }
);

export const shouldUpdateStep = createSelector(
  [hasGroupModeSteData, plateHasChanged, analysisSettingsHaveChanged],
  (hasModeStepData, plateChanged, analysisSettingsChanged) => {
    if (!hasModeStepData) return true;

    if (plateChanged) return true;

    return analysisSettingsChanged;
  }
);

export const stepCalculationErrored = (state: Map<string, any>) => {
  const selectedStepStr = getSelectedStepNumberStr(state);
  const groupMode = getAnalysisMode(state);
  const plate = state.getIn(['run', 'plate']) as Map<string, any>;
  const newPlateHash = maybeHash(plate);
  const newAnalysisSettings = getPerStepAnalysisSettingsForStepGroupMode(state);
  return (
    !!state.getIn(['loading', selectedStepStr, groupMode, 'error']) &&
    state.getIn(['loading', selectedStepStr, groupMode, 'plateHash']) === newPlateHash &&
    state.getIn(['loading', selectedStepStr, groupMode, 'analysisSettingsHash']) ===
      maybeHash(newAnalysisSettings)
  );
};

export const getRunNameError = (state: Map<string, any>) => state.getIn(['errors', 'runNameError']);

export const getRunNotesError = (state: Map<string, any>) =>
  state.getIn(['errors', 'runNotesError']);

export const getRunBarcodeError = (state: Map<string, any>) =>
  state.getIn(['errors', 'runBarcodeError']) || '';

export const getRunPlateErrors = (state: Map<string, any>) =>
  state.getIn(['errors', 'plateErrors']);

export const runHasLocalEdits = (state: Map<string, any>) => {
  const currentNotes = getRunNotes(state) || '';
  const originalNotes = state.getIn(['original', 'notes']) || '';
  const currentBarcode = getRunBarcode(state) || '';
  const originalBarcode = state.getIn(['original', 'barcode']) || '';
  return (
    getRunName(state) !== state.getIn(['original', 'name']) ||
    currentNotes !== originalNotes ||
    currentBarcode !== originalBarcode ||
    maybeHash(state.getIn(['run', 'protocol']) as Map<string, any>) !==
      state.getIn(['original', 'protocolHash']) ||
    maybeHash(state.getIn(['run', 'plate']) as Map<string, any>) !==
      state.getIn(['original', 'plateHash']) ||
    maybeHash(state.getIn(['run', 'settings']) as Map<string, any>) !==
      state.getIn(['original', 'settingsHash'])
  );
};

export const isPlateValid = (state: Map<string, any>) =>
  !state.getIn(['errors', 'plateErrors', 'plateEmpty']) &&
  !state.getIn(['errors', 'plateErrors', 'replicateGroupError']) &&
  !state.getIn(['errors', 'plateErrors', 'replicate']) &&
  !state.getIn(['errors', 'plateErrors', 'noFluorsError']) &&
  !state.getIn(['errors', 'plateErrors', 'biogroup']) &&
  !state.getIn(['errors', 'plateErrors', 'sample']) &&
  !state.getIn(['errors', 'plateErrors', 'target']) &&
  !state.getIn(['errors', 'plateErrors', 'concentration']);

export const isRunDetailsValid = (state: Map<string, any>) =>
  !state.getIn(['errors', 'runNameError']) &&
  !state.getIn(['errors', 'runNotesError']) &&
  !state.getIn(['errors', 'runBarcodeError']);

export const isProtocolValid = (state: Map<string, any>) =>
  !state.get('protocolIsUpdating') && !!state.getIn(['run', 'protocol']);

export const isCompletedRun = (state: Map<string, any>) =>
  state.getIn(['run', 'type']) === FILETYPE_CFX_RUN;

export const isInProgressRun = (state: Map<string, any>) =>
  state.getIn(['run', 'type']) === FILETYPE_IN_PROGRESS_CFX_RUN;

export const isPendingRun = (state: Map<string, any>): boolean =>
  state.getIn(['run', 'type']) === FILETYPE_PENDING_CFX_RUN;

export const isCreatePendingRun = (state: Map<string, any>): boolean =>
  isPendingRun(state) && getEntityId(state) === undefined;

export const isSaveInProgress = (state: Map<string, any>) => state.get('isSaveInProgress');

export const getTargets = (state: Map<string, any>) => state.get('targets');

export const isRunValid = (state: Map<string, any>) =>
  isPlateValid(state) && isRunDetailsValid(state) && isProtocolValid(state);

export const hasStepGroupData = (state: Map<string, any>): boolean => {
  const step = getSelectedStepNumberStr(state);
  const groupMode = getAnalysisMode(state);
  return !!state.getIn(['run', 'stepData', step, groupMode]);
};

export const hasDataSteps = (state: Map<string, any>): boolean => {
  const stepData = state.getIn(['run', 'stepData']) as Map<string, any>;
  return !!stepData && stepData.size > 0;
};

export const hasValidLocalEdits = (state: Map<string, any>) =>
  runHasLocalEdits(state) && isRunValid(state);

export const getCqDataSortOrder = (state: OrderedMap<string, any>): CqDataSortOrder =>
  state.get('cqDataSortOrder') || OrderedMap();

export const getShowPlateSetup = (state: Map<string, any>): boolean => state.get('showPlateSetup');

export const getShouldRedirect = (state: Map<string, any>): boolean => state.get('shouldRedirect');

export const getRun = (state: Map<string, any>): boolean => state.get('run');

export const selectedStepLoading = (state: Map<string, any>) => {
  const selectedStepStr = getSelectedStepNumberStr(state);
  const groupMode = getAnalysisMode(state);
  return !!state.getIn(['loading', selectedStepStr, groupMode]);
};

export const selectedStepHasErrors = (state: Map<string, any>) => {
  const selectedStepStr = getSelectedStepNumberStr(state);
  const groupMode = getAnalysisMode(state);
  return !!state.getIn(['loading', selectedStepStr, groupMode, 'error']);
};

export const getPerStepAnalysisSettings = (state: Map<string, any>): Map<string, any> =>
  state.getIn(['run', 'settings', 'analysis', 'perStep']) as Map<string, any>;

const thresholdSettings = createSelector(
  [getPerStepAnalysisSettings, getSelectedStepNumberStr, getAnalysisMode],
  (
    perStepData: Map<string, any>,
    selectedStep: string | null | undefined,
    analysisMode: AnalysisModeEnum
  ): Map<string, any> => {
    const thresholdSettingsValue = perStepData.getIn([
      selectedStep,
      analysisMode === 'target' ? 'targets' : 'fluors'
    ]) as Map<string, any>;
    return thresholdSettingsValue || Map<string, any>();
  }
);

export const defaultThresholds = createSelector(
  [getSelectedStepData, getAnalysisMode],
  (stepData: Map<string, any>, analysisMode: string): Map<string, any> =>
    (stepData.getIn([analysisMode, 'computations', 'perPlate', 'thresholds']) as Map<
      string,
      any
    >) || Map<string, any>()
);

export const customThresholds = createSelector(
  [thresholdSettings],
  (settings: Map<string, any>): Map<string, any> =>
    settings.reduce(
      (accum: Map<string, any>, setting: Map<string, any>, fluorOrTarget: string) =>
        accum.set(fluorOrTarget, setting.getIn(['threshold', 'value'])),
      Map<string, any>()
    )
);

export const autoThresholdSettings = createSelector(
  [thresholdSettings],
  (settings: Map<string, any>): Map<string, any> =>
    settings.reduce(
      (accum: Map<string, any>, setting: Map<string, any>, fluorOrTarget: string) =>
        accum.set(fluorOrTarget, setting.getIn(['threshold', 'auto'])),
      Map<string, any>()
    )
);

export const getCurrentThresholds = createSelector(
  [defaultThresholds, customThresholds, autoThresholdSettings],
  (
    defaults: Map<string, any>,
    custom: Map<string, any>,
    autoSetting: Map<string, any>
  ): Map<string, any> =>
    defaults.reduce(
      (thresholds: Map<string, any>, threshold: Map<string, any>, fluorOrTarget: string) => {
        if (autoSetting.get(fluorOrTarget) === false)
          return thresholds.set(fluorOrTarget, custom.get(fluorOrTarget));

        return thresholds.set(fluorOrTarget, threshold);
      },
      Map<string, any>()
    )
);

export const hasCustomThreshold = createSelector(
  [autoThresholdSettings],
  (settings: Map<string, any>): boolean => {
    const customSettings = settings.filter((auto: boolean) => auto === false);
    return customSettings.size > 0;
  }
);

export const getMeltSteps = (state: Map<string, any>): List<number> =>
  (state.getIn(['run', 'meltSteps']) as List<number>) || List();

export const ampStepSelected = createSelector(
  [getSelectedStepNumber, getSteps],
  (selectedStep: number | null | undefined, steps: List<number>): boolean =>
    typeof selectedStep === 'number' && steps.includes(selectedStep)
);

export const meltStepSelected = createSelector(
  [getSelectedStepNumber, getMeltSteps],
  (selectedStep: number | null | undefined, meltSteps: List<number>): boolean =>
    typeof selectedStep === 'number' && meltSteps.includes(selectedStep)
);

export type ReplicateFormatterFunction = (
  wellType: string,
  replicate: number | null | undefined
) => string | null | undefined;

export const getWellReplicateNumberFormatter = createSelector(
  [getPlateWells],
  (wells: any): ReplicateFormatterFunction => {
    const replicateTypeToMaxLengthMap = getWellTypeReplicateMaxes(wells);
    return (wellType: string, replicate: number | null | undefined): string | null | undefined => {
      if (replicate || replicate === 0) {
        const replicateMax = replicateTypeToMaxLengthMap.get(wellType);
        const paddingAmount = replicateMax
          ? replicateMax.toString().length - replicate.toString().length
          : 0;
        return padNumberWithZero(replicate, paddingAmount);
      }
      return undefined;
    };
  }
);

export const getCqData = createSelector(
  [
    getResultsStep,
    getTargetsOrFluorsToShow,
    getSelectedWells,
    getWellReplicateNumberFormatter,
    getCqDataSortOrder,
    getSubtractBaseline,
    getCurrentThresholds,
    getTargetsOrFluorsInRun,
    getPlateChannelMap,
    getTargets
  ],
  (
    ampStep,
    targetsOrFluorsToShow: Set<string>,
    wellsToShow: any,
    wellReplicateNumberFormatter: ReplicateFormatterFunction,
    sortOrder: CqDataSortOrder,
    baselineSubtraction: boolean,
    currentThresholds,
    targetsOrFluorsInRun,
    channelMap,
    plateTargets
  ): CqData | null | undefined => {
    if (ampStep) {
      const { mode, cycles, stepGroupData, plateWells, excludedWells } = ampStep;

      return new CqData(
        wellReplicateNumberFormatter,
        targetsOrFluorsToShow,
        wellsToShow,
        // @ts-ignore
        sortOrder,
        mode,
        baselineSubtraction,
        currentThresholds,
        targetsOrFluorsInRun,
        cycles,
        channelMap,
        plateTargets,
        stepGroupData,
        plateWells,
        excludedWells
      );
    }
    return undefined;
  }
);

export const getMeltData = createSelector(
  [
    getResultsStep,
    getTargetsOrFluorsToShow,
    getSelectedWells,
    getWellReplicateNumberFormatter,
    getCqDataSortOrder,
    getSelectedStepProtocol,
    getCurrentThresholds,
    getTargetsOrFluorsInRun,
    getPlateChannelMap,
    getTargets,
    getMeltMode
  ],
  (
    meltStep: AnalysisResultsStep | null | undefined,
    targetsOrFluorsToShow: Set<string>,
    wellsToShow: Set<string>,
    wellReplicateNumberFormatter: ReplicateFormatterFunction,
    sortOrder: CqDataSortOrder,
    selectedStepProtocol: Map<string, any>,
    currentThresholds: Map<string, any>,
    targetsOrFluorsInRun: Set<string>,
    channelMap: Map<string, any>,
    plateTargets: Map<string, any>,
    meltMode: string
  ): MeltData | null | undefined => {
    if (meltStep) {
      const { stepGroupData, plateWells, excludedWells, cycles, mode } = meltStep;

      return new MeltData(
        wellReplicateNumberFormatter,
        targetsOrFluorsToShow,
        wellsToShow,
        // @ts-ignore
        sortOrder,
        mode,
        cycles,
        selectedStepProtocol,
        currentThresholds,
        targetsOrFluorsInRun,
        channelMap,
        plateTargets,
        stepGroupData,
        plateWells,
        excludedWells,
        meltMode
      );
    }
    return undefined;
  }
);

export const isRunFromTemplate = (state: Map<string, any>): boolean =>
  !!state.get('samplePlaceholders');

export const getSamplePlaceholders = (state: Map<string, any>): List<Map<string, any>> =>
  state.get('samplePlaceholders');
