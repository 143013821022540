import * as React from 'react';
import { FormControl } from 'react-bootstrap';

export type Props = {
  /** id for the input field */
  id?: string;

  /** name for the input field */
  name: string;

  /** initial value of the field */
  defaultValue?: number;

  /** whether to allow negative values as input */
  allowNegative: boolean;

  /** callback for when the value has been changed */
  onChange?: (name: string, value?: number) => void;
};

type State = {
  value: string;
};

/**
 * NOTE: this is a controlled component, to update with a new value, you need to give a different "key"
 *
 *  The IntField component is a text input that allows users to input integers.
 *  Non-numeric characters are prevented from being entered except (hyphen ('-') when allowNegative is true)
 *  Repeated or ill-placed hyphen character is prevented
 *  All input after the first decimal place is stripped
 *
 *  onChange callback is called with
 *    undefined - when the user wishes to unset the value by making the input blank (or)
 *    number - the numeric representation of the user input
 *      NaN - when the input is a partial number ex: -
 *      Number.POSITIVE_INFINITY - when the input is greater than Number.MAX_VALUE
 *      Number.NEGATIVE_INFINITY - when the input is less than -1 * Number.MAX_VALUE
 */
class IntField extends React.Component<Props, State> {
  static defaultProps = {
    defaultValue: undefined,
    id: undefined,
    allowNegative: true,
    onChange: undefined
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      value: props.defaultValue === undefined ? '' : props.defaultValue.toString()
    };
  }

  handleChange = (e: Event) => {
    const { onChange } = this.props;
    const target: HTMLInputElement = e.target as any;
    const value = this.cleanupInt(target.value);
    const intValue = value === '' ? undefined : parseInt(value, 10);
    this.setState({ value });
    if (onChange) {
      onChange(target.name, intValue);
    }
  };

  cleanupInt = (value: string) => {
    const { allowNegative } = this.props;
    let str = value.replace(/[^\d-]/g, ''); // Remove all non-numeric characters
    if (allowNegative) str = str.substring(0, 1) + str.substring(1).replace(/[-]/g, '');
    // Remove any dashes not in the first character
    else str = str.replace(/[-]/g, ''); // Remove all dashes
    return str;
  };

  render() {
    const { defaultValue, allowNegative, onChange, ...remaining } = this.props;
    const { value } = this.state;
    // @ts-ignore
    return (
      <FormControl
        disabled={!onChange}
        value={value}
        type="text"
        {...remaining}
        // @ts-ignore
        onChange={onChange && this.handleChange}
      />
    );
  }
}

export default IntField;
