import { Set, fromJS, Map, List } from 'immutable';
import {
  CURRENT_CFX_RUN_BASELINE_SUBTRACTION,
  CURRENT_CFX_RUN_CLEAR,
  CURRENT_CFX_RUN_CLEAR_STEP_ERROR,
  CURRENT_CFX_RUN_INIT,
  CURRENT_CFX_RUN_PROTOCOL_IS_UPDATING,
  CURRENT_CFX_RUN_PROTOCOL_UPDATE,
  CURRENT_CFX_RUN_SET_ANALYSIS_MODE,
  CURRENT_CFX_RUN_SET_HIDE_FLUORS,
  CURRENT_CFX_RUN_SET_HIDE_TARGETS,
  CURRENT_CFX_RUN_SET_NAME,
  CURRENT_CFX_RUN_SET_NOTES,
  CURRENT_CFX_RUN_SET_PLATE,
  CURRENT_CFX_RUN_SET_PLATE_ID,
  CURRENT_CFX_RUN_SET_PLATE_TYPE,
  CURRENT_CFX_RUN_SET_SELECTED_STEP,
  CURRENT_CFX_RUN_SET_WELLS_TO_HIDE,
  CURRENT_CFX_RUN_AMPCHART_SCALE_TYPE,
  CURRENT_CFX_RUN_PLATE_SETUP_DONE,
  CURRENT_CFX_RUN_UPDATE_PLATE_TARGETS,
  CURRENT_CFX_RUN_CQ_SORT_ORDER,
  CURRENT_CFX_RUN_SHOW_PLATE_SETUP,
  CURRENT_CFX_RUN_UPDATE_THRESHOLD_SETTINGS,
  CURRENT_CFX_RUN_UPDATE_AMP_SELECTION,
  CURRENT_CFX_RUN_UPDATE_MELT_SELECTION,
  CURRENT_CFX_RUN_REPLACE_PLATE,
  CURRENT_CFX_RUN_SHOW_LOG,
  CURRENT_CFX_RUN_HIDE_LOG,
  CURRENT_CFX_RUN_LOG_LOADED,
  CURRENT_CFX_RUN_SET_MELT_MODE,
  CURRENT_CFX_RUN_UPDATE_CYCLE_RANGE_SETTINGS,
  CURRENT_CFX_RUN_DRIFT_CORRECTION
} from '../actions/currentCfxRun_types';
import { CFXRUN_TEMPLATE_ADDED } from '../actions/cfx_run_template_types';
import {
  QPCRDATA_RUN_EDITED,
  QPCRDATA_STEP_ERROR,
  QPCRDATA_STEP_LOADED,
  QPCRDATA_STEP_LOADING,
  QPCRDATA_LOADED,
  QPCRDATA_RUN_ADDED,
  QPCRDATA_SAVE_LOADING
} from '../actions/qpcrdata_types';
import { FILETYPE_PENDING_CFX_RUN } from '../frontend-common-libs/src/common/userfiles_common';
import { maybeHash } from '../frontend-common-libs/src/utils/immutableUtils';
import {
  runHasLocalEdits,
  getRunNameError,
  getRunNotesError,
  getRunBarcodeError,
  getRunPlateErrors,
  isCompletedRun,
  getPlateWells,
  getShowPlateSetup,
  getPerStepAnalysisSettingsForStepGroupMode,
  getAnalysisMode
} from '../selectors/current-cfx-run-selectors';
import { ApiAction } from '../types';
import { getNewFileName } from '../frontend-common-libs/src/utils/fileUtils';
import { getDefaultPlate, getPlateTargets, getValuesInWells } from '../utils/microplateUtils';

function makeOriginalHashes(run: Map<string, any>) {
  return fromJS({
    name: run.get('name') || '',
    notes: run.getIn(['runInfo', 'notes']) || '',
    barcode: run.getIn(['runInfo', 'barcode']) || '',
    protocolHash: maybeHash(run.get('protocol')),
    plateHash: maybeHash(run.get('plate')),
    settingsHash: maybeHash(run.get('settings'))
  });
}

const getDefaultRun = () =>
  fromJS({
    run: {
      plate: getDefaultPlate(96, 'all', 'BR White'),
      name: getNewFileName(),
      type: FILETYPE_PENDING_CFX_RUN
    },
    showPlateSetup: true
  });

const onInit = (state: Map<string, any> | null, action: ApiAction<any, any>) => {
  let currentCFXRun = fromJS(action.payload || getDefaultRun());
  // @ts-ignore
  if (isCompletedRun(currentCFXRun)) {
    // @ts-ignore
    let selectedStep = currentCFXRun.getIn(['run', 'steps']).first();
    // if no amp steps use the first melt step
    // @ts-ignore
    if (selectedStep == null) selectedStep = currentCFXRun.getIn(['run', 'meltSteps']).first();
    // @ts-ignore
    currentCFXRun = currentCFXRun // set the selected step
      .set('selectedStep', selectedStep) // update the current plate hash
      // @ts-ignore
      .setIn(['current', 'plateHash'], maybeHash(currentCFXRun.getIn(['run', 'plate'])));

    // now that selectedStep is set in currentCFXrun we can get the analysis settings
    // @ts-ignore
    const groupMode = getAnalysisMode(currentCFXRun);
    const selectedStepStr = selectedStep && selectedStep.toString();
    // @ts-ignore
    const stepGroupModeAnalysisSettings = getPerStepAnalysisSettingsForStepGroupMode(currentCFXRun);
    // @ts-ignore
    currentCFXRun = currentCFXRun.setIn(
      ['current', 'perStepAnalysisSettingsHash', selectedStepStr, groupMode],
      // @ts-ignore
      maybeHash(stepGroupModeAnalysisSettings)
    );
  }

  return (
    // @ts-ignore
    currentCFXRun
      // @ts-ignore
      .set('original', makeOriginalHashes(currentCFXRun.get('run')))
      // @ts-ignore
      .set('targets', getPlateTargets(currentCFXRun.getIn(['run', 'plate', 'wells'])))
      .set('availableTargetPositions', fromJS([]))
  );
};

const onClear = () => null;

const onRunName = (state: Map<string, any>, action: ApiAction<any, any>) => {
  const { name, error } = action.payload;
  return state.setIn(['run', 'name'], name).setIn(['errors', 'runNameError'], error);
};

const onRunNotes = (state: Map<string, any>, action: ApiAction<any, any>) => {
  const { notes, error } = action.payload;
  return state.setIn(['run', 'runInfo', 'notes'], notes).setIn(['errors', 'runNotesError'], error);
};

const onRunBarcode = (state: Map<string, any>, action: ApiAction<any, any>) => {
  const { barcode, error } = action.payload;

  return state
    .setIn(['run', 'runInfo', 'barcode'], barcode)
    .setIn(['errors', 'runBarcodeError'], error);
};

const onPlate = (state: Map<string, any>, action: ApiAction<any, any>) => {
  const { plate, plateErrors } = action.payload;
  const wellsInState = state.getIn(['run', 'plate', 'wells']);
  const selectedWells = state.get('selectedWells');
  // @ts-ignore
  const deselectedWells = Set.fromKeys(wellsInState).subtract(selectedWells);
  const newSelectedWells = Set.fromKeys(plate.get('wells')).subtract(deselectedWells);
  return state
    .set('selectedWells', newSelectedWells)
    .setIn(['run', 'plate'], plate)
    .setIn(['errors', 'plateErrors'], plateErrors);
};

const onPlateType = (state: Map<string, any>, action: ApiAction<any, any>) => {
  const { type } = action.payload;
  return state.setIn(['run', 'plate', 'plateType'], type);
};

const onProtocolUpdating = (state: Map<string, any>, action: ApiAction<any, any>) =>
  state.set('protocolIsUpdating', action.payload.updating);

const onProtocolUpdate = (state: Map<string, any>, action: ApiAction<any, any>) =>
  state
    .set('protocolEntity', action.payload.entity)
    .setIn(['run', 'protocol'], action.payload.protocol)
    .setIn(['run', 'runInfo', 'protocolName'], action.payload.protocolName);

const onSetWellsToHide = (state: Map<string, any>, action: ApiAction<any, any>) =>
  state.setIn(['run', 'settings', 'view', 'wellsToHide'], action.payload.wellsToHide);

const onSetSelectedStep = (state: Map<string, any>, action: ApiAction<any, any>) =>
  state.set('selectedStep', action.payload.step);

const onSetAnalysisMode = (state: Map<string, any>, action: ApiAction<any, any>) =>
  state.setIn(['run', 'settings', 'analysis', 'groupMode'], action.payload.mode);

const onSetMeltMode = (state: Map<string, any>, action: ApiAction<any, any>) =>
  state.setIn(['run', 'viewSettings', 'meltMode'], action.payload);

const onHideFluors = (state: Map<string, any>, action: ApiAction<any, any>) => {
  const { fluorsToHide } = action.payload;
  return state.setIn(['run', 'settings', 'view', 'fluorsToHide'], fluorsToHide);
};

const onHideTargets = (state: Map<string, any>, action: ApiAction<any, any>) => {
  const { targetsToHide } = action.payload;
  return state.setIn(['run', 'settings', 'view', 'targetsToHide'], targetsToHide);
};

const onBaselineSubtraction = (state: Map<string, any>, action: ApiAction<any, any>) =>
  state.setIn(
    ['run', 'settings', 'view', 'baselineSubtraction'],
    action.payload.baselineSubtraction
  );

const onDriftCorrection = (state: Map<string, any>, action: ApiAction<any, any>) =>
  state
    .setIn(['run', 'settings', 'analysis', 'driftCorrection'], action.payload.driftCorrection)
    // Resetting perStepAnalysisSettingsHash on drift correction toggle to force data reload with correct drift correction mode
    .setIn(['current', 'perStepAnalysisSettingsHash'], Map<string, any>());

const isValidState = (state: Map<string, any>, faId: string) => state && state.get('id') === faId;

const runHasChanged = (currentRun: Map<string, any>, newRun: Map<string, any>) => {
  const currentRunHash = maybeHash(currentRun.delete('stepData'));
  const newRunHash = maybeHash(newRun.delete('stepData'));
  return currentRunHash !== newRunHash;
};

const hasCompatibleChanges = (currentRun: Map<string, any>, newRun: Map<string, any>) => {
  const newScanMode = newRun.getIn(['plate', 'scanMode']);
  const currentScanMode = currentRun.getIn(['plate', 'scanMode']);
  const newRowCount = newRun.getIn(['plate', 'layout', 'rows']);
  const currentRowCount = currentRun.getIn(['plate', 'layout', 'rows']);
  return newScanMode === currentScanMode && newRowCount === currentRowCount;
};

const retainLocalAnalysisSetting = (
  state: Map<string, any>,
  currentRun: Map<string, any>
): Map<string, any> => {
  const localAnalysisSettings = currentRun.getIn(['settings', 'analysis']);
  if (!localAnalysisSettings) return state;
  return state.setIn(['run', 'settings', 'analysis'], localAnalysisSettings);
};

const onQpcrDataLoaded = (state: Map<string, any>, action: ApiAction<any, any>) => {
  const { faId, data: newRun } = action.payload;
  if (!isValidState(state, faId)) return state;

  const currentRun = state.get('run');

  if (!runHasLocalEdits(state)) {
    if (!runHasChanged(currentRun, newRun)) {
      return state;
    }
    // if there are no local edits but run has changed (ex: pending run to completed run)
    // initialize with the new data
    return onInit(state, {
      type: '',
      payload: {
        id: faId,
        run: newRun,
        showPlateSetup: getShowPlateSetup(state)
      }
    });
  }
  const payload = {
    id: faId,
    run: newRun,
    showPlateSetup: getShowPlateSetup(state)
  };

  if (!hasCompatibleChanges(currentRun, newRun)) {
    // if the new run is different than the current run
    // and the updates are not compatible with the completed run data
    // initialize with the new data
    // The user will lose their edits
    return onInit(state, {
      type: '',
      payload
    });
  }

  // start with state initialized with new run
  let updatedState = onInit(state, {
    type: '',
    payload
  });
  updatedState = updatedState
    .set('isSaveInProgress', state.get('isSaveInProgress'))
    .set('targets', state.get('targets'))
    .set('availableTargetPositions', state.get('availableTargetPositions'));

  // NAME
  const currentName = currentRun.get('name');
  const originalName = state.getIn(['original', 'name']);
  if (currentName !== originalName) {
    // replace name with current run name to maintain user's unsaved edits
    updatedState = updatedState.setIn(['run', 'name'], currentName);
    // preserve run name error
    const runNameError = getRunNameError(state);
    if (runNameError) {
      updatedState = updatedState.setIn(['errors', 'runNameError'], runNameError);
    }
  }

  // NOTES
  const currentNotes = currentRun.getIn(['runInfo', 'notes']);
  const originalNotes = state.getIn(['original', 'notes']);
  if (currentNotes !== originalNotes) {
    // replace notes with current run notes to maintain user's unsaved edits
    updatedState = updatedState.setIn(['run', 'runInfo', 'notes'], currentNotes);
    // preserve run notes error
    const runNotesError = getRunNotesError(state);
    if (runNotesError) {
      updatedState = updatedState.setIn(['errors', 'runNotesError'], runNotesError);
    }
  }

  // PLATEID
  const currentBarcode = currentRun.getIn(['runInfo', 'barcode']);
  const originalBarcode = state.getIn(['original', 'barcode']);
  if (currentBarcode !== originalBarcode) {
    // replace orginal Plate Id with current Plate Id to maintain user's unsaved edits
    updatedState = updatedState.setIn(['run', 'runInfo', 'barcode'], currentBarcode);
    // preserve run barcode error
    const runBarcodeError = getRunBarcodeError(state);
    if (runBarcodeError) {
      updatedState = updatedState.setIn(['errors', 'runBarcodeError'], runBarcodeError);
    }
  }

  // PLATE
  const currentPlateHash = maybeHash(currentRun.get('plate'));
  if (currentPlateHash !== state.getIn(['original', 'plateHash'])) {
    // replace plate with current plate to maintain user's compatible unsaved edits
    updatedState = updatedState.setIn(['run', 'plate'], currentRun.get('plate'));

    // preserve plate errors
    const plateErrors = getRunPlateErrors(state);
    if (plateErrors) {
      updatedState = updatedState.setIn(['errors', 'plateErrors'], plateErrors);
    }
  }

  // VIEW SETTINGS
  const currentRunViewSettings = currentRun.getIn(['settings', 'view']);
  if (currentRunViewSettings) {
    updatedState = updatedState.setIn(['run', 'settings', 'view'], currentRunViewSettings);
  }

  // ANALYSIS SETTINGS
  updatedState = retainLocalAnalysisSetting(updatedState, currentRun);

  // save current selectedStep
  if (state.has('selectedStep')) {
    updatedState = updatedState.set('selectedStep', state.get('selectedStep'));
  }

  return updatedState;
};

const onPcrResultsUpdated = (state: Map<string, any>, action: ApiAction<any, any>) => {
  const { faId, step, groupMode, stepData, plate, perStepAnalysisSettings } = action.payload;
  /*
    Scenarios Covered:
    [I] Stale Step Data - after requesting new step data, BUT before that step data has loaded:
      (1) User navigates to a new completed run
      (2) User updates the plate 
    [II] Current Step Data - update current step's data and:
      (A) Change Plate - remove all other step data since it is now stale
      (B) Change Analysis Settings - nothing, analysis settings are applied per step
  */
  // [I] Determine if new stepData is stale
  // (1) if the user navigates to a new completed run the entityId's
  // would be different so the recomputed stepData should be ignored
  if (!isValidState(state, faId)) return state;

  const stepNumStr = step.toString();
  const plateHash = maybeHash(plate);
  // @ts-ignore
  const runPlateHash = maybeHash(state.getIn(['run', 'plate']));
  const analysisSettingsHash = maybeHash(perStepAnalysisSettings);
  // @ts-ignore
  const runAnalysisSettingsHash = maybeHash(getPerStepAnalysisSettingsForStepGroupMode(state)); // add maybe hash for FDC
  const analysisSettingsChanged = runAnalysisSettingsHash !== analysisSettingsHash;
  if (runPlateHash !== plateHash || analysisSettingsChanged) {
    // (2) plate or anlaysis settings used to recalculate the stepData no longer matches the 'view plate'
    // 'view analysis settings'
    // ie: while recalculating the stepData user navigates back to the plate
    // editor and updates the plate.
    return state;
  }
  // [II] New step data is not stale and should be loaded

  // update the current hashes. These are used in view to determine if a step update is needed
  // also remove the loading information
  // * Making this update will cause the view to load the new step data *
  let newState = state
    .setIn(['current', 'plateHash'], plateHash)
    .setIn(['current', 'perStepAnalysisSettingsHash', stepNumStr, groupMode], analysisSettingsHash)
    .deleteIn(['loading', stepNumStr, groupMode]);

  if (state.getIn(['current', 'plateHash']) !== plateHash) {
    // (A) Change Plate - since plate changes effect all steps,
    // all stepData should be replaced with the current step's new data
    // New step data for the other steps will be loaded when the user navigates to that step
    newState = newState.setIn(
      ['run', 'stepData'],
      fromJS({ [stepNumStr]: { [groupMode]: stepData } })
    );
  } else {
    // (B) Update Analysis Settings - analysis settings are per step and group mode, so you just
    // need to update that data
    newState = newState.setIn(['run', 'stepData', stepNumStr, groupMode], fromJS(stepData));
  }
  return newState;
};

const onPcrRunSaved = (state: Map<string, any>, action: ApiAction<any, any>) => {
  const { faEntity, data, shouldRedirect } = action.payload;
  if (isValidState(state, faEntity.id)) {
    return state
      .set('original', makeOriginalHashes(data))
      .set('shouldRedirect', shouldRedirect)
      .set('isSaveInProgress', false);
  }
  return state;
};

const onPcrStepLoading = (state: Map<string, any>, action: ApiAction<any, any>) => {
  const { faId, step, groupMode, plate, perStepAnalysisSettings } = action.payload;
  if (!isValidState(state, faId)) return state;

  const stepNumStr = step.toString();
  return state
    .setIn(['loading', stepNumStr, groupMode, 'plateHash'], maybeHash(plate))
    .setIn(
      ['loading', stepNumStr, groupMode, 'analysisSettingsHash'],
      maybeHash(perStepAnalysisSettings)
    );
};

const onPcrStepError = (state: Map<string, any>, action: ApiAction<any, any>) => {
  const { faId, step, groupMode, plate, perStepAnalysisSettings } = action.payload;
  if (!isValidState(state, faId)) return state;

  const stepNumStr = step.toString();
  const prevPlateHash = state.getIn(['loading', stepNumStr, groupMode, 'plateHash']);
  const prevAnalysisSettingsHash = state.getIn([
    'loading',
    stepNumStr,
    groupMode,
    'analysisSettingsHash'
  ]);

  if (
    prevPlateHash === maybeHash(plate) &&
    prevAnalysisSettingsHash === maybeHash(perStepAnalysisSettings)
  ) {
    return state.setIn(['loading', stepNumStr, groupMode, 'error'], true);
  }
  return state;
};

const onClearStepError = (state: Map<string, any>, action: ApiAction<any, any>) => {
  const { id, step, groupMode } = action.payload;
  if (!isValidState(state, id)) return state;

  const stepNumStr = step.toString();
  return state.deleteIn(['loading', stepNumStr, groupMode]);
};

const onSetAmpChartScaleType = (state: Map<string, any>, action: ApiAction<any, any>) => {
  const {
    payload: { scaleType }
  } = action;
  return state.setIn(['run', 'settings', 'view', 'ampScale'], scaleType);
};

const onPlateSetupDone = (state: Map<string, any>) => state.delete('needsPlateSetup');

const onQpcrRunAdded = (state: Map<string, any>, action: ApiAction<any, any>) => {
  const { faEntity, data, shouldRedirect } = action.payload;
  const { id } = faEntity;
  const newState = state.set('shouldRedirect', shouldRedirect);
  if (state.has('id')) return newState;
  return newState
    .set('id', id)
    .set('original', makeOriginalHashes(data))
    .set('shouldRedirect', shouldRedirect)
    .set('isSaveInProgress', false);
};

const onRunTemplateAdded = (state: Map<string, any>, action: ApiAction<any, any>) => {
  const { shouldRedirect } = action.payload;
  return state.set('shouldRedirect', shouldRedirect);
};

const onUpdatePlateTargets = (state: Map<string, any>, action: ApiAction<any, any>) => {
  const { newTarget, oldTarget } = action.payload;
  const oldTargets = state.get('targets');
  // if target was updated or new target is added
  if (newTarget && !oldTargets.has(newTarget)) {
    // Get list of targets currently in the plate
    const plateTargets = getValuesInWells(getPlateWells(state), 'target');
    // Get list of targets located in the old and new target list
    const targets = oldTargets.filter((v: unknown, k: unknown) => plateTargets.has(k));

    // Get all deleted targets and add them to availableTargetPositions
    let deletedTargets = oldTargets.filterNot((v: unknown, k: unknown) => plateTargets.has(k));
    let updatedTargets;
    let availableTargetPositions = state.get('availableTargetPositions');
    // target was updated
    if (oldTargets.has(oldTarget) && !plateTargets.has(oldTarget)) {
      // set newtarget with oldtarget value
      updatedTargets = targets.set(newTarget, oldTargets.get(oldTarget)).delete(oldTarget);
      deletedTargets = deletedTargets.delete(oldTarget); // do not add old target to available positions
      availableTargetPositions = availableTargetPositions.concat(deletedTargets.valueSeq());
    } else {
      availableTargetPositions = availableTargetPositions.concat(deletedTargets.valueSeq());

      let colorIndex = targets.size;
      if (availableTargetPositions.size) {
        colorIndex = availableTargetPositions.first();
        availableTargetPositions = availableTargetPositions.shift();
      }
      updatedTargets = targets.set(newTarget, colorIndex);
    }

    return state
      .set('targets', updatedTargets)
      .set('availableTargetPositions', availableTargetPositions);
  }
  return state;
};

const onPcrSaveLoading = (state: Map<string, any>, action: ApiAction<any, any>) =>
  state.set('isSaveInProgress', action.payload.isSaveInProgress);

const onUpdateCqDataSortOrder = (state: Map<string, any>, action: ApiAction<any, any>) =>
  state.set('cqDataSortOrder', action.payload.sortOrder);

const onShowPlateSetup = (state: Map<string, any>, action: ApiAction<any, any>) =>
  state.set('showPlateSetup', action.payload.showPlate);

const onUpdateThresholdSettings = (state: Map<string, any>, action: ApiAction<any, any>) => {
  const { step, analysisMode, updatedCustomThresholds, updatedAutoThresholdSettings } =
    action.payload;
  let analysisSettings = getPerStepAnalysisSettingsForStepGroupMode(state);

  analysisSettings = updatedCustomThresholds.reduce(
    (settings: Map<string, any>, threshold: unknown, fluorOrTarget: unknown) =>
      settings.setIn([fluorOrTarget, 'threshold', 'value'], threshold),
    analysisSettings
  );

  analysisSettings = updatedAutoThresholdSettings.reduce(
    (settings: Map<string, any>, auto: unknown, fluorOrTarget: unknown) =>
      settings.setIn([fluorOrTarget, 'threshold', 'auto'], auto),
    analysisSettings
  );

  return state.setIn(
    [
      'run',
      'settings',
      'analysis',
      'perStep',
      step,
      analysisMode === 'target' ? 'targets' : 'fluors'
    ],
    analysisSettings
  );
};

const onSetDefaultAmpStep = (state: Map<string, any>) => {
  // @ts-ignore
  const step = state.getIn(['run', 'steps']).first();
  return state.set('selectedStep', step);
};

const onSetDefaultMeltStep = (state: Map<string, any>) => {
  // @ts-ignore
  const meltStep = state.getIn(['run', 'meltSteps']).first();
  return state.set('selectedStep', meltStep);
};

const onReplacePlate = (state: Map<string, any>, action: ApiAction<any, any>) => {
  const { plate } = action.payload;
  return state
    .setIn(['run', 'plate'], plate)
    .set('targets', getPlateTargets(plate.get('wells')))
    .set('availableTargetPositions', List())
    .deleteIn(['errors', 'plateErrors']);
};

const onShowLog = (state: Map<string, any>) => {
  return state.setIn(['run', 'log', 'showingLog'], true).setIn(['run', 'log', 'loadingLog'], true);
};

const onHideLog = (state: Map<string, any>) => {
  return state.setIn(['run', 'log', 'showingLog'], false);
};

const onLogLoaded = (state: Map<string, any>, action: ApiAction<any, any>) => {
  const logEntries = action.payload;
  return state
    .setIn(['run', 'log', 'logsEntries'], logEntries)
    .setIn(['run', 'log', 'loadingLog'], false);
};

const checkStateExists = (
  handler: (state: Map<string, any>, action: ApiAction<any, any>) => Map<string, any>
): ((state: Map<string, any> | null, action: ApiAction<any, any>) => Map<string, any> | null) => {
  return (state: Map<string, any> | null, action: ApiAction<any, any>) => {
    if (!state) {
      return null;
    }
    return handler(state, action);
  };
};

const updateCyclePerWell = (
  state: Map<string, any>,
  step: string,
  analysisMode: string,
  customChanges: Map<string, any>
) => {
  const targetsOrFluors = analysisMode === 'target' ? 'targets' : 'fluors';
  let updatedState = state;
  customChanges.forEach((settings: Map<string, any>, targetOrFluor: string) => {
    const cyclesPerWell = settings.get('cyclesPerWell');
    if (cyclesPerWell != null) {
      cyclesPerWell.forEach((wellSetting: Map<string, any>, wellName: string) => {
        wellSetting.forEach((value: number | null, key: string) => {
          updatedState = updatedState.setIn(
            [
              'run',
              'settings',
              'analysis',
              'perStep',
              step,
              targetsOrFluors,
              targetOrFluor,
              'cyclesPerWell',
              wellName,
              key
            ],
            value
          );
        });
      });
    }
  });
  return updatedState;
};

const onUpdateCycleRangeSettings = (state: Map<string, any>, action: ApiAction<any, any>) => {
  const { step, analysisMode, customChanges } = action.payload;
  return updateCyclePerWell(state, step, analysisMode, customChanges);
};

const actionMap = {
  [CURRENT_CFX_RUN_INIT]: onInit,
  [CURRENT_CFX_RUN_CLEAR]: onClear,
  [CURRENT_CFX_RUN_SET_NAME]: checkStateExists(onRunName),
  [CURRENT_CFX_RUN_SET_NOTES]: checkStateExists(onRunNotes),
  [CURRENT_CFX_RUN_SET_PLATE]: checkStateExists(onPlate),
  [CURRENT_CFX_RUN_SET_PLATE_ID]: checkStateExists(onRunBarcode),
  [CURRENT_CFX_RUN_SET_PLATE_TYPE]: checkStateExists(onPlateType),
  [CURRENT_CFX_RUN_PROTOCOL_IS_UPDATING]: checkStateExists(onProtocolUpdating),
  [CURRENT_CFX_RUN_PROTOCOL_UPDATE]: checkStateExists(onProtocolUpdate),
  [CURRENT_CFX_RUN_SET_WELLS_TO_HIDE]: checkStateExists(onSetWellsToHide),
  [CURRENT_CFX_RUN_SET_SELECTED_STEP]: checkStateExists(onSetSelectedStep),
  [CURRENT_CFX_RUN_SET_ANALYSIS_MODE]: checkStateExists(onSetAnalysisMode),
  [CURRENT_CFX_RUN_SET_MELT_MODE]: checkStateExists(onSetMeltMode),
  [CURRENT_CFX_RUN_SET_HIDE_FLUORS]: checkStateExists(onHideFluors),
  [CURRENT_CFX_RUN_SET_HIDE_TARGETS]: checkStateExists(onHideTargets),
  [CURRENT_CFX_RUN_BASELINE_SUBTRACTION]: checkStateExists(onBaselineSubtraction),
  [CURRENT_CFX_RUN_DRIFT_CORRECTION]: checkStateExists(onDriftCorrection),
  [QPCRDATA_LOADED]: checkStateExists(onQpcrDataLoaded),
  [QPCRDATA_STEP_LOADED]: checkStateExists(onPcrResultsUpdated),
  [QPCRDATA_STEP_LOADING]: checkStateExists(onPcrStepLoading),
  [QPCRDATA_SAVE_LOADING]: checkStateExists(onPcrSaveLoading),
  [QPCRDATA_STEP_ERROR]: checkStateExists(onPcrStepError),
  [QPCRDATA_RUN_EDITED]: checkStateExists(onPcrRunSaved),
  [CURRENT_CFX_RUN_CLEAR_STEP_ERROR]: checkStateExists(onClearStepError),
  [CURRENT_CFX_RUN_AMPCHART_SCALE_TYPE]: checkStateExists(onSetAmpChartScaleType),
  [CURRENT_CFX_RUN_PLATE_SETUP_DONE]: checkStateExists(onPlateSetupDone),
  [QPCRDATA_RUN_ADDED]: checkStateExists(onQpcrRunAdded),
  [CFXRUN_TEMPLATE_ADDED]: checkStateExists(onRunTemplateAdded),
  [CURRENT_CFX_RUN_UPDATE_PLATE_TARGETS]: checkStateExists(onUpdatePlateTargets),
  [CURRENT_CFX_RUN_CQ_SORT_ORDER]: checkStateExists(onUpdateCqDataSortOrder),
  [CURRENT_CFX_RUN_SHOW_PLATE_SETUP]: checkStateExists(onShowPlateSetup),
  [CURRENT_CFX_RUN_UPDATE_THRESHOLD_SETTINGS]: checkStateExists(onUpdateThresholdSettings),
  [CURRENT_CFX_RUN_UPDATE_AMP_SELECTION]: checkStateExists(onSetDefaultAmpStep),
  [CURRENT_CFX_RUN_UPDATE_MELT_SELECTION]: checkStateExists(onSetDefaultMeltStep),
  [CURRENT_CFX_RUN_REPLACE_PLATE]: checkStateExists(onReplacePlate),
  [CURRENT_CFX_RUN_SHOW_LOG]: checkStateExists(onShowLog),
  [CURRENT_CFX_RUN_HIDE_LOG]: checkStateExists(onHideLog),
  [CURRENT_CFX_RUN_LOG_LOADED]: checkStateExists(onLogLoaded),
  [CURRENT_CFX_RUN_UPDATE_CYCLE_RANGE_SETTINGS]: checkStateExists(onUpdateCycleRangeSettings)
};

export default function currentCfxRunReducer(
  state: Map<string, any> | null = null,
  action: ApiAction<any, any> | undefined = undefined
): Map<string, any> {
  if (!action) return state as Map<string, any>;
  return actionMap[action.type] ? actionMap[action.type](state, action) : state;
}
