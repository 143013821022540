import React, { Component, createRef } from 'react';
import { Overlay, Tooltip } from 'react-bootstrap';
import CopyToClipboardIcon from '../../../img/copy-to-clipboard.svg';
import SucceededCopyActionIcon from '../../../img/circle-check.svg';
import styles from '../style/create-api-token-modal.module.scss';

export type Props = {
  textToCopy: string;
};

type State = {
  copied: boolean;
};

class CopyToClipboard extends Component<Props, State> {
  private target = createRef<HTMLButtonElement>();

  public constructor(props: Props) {
    super(props);
    this.state = {
      copied: false
    };
    this.getTarget = this.getTarget.bind(this);
  }

  public componentDidUpdate(prevProps: Props, prevState: State) {
    const { copied } = this.state;
    if (!prevState.copied && copied) {
      setTimeout(() => {
        this.setState({ copied: false });
      }, 1000);
    }
  }

  public copyToClipboard = async () => {
    const { textToCopy } = this.props;
    await navigator.clipboard.writeText(textToCopy);
    this.setState({ copied: true });
  };

  /* istanbul ignore next */
  private getTarget() {
    return this.target.current;
  }

  render() {
    const { copied } = this.state;
    const overlayProps = {
      container: this,
      show: true,
      target: this.getTarget
    };
    return (
      <div className={styles.copyClipboardContainer}>
        <button
          type="button"
          onClick={this.copyToClipboard}
          id="btn-copy-to-clipboard"
          ref={this.target}
        >
          {copied ? (
            <>
              <img src={SucceededCopyActionIcon} alt="copied" />
              <Overlay {...overlayProps} placement="top">
                <Tooltip id="tooltip-copied-message">Copied!</Tooltip>
              </Overlay>
            </>
          ) : (
            <img src={CopyToClipboardIcon} alt="copy to clipboard" />
          )}
        </button>
      </div>
    );
  }
}

export default CopyToClipboard;
