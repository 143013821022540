import React from 'react';
import { getTypeIcon } from '../utils/instrumentUtils';

export type Props = {
  type: string;
};

export default function RealTimePCRInstrumentTypeIcon({ type }: Props) {
  return <img src={getTypeIcon(type)} alt={type} />;
}
