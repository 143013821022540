// @ts-nocheck
import React from 'react';
import exportIcon from '../../../../../img/export.svg';
import { createBlobURL } from '../../../../utils/windowUtils';

type Props = {
  fileName: string;
  csvDataString: string;
  disabled?: boolean;
};

const id = 'download-csv';
const title = 'Export table data as CSV.';
const imgAlt = 'Export Button';

export const ExportIconButtonImpl = (props: Props) => {
  const { csvDataString, disabled, fileName } = props;

  const renderButtonContent = () => (
    <>
      <img src={exportIcon} className="icon" alt={imgAlt} />
      Export
    </>
  );

  // rendering a disabled button when a threshold change is made
  if (disabled)
    return (
      <button
        type="button"
        title={title}
        id={id}
        disabled={disabled}
        className="new-btn-icon"
        tabIndex={-1}
      >
        {renderButtonContent()}
      </button>
    );

  return (
    <a
      title={title}
      id={id}
      download={fileName}
      className="new-btn-icon"
      href={createBlobURL(csvDataString)}
    >
      {renderButtonContent()}
    </a>
  );
};

ExportIconButtonImpl.defaultProps = {
  disabled: false
};

export default ExportIconButtonImpl;
