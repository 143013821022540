import React, { PureComponent } from 'react';
import '../../styles/search-toolbar.scss';
import { SearchBox } from '../search-box';

export type Props = {
  onSearchInputChanged: (searchText: string) => void;
  searchText: string;
  onSearch: () => void;
  onClearSearch: () => void;
};

export default class SearchToolbar extends PureComponent<Props> {
  render() {
    const { searchText, onSearchInputChanged, onSearch, onClearSearch } = this.props;
    return (
      <div className="search-toolbar">
        <SearchBox
          id="file-search-box"
          placeholder="Search files"
          onSearchInputChanged={onSearchInputChanged}
          searchText={searchText}
          onSearch={onSearch}
          onClearSearch={onClearSearch}
        />
      </div>
    );
  }
}
