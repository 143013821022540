import { Map } from 'immutable';
import { Dispatch, ThunkAction } from '../../types';
import {
  COMPLETE_REGISTRATION_ERROR,
  COMPLETE_REGISTRATION_IN_PROGRESS,
  COMPLETE_REGISTRATION_SUCCESS,
  HIDE_VERIFIED_ACCOUNT_MESSAGE_ON_LOGIN,
  CompleteRegistrationActionType
} from './complete_registration_types';
import { createCognitoUser } from '../../frontend-common-libs/src/utils/authUtils';
import EmailVerificationErrorOccurredEvent from '../../user-analytics/email-verification/EmailVerificationErrorOccurredEvent';

export function confirmRegistration(
  confirmationCode: string,
  username: string
): ThunkAction<CompleteRegistrationActionType, string | Map<unknown, unknown>> {
  return async (
    dispatch: Dispatch<CompleteRegistrationActionType, string | Map<unknown, unknown>>
  ) => {
    dispatch({
      type: COMPLETE_REGISTRATION_IN_PROGRESS,
      payload: ''
    });

    const cognitoUser = createCognitoUser(username);
    cognitoUser.confirmRegistration(confirmationCode, false, err => {
      if (err) {
        const errorMessage: string = err.message;
        new EmailVerificationErrorOccurredEvent(errorMessage).track();
        return dispatch({
          type: COMPLETE_REGISTRATION_ERROR,
          payload: err.message
        });
      }
      return dispatch({
        type: COMPLETE_REGISTRATION_SUCCESS,
        payload: ''
      });
    });
  };
}

export function hideVerifiedAccountMessageOnLogin(): Object {
  return {
    type: HIDE_VERIFIED_ACCOUNT_MESSAGE_ON_LOGIN
  };
}
