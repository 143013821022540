import { Dispatch, GetState } from '../types';
import { ArchiveActionType, ARCHIVE_LOADING, ARCHIVE_LOADED, ARCHIVE_ERROR } from './archive_types';
import { getArchivedEntitiesList, restoreEntities } from '../frontend-common-libs/src/api/entities';
import { getSelectedProjectId } from '../project-management';
import { ProjectId } from '../frontend-common-libs/src/common/project-management-types';
import { USERFILES_RESTORED } from '../file-management/actions/action-types';
import { PROTOCOL_TEMPLATES_RESTORED } from './protocolTemplate_types';
import notification from '../frontend-common-libs/src/utils/notifications';
import { PROTOCOL_ENTITY_TYPE } from '../frontend-common-libs/src/common/protocol_common';
import { UserFile } from '../frontend-common-libs/src/common/types';
import { searchFiles } from '../file-management/actions/actions';
import { withRefreshProjectToken } from '../project-management/actions/with-refresh-project-token';
import { CbWithToken } from '../organization-management/actions/WithRefreshToken';

type DispatchType = Dispatch<ArchiveActionType, Record<string, any> | string>;

function getArchivedEntities(lastSeen: string, projectId: ProjectId) {
  return async (dispatch: DispatchType) => {
    dispatch({
      type: ARCHIVE_LOADING,
      payload: { projectId }
    });

    try {
      const result = await getArchivedEntitiesList(lastSeen, projectId);

      dispatch({
        type: ARCHIVE_LOADED,
        payload: { ...result.data, projectId }
      });
    } catch (error) {
      const message = error instanceof Error ? error.message : 'Unknown error';
      dispatch({
        type: ARCHIVE_ERROR,
        payload: { message, projectId }
      });
    }
  };
}

export function getArchivedEntitiesIfNeeded(lastSeen: string) {
  return (
    dispatch: Dispatch<ArchiveActionType, Record<string, any> | string>,
    getState: GetState
  ) => {
    const state = getState();
    const projectId = getSelectedProjectId(state);
    if (!state.archive.getIn(['projects', projectId, 'isLoading'])) {
      dispatch(getArchivedEntities(lastSeen, projectId));
    }
  };
}

function splitToProtocolAndFiles(entities: UserFile[]) {
  const protocolsEntities: UserFile[] = [];
  const filesEntities: UserFile[] = [];
  entities.forEach((entity: UserFile) => {
    if ((entity.type as string) === PROTOCOL_ENTITY_TYPE) protocolsEntities.push(entity);
    else filesEntities.push(entity);
  });
  return { protocolsEntities, filesEntities };
}

export function restoreUserEntities(entityList: string[]) {
  return async (dispatch: Dispatch<any, Record<string, any> | string>, getState: GetState) => {
    const state = getState();
    const projectId = getSelectedProjectId(state);
    try {
      const restoreCb: CbWithToken = async (projectAccessToken: string) => {
        return restoreEntities(entityList, projectAccessToken);
      };
      const res = await withRefreshProjectToken(dispatch, getState, projectId, restoreCb);
      const { entities } = res.data;
      const { protocolsEntities, filesEntities } = splitToProtocolAndFiles(entities);

      if (filesEntities.length > 0) {
        dispatch({
          type: USERFILES_RESTORED,
          payload: { entities: filesEntities }
        });
      }
      if (protocolsEntities.length > 0) {
        dispatch({
          type: PROTOCOL_TEMPLATES_RESTORED,
          payload: { entities: protocolsEntities }
        });
      }

      const searchText = state.userFiles.getIn(['projects', projectId, 'searchText']);

      if (searchText) {
        await searchFiles(<string>searchText)(dispatch, getState);
      }

      notification.success('Your item has been restored.');
    } catch (error) {
      const message = error instanceof Error ? error.message : 'Unknown error';
      dispatch({
        type: ARCHIVE_ERROR,
        payload: { message, projectId }
      });
    }
  };
}
