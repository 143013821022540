import React from 'react';
import RectangularSkeletonLoader, { SkeletonLoaderProps } from './RectangularSkeletonLoader';

export default function FileIconSkeletonLoader(props: Readonly<SkeletonLoaderProps>) {
  const { viewBox, height, width, y, rx, ry } = props;

  const overrideProps = {
    ...props,
    viewBox: viewBox ?? '0 0 50 60',
    height: height ?? '60',
    width: width ?? '40',
    y: y ?? '10',
    rx: rx ?? '0',
    ry: ry ?? '0'
  };

  return <RectangularSkeletonLoader {...overrideProps} />;
}
