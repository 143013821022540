import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Map, Set } from 'immutable';
import Plate from './Plate';
import SelectionBox from './SelectionBox';
import { SelectionBoxDimensions, ModifierKeys } from './SelectionBox';
import { getTargets, getExcludedWells } from '../../../selectors/selectors';
import { ReplicateFormatterFunction } from '../../../selectors/current-cfx-run-selectors';
import { ReduxState } from '../../../types';

export type Props = {
  rows: number;
  cols: number;
  wells: Map<string, any>;
  wellReplicateNumberFormatter: ReplicateFormatterFunction;
  selectedWells: Set<string>;
  changeSelectedWells: (arg0: Set<string>, arg1: ModifierKeys) => any;
  editMode?: boolean;
  plateWrapperSelector: string;
  verticalOffset?: number;
  plateTargets: Map<string, any>;
  isAnalysisView: any;
  excludedWells: Set<string>;
  colHeaderOffset: number;
  rowHeaderOffset: number;
  tableSpacingLeft: number;
  tableSpacingTop: number;
};

export type State = {
  highlightedWells: Set<string>;
};

export class SelectionPlateImpl extends Component<Props, State> {
  static defaultProps = {
    editMode: false,
    verticalOffset: 0
  };

  plateNode: Plate | null | undefined;

  constructor(props: Props) {
    super(props);
    this.state = {
      highlightedWells: Set()
    };
  }

  getWellsInSelectionBox = (selectionBoxDimensions: SelectionBoxDimensions): Set<string> => {
    const { editMode, wells } = this.props;
    let wellsInSelectionBox = Set(); // start with empty map, recalculate each time
    if (this.plateNode) {
      const { wellRefs } = this.plateNode;
      if (!editMode) {
        // iterate over wells to determine which ones are inside of the selectionBox
        wells.forEach((well, wellId) => {
          // check to see if the current well DOM node is w/in the selectionBox using the index
          const node = wellRefs[wellId];
          if (node && this.isInside(selectionBoxDimensions, node)) {
            wellsInSelectionBox = wellsInSelectionBox.add(wellId);
          }
        });
      } else {
        Object.keys(wellRefs).forEach(wellId => {
          const node = wellRefs[wellId];
          if (node && this.isInside(selectionBoxDimensions, node)) {
            wellsInSelectionBox = wellsInSelectionBox.add(wellId);
          }
        });
      }
    }
    // @ts-ignore
    return wellsInSelectionBox;
  };

  setPlateRef = (plateRef: Plate | null | undefined) => {
    this.plateNode = plateRef;
  };

  isInside = (box: SelectionBoxDimensions, node: HTMLElement): boolean => {
    const nodeBox = node.getBoundingClientRect();
    return (
      box.left <= nodeBox.left + nodeBox.width &&
      box.left + box.width >= nodeBox.left &&
      box.top <= nodeBox.top + nodeBox.height &&
      box.top + box.height >= nodeBox.top
    );
  };

  // highlight wells within the selectionBox
  highlightWells = (selectionBoxDimensions: SelectionBoxDimensions) => {
    const { highlightedWells } = this.state;
    const wellsInSelectionBox = this.getWellsInSelectionBox(selectionBoxDimensions);
    if (
      wellsInSelectionBox.size !== highlightedWells.size ||
      wellsInSelectionBox.every((well, wellId) => !highlightedWells.has(wellId))
    ) {
      this.setState({
        highlightedWells: wellsInSelectionBox
      });
    }
  };

  // select/deselect wells within the selection box
  selectWells = (selectionBoxDimensions: SelectionBoxDimensions, modifierKeys: ModifierKeys) => {
    const { isAnalysisView, changeSelectedWells, selectedWells } = this.props;
    const wellsInSelectionBox = this.getWellsInSelectionBox(selectionBoxDimensions);

    if (wellsInSelectionBox.size > 0) {
      if (isAnalysisView) {
        changeSelectedWells(wellsInSelectionBox, modifierKeys);
      } else {
        const isEqualToSelectedWells = selectedWells.equals(wellsInSelectionBox);
        const { ctrlKey, metaKey } = modifierKeys;
        if (
          (!isEqualToSelectedWells && !ctrlKey && !metaKey) ||
          (!isEqualToSelectedWells && ctrlKey) ||
          metaKey ||
          ctrlKey
        ) {
          changeSelectedWells(wellsInSelectionBox, modifierKeys);
        }
      }

      this.setState({
        highlightedWells: Set() // removed all highlighted wells
      });
    }
  };

  render() {
    const { highlightedWells } = this.state;
    const {
      plateWrapperSelector,
      verticalOffset,
      rows,
      cols,
      wells,
      wellReplicateNumberFormatter,
      selectedWells,
      editMode,
      changeSelectedWells,
      plateTargets,
      isAnalysisView,
      excludedWells,
      colHeaderOffset,
      rowHeaderOffset,
      tableSpacingLeft,
      tableSpacingTop
    } = this.props;

    return (
      <SelectionBox
        selectionChanging={this.highlightWells}
        selectionComplete={this.selectWells}
        selectionWindowSelector={plateWrapperSelector}
        verticalOffset={verticalOffset}
      >
        <Plate
          ref={this.setPlateRef}
          rows={rows}
          cols={cols}
          wells={wells}
          wellReplicateNumberFormatter={wellReplicateNumberFormatter}
          highlightedWells={highlightedWells}
          selectedWells={selectedWells}
          editMode={editMode}
          changeSelectedWells={changeSelectedWells}
          plateTargets={plateTargets}
          isAnalysisView={isAnalysisView}
          excludedWells={excludedWells}
          colHeaderOffset={colHeaderOffset}
          rowHeaderOffset={rowHeaderOffset}
          scrollableParentSelector={plateWrapperSelector}
          tableSpacingLeft={tableSpacingLeft}
          tableSpacingTop={tableSpacingTop}
        />
      </SelectionBox>
    );
  }
}

function mapStateToProps(state: ReduxState) {
  const plateTargets = getTargets(state);
  const excludedWells = getExcludedWells(state);
  return {
    plateTargets,
    excludedWells
  };
}

export default connect(mapStateToProps)(SelectionPlateImpl);
