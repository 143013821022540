export type CustomModalStyle = {
  modalStyle: string;
  primaryBtnStyle: string;
  secondaryBtnStyle: string;
};
export const modalStyleV1: CustomModalStyle = {
  modalStyle: 'own-modal',
  primaryBtnStyle: 'btn-primary',
  secondaryBtnStyle: 'btn-secondary'
};
export const modalStyleV2: CustomModalStyle = {
  modalStyle: 'modal-dialog-style-v2',
  primaryBtnStyle: 'new-btn utility',
  secondaryBtnStyle: 'new-btn ghost'
};

export const editFileStyleV2: CustomModalStyle = {
  ...modalStyleV2,
  modalStyle: 'edit-file-dialog-style-v2'
};

export const okCancelStyleV2: CustomModalStyle = {
  ...modalStyleV2,
  modalStyle: 'ok-cancel-dialog-style-v2'
};
