import React from 'react';
import PageTitle from '../../frontend-common-libs/src/components/common/page-title/PageTitle';
import { getRouteTitle, RoutePath } from '../routes';

type Props = {
  path: RoutePath;
  className: string;
};

export default function RealTimePageTitle({ path, className }: Props) {
  const routeTitle = getRouteTitle(path);
  return <PageTitle routeTitle={routeTitle} className={className} />;
}
