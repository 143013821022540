import UserAnalyticsSubscriber from '../user-analytics/UserAnalyticsSubscriber';
import { SystemEventBroker } from '../frontend-common-libs/src/system-event';
import { pageViewedSystemEventName } from '../page-viewed-system-event-publisher';
import PageViewAnalyticsEvent from '../user-analytics/PageViewAnalyticsEvent';

export default class Core {
  public static registerUserAnalyticsEvents(userAnalyticsSubscriber: UserAnalyticsSubscriber) {
    SystemEventBroker.getInstance().subscribe(userAnalyticsSubscriber);
    userAnalyticsSubscriber.registerEvent(pageViewedSystemEventName, PageViewAnalyticsEvent);
  }
}
