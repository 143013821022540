import React, { PureComponent } from 'react';
import PCRProtocolSteps from '../PCRProtocolSteps';
import PCRProtocolPlateRead from '../PCRProtocolPlateRead';
import PCRProtocolExtendTime from '../PCRProtocolExtendTime';
import ReadToggle from '../ReadToggle';
import PCRProtocolStepEditorModal from '../PCRProtocolStepEditorModal';
import EditStepContainer from '../EditStepContainer';
import { FieldValidationResult, isValidTemperature, isValidTime } from '../PCREditorValidateInput';
import QPcrStep from '../models/QPcrStep';
import { StepValue } from '../../pcr_protocol_types';
import {
  RealTimeGradientCalculator,
  ConventionalPcrGradientCalculator
} from '../gradient-calculator';
import { ProtocolStepProps as ProtocolGradientStepProps } from '../PCRProtocolStepTypes';
import IntFieldGroup from '../../../common/IntFieldGroup';
import FloatFieldGroup from '../../../common/FloatFieldGroup';
import { parseFloatIfNot } from '../../../../common/numbers';

export type State = {
  editStep: QPcrStep;
};

export class PCRProtocolGradientStepImpl extends PureComponent<ProtocolGradientStepProps, State> {
  static defaultProps = {
    selectedIndex: 0
  };

  constructor(props: ProtocolGradientStepProps) {
    super(props);

    if (props.editing) {
      this.state = { editStep: new QPcrStep(props.step) };
    }
  }

  componentWillReceiveProps(nextProps: ProtocolGradientStepProps) {
    const { editing } = this.props;
    if (nextProps.editing && !editing) {
      this.setState({ editStep: new QPcrStep(nextProps.step) });
    }
  }

  onEditSave = () => {
    const { saveEditAction, index } = this.props;
    const { editStep } = this.state;
    saveEditAction(index, editStep.map);
  };

  onEditCancel = () => {
    const { cancelEditAction } = this.props;
    cancelEditAction();
  };

  onChangeRead = (value: boolean) => {
    this.onFieldChanged('read', value);
  };

  onFieldChanged = (key: string, value?: StepValue) => {
    const { editStep } = this.state;
    const newStep = editStep.set(key, value);
    this.setState({ editStep: newStep });
  };

  selectIndex = () => {
    const { setSelectedStepIndex, index } = this.props;
    setSelectedStepIndex(index);
  };

  renderGradientCalculator(isValid: boolean) {
    const {
      editStep: { temp, grd }
    } = this.state;
    const { isPcr } = this.props;

    const lowerTemperature = parseFloatIfNot(temp);
    const gradientRange = parseFloatIfNot(grd);

    return isPcr ? (
      <ConventionalPcrGradientCalculator
        lowerTemperature={lowerTemperature}
        gradientRange={gradientRange}
        isValid={isValid}
      />
    ) : (
      <RealTimeGradientCalculator
        lowerTemperature={lowerTemperature}
        gradientRange={gradientRange}
        isValid={isValid}
      />
    );
  }

  renderControls(
    temperatureValid: FieldValidationResult,
    timeValid: FieldValidationResult,
    gradValid: FieldValidationResult,
    extValid: FieldValidationResult
  ) {
    const { isPcr } = this.props;
    const {
      editStep: { temp, time, ext, grd, read }
    } = this.state;

    const lowerTempField = (
      <FloatFieldGroup
        id="temp-edit"
        name="temp"
        label="Lower Temperature"
        subLabel="(&#176;C)"
        error={temperatureValid.helpString}
        value={temp}
        onChange={this.onFieldChanged}
        onBlur={this.onFieldChanged}
        validationState={temperatureValid.valid ? null : 'error'}
        allowNegative={false}
      />
    );
    const timeField = (
      <IntFieldGroup
        id="time-edit"
        name="time"
        label="Time"
        subLabel="(sec/cycle)"
        error={timeValid.helpString}
        value={time}
        onChange={this.onFieldChanged}
        validationState={timeValid.valid ? null : 'error'}
        allowNegative={false}
      />
    );
    const gradientField = (
      <FloatFieldGroup
        id="grad-edit"
        name="grd"
        label="Gradient"
        subLabel="(&#176;C)"
        error={gradValid.helpString}
        value={grd}
        onChange={this.onFieldChanged}
        onBlur={this.onFieldChanged}
        validationState={gradValid.valid ? null : 'error'}
        allowNegative={false}
      />
    );
    const extendField = (
      <IntFieldGroup
        id="ext-edit"
        name="ext"
        label="Extend"
        subLabel="(sec/cycle)"
        error={extValid.helpString}
        value={ext}
        onChange={this.onFieldChanged}
        validationState={extValid.valid ? null : 'error'}
      />
    );
    const readToggle = (
      <ReadToggle id="read-check" state={Boolean(read)} onChange={this.onChangeRead} />
    );

    const controls = [lowerTempField, timeField, gradientField, extendField];
    if (!isPcr) controls.push(readToggle);
    return (
      <>
        {controls.map(
          (
            c,
            i // eslint-disable-next-line react/no-array-index-key
          ) => (
            <li key={i}>
              <div className="col-xs-12 form-group ">{c}</div>
            </li>
          )
        )}
      </>
    );
  }

  renderEditStep() {
    const { isAdd, index, stepsList, editStepTypeChangeAction } = this.props;
    const {
      editStep: { temp, time, ext, grd }
    } = this.state;
    const temperatureValid = isValidTemperature(parseFloatIfNot(temp), 30, 99, true);
    const timeValid = isValidTime(time, 0, 18 * 60 * 60, true);
    const extValid = isValidTime(ext, -60, 60);

    let maxGrad = 24;
    if (temperatureValid.valid) {
      const grad = parseFloat((100 - temp).toFixed(1));
      maxGrad = Math.min(grad, 24);
    }
    const gradValid = isValidTemperature(parseFloatIfNot(grd), 1, maxGrad, true);
    const saveValid =
      temperatureValid.valid && timeValid.valid && gradValid.valid && extValid.valid;

    return (
      <PCRProtocolStepEditorModal
        onSave={saveValid ? this.onEditSave : undefined}
        onCancel={this.onEditCancel}
        isAdd={isAdd}
        index={index}
      >
        <div className="pcr-protocol-gradient-step-edit">
          <div className="edit-controls-container">
            <EditStepContainer
              currentType="gradient"
              stepsList={stepsList}
              onEditStepTypeChange={editStepTypeChangeAction}
            >
              {this.renderControls(temperatureValid, timeValid, gradValid, extValid)}
            </EditStepContainer>
          </div>
          {this.renderGradientCalculator(temperatureValid.valid && gradValid.valid)}
        </div>
      </PCRProtocolStepEditorModal>
    );
  }

  renderViewStep() {
    const { step } = this.props;
    const { tempAsString, grdTempHighAsString, timeAsString, read, ext } = new QPcrStep(step);
    const gradientStr = `Gradient ${tempAsString} / ${grdTempHighAsString} \xB0C for ${timeAsString}`;

    return (
      <div role="presentation" onClick={this.selectIndex}>
        {gradientStr}
        <PCRProtocolPlateRead render={read} />
        <PCRProtocolExtendTime extTime={ext} />
      </div>
    );
  }

  render() {
    const { editing, index, deleteAction, selectedIndex, editAction } = this.props;
    if (editing) {
      return this.renderEditStep();
    }
    return (
      <PCRProtocolSteps
        index={index}
        deleteAction={deleteAction}
        selectedIndex={selectedIndex}
        editAction={editAction}
      >
        {this.renderViewStep()}
      </PCRProtocolSteps>
    );
  }
}

export default PCRProtocolGradientStepImpl;
