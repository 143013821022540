import { Set, fromJS, List, Map } from 'immutable';
import { formatWellDisplayContent } from '../utils/microplateUtils';
import { naturalSort } from '../frontend-common-libs/src/utils/commonUtils';
import { ReplicateFormatterFunction } from './current-cfx-run-selectors';
import { Step } from '../components/pcr/analysis/models/helpers';
import { fluorMode, getWellContent } from '../components/pcr/analysis/pcrDataUtils';

export default class AnalysisResultsData {
  data: Map<string, any>;

  wellReplicateNumberFormatter: ReplicateFormatterFunction;

  targetsOrFluorsToShow: Set<string>;

  wellsToShow: Set<string>;

  sortOrder: List<any>;

  stepGroupData: Map<string, any>;

  plateWells: Map<string, any>;

  excludedWells: List<string>;

  groupMode: string;

  rawData: Map<string, any>;

  constructor(
    wellReplicateNumberFormatter: ReplicateFormatterFunction,
    targetsOrFluorsToShow: Set<string>,
    wellsToShow: Set<string>,
    sortOrder: List<any>,
    stepGroupData: Map<string, any>,
    plateWells: Map<string, any>,
    excludedWells: List<string>,
    mode: string
  ) {
    this.wellReplicateNumberFormatter = wellReplicateNumberFormatter;
    this.targetsOrFluorsToShow = targetsOrFluorsToShow;
    this.wellsToShow = wellsToShow;
    this.sortOrder = sortOrder;
    this.stepGroupData = stepGroupData;
    this.plateWells = plateWells;
    this.excludedWells = excludedWells;
    this.groupMode = mode;

    const { data, rawData } = this.getResultsData();
    this.rawData = rawData;
    this.data = data;
  }

  transformCQData = (): List<any> => {
    const filteredData = AnalysisResultsData.getFilterData(
      this.data,
      this.targetsOrFluorsToShow,
      this.wellsToShow
    );

    const dataWithNewKeys = filteredData.map((targetOrFluor: Map<string, any>) =>
      targetOrFluor.map(well => {
        const wellType = well.get('type');
        const wellReplicate = well.get('replicate');
        const wellId = well.get('well');
        const formattedReplicate = this.wellReplicateNumberFormatter(wellType, wellReplicate);
        return well
          .set('content', formatWellDisplayContent(wellType, formattedReplicate))
          .set('wellCol', `${wellId.slice(1)}${wellId.slice(0, 1)}`);
      })
    );

    return dataWithNewKeys
      .valueSeq()
      .reduce(
        (result: List<any>, wells: Map<string, any>) => result.concat(wells.valueSeq().toList()),
        List()
      );
  };

  getResultsData = () => {
    let rawData: Map<string, any> = Map<string, any>();
    let data: Map<string, any> = Map<string, any>();

    this.plateWells.forEach((well: Map<string, any>, wellName: string) => {
      if (!this.excludedWells.includes(wellName))
        well.get('fluors').forEach((fluor: Map<string, any>) => {
          const fluorName = fluor.get('name');
          const targetName = fluor.get('target');
          const keyPath = [
            this.groupMode === fluorMode ? fluorName : targetName || fluorName,
            wellName
          ];

          data = data.setIn(
            keyPath,
            fromJS({
              well: wellName,
              fluor: fluorName,
              target: targetName,
              ...getWellContent(well)
            })
          );
          const rawWellData = Step.wellData(this.stepGroupData, fluorName, wellName);

          rawData = rawData.setIn(keyPath, rawWellData);
        });
    });

    return { data, rawData };
  };

  static getDefaultSortOrder() {
    return fromJS({
      fluor: {
        key: 'fluor',
        isAscending: true
      },
      well: {
        key: 'well',
        isAscending: true
      }
    }) as List<any>;
  }

  static sortData(
    data: Map<string, any>,
    tableHeaders: Map<string, any>,
    sortOrder: Map<string, any>,
    isNumeric: (...args: Array<any>) => any
  ): Map<string, any> {
    let sortedData = data;
    sortOrder.valueSeq().forEach(columnOrder => {
      const sortKey =
        tableHeaders.findKey(value => value === columnOrder.get('key')) || columnOrder.get('key');
      const isAscending = columnOrder.get('isAscending');
      // @ts-ignore
      sortedData = naturalSort(sortedData, sortKey, isAscending, isNumeric(sortKey));
    });
    return sortedData;
  }

  /** filters the data by including targets/fluors in targetsOrFLuorsToInclude and removing the wells in the wellsToRemove */

  /** data: {
   *  <target/fluor>: {
   *    <well>: any
   *  }
   * }
   * targetsOrFluorsToInclude: the targets or fluors to include
   * wellsToInclude: the wells to remove
   * */
  static getFilterData = (
    data: Map<string, any>,
    targetsOrFluorsToInclude: Set<string>,
    wellsToInclude: Set<string>
  ) =>
    targetsOrFluorsToInclude.reduce(
      (
        result,
        targetOrFluor // add targetOrFluor in filterList
      ) =>
        result.set(
          targetOrFluor,
          wellsToInclude.reduce(
            (wells, well) =>
              data.getIn([targetOrFluor, well])
                ? wells.set(well, data.getIn([targetOrFluor, well]))
                : wells, // add wells in wellsToInclude
            Map()
          )
        ),
      Map<string, any>() // start with empty map
    );

  hasData = (): boolean =>
    !!this.rawData.find(targetsOrFluours => targetsOrFluours.find((well: any) => well !== null));
}
