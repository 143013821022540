import React from 'react';
import Header from './sections/Header';
import Info from './sections/Info';
import ThermalCyclerInfo from './sections/ThermalCyclerInfo';
import RunConditions from './sections/RunConditions';
import ErrorSummary from './sections/ErrorSummary';
import ProtocolSteps from './sections/ProtocolSteps';
import RunDetails from './sections/RunDetails';
import RunParser from './sections-parsing/RunParser';
import { flexRows } from './common/styles';

export type Props = {
  runParser: RunParser;
};

// eslint-disable-next-line react/prefer-stateless-function
export default class RunReport extends React.Component<Props> {
  render() {
    const pageStyle = {
      width: '1366px',
      // height: '1583px', //For now have long long page.
      background: 'white',
      padding: '20px',
      fontFamily: 'Rubik',
      ...flexRows
    };

    const { runParser } = this.props;
    // Note: all the components in the run report use style and not className. This is required for the styling in the report using jspdf
    return (
      <div id="run-report" style={pageStyle}>
        <Header header={runParser.headerParser} />
        <Info info={runParser.infoParser} />
        <ThermalCyclerInfo instrumentDetails={runParser.thermalCyclerInfoParser} />
        <RunConditions runConditions={runParser.runConditionsParser} />
        <ErrorSummary error={runParser.errorSummaryParser} />
        <ProtocolSteps steps={runParser.protocolStepsParser} />
        <RunDetails rows={runParser.runDetailsParser} />
      </div>
    );
  }
}
