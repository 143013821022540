import React, { PureComponent, RefObject } from 'react';
import { Wrapper, Button, Menu, closeMenu } from 'react-aria-menubutton';
import ToolbarButton from '../../../../../frontend-common-libs/src/components/common/buttons/ToolbarButton';
import gearIcon from '../../../../../../img/gear.svg';
import PopoverAnalysis from './PopoverAnalysis';

type Props = {
  disabled: boolean;
  isMeltStepSelected: boolean;
};

export default class AnalysisSetup extends PureComponent<Props> {
  static WRAPPERID = 'analysis-settings';

  buttonRef: RefObject<any> = React.createRef();

  handlePopoverBlur = (event: any) => {
    const { buttonRef, handleClose } = this;
    const { target } = event;
    if (buttonRef && buttonRef.current && !buttonRef.current.contains(target)) {
      handleClose();
    }
  };

  handleClose = () => {
    closeMenu(AnalysisSetup.WRAPPERID);
  };

  render() {
    const { disabled, isMeltStepSelected } = this.props;
    return (
      <Wrapper id={AnalysisSetup.WRAPPERID} className="analysis-setup" closeOnBlur={false}>
        <div ref={this.buttonRef}>
          <Button
            id={`${AnalysisSetup.WRAPPERID}-button`}
            className="new-btn-icon"
            title="Adjust analysis settings for the run."
            disabled={disabled}
          >
            <ToolbarButton icon={gearIcon} imgAlt="gear icon">
              Analysis Setup
            </ToolbarButton>
          </Button>
        </div>
        <Menu>
          <PopoverAnalysis
            onClose={this.handleClose}
            onBlur={this.handlePopoverBlur}
            isMeltStepSelected={isMeltStepSelected}
          />
        </Menu>
      </Wrapper>
    );
  }
}
