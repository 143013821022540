import React from 'react';
import HorizontalLine from '../common/HorizontalLine';
import ReportTable, { TableModel } from '../common/ReportTable';
import SectionHeader from '../common/SectionHeader';
import RunConditionsParser from '../sections-parsing/RunConditionsParser';

type Props = {
  runConditions: RunConditionsParser;
};
export default function RunConditions(props: Props) {
  const { runConditions } = props;
  const tableModel = new TableModel();
  tableModel.header1 = 'Protocol Name';
  tableModel.header2 = 'Plate ID';
  tableModel.header3 = 'Sample Volume';
  tableModel.header4 = 'Method';
  tableModel.data1 = runConditions.protocolName;
  tableModel.data2 = runConditions.plateId;
  tableModel.data3 = runConditions.volume;
  tableModel.data4 = runConditions.method;

  const statusTableModel = new TableModel();
  statusTableModel.header1 = 'Lid Temperature';
  statusTableModel.data1 = runConditions.lidTemp;

  return (
    <div id="run-condition">
      <SectionHeader text="Run Conditions" />
      <ReportTable tableModel={tableModel} tableName="run-condition-1" />
      <ReportTable
        tableModel={statusTableModel}
        tableStyle={{ marginTop: '30px', marginBottom: '44px' }}
        tableName="run-condition-2"
      />
      <HorizontalLine />
    </div>
  );
}
