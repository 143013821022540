import { matchPath } from 'react-router';
import uuidRegex from '../../frontend-common-libs/src/common/routes/regex';

type RouteName = string;
type RoutePattern = string;
type RoutePath = string;
type RouteTitle = string;

const PCR = '/pcr';
const PCR_PROTOCOL = `${PCR}/protocol`;
const PCR_PROTOCOL_LIST: RouteName = `${PCR_PROTOCOL}/list`;
const CREATE_PCR_PROTOCOL: RouteName = `${PCR_PROTOCOL}/create`;
const EDIT_PCR_PROTOCOL: RouteName = `${PCR_PROTOCOL}/:entityId(${uuidRegex})`;
const VIEW_PCR: RouteName = `${PCR}/:entityId(${uuidRegex})`;
const VIEW_PCR_RUN_DETAILS = `${VIEW_PCR}/details`;
const VIEW_PCR_RUN_REPORT = `${VIEW_PCR}/report`;
const VIEW_PCR_RUN_PROTOCOL = `${VIEW_PCR}/protocol`;

const namedRoutes: { [key: string]: RoutePattern } = {
  PCR_PROTOCOL_LIST,
  CREATE_PCR_PROTOCOL,
  PCR_PROTOCOL,
  EDIT_PCR_PROTOCOL,
  VIEW_PCR,
  PCR,
  VIEW_PCR_RUN_DETAILS,
  VIEW_PCR_RUN_REPORT,
  VIEW_PCR_RUN_PROTOCOL
};
export const ROUTE_NOT_FOUND = '/*';

export function getRouteName(path: RoutePath): RouteName {
  return (
    Object.keys(namedRoutes).find(
      name =>
        matchPath(path, {
          path: namedRoutes[name],
          exact: true,
          strict: false
        }) !== null
    ) || 'ROUTE_NOT_FOUND'
  );
}

const routeTitles: { [key: string]: RouteTitle } = {
  PCR_PROTOCOL_LIST: 'PTC Protocols',
  CREATE_PCR_PROTOCOL: 'Create PTC Protocol',
  EDIT_PCR_PROTOCOL: 'PTC Protocol',
  VIEW_PCR_RUN_DETAILS: 'PTC Run',
  VIEW_PCR_RUN_REPORT: 'PTC Run',
  VIEW_PCR_RUN_PROTOCOL: 'PTC Run'
};

export function getPageTitle(routePath: RoutePath): RouteTitle {
  return routeTitles[getRouteName(routePath)] || '';
}

export function getRoute(routeName: string): string {
  return namedRoutes[routeName] || ROUTE_NOT_FOUND;
}

export default namedRoutes;
