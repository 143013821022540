import React from 'react';
import { numToChar } from '../../../../../utils/commonUtils';

export type Props = {
  rowIndex: number;
  value: string;
  backgroundColor: string;
  color: string;
};

function GradientRow(props: Props) {
  const { rowIndex, value, backgroundColor, color } = props;
  const label = numToChar(rowIndex + 1);
  return (
    <div className="row-item">
      <div id={`gradient-row-label-${rowIndex}`}>{label}</div>
      <div id={`gradient-row-value-${rowIndex}`} style={{ backgroundColor, color }}>
        {value}
      </div>
    </div>
  );
}

export default GradientRow;
