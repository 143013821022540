import React from 'react';
import RectangularOverflowSkeletonLoader from './RectangularOverflowSkeletonLoader';
import styles from './styles/recent-file-row-skeleton-loader.module.scss';
import FileIconSkeletonLoader from './FileIconSkeletonLoader';

export default function RecentFileRowSkeletonLoader() {
  return (
    <tr className={styles.fileRow}>
      <td className={styles.fileIconCol}>
        <FileIconSkeletonLoader rx="0" ry="0" />
      </td>
      <td className={styles.fileNameCol}>
        <RectangularOverflowSkeletonLoader />
      </td>
      <td className={styles.fileTypeCol}>
        <RectangularOverflowSkeletonLoader />
      </td>
      <td className={styles.fileLastUpdatedCol}>
        <RectangularOverflowSkeletonLoader />
      </td>
    </tr>
  );
}
