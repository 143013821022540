import React from 'react';

import {
  endTemps,
  makePath,
  makeTextAboveLine,
  makeTextBelowLine,
  makeCamera,
  StepProps,
  Point
} from './common';
import QPcrStep from '../models/QPcrStep';

function makeLineData(p: number, x1: number, x2: number, x3: number, y: number) {
  return [
    { x: x1, y: p },
    { x: x2, y },
    { x: x3, y }
  ];
}

function GradStep(props: StepProps) {
  const { step, yScale, stepWidth, prev, index } = props;
  const { temp, tempAsString, grdTempHigh, grdTempHighAsString, timeAsString, read } = new QPcrStep(
    step
  );

  const y1 = yScale(temp);
  const y2 = yScale(grdTempHigh);
  const x1 = 0.3;
  const x2 = 0.2 * stepWidth;
  const x3 = stepWidth;
  const prevPoints: number[] = endTemps(prev).map(yScale);
  const lineData: Point[][] = [];

  lineData.push(makeLineData(prevPoints[0], x1, x2, x3, y1));
  if (prevPoints.length === 2) {
    lineData.push(makeLineData(prevPoints[1], x1, x2, x3, y2));
  } else {
    lineData.push(makeLineData(prevPoints[0], x1, x2, x3, y2));
  }

  return (
    <>
      {lineData.map(makePath)}
      {makeTextAboveLine(
        stepWidth / 2,
        y2,
        `${grdTempHighAsString} ºC`,
        `graph-steptemp-high-${index + 1}`
      )}
      {makeTextBelowLine(
        stepWidth / 2,
        y1,
        `${tempAsString} ºC`,
        `graph-steptemp-low-${index + 1}`,
        'temperature-label'
      )}
      {makeTextBelowLine(stepWidth / 2, y1 + 18, timeAsString, `graph-steptime-${index + 1}`)}
      {read && makeCamera(index, temp, stepWidth / 2, yScale)}
    </>
  );
}

GradStep.defaultProps = {
  prev: undefined
};

GradStep.displayName = 'GradientStep';

export default GradStep;
