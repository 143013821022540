import 'core-js/es/symbol';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import store from './store';
import App from './components/main';
import { withFlagLoader } from './frontend-common-libs/src/components/feature_flags';
import withPageViewSystemEventPublisher from './page-viewed-system-event-publisher';
import UserAnalyticsSubscriber from './user-analytics/UserAnalyticsSubscriber';
import withSystemHealthLoader from './components/health_loader';
import '../scss/app.scss';
import Core from './core';
import notification from './frontend-common-libs/src/utils/notifications';

const userAnalyticsSubscriber = UserAnalyticsSubscriber.getInstance();
Core.registerUserAnalyticsEvents(userAnalyticsSubscriber);
notification.registerEvent();

const wrappedApp = withFlagLoader(withSystemHealthLoader(withPageViewSystemEventPublisher(App)));

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <Switch>
        <Route component={wrappedApp} />
      </Switch>
    </BrowserRouter>
  </Provider>,
  document.querySelector('.wrapper')
);
