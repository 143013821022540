import { fromJS, Map, OrderedMap } from 'immutable';
import {
  USERFILES_ADDED,
  USERFILES_ARCHIVED,
  USERFILES_DELETED,
  USERFILES_ERROR,
  USERFILES_LOADED,
  USERFILES_LOADING,
  USERFILES_PROCESSING_COMPLETE,
  USERFILES_RENAMED,
  USERFILES_RESTORED,
  USERFILES_SEARCH_CLEARED,
  USERFILES_SEARCH_INPUT_CHANGED,
  USERFILES_SEARCH_LOADED,
  USERFILES_SEARCH_LOADING,
  USERFILES_SEARCHED
} from '../actions/action-types';
import { QPCRDATA_RUN_ADDED, QPCRDATA_RUN_EDITED } from '../../actions/qpcrdata_types';
import {
  FILE_OPERATION_COMPLETE,
  FileType,
  FILETYPE_CFX_RUN_TEMPLATE,
  FILETYPE_PENDING_CFX_RUN,
  FILETYPE_UPLOAD
} from '../../frontend-common-libs/src/common/userfiles_common';
import { UNAUTH_USER } from '../../auth/actions/auth_types';
import { jsArrayToOrderedMap } from '../../frontend-common-libs/src/utils/immutableUtils';
import { ApiAction } from '../../types';
import { sortEntitiesByLastUpdated } from '../../frontend-common-libs/src/utils/rowEntityUtils';
import { CFXRUN_TEMPLATE_ADDED } from '../../actions/cfx_run_template_types';
import { PROJECT_SELECTED } from '../../project-management';
import { ImmutableMap, UserFile } from '../../frontend-common-libs/src/common/types';
import {
  DEFAULT_PROJECT_ID,
  ProjectId
} from '../../frontend-common-libs/src/common/project-management-types';
import { EXCLUDED_FILES } from '../actions/actions';

export type UserFilesForProject = ImmutableMap<{
  isLoading: boolean;
  filesLoaded: boolean;
  staleData: boolean;
  files: OrderedMap<string, ImmutableMap<UserFile>>;
  filesSearched: OrderedMap<string, ImmutableMap<UserFile>>;
  errorMessage: string;
  lastSeen: string;
  searchText: string;
  isSearching: boolean;
}>;

export type UserFilesState = ImmutableMap<{
  projects: Map<string, UserFilesForProject>;
}>;

function getFileTypeFromEntityType(entityType: string) {
  let fileType: FileType;
  switch (entityType) {
    case QPCRDATA_RUN_ADDED:
      fileType = FILETYPE_PENDING_CFX_RUN;
      break;
    case CFXRUN_TEMPLATE_ADDED:
      fileType = FILETYPE_CFX_RUN_TEMPLATE;
      break;
    default:
      fileType = FILETYPE_UPLOAD;
  }
  return fileType;
}

function createFile(type: string, fileId: string, fileName: string, projectId: string) {
  const fileType: FileType = getFileTypeFromEntityType(type);
  return {
    id: fileId,
    updated: new Date().toISOString(),
    status: FILE_OPERATION_COMPLETE,
    name: fileName,
    created: new Date().toISOString(),
    type: fileType,
    parent_id: projectId
  };
}

// @ts-ignore
const INITIAL_STATE_FOR_PROJECT: UserFilesForProject = fromJS({
  isLoading: false,
  filesLoaded: false,
  staleData: true,
  files: OrderedMap({}),
  filesSearched: OrderedMap({}),
  errorMessage: '',
  lastSeen: ''
});
// @ts-ignore
const INITIAL_STATE: UserFilesState = fromJS({
  projects: {
    [DEFAULT_PROJECT_ID]: INITIAL_STATE_FOR_PROJECT
  }
});

function getFiles(state: Map<string, any>, projectId: string): OrderedMap<string, any> {
  return state.getIn(['projects', projectId, 'files']) as OrderedMap<string, any>;
}

const onFilesLoading = (state: UserFilesState, action: ApiAction<any, any>) => {
  const { projectId } = action.payload;
  return state
    .setIn(['projects', projectId, 'isLoading'], true)
    .setIn(['projects', projectId, 'errorMessage'], '');
};

const onFileRenamed = (state: UserFilesState, action: ApiAction<any, any>) => {
  const { faEntity } = action.payload;
  const faId = faEntity.id;
  const projectId = faEntity.parent_id;
  let renamedFile = state.getIn(['projects', projectId, 'files', faId]);
  if (!renamedFile) {
    return state;
  }
  // @ts-ignore
  renamedFile = fromJS(faEntity);
  // add the renamed file to the top of the list
  const files: OrderedMap<string, ImmutableMap<UserFile>> = OrderedMap<ImmutableMap<UserFile>>({})
    // @ts-ignore
    .set(faId, renamedFile)
    .merge(getFiles(state, projectId).delete(faId));
  return state.setIn(['projects', projectId, 'files'], files);
};

function addFile(newFile: UserFile, state: UserFilesState) {
  const projectId = newFile.parent_id;
  const files = getFiles(state, projectId);
  if (files == null) return state;
  return state.setIn(
    ['projects', projectId, 'files'],
    jsArrayToOrderedMap([newFile], 'id').merge(files)
  );
}

function setOnFileAdded(
  faId: string,
  state: UserFilesState,
  projectId: string,
  action: ApiAction<any, any>,
  name: string
) {
  if (!faId || !state.getIn(['projects', projectId, 'filesLoaded'])) {
    return state;
  }
  const { type } = action;
  // @ts-ignore
  const newFile: UserFile = createFile(type, faId, name, projectId);
  return addFile(newFile, state);
}

function projectExistsInState(state: UserFilesState, projectId: ProjectId): boolean {
  return state.getIn(['projects', projectId]) != null;
}

const onCfxRunTemplateAdded = (state: UserFilesState, action: ApiAction<any, any>) => {
  const { faId, name, projectId } = action.payload;
  return setOnFileAdded(faId, state, projectId, action, name);
};

const onFileAdded = (state: UserFilesState, action: ApiAction<any, any>) => {
  const { faEntity, name } = action.payload;
  const projectId = faEntity.parent_id;
  const faId = faEntity.id;
  return setOnFileAdded(faId, state, projectId, action, name);
};

const onFilesError = (state: UserFilesState, action: ApiAction<any, any>) => {
  const { message, projectId } = action.payload;
  return state
    .setIn(['projects', projectId, 'isLoading'], false)
    .setIn(['projects', projectId, 'errorMessage'], message);
};

const onFilesDeleted = (state: UserFilesState, action: ApiAction<any, any>) => {
  const { fileList: deletedList, projectId } = action.payload;
  let files = getFiles(state, projectId);
  if (files == null) return state;
  deletedList.forEach((id: string) => {
    files = files.delete(id);
  });
  return state.setIn(['projects', projectId, 'files'], files);
};

function deleteFile(file: UserFile, state: UserFilesState) {
  const projectId = file.parent_id;
  let files = getFiles(state, projectId);
  if (files == null) return state;
  files = files.delete(file.id);
  return state.setIn(['projects', projectId, 'files'], files);
}

const onFilesArchived = (state: UserFilesState, action: ApiAction<any, any>) => {
  const { entities: archivedList } = action.payload;
  let newState = state;
  archivedList.forEach((entity: UserFile) => {
    newState = deleteFile(entity, newState);
  });
  return newState;
};

const onFilesLoaded = (state: UserFilesState, action: ApiAction<any, any>) => {
  const { entities: files, projectId } = action.payload;
  // add the files to end of the list
  const newFiles = getFiles(state, projectId).merge(jsArrayToOrderedMap<UserFile>(files, 'id'));
  return state
    .setIn(['projects', projectId, 'isLoading'], false)
    .setIn(['projects', projectId, 'filesLoaded'], true)
    .setIn(['projects', projectId, 'staleData'], false)
    .setIn(['projects', projectId, 'files'], sortEntitiesByLastUpdated(newFiles))
    .setIn(['projects', projectId, 'errorMessage'], '')
    .setIn(
      ['projects', projectId, 'lastSeen'],
      action.payload.lastSeen ? action.payload.lastSeen : ''
    );
};

const onUserFilesSearchLoading = (state: UserFilesState, action: ApiAction<any, any>) => {
  const { projectId } = action.payload;
  return state
    .setIn(['projects', projectId, 'filesSearched'], OrderedMap({}))
    .setIn(['projects', projectId, 'isLoading'], true)
    .setIn(['projects', projectId, 'errorMessage'], '');
};

const onUserFilesSearchLoaded = (state: UserFilesState, action: ApiAction<any, any>) => {
  const { entities: files, projectId } = action.payload;
  // add the files to end of the list
  // @ts-ignore
  const newFilesSearched = state
    .getIn(['projects', projectId, 'filesSearched'])
    .merge(jsArrayToOrderedMap<UserFile>(files, 'id'));
  return state
    .setIn(['projects', projectId, 'isLoading'], false)
    .setIn(['projects', projectId, 'filesLoaded'], true)
    .setIn(['projects', projectId, 'staleData'], false)
    .setIn(['projects', projectId, 'filesSearched'], sortEntitiesByLastUpdated(newFilesSearched))
    .setIn(['projects', projectId, 'errorMessage'], '')
    .setIn(
      ['projects', projectId, 'lastSeen'],
      action.payload.lastSeen ? action.payload.lastSeen : ''
    );
};

const onFileProcessingComplete = (state: UserFilesState, action: ApiAction<any, any>) => {
  const { entity } = action.payload;
  const { id } = entity;
  const projectId = entity.parent_id;
  // if files not loaded, do not add the file
  if (!state.getIn(['projects', projectId, 'filesLoaded'])) {
    return state;
  }
  return state.setIn(
    ['projects', projectId, 'files'],
    jsArrayToOrderedMap([entity], 'id').merge(getFiles(state, projectId).delete(id)) // add or move to the top
  );
};

const onArchiveRestored = (state: UserFilesState, action: ApiAction<any, any>) => {
  const { entities } = action.payload;
  let newState = state;
  entities.forEach((entity: UserFile) => {
    if (!EXCLUDED_FILES.includes(entity.type)) newState = addFile(entity, newState);
  });
  return newState;
};

const onSearchInputChanged = (state: UserFilesState, action: ApiAction<any, any>) => {
  const { searchText, projectId } = action.payload;
  return state.setIn(['projects', projectId, 'searchText'], searchText);
};

const onSearched = (state: UserFilesState, action: ApiAction<any, any>) => {
  const { projectId } = action.payload;
  return state
    .setIn(['projects', projectId, 'files'], OrderedMap({}))
    .setIn(['projects', projectId, 'isSearching'], true)
    .setIn(['projects', projectId, 'lastSeen'], '');
};

const onSearchCleared = (state: UserFilesState, action: ApiAction<any, any>) => {
  const { projectId } = action.payload;
  return state
    .setIn(['projects', projectId, 'searchText'], '')
    .setIn(['projects', projectId, 'isSearching'], false)
    .setIn(['projects', projectId, 'isLoading'], true);
};

const resetState = () => INITIAL_STATE;

function onChangeProjectSelection(state: UserFilesState, action: any) {
  const { projectId } = action.payload;
  const projectExists = projectExistsInState(state, projectId);
  if (!projectExists) return state.setIn(['projects', projectId], INITIAL_STATE_FOR_PROJECT);
  return state;
}

const actionMap = {
  [USERFILES_LOADING]: onFilesLoading, // Querying user file list
  [USERFILES_LOADED]: onFilesLoaded, // User file list query returned successfully
  [USERFILES_DELETED]: onFilesDeleted, // Removed a user file
  [USERFILES_ARCHIVED]: onFilesArchived, // Archived a user file
  [USERFILES_ERROR]: onFilesError, // User file query failed
  [USERFILES_RENAMED]: onFileRenamed, // File renamed
  [USERFILES_PROCESSING_COMPLETE]: onFileProcessingComplete, // File event received from iot
  [QPCRDATA_RUN_ADDED]: onFileAdded, // new CFX run added
  [USERFILES_ADDED]: onFileProcessingComplete, // new PTC run added
  [CFXRUN_TEMPLATE_ADDED]: onCfxRunTemplateAdded, // new CFX run template added
  [QPCRDATA_RUN_EDITED]: onFileRenamed, // CFX run edited
  [USERFILES_RESTORED]: onArchiveRestored, // Entity restored from archive
  [USERFILES_SEARCH_INPUT_CHANGED]: onSearchInputChanged,
  [USERFILES_SEARCH_CLEARED]: onSearchCleared,
  [USERFILES_SEARCHED]: onSearched,
  [UNAUTH_USER]: resetState, // User logged out
  [PROJECT_SELECTED]: onChangeProjectSelection,
  [USERFILES_SEARCH_LOADING]: onUserFilesSearchLoading,
  [USERFILES_SEARCH_LOADED]: onUserFilesSearchLoaded
};

export default function userfilesReducer(
  state: UserFilesState = INITIAL_STATE,
  action: ApiAction<any, any> | undefined = undefined
): UserFilesState {
  if (action) {
    return actionMap[action.type] ? actionMap[action.type](state, action) : state;
  }
  return state;
}
