export type StepValue = string | number | boolean;

export type Step = {
  type: string;
  temp?: number;
  time?: number;
  read?: boolean;
  step?: number;
  cycles?: number;
  grd?: number;
  ext?: number;
  end?: number;
  inc?: number;
  interval?: number;
  ramp?: number;
  beep?: boolean;
};

export const LidSettingCustom = 'custom';
export const LidSettingDefault = 'default';
export const LidSettingOff = 'off';
export type LidSetting = 'default' | 'custom' | 'off';

export type PcrProtocolContent = {
  vol: number;
  lidTemp: { mode: LidSetting; temp?: number };
  steps: Step[];
};

export type QPCRProtocol = PcrProtocolContent & {
  filename: string;
};
