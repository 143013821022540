import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { List, Map, Set } from 'immutable';

import PlateErrors from './PlateErrors';
import { updatePlate as updatePlateAction } from '../../../actions/currentCfxRun_actions';
import { ReduxState } from '../../../types';
import WellEditor from './WellEditor';
import SamplesInputList from './SamplesInputList';
import { getRunPlateErrors } from '../../../selectors/current-cfx-run-selectors';

export type PlateEditorProps = {
  plate: Map<string, any>;
  selectedWellIds: Set<string>;
  updatePlate: (plate: Map<string, any>, plateErrors: Map<string, any>) => void;
  plateErrors: Map<string, any>;
  samplePlaceholders?: List<Map<string, any>>;
};

type PlateEditorState = {};

export class PlateEditorImpl extends PureComponent<PlateEditorProps, PlateEditorState> {
  renderWellEditor = () => {
    const { plate, selectedWellIds, plateErrors, updatePlate } = this.props;
    return (
      <WellEditor
        plate={plate}
        selectedWellIds={selectedWellIds}
        plateErrors={plateErrors}
        updatePlate={updatePlate}
      />
    );
  };

  render() {
    return (
      <div id="plate-edit-controls" className="plate-edit-controls">
        <h2>Plate Setup</h2>
        <PlateErrors />
        {this.renderEditor()}
      </div>
    );
  }

  private renderEditor() {
    const { samplePlaceholders } = this.props;
    if (samplePlaceholders) {
      return this.renderSampleInputList(samplePlaceholders);
    }
    return this.renderWellEditor();
  }

  private renderSampleInputList(samplePlaceholders: List<Map<string, any>>) {
    const { updatePlate, plate } = this.props;

    return (
      <SamplesInputList
        samplePlaceholders={samplePlaceholders}
        updatePlate={updatePlate}
        plate={plate}
      />
    );
  }
}

function mapStateToProps(state: ReduxState) {
  const plateErrors = getRunPlateErrors(state.currentCfxRun);
  const samplePlaceholders = state.currentCfxRun.get('samplePlaceholders');
  return {
    plateErrors,
    samplePlaceholders
  };
}
// @ts-ignore
export default connect(mapStateToProps, { updatePlate: updatePlateAction })(PlateEditorImpl);
