import React, { Component } from 'react';
import { Set } from 'immutable';
import { WrappedFieldProps } from 'redux-form';
import CountriesRepository from '../../utils/CountriesRepository';
import AutoSuggest from '../pcr/wellediting/AutoSuggest';
import FeatureFlags, {
  FeatureFlagKeys
} from '../../frontend-common-libs/src/components/feature_flags';
import NewAutoSuggest, { SearchStringFormatter } from '../../common/autosuggest';

type Props = {
  label: string;
} & WrappedFieldProps;

type State = {
  countries: Set<string>;
};

export function countryRequired(value: string) {
  if (!value) {
    return 'Required';
  }
  const isCountryValid = CountriesRepository.getCode(value);
  if (!isCountryValid) {
    return 'Country is not valid';
  }
  return undefined;
}

class CountryDropdown extends Component<Props, State> {
  _countriesList: Set<string>;

  constructor(props: Props) {
    super(props);
    this.state = {
      countries: Set<string>()
    };

    this._countriesList = CountriesRepository.getOrderedCountrySet();
  }

  componentDidMount() {
    this.loadCountries();
  }

  loadCountries = () => {
    this.setState({
      countries: this._countriesList
    });
  };

  render() {
    const {
      label,
      input: originalInput,
      meta: { error, touched, active }
    } = this.props;
    const { countries } = this.state;
    const { value, ...input } = originalInput;
    let fieldClass = active ? 'country-form-active' : 'country-form';
    if (touched && error) {
      fieldClass = active ? 'country-form-error-active' : 'country-form-error';
    }
    return (
      <div>
        <div className="form-label-flex">
          <div id="country-form-label">{label}</div>
          <div id={`${input.name}-error`} className="form-label-error-flex">
            {touched && error && <span>{error}</span>}
          </div>
        </div>
        {FeatureFlags().get(FeatureFlagKeys.NEW_AUTOSUGGEST) ? (
          <NewAutoSuggest searchStringFormatter={SearchStringFormatter} />
        ) : (
          <AutoSuggest
            placeholder={' '}
            className={fieldClass}
            id="country-form-dropdown"
            keywords={countries}
            value={value}
            maxResults={this._countriesList.size}
            {...input}
          />
        )}
      </div>
    );
  }
}

export default CountryDropdown;
