import React, { Component, ReactElement, ComponentType } from 'react';
import Dropzone, { DropzoneRef } from 'react-dropzone';
import { Button } from 'react-bootstrap';
import classnames from 'classnames';

const DROPZONE_HINT = 'Drop files to upload';

export type Props = {
  uploadFiles: (files: File[]) => Promise<any>;
  showEmpty: boolean;
  className?: string;
};
type State = { dropzoneActive: boolean };

function withDropZone<WrappedComponentProps extends object>(
  WrappedComponent: ComponentType<WrappedComponentProps>
) {
  type WithDropZoneProps = Props & WrappedComponentProps;

  class WithDropZone extends Component<WithDropZoneProps, State> {
    static defaultProps = {
      className: undefined
    };

    dropZoneRef: DropzoneRef | null | undefined;

    constructor(props: WithDropZoneProps) {
      super(props);

      this.state = {
        dropzoneActive: false
      };
    }

    private getDropZoneRef = (node: DropzoneRef): void => {
      this.dropZoneRef = node;
    };

    private dragEnterHandler = (): void => {
      this.setState({
        dropzoneActive: true
      });
    };

    private dragLeaveHandler = (): void => {
      this.setState({
        dropzoneActive: false
      });
    };

    dropHandler = (files: File[]): void => {
      const { uploadFiles } = this.props;
      uploadFiles(files);
      this.dragLeaveHandler();
    };

    browseFiles = () => {
      if (this.dropZoneRef) {
        this.dropZoneRef.open();
      }
    };

    private addClassFromProps = (className: string | null | undefined = undefined) => {
      const { className: currentClass } = this.props;
      return classnames(className, currentClass);
    };

    renderOverlayedComponent = (passThroughProps: WrappedComponentProps): ReactElement => (
      <div className={this.addClassFromProps('drop-zone-own-wrapper')}>
        <div className="drop-zone-overlay drop-zone-overlay-bg">
          <div className="drop-zone-valign">
            <i className="fas fa-cloud-upload-alt fa-4x" />
            <p>{DROPZONE_HINT}</p>
          </div>
        </div>
        <WrappedComponent {...passThroughProps} />
      </div>
    );

    renderEmptyComponent = (passThroughProps: WrappedComponentProps): ReactElement => (
      <div className={this.addClassFromProps('drop-zone-own-wrapper')}>
        <div className="drop-zone-overlay">
          <div className="drop-zone-valign drop-zone-empty">
            <i className="fas fa-cloud-upload-alt fa-4x" />
            <p>
              <Button
                id="drop-zone-choose-files"
                bsClass="drop-zone-choose-btn btn-link"
                onClick={this.browseFiles}
              >
                Choose files
              </Button>{' '}
              or Drag here
            </p>
          </div>
        </div>
        <WrappedComponent {...passThroughProps} />
      </div>
    );

    renderComponent(passThroughProps: WrappedComponentProps): ReactElement {
      const { showEmpty } = this.props;
      const { dropzoneActive } = this.state;
      if (dropzoneActive) {
        return this.renderOverlayedComponent(passThroughProps);
      }
      if (showEmpty) {
        return this.renderEmptyComponent(passThroughProps);
      }
      return <WrappedComponent {...passThroughProps} />;
    }

    render() {
      const { uploadFiles, showEmpty, ...passThroughProps } = this.props;

      return (
        <div className={this.addClassFromProps('dropzone')} title={DROPZONE_HINT} id="dropzone">
          <Dropzone
            noClick
            multiple
            noKeyboard
            onDrop={this.dropHandler}
            onDragEnter={this.dragEnterHandler}
            onDragLeave={this.dragLeaveHandler}
            ref={this.getDropZoneRef}
          >
            {({ getInputProps, getRootProps }) => {
              return (
                <div
                  {...getRootProps({
                    className: 'flex-column-container'
                  })}
                >
                  <input {...getInputProps({ id: 'dropzone', name: 'dropzoneinput' })} />
                  {this.renderComponent(passThroughProps as WrappedComponentProps)}
                </div>
              );
            }}
          </Dropzone>
        </div>
      );
    }
  }
  return WithDropZone;
}

export default withDropZone;
