import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Map, Seq } from 'immutable';
import Table from '../../frontend-common-libs/src/components/common/tables/Table';
import THead from '../../frontend-common-libs/src/components/common/tables/THead';
import TCell from '../../frontend-common-libs/src/components/common/tables/TCell';
import LoadableTBody from '../../frontend-common-libs/src/components/common/tables/LoadableTBody';
import { updateSelectedEntities } from '../../frontend-common-libs/src/utils/rowEntityUtils';
import {
  archiveUserFiles as archiveUserFilesAction,
  generatePcrd as generatePcrdAction,
  renameFile as renameFileAction,
  downloadFile
} from '../../file-management/actions/actions';
import { InstrumentFamilyPluginRepository } from '../../instrument-family-plugin';
import { UserFile } from '../../frontend-common-libs/src/common/types';
import withCanOpenFile from './withCanOpenFile';

export type Props = {
  list: Seq.Indexed<Map<string, any>>;
  fetchMoreData: (...args: Array<any>) => any;
  hasMoreData: boolean;
  isLoading: boolean;
  isError: boolean;
  archiveUserFiles: typeof archiveUserFilesAction;
  renameFile: typeof renameFileAction;
  generatePcrd: typeof generatePcrdAction;
  moveFile?: (entity: UserFile) => unknown;
  fileHistory?: (entity: UserFile) => unknown;
  canEditProjectFiles?: boolean;
};
export type State = {
  selectedRows: Array<string>;
};
// FileTable must be a component and not a pure function because it has a nested component (LoadableTBody) which holds the tableBodyRef
// eslint-disable-next-line react/prefer-stateless-function
class FileTable extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      selectedRows: []
    };
  }

  renderFileRow = (
    item: Map<string, any>,
    tableBodyRef: any,
    archiveUserFiles: typeof archiveUserFilesAction,
    renameFile: typeof renameFileAction,
    generatePcrd: typeof generatePcrdAction
  ) => {
    const { moveFile, fileHistory, canEditProjectFiles } = this.props;
    const plugin =
      InstrumentFamilyPluginRepository.getInstance().getInstrumentFamilyPluginForFile(item);

    if (plugin) {
      const FileRow = plugin.name?.includes('image-lab-touch')
        ? withCanOpenFile(withRouter(plugin.getFileRow()))
        : plugin.getFileRow();

      return (
        <FileRow
          key={item.get('id')}
          // @ts-ignore
          item={item}
          onSelectedRow={this.onRowSelected}
          archiveUserFiles={archiveUserFiles}
          tableBodyRef={tableBodyRef}
          renameFile={renameFile}
          generatePcrd={generatePcrd}
          downloadFile={downloadFile}
          moveFile={moveFile}
          fileHistory={fileHistory}
          canEditProjectFiles={canEditProjectFiles}
        />
      );
    }

    return null;
  };

  render() {
    const {
      list,
      fetchMoreData,
      hasMoreData,
      isLoading,
      isError,
      archiveUserFiles,
      renameFile,
      generatePcrd
    } = this.props;

    return (
      <div className="flex-column-container" id="files-table-container">
        <Table className="flex-column-container base-table" id="files-table">
          <THead>
            <TCell />
            <TCell>NAME</TCell>
            <TCell>LAST UPDATED</TCell>
            <TCell>TYPE</TCell>
            <TCell />
          </THead>
          <LoadableTBody
            fetchMoreData={fetchMoreData}
            hasMoreData={hasMoreData}
            isLoading={isLoading}
            isError={isError}
            errorMsg="Error retrieving files."
          >
            {(tableBodyRef: any) =>
              list.map(item =>
                this.renderFileRow(item, tableBodyRef, archiveUserFiles, renameFile, generatePcrd)
              )
            }
          </LoadableTBody>
        </Table>
      </div>
    );
  }

  private onRowSelected = (rowId: string) => {
    const { selectedRows } = this.state;
    this.setState({ selectedRows: updateSelectedEntities(selectedRows, rowId) });
  };
}

export default FileTable;
