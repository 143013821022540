import AWSIot from 'aws-iot-device-sdk';

export type TopicStub = 'get' | 'update' | 'delete';
export default class InstrumentNamedShadow {
  private thingShadow: AWSIot.thingShadow;

  constructor(websocket: AWSIot.thingShadow) {
    this.thingShadow = websocket;
  }

  public updateNamedShadow(
    instrumentId: string,
    shadowName: string,
    desiredState: Record<string, unknown>
  ): void {
    const desiredStateFormat = JSON.stringify(desiredState);
    this.publishToNamedShadow(instrumentId, shadowName, 'update', desiredStateFormat);
  }

  public subscribeToNamedShadow(
    instrumentId: string,
    shadowName: string,
    topicStub: TopicStub,
    callback: (topic: string, payload: unknown) => void
  ): void {
    const shadowTopic = `$aws/things/${instrumentId}/shadow/name/${shadowName}/${topicStub}`;
    const shadowAcceptedTopic = `${shadowTopic}/accepted`;
    const shadowRejectedTopic = `${shadowTopic}/rejected`;

    const shadowTopicsToSubscribe = [shadowAcceptedTopic, shadowRejectedTopic];

    const callbackInListenerFound =
      this.listListeners('message').find(listener => listener.name === callback.name) !== undefined;
    if (!callbackInListenerFound) {
      this.thingShadow?.subscribe(shadowTopicsToSubscribe, { qos: 1 });
      this.thingShadow?.on('message', callback);
    }
  }

  public publishToNamedShadow(
    instrumentId: string,
    shadowName: string,
    topicStub: TopicStub,
    message?: string
  ): void {
    const shadowTopic = `$aws/things/${instrumentId}/shadow/name/${shadowName}/${topicStub}`;
    this.thingShadow?.publish(shadowTopic, message ?? '', { qos: 1 });
  }

  public unsubscribeNamedShadow(
    instrumentId: string,
    shadowName: string,
    topicStub: TopicStub
  ): void {
    const shadowTopic = `$aws/things/${instrumentId}/shadow/name/${shadowName}/${topicStub}`;
    const shadowAcceptedTopic = `${shadowTopic}/accepted`;
    const shadowRejectedTopic = `${shadowTopic}/rejected`;

    const shadowTopicsToUnsubscribe = [shadowAcceptedTopic, shadowRejectedTopic];
    this.thingShadow?.unsubscribe(shadowTopicsToUnsubscribe);

    const listeners = this.thingShadow.listeners('message');
    const callback = listeners.find(listener => {
      return listener.name.toLowerCase().includes(topicStub.toString().toLowerCase());
    });
    if (callback !== undefined) {
      this.thingShadow.removeListener(
        'message',
        callback as (topic: string, payload: unknown) => void
      );
    }
  }

  public listListeners(eventName: string): Function[] {
    return this.thingShadow.listeners(eventName);
  }
}
