import Gradient, { GradientLowerTemperature, GradientRange } from '../../Gradient';
import GradientTemperature from '../../gradient-temperature/GradientTemperature';
import GradientTemperature384 from '../../gradient-temperature/GradientTemperature384';

export default class Gradient384 extends Gradient {
  constructor(lowerTemp: GradientLowerTemperature, range: GradientRange, isValid: boolean) {
    super(16, lowerTemp, range, isValid, Gradient384.offsetTable, Gradient384.scTable);
  }

  private static readonly offsetTable: Array<Array<number>> = [
    [29.98657, 40.028498, 50.070426, 60.132251, 70.108793, 80.127482, 90.150247, 100.173013],
    [29.971972, 40.022498, 50.073024, 60.139112, 70.141372, 80.173376, 90.220321, 100.267266],
    [29.978339, 40.029855, 50.081372, 60.155888, 70.176037, 80.21678, 90.266593, 100.316406],
    [29.967168, 40.022541, 50.077914, 60.153616, 70.186539, 80.230591, 90.292037, 100.353484],
    [29.962946, 40.01934, 50.075735, 60.155718, 70.198182, 80.241938, 90.301592, 100.361246],
    [29.962697, 40.018, 50.073303, 60.149863, 70.193191, 80.234404, 90.293975, 100.353547],
    [29.968851, 40.021603, 50.074355, 60.151562, 70.193994, 80.234587, 90.289474, 100.344361],
    [29.973782, 40.020966, 50.068149, 60.137332, 70.171502, 80.202975, 90.252503, 100.302032],
    [29.980433, 40.027257, 50.074081, 60.141746, 70.182359, 80.216696, 90.267472, 100.318248],
    [29.973946, 40.019702, 50.065458, 60.133916, 70.172977, 80.20865, 90.261547, 100.314443],
    [29.978602, 40.023769, 50.068935, 60.134872, 70.170889, 80.208681, 90.262321, 100.31596],
    [29.979668, 40.015356, 50.051045, 60.120511, 70.152998, 80.190192, 90.24674, 100.303288],
    [29.982764, 40.01445, 50.046135, 60.115016, 70.138327, 80.171933, 90.226476, 100.281019],
    [29.982407, 40.006876, 50.031344, 60.097388, 70.103975, 80.131384, 90.182146, 100.232908],
    [29.963753, 39.983802, 50.00385, 60.064172, 70.059786, 80.077295, 90.119329, 100.161363],
    [29.971561, 39.979084, 49.986607, 60.032367, 69.995152, 79.996875, 90.018751, 100.040627]
  ];

  private static readonly scTable: Array<Array<number>> = [
    [0.605238, 0.607618, 0.609998, 0.609885, 0.607894, 0.606521, 0.605935, 0.605349],
    [0.584942, 0.587051, 0.589159, 0.589764, 0.58847, 0.588583, 0.587018, 0.585453],
    [0.546202, 0.548876, 0.551549, 0.551872, 0.550315, 0.549888, 0.548506, 0.547123],
    [0.488053, 0.490512, 0.492971, 0.49369, 0.492565, 0.493342, 0.492172, 0.491002],
    [0.411209, 0.413481, 0.415752, 0.416062, 0.414566, 0.414738, 0.413583, 0.412429],
    [0.313808, 0.315518, 0.317229, 0.317349, 0.315622, 0.316382, 0.31522, 0.314057],
    [0.199783, 0.201197, 0.202611, 0.20237, 0.200501, 0.200676, 0.199869, 0.199063],
    [0.066656, 0.067994, 0.069332, 0.069216, 0.067727, 0.068681, 0.068114, 0.067547],
    [-0.074479, -0.072895, -0.071311, -0.070642, -0.071667, -0.070476, -0.07024, -0.070004],
    [-0.20717, -0.205455, -0.20374, -0.202931, -0.203196, -0.201801, -0.201375, -0.20095],
    [-0.319839, -0.318294, -0.316748, -0.316228, -0.316841, -0.315719, -0.315507, -0.315295],
    [-0.413955, -0.412407, -0.410859, -0.411342, -0.412574, -0.411695, -0.412104, -0.412513],
    [-0.487196, -0.485871, -0.484546, -0.485886, -0.487792, -0.487152, -0.487912, -0.488672],
    [-0.54323, -0.541895, -0.54056, -0.542524, -0.544244, -0.543559, -0.544269, -0.544978],
    [-0.578879, -0.577853, -0.576828, -0.579343, -0.581136, -0.580655, -0.581301, -0.581948],
    [-0.598085, -0.597215, -0.596344, -0.599096, -0.600841, -0.600241, -0.600774, -0.601306]
  ];

  // eslint-disable-next-line class-methods-use-this
  protected createGradientTemperature(
    index: number,
    temperatureValue: number
  ): GradientTemperature {
    return new GradientTemperature384(index, temperatureValue);
  }
}
