import React, { PureComponent } from 'react';
import { fromJS } from 'immutable';
import { connect } from 'react-redux';
import InfiniteScrollSelect from '../../../frontend-common-libs/src/components/common/dropdown/InfiniteScrollSelect';
import LinkButton from '../../../frontend-common-libs/src/components/common/buttons/LinkButton';
import QPCRRunsList from './models/QPCRRunsList';
import QPCRRun from './models/QPCRRun';
import { replacePlate as replacePlateAction } from '../../../actions/currentCfxRun_actions';
import SelectMenuItem from '../../../frontend-common-libs/src/components/common/dropdown/SelectMenuItem';
import { formatId } from '../../../frontend-common-libs/src/components/common/dropdown';
import notification from '../../../frontend-common-libs/src/utils/notifications';
import NonSelectableMenuItem from '../../../frontend-common-libs/src/components/common/dropdown/NonSelectableMenuItem';
import { getSelectedProjectId } from '../../../project-management';
import { ReduxState } from '../../../types';
import { ProjectId } from '../../../frontend-common-libs/src/common/project-management-types';

export type Props = {
  plateSize: number | null | undefined;
  scanMode: string | null | undefined;
  replacePlate: typeof replacePlateAction;
  runId: string | null | undefined;
  selectedProjectId: ProjectId;
};

export type State = {
  showDropdown: boolean;
  runsList: QPCRRunsList;
  isLoading: boolean;
  loadingErrored: boolean;
};

export class PlateLayoutSelectorImpl extends PureComponent<Props, State> {
  _isMounted = false;

  _noRunsAvailableMessage = 'No runs available';

  constructor(props: Props) {
    super(props);
    const { plateSize, scanMode, runId: currentRunId, selectedProjectId } = this.props;
    this.state = {
      showDropdown: false,
      runsList: new QPCRRunsList(plateSize, scanMode, currentRunId, selectedProjectId),
      isLoading: false,
      loadingErrored: false
    };
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  _fetchMoreData = async () => {
    const { runsList } = this.state;
    this.setState({ isLoading: true, loadingErrored: false });
    try {
      const updatedRunsList = await runsList.getRuns();
      if (this._isMounted) {
        this.setState({ isLoading: false, runsList: updatedRunsList });
      }
    } catch (err) {
      this.setState({ isLoading: false, loadingErrored: true });
    }
  };

  _getPlate = async (id: string) => {
    const { runsList } = this.state;
    const qpcrRun = runsList.getRunById(id);
    const plate = await qpcrRun.getPlate();
    return fromJS(plate);
  };

  _handleDropdown = async () => {
    this.setState({ showDropdown: true });
  };

  _replacePlate = async (eventKey: string) => {
    const { replacePlate } = this.props;
    try {
      const plate = (await this._getPlate(eventKey)) as any;
      if (this._isMounted) {
        this.setState({ showDropdown: false });
        replacePlate(plate);
      }
    } catch (err) {
      notification.error('Error applying plate layout');
    }
  };

  _setDropdownText = () => {
    const { runsList } = this.state;
    return runsList.noRunsAvailable ? this._noRunsAvailableMessage : 'Choose one';
  };

  _getDisplay = (run: QPCRRun): string => run.name;

  _getValue = (run: QPCRRun): string => run.id;

  _renderMenuItems = () => {
    const { runsList } = this.state;
    if (runsList.noRunsAvailable) {
      return <NonSelectableMenuItem>{this._noRunsAvailableMessage}</NonSelectableMenuItem>;
    }
    return this.renderRunOptions();
  };

  renderRunOptions() {
    const { runsList } = this.state;
    const options = runsList.runs;
    return options.map(option => {
      const value = this._getValue(option);
      return (
        <SelectMenuItem
          key={formatId('plate-layout-dropdown', value)}
          value={value}
          id={formatId('plate-dropdown', value)}
        >
          {this._getDisplay(option)}
        </SelectMenuItem>
      );
    });
  }

  renderCopyPlateButton = () => (
    <LinkButton
      type="button"
      id="copy-plate-link-btn"
      onClick={this._handleDropdown}
      disabled={false}
    >
      Copy plate from another run
    </LinkButton>
  );

  renderSelectPlateDropdown = () => {
    const { runsList, isLoading, loadingErrored } = this.state;
    return (
      <div id="copy-plate-layout-select">
        <span className="plate-controls-label">Select a Run</span>
        <InfiniteScrollSelect
          id="plate-layout-dropdown"
          placeholder={this._setDropdownText()}
          handleSelection={this._replacePlate}
          fetchMoreData={this._fetchMoreData}
          hasMoreData={runsList.hasMore}
          isLoading={isLoading}
          loadingErrored={loadingErrored}
        >
          {this._renderMenuItems()}
        </InfiniteScrollSelect>
      </div>
    );
  };

  render() {
    const { showDropdown } = this.state;
    return !showDropdown ? this.renderCopyPlateButton() : this.renderSelectPlateDropdown();
  }
}

export function mapStateToProps(state: ReduxState) {
  const selectedProjectId = getSelectedProjectId(state);
  return {
    selectedProjectId
  };
}

export default connect(mapStateToProps, { replacePlate: replacePlateAction })(
  // @ts-ignore
  PlateLayoutSelectorImpl
);
