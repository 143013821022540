import React from 'react';
import { Toggle } from '../../../frontend-common-libs/src/components/common/radio_button_group';

type Props = {
  scale: string;
  onUpdateScale: (...args: Array<any>) => any;
};

export default function ({ scale, onUpdateScale }: Props) {
  const scaleOptions = [
    {
      id: 'amp-chart-scale-lin',
      label: 'Linear',
      value: 'linear'
    },
    {
      id: 'amp-chart-scale-log',
      label: 'Log',
      value: 'log'
    }
  ];

  return (
    <Toggle
      id="amp-chart-scale-option"
      name="amp-chart-scale"
      onChange={onUpdateScale}
      checkedValue={scale}
      // @ts-ignore
      options={scaleOptions}
    />
  );
}
