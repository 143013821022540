import { Map, fromJS } from 'immutable';
import {
  PCR_PROTOCOL_CLOSED,
  PCR_PROTOCOL_ERROR,
  PCR_PROTOCOL_IS_SAVING,
  PCR_PROTOCOL_LOADED,
  PCR_PROTOCOL_LOADING,
  PcrProtocolActionType
} from '../actions/pcr_protocol_types';
import {
  PROTOCOL_TEMPLATES_DELETED,
  PROTOCOL_TEMPLATES_ARCHIVED,
  PROTOCOL_TEMPLATES_RENAMED
} from '../actions/protocolTemplate_types';
import { UNAUTH_USER } from '../auth/actions/auth_types';

import { ApiAction } from '../types';
import { getWindowUrl } from '../utils/windowUtils';
import coreRoutes from '../core/routes';
import realTimePcrRoutes from '../real-time-pcr/routes';
import { NullableNumber } from '../frontend-common-libs/src/common/nullable-types';
import { UserFile } from '../frontend-common-libs/src/common/types';
// @ts-ignore
const INITIAL_STATE: Map<string, any> = Map();

export function isEditingProtocol(entityId: string): boolean {
  const currentUrl = getWindowUrl();
  return currentUrl.includes(`${coreRoutes.APP}${realTimePcrRoutes.QPCR_PROTOCOL}/${entityId}`);
}

function isNewerVersion(state: Map<string, any>, entity: UserFile): boolean {
  const { id, versionNumber } = entity;
  const storedVersionNumber = state.getIn([id, 'protocol', 'versionNumber']) as NullableNumber;
  return (
    versionNumber != null && storedVersionNumber != null && versionNumber > storedVersionNumber
  );
}

const onProtocolsDeleted = (state: Map<string, any>, action: ApiAction<any, any>) => {
  const { fileList: deletedList } = action.payload;
  let newState = state;
  deletedList.forEach((id: string) => {
    newState = newState.delete(id);
  });
  return newState;
};

const onProtocolsArchived = (state: Map<string, any>, action: ApiAction<any, any>) => {
  const { entities: archivedList } = action.payload;
  let newState = state;
  archivedList.forEach((entity: UserFile) => {
    if (isEditingProtocol(entity.id) && isNewerVersion(state, entity))
      newState = newState.setIn([entity.id, 'hasNewerVersion'], true);
    else newState = newState.delete(entity.id);
  });
  return newState;
};

const onProtocolLoaded = (
  state: Map<string, any>,
  action: ApiAction<PcrProtocolActionType, any>
) => {
  const { faId } = action.payload;
  const protocol = fromJS(action.payload.protocol);
  return state
    .setIn([faId, 'loading'], false)
    .setIn([faId, 'protocol'], protocol)
    .setIn([faId, 'hasNewerVersion'], false);
};

const onProtocolRenamed = (
  state: Map<string, any>,
  action: ApiAction<PcrProtocolActionType, any>
) => {
  const { id } = action.payload.faEntity;
  if (!isEditingProtocol(id)) return state.delete(id);
  if (isNewerVersion(state, action.payload.faEntity)) {
    return state.setIn([id, 'hasNewerVersion'], true);
  }
  return state;
};

const onProtocolError = (
  state: Map<string, any>,
  action: ApiAction<PcrProtocolActionType, any>
) => {
  const { faId } = action.payload;
  return state.delete(faId);
};

const onProtocolLoading = (
  state: Map<string, any>,
  action: ApiAction<PcrProtocolActionType, any>
) => {
  const { faId } = action.payload;
  return state.setIn([faId, 'loading'], true);
};

const onProtocolClosed = (
  state: Map<string, any>,
  action: ApiAction<PcrProtocolActionType, any>
) => {
  const { id } = action.payload;
  const hasNewerVersion = state.getIn([id, 'hasNewerVersion']);
  if (hasNewerVersion) return state.delete(id);
  return state;
};

const onProtocolIsSaving = (state: Map<string, any>, action: any) => {
  const { faId, isSaving } = action.payload;
  return state.setIn([faId, 'isProtocolSaving'], isSaving);
};

const resetState = () => INITIAL_STATE;

const actionMap = {
  [PCR_PROTOCOL_LOADING]: onProtocolLoading, // Querying protocol
  [PCR_PROTOCOL_ERROR]: onProtocolError, // Protocol query failed
  [PCR_PROTOCOL_LOADED]: onProtocolLoaded, // Protocol loaded
  [PCR_PROTOCOL_CLOSED]: onProtocolClosed, // Protocol closed
  [PCR_PROTOCOL_IS_SAVING]: onProtocolIsSaving, // Protocol closed
  [PROTOCOL_TEMPLATES_DELETED]: onProtocolsDeleted, // Protocols deleted
  [PROTOCOL_TEMPLATES_RENAMED]: onProtocolRenamed, // Protocols deleted
  [PROTOCOL_TEMPLATES_ARCHIVED]: onProtocolsArchived, // Protocols archived
  [UNAUTH_USER]: resetState // User logged out
};

export default function protocolReducer(
  state: Map<string, any> = INITIAL_STATE,
  action: ApiAction<any, any> | undefined = undefined
): Map<string, any> {
  if (action) {
    return actionMap[action.type] ? actionMap[action.type](state, action) : state;
  }
  return state;
}
