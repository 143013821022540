import {
  FILETYPE_IN_PROGRESS_PTC_TEMPO_RUN,
  FILETYPE_PTC_RUN
} from '../../frontend-common-libs/src/common/userfiles_common';

export const FILETYPE_PTC_PROTOCOL = 'cpcr-protocol';

export default class PTCTempoFileRowType {
  private static displayedFileTypes: { [key: string]: string } = {
    [FILETYPE_IN_PROGRESS_PTC_TEMPO_RUN]: 'PTC Tempo run: In progress',
    [FILETYPE_PTC_RUN]: 'PTC Tempo run: Completed',
    [FILETYPE_PTC_PROTOCOL]: 'PTC Protocol'
  };

  public static getDisplayedFileType(type: string): string {
    return PTCTempoFileRowType.displayedFileTypes[type] || type;
  }
}
