import { connect } from 'react-redux';
import onMessageAction from '../actions/iot_actions';
import * as instrumentActions from '../instruments/actions/instrument.actions';
import IoT from '../frontend-common-libs/src/components/IoT';
import IOTConnection from '../iot/iot-connection';

const mapStateToProps = () => {
  return {
    iotConnection: IOTConnection
  };
};

export default connect(mapStateToProps, {
  onMessage: onMessageAction,
  onReconnect: instrumentActions.refreshInstruments
})(IoT);
