import React, { ReactElement, HTMLProps, ComponentProps } from 'react';
import AriaAutoSuggestDropdown from './AriaAutoSuggestDropdown';
import AriaAutoSuggestInput from './AriaAutoSuggestInput';
import AriaAutoSuggestDropdownItem from './AriaAutoSuggestDropdownItem';
import SearchStringFormatter from './SearchStringFormatter';

const options = Array(20).fill('Option-');
const isOpen = true;
const isHighlighted = false;
const labelText = 'Choose an element:';

interface AriaAutoSuggestContainerProps {
  searchStringFormatter: typeof SearchStringFormatter;
}

export default function AriaAutoSuggestContainer({
  searchStringFormatter: StringFormatter
}: AriaAutoSuggestContainerProps): ReactElement {
  function getAriaAutoSuggestDropdownProps(): HTMLProps<HTMLUListElement> {
    return { dropdownProp: 'dropdownProp' } as HTMLProps<HTMLUListElement>;
  }

  function getLabelProps(): HTMLProps<HTMLLabelElement> {
    return { labelProp: 'labelProp' } as HTMLProps<HTMLLabelElement>;
  }

  function getDropdownToggleProps(): ComponentProps<'button'> {
    return { toggleButtonProp: 'toggleButtonProp' } as ComponentProps<'button'>;
  }

  function getAriaAutoSuggestDropdownItemProps(): HTMLProps<HTMLLIElement> {
    return { dropdownItemProp: 'dropdownItemProp' } as HTMLProps<HTMLLIElement>;
  }

  function getInputProps(): HTMLProps<HTMLInputElement> {
    return { inputProp: 'inputProp' } as HTMLProps<HTMLInputElement>;
  }

  function getInputWrapperDivProps(): HTMLProps<HTMLDivElement> {
    return { divProp: 'divProp' } as HTMLProps<HTMLDivElement>;
  }

  return (
    <>
      <AriaAutoSuggestInput
        labelProps={getLabelProps()}
        toggleButtonProps={getDropdownToggleProps()}
        inputProps={getInputProps()}
        inputWrapperProps={getInputWrapperDivProps()}
        labelText={labelText}
      />
      <AriaAutoSuggestDropdown isOpen menuProps={getAriaAutoSuggestDropdownProps()}>
        {isOpen &&
          options.map((item: string, index: number) => {
            const key = `${item}-${index}`;
            const displayText = `${item}${index + 1}`;
            return (
              <AriaAutoSuggestDropdownItem
                key={key}
                dropdownItemProps={getAriaAutoSuggestDropdownItemProps()}
                isHighlighted={isHighlighted}
              >
                <StringFormatter>{displayText}</StringFormatter>
              </AriaAutoSuggestDropdownItem>
            );
          })}
      </AriaAutoSuggestDropdown>
    </>
  );
}
