import React, { Component, SyntheticEvent } from 'react';
import { Link } from 'react-router-dom';
import routes from '../../../core/routes';
import PrimaryButton from '../../../frontend-common-libs/src/common/buttons';
import styles from './styles/link-instrument-form.module.scss';
import commonStyles from '../../../frontend-common-libs/src/scss/auth.module.scss';

export type Props = {
  userCode: string;
  handleOnSubmit: (e: SyntheticEvent<HTMLFormElement>) => void;
  handleOnChange: (userCode: string) => void;
  errorMessage: string;
};

export default class LinkInstrumentForm extends Component<Props> {
  updateUserCode = (e: SyntheticEvent<HTMLInputElement>) => {
    const { handleOnChange } = this.props;
    const userCode = e.currentTarget.value;
    handleOnChange(userCode.toUpperCase());
  };

  render() {
    const { userCode, errorMessage, handleOnSubmit } = this.props;
    return (
      <div className={`${styles.container} headerContainer`}>
        <div className={styles.text}>
          <p>Enter the code shown on your instrument to link it to your BR.io account.</p>
          <p className={`${styles.helper} helper`}>
            If you do not have a BR.io account,
            <Link id="sign-up-link" title="Sign Up" to={routes.REGISTER} target="_blank">
              sign up here
            </Link>
            {'\n'}
            before continuing.
          </p>
        </div>
        <form onSubmit={handleOnSubmit}>
          <div className={styles.codeInputWrapper}>
            <div className={styles.labelWrapper}>
              <label htmlFor="user-code" className={styles.formLabel}>
                CODE
              </label>
              {errorMessage === '' ? null : (
                <label className={`${styles.formLabelError} formLabelError`}>{errorMessage}</label>
              )}
            </div>
            <input
              id="user-code"
              type="text"
              className={`${styles.userCodeInput} ${commonStyles.inputField}`}
              value={userCode}
              onChange={this.updateUserCode}
              maxLength={15}
            />
            <PrimaryButton
              id="link-code-btn"
              type="submit"
              className={styles.button}
              disabled={userCode === ''}
            >
              Submit
            </PrimaryButton>
          </div>
        </form>
      </div>
    );
  }
}
