import { createCognitoUser } from '../frontend-common-libs/src/utils/authUtils';
import FatalPasswordResetError from './FatalPasswordResetError';
import RetryablePasswordResetError from './RetryablePasswordResetError';

const fatalErrors = [
  'CodeMismatchException',
  'ExpiredCodeException',
  'NotAuthorizedException',
  'ResourceNotFoundException',
  'InvalidParameterException',
  'UserNotConfirmed'
];

const retryableErrors = ['InvalidPasswordException'];

function getResetPwdError(errorCode: string): Error {
  if (fatalErrors.includes(errorCode)) {
    return new FatalPasswordResetError();
  }
  if (retryableErrors.includes(errorCode)) {
    return new RetryablePasswordResetError();
  }
  return new Error('Unknown password reset error');
}

export type ResetPwdInfo = {
  password: string;
};

export function resetPwd(resetPwdInfo: ResetPwdInfo, email: string, code: string): Promise<void> {
  return new Promise((resolve, reject) => {
    const cognitoUser = createCognitoUser(email);

    cognitoUser.confirmPassword(code, resetPwdInfo.password, {
      onSuccess() {
        resolve();
      },
      onFailure(err) {
        // @ts-ignore
        const error = getResetPwdError(err.code);
        reject(error);
      }
    });
  });
}
