class LocalFeatureFlagClient {
  save(key: string, value: any): void {
    window.localStorage.setItem(
      this.FEATURE_FLAG_KEY,
      JSON.stringify({
        ...this.getAll(),
        ...{ [key]: value }
      })
    );
  }

  get(key: string): any | null | undefined {
    return this.getAll()[key];
  }

  getAll(): {
    [key: string]: any;
  } {
    const flags = window.localStorage.getItem(this.FEATURE_FLAG_KEY);
    return (flags && JSON.parse(flags)) || {};
  }

  FEATURE_FLAG_KEY = 'featureFlags';
}

export default new LocalFeatureFlagClient();
