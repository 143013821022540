import React, { PureComponent, SyntheticEvent } from 'react';
import { Amplify } from 'aws-amplify';
import LinkInstrumentForm from './link-instrument-form/LinkInstrumentForm';
import LinkInstrumentConfirmation from './link-instrument-confirmation/LinkInstrumentConfirmation';
import { addAuthCode, lookupUserCode } from '../api/link-instrument';
import uuidRegex from '../../frontend-common-libs/src/common/routes/regex';
import { getCookie, setCookie } from '../../frontend-common-libs/src/utils/commonUtils';
import Loader from '../../frontend-common-libs/src/components/common/Loader';
import LinkInstrumentFinished from './link-instrument-finished/LinkInstrumentFinished';
import InstrumentIcon from '../../../img/instrument-large.svg';
import LinkIcon from '../../../img/link.svg';
import BrioLogo from '../../../img/b-rio-color-small.svg';
import styles from './styles/link-instrument.module.scss';
import DeviceLinkingTrackingEvent, {
  DeviceLinkingEvent
} from '../user-analytics/DeviceLinkingTrackingEvent';

export type Props = {};

export enum Step {
  FORM,
  CONFIRMATION,
  LOADING,
  FINISHED
}

export type State = {
  userCode: string;
  deviceFlowStep: Step;
  instrumentName: string;
  serialNumber: string;
  instrumentId: string;
  deviceType: string;
  errorMessage: string;
  isError: boolean;
};

export default class LinkInstrument extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      userCode: '',
      deviceFlowStep: Step.FORM,
      instrumentName: '',
      serialNumber: '',
      instrumentId: '',
      deviceType: '',
      errorMessage: '',
      isError: false
    };
  }

  updateUserCode = (userCode: string) => {
    this.setState({ userCode });
  };

  validateUserCode = async (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { userCode } = this.state;
    try {
      const response = await lookupUserCode(userCode);
      const { instrumentName, serialNumber, instrumentId, deviceType } = response.data;
      this.setState({
        instrumentName,
        serialNumber,
        instrumentId,
        deviceType,
        deviceFlowStep: Step.CONFIRMATION,
        errorMessage: ''
      });
      DeviceLinkingTrackingEvent.track(DeviceLinkingEvent.USE_CODE, instrumentId, deviceType);
    } catch (err) {
      this.setState({ errorMessage: 'Invalid code. Please try again.' });
      console.log(err);
    }
  };

  getAuthCodeFromURL = (): string | null => {
    // @ts-ignore
    // eslint-disable-next-line react/prop-types
    const { location } = this.props;
    // @ts-ignore
    // eslint-disable-next-line react/prop-types
    const params = new URLSearchParams(location.search);
    return params.get('code');
  };

  validateAuthCodeFromURL = (): boolean => {
    const authCode = this.getAuthCodeFromURL();
    if (authCode && authCode !== '') {
      const uuidRegexExp = new RegExp(`^${uuidRegex}$`);
      return uuidRegexExp.test(authCode);
    }
    return false;
  };

  getUserCodeFromCookie = (): string | null => {
    return getCookie('usercode');
  };

  getInstrumentNameFromCookie = (): string | null => {
    return getCookie('instrumentName');
  };

  validateUserCodeCookie = () => {
    const userCode = this.getUserCodeFromCookie();
    if (userCode) {
      return userCode.length > 0 && userCode.length < 15;
    }
    return false;
  };

  handleStepChange = (step: Step) => {
    this.setState({ deviceFlowStep: step });
  };

  clearDeviceFlowDetails = () => {
    this.setState({
      userCode: '',
      instrumentName: '',
      serialNumber: '',
      instrumentId: '',
      deviceType: '',
      isError: false
    });
  };

  setFinishedWithSuccess = (instrumentName: string) => {
    this.setState({ isError: false, instrumentName });
  };

  setFinishedWithError = () => {
    this.setState({ isError: true });
  };

  renderStep = () => {
    const {
      deviceFlowStep,
      userCode,
      instrumentName,
      serialNumber,
      instrumentId,
      deviceType,
      errorMessage,
      isError
    } = this.state;
    switch (deviceFlowStep) {
      case Step.FORM:
        return (
          <LinkInstrumentForm
            userCode={userCode}
            handleOnSubmit={this.validateUserCode}
            handleOnChange={this.updateUserCode}
            errorMessage={errorMessage}
          />
        );
      case Step.CONFIRMATION:
        return (
          <LinkInstrumentConfirmation
            userCode={userCode}
            serialNumber={serialNumber}
            instrumentName={instrumentName}
            instrumentId={instrumentId}
            deviceType={deviceType}
            handleStepChange={this.handleStepChange}
            clearDeviceFlowDetails={this.clearDeviceFlowDetails}
          />
        );
      case Step.LOADING:
        return <Loader />;
      case Step.FINISHED:
        return (
          <LinkInstrumentFinished
            isError={isError}
            handleStepChange={this.handleStepChange}
            clearDeviceFlowDetails={this.clearDeviceFlowDetails}
          />
        );
      default:
        return null;
    }
  };

  async componentDidMount() {
    Amplify.configure({});
    if (this.validateUserCodeCookie() && this.validateAuthCodeFromURL()) {
      this.handleStepChange(Step.LOADING);
      const userCode = this.getUserCodeFromCookie() as string;
      const instrumentName = this.getInstrumentNameFromCookie() as string;
      const authCode = this.getAuthCodeFromURL() as string;
      try {
        await addAuthCode(userCode, authCode);
        this.setFinishedWithSuccess(instrumentName);
      } catch (err) {
        this.setFinishedWithError();
      } finally {
        setCookie('usercode', '', 0, '/link');
        setCookie('instrumentName', '', 0, '/link');
        this.handleStepChange(Step.FINISHED);
      }
    }
  }

  render() {
    return (
      <div className={styles.container}>
        <div className={styles.icons}>
          <img src={InstrumentIcon} alt="Instrument icon" />
          <img src={LinkIcon} alt="Link icon" />
          <img src={BrioLogo} alt="Br.io logo" />
        </div>
        {this.renderStep()}
      </div>
    );
  }
}
