import { secondsToTimeString } from '../../../../../../frontend-common-libs/src/utils/protocolUtils';
import type { Step } from '../../../../../../frontend-common-libs/src/components/pcr/pcr_protocol_types';

export default class ProtocolStepsParser {
  report: any;

  public readonly protocolSteps: any;

  constructor(runReport: any) {
    this.report = runReport;
    this.protocolSteps = this.getSteps();
  }

  private getSteps() {
    const protocolJson = this.report.protocol;
    const protocolSteps = [];
    for (let index = 0; index < protocolJson.steps.length; index += 1) {
      const step = `Step ${index + 1}:`;
      const lines = ProtocolStepsParser.createStep(protocolJson.steps[index]);
      protocolSteps.push({ step, lines });
    }
    protocolSteps.push({ step: '', lines: ['END'] });
    return protocolSteps;
  }

  private static createStep(step: Step): string[] {
    if (step.type === 'goto') return ProtocolStepsParser.createGotoStep(step);
    if (step.type === 'gradient') return ProtocolStepsParser.createGradientStep(step);
    if (step.type === 'temp') return ProtocolStepsParser.createTemperatureStep(step);
    return [];
  }

  private static createGotoStep(step: Step) {
    // @ts-ignore
    return [`GOTO Step ${step.step + 1}, ${step.cycles} more times.`];
  }

  private static createGradientStep(step: Step) {
    // @ts-ignore
    const header = `Gradient ${step.temp.toFixed(1)}°C / ${(step.temp + step.grd).toFixed(
      1
    )}°C for ${secondsToTimeString(step.time)}.`;
    const lines = [header, ...ProtocolStepsParser.getStepOptions(step)];
    return lines;
  }

  private static createTemperatureStep(step: Step) {
    // @ts-ignore
    const header = `${step.temp.toFixed(1)}°C for ${secondsToTimeString(step.time)}.`;
    const lines = [header, ...ProtocolStepsParser.getStepOptions(step)];
    return lines;
  }

  private static getStepOptions(step: Step) {
    const lines = [];
    if (step.inc) {
      lines.push(`Increment temperature by ${step.inc.toFixed(1)}°C per cycle.`);
    }
    if (step.ramp) {
      lines.push(`Change Ramp Rate to ${step.ramp}°C per sec.`);
    }
    if (step.ext) {
      lines.push(`Extend time by ${step.ext} sec per cycle.`);
    }
    if (step.beep) {
      lines.push(`Beep when step temperature reached.`);
    }
    return lines;
  }
}
