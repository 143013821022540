import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import {
  formatId,
  Select,
  SelectMenuItem
} from '../../../frontend-common-libs/src/components/common/dropdown';
import {
  setPlate as setPlateAction,
  setPlateType as setPlateTypeAction
} from '../../../actions/currentCfxRun_actions';
import { scanModes } from '../../../utils/microplateUtils';
import {
  isCompletedRun,
  isInProgressRun,
  getEntityId,
  getPlate
} from '../../../selectors/selectors';
import { replaceWhitespace } from '../../../frontend-common-libs/src/common/strings';
import RadioButtonGroup from '../../../frontend-common-libs/src/components/common/radio_button_group';
import PlateLayoutSelector from './PlateLayoutSelector';
import { ReduxState } from '../../../types';

type Props = {
  plate: Map<string, any>;
  isEditable: boolean;
  setPlate: typeof setPlateAction;
  setPlateType: typeof setPlateTypeAction;
  runId: string;
};

export class PlateSetupImpl extends PureComponent<Props> {
  getDisplay = (scanModeKey: string): string => scanModes[scanModeKey];

  updatePlateSize = (e: React.SyntheticEvent<HTMLInputElement>) => {
    const { setPlate } = this.props;
    const plateSize = parseInt(e.currentTarget.value, 10);
    setPlate(this.scanMode(), plateSize, this.plateType());
  };

  updatePlateType = (e: React.SyntheticEvent<HTMLInputElement>) => {
    const { setPlateType } = this.props;
    const plateType = e.currentTarget.value;
    setPlateType(plateType);
  };

  updateScanMode = (value: string) => {
    const { setPlate } = this.props;
    if (this.scanMode() !== value) {
      setPlate(value, this.plateSize(), this.plateType());
    }
  };

  scanMode = (): string => {
    const { plate } = this.props;
    return plate.get('scanMode');
  };

  plateType = (): string => {
    const { plate } = this.props;
    return plate.get('plateType');
  };

  plateSize = () => {
    const { plate } = this.props;
    // @ts-ignore
    return plate.getIn(['layout', 'rows']) * plate.getIn(['layout', 'columns']);
  };

  renderPlateSize() {
    const {
      props: { isEditable },
      plateSize
    } = this;
    const name = 'plate-size';
    const valueFor96 = '96';
    const valueFor384 = '384';
    const plateSizeOptions = [
      {
        id: replaceWhitespace(`${name}:${valueFor96}`),
        label: '96 Wells',
        value: valueFor96,
        disabled: !isEditable
      },
      {
        id: replaceWhitespace(`${name}:${valueFor384}`),
        label: '384 Wells',
        value: valueFor384,
        disabled: !isEditable
      }
    ];
    return (
      <div className="run-plate-size">
        <span className="plate-controls-label">Plate Size</span>
        <div id="plate-size" className="plate-options">
          <RadioButtonGroup
            name={name}
            onChange={this.updatePlateSize}
            checkedValue={String(plateSize())}
            options={plateSizeOptions}
          />
        </div>
      </div>
    );
  }

  renderPlateType() {
    const { plateType, plateSize, updatePlateType } = this;
    const is96Well = plateSize() === 96;
    const name = 'plate-type';
    const clearValue = 'BR Clear';
    const whiteValue = 'BR White';
    const plateSizeOptions = [
      {
        id: replaceWhitespace(`${name}:${clearValue}`),
        label: 'Clear Wells',
        value: clearValue,
        disabled: !is96Well
      },
      {
        id: replaceWhitespace(`${name}:${whiteValue}`),
        label: 'White Wells',
        value: whiteValue,
        disabled: false
      }
    ];

    return (
      <div className="run-plate-type">
        <span className="plate-controls-label">Plate Type</span>
        <div id="plate-type" className="plate-options">
          <RadioButtonGroup
            name={name}
            onChange={updatePlateType}
            checkedValue={plateType()}
            options={plateSizeOptions}
          />
        </div>
      </div>
    );
  }

  renderScanModeOptions = (selectId: string) => {
    return Object.keys(scanModes).map<any>(scanMode => {
      const formattedId = formatId(selectId, scanMode);
      return (
        <SelectMenuItem id={formattedId} value={scanMode} key={formattedId}>
          {this.getDisplay(scanMode)}
        </SelectMenuItem>
      );
    });
  };

  renderScanMode() {
    const scanMode = this.scanMode();
    const { isEditable } = this.props;
    const selectId = 'scan-mode-dropdown';
    return (
      <div className="scan-mode">
        <span className="plate-controls-label">Scan Mode</span>
        <Select
          id={selectId}
          disabled={!isEditable}
          value={this.getDisplay(scanMode)}
          placeholder="Select Scan Mode"
          handleSelection={this.updateScanMode}
        >
          {this.renderScanModeOptions(selectId)}
        </Select>
      </div>
    );
  }

  render() {
    const { isEditable, runId } = this.props;
    return (
      <div className="plate-setup-controls">
        {this.renderScanMode()}
        {this.renderPlateSize()}
        {this.renderPlateType()}
        <PlateLayoutSelector
          key={isEditable ? 'unfiltered' : 'filtered'}
          plateSize={isEditable ? undefined : this.plateSize()}
          scanMode={isEditable ? undefined : this.scanMode()}
          runId={runId}
        />
      </div>
    );
  }
}

function mapStateToProps(state: ReduxState) {
  return {
    plate: getPlate(state),
    isEditable: !isCompletedRun(state) && !isInProgressRun(state),
    runId: getEntityId(state)
  };
}

export default connect(mapStateToProps, {
  setPlate: setPlateAction,
  setPlateType: setPlateTypeAction
  // @ts-ignore
})(PlateSetupImpl);
