import { Map, List } from 'immutable';
import { ReduxState } from '../../types';
import {
  ReduxInstrumentsMap,
  InstrumentStatuses,
  Instrument,
  InstrumentsList,
  InstrumentStatus
} from '../../frontend-common-libs/src/instruments/types';

const getInstrumentStatus = (deviceStatus?: Map<string, any>): InstrumentStatus => {
  let status;
  const lastKnownStatus =
    deviceStatus && deviceStatus.getIn(['statusObject', 'state', 'reported', 'status']);
  if (deviceStatus && lastKnownStatus) {
    const isStale = deviceStatus.get('isStale');
    status = isStale ? 'Offline' : lastKnownStatus;
  }
  // else status is unknown which we are ignoring
  return status;
};

const getInstruments = (state: ReduxState): ReduxInstrumentsMap =>
  state.instruments.get('instruments');

const getStatuses = (state: ReduxState): InstrumentStatuses => state.instruments.get('statuses');

const getInstrumentsWithReportedStatus = (state: ReduxState): InstrumentsList | List<unknown> => {
  const instruments = getInstruments(state);
  const statuses = getStatuses(state);

  return instruments
    .map(instrument => {
      const deviceId = instrument.get('deviceId');
      // deviceStatus comes from redux state, contains statuses for all instruments
      const deviceStatus = statuses.get(deviceId);
      // status works only for Opus since uses its own shadow structure
      const status = getInstrumentStatus(deviceStatus);
      return instrument.set('status', status).set('deviceStatus', deviceStatus);
    })
    .toList();
};

export const waitingForStatuses = (state: ReduxState): boolean => {
  const statuses = getStatuses(state);
  const allInstruments = getInstruments(state);
  const instrumentsWithStatuses = getInstruments(state).reduce(
    (accum: InstrumentsList, instrument: Instrument) => {
      const deviceId = instrument.get('deviceId');
      const deviceStatus = statuses.get(deviceId);
      if (deviceStatus) {
        return accum.push(instrument);
      }
      return accum;
    },
    List()
  );

  return instrumentsWithStatuses.size !== allInstruments.size;
};

export const getInstrumentsWithStatus = (state: ReduxState): InstrumentsList | List<unknown> => {
  // check to see if all instruments have connected to their shadow
  if (!waitingForStatuses(state)) {
    // only return instruments with status
    return getInstrumentsWithReportedStatus(state);
  }
  return List();
};
