import { matchPath } from 'react-router';

export type RouteName = string;
export type RoutePattern = string;
export type RoutePath = string;
export type RouteTitle = string;

export const ROUTE_NOT_FOUND = '/*';

export function getRouteName(
  path: RoutePath,
  routesNames: { [key: string]: RoutePattern }
): RouteName {
  return (
    Object.keys(routesNames).find(
      name =>
        matchPath(path, {
          path: routesNames[name],
          exact: true,
          strict: false
        }) !== null
    ) || 'ROUTE_NOT_FOUND'
  );
}

const externalRouteNames: Array<{ [key: string]: RoutePattern }> = [];
const externalRouteTitles: Array<{ [key: string]: RouteTitle }> = [];

export function addExternalRouteTitles(
  routesNames: { [key: string]: RoutePattern },
  routesTitles: { [key: string]: RouteTitle }
) {
  externalRouteNames.push(routesNames);
  externalRouteTitles.push(routesTitles);
}

export function getExternalTitle(routePath: RoutePath): RouteTitle {
  for (let i = 0; i < externalRouteNames.length; i += 1) {
    const routeName = getRouteName(routePath, externalRouteNames[i]);
    const title = externalRouteTitles[i][routeName];
    if (title) return title;
  }
  return '';
}
