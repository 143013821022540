import { format, parseISO } from 'date-fns';

export default class DateFnsFactory {
  public static format(date: Date, dateFormat: string): string {
    return format(date, dateFormat);
  }

  public static parseISO(utcString: string): Date {
    return parseISO(utcString);
  }
}
