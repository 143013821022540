import React from 'react';
import { Field, reduxForm, FormProps } from 'redux-form';
// @ts-ignore
import { SubmitFunction } from 'redux-form/lib/types.js.flow';
import { RenderField } from '../../../common/input-fields';
import ModalDialog from './ModalDialog';
import showOrNot from '../ShowOrNot';
import {
  makeTextRequired,
  makeMaximumText,
  makeFileNameCharacterValidator
} from '../../../common/form_validators';
import { CustomModalStyle } from './custom-modal-styles';

export type EditModalFormValues = {
  name: string;
};

export type Props = {
  title: string;
  onCancel: (...args: Array<any>) => any;
  onSuccess: SubmitFunction;
  okButtonText?: string;
  customModalStyle?: CustomModalStyle;
} & FormProps<any, any>;

export class EditFileModalImpl extends React.Component<Props> {
  static textRequired = makeTextRequired('Name may not be empty');

  static lengthValidator = makeMaximumText('Name is too long', 255);

  static specialCharValidator = makeFileNameCharacterValidator('Name may not include:');

  static defaultProps = {
    title: 'Edit File'
  };

  fieldHighlight = (inputRef: HTMLInputElement | null | undefined) => {
    if (inputRef) {
      const node = inputRef;
      node.focus();
      node.setSelectionRange(0, node.defaultValue.lastIndexOf('.'));
    }
  };

  render() {
    const {
      title,
      onCancel,
      onSuccess,
      // @ts-ignore
      handleSubmit,
      // @ts-ignore
      invalid,
      // @ts-ignore
      pristine,
      // @ts-ignore
      submitting,
      okButtonText,
      customModalStyle
    } = this.props;
    return (
      <ModalDialog
        show
        title={title}
        onCancel={onCancel}
        onSuccess={handleSubmit(onSuccess)}
        okBtnText={okButtonText || 'Update'}
        okBtnDisabled={invalid || pristine}
        inProgress={submitting}
        customModalStyle={customModalStyle}
      >
        <Field
          id="name"
          name="name"
          label="Name"
          component={RenderField}
          fieldInit={this.fieldHighlight}
          type="text"
          validate={[
            EditFileModalImpl.textRequired,
            EditFileModalImpl.lengthValidator,
            EditFileModalImpl.specialCharValidator
          ]}
        />
      </ModalDialog>
    );
  }
}

export default showOrNot(
  reduxForm({
    form: 'file-edit',
    touchOnChange: true
    // @ts-ignore
  })(EditFileModalImpl)
);
