import React, { Component } from 'react';
import { DropdownButton, MenuItem } from 'react-bootstrap';
import { StepItem } from '../../../utils/protocolUtils';

export type Props = {
  currentType: string;
  onEditStepTypeChange: (...args: Array<any>) => any;
  stepsList: (...args: Array<any>) => any;
};

type EventMetadata = { source: 'select' | 'keydown' | 'rootClose' | 'click' };

export default class StepTypeSelection extends Component<Props> {
  stepTypeToggleHandler = (
    open: boolean,
    event: React.SyntheticEvent,
    { source }: EventMetadata
  ): void => {
    const keyboardEvent = event as unknown as React.KeyboardEvent<HTMLButtonElement>;
    if (!open && source === 'keydown' && keyboardEvent.key === 'Escape') {
      event.nativeEvent.stopImmediatePropagation();
    }
  };

  render() {
    const { currentType, onEditStepTypeChange, stepsList } = this.props;

    const itemList: StepItem[] = stepsList();

    let menuTitle = '';
    itemList.forEach((item: StepItem) => {
      if (item.type === currentType) {
        menuTitle = item.name;
      }
    });

    return (
      <label htmlFor="protocol-type-dropdown" className="heading-title step-type-label">
        Type
        <DropdownButton
          bsStyle="primary"
          title={menuTitle}
          id="protocol-type-dropdown"
          onSelect={onEditStepTypeChange}
          autoFocus
          onToggle={this.stepTypeToggleHandler}
        >
          {itemList.map(({ type, name, disable }) => (
            <MenuItem
              id={`protocol-step-type-${name.toLowerCase()}`}
              eventKey={type}
              key={type}
              active={type === currentType}
              disabled={!!disable}
              title={disable}
            >
              {name}
            </MenuItem>
          ))}
        </DropdownButton>
      </label>
    );
  }
}
