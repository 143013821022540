import { Dispatch } from '../../types';

export type OrgManagementActionTypes =
  | 'login_token_loading'
  | 'login_token_loaded'
  | 'organization_token_loading'
  | 'organization_token_loaded'
  | 'organization_token_revoked'
  | 'organization_added'
  | 'organization_removed'
  | 'organizations_loading'
  | 'organizations_loaded'
  | 'organization_selected'
  | 'organization_details_loading'
  | 'organization_details_loaded'
  | 'organization_user_added'
  | 'organization_user_removed'
  | 'organization_member_role_changed'
  | 'organization_renamed'
  | 'organization_description_changed';

export const LOGIN_TOKEN_LOADING: OrgManagementActionTypes = 'login_token_loading';
export const LOGIN_TOKEN_LOADED: OrgManagementActionTypes = 'login_token_loaded';
export const ORG_TOKEN_LOADING: OrgManagementActionTypes = 'organization_token_loading';
export const ORG_TOKEN_LOADED: OrgManagementActionTypes = 'organization_token_loaded';
export const ORG_TOKEN_REVOKED: OrgManagementActionTypes = 'organization_token_revoked';
export const ORG_ADDED: OrgManagementActionTypes = 'organization_added';
export const ORG_REMOVED: OrgManagementActionTypes = 'organization_removed';
export const ORGS_LOADING: OrgManagementActionTypes = 'organizations_loading';
export const ORGS_LOADED: OrgManagementActionTypes = 'organizations_loaded';
export const ORG_SELECTED: OrgManagementActionTypes = 'organization_selected';
export const ORG_DETAILS_LOADING: OrgManagementActionTypes = 'organization_details_loading';
export const ORG_USER_ADDED: OrgManagementActionTypes = 'organization_user_added';
export const ORG_USER_REMOVED: OrgManagementActionTypes = 'organization_user_removed';
export const ORG_DETAILS_LOADED: OrgManagementActionTypes = 'organization_details_loaded';
export const ORG_MEMBER_ROLE_CHANGED: OrgManagementActionTypes = 'organization_member_role_changed';
export const ORG_RENAMED: OrgManagementActionTypes = 'organization_renamed';
export const ORG_DESCRIPTION_CHANGED: OrgManagementActionTypes = 'organization_description_changed';

export type DispatchType = Dispatch<OrgManagementActionTypes, Record<string, any> | string>;

export interface Tenant {
  name: string;
  id: string;
}

export type Organization = Tenant;
