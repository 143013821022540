import React, { Component, ChangeEvent, ReactElement } from 'react';
import { CognitoUser } from 'amazon-cognito-identity-js';
import ProgressButton from '../../frontend-common-libs/src/components/common/ProgressButton';
import styles from '../style/create-api-token-form.module.scss';
import { getCurrentUser } from '../../frontend-common-libs/src/utils/authUtils';
import request from '../../frontend-common-libs/src/utils/httpUtils';
import { GATEWAY } from '../../frontend-common-libs/src/config/config';
import CopyToClipboard from './CopyToClipboard';
import DatePicker from '../../frontend-common-libs/src/components/common/datepicker/DatePicker';
import WarningIcon from '../img/warning-icon.svg';
import APITokenCreatedTrackingEvent from '../../user-analytics/APITokenCreatedTrackingEvent';

interface CreateAPITokenFormProps {
  setApiTokenCreated: (isCreated: boolean) => void;
}

export type CreateAPITokenFormState = {
  loading: boolean;
  description: string;
  expiration: string;
  networkError: boolean;
  jwt: string;
  descriptionErrorMessage: string;
  showCustomExpiration: boolean;
};

const authServiceBaseURL = GATEWAY.AUTH_SERVICE_PATHS.BASE_URL;
const apiTokensPath = GATEWAY.AUTH_SERVICE_PATHS.API_TOKENS;
const secondsInDay = 86400;

class CreateAPITokenForm extends Component<CreateAPITokenFormProps, CreateAPITokenFormState> {
  private currentDate = new Date();

  public constructor(props: CreateAPITokenFormProps) {
    super(props);
    this.state = {
      loading: false,
      description: '',
      expiration: (7 * secondsInDay).toString(),
      networkError: false,
      jwt: '',
      descriptionErrorMessage: '',
      showCustomExpiration: false
    };
  }

  private isGenerateTokenButtonDisabled() {
    const { networkError, jwt, loading, description, descriptionErrorMessage } = this.state;
    return (
      (!networkError && jwt !== '') ||
      description === '' ||
      descriptionErrorMessage !== '' ||
      loading
    );
  }

  private areFormInputsDisabled() {
    const { jwt, networkError, loading } = this.state;
    return (jwt && !networkError) || loading;
  }

  private async createApiToken() {
    const { description, expiration, showCustomExpiration } = this.state;
    const { setApiTokenCreated } = this.props;

    try {
      this.setState({ loading: true });
      const user = getCurrentUser() as CognitoUser;
      const response = await request.post(
        authServiceBaseURL,
        {},
        apiTokensPath,
        {},
        {
          description,
          expiration: Number(expiration),
          email: user.getUsername()
        }
      );
      new APITokenCreatedTrackingEvent({
        expiration: Number(expiration),
        expirationType: showCustomExpiration ? 'custom' : 'predefined'
      }).track();
      this.setState({ jwt: response.data.jwt, networkError: false });
      setApiTokenCreated(true);
    } catch (e) {
      console.error(e);
      this.setState({ networkError: true });
    } finally {
      this.setState({ loading: false });
    }
  }

  private handleDescriptionChange(e: ChangeEvent<HTMLTextAreaElement>) {
    const { value } = e.target;
    this.setState({ description: value });
    this.validateDescription(value);
  }

  private handleExpirationChange(event: ChangeEvent<HTMLSelectElement>) {
    const { value } = event.target;
    this.setState({ expiration: value });
    this.setState({ showCustomExpiration: value === 'custom' });
  }

  private handleCustomExpirationChange(expirationDate: Date) {
    const currentDate = new Date();
    const expirationInSeconds = Math.round(
      expirationDate.getTime() / 1000 - currentDate.getTime() / 1000
    );
    this.setState({ expiration: expirationInSeconds.toString() });
  }

  private handleButtonOnClick() {
    this.createApiToken();
  }

  private validateDescription(value: string) {
    let descriptionError = '';
    if (value.trim() === '') {
      descriptionError = 'Description is required';
    } else if (value.length > 255) {
      descriptionError = 'Description is too long';
    }
    this.setState({ descriptionErrorMessage: descriptionError });
  }

  private get currentDatePlusOneDay(): Date {
    return new Date(
      this.currentDate.getFullYear(),
      this.currentDate.getMonth(),
      this.currentDate.getDate() + 1,
      this.currentDate.getHours(),
      this.currentDate.getMinutes(),
      this.currentDate.getSeconds()
    );
  }

  private get renderCopyTokenWarningMessage(): ReactElement | undefined {
    const { jwt } = this.state;
    if (jwt) {
      return (
        <span className={styles.copyApiTokenWarning} id="create-api-token-form-copy-warning">
          <img src={WarningIcon} alt="alert" />
          <span>Make sure to copy your new API token now.</span> You won’t be able to see it again.
        </span>
      );
    }
    return undefined;
  }

  render() {
    const {
      description,
      loading,
      networkError,
      jwt,
      descriptionErrorMessage,
      showCustomExpiration
    } = this.state;

    return (
      <div className={styles.createApiTokenFormContainer}>
        <form>
          <div className={styles.descriptionContainer}>
            <div>
              <label htmlFor="create-api-token-form-description">Description</label>
              {descriptionErrorMessage && (
                <span className={styles.errorText}>{descriptionErrorMessage}</span>
              )}
            </div>
            <textarea
              id="create-api-token-form-description"
              name="description"
              placeholder="Type description here"
              value={description}
              onChange={e => this.handleDescriptionChange(e)}
              disabled={this.areFormInputsDisabled()}
            />
          </div>
          <div className={styles.expirationContainer}>
            <label htmlFor="create-api-token-form-expiration">Expiration</label>
            <select
              id="create-api-token-form-expiration"
              name="expiration"
              defaultValue={7 * secondsInDay}
              onChange={e => this.handleExpirationChange(e)}
              disabled={this.areFormInputsDisabled()}
            >
              <option value={7 * secondsInDay}>7 days</option>
              <option value={30 * secondsInDay}>30 days</option>
              <option value={90 * secondsInDay}>90 days</option>
              <option value="custom">Custom</option>
            </select>
            {showCustomExpiration && (
              <DatePicker
                id="create-api-token-form-date-picker"
                onDateChange={(date: Date) => this.handleCustomExpirationChange(date)}
                minDate={this.currentDatePlusOneDay}
              />
            )}
          </div>
          <div className={styles.generateTokenContainer}>
            <ProgressButton
              id="create-api-token-form-generate-token-button"
              className="new-btn primary"
              onClick={() => this.handleButtonOnClick()}
              inProgress={loading}
              disabled={this.isGenerateTokenButtonDisabled()}
            >
              {jwt !== '' ? 'Token created' : 'Generate Token'}
            </ProgressButton>
            <span className={styles.errorText}>
              {networkError && 'An error occurred while generating API token. Please try again.'}
            </span>
          </div>
        </form>
        <div className={styles.apiTokenContainer}>
          <label htmlFor="new-api-token-jwt-field">API Token</label>
          {this.renderCopyTokenWarningMessage}
          <textarea
            id="new-api-token-jwt-field"
            name="new-api-token-jwt-field"
            readOnly
            value={jwt}
          />
          {jwt !== '' && <CopyToClipboard textToCopy={jwt} />}
        </div>
      </div>
    );
  }
}

export default CreateAPITokenForm;
