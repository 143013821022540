import React, { Component } from 'react';
import { connect } from 'react-redux';
import { MenuItem } from 'react-aria-menubutton';
import { Set } from 'immutable';
import { Map } from 'immutable';
import MultiSelect from '../../../common/multiselect/MultiSelect';
import ToolbarButton from '../../../../frontend-common-libs/src/components/common/buttons/ToolbarButton';
import SelectionBox from '../../../common/multiselect/SelectionBox';
import { setFluorsToShow, setTargetsToShow } from '../../../../actions/currentCfxRun_actions';
import { analysisModes, targetMode } from '../pcrDataUtils';
import getTraceColor from '../../../../utils/chartUtils';
import {
  getTargetsInRun,
  getAnalysisMode,
  getTargetsToShow,
  getFluorsInRun,
  getFluorsToShow,
  getPlateChannelMap,
  shouldUpdateStep,
  stepCalculationErrored,
  getTargets
} from '../../../../selectors/selectors';
import filtersImg from '../../../../../img/funnel.svg';
import { replaceWhitespace } from '../../../../frontend-common-libs/src/common/strings';
import { ReduxState } from '../../../../types';

type Props = {
  analysisMode: string;
  targetsInRun: Set<any>;
  targetsToShow: Set<any>;
  fluorsInRun: Set<any>;
  fluorsToShow: Set<any>;
  channelMap: Map<string, any>;
  dataReady: boolean;
  changeTargetsToShow: typeof setTargetsToShow;
  changeFluorsToShow: typeof setFluorsToShow;
  plateTargets: Map<string, any>;
  disabled: boolean;
};

export class TargetsOrFluorsSelectorImpl extends Component<Props> {
  changeTargetsOrFluorsToShow = (targetsToShow: Set<any>) => {
    const { analysisMode } = this.props;
    if (analysisMode === targetMode) {
      const { targetsInRun, changeTargetsToShow } = this.props;
      changeTargetsToShow(targetsToShow, targetsInRun);
    } else {
      const { fluorsInRun, changeFluorsToShow } = this.props;
      changeFluorsToShow(targetsToShow, fluorsInRun);
    }
  };

  renderMenu = (
    id: string,
    filteredItems: {
      [key: string]: any;
    },
    selectedItems: {
      [key: string]: any;
    }
  ) => {
    const { channelMap, plateTargets, analysisMode } = this.props;
    return filteredItems.toArray().map((item: any) => (
      <MenuItem
        id={`${id}-menu-item:${replaceWhitespace(item)}`}
        tabIndex={-1}
        value={item}
        key={item}
        className="menu-item"
      >
        <div className="menu-item-text">
          <SelectionBox selected={selectedItems.has(item)} />
          {item}
        </div>
        <span
          className="pcr-data-color-circle"
          style={{
            backgroundColor: getTraceColor(item, plateTargets, analysisMode, channelMap.get(item))
          }}
        />
      </MenuItem>
    ));
  };

  render() {
    const { analysisMode, dataReady, disabled } = this.props;
    let items;
    let selectedItems: any;
    if (analysisMode === targetMode) {
      const { targetsInRun, targetsToShow } = this.props;
      items = targetsInRun;
      selectedItems = targetsToShow;
    } else {
      const { fluorsInRun, fluorsToShow } = this.props;
      items = fluorsInRun;
      selectedItems = fluorsToShow;
    }
    if (!dataReady) {
      items = Set();
      selectedItems = Set();
    }
    const id = 'filter';
    const renderMenu = (filteredItems: any) => this.renderMenu(id, filteredItems, selectedItems);
    function handleRenderMenu(filteredItems: any) {
      return renderMenu(filteredItems);
    }

    return (
      <MultiSelect
        id={id}
        items={items}
        selectedItems={selectedItems}
        onSelectionChange={this.changeTargetsOrFluorsToShow}
        // @ts-ignore
        itemName={analysisModes[analysisMode]}
        // eslint-disable-next-line react/jsx-no-bind
        renderMenu={handleRenderMenu}
        title="Filter targets or fluorophores from analysis."
        disabled={disabled}
      >
        <ToolbarButton icon={filtersImg} imgAlt="filter icon">
          Filters
        </ToolbarButton>
      </MultiSelect>
    );
  }
}

function mapStateToProps(state: ReduxState) {
  const stepError = stepCalculationErrored(state);
  const shouldLoadStep = !stepError && shouldUpdateStep(state);
  const plateTargets = getTargets(state);
  return {
    analysisMode: getAnalysisMode(state),
    targetsInRun: getTargetsInRun(state),
    targetsToShow: getTargetsToShow(state),
    fluorsInRun: getFluorsInRun(state),
    fluorsToShow: getFluorsToShow(state),
    channelMap: getPlateChannelMap(state),
    dataReady: !shouldLoadStep && !stepError,
    plateTargets
  };
}

export default connect(mapStateToProps, {
  changeFluorsToShow: setFluorsToShow,
  changeTargetsToShow: setTargetsToShow
  // @ts-ignore
})(TargetsOrFluorsSelectorImpl);
