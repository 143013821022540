import { Component } from 'react';
import IoTConnection from '../iot/IoTConnection';

type Props = {
  onMessage: (topic: string, message: any) => void;
  onReconnect: () => void;
  iotConnection: IoTConnection;
};

export default class IoT extends Component<Props> {
  componentDidMount() {
    const { onMessage, onReconnect, iotConnection } = this.props;
    iotConnection.startTopic(onMessage, onReconnect);
  }

  render() {
    return null;
  }
}
