import React from 'react';
import {
  InstrumentBlock,
  InstrumentIcon,
  InstrumentId,
  InstrumentModel,
  InstrumentName
} from '../../../types';
import { NullableString } from '../../../../common/nullable-types';
import { InstrumentStatusEnumValue } from '../../../../../../instruments/utils/instrumentUtils';

export type Props = {
  id: InstrumentId;
  name: InstrumentName;
  status: InstrumentStatusEnumValue | NullableString;
  modelName?: InstrumentModel;
  icon: InstrumentIcon;
  block?: InstrumentBlock;
};

function InstrumentTile({ id, name, status, modelName, icon, block }: Props) {
  const renderDefault = () => {
    return (
      <div className="instrument-name-label" title={name}>
        <span className="with-quotes">{name}</span>
      </div>
    );
  };

  const renderWithBlock = () => {
    const nameWithBlock = block ? `${name} ${block}` : name;
    return (
      <div className="instrument-name-label-block" title={nameWithBlock}>
        <div className="name">{name}</div>
        <div className="closing-quotes" />
        <div className="block">{block}</div>
      </div>
    );
  };

  return (
    <div id={`instrument_item_${id}`} className="recent-instrument-item">
      <div className="icon">
        <img src={icon.src} alt={icon.alt} />
      </div>
      <div className="details">
        <div className="instrument-model-label">{modelName}</div>
        {block ? renderWithBlock() : renderDefault()}
        <div className="label-status">{status}</div>
      </div>
    </div>
  );
}

export default InstrumentTile;
