import {
  DispatchType,
  ORG_DETAILS_LOADED,
  ORG_SELECTED,
  ORG_TOKEN_LOADED,
  ORG_TOKEN_LOADING
} from './action-types';
import { setActiveOrganization } from '../api/org-api';
import { GetState } from '../../types';
import { getOrgUserToken, isOrgTokenLoading } from '../selectors/selectors';
import { CbWithToken } from './WithRefreshToken';
import { withRefreshLoginToken } from './with-refresh-login-token';

export function selectOrg(organizationId: string) {
  return async (dispatch: DispatchType, getState: GetState) => {
    dispatch({
      type: ORG_SELECTED,
      payload: { organizationId }
    });
    dispatch({
      type: ORG_TOKEN_LOADING,
      payload: { organizationId }
    });
    try {
      const setActiveOrgCb: CbWithToken = async (accessToken: string) => {
        return setActiveOrganization(organizationId, accessToken);
      };
      const loginInfo = await withRefreshLoginToken(dispatch, getState, setActiveOrgCb);
      dispatch({
        type: ORG_TOKEN_LOADED,
        payload: { loginInfo, organizationId }
      });
    } catch (error) {
      dispatch({
        type: ORG_DETAILS_LOADED,
        payload: { orgId: organizationId, details: { error } }
      });
    }
  };
}

export function getOrgTokenIfNeeded(orgId: string) {
  return async (dispatch: DispatchType, getState: GetState) => {
    const state = getState();
    const isOrgTokenLoaded = getOrgUserToken(state, orgId) != null;
    if (!isOrgTokenLoaded && !isOrgTokenLoading(state, orgId)) {
      await selectOrg(orgId)(dispatch, getState);
    }
  };
}
