import { List, Map, OrderedMap } from 'immutable';

export function guid() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return `${s4() + s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
}

// returns the string after the last period in a file name
export function getFileExtension(filename: string) {
  return filename.split('.').slice(-1)[0];
}

// maps numbers to alphabet. 1->A, 2-> B, etc.
export function numToChar(number: number): string {
  if (number > 26 || number === 0) {
    throw new RangeError('Row index exceeds limit.');
  }
  return String.fromCharCode(number + 64);
}

export const intlCollator = new Intl.Collator('en-US', {
  numeric: true,
  sensitivity: 'case'
});

/**
 * Natural sort uses Javascript Internationalization Object (Intl) to sort strings based on the natural language sort order
 * Includes sorting of alphanumeric strings including locale specific special characters
 * Takes in the 'key' based on which the 'list' of objects to be sorted
 * list: [
 *  {key1: valueS1, key2: valueR2A2, ...},
 *  {key1: valueA2, key2: valueD2C2, ...},
 *  {key1: valueA4, key2: valueE3C2, ...},
 *  {key1: valueR6, key2: valueR4, ...}
 * ]
 * @param list
 * @param key
 * @param isAscending
 * @param isNumeric
 * @returns {Iterable<K, V>}
 */
export function naturalSort(
  list: List<Map<string, any>>,
  key: string,
  isAscending: boolean,
  isNumeric?: boolean
) {
  if (isNumeric) {
    return list.sort((val1, val2) =>
      isAscending ? val1.get(key) - val2.get(key) : val2.get(key) - val1.get(key)
    );
  }
  return list.sort((val1, val2) =>
    isAscending
      ? intlCollator.compare(val1.get(key), val2.get(key))
      : intlCollator.compare(val2.get(key), val1.get(key))
  );
}

/**
 * Headers are the keys in the inputData object and also the table headers
 * which will be placed on the CSV File.
 * Input data should be an array of objects with keys as in header list.
 *
 * Sample Input Data:
 *
 * headers: ['well', 'fluor', 'target',cq]
 * inputData:
 * [
 *    {well: 'A1', fluor: 'Cy5', cq: 1.2},
 *    {well: 'A1', fluor: 'FAM', target: 'Cyclophilin', cq: 12.1},
 *    {well: 'A1', fluor: 'HEX', target: 'IL1Beta', }
 *    ...
 * ]
 *
 * Output:
 * Well,Fluor,Target,Cq\nA1,Cy5,,1.2\nA1,FAM,Cyclophilin,12.1\nA1,HEX,IL1Beta,,
 *
 * Note: there will be empty comma's if a particular column value is missing in the inputData.
 *
 * @param headers
 * @param inputData
 * @returns comma separated value string - CSV
 */
export function createCSVStringFromData(
  headers: OrderedMap<string, any>,
  inputData: List<Map<string, any>>
) {
  const wrapInQuote = (data: string) =>
    data && !Number.isFinite(data) && data.includes(',') ? `"${data}"` : data;
  if (!headers || !headers.size || !inputData) return '';
  const getHeadString = (str: string) => str.replace(/^\w/, c => c.toUpperCase());

  return `${headers.reduce(
    (res, displayValue) => `${getHeadString(res)},${getHeadString(displayValue)}`
  )}\n${inputData
    .map(
      dataObject =>
        `${headers.reduce(
          (res, _, dataKey) =>
            `${res ? `${res},` : ''}${wrapInQuote(dataObject.get(dataKey)) || ''}`,
          ''
        )}`
    )
    .join('\n')}`;
}

// Returns the OS the broswer is running on
export const isMac = (): boolean => window.navigator.platform.includes('Mac');

export const setCookie = (
  cookieName: string,
  cookieValue: string | number,
  minsToExpire: number,
  cookiePagePath: string
): void => {
  const d = new Date();
  d.setTime(d.getTime() + minsToExpire * 60 * 1000);
  const expiresAt = d.toUTCString();
  document.cookie = `${cookieName}=${cookieValue}; expires=${expiresAt}; path=${cookiePagePath}`;
};

export const getCookie = (cookieName: string): string | null => {
  const foundCookie = document.cookie.split('; ').find(cookie => cookie.startsWith(cookieName));
  if (foundCookie) {
    return foundCookie.split('=')[1];
  }
  return null;
};
