import React, { Component } from 'react';
import { Map } from 'immutable';
import iconForEntityType from '../../../utils/rowEntityUtils';
import { utcStringToDateTimeFormat } from '../../../common/display-formats';
import TRow from './TRow';
import TCell from './TCell';
import PopupMenu, { PopupMenuItem } from '../menus/PopupMenu';
import { getFileTypeStatus } from '../../../common/userfiles_common';

export type Props = {
  id: string;
  item: Map<string, any>;
  tableBodyRef: any;
  canOpen: boolean;
  popupItems: PopupMenuItem[];
  linkedItemName: string;
  linkId?: string;
  onClick?: (...args: Array<any>) => any;
  onKeyDown?: (...args: Array<any>) => any;
  entityPath?: string;
  icon?: JSX.Element;
  displayedFileType?: string;
};

export default class EntityRow extends Component<Props> {
  static defaultProps = {
    linkId: undefined,
    onClick: undefined,
    onEntitySelected: undefined,
    onKeyDown: undefined,
    entityPath: undefined
  };

  createMenuItemsList = () => {
    const menuItems: Array<PopupMenuItem> = [];

    const { popupItems } = this.props;

    popupItems.forEach(item => {
      menuItems.push({
        menuItemKey: item.menuItemKey,
        menuItemCallback: item.menuItemCallback,
        menuItemTitle: item.menuItemTitle,
        menuItemText: item.menuItemText,
        menuItemDisabled: item.menuItemDisabled
      });
    });

    return menuItems;
  };

  renderIcon() {
    const { item, icon } = this.props;

    if (icon) {
      return icon;
    }

    const entityType = item.get('type');
    const img = iconForEntityType(entityType);
    return (
      <img
        src={`/img/${img}`}
        className="center-block file-row-icon"
        alt={entityType}
        role="presentation"
      />
    );
  }

  render() {
    const {
      onClick,
      onKeyDown,
      canOpen,
      entityPath,
      item,
      id,
      linkId,
      tableBodyRef,
      displayedFileType
    } = this.props;
    const entityType = item.get('type');
    const entityId = item.get('id');
    const entityName = item.get('name');
    const entityUpdated = item.get('updated');
    const fileTypeStatus = displayedFileType || getFileTypeStatus(entityType);
    const { linkedItemName } = this.props;
    return (
      <TRow id={id} onClick={onClick} tabIndex={0} onKeyDown={onKeyDown}>
        <TCell>{this.renderIcon()}</TCell>
        <TCell className="name-cell" title={entityName} role="presentation">
          {canOpen ? (
            <a
              href={entityPath}
              id={linkId || entityId}
              className="name-link"
              tabIndex={-1}
              onClick={onClick}
            >
              {entityName}
            </a>
          ) : (
            entityName
          )}
        </TCell>
        <TCell>{utcStringToDateTimeFormat(entityUpdated)}</TCell>
        <TCell>{fileTypeStatus || entityType}</TCell>
        <TCell>
          <PopupMenu
            linkedItem={item}
            tableBodyRef={tableBodyRef}
            menuItems={this.createMenuItemsList()}
            linkedItemEntityName={linkedItemName}
          />
        </TCell>
      </TRow>
    );
  }
}
