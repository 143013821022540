import { InstrumentActionType, instrumentActions } from '../instruments';
import {
  onIotMessage as generatePcrdOnIotMessage,
  PcrdIoTMessage
} from './generate_pcrd/generate_pcrd_actions';
import { Dispatch, GetState } from '../types';
import { UserFilesActionType } from '../file-management/actions/action-types';
import { EntityIotMessage } from '../frontend-common-libs/src/common/userfiles_common';
import FileArbiterIotHandler from './iot_handler/FileArbiterIotHandler';
import { getSelectedProjectId } from '../project-management';
import { searchFiles } from '../file-management/actions/actions';
import AccessControlIotHandler from '../organization-management/iot_handler/AccessControlIotHandler';

type ActionType = UserFilesActionType | InstrumentActionType;

function onMessage(topic: string, message: EntityIotMessage | PcrdIoTMessage) {
  return async (dispatch: Dispatch<ActionType, any>, getState: GetState) => {
    if (message.sender === 'file-arbiter') {
      FileArbiterIotHandler.onIotMessage(dispatch, message as EntityIotMessage);
      const state = getState();
      const projectId = getSelectedProjectId(state);
      const searchText = state.userFiles.getIn(['projects', projectId, 'searchText']);
      if (searchText) {
        await searchFiles(<string>searchText)(dispatch, getState);
      }
    } else if (message.sender === 'instrument-service') {
      const instrumentDispatch = dispatch as Dispatch<any, any> as Dispatch<
        InstrumentActionType,
        any
      >;
      instrumentActions.onIotMessage(instrumentDispatch, getState, message);
    } else if (message.sender === 'qpcr-api.generatePcrd') {
      generatePcrdOnIotMessage(message as PcrdIoTMessage);
    } else if (message.sender === 'access-control') {
      AccessControlIotHandler.onIotMessage(dispatch as any, getState, message);
    }
  };
}

export default onMessage;
