import { MaxProjectsPerUser } from '@biorad-lsg-tsc/organization';
import { DispatchType, PROJECT_ADDED } from './action-types';
import notification from '../../frontend-common-libs/src/utils/notifications';
import { createProject as createProjectApi } from '../api/project-api';
import { GetState } from '../../types';
import { getActiveOrg } from '../../organization-management/selectors/selectors';
import { Project } from '../../frontend-common-libs/src/common/project-management-types';
import {
  TrackedOrganizationEvents,
  trackEvent
} from '../../frontend-common-libs/src/user-analytics/trackedEvents';
import { isProjectCreateLimitError } from '../../organization-management/api/api-errors';
import { CbWithToken } from '../../organization-management/actions/WithRefreshToken';
import { withRefreshOrgToken } from '../../organization-management/actions/with-refresh-org-token';

export function dispatchProjectAdded(dispatch: DispatchType, orgId: string, project: Project) {
  dispatch({
    type: PROJECT_ADDED,
    payload: { orgId, project }
  });
}

export function createProject(projectName: string) {
  return async (dispatch: DispatchType, getState: GetState) => {
    const state = getState();
    const orgId = getActiveOrg(state);
    try {
      const createProjectCb: CbWithToken = async (accessToken: string) => {
        return createProjectApi(projectName, orgId, accessToken);
      };
      const project = await withRefreshOrgToken(dispatch, getState, orgId, createProjectCb);
      dispatchProjectAdded(dispatch, orgId, project);
      trackEvent(TrackedOrganizationEvents.CreateProject, {
        projectName
      });
    } catch (e) {
      let errMsg = 'Error creating project';
      if (isProjectCreateLimitError(e))
        errMsg = `User can create up to ${MaxProjectsPerUser} project.`;
      notification.error(errMsg);
    }
  };
}
