/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PCRProtocol, { State } from './QpcrProtocol';
import coreRoutes from '../../../core/routes';
import realTimePcrRoutes from '../../../real-time-pcr/routes';
import { ReduxState } from '../../../types';
import NewVersionIcon from '../../../frontend-common-libs/src/components/files/NewVersionIcon';

export type Props = {
  hasNewerVersion?: boolean;
  isProtocolSaving?: boolean;
};

export class PCRProtocolFullPageImpl extends React.Component<Props, State> {
  renderNewerVersionIconIfNeeded() {
    const { hasNewerVersion, isProtocolSaving } = this.props;
    return (
      <NewVersionIcon
        hasNewerVersion={hasNewerVersion}
        tooltip="This protocol has a newer version"
        iconStyle="protocol-newer-version-icon"
        isRunSaving={isProtocolSaving}
      />
    );
  }

  renderProtocol() {
    return <PCRProtocol {...this.props} />;
  }

  render() {
    return (
      <div className="protocol-container">
        <div className="protocols-link">
          <Link id="protocols" to={`${coreRoutes.APP}${realTimePcrRoutes.QPCR_PROTOCOL_LIST}`}>
            <span className="chevron left">Back to protocols</span>
          </Link>
          {this.renderNewerVersionIconIfNeeded()}
        </div>
        {this.renderProtocol()}
      </div>
    );
  }
}

export const mapStateToProps = (
  state: ReduxState,
  ownProps: {
    [key: string]: any;
  }
) => {
  const { entityId } = ownProps.match.params;
  const hasNewerVersion = state.protocols.getIn([entityId, 'hasNewerVersion']);
  const isProtocolSaving = state.protocols.getIn([entityId, 'isProtocolSaving']);
  return {
    entityId,
    hasNewerVersion,
    isProtocolSaving
  };
};

export default withRouter(
  // @ts-ignore
  connect(mapStateToProps, {
    // @ts-ignore
  })(PCRProtocolFullPageImpl)
);
