import React, { Component } from 'react';
import { Redirect, RouteComponentProps, withRouter } from 'react-router-dom';
import { parse } from 'query-string';
import { resetPwd, ResetPwdInfo } from '../../../actions/resetPwd_actions';
import PasswordResetForm, { FormInfo } from './PasswordResetForm';
import routes from '../../../core/routes';
import notification from '../../../frontend-common-libs/src/utils/notifications';
import RetryablePasswordResetError from '../../../actions/RetryablePasswordResetError';
import FatalPasswordResetError from '../../../actions/FatalPasswordResetError';

export type Props = {
  location: RouteComponentProps['location'];
};

type State = {
  resetComplete: boolean;
  hasFatalPasswordResetError: boolean;
};

type ParsedUrl = {
  [name: string]: string | Array<string>;
};

const errorMessage = 'Unknown error, please try again';
const successMessage = 'Password reset successful!';

export class PasswordResetImpl extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      resetComplete: false,
      hasFatalPasswordResetError: false
    };
  }

  handleFormSubmit = async (pwdInfo: FormInfo) => {
    const code = this.getCode();
    const user = this.getUser();
    const resetPwdInfo: ResetPwdInfo = {
      password: pwdInfo.password1
    };

    try {
      await resetPwd(resetPwdInfo, user, code);
      notification.success(successMessage);
      this.setState({
        resetComplete: true
      });
    } catch (error) {
      if (error instanceof RetryablePasswordResetError) {
        this.handleRetryableError();
      } else if (error instanceof FatalPasswordResetError) {
        this.handleFatalError();
      } else {
        this.handleRetryableError();
      }
    }
  };

  handleRetryableError = () => {
    notification.error(errorMessage);
  };

  handleFatalError = () => {
    this.setState({
      hasFatalPasswordResetError: true
    });
    notification.error(errorMessage);
  };

  parseUrl = (): ParsedUrl => {
    const { location } = this.props;
    // @ts-ignore
    return location.search ? parse(location.search) : {};
  };

  getCode = (): string => {
    const { code } = this.parseUrl();
    if (typeof code === 'string') {
      return code;
    }
    return '';
  };

  getUser = (): string => {
    const { user } = this.parseUrl();
    if (typeof user === 'string') {
      return user;
    }
    return '';
  };

  render() {
    const code = this.getCode();
    const user = this.getUser();

    const { resetComplete, hasFatalPasswordResetError } = this.state;

    if (resetComplete) {
      return <Redirect to={routes.LOGIN} />;
    }
    if (!user || !code) {
      return <Redirect to={routes.LANDING} />;
    }
    if (hasFatalPasswordResetError) {
      return <Redirect to={routes.FORGOT_PASSWORD} />;
    }

    return (
      <PasswordResetForm
        // @ts-ignore
        fieldLabel="NEW PASSWORD"
        btnLabel="Reset Password"
        onSubmit={this.handleFormSubmit}
      />
    );
  }
}

// @ts-ignore
export default withRouter(PasswordResetImpl);
