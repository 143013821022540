import React, { ReactNode } from 'react';
import BenefitBox from './BenefitBox';

import iconOneUpdated from '../../../../img/benefitBox_icon_1_updated.svg';
import iconTwoUpdated from '../../../../img/benefitBox_icon_2_updated.svg';
import iconThreeUpdated from '../../../../img/benefitBox_icon_3_updated.svg';
import iconFourUpdated from '../../../../img/benefitBox_icon_4_updated.svg';
import iconFiveUpdated from '../../../../img/benefitBox_icon_5_updated.svg';

export default function () {
  function getBenefitContainerContent(): Record<string, string | string[]>[] {
    {
      return [
        {
          image: iconOneUpdated,
          title: 'Remote setup and data access',
          textContentItems: [
            'Create new experiments from anywhere and automatically transfer them to the instrument',
            'Access experimental data upon completion of an experiment, even if you are away from the lab'
          ]
        },
        {
          image: iconTwoUpdated,
          title: 'Remote instrument monitoring',
          textContentItems: [
            'Remotely access instrument information, including model, serial number, and firmware version',
            'Monitor status of instruments located in different rooms, buildings, or sites from a single computer',
            'Check the progress of experiments without returning to the lab'
          ]
        },
        {
          image: iconThreeUpdated,
          title: 'Automatic data transfer and storage',
          textContentItems: [
            'Save time with automated data transfer to the cloud',
            'Minimize risk of data loss due to local storage malfunction or damage',
            'Ensure automatic backup of data to the cloud'
          ]
        },
        {
          image: iconFourUpdated,
          title: 'Cloud-enabled software',
          textContentItems: [
            'Deploy and update easily – no need to install and manage software',
            'Access BR.io from any Internet-connected computer (MacOS or Windows), anywhere in the world',
            'Reduce dependency on laboratory computers'
          ]
        },
        {
          image: iconFiveUpdated,
          title: 'Enterprise-grade security and privacy controls',
          textContentItems: [
            'Protect your account and data using our strong password requirements',
            'Secure your intellectual property with data encryption in transit and at rest',
            'Have confidence in the safety of your data — the BR.io cloud platform is regularly tested for security vulnerabilities',
            'Utilize our data privacy controls for GDPR compliance'
          ]
        }
      ];
    }
  }

  const renderBenefitBoxes = (): ReactNode[] =>
    getBenefitContainerContent().map((boxItem, index) => {
      const { title, image, textContentItems } = boxItem;
      return (
        <BenefitBox
          key={`benefitbox-${index + 1}`}
          index={index}
          title={title as string}
          image={image as string}
          textContentItems={textContentItems as string[]}
        />
      );
    });

  return (
    <div className="benefit-container">
      <h2 className="benefit-title">Bringing Cloud Benefits to the Laboratory</h2>
      <div className="benefit-boxes-container">{renderBenefitBoxes()}</div>
    </div>
  );
}
