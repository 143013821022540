import React from 'react';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import {
  UPLOAD_IN_PROGRESS,
  FILE_OPERATION_COMPLETE,
  FILE_OPERATION_FAILED,
  UPLOAD_CANCELLED,
  FILE_OPERATION_PROCESSING,
  UPLOAD_UNSUPPORTED,
  UPLOAD_COMPLETE_UNRECOGNIZED,
  UPLOAD_SIZE_EXCEEDED,
  FileOperationType,
  FILE_NETWORK_OPERATION_FAILED,
  FileProgress
} from '../../frontend-common-libs/src/common/userfiles_common';
import { cancelUpload as cancelUploadAction } from '../../actions/file_upload/fileupload_actions';
import { UploadNetworkError } from '../../frontend-common-libs/src/file-operations/messages';

export type FileOperationStatusProps = {
  item: Map<string, any>;
  cancelUpload: typeof cancelUploadAction;
};
export class FileOperationStatusItemImpl extends React.Component<FileOperationStatusProps> {
  cancel = () => {
    const { cancelUpload, item } = this.props;
    cancelUpload(item.get('id'));
  };

  renderStatusMsg = (status: FileProgress) => {
    const { item } = this.props;
    let message = item.get('errorMessage');
    if (message == null) {
      switch (status) {
        case FILE_NETWORK_OPERATION_FAILED: {
          message = UploadNetworkError;
          break;
        }
        case UPLOAD_COMPLETE_UNRECOGNIZED:
        case UPLOAD_UNSUPPORTED: {
          message = 'Upload failed due to unsupported file';
          break;
        }
        case UPLOAD_SIZE_EXCEEDED: {
          message = 'Max file size of 15 MB exceeded';
          break;
        }
        default:
          message = null;
      }
    }
    if (message != null) {
      const fileId = item.get('id');
      return (
        <div style={{ fontSize: '11px' }} id={`upload-file-msg_${fileId}`}>
          {message}
        </div>
      );
    }
    return null;
  };

  renderStatusIcon = (status: FileProgress) => {
    const { item } = this.props;
    const fileId = item.get('id');
    switch (status) {
      case UPLOAD_IN_PROGRESS: {
        return (
          <>
            <button
              className="btn-icon"
              onClick={this.cancel}
              id={`cancel-upload-btn_${fileId}`}
              title="Cancel"
              type="button"
            >
              <i className="fa fa-times-circle" />
            </button>
            <i className="fa fa-spinner fa-spin margin-left-5" title="Uploading" />
          </>
        );
      }
      case FILE_OPERATION_PROCESSING: {
        return <i className="fa fa-cog fa-spin margin-left-5" title="Processing" />;
      }
      case FILE_OPERATION_COMPLETE: {
        return <i className="fa fa-check-circle text-success" id={`status-success_${fileId}`} />;
      }
      case FILE_NETWORK_OPERATION_FAILED:
      case FILE_OPERATION_FAILED: {
        return (
          <i
            className="fa fa-exclamation-triangle text-danger"
            title="Failed"
            id={`status-failed_${fileId}`}
          />
        );
      }
      case UPLOAD_CANCELLED: {
        return <div>Cancelled</div>;
      }
      case UPLOAD_SIZE_EXCEEDED:
      case UPLOAD_COMPLETE_UNRECOGNIZED:
      case UPLOAD_UNSUPPORTED: {
        return (
          <i className="fa fa-exclamation-triangle text-danger" id={`status-failed_${fileId}`} />
        );
      }
      default:
        throw new Error(`Illegal Status: ${status}`);
    }
  };

  renderFileOperationIcon() {
    const { item } = this.props;
    const fileOperationType = item.get('fileOperationType');
    const id = `file-operation-icon_${item.get('id')}`;

    switch (fileOperationType) {
      case FileOperationType.exportPcrd:
        return (
          <i
            className="fa fa-arrow-circle-down margin-right-10 blue-color"
            title="Download"
            id={id}
          />
        );
      case FileOperationType.upload:
        return (
          <i className="fa fa-arrow-circle-up margin-right-10 green-color" title="Upload" id={id} />
        );
      default:
        return null;
    }
  }

  render() {
    const { item } = this.props;
    const fileStatus = item.get('status');
    const fileName = item.get('file').name;
    const fileId = item.get('id');
    return (
      <li className="list-group-item" id={`upload-item_${fileId}`}>
        <div className="parent-flex">
          {this.renderFileOperationIcon()}
          <div className="left-flex flex-grow-1">
            <div
              className="text-ellipsis ellipsis-width-200"
              title={fileName}
              id={`upload-file-name_${fileId}`}
            >
              {fileName}
            </div>
            {this.renderStatusMsg(fileStatus)}
          </div>
          <div className="right-flex" id={`upload-status-icon_${fileId}`}>
            {this.renderStatusIcon(fileStatus)}
          </div>
        </div>
      </li>
    );
  }
}

// @ts-ignore
export default connect(null, { cancelUpload: cancelUploadAction })(FileOperationStatusItemImpl);
