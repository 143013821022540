import { ArtifactId, ArtifactURL } from '@biorad-lsg-tsc/instrument-family-plugin';

declare global {
  interface Window {
    brio?: { [namespace: string]: any };
  }
}

export enum InstrumentTypeEnum {
  cfx3G = 'CFX3G',
  c1000Touch = 'C1000Touch',
  ptcTempo = 'PTCTempo',
  imageLabTouch = 'ImageLabTouch'
}

type ElementCreator = (artifactURL: ArtifactURL, artifactId: ArtifactId) => Promise<void>;
export type ElementCreatorMap = { [fileExtension: string]: ElementCreator };
