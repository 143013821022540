import React, { Component } from 'react';
import { connect } from 'react-redux';
import NotificationsSettingsContainer from './NotificationsSettingsContainer';
import { ReduxState } from '../../../types';
import UserPreferencesSidebar, { UserPreferencesSidebarItem } from './UserPreferencesSidebar';
import UserPreferenceDetails from './UserPreferenceDetails';

type Props = {
  isLoading: boolean;
  onClose: (...args: Array<any>) => any;
  onBlur: (...args: Array<any>) => any;
};

type State = {
  currentPreferenceId: any;
};

export class UserPreferencesPanelImpl extends Component<Props, State> {
  private popoverRef: any = React.createRef();

  private notificationsUserPreference: UserPreferencesSidebarItem = {
    displayText: 'Notifications',
    id: 'user-preferences-notifications',
    renderComponent: () => <NotificationsSettingsContainer />
  };

  private userPreferences: Array<UserPreferencesSidebarItem> = [this.notificationsUserPreference];

  constructor(props: Props) {
    super(props);

    this.state = {
      currentPreferenceId: this.notificationsUserPreference.id
    };
  }

  componentDidMount() {
    const { handleBlur } = this;
    document.addEventListener('mousedown', handleBlur, false);
  }

  componentWillUnmount() {
    const { handleBlur } = this;
    document.removeEventListener('mousedown', handleBlur, false);
  }

  handleBlur = (event: MouseEvent) => {
    const {
      popoverRef,
      props: { onBlur }
    } = this;
    const { target } = event;
    if (popoverRef && popoverRef.current && !popoverRef.current.contains(target)) {
      onBlur(event);
    }
  };

  handleTabOut = (event: React.KeyboardEvent<HTMLButtonElement>) => {
    const { key, shiftKey } = event;
    if (key === 'Tab' && !shiftKey) {
      const { onClose } = this.props;
      onClose();
    }
  };

  handleOnUserPreferenceItemClick = (userPreferenceId: string) => {
    const currentUserPreference = this.userPreferences.find(
      (userPreference: UserPreferencesSidebarItem) => userPreference.id === userPreferenceId
    ) as UserPreferencesSidebarItem;
    this.setState({
      currentPreferenceId: currentUserPreference.id
    });
  };

  renderUserPreferenceDetails = () => {
    const { currentPreferenceId } = this.state;

    const currentUserPreference = this.userPreferences.find(
      (userPreference: UserPreferencesSidebarItem) => userPreference.id === currentPreferenceId
    ) as UserPreferencesSidebarItem;

    return currentUserPreference.renderComponent();
  };

  renderLoader = () => (
    <div className="loader flex-bullseye">
      <i className="fa fa-spinner fa-spin fa-3x fa-fw" />
      <span className="sr-only">Loading...</span>
    </div>
  );

  handleRender() {
    const { isLoading, onClose } = this.props;

    if (isLoading) {
      return this.renderLoader();
    }

    return (
      <div className="popover-panel" id="user-preferences-popover-panel">
        <div id="user-preferences-left-panel">
          <div className="popover-navbar" id="user-preferences-popover-navbar">
            User Preferences
          </div>
          <div className="popover-left-panel" id="user-preferences-sidebar">
            <UserPreferencesSidebar
              userPreferences={this.userPreferences}
              onUserPreferenceItemClick={this.handleOnUserPreferenceItemClick}
            />
          </div>
        </div>
        <div className="popover-right-panel" id="notifications-popover-right-panel">
          <UserPreferenceDetails onClose={onClose} handleTabOut={this.handleTabOut}>
            {this.renderUserPreferenceDetails()}
          </UserPreferenceDetails>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div id="user-preferences-popover" className="toolbar-popover" ref={this.popoverRef}>
        {this.handleRender()}
      </div>
    );
  }
}

function mapStateToProps(state: ReduxState) {
  return {
    isLoading: state.userPreferences.get('isLoading')
  };
}

export default connect(mapStateToProps, undefined)(UserPreferencesPanelImpl as any);
