import React, { Component } from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import { Map, Set } from 'immutable';
import Well from './Well';
import PlateHeader from './PlateHeader';
import { numToChar } from '../../../frontend-common-libs/src/utils/commonUtils';
import applySelectionOutline from '../../../utils/selectionOutlineUtil';
import { ModifierKeys } from './SelectionBox';
import { ReplicateFormatterFunction } from '../../../selectors/current-cfx-run-selectors';

export type Props = {
  rows: number;
  cols: number;
  wells: Map<string, Map<string, any>>;
  wellReplicateNumberFormatter: ReplicateFormatterFunction;
  selectedWells: Set<string>;
  highlightedWells: Set<string>;
  editMode?: boolean;
  changeSelectedWells: (arg0: Set<string>, arg1: ModifierKeys) => any;
  plateTargets: Map<string, any>;
  isAnalysisView: any;
  excludedWells: Set<string>;
  rowHeaderOffset: number;
  colHeaderOffset: number;
  tableSpacingTop: number;
  tableSpacingLeft: number;
  scrollableParentSelector: string;
};

type State = {
  scrollLeft: number;
  scrollTop: number;
  scrollingHorizontal: boolean;
  scrollingVertical: boolean;
};

class Plate extends Component<Props, State> {
  static defaultProps = {
    editMode: false
  };

  scrollableElement?: HTMLElement;

  wellRefs: Record<string, HTMLElement> = {};

  constructor(props: Props) {
    super(props);
    this.state = {
      scrollTop: 0,
      scrollingHorizontal: false,
      scrollingVertical: false,
      scrollLeft: 0
    };
  }

  componentDidMount() {
    const { scrollableParentSelector } = this.props;
    this.scrollableElement = document.querySelector(scrollableParentSelector) as any as HTMLElement;
    this.scrollableElement.addEventListener('scroll', this.onScroll);
  }

  componentWillUnmount() {
    if (this.scrollableElement) {
      this.scrollableElement.removeEventListener('scroll', this.onScroll);
    }
  }

  setWellRef = (wellRef: HTMLElement | null | undefined, wellId: string) => {
    if (wellRef) {
      this.wellRefs[wellId] = wellRef;
    } else {
      delete this.wellRefs[wellId];
    }
  };

  getColWellIds = (colIndex: number): Set<string> =>
    Set(this.rowIndices().map(rowIndex => `${numToChar(rowIndex)}${colIndex}`));

  colIndices = () => {
    const { cols } = this.props;
    return _.range(1, cols + 1);
  };

  rowIndices = () => {
    const { rows } = this.props;
    return _.range(1, rows + 1);
  };

  handleHeaderClick = (e: React.MouseEvent<HTMLElement>, wellIds: Set<string>) => {
    const { changeSelectedWells } = this.props;
    const { ctrlKey, metaKey } = e;
    const modifierKeys = { ctrlKey, metaKey };

    changeSelectedWells(wellIds, modifierKeys);
  };

  handleColHeaderClick = (e: React.MouseEvent<HTMLElement>, colStr: string) => {
    const colIndex = Number.parseInt(colStr, 10);
    const colWellIds = this.getColWellIds(colIndex);
    this.handleHeaderClick(e, colWellIds);
  };

  handleRowHeaderClick = (e: React.MouseEvent<HTMLElement>, rowChar: string) => {
    const rowWellIds = Set(this.colIndices().map(colIndex => `${rowChar}${colIndex}`));
    this.handleHeaderClick(e, rowWellIds);
  };

  handleSelectAllClick = (e: React.MouseEvent<HTMLElement>) => {
    const allWellIds = this.colIndices().reduce(
      (acc: Set<string>, colIndex: number) => acc.union(this.getColWellIds(colIndex)),
      Set()
    );
    this.handleHeaderClick(e, allWellIds);
  };

  handleMouseDown = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
  };

  onScroll = () => {
    const scrollableElement = this.scrollableElement as HTMLElement;
    this.setState((state: State) => ({
      scrollTop: scrollableElement.scrollTop,
      scrollLeft: scrollableElement.scrollLeft,
      scrollingHorizontal: state.scrollLeft !== scrollableElement.scrollLeft,
      scrollingVertical: state.scrollTop !== scrollableElement.scrollTop
    }));
    setTimeout(() => {
      this.setState({ scrollingHorizontal: false, scrollingVertical: false });
    }, 200);
  };

  getMarginLeft = () => {
    const { scrollLeft } = this.state;
    const { rowHeaderOffset, tableSpacingLeft } = this.props;
    return scrollLeft <= tableSpacingLeft
      ? -rowHeaderOffset
      : scrollLeft - (rowHeaderOffset + tableSpacingLeft);
  };

  getMarginTop = () => {
    const { scrollTop } = this.state;
    const { colHeaderOffset, tableSpacingTop } = this.props;
    return scrollTop <= tableSpacingTop
      ? -colHeaderOffset
      : scrollTop - (colHeaderOffset + tableSpacingTop);
  };

  renderTBody() {
    return (
      <>
        {this.renderTableHeader()}
        {this.rowIndices().map(row => this.renderTableRow(row))}
      </>
    );
  }

  renderTableHeader = () => {
    const { isAnalysisView } = this.props;
    const { handleColHeaderClick, handleSelectAllClick, handleMouseDown } = this;
    const colIndices = this.colIndices();
    const { scrollingVertical } = this.state;

    const opacity = scrollingVertical ? 0 : 1;
    const marginLeft = this.getMarginLeft();
    const marginTop = this.getMarginTop();

    const header = colIndices.map(colIndex => (
      <PlateHeader
        id={`well-th-${colIndex}`}
        key={`topHeader${colIndex}`}
        onClick={handleColHeaderClick}
        wellGroup={colIndex.toString()}
        isAnalysisView={isAnalysisView}
        onMouseDown={handleMouseDown}
      />
    ));

    if (isAnalysisView) {
      header.unshift(
        <button
          type="button"
          className={classNames('analysis-select-all', 'cell-well', 'table-headers')}
          onClick={handleSelectAllClick}
          onMouseDown={handleMouseDown}
          key="topHeader0"
          id="well-th-0"
          style={{
            opacity,
            marginLeft
          }}
        >
          All
        </button>
      );
    } else {
      header.unshift(
        <div
          className="select-all"
          id="well-th-0"
          key="topHeader0"
          onClick={handleSelectAllClick}
          onMouseDown={handleMouseDown}
          role="presentation"
          style={{
            opacity,
            marginLeft
          }}
        >
          <div className="select-all-outer">
            <div className="select-all-inner" />
          </div>
        </div>
      );
    }

    return (
      <div
        className={classNames('header-table-well', 'col-headers-row')}
        style={{
          opacity,
          marginTop
        }}
      >
        {header}
      </div>
    );
  };

  renderTableRow = (row: number) => {
    const rowChar = numToChar(row);

    const { handleRowHeaderClick, handleMouseDown } = this;
    const { isAnalysisView } = this.props;
    const { scrollingHorizontal } = this.state;

    const opacity = scrollingHorizontal ? 0 : 1;
    const marginLeft = this.getMarginLeft();

    return (
      <div className="row-well-table" key={`row${rowChar}`}>
        <div
          className="row-header"
          style={{
            opacity,
            marginLeft
          }}
        >
          <PlateHeader
            id={`well-tr-${rowChar}`}
            key={`leftHeader${rowChar}`}
            onClick={handleRowHeaderClick}
            wellGroup={rowChar}
            isAnalysisView={isAnalysisView}
            onMouseDown={handleMouseDown}
          />
        </div>
        {this.colIndices().map(col => this.renderTableCell(row, col))}
      </div>
    );
  };

  renderTableCell(row: number, col: number) {
    const { wells } = this.props;
    const wellId = numToChar(row) + col; // ie: A1, B17, etc
    const well = wells.get(wellId);
    let selectionOutline: string[] = [];
    const {
      excludedWells,
      editMode,
      selectedWells,
      wellReplicateNumberFormatter,
      plateTargets,
      highlightedWells
    } = this.props;

    const isExcluded = excludedWells && excludedWells.has(wellId);

    const isSelected = (editMode || well) && selectedWells.has(wellId);

    const isHighlighted = well && highlightedWells.has(wellId) && !isExcluded;

    if (isSelected) {
      selectionOutline = applySelectionOutline(wellId, selectedWells);
    }

    return (
      <div className={classNames('cell-well', selectionOutline)} key={wellId}>
        <Well
          editMode={editMode}
          wellId={wellId}
          well={well}
          wellReplicateNumberFormatter={wellReplicateNumberFormatter}
          setWellRef={this.setWellRef}
          isExcluded={isExcluded}
          isSelected={isSelected}
          isHighlighted={isHighlighted}
          plateTargets={plateTargets}
        >
          {wellId}
        </Well>
      </div>
    );
  }

  render() {
    return (
      <div className="table-well" id="table-well">
        {this.renderTBody()}
      </div>
    );
  }
}

export default Plate;
