import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';
import { OrderedMap, List, Map, Set } from 'immutable';
import AmplificationChart from './AmplificationChart';
import MeltChart from './MeltChart';
import ResultsPlate from './ResultsPlate';
import AnalysisResultsTable from './AnalysisResultsTable';
import DeprecatedDataLoader from '../../common/DeprecatedDataLoader';
import { getOrUpdateStepDataIfNeeded as getOrUpdateStepDataIfNeededAction } from '../../../actions/qpcrdata_actions';
import {
  clearStepError,
  updateCqDataSortOrder,
  setAmpChartScaleType,
  setAnalysisMode
} from '../../../actions/currentCfxRun_actions';
import { SecondaryButton } from '../../../frontend-common-libs/src/components/common/buttons';
import {
  shouldUpdateStep,
  stepCalculationErrored,
  getExcludedWells,
  isSaveInProgress as getIsSaveInProgress,
  getSelectedStepNumber,
  getAnalysisMode,
  getPerStepAnalysisSettingsForStepGroupMode,
  getCqDataSortOrder,
  getSubtractBaseline,
  getResultsStep,
  getSelectedWells,
  getTargetsOrFluorsToShow,
  getAmpChartScale,
  getCurrentThresholds,
  getPlate,
  getDriftCorrection
} from '../../../selectors/selectors';
import Spinner from '../../../frontend-common-libs/src/components/common/Spinner';
import { State } from '../../../types';
import { AnalysisResultsStep } from '../../../selectors/current-cfx-run-types';
import CqData from '../../../selectors/CqData';
import MeltData from '../../../selectors/MeltData';

export type Props = {
  entityId: string;
  selectedStep: number;
  groupMode: string;
  plate: Map<string, any>;
  shouldLoadStep: boolean;
  excludedWells: List<string>;
  stepError: boolean;
  getOrUpdateStepDataIfNeeded: typeof getOrUpdateStepDataIfNeededAction;
  resetStepError: typeof clearStepError;
  saveAction?: (...args: Array<any>) => any;
  isSaveInProgress: boolean;
  isAnalysisView: boolean;
  perStepAnalysisSettings: Map<string, any>;
  dataTableSortOrder: OrderedMap<string, any>;
  dataTableSortedData: Map<string, any>;
  dataTableHeaders: List<string>;
  updateDataTableSortOrder: (...args: Array<any>) => any;
  isAmpStepSelected: boolean;
  step: AnalysisResultsStep;
  targetsOrFluorsToShow: Set<string>;
  wellsToShow: Set<string>;
  subtractBaseline: boolean;
  scale: string;
  setChartScaleType: typeof setAmpChartScaleType;
  analysisMode: string;
  changeAnalysisMode: typeof setAnalysisMode;
  currentThresholds: Map<string, any>;
  resultsData: CqData | MeltData;
  driftCorrection: boolean;
};

export class PCRResultsImpl extends PureComponent<Props> {
  static defaultProps = {
    saveAction: undefined
  };

  loadStepData = async () => {
    const {
      entityId,
      selectedStep,
      groupMode,
      plate,
      excludedWells,
      perStepAnalysisSettings,
      isAmpStepSelected,
      getOrUpdateStepDataIfNeeded,
      driftCorrection
    } = this.props;
    const stepAnalysisCompute = {
      entityId,
      step: selectedStep,
      groupMode,
      plate,
      excludedWells,
      perStepAnalysisSettings,
      isAmpStepSelected,
      driftCorrection
    };
    await getOrUpdateStepDataIfNeeded(stepAnalysisCompute);
  };

  clearStepError = () => {
    const { resetStepError, entityId, selectedStep, groupMode } = this.props;
    resetStepError(entityId, selectedStep, groupMode);
  };

  renderComponents = () => {
    const {
      shouldLoadStep,
      selectedStep,
      saveAction,
      isSaveInProgress,
      isAnalysisView,
      dataTableSortOrder,
      dataTableSortedData,
      dataTableHeaders,
      updateDataTableSortOrder,
      isAmpStepSelected,
      step,
      targetsOrFluorsToShow,
      wellsToShow,
      subtractBaseline,
      scale,
      setChartScaleType,
      analysisMode,
      changeAnalysisMode,
      currentThresholds,
      resultsData
    } = this.props;
    const resultsPlateClass = 'pcr-plate-grid';
    const tableName = isAmpStepSelected ? 'Cq Data' : 'Melt Data';

    return (
      <DeprecatedDataLoader
        shouldLoad={shouldLoadStep}
        displayName={`Step-${selectedStep} data`}
        getData={this.loadStepData}
      >
        <div className="pcr-results">
          <div className="pcr-results-wrapper">
            <div className="pcr-data-and-chart">
              <div className="pcr-chart">
                {isAmpStepSelected ? (
                  <AmplificationChart
                    step={step}
                    targetsOrFluorsToShow={targetsOrFluorsToShow}
                    wellsToShow={wellsToShow}
                    subtractBaseline={subtractBaseline}
                    scale={scale}
                    setAmpChartScaleType={setChartScaleType}
                    analysisMode={analysisMode}
                    changeAnalysisMode={changeAnalysisMode}
                    currentThresholds={currentThresholds}
                    resultsData={resultsData}
                  />
                ) : (
                  <MeltChart
                    step={step}
                    targetsOrFluorsToShow={targetsOrFluorsToShow}
                    wellsToShow={wellsToShow}
                    analysisMode={analysisMode}
                    changeAnalysisMode={changeAnalysisMode}
                    resultsData={resultsData}
                  />
                )}
              </div>
              <div className="pcr-plate-grid-wrapper">
                <div className={resultsPlateClass}>
                  <ResultsPlate
                    plateWrapperSelector={`.${resultsPlateClass}`}
                    isAnalysisView={isAnalysisView}
                  />
                </div>
              </div>
            </div>
            <div className="pcr-qc-data">
              <div id="pcr-data-table" className="br-data-table">
                <AnalysisResultsTable
                  updateSortOrder={updateDataTableSortOrder}
                  sortOrder={dataTableSortOrder}
                  sortedData={dataTableSortedData}
                  tableHeaders={dataTableHeaders}
                  tableName={tableName}
                />
              </div>
              <div className="button-group right">
                <SecondaryButton
                  id="save-results-button"
                  onClick={saveAction}
                  isInProgress={isSaveInProgress}
                  disabled={!saveAction || isSaveInProgress}
                  type="button"
                >
                  {isSaveInProgress ? (
                    <>
                      <Spinner />
                      Saving...
                    </>
                  ) : (
                    'Save'
                  )}
                </SecondaryButton>
              </div>
            </div>
          </div>
        </div>
      </DeprecatedDataLoader>
    );
  };

  renderErrorRetry = () => (
    <div className="alert alert-danger">
      Error retrieving step data
      <Button bsStyle="primary" className="margin-left-10" onClick={this.clearStepError}>
        Try Again
      </Button>
    </div>
  );

  renderContent = () => {
    const { stepError } = this.props;
    if (stepError) return this.renderErrorRetry();
    return this.renderComponents();
  };

  render() {
    return this.renderContent();
  }
}

function mapStateToProps(state: State) {
  const selectedStep = getSelectedStepNumber(state);
  const groupMode = getAnalysisMode(state);
  const plate = getPlate(state);
  const excludedWells = getExcludedWells(state);
  const stepError = stepCalculationErrored(state);
  const isSaveInProgress = getIsSaveInProgress(state);
  const perStepAnalysisSettings = getPerStepAnalysisSettingsForStepGroupMode(state);
  const driftCorrection = getDriftCorrection(state);

  // AnalysisResultsTable props
  const dataTableSortOrder = getCqDataSortOrder(state) || OrderedMap();

  // Chart props
  const step = getResultsStep(state);
  const targetsOrFluorsToShow = getTargetsOrFluorsToShow(state);
  const wellsToShow = getSelectedWells(state);
  const subtractBaseline = getSubtractBaseline(state);
  const scale = getAmpChartScale(state);
  const analysisMode = getAnalysisMode(state);
  const currentThresholds = step ? getCurrentThresholds(state) : Map();

  return {
    shouldLoadStep: !stepError && shouldUpdateStep(state),
    selectedStep,
    groupMode,
    plate,
    excludedWells,
    stepError,
    isSaveInProgress,
    perStepAnalysisSettings,
    dataTableSortOrder,
    step,
    targetsOrFluorsToShow,
    wellsToShow,
    subtractBaseline,
    scale,
    analysisMode,
    currentThresholds,
    driftCorrection
  };
}

export default connect(mapStateToProps, {
  getOrUpdateStepDataIfNeeded: getOrUpdateStepDataIfNeededAction,
  resetStepError: clearStepError,
  updateDataTableSortOrder: updateCqDataSortOrder,
  setChartScaleType: setAmpChartScaleType,
  changeAnalysisMode: setAnalysisMode
  // @ts-ignore
})(PCRResultsImpl);
