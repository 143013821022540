import React, { PureComponent, RefObject } from 'react';
import imgUploadBlue from '../../../img/upload-icon-blue.svg';
import imgUploadDisabled from '../../../img/upload-icon-disabled.svg';
import styles from './styles/OpenFileDialogButton.module.scss';

type Props = {
  onFilesSelected: (files: Array<File>) => any;
  multiple: boolean;
  disabled?: boolean;
  tooltip?: string;
  accept?: string;
};

export default class OpenFileDialogButton extends PureComponent<Props> {
  fileUploaderRef: RefObject<any>;

  constructor(props: Props) {
    super(props);
    this.fileUploaderRef = React.createRef();
  }

  openFileBrowser = () => {
    const fileInput = this.fileUploaderRef.current;
    fileInput.value = null;
    fileInput.click();
  };

  afterFileBrowserClosed = () => {
    const { onFilesSelected } = this.props;
    const fileInput = this.fileUploaderRef.current;
    const files = [...fileInput.files]; // convert to array
    if (files == null || files.length <= 0) return;
    onFilesSelected(files);
  };

  render() {
    const { multiple, disabled, accept } = this.props;
    const imgActive = imgUploadBlue;
    const img = disabled ? imgUploadDisabled : imgActive;

    return (
      <>
        <button
          tabIndex={0}
          id="upload-file"
          type="button"
          onClick={this.openFileBrowser}
          disabled={disabled}
          className={styles.uploadButton}
        >
          <div>
            <img src={img} alt="Upload File" role="presentation" />
            <span>Upload</span>
          </div>
        </button>
        <input
          type="file"
          id="open-file"
          ref={this.fileUploaderRef}
          style={{ display: 'none' }}
          multiple={multiple}
          onChange={this.afterFileBrowserClosed}
          accept={accept}
        />
      </>
    );
  }
}
