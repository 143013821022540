import LocalFeatureFlagClient from './LocalFeatureFlagClient';
import CloudFeatureFlagClient from './CloudFeatureFlagClient';
import FeatureFlagKeys from './FeatureFlagKeys';
import getEnvironmentConfig from '../../config/EnvironmentConfiguration';

class FeatureFlags {
  constructor(cloudClient: CloudFeatureFlagClient) {
    this._cloudClient = cloudClient;
  }

  _cloudClient: CloudFeatureFlagClient;

  get = (key: string): any => {
    if (FeatureFlagKeys.isLocalOnly(key) || this._shouldCheckLocalFlags()) {
      return this._getFromLocalClient(key) || this._getFromCloudClient(key);
    }

    return this._getFromCloudClient(key);
  };

  _isDeveloper = () => this._getFromCloudClient(FeatureFlagKeys.DEVELOPER);

  _shouldCheckLocalFlags = (): boolean => this._isDeveloper() || getEnvironmentConfig().isLocalhost;

  _getFromCloudClient = (key: string): any => this._cloudClient.get(key);

  _getFromLocalClient = (key: string): any => LocalFeatureFlagClient.get(key);

  static async createFeatureFlags(email: string | null | undefined): Promise<FeatureFlags> {
    const cloudClient = await CloudFeatureFlagClient.create(email);
    return new FeatureFlags(cloudClient);
  }
}

let instance: FeatureFlags | undefined;

export type FeatureFlagsType = FeatureFlags;

export async function initializeFeatureFlags(email?: string): Promise<void> {
  instance = await FeatureFlags.createFeatureFlags(email);
}

export async function handleLogin(email: string): Promise<void> {
  await initializeFeatureFlags(email);
}

export async function handleLogout(): Promise<void> {
  await initializeFeatureFlags();
}

export default function FeatureFlagsInstance(): FeatureFlagsType {
  if (!instance) {
    throw new Error('Feature flags should not be called prior to being loaded');
  }
  return instance;
}

export function resetFeatureFlags(): void {
  instance = undefined;
}
