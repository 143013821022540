import React from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import { RouteComponentProps } from 'react-router';
import notification from '../../../frontend-common-libs/src/utils/notifications';
import {
  createProtocol as createProtocolAction,
  dispatchProtocolClosed as dispatchProtocolClosedAction,
  getPCRProtocolIfNeeded as getPCRProtocolIfNeededAction,
  getQpcrProtocolFromEntity,
  protocolVersionConflict,
  updatePCRProtocol as updatePCRProtocolAction
} from '../../../actions/pcr_protocol_actions';
import QPcrProtocol from '../../../frontend-common-libs/src/components/pcr/pcr-protocols/models/QPcrProtocol';
import coreRoutes from '../../../core/routes';
import realTimePcrRoutes from '../../../real-time-pcr/routes';
import { ReduxState } from '../../../types';
import { ProjectId } from '../../../frontend-common-libs/src/common/project-management-types';
import { getSelectedProjectId } from '../../../project-management';
import VersionConflictPrompt from '../../../frontend-common-libs/src/components/files/conflict-dialog/VersionConflictPrompt';
import VersionConflictPromptVm from '../../../frontend-common-libs/src/components/files/conflict-dialog/VersionConflictPromptVm';
import changeProjectSelectionAction from '../../../project-management/actions/change-project-selection';
import { PcrProtocolEditor } from '../../../frontend-common-libs/src/components/pcr/pcr-protocols/PcrProtocolEditor';
import ErrorAlert from '../../../frontend-common-libs/src/components/common/ErrorAlert';
import {
  TrackedFilesEvents,
  trackEvent
} from '../../../frontend-common-libs/src/user-analytics/trackedEvents';
import {
  canEditFilesInProject,
  isSelectedProjectTokenLoaded
} from '../../../project-management/selectors/selectors';
import Loader from '../../../frontend-common-libs/src/components/common/Loader';

const PATH_TO_TEMPLATES = `${coreRoutes.APP}${realTimePcrRoutes.QPCR_PROTOCOL_LIST}`;

export type Props = {
  history?: RouteComponentProps['history'];
  createProtocol?: (
    fileName: string,
    protocol: QPcrProtocol,
    projectId: ProjectId
  ) => Promise<void>;
  updatePCRProtocol?: (
    entityId: string,
    protocolID: string,
    newProtocol: QPcrProtocol
  ) => Promise<void>;
  getPCRProtocolIfNeeded?: (
    entityId: string,
    protocolID: string,
    versionNumber: number
  ) => Promise<any>;
  templateEntityId?: string;
  templateEntity?: Map<string, any>;
  template?: Map<string, any>;
  disabled: boolean;
  isCreate: boolean;
  isPendingRun: boolean;
  selectedProjectId: ProjectId;
  versionNumber: number;
  dispatchProtocolClosed: (entityId: string) => void;
  changeProjectSelection: any;
  showProtocolName: boolean;
  hasEditPermissions?: boolean;
  isProjectTokenLoaded: boolean;
};

export type State = {
  done: boolean;
  error: string;
  isLoading: boolean;
  pcrProtocol: QPcrProtocol;
  redirectToPlate: boolean;
  versionConflict: VersionConflictPromptVm;
  reloadCounter: number;
};

export class QpcrProtocolImpl extends React.Component<Props, State> {
  static defaultProps = {
    templateEntityId: undefined,
    templateEntity: undefined,
    template: undefined,
    disabled: false,
    isCreate: true,
    history: undefined,
    createProtocol: undefined,
    updatePCRProtocol: undefined,
    getPCRProtocolIfNeeded: undefined,
    selectedProjectId: undefined,
    versionNumber: undefined,
    dispatchProtocolClosed: undefined,
    changeProjectSelection: undefined,
    showProtocolName: true,
    isProjectTokenLoaded: true
  };

  constructor(props: Props) {
    super(props);
    const defaultState = {
      done: false,
      error: '',
      isLoading: false,
      redirectToPlate: false,
      versionConflict: new VersionConflictPromptVm(this.onReload, this.onSaveAs),
      reloadCounter: 1
    };
    if (props.isCreate) {
      this.state = {
        ...defaultState,
        pcrProtocol: QPcrProtocol.default()
      };
    } else {
      let pcrProtocol;
      let isLoading = true;
      if (props.templateEntity) {
        let protocol;
        if (props.template && props.template.get('protocol')) {
          protocol = props.template.get('protocol');
          isLoading = false;
        }
        pcrProtocol = QPcrProtocol.fromEntity(props.templateEntity, protocol);
      } else if (props.template && props.template.get('protocol')) {
        pcrProtocol = QPcrProtocol.runProtocol(props.template.get('protocol'));
        isLoading = false;
      } else {
        pcrProtocol = QPcrProtocol.empty();
      }

      this.state = {
        ...defaultState,
        pcrProtocol,
        isLoading
      };
    }
  }

  loadQpcrProtocol = async () => {
    const { templateEntity, template } = this.props;
    const { isLoading, pcrProtocol } = this.state;
    if (isLoading) {
      if (!templateEntity || !pcrProtocol.templateId) {
        await this.getTemplateEntity();
      } else if (!template || !template.get('protocol')) {
        await this.getTemplate(pcrProtocol);
      }
    }
    const { pcrProtocol: newPcrProtocol } = this.state;
    return newPcrProtocol;
  };

  componentWillUnmount() {
    const { dispatchProtocolClosed, templateEntityId } = this.props;
    if (templateEntityId) dispatchProtocolClosed(templateEntityId);
  }

  getTemplateEntity = async () => {
    try {
      const { templateEntityId, changeProjectSelection } = this.props;
      if (templateEntityId) {
        const pcrProtocol = await getQpcrProtocolFromEntity(templateEntityId);
        changeProjectSelection(pcrProtocol.projectId);
        await this.getTemplate(pcrProtocol);
      }
    } catch (e) {
      const errorMessage = e instanceof Error && e.message ? e.message : 'Failed to load';
      this.setState({
        error: errorMessage,
        isLoading: false
      });
    }
  };

  getTemplate = async (pcrProtocol: QPcrProtocol) => {
    try {
      const { templateEntityId, getPCRProtocolIfNeeded } = this.props;
      if (templateEntityId && getPCRProtocolIfNeeded) {
        const protocolTemplate = await getPCRProtocolIfNeeded(
          templateEntityId,
          pcrProtocol.templateId,
          pcrProtocol.versionNumber
        );
        const newPcrProtocol = pcrProtocol.setProtocol(protocolTemplate);
        this.setState({
          isLoading: false,
          pcrProtocol: newPcrProtocol
        });
      }
    } catch (e) {
      const errorMessage = e instanceof Error && e.message ? e.message : 'Failed to load';
      this.setState({
        isLoading: false,
        error: errorMessage
      });
    }
  };

  handleRedirectToPlate = () => {
    this.setState({
      redirectToPlate: true
    });
  };

  onApplyPressed = async (protocol: QPcrProtocol) => {
    const { isCreate } = this.props;
    if (isCreate) await this.createProtocol(protocol);
    else {
      await this.updateProtocol(protocol);
    }
  };

  createProtocol = async (pcrProtocol: QPcrProtocol) => {
    try {
      const { createProtocol, selectedProjectId } = this.props;
      if (createProtocol) {
        await createProtocol(pcrProtocol.name, pcrProtocol, selectedProjectId);
        this.setState({
          done: true
        });
        trackEvent(TrackedFilesEvents.CfxCreateProtocol, { fileName: pcrProtocol.name });
      }
    } catch (e) {
      const errorMessage = e instanceof Error && e.message ? e.message : 'Error adding protocol';
      notification.error(errorMessage);
    }
  };

  updateProtocol = async (pcrProtocol: QPcrProtocol) => {
    this.setState({ pcrProtocol });
    const { versionConflict } = this.state;
    versionConflict.beforeSave(pcrProtocol);
    const { templateEntityId, updatePCRProtocol } = this.props;
    if (templateEntityId) {
      try {
        // only update protocol if the protocol has changed
        if (pcrProtocol.hasChanges() && updatePCRProtocol) {
          await updatePCRProtocol(templateEntityId, pcrProtocol.templateId, pcrProtocol);
          trackEvent(TrackedFilesEvents.CfxEditProtocol, { fileName: pcrProtocol.name });
        }
        this.setState({ done: true });
      } catch (e) {
        const errorMessage = e instanceof Error && e.message ? e.message : 'Error saving protocol';
        if (errorMessage === protocolVersionConflict)
          versionConflict.showVersionConflictDialog(pcrProtocol.name);
        else notification.error('Error saving protocol');
      }
    }
  };

  cancel = () => {
    const { history } = this.props;
    if (history) {
      history.replace(PATH_TO_TEMPLATES);
    }
  };

  onReload = async () => {
    const { dispatchProtocolClosed, templateEntityId } = this.props;
    const { reloadCounter, versionConflict } = this.state;
    if (templateEntityId) dispatchProtocolClosed(templateEntityId);
    this.setState({
      isLoading: true,
      pcrProtocol: QPcrProtocol.empty(),
      reloadCounter: reloadCounter + 1
    });
    versionConflict.hideDialog();
  };

  onSaveAs = async (fileName: string) => {
    const { pcrProtocol } = this.state;
    await this.createProtocol(pcrProtocol.updateName(fileName));
  };

  renderProtocol() {
    const { redirectToPlate, versionConflict, error, reloadCounter } = this.state;
    const {
      disabled,
      showProtocolName,
      isCreate,
      isPendingRun,
      createProtocol: isNewProtocol,
      hasEditPermissions,
      isProjectTokenLoaded
    } = this.props;
    if (!isProjectTokenLoaded) return <Loader />;
    if (error) {
      return <ErrorAlert error={error} errorId="protocol-archived-error" />;
    }
    if (isCreate && !hasEditPermissions)
      return (
        <ErrorAlert
          error="Reviewers cannot create protocols within a project."
          errorId="protocol-create-permissions-error"
        />
      );
    if (redirectToPlate) {
      return <Redirect push to={`${coreRoutes.APP}${realTimePcrRoutes.CREATE_QPCR_RUN_PLATE}`} />;
    }

    return (
      <>
        <VersionConflictPrompt versionConflictPromptVm={versionConflict} />
        <PcrProtocolEditor
          reloadCounter={reloadCounter}
          loadProtocol={this.loadQpcrProtocol}
          showProtocolName={showProtocolName}
          disabled={disabled || !hasEditPermissions}
          enableApplyIfNoChanges={isCreate}
          applyButtonText={isCreate ? 'Add' : 'Save'}
          onApplyPressed={this.onApplyPressed}
          onCancelPressed={this.cancel}
          showNextButton={isPendingRun && !isCreate && !isNewProtocol}
          nextButtonText="Continue to Plate Setup"
          onNextPressed={this.handleRedirectToPlate}
        />
      </>
    );
  }

  render() {
    const { done } = this.state;
    if (done) {
      return <Redirect to={PATH_TO_TEMPLATES} />;
    }
    return this.renderProtocol();
  }
}

function mapStateToProps(
  state: ReduxState,
  ownProps: {
    [key: string]: any;
  }
): {
  [key: string]: any;
} {
  const { entityId } = ownProps.match.params;
  const selectedProjectId = getSelectedProjectId(state);
  const hasEditPermissions = canEditFilesInProject(state, selectedProjectId);
  const isProjectTokenLoaded = isSelectedProjectTokenLoaded(state);
  if (entityId) {
    const templateEntityId = entityId;
    const protocolEntity = state.protocolTemplates.getIn([
      'projects',
      selectedProjectId,
      'protocols',
      templateEntityId
    ]);
    const protocol = state.protocols.get(templateEntityId);

    return {
      isCreate: false,
      templateEntityId,
      templateEntity: protocolEntity,
      template: protocol,
      entityId,
      selectedProjectId,
      hasEditPermissions,
      isProjectTokenLoaded
    };
  }

  return {
    isCreate: true,
    selectedProjectId,
    hasEditPermissions,
    isProjectTokenLoaded
  };
}

export default withRouter(
  // @ts-ignore
  connect(mapStateToProps, {
    createProtocol: createProtocolAction,
    updatePCRProtocol: updatePCRProtocolAction,
    getPCRProtocolIfNeeded: getPCRProtocolIfNeededAction,
    dispatchProtocolClosed: dispatchProtocolClosedAction,
    changeProjectSelection: changeProjectSelectionAction
    // @ts-ignore
  })(QpcrProtocolImpl)
);
