import BrowserWindow from '../common/BrowserWindow';

export interface EnvConfig {
  COGNITO_CONFIG: {
    USERPOOL_ID: string;
    IDENTITYPOOL_ID: string;
    REGION: string;
    APPCLIENT_ID: string;
    INSTRUMENT_V2_APPCLIENT_ID: string;
  };
  IOT_CONFIG: {
    ENDPOINT: string;
  };
}

type EnvKeys = 'dev' | 'prod' | 'labDev';
export type EnvsConfig = Record<EnvKeys, EnvConfig>;

const envConfig: EnvsConfig = {
  dev: {
    COGNITO_CONFIG: {
      USERPOOL_ID: 'us-west-2_JiUIsc2yP',
      IDENTITYPOOL_ID: 'us-west-2:4c11d84c-fd9a-42f0-b4f3-be564ad4cc04',
      REGION: 'us-west-2',
      APPCLIENT_ID: '21e6g51p93gg0g2ehg46o83j8d',
      INSTRUMENT_V2_APPCLIENT_ID: '70t3d7vnl74gs9mm7meut6rg7p'
    },
    IOT_CONFIG: {
      ENDPOINT: 'anpo9j5hnahgi-ats.iot.us-west-2.amazonaws.com'
    }
  },
  prod: {
    COGNITO_CONFIG: {
      USERPOOL_ID: 'us-west-2_h3owOLeP4',
      IDENTITYPOOL_ID: 'us-west-2:6b1cf01c-9a4b-4a07-918e-904486d18e90',
      REGION: 'us-west-2',
      APPCLIENT_ID: '34792bi67p1438epv10ghlc1b4',
      INSTRUMENT_V2_APPCLIENT_ID: '746gbeck0g9eduoaeec93415ci'
    },
    IOT_CONFIG: {
      ENDPOINT: 'a3hnxuoxgl7f12-ats.iot.us-west-2.amazonaws.com'
    }
  },
  labDev: {
    COGNITO_CONFIG: {
      USERPOOL_ID: 'us-west-2_TtX4ahV0f',
      IDENTITYPOOL_ID: 'us-west-2:4509fd0f-ec4f-4ee3-baf7-e06cc32aa48c',
      REGION: 'us-west-2',
      APPCLIENT_ID: '4g3tv3p3l0jrhfvcdvc2q3slh2',
      INSTRUMENT_V2_APPCLIENT_ID: '6j5r7r8bd3d16u30kgajp4iv2v'
    },
    IOT_CONFIG: {
      ENDPOINT: 'a1to1zw51mgni9-ats.iot.us-west-2.amazonaws.com'
    }
  }
};

class EnvironmentConfiguration {
  env: EnvKeys;

  isLocalhost: boolean;

  apiDomain: string;

  config: EnvConfig;

  constructor(domain: string) {
    this.isLocalhost = EnvironmentConfiguration.getIsLocalhost(domain);
    this.env = EnvironmentConfiguration.getEnv(domain, this.isLocalhost);
    this.apiDomain = EnvironmentConfiguration.getApiDomain(domain, this.env, this.isLocalhost);
    this.config = this.getConfig();
  }

  static getIsLocalhost(domain: string): boolean {
    return domain.includes('localhost');
  }

  static getEnv(domain: string, isLocalhost: boolean): EnvKeys {
    if (
      domain === 'www.br.io' ||
      domain === 'www.brprodcloud.cloud' ||
      domain === 'www.brtesting.rocks'
    ) {
      return 'prod';
    }

    let env;
    const domainParts = domain.split('.');
    [env] = domainParts;

    if (env === 'www' && !isLocalhost) {
      [, env] = domainParts;
    }

    return env as EnvKeys;
  }

  static getApiDomain(domain: string, env: EnvKeys, isLocalhost: boolean): string {
    if (!isLocalhost) {
      if (domain === 'www.brtesting.rocks') {
        return 'br.io';
      }
      return domain.replace('www.', '');
    }

    if (env === 'prod') {
      return 'br.io';
    }

    return `${env}.brcloud.cloud`;
  }

  isLabAccount(): boolean {
    return this.apiDomain === 'dev.brlabcloud.cloud';
  }

  getConfig(): EnvConfig {
    return this.isLabAccount() ? envConfig.labDev : envConfig[this.env];
  }
}

let environmentConfigInstance: EnvironmentConfiguration | undefined;

export default (): EnvironmentConfiguration => {
  if (!environmentConfigInstance) {
    environmentConfigInstance = new EnvironmentConfiguration(BrowserWindow.getHostName());
  }
  return environmentConfigInstance;
};

export function resetInstance(): void {
  environmentConfigInstance = undefined;
}
