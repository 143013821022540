export const TimeRemainingNA = '- - -';

export function getTimeRemaining(time: number): string {
  if (!time || Math.floor(time) === 0) return TimeRemainingNA;
  const hours = Math.floor(time / 3600);
  const minutes = Math.floor((time % 3600) / 60);
  const minutesFormat = minutes < 10 ? `0${minutes}` : minutes;
  const seconds = (time % 3600) % 60;
  const secondsFormat = seconds < 10 ? `0${seconds}` : seconds;
  if (hours < 1 && minutes < 1) return `${seconds} secs`;
  if (hours < 1) return `${minutes} mins, ${secondsFormat} secs`;
  return `${hours} hr, ${minutesFormat} mins`;
}
