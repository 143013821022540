import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import startRunStyles from './styles/start-run-modal.module.scss';
import instrumentFleetModalStyles from '../create-reservations-tab/instrument-fleet-modal/styles/instrument-fleet-modal.module.scss';
import StartRun from '../instrument-control-tab/actions/assets/icons-instrument-start-run.svg';

export type Props = {
  handleStartRun: () => Promise<void>;
  onClose: () => void;
  show: boolean;
};

export default function StopRunModal(props: Readonly<Props>) {
  const { handleStartRun, onClose, show } = props;
  const startRunModalBody = () => {
    const { centerContent, startRunImage, contentHeader, contentBold, contentMessage } =
      startRunStyles;
    const { fleetReservationModalBody } = instrumentFleetModalStyles;
    return (
      <Modal.Body className={fleetReservationModalBody}>
        <div className={centerContent}>
          <div>
            <img className={startRunImage} src={StartRun} alt="Start Run Disclaimer" />
          </div>
          <div className={contentHeader}>Confirm Start</div>
          <div id="start-run-modal-text" className={contentMessage}>
            Verify that the reaction tubes are on the instrument or confirm that you want to start
            the run without them.
            <br />
            <br />
            Click <span className={contentBold}>Start</span> to start the run with or without the
            reaction tubes.
            <br />
            <br />
            Click <span className={contentBold}>Cancel</span> to return to the Instrument Control
            screen.
            <br />
            <br />
            If no reaction tubes are present, you must confirm the start on the instrument interface
            as well.
          </div>
        </div>
      </Modal.Body>
    );
  };

  const startRunModalFooter = () => {
    const { cancelBtn, startBtn } = startRunStyles;
    const { fleetReservationModalFooter } = instrumentFleetModalStyles;
    return (
      <Modal.Footer className={fleetReservationModalFooter}>
        <Button
          className={cancelBtn}
          onClick={onClose}
          aria-label="cancel"
          id="start-run-modal-cancel-button"
        >
          Cancel
        </Button>
        <Button
          className={startBtn}
          onClick={handleStartRun}
          aria-label="start"
          id="start-run-modal-start-button"
        >
          Start
        </Button>
      </Modal.Footer>
    );
  };

  return (
    <Modal
      backdrop="static"
      className={startRunStyles.startRunModal}
      onHide={onClose}
      show={show}
      data-testid="start-run-modal"
    >
      {startRunModalBody()}
      {startRunModalFooter()}
    </Modal>
  );
}
