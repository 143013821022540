import React from 'react';
import profileBtnImg from '../../../img/user.svg';
import ButtonMenu, { Header, Separator, TextMenuItem } from '../../common/button_menu';
import FeatureFlags, {
  FeatureFlagKeys
} from '../../frontend-common-libs/src/components/feature_flags';
import { NotificationIcon } from '../../frontend-common-libs/src/components/common/notifications/NotificationIcon';

type Props = {
  email: string;
  notification?: any;
  onLogoutSelected: () => void;
  onUserProfileSelected: () => void;
  onShowUserPreferencesPanel: () => void;
  onUserOrganizationsSelected: () => void;
  onSettingsSelected: () => void;
};

function ProfileMenu({
  email,
  onLogoutSelected,
  onUserProfileSelected,
  onShowUserPreferencesPanel,
  notification,
  onUserOrganizationsSelected,
  onSettingsSelected
}: Props) {
  return (
    <ButtonMenu
      menuId="profile-buttonmenu"
      btnImage={profileBtnImg}
      notification={
        <NotificationIcon
          notificationsNumber={notification}
          notificationsLimit={9}
          className="notification-icon-profile-menu"
        />
      }
    >
      <Header id="profile-user-name" text={email} />
      <Separator />
      <TextMenuItem
        text="Profile Information"
        onSelection={onUserProfileSelected}
        id="user-profile-link"
      />
      {FeatureFlags().get(FeatureFlagKeys.SETTINGS_PAGE) && (
        <TextMenuItem text="Settings" onSelection={onSettingsSelected} id="settings-link" />
      )}
      <TextMenuItem
        text="User Preferences"
        onSelection={onShowUserPreferencesPanel}
        id="user-preferences-link"
      />
      <TextMenuItem
        text="Organizations"
        onSelection={onUserOrganizationsSelected}
        id="user-organizations-link"
      />
      <TextMenuItem text="Logout" id="logout-link" onSelection={onLogoutSelected} />
    </ButtonMenu>
  );
}

export default ProfileMenu;
