import React from 'react';
import orgIcon from '../../../img/organization.svg';
import { Organization } from '../actions/action-types';
import TenantRow from './TenantRow';

export type Props = {
  org: Organization;
  onClick: (org: Organization) => void;
  isSelected?: boolean;
  isActive?: boolean;
};

export default function OrgRow(props: Readonly<Props>) {
  const { org, isSelected, isActive, onClick } = props;
  return (
    <TenantRow
      onClick={onClick}
      isSelected={isSelected}
      isActive={isActive}
      tenant={org}
      tenantIcon={orgIcon}
    />
  );
}
