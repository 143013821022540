import { OrgRoles } from '@biorad-lsg-tsc/organization';
import request from '../../frontend-common-libs/src/utils/httpUtils';
import {
  baseURL,
  accessControlBasePath,
  getUserIdRequestHeader,
  getUserTokenHeader
} from './access-control';

export const basePath = `${accessControlBasePath}/org`;

export async function login(email: string, userSubId: string): Promise<any> {
  const path = `${basePath}/login`;
  const body = { userEmail: email };
  const response = await request.post(baseURL, {}, path, getUserIdRequestHeader(userSubId), body);
  return response.data;
}

export async function createOrg(
  organizationName: string,
  description: string | undefined,
  userToken: string
): Promise<any> {
  const path = `${basePath}`;
  const body = { organizationName, description };
  const response = await request.post(baseURL, {}, path, getUserTokenHeader(userToken), body);
  return response.data;
}

export async function getUserOrgs(userToken: string): Promise<any> {
  const path = `${basePath}/user-orgs`;
  const response = await request.get(baseURL, {}, path, getUserTokenHeader(userToken));
  return response.data;
}

export async function getOrgDetails(orgId: string, userToken: string): Promise<any> {
  const path = `${basePath}/details/${orgId}`;
  const response = await request.get(baseURL, {}, path, getUserTokenHeader(userToken));
  return response.data;
}

export async function addUserToOrganization(
  orgId: string,
  user: string,
  userToken: string,
  userRole?: OrgRoles
): Promise<any> {
  const path = `${basePath}/add-user`;
  const body = { organizationId: orgId, user, userRole };
  const response = await request.post(baseURL, {}, path, getUserTokenHeader(userToken), body);
  return response.data;
}

export async function removeUserFromOrganization(
  orgId: string,
  userEmail: string,
  userToken: string
): Promise<any> {
  const path = `${basePath}/remove-user`;
  const body = { organizationId: orgId, userEmail };
  const response = await request.post(baseURL, {}, path, getUserTokenHeader(userToken), body);
  return response.data;
}

export async function setActiveOrganization(
  organizationId: string,
  userToken: string
): Promise<any> {
  const path = `${basePath}/active`;
  const body = { organizationId };
  const response = await request.put(baseURL, {}, path, getUserTokenHeader(userToken), body);
  return response.data;
}

export async function setOrgMemberRole(
  organizationId: string,
  userName: string,
  userRole: string,
  userToken: string
): Promise<any> {
  const path = `${basePath}/assign-role`;
  const body = { organizationId, userEmail: userName, userRole };
  const response = await request.put(baseURL, {}, path, getUserTokenHeader(userToken), body);
  return response.data;
}

export async function editOrg(
  organizationId: string,
  organizationName: string | undefined,
  description: string | undefined,
  userToken: string
): Promise<any> {
  const path = `${basePath}/edit`;
  const body = { organizationId, organizationName, description };
  const response = await request.put(baseURL, {}, path, getUserTokenHeader(userToken), body);
  return response.data;
}

export async function refreshOrgAccessToken(refreshToken: string, userToken: string): Promise<any> {
  const path = `${basePath}/refresh-access-token`;
  const body = { refreshToken };
  const response = await request.post(baseURL, {}, path, getUserTokenHeader(userToken), body);
  return response.data;
}
