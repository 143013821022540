import _ from 'lodash';
import { fromJS, Map } from 'immutable';
import { getInstrumentList, unlinkUserInstrument } from '../api/instruments.api';
import {
  INSTRUMENT_ADDED,
  INSTRUMENTS_LOADING,
  INSTRUMENTS_LOADED,
  INSTRUMENTS_ERROR,
  INSTRUMENT_STATUS_UPDATED,
  UNLINKED_INSTRUMENT,
  InstrumentActionType
} from './instrument.types';
import { Dispatch, GetState } from '../../types';
import IOTConnection from '../../iot/iot-connection';
import { jsArrayToOrderedMap } from '../../frontend-common-libs/src/utils/immutableUtils';
import { getPcrData } from '../../api/pcrData';
import { STATUS_EXPIRATION_WINDOW } from '../../frontend-common-libs/src/instruments/instrumentStatusTimeout';

const pendingInstrumentTimeouts: any = {};

export const clearInstrumentTimeouts = () => {
  Object.keys(pendingInstrumentTimeouts).forEach((instrumentId: string) => {
    clearTimeout(pendingInstrumentTimeouts[instrumentId]);
    delete pendingInstrumentTimeouts[instrumentId];
  });
};

function setStatusUpdateTimeout(
  instrumentId: string,
  timeout: number,
  runName: string,
  steps: number,
  statusObject: { [key: string]: any },
  dispatch: Dispatch<
    InstrumentActionType,
    {
      [key: string]: any;
    }
  >
) {
  pendingInstrumentTimeouts[instrumentId] = setTimeout(() => {
    dispatch({
      type: INSTRUMENT_STATUS_UPDATED,
      // @ts-ignore
      payload: fromJS({
        instrumentId,
        isStale: true,
        runName,
        steps,
        statusObject
      })
    });
    delete pendingInstrumentTimeouts[instrumentId];
  }, timeout);
}

async function getPcrRunData(
  getState: GetState,
  instrumentId: string,
  statusObject: { [key: string]: any }
) {
  const instrumentState = getState().instruments.getIn(['statuses', instrumentId], Map()) as Map<
    string,
    any
  >;
  // get the current run id
  const currentRunId = instrumentState.getIn([
    'statusObject',
    'state',
    'reported',
    'details',
    'blkA',
    'runID'
  ]);
  // get the new run id from the statusObject passed in by the IoT Shadow
  const newRunId = _.get(statusObject, ['state', 'reported', 'details', 'blkA', 'runID']);
  // get the current run name and steps
  let runName = instrumentState.get('runName', null);
  let steps = instrumentState.get('steps', null);
  // if the shadow sends a newRunId that's different from the current, update the currentRun info
  if (newRunId && currentRunId !== newRunId) {
    try {
      const { data } = await getPcrData(newRunId);
      runName = data.name;
      ({ steps } = data.protocol);
    } catch (err) {
      runName = null;
      steps = null;
    }
  }

  return { runName, steps };
}

export function updateInstrumentStatus(
  instrumentId: string,
  statusObject: {
    [key: string]: any;
  }
) {
  return async (
    dispatch: Dispatch<
      InstrumentActionType,
      {
        [key: string]: any;
      }
    >,
    getState: GetState
  ) => {
    const currentStatusVersion = getState().instruments.getIn([
      'statuses',
      instrumentId,
      'statusObject',
      'version'
    ]) as number | undefined;
    const newStatusVersion = _.get(statusObject, 'version');

    // ignore old versions
    if (currentStatusVersion && newStatusVersion && currentStatusVersion > newStatusVersion) {
      return;
    }

    const runData = await getPcrRunData(getState, instrumentId, statusObject);
    const { runName, steps } = runData;

    // get timestamp for redux timeout
    const reportedTimestamp = _.get(statusObject, ['metadata', 'reported', 'status', 'timestamp']);
    let isStale = true;
    if (reportedTimestamp) {
      // remove existing timeout
      clearTimeout(pendingInstrumentTimeouts[instrumentId]);

      const timestampInMillis = reportedTimestamp * 1000; // convert timestamp to ms to match Date.now
      const timeout = STATUS_EXPIRATION_WINDOW - (Date.now() - timestampInMillis);
      // set new Timeout if status is NOT already stale
      isStale = timeout ? timeout < 0 : true;
      if (!isStale)
        setStatusUpdateTimeout(instrumentId, timeout, runName, steps, statusObject, dispatch);
    }

    dispatch({
      type: INSTRUMENT_STATUS_UPDATED,
      // @ts-ignore
      payload: fromJS({ instrumentId, statusObject, isStale, runName, steps })
    });
  };
}

function dispatchInstrumentStatus(
  dispatch: Dispatch<
    InstrumentActionType,
    {
      [key: string]: any;
    }
  >
) {
  return (
    instrumentId: string,
    statusObject: {
      [key: string]: any;
    }
  ) => {
    dispatch(updateInstrumentStatus(instrumentId, statusObject));
  };
}

function loadInstrumentList() {
  return async (
    dispatch: Dispatch<
      InstrumentActionType,
      {
        [key: string]: any;
      }
    >
  ) => {
    dispatch({
      type: INSTRUMENTS_LOADING,
      payload: {}
    });
    try {
      const res = await getInstrumentList();
      const instruments = jsArrayToOrderedMap(res.data.devices, 'deviceId');
      if (instruments.size > 0) {
        IOTConnection.startShadow(dispatchInstrumentStatus(dispatch));
      }
      dispatch({
        type: INSTRUMENTS_LOADED,
        payload: instruments
      });
    } catch (e) {
      dispatch({
        type: INSTRUMENTS_ERROR,
        payload: {
          msg: 'Failed to load instruments list'
        }
      });
    }
  };
}

export function loadInstrumentListIfNeeded() {
  return (
    dispatch: Dispatch<
      InstrumentActionType,
      {
        [key: string]: any;
      }
    >,
    getState: GetState
  ) => {
    if (!getState().instruments.get('isLoading')) {
      dispatch(loadInstrumentList());
    }
  };
}

export function refreshInstruments() {
  IOTConnection.stopShadow();
  return (
    dispatch: Dispatch<
      InstrumentActionType,
      {
        [key: string]: any;
      }
    >
  ) => {
    dispatch(loadInstrumentList());
  };
}

export function onIotMessage(
  dispatch: Dispatch<InstrumentActionType, any>,
  getState: GetState,
  message: {
    [key: string]: any;
  }
) {
  const { event: entity } = message;
  if (entity) {
    const { type, device } = entity;
    if (device && type === 'added') {
      // There is no need to create an iot shadow for newly added instrument
      // if instruments related pages have never been loaded
      if (getState().instruments.get('instrumentsLoaded')) {
        IOTConnection.startShadow(dispatchInstrumentStatus(dispatch));
        dispatch({
          type: INSTRUMENT_ADDED,
          payload: { device }
        });
      }
    }
  }
}

export function unlinkInstrument(instrumentId: string) {
  return async (
    dispatch: Dispatch<
      InstrumentActionType,
      {
        [key: string]: any;
      }
    >
  ) => {
    const response = await unlinkUserInstrument(instrumentId);
    if (response.status === 200) {
      dispatch({
        type: UNLINKED_INSTRUMENT,
        payload: { instrumentId }
      });
    } else {
      throw new Error(`Status: ${response.status} - There was an error unlinking the instrument`);
    }
  };
}
