import React from 'react';
import BrioIcon from '../../../img/brio-icon.svg';
import BioIcon from '../../../img/new-logo.svg';

export type Props = {
  children: React.ReactNode;
};

function WithBioradIcons({ children }: Props) {
  const renderBioradIcons = () => (
    <div id="verification-icon-header">
      <img src={BioIcon} alt="Bio Icon" id="biorad-icon" />
      <img src={BrioIcon} alt="Brio Icon" id="brio-icon" />
    </div>
  );

  return (
    <div id="biorad-icons-container">
      {renderBioradIcons()}
      <div id="children-container">{children}</div>
    </div>
  );
}

WithBioradIcons.defaultProps = {
  children: null
};

export default WithBioradIcons;
