import React from 'react';

function THead(props: any) {
  return (
    <thead>
      <tr {...props} />
    </thead>
  );
}

export default THead;
