import React, { Component } from 'react';
import NonSelectableMenuItem from './NonSelectableMenuItem';

export type Props = {
  menuItemRef?: any;
  retry: (index: number) => void;
  index: number;
};

export default class RetryMenuItem extends Component<Props> {
  static defaultProps = {
    menuItemRef: undefined
  };

  _retry = () => {
    const { retry, index } = this.props;
    retry(index);
  };

  _handleRetryClick = (event: React.SyntheticEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    this._retry();
  };

  _handleKeyDown = (event: React.KeyboardEvent<HTMLButtonElement>) => {
    const { key } = event;
    if (key === 'Enter' || key === ' ') {
      event.stopPropagation();
      this._retry();
    }
  };

  render() {
    const { menuItemRef } = this.props;
    return (
      <NonSelectableMenuItem
        menuItemRef={menuItemRef}
        onClick={this._handleRetryClick}
        onKeyDown={this._handleKeyDown}
      >
        <span className="retry-menu-item-text">Error! Click to retry</span>
      </NonSelectableMenuItem>
    );
  }
}
