import React from 'react';
import CommonMenuItemLink from '../../../frontend-common-libs/src/components/common-help-link/CommonMenuItemLink';
import { HelpPageUrl, MenuItemOnSelection } from './types/types';

export type Props = {
  to: HelpPageUrl;
  className: string;
  onSelection?: MenuItemOnSelection;
};
export default function ContextSensitiveMenuItemLink({ to, className, onSelection }: Props) {
  return (
    <CommonMenuItemLink
      id="help-link"
      className={className}
      toRoute={to}
      label="Help For This Page"
      openInNewTab
      onSelection={onSelection}
    />
  );
}
