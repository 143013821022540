import React from 'react';
import HorizontalLine from '../common/HorizontalLine';
import { flexRows, getRubikFontWeight } from '../common/styles';
import HeaderParser from '../sections-parsing/HeaderParser';

export type Props = {
  header: HeaderParser;
};
export default function Header(props: Props) {
  const { header } = props;
  const cellStyle = {
    height: '19px',
    fontSize: '16px',
    fontWeight: getRubikFontWeight(),
    marginTop: '12px',
    paddingRight: '20px'
  };
  return (
    <div>
      <div id="header" style={{ display: 'flex' }}>
        <img
          id="logo"
          src="../../../img/new-logo.png"
          style={{ width: '160px', height: '44px' }}
          alt="Bio-Rad logo"
        />
        <div style={{ ...flexRows, marginLeft: '32px' }}>
          <span
            style={{
              minHeight: '28px',
              fontSize: '24px',
              fontWeight: getRubikFontWeight(),
              wordBreak: 'break-word'
            }}
          >
            {`Run Name: ${header.runName}`}
          </span>
          <span style={cellStyle}>{header.date}</span>
        </div>
      </div>
      <HorizontalLine />
    </div>
  );
}
