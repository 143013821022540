import React from 'react';
import { connect } from 'react-redux';
import { List } from 'immutable';
import classnames from 'classnames';
import { State, AnalysisModeEnum } from '../../../../../types';
import { updateCycleRangeSettings as updateCycleRangeSettingsAction } from '../../../../../actions/currentCfxRun_actions';
import {
  getAnalysisMode,
  getSelectedStepNumberStr,
  selectedStepLoading,
  selectedStepHasErrors,
  getTargetsOrFluorsInRun,
  getComputationsPerWell,
  getPlateWells,
  getExcludedWells,
  getPerStepAnalysisSettingsForStepGroupMode,
  getNumberOfCyclesInStep
} from '../../../../../selectors/selectors';
import { BasePanel, BasePanelProps } from './BasePanel';
import {
  formatId,
  Select,
  SelectMenuItem
} from '../../../../../frontend-common-libs/src/components/common/dropdown';
import FloatField from '../../../../../frontend-common-libs/src/components/common/FloatField';
import { CycleRangeTable, CyclesRangeTableProps, EditedNumber } from './CycleRangeTable';
import CycleRangeWell, { StartOrEnd } from './CycleRangeWell';
import Checkbox from '../../../../../frontend-common-libs/src/common/checkbox';
import { LinkButton } from '../../../../../frontend-common-libs/src/components/common/buttons';

export type CyclesPanelProps = {
  selectedStep: string;
  updateCycleRangeSettings: typeof updateCycleRangeSettingsAction;
  fluorOrTargetsToShow: List<string>;
} & CyclesRangeTableProps &
  BasePanelProps;

type CyclesPanelState = {
  selectedFluorOrTarget: string;
  cyclesRangeTable: CycleRangeTable;
};

export class CyclesPanelImpl extends BasePanel<CyclesPanelProps, CyclesPanelState> {
  constructor(props: CyclesPanelProps) {
    super(props);
    const selectedFluorOrTarget: string =
      props.fluorOrTargetsToShow != null && props.fluorOrTargetsToShow.size > 0
        ? props.fluorOrTargetsToShow.first()
        : '';
    this.state = {
      selectedFluorOrTarget,
      cyclesRangeTable: new CycleRangeTable(props, selectedFluorOrTarget)
    };
  }

  onApply = () => {
    const { updateCycleRangeSettings, selectedStep, analysisMode } = this.props;
    const { cyclesRangeTable } = this.state;
    updateCycleRangeSettings(
      selectedStep,
      analysisMode,
      cyclesRangeTable.getCustomCycleRangeEditChanges()
    );
    cyclesRangeTable.resetCustomCycleRangeChanges();
    this.setState({ cyclesRangeTable });
  };

  hasValidChanges = (): boolean => {
    const { cyclesRangeTable } = this.state;
    return cyclesRangeTable.hasCustomCycleRangeChanges();
  };

  panelTitle = () => 'Set Baseline Cycles';

  renderGroupDropDown = () => {
    const { analysisMode, isDataLoading } = this.props;
    const { selectedFluorOrTarget } = this.state;
    const selectId = 'group-dropdown';
    return (
      <div className="select-group">
        <span>{analysisMode === AnalysisModeEnum.fluor ? 'Fluorophore:' : 'Target:'}</span>
        <Select
          id={selectId}
          value={selectedFluorOrTarget}
          handleSelection={this.updateSelectedGroup}
          disabled={isDataLoading}
        >
          {this.renderGroupOptions(selectId)}
        </Select>
      </div>
    );
  };

  updateSelectedGroup = (value: string) => {
    this.setState({ selectedFluorOrTarget: value });
  };

  renderGroupOptions = (selectId: string) => {
    const { fluorOrTargetsToShow } = this.props;
    if (fluorOrTargetsToShow == null) return null;
    return fluorOrTargetsToShow.map((fluorOrTarget: string) => {
      const formattedId = formatId(selectId, fluorOrTarget);
      return (
        <SelectMenuItem id={formattedId} value={fluorOrTarget} key={formattedId}>
          {fluorOrTarget}
        </SelectMenuItem>
      );
    });
  };

  renderWellTable = () => {
    const { cyclesRangeTable, selectedFluorOrTarget } = this.state;
    const { isDataLoading } = this.props;
    cyclesRangeTable.updateProps(this.props, selectedFluorOrTarget);
    const { wells, multiEdit } = cyclesRangeTable;

    return (
      <div id="well-cycle-range-table" className="br-data-table well-cycle-range-table">
        <table>
          <thead>
            <tr>
              <th className="well-header select-all-wells-header">
                <Checkbox
                  id="all-checkbox"
                  name="all-checkbox"
                  value={multiEdit.allSelected}
                  checked={multiEdit.allSelected}
                  onChange={this.onSelectAllChange}
                  disabled={isDataLoading}
                />
              </th>
              <th className="well-header">WELL</th>
              <th className="well-header">AUTO BEGIN CYCLE</th>
              <th className="well-header">AUTO END CYCLE</th>
              <th className="well-header">CUSTOM BEGIN CYCLE</th>
              <th className="well-header">CUSTOM END CYCLE</th>
            </tr>
          </thead>
          <tbody id="well-cycle-range-table-content">
            {wells.map((entry: CycleRangeWell, index: number) => this.makeWellRow(entry, index))}
          </tbody>
        </table>
      </div>
    );
  };

  makeWellRow = (entry: CycleRangeWell, rowIndex: number) => {
    const { wellId, start, end, customStart, customEnd, isSelected } = entry;
    const { isDataLoading } = this.props;
    const id = `well-cycle-table-row-${rowIndex}`;
    return (
      <tr key={id} id={id} className="br-table-row">
        <td className="well-col" id={`selected-cell-${rowIndex}`}>
          <Checkbox
            id={`selected-checkbox-${rowIndex}`}
            name={wellId}
            value={isSelected}
            checked={isSelected}
            onChange={this.onSelectWellChange}
            disabled={isDataLoading}
          />
        </td>
        <td className="well-col" id={`well-cell-${rowIndex}`}>
          {wellId}
        </td>
        <td className="well-col" id={`start-cell-${rowIndex}`}>
          {start}
        </td>
        <td className="well-col" id={`end-cell-${rowIndex}`}>
          {end}
        </td>
        <td className="well-col" id={`custom-start-cell-${rowIndex}`}>
          <FloatField
            id={`${id}-custom-start-input`}
            // @ts-ignore
            className="br-text-label int-input"
            name={wellId}
            allowNegative={false}
            defaultValue={customStart || ''}
            onChange={this.onChangeCustomStartCycle}
            onBlur={this.onBlurCustomStartCycle}
            disabled={isDataLoading}
          />
        </td>
        <td className="well-col" id={`custom-end-cell-${rowIndex}`}>
          <FloatField
            id={`${id}-custom-end-input`}
            // @ts-ignore
            className="br-text-label int-input"
            name={wellId}
            allowNegative={false}
            defaultValue={customEnd || ''}
            onChange={this.onChangeCustomEndCycle}
            onBlur={this.onBlurCustomEndCycle}
            disabled={isDataLoading}
          />
        </td>
      </tr>
    );
  };

  setStateCustomStartEndEdit = (
    wellId: string,
    startOrEnd: StartOrEnd,
    value: number | string | null | undefined,
    isSubmit: boolean
  ) => {
    const { cyclesRangeTable } = this.state;
    cyclesRangeTable.updateCustomCycleRangeEdits(wellId, startOrEnd, value, isSubmit);
    this.setState({ cyclesRangeTable });
  };

  onChangeCustomStartCycle = (wellId: string, value?: EditedNumber) => {
    this.setStateCustomStartEndEdit(wellId, StartOrEnd.start, value, false);
  };

  onBlurCustomStartCycle = (wellId: string, value?: EditedNumber) => {
    this.setStateCustomStartEndEdit(wellId, StartOrEnd.start, value, true);
  };

  onChangeCustomEndCycle = (wellId: string, value?: EditedNumber) => {
    this.setStateCustomStartEndEdit(wellId, StartOrEnd.end, value, false);
  };

  onBlurCustomEndCycle = (wellId: string, value?: EditedNumber) => {
    this.setStateCustomStartEndEdit(wellId, StartOrEnd.end, value, true);
  };

  renderSelectedWellsEdits = () => {
    const { isDataLoading } = this.props;
    const { allStart, allEnd, selectedCount } = this.state.cyclesRangeTable.multiEdit;
    const disabled = isDataLoading || selectedCount < 1;
    const noTableScroll = this.state.cyclesRangeTable.wells.length <= 6;
    return (
      <div className="br-data-table edit-selected-wells" aria-disabled={disabled}>
        <table>
          <tbody>
            <tr>
              <td
                className={classnames('header-selected-wells', {
                  'no-table_scroll': noTableScroll
                })}
                id="header-selected-wells"
              >{`Set Selected Wells (${selectedCount})`}</td>
              <td className="header-begin-end">Begin</td>
              <td className="header-begin-end">End</td>
            </tr>
            <tr>
              <td />
              <td>
                <FloatField
                  id="all-custom-start-input"
                  // @ts-ignore
                  className="br-text-label int-input"
                  name={StartOrEnd.start}
                  allowNegative={false}
                  // @ts-ignore
                  defaultValue={allStart}
                  onChange={this.onChangeEditAll}
                  onBlur={this.onBlurEditAll}
                  disabled={disabled}
                />
              </td>
              <td>
                <FloatField
                  id="all-custom-end-input"
                  // @ts-ignore
                  className="br-text-label int-input"
                  name={StartOrEnd.end}
                  allowNegative={false}
                  // @ts-ignore
                  defaultValue={allEnd}
                  onChange={this.onChangeEditAll}
                  onBlur={this.onBlurEditAll}
                  disabled={disabled}
                />
              </td>
            </tr>
            <tr>
              <td>
                <LinkButton
                  className="popover-link"
                  id="reset-selected-button"
                  onClick={this.resetSelected}
                  type="button"
                  disabled={disabled}
                >
                  Reset Selected
                </LinkButton>
              </td>
              <td colSpan={2} className="center-button">
                <LinkButton
                  className="popover-link"
                  id="set-selected-button"
                  onClick={this.setSelected}
                  type="button"
                  disabled={disabled}
                >
                  Set Selected
                </LinkButton>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  onSelectAllChange = ({ target: { checked } }: React.ChangeEvent<HTMLInputElement>) => {
    const { cyclesRangeTable } = this.state;
    cyclesRangeTable.selectAllWells(checked);
    this.setState({ cyclesRangeTable });
  };

  onSelectWellChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { checked, name }
    } = event;
    const { cyclesRangeTable } = this.state;
    cyclesRangeTable.selectWell(checked, name);
    this.setState({ cyclesRangeTable });
  };

  resetSelected = () => {
    const { cyclesRangeTable } = this.state;
    cyclesRangeTable.resetSelectedWells();
    this.setState({ cyclesRangeTable });
  };

  setStateAllStartEndEdit = (
    startOrEnd: StartOrEnd,
    value: number | string | null | undefined,
    isSubmit: boolean
  ) => {
    const { cyclesRangeTable } = this.state;
    cyclesRangeTable.updateSelectedCycleRangeEdits(startOrEnd, value, isSubmit);
    this.setState({ cyclesRangeTable });
  };

  onChangeEditAll = (startOrEnd: string, value?: EditedNumber) => {
    this.setStateAllStartEndEdit(startOrEnd as StartOrEnd, value, false);
  };

  onBlurEditAll = (startOrEnd: string, value?: EditedNumber) => {
    this.setStateAllStartEndEdit(startOrEnd as StartOrEnd, value, true);
  };

  setSelected = () => {
    const { cyclesRangeTable } = this.state;
    cyclesRangeTable.setSelectedWells();
    this.setState({ cyclesRangeTable });
  };

  renderPanel = () => {
    return (
      <div className="cycle-range-panel">
        {this.renderGroupDropDown()}
        {this.renderWellTable()}
        {this.renderSelectedWellsEdits()}
      </div>
    );
  };
}

function mapStateToProps(state: State) {
  const isDataLoading = selectedStepLoading(state) || selectedStepHasErrors(state);

  return {
    analysisMode: getAnalysisMode(state),
    selectedStep: getSelectedStepNumberStr(state),
    isDataLoading,
    fluorOrTargetsToShow: getTargetsOrFluorsInRun(state),
    computationsPerWell: getComputationsPerWell(state),
    plateWells: getPlateWells(state),
    excludedWells: getExcludedWells(state),
    perStepAnalysisSettingsForStepGroupMode: getPerStepAnalysisSettingsForStepGroupMode(state),
    numberOfCycles: getNumberOfCyclesInStep(state)
  };
}

export default connect(mapStateToProps, {
  updateCycleRangeSettings: updateCycleRangeSettingsAction
  // @ts-ignore
})(CyclesPanelImpl);
