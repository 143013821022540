export type FileOperationActionType =
  | 'fileupload_failed'
  | 'fileupload_started'
  | 'fileupload_clear'
  | 'fileupload_cancelled'
  | 'fileupload_processing'
  | 'fileupload_unsupported'
  | 'fileupload_incompatible'
  | 'fileupload_processing_complete'
  | 'export_file_started'
  | 'export_file_complete'
  | 'export_file_failed'
  | 'fileupload_size_exceeded'
  | 'fileupload_network_error';

export const FILEUPLOAD_FAILED: FileOperationActionType = 'fileupload_failed';
export const FILEUPLOAD_STARTED: FileOperationActionType = 'fileupload_started';
export const FILEUPLOAD_CLEAR: FileOperationActionType = 'fileupload_clear';
export const FILEUPLOAD_CANCELLED: FileOperationActionType = 'fileupload_cancelled';
export const FILEUPLOAD_PROCESSING: FileOperationActionType = 'fileupload_processing';
export const FILEUPLOAD_UNSUPPORTED: FileOperationActionType = 'fileupload_unsupported';
export const FILEUPLOAD_INCOMPATIBLE: FileOperationActionType = 'fileupload_incompatible';
export const FILEUPLOAD_PROCESSING_COMPLETE: FileOperationActionType =
  'fileupload_processing_complete';
export const EXPORT_FILE_STARTED: FileOperationActionType = 'export_file_started';
export const EXPORT_FILE_COMPLETE: FileOperationActionType = 'export_file_complete';
export const EXPORT_FILE_FAILED: FileOperationActionType = 'export_file_failed';
export const FILEUPLOAD_SIZE_EXCEEDED: FileOperationActionType = 'fileupload_size_exceeded';
export const FILEUPLOAD_NETWORK_ERROR: FileOperationActionType = 'fileupload_network_error';

export type FileUploadPayload = { payload: { ids: string[]; errorMessage?: string } };
