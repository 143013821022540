import React from 'react';
import { connect } from 'react-redux';
import { Map, List } from 'immutable';
import { State } from '../../../../../types';
import { updateThresholdSettings } from '../../../../../actions/currentCfxRun_actions';
import {
  getAnalysisMode,
  getSubtractBaseline,
  defaultThresholds as getDefaultThresholds,
  customThresholds as getCustomThresholds,
  autoThresholdSettings as getAutoThresholdSettings,
  hasCustomThreshold as getHasCustomThreshold,
  getSelectedStepNumberStr,
  getTargetsOrFluorsInRun,
  selectedStepLoading,
  selectedStepHasErrors
} from '../../../../../selectors/selectors';
import { RadioButton } from '../../../../../frontend-common-libs/src/components/common/radio_button_group';
import FloatField from '../../../../../frontend-common-libs/src/components/common/FloatField';
import { replaceWhitespace } from '../../../../../frontend-common-libs/src/common/strings';
import { toFixedDecimals } from '../../../../../frontend-common-libs/src/common/numbers';
import { BasePanel, BasePanelProps } from './BasePanel';

export type ThresholdPanelProps = {
  analysisMode: string;
  selectedStep: string;
  fluorOrTargetsToShow: List<string>;
  defaultThresholds: Map<string, any>;
  customThresholds: Map<string, any>;
  autoThresholdSettings: Map<string, any>;
  setThresholdSettings: (...args: Array<any>) => any;
  isMeltStepSelected?: boolean;
} & BasePanelProps;

type ThresholdPanelState = {
  customThresholds: Map<string, any>;
  autoThresholdSettings: Map<string, any>;
};

export class ThresholdPanelImpl extends BasePanel<ThresholdPanelProps, ThresholdPanelState> {
  static TARGET_COLUMN = 'target-column';

  static AUTO_COLUMN = 'auto-threshold-column';

  static USER_COLUMN = 'user-threshold-column';

  static UPPER_LIMIT = 1000000;

  static LOWER_LIMIT = -1000000;

  static defaultProps = {
    isMeltStepSelected: false,
    closePopover: () => {}
  };

  constructor(props: ThresholdPanelProps) {
    super(props);
    // @ts-ignore
    this.state = { customThresholds: Map(), autoThresholdSettings: Map() };
  }

  onRadioButtonChange = (event: any) => {
    const { name: fluorOrTarget, value: isDefaultString } = event.currentTarget;
    const isDefault = isDefaultString === 'true';
    let { autoThresholdSettings, customThresholds } = this.state;
    const { autoThresholdSettings: originalAutoThresholdSettings } = this.props;
    const originalAutoSettings = originalAutoThresholdSettings.get(fluorOrTarget);
    const originalAutoValue = originalAutoSettings !== undefined ? originalAutoSettings : true;
    // If radio button is checked back to it's original value remove it from state
    if (isDefault === originalAutoValue) {
      if (autoThresholdSettings.get(fluorOrTarget) !== undefined)
        autoThresholdSettings = autoThresholdSettings.delete(fluorOrTarget);
    } else {
      autoThresholdSettings = autoThresholdSettings.set(fluorOrTarget, isDefault);
    }
    if (
      originalAutoValue === true &&
      originalAutoValue === isDefault &&
      customThresholds.get(fluorOrTarget) === ''
    ) {
      customThresholds = customThresholds.delete(fluorOrTarget);
    }
    this.setState({ autoThresholdSettings, customThresholds });
  };

  onApply = () => {
    const { selectedStep, analysisMode, setThresholdSettings } = this.props;
    const { customThresholds, autoThresholdSettings } = this.prepareState();

    setThresholdSettings(selectedStep, analysisMode, customThresholds, autoThresholdSettings);
  };

  prepareState = () => {
    const { customThresholds, autoThresholdSettings } = this.state;
    const {
      customThresholds: originalCustomThresholds,
      autoThresholdSettings: originalAutoThresholdSettings
    } = this.props;

    // Remove auto settings if they are match what is in props
    // @ts-ignore
    const updatedAutoThresholdSettings: Map<string, any> = autoThresholdSettings.reduce(
      (thresholds, value, fluorOrTarget) => {
        const originalSetting = originalAutoThresholdSettings.get(fluorOrTarget);
        if (value === originalSetting) {
          return thresholds;
        }
        return thresholds.set(fluorOrTarget, value);
      },
      Map()
    );

    // Normalize custom values. If the custom value is empty a zero will be set
    // otherwise the string value will be parse to float
    const newCustomThresholds = customThresholds.reduce((thresholds, value, fluorOrTarget) => {
      const threshold = value === '' ? 0 : parseFloat(value);
      const originalCustomThreshold = originalCustomThresholds.get(fluorOrTarget);
      if (threshold === originalCustomThreshold) return thresholds;
      return thresholds.set(fluorOrTarget, threshold);
    }, Map());
    // If an empty custom value is set and original custom value is empty a zero is set
    const otherCustomThresholds = updatedAutoThresholdSettings.reduce(
      (thresholds: Map<string, any>, threshold: unknown, fluorOrTarget: string) => {
        const originalCustomThreshold = originalCustomThresholds.get(fluorOrTarget);
        if (!customThresholds.has(fluorOrTarget) && originalCustomThreshold === undefined)
          return thresholds.set(fluorOrTarget, 0);
        return thresholds;
      },
      // @ts-ignore
      Map()
    );

    return {
      customThresholds: newCustomThresholds.merge(otherCustomThresholds),
      autoThresholdSettings: updatedAutoThresholdSettings
    };
  };

  hasValidChanges = (): boolean => {
    const { customThresholds, autoThresholdSettings } = this.prepareState();

    // @ts-ignore
    const allCustomThresholdsValid = customThresholds.every((threshold: number) =>
      this.isCustomThresholdValid(threshold)
    );
    return (
      (customThresholds.size > 0 && allCustomThresholdsValid) || autoThresholdSettings.size > 0
    );
  };

  countDots = (str: string) => {
    const dots = str.match(/\./g);
    if (dots) {
      return dots.length;
    }
    return 0;
  };

  cleanInput = (originalValue: string): string => {
    const maxDecimalLength = 2;
    const dots = this.countDots(originalValue);
    let cleanValue =
      originalValue.charAt(originalValue.length - 1) === '.' && dots > 1
        ? originalValue.substring(0, originalValue.length - 1)
        : originalValue;
    cleanValue = cleanValue.replace(/[^\d.]/g, '');
    let afterDec = '';
    if (this.countDots(cleanValue) > 1) {
      afterDec = cleanValue.substring(cleanValue.indexOf('.') + 1);
    }
    return afterDec.length > maxDecimalLength
      ? parseFloat(cleanValue).toFixed(maxDecimalLength)
      : cleanValue;
  };

  updateCustomThreshold = (fluorOrTarget: string, value?: number | string) => {
    let { customThresholds } = this.state;
    const { customThresholds: originalCustomThresholds } = this.props;
    const threshold = value || '';
    const originalThreshold = originalCustomThresholds.get(fluorOrTarget);
    // If the custom value is different from the actual value it is set in the
    // state so it can be tracked
    if (threshold === originalThreshold && toFixedDecimals(originalThreshold, 2) === value) {
      if (customThresholds.get(fluorOrTarget))
        customThresholds = customThresholds.delete(fluorOrTarget);
    } else {
      customThresholds = customThresholds.set(fluorOrTarget, threshold);
    }
    this.setState({ customThresholds });
  };

  isCustomThresholdValid = (threshold: number): boolean =>
    threshold <= ThresholdPanelImpl.UPPER_LIMIT && threshold >= ThresholdPanelImpl.LOWER_LIMIT;

  formatCustomThreshold = (fluorOrTarget: string, value?: number | string) => {
    let threshold: string | number = value || '';
    // If custom value is not empty it will be checked againts constraints
    if (threshold !== '') {
      // @ts-ignore
      threshold = parseFloat(threshold);
      if (!this.isCustomThresholdValid(threshold)) {
        if (threshold > ThresholdPanelImpl.UPPER_LIMIT) threshold = ThresholdPanelImpl.UPPER_LIMIT;
        else threshold = ThresholdPanelImpl.LOWER_LIMIT;
      }
    }
    const { customThresholds: originalCustomThresholds } = this.props;
    let { customThresholds } = this.state;
    const originalThreshold = originalCustomThresholds.get(fluorOrTarget);
    // If the custom value is the same as the original custom value it will
    // be delete from internal state so there will be no changes tosave
    if (threshold === originalThreshold) {
      if (customThresholds.get(fluorOrTarget))
        customThresholds = customThresholds.delete(fluorOrTarget);
    } else {
      customThresholds = customThresholds.set(
        fluorOrTarget,
        threshold !== '' ? toFixedDecimals(threshold, 2) : ''
      );
    }
    this.setState({ customThresholds });
  };

  isDefault = (fluorOrTarget: string): boolean => {
    const { autoThresholdSettings } = this.state;
    const { autoThresholdSettings: originalAutoThresholdSettings } = this.props;

    const originaAutoValue = originalAutoThresholdSettings.get(fluorOrTarget);
    const defaultAuto = originaAutoValue !== undefined ? originaAutoValue : true;

    const autoValue = autoThresholdSettings.get(fluorOrTarget);
    const isDefaultValue = autoValue !== undefined ? autoValue : defaultAuto;
    return isDefaultValue;
  };

  makeRow = (fluorOrTarget: string) => {
    const {
      defaultThresholds,
      customThresholds: originalCustomThresholds,
      isDataLoading
    } = this.props;
    const { customThresholds } = this.state;

    const id = `threshold-editor-table-row:${replaceWhitespace(fluorOrTarget)}`;

    const defaultThreshold = defaultThresholds.get(fluorOrTarget)
      ? toFixedDecimals(defaultThresholds.get(fluorOrTarget), 2)
      : 'N/A';

    const isChecked = this.isDefault(fluorOrTarget);
    const customThreshold = originalCustomThresholds.get(fluorOrTarget);
    const defaultCustomThresholdValue =
      customThreshold !== undefined ? toFixedDecimals(customThreshold, 2) : '';

    const customThresholdValue = customThresholds.get(fluorOrTarget);
    return (
      <tr key={id} id={id} className="br-table-row">
        <td className={ThresholdPanelImpl.TARGET_COLUMN}>{fluorOrTarget}</td>
        <td className={ThresholdPanelImpl.AUTO_COLUMN}>
          <RadioButton
            id={`${id}-default-radio-button`}
            name={fluorOrTarget}
            checked={isChecked}
            onChange={this.onRadioButtonChange}
            // @ts-ignore
            value
            disabled={isDataLoading}
          >
            {defaultThreshold}
          </RadioButton>
        </td>
        <td className={ThresholdPanelImpl.USER_COLUMN}>
          <RadioButton
            id={`${id}-custom-radio-button`}
            name={fluorOrTarget}
            checked={!isChecked}
            onChange={this.onRadioButtonChange}
            // @ts-ignore
            value={false}
            disabled={isDataLoading}
          >
            <FloatField
              id={`${id}-custom-input`}
              // @ts-ignore
              className="br-text-label"
              name={fluorOrTarget}
              allowNegative
              maxDecimalLength={100}
              defaultValue={
                customThresholdValue !== undefined
                  ? customThresholdValue
                  : defaultCustomThresholdValue
              }
              onChange={this.updateCustomThreshold}
              onBlur={this.formatCustomThreshold}
              onFocus={this.onRadioButtonChange}
              disabled={isDataLoading}
            />
          </RadioButton>
        </td>
      </tr>
    );
  };

  panelTitle = () => {
    const { isMeltStepSelected } = this.props;
    return isMeltStepSelected ? 'Melt Peak Thresholds' : 'Threshold Settings';
  };

  showBackButton = () => {
    const { isMeltStepSelected } = this.props;
    return !isMeltStepSelected;
  };

  showCloseButton = () => {
    const { isMeltStepSelected } = this.props;
    return isMeltStepSelected === true;
  };

  renderPanel = () => {
    const { analysisMode, fluorOrTargetsToShow } = this.props;
    return (
      <div id="threshold-table" className="br-data-table">
        <table>
          <thead>
            <tr>
              <th className={ThresholdPanelImpl.TARGET_COLUMN}>
                {analysisMode === 'target' ? 'Target' : 'Fluorophore'}
              </th>
              <th className={ThresholdPanelImpl.AUTO_COLUMN}>Auto Threshold</th>
              <th className={ThresholdPanelImpl.USER_COLUMN}>User Defined Threshold</th>
            </tr>
          </thead>
          <tbody className="threshold-panel-table-content">
            {fluorOrTargetsToShow.map(fluorOrTarget => this.makeRow(fluorOrTarget))}
          </tbody>
        </table>
      </div>
    );
  };
}

function mapStateToProps(state: State) {
  const defaultThresholds = getDefaultThresholds(state);
  const fluorOrTargetsToShow = getTargetsOrFluorsInRun(state);
  const isDataLoading = selectedStepLoading(state) || selectedStepHasErrors(state);

  return {
    analysisMode: getAnalysisMode(state),
    selectedStep: getSelectedStepNumberStr(state),
    subtractBaseline: getSubtractBaseline(state),
    fluorOrTargetsToShow,
    defaultThresholds,
    customThresholds: getCustomThresholds(state),
    autoThresholdSettings: getAutoThresholdSettings(state),
    hasCustomThreshold: getHasCustomThreshold(state),
    isDataLoading
  };
}

export default connect(mapStateToProps, {
  setThresholdSettings: updateThresholdSettings
  // @ts-ignore
})(ThresholdPanelImpl);
