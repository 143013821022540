import React from 'react';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import ErrorAlert from '../../../../frontend-common-libs/src/components/common/ErrorAlert';
import Loader from '../../../../frontend-common-libs/src/components/common/Loader';
import RunRepository from '../../repository/RunRepository';
import RunParser from './run-report/sections-parsing/RunParser';
import RunReportPage from './RunReportPage';

type State = {
  error: string;
  isLoading: boolean;
  runParser: RunParser;
  fileName: string;
};

export type Props = {
  match: RouteComponentProps['match'];
  history?: RouteComponentProps['history'];
};

export class RunReportTabImpl extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      error: '',
      isLoading: true,
      // @ts-ignore
      runParser: {}
    };
  }

  async componentDidMount() {
    await this.loadRun();
  }

  get entityId() {
    const { match } = this.props as Record<string, any>;
    const { entityId } = match.params;
    return entityId;
  }

  loadRun = async () => {
    try {
      const ptcRun = await RunRepository.instance.getRun(this.entityId);
      const runParser = new RunParser(ptcRun.runReport.run);
      this.setState({
        isLoading: false,
        runParser,
        fileName: ptcRun.file.name
      });
    } catch (e) {
      const errorMessage = 'Failed to load run report';
      this.setState({
        error: errorMessage,
        isLoading: false
      });
    }
  };

  render() {
    const { isLoading, error, runParser, fileName } = this.state;
    if (isLoading) {
      return <Loader />;
    }
    if (error) return <ErrorAlert error={error} errorId="render-run-error" />;
    return <RunReportPage runParser={runParser} fileName={fileName} entityId={this.entityId} />;
  }
}

// @ts-ignore
export default withRouter(RunReportTabImpl);
