import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { RenderField } from '../../../frontend-common-libs/src/common/input-fields';
import NewPasswordEntry from '../new-password/NewPasswordEntry';
import registerUserAction, { RegisterInfo } from './actions/registerUser_actions';
import {
  textRequired,
  emailValidForAuth
} from '../../../frontend-common-libs/src/common/form_validators';
import {
  ExternalAnchorLink,
  PrimaryButton
} from '../../../frontend-common-libs/src/components/common/buttons';
import UserNameExistsError from './actions/UserNameExistsError';
import InvalidUserOrPasswordError from './actions/InvalidUserOrPasswordError';
import notification from '../../../frontend-common-libs/src/utils/notifications';
import styles from './styles/register.module.scss';
import authStyles from '../styles/auth.module.scss';
import commonStyles from '../../../frontend-common-libs/src/scss/auth.module.scss';
import HelpCenter, { docHelpPaths } from '../../../utils/HelpCenter';

export interface AdditionalProps {
  emailError: string;
  pwdError: string;
  nameError: string;
  registerUser: typeof registerUserAction;
}

interface State {
  hasEmailError: boolean;
  hasPasswordError: boolean;
  emailError: string;
  pwdError: string;
  isInProgress: boolean;
}

type InjectedProps = InjectedFormProps<RegisterInfo, Props>;
export type Props = AdditionalProps & InjectedProps;

export class RegisterImpl extends Component<Props, State> {
  private readonly submitButtonLabel = 'Create Account';

  private helpCenter: HelpCenter;

  constructor(props: Props) {
    super(props);
    this.state = {
      hasEmailError: false,
      hasPasswordError: false,
      emailError: '',
      pwdError: '',
      isInProgress: false
    };
    this.helpCenter = new HelpCenter();
  }

  private emailRequired = (value: string) => textRequired(value);

  private emailFormValid = (value: string) => emailValidForAuth(value);

  private pwdRequired = (value: string) => textRequired(value);

  private nameRequired = (value: string) => textRequired(value);

  private handleRegisterUser = async (registerInfo: RegisterInfo) => {
    const { registerUser } = this.props;

    this.setState({
      isInProgress: true
    });

    try {
      await registerUser(registerInfo);
    } catch (error) {
      if (error instanceof UserNameExistsError) {
        this.setState({
          emailError: 'An account already exists for this email. Please login.',
          hasEmailError: true
        });
      } else if (error instanceof InvalidUserOrPasswordError) {
        this.setState({
          pwdError: 'Password is invalid',
          hasPasswordError: true
        });
      } else {
        notification.error('Unknown error, please try again');
      }
    }

    this.setState({
      isInProgress: false
    });
  };

  private handleChange = () => {
    this.setState({
      hasEmailError: false,
      hasPasswordError: false,
      pwdError: '',
      emailError: ''
    });
  };

  private emailMatchValidator = (verifyEmailValue: string | undefined, allValues: any) => {
    const emailForComparison = (allValues && allValues.email) || '';
    const verifyEmailForComparison = verifyEmailValue || '';

    return emailForComparison !== verifyEmailForComparison ? 'Emails must match' : undefined;
  };

  render() {
    const {
      nameError,
      emailError: emailValidationError,
      dirty,
      handleSubmit,
      pwdError: passwordValidationError,
      invalid
    } = this.props;

    const {
      emailError: emailErrorFromServer,
      pwdError: passwordErrorFromServer,
      hasPasswordError,
      hasEmailError,
      isInProgress
    } = this.state;

    const emailError = emailValidationError || emailErrorFromServer;
    const pwdError = passwordValidationError || passwordErrorFromServer;

    return (
      <div className={styles.registerUser}>
        <div className={authStyles.formContainer}>
          <form onSubmit={handleSubmit(this.handleRegisterUser)}>
            <Field
              label="FULL NAME"
              name="name"
              type="text"
              additionalClass={commonStyles.inputField}
              extraError={!dirty ? '' : nameError}
              component={RenderField}
              validate={[this.nameRequired]}
            />
            <Field
              label="EMAIL"
              name="email"
              type="email"
              additionalClass={commonStyles.inputField}
              extraError={!dirty ? '' : emailError}
              component={RenderField}
              validate={[this.emailRequired, this.emailFormValid]}
              onChange={this.handleChange}
            />
            <Field
              label="VERIFY EMAIL"
              name="verify-email"
              type="email"
              additionalClass={commonStyles.inputField}
              component={RenderField}
              validate={[this.emailMatchValidator]}
            />
            <NewPasswordEntry
              label="PASSWORD"
              name="password"
              additionalClass={commonStyles.inputField}
              extraError={!dirty ? '' : pwdError}
              component={RenderField}
              validate={[this.pwdRequired]}
              onChange={this.handleChange}
            />
            <PrimaryButton
              id="register-button"
              className={styles.registerButton}
              type="submit"
              isInProgress={isInProgress}
              disabled={invalid || hasEmailError || hasPasswordError}
            >
              {this.submitButtonLabel}
            </PrimaryButton>
          </form>
          <div className={styles.termsOfUseBox}>
            <span id="tos-text">{`By clicking "${this.submitButtonLabel}" you agree to the `}</span>
            <ExternalAnchorLink
              id="register-terms-of-use"
              className={styles.termsOfUse}
              href={this.helpCenter.helpUrl(docHelpPaths.Terms_Of_Use)}
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms of Use
            </ExternalAnchorLink>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(null, { registerUser: registerUserAction })(
  reduxForm<RegisterInfo, Props>({ form: 'registration' })(RegisterImpl)
);
