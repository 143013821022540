import React from 'react';
import { ProjectRoles, projectRolesDisplayNames } from '@biorad-lsg-tsc/organization';
import '../../organization-management/organizations/styles/members-table.scss';
import TenantMemberRow, {
  TenantMemberRowProps
} from '../../organization-management/organizations/TenantMemberRow';
import filterRoles from './project-roles';

export type Props = TenantMemberRowProps<ProjectRoles>;

export default function ProjectMemberRow(props: Readonly<Props>) {
  return (
    <TenantMemberRow
      {...{ ...props, roleEnum: filterRoles(), displayNames: projectRolesDisplayNames }}
    />
  );
}
