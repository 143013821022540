import React, { PureComponent } from 'react';
import { GradientLowerTemperature, GradientRange } from '../Gradient';
import GradientCalculatorContainer from '../gradient-calculator-container';
import GradientCalculatorOptions from '../gradient-calculator-options';
import PlateSize from './PlateSize';
import GradientCalculatorTable from '../gradient-calculator-table';
import { GradientTemperature } from '../gradient-temperature';
import GradientFactory from './gradient-factory';

export type PlateSizeValue = '96' | '96D' | '48' | '384';

export type Props = {
  lowerTemperature: GradientLowerTemperature;
  gradientRange: GradientRange;
  isValid: boolean;
};

export type State = {
  plateSize: PlateSize;
};

export default class ConventionalPcrGradientCalculator extends PureComponent<Props, State> {
  private plateSizeEnumLookup = {
    '96': PlateSize.PlateSize96,
    '96D': PlateSize.PlateSize96D,
    '48': PlateSize.PlateSize48,
    '384': PlateSize.PlateSize384
  };

  private plateSizeValueLookup: Record<number, PlateSizeValue> = {
    [PlateSize.PlateSize96]: '96',
    [PlateSize.PlateSize96D]: '96D',
    [PlateSize.PlateSize48]: '48',
    [PlateSize.PlateSize384]: '384'
  };

  private plateSizeLabelLookup: Record<number, PlateSizeValue> = {
    [PlateSize.PlateSize96]: '96',
    [PlateSize.PlateSize96D]: '96D',
    [PlateSize.PlateSize48]: '48',
    [PlateSize.PlateSize384]: '384'
  };

  public constructor(props: Props) {
    super(props);
    this.state = { plateSize: PlateSize.PlateSize96 };
  }

  private handlePlateSizeChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
    const plateSizeValue = e.currentTarget.value as unknown as PlateSizeValue;
    this.setState({
      plateSize: this.plateSizeEnumLookup[plateSizeValue]
    });
  };

  private getTemperatures(): GradientTemperature[] {
    const { plateSize } = this.state;
    const { isValid, lowerTemperature, gradientRange } = this.props;
    const gradient = GradientFactory.getGradient(
      plateSize,
      lowerTemperature,
      gradientRange,
      isValid
    );
    return gradient.getTemperatures();
  }

  private getGradientOptions = () => {
    const plateSizeOptions = [
      PlateSize.PlateSize96,
      PlateSize.PlateSize96D,
      PlateSize.PlateSize48,
      PlateSize.PlateSize384
    ];

    return plateSizeOptions.map(plateSize => this.createGradientCalculatorOption(plateSize));
  };

  private createGradientCalculatorOption = (plateSize: PlateSize) => {
    const value = this.plateSizeValueLookup[plateSize];
    const label = this.plateSizeLabelLookup[plateSize];
    return {
      label,
      value,
      disabled: false
    };
  };

  render() {
    const { plateSize: currentPlateSize } = this.state;

    return (
      <GradientCalculatorContainer>
        <GradientCalculatorOptions
          onChange={this.handlePlateSizeChange}
          checkedValue={this.plateSizeValueLookup[currentPlateSize]}
          options={this.getGradientOptions()}
        />
        <GradientCalculatorTable temperatures={this.getTemperatures()} />
      </GradientCalculatorContainer>
    );
  }
}
