import React from 'react';
import classnames from 'classnames';
import styles from './styles/checkbox.module.scss';

export type CheckboxProps = {
  id: string;
  value: boolean;
  name: string;
  onChange: (...args: Array<any>) => any;
  disabled?: boolean;
  checked?: boolean;
  children?: any;
};

function Checkbox({ id, name, value, disabled, checked, onChange, children }: CheckboxProps) {
  return (
    <label
      id={`${id}-label`}
      htmlFor={id}
      className={classnames({ checked }, styles['br-checkbox-group'], {
        [styles.checked]: checked
      })}
      // @ts-ignore
      disabled={disabled}
      tabIndex={-1}
    >
      <input
        id={id}
        type="checkbox"
        name={name}
        // @ts-ignore
        value={value}
        className={styles['br-hidden-checkbox-input']}
        disabled={disabled}
        checked={checked}
        tabIndex={0}
        onChange={onChange}
      />
      <span className={styles['br-checkbox']} />
      {children}
    </label>
  );
}

Checkbox.defaultProps = {
  disabled: false,
  checked: false,
  children: null
};

export default Checkbox;
