import { DispatchType, PROJECT_USER_REMOVED } from './action-types';
import { removeUserFromProject as removeUserFromProjectApi } from '../api/project-api';
import { GetState, ReduxState } from '../../types';
import { getProjectOrgIdIfLoaded } from '../selectors/selectors';
import notification from '../../frontend-common-libs/src/utils/notifications';
import { getUserEmail } from '../../selectors/auth-selectors';
import { dispatchProjectRemoved } from './remove-project';
import {
  TrackedOrganizationEvents,
  trackEvent
} from '../../frontend-common-libs/src/user-analytics/trackedEvents';
import { isAtLeastOneAdminError } from '../../organization-management/api/api-errors';
import { withRefreshProjectToken } from './with-refresh-project-token';
import { CbWithToken } from '../../organization-management/actions/WithRefreshToken';

export function dispatchProjectUserRemoved(
  dispatch: DispatchType,
  state: ReduxState,
  projectId: string,
  userName: string
) {
  dispatch({
    type: PROJECT_USER_REMOVED,
    payload: { projectId, userName }
  });
  const email = getUserEmail(state);
  if (userName !== email) return;
  const orgId = getProjectOrgIdIfLoaded(state, projectId);
  if (!orgId) return;
  dispatchProjectRemoved(dispatch, state, projectId, orgId);
}

export function removeUserFromProject(projectId: string, userName: string) {
  return async (dispatch: DispatchType, getState: GetState) => {
    try {
      const state = getState();
      const removeUserCb: CbWithToken = async (accessToken: string) => {
        return removeUserFromProjectApi(projectId, userName, accessToken);
      };
      await withRefreshProjectToken(dispatch, getState, projectId, removeUserCb);
      dispatchProjectUserRemoved(dispatch, state, projectId, userName);
      trackEvent(TrackedOrganizationEvents.RemoveUserFromProject, {
        userName
      });
    } catch (error) {
      let errMsg = 'Error removing user from project.';
      if (isAtLeastOneAdminError(error)) errMsg = 'A project must have at least one administrator.';
      notification.error(errMsg);
    }
  };
}
