import { SystemEvent, SystemEventBroker } from './index';

export default class LogoutEvent implements SystemEvent {
  public static readonly eventName = 'logout';

  public readonly name = LogoutEvent.eventName;

  public static isLogoutEvent(systemEvent: SystemEvent) {
    return systemEvent.name === LogoutEvent.eventName;
  }

  public static notify() {
    SystemEventBroker.getInstance().notify(new LogoutEvent());
  }
}
