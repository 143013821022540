import _ from 'lodash';
import { ProjectRoles } from '@biorad-lsg-tsc/organization';
import FeatureFlags, {
  FeatureFlagKeys
} from '../../frontend-common-libs/src/components/feature_flags';

export default function filterRoles() {
  if (FeatureFlags().get(FeatureFlagKeys.PROJECT_REVIEWER)) return ProjectRoles;
  return _.omit(ProjectRoles, 'Reviewer');
}
