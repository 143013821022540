// @ts-nocheck
/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import { ComponentType } from 'react';
import MaintenancePage from '../pages/maintenance_page';
import DataLoader from '../common/DataLoader';
import requestMaintenanceStatus from '../../actions/systemHealth_actions';

type Props = {
  isMaintenanceMode: boolean;
  children: any;
};

export const SystemHealthLoader = ({ isMaintenanceMode, children }: Props) =>
  isMaintenanceMode ? <MaintenancePage /> : children;

export default (WrappedComponent: ComponentType<any>) => (props: any) =>
  (
    <DataLoader getData={requestMaintenanceStatus}>
      {isMaintenanceMode => (
        <SystemHealthLoader isMaintenanceMode={isMaintenanceMode}>
          <WrappedComponent {...props} />
        </SystemHealthLoader>
      )}
    </DataLoader>
  );
