import React, { ReactElement } from 'react';
import RadioButton, { RadioButtonOption } from './RadioButton';

export type OptionProps = {
  label: any;
} & RadioButtonOption;

export type Props = {
  name: string;
  onChange: (...args: Array<any>) => any;
  checkedValue: string;
  options: OptionProps[];
};

export default function ({ name, onChange, checkedValue, options }: Props): ReactElement {
  return (
    <>
      {options.map(({ id, label, value, disabled }) => (
        <RadioButton
          id={id}
          key={id}
          value={value}
          name={name}
          disabled={disabled}
          checked={value === checkedValue}
          onChange={onChange}
        >
          {label}
        </RadioButton>
      ))}
    </>
  );
}
