import Gradient, { GradientLowerTemperature, GradientRange } from '../../Gradient';
import { GradientTemperature, GradientTemperature96 } from '../../gradient-temperature';

export default class Gradient96 extends Gradient {
  constructor(lowerTemp: GradientLowerTemperature, range: GradientRange, isValid: boolean) {
    super(8, lowerTemp, range, isValid, Gradient96.offsetTable, Gradient96.scTable);
  }

  private static readonly offsetTable: Array<Array<number>> = [
    [30.041456, 40.038834, 50.036211, 60.067555, 70.030838, 80.027653, 90.04383, 100.060007],
    [30.02514, 40.040712, 50.056285, 60.107755, 70.124184, 80.152066, 90.200395, 100.248724],
    [30.013823, 40.035169, 50.056516, 60.11661, 70.153102, 80.192007, 90.248607, 100.305206],
    [30.014743, 40.033169, 50.051596, 60.111374, 70.147654, 80.184884, 90.234594, 100.284305],
    [30.02184, 40.036952, 50.052064, 60.108676, 70.143099, 80.176595, 90.221374, 100.266153],
    [30.027311, 40.034003, 50.040695, 60.094758, 70.123502, 80.15693, 90.203906, 100.250882],
    [30.052666, 40.04321, 50.033754, 60.071622, 70.071684, 80.088319, 90.129441, 100.170564],
    [30.06838, 40.030968, 49.993556, 60.007715, 69.945425, 79.931327, 89.941439, 99.951551]
  ];

  private static readonly scTable: Array<Array<number>> = [
    [0.611014, 0.612234, 0.613454, 0.613376, 0.611173, 0.610456, 0.608975, 0.607494],
    [0.530148, 0.531729, 0.533311, 0.533436, 0.531299, 0.531195, 0.530061, 0.528927],
    [0.377064, 0.378173, 0.379282, 0.378692, 0.376093, 0.375823, 0.374795, 0.373767],
    [0.137649, 0.137946, 0.138243, 0.136764, 0.134154, 0.133672, 0.133026, 0.132379],
    [-0.141701, -0.141046, -0.140391, -0.141075, -0.14235, -0.141827, -0.140833, -0.139838],
    [-0.379166, -0.378226, -0.377286, -0.37803, -0.379136, -0.378596, -0.378117, -0.377637],
    [-0.53193, -0.53114, -0.530351, -0.531196, -0.53275, -0.532072, -0.529922, -0.527773],
    [-0.616453, -0.614109, -0.611765, -0.612215, -0.612059, -0.610782, -0.610406, -0.610029]
  ];

  // eslint-disable-next-line class-methods-use-this
  protected createGradientTemperature(
    index: number,
    temperatureValue: number
  ): GradientTemperature {
    return new GradientTemperature96(index, temperatureValue);
  }
}
