import React, { PureComponent, ReactElement } from 'react';

export type Props = {
  children: number | string | ReactElement | Array<any>;
};

export default class PCRProtocolStepSubSection extends PureComponent<Props> {
  render() {
    const { children } = this.props;
    return <div className="text-left step-options">{children}</div>;
  }
}
