import { SystemEvent, SystemEventBroker, SystemEventSubscriber } from '../index';
import SetSelectedProjectEvent from './SetSelectedProjectEvent';
import { ProjectId } from '../../common/project-management-types';

export type SelectProjectEventHandler = (selectedProjectId: ProjectId) => void;

export default class ProjectMsgsSubscriber implements SystemEventSubscriber {
  public changeProjectSelection: SelectProjectEventHandler | undefined = undefined;

  public notify(systemEvent: SystemEvent): void {
    if (SetSelectedProjectEvent.isSetSelectedProjectEvent(systemEvent)) {
      const { projectId } = systemEvent as SetSelectedProjectEvent;
      if (this.changeProjectSelection !== undefined) {
        this.changeProjectSelection(projectId);
      }
    }
  }

  public registerEvent(changeProjectSelection: SelectProjectEventHandler) {
    SystemEventBroker.getInstance().subscribe(this);
    this.changeProjectSelection = changeProjectSelection;
  }

  public unregisterEvent() {
    SystemEventBroker.getInstance().unsubscribe(this);
    this.changeProjectSelection = undefined;
  }
}
