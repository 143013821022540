import React, { Component } from 'react';
import { Map, Seq } from 'immutable';
import Table from '../../frontend-common-libs/src/components/common/tables/Table';
import THead from '../../frontend-common-libs/src/components/common/tables/THead';
import TCell from '../../frontend-common-libs/src/components/common/tables/TCell';
import LoadableTBody from '../../frontend-common-libs/src/components/common/tables/LoadableTBody';
import { restoreUserEntities as restoreUserEntitiesAction } from '../../actions/archive_actions';
import { updateSelectedEntities } from '../../frontend-common-libs/src/utils/rowEntityUtils';
import { InstrumentFamilyPluginRepository } from '../../instrument-family-plugin';

export type Props = {
  entities: Seq.Indexed<Map<string, any>>;
  fetchMoreData: (...args: Array<any>) => any;
  hasMoreData: boolean;
  isLoading: boolean;
  isError: boolean;
  restoreUserEntities: typeof restoreUserEntitiesAction;
  canEditProjectFiles?: boolean;
};
export type State = {
  selectedRows: Array<string>;
};
const archiveRowIdPrefix = 'archive';

export default class ArchiveTable extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      selectedRows: []
    };
  }

  onSelectedRow = (rowId: string) => {
    const { selectedRows } = this.state;
    this.setState({ selectedRows: updateSelectedEntities(selectedRows, rowId) });
  };

  render() {
    const {
      entities,
      fetchMoreData,
      hasMoreData,
      isLoading,
      isError,
      restoreUserEntities,
      canEditProjectFiles
    } = this.props;

    return (
      <div className="flex-column-container" id="archive-table-container">
        <Table className="flex-column-container base-table" id="archive-table">
          <THead>
            <TCell />
            <TCell>NAME</TCell>
            <TCell>LAST UPDATED</TCell>
            <TCell>TYPE</TCell>
            <TCell />
          </THead>
          <LoadableTBody
            fetchMoreData={fetchMoreData}
            hasMoreData={hasMoreData}
            isLoading={isLoading}
            isError={isError}
            errorMsg="Error retrieving archive."
          >
            {tableBodyRef =>
              entities.map(item => {
                const archiveRowId = `${archiveRowIdPrefix}_${item.get('id')}`;

                const plugin =
                  InstrumentFamilyPluginRepository.getInstance().getInstrumentFamilyPluginForFile(
                    item
                  );

                if (plugin) {
                  const ArchiveRowPlugin = plugin.getArchiveRow();
                  return (
                    <ArchiveRowPlugin
                      key={archiveRowId}
                      id={archiveRowId}
                      item={item}
                      tableBodyRef={tableBodyRef}
                      restoreUserEntities={restoreUserEntities}
                      onSelectedRow={this.onSelectedRow}
                      canEditProjectFiles={canEditProjectFiles}
                    />
                  );
                }

                return null;
              })
            }
          </LoadableTBody>
        </Table>
      </div>
    );
  }
}
