import React from 'react';
import { Toggle } from '../../../../frontend-common-libs/src/components/common/radio_button_group';
import { MeltModeEnum } from '../../../../selectors/MeltData';

type Props = {
  mode: string;
  onUpdateMode: (...args: Array<any>) => any;
};

export default function ({ mode, onUpdateMode }: Props) {
  const modeOptions = [
    {
      id: 'melt-chart-mode-peak',
      label: MeltModeEnum.MeltPeak,
      value: MeltModeEnum.MeltPeak
    },
    {
      id: 'melt-chart-mode-curve',
      label: MeltModeEnum.MeltCurve,
      value: MeltModeEnum.MeltCurve
    }
  ];

  return (
    <Toggle
      id="melt-chart-mode-option"
      name="melt-chart-mode"
      onChange={onUpdateMode}
      checkedValue={mode}
      // @ts-ignore
      options={modeOptions}
    />
  );
}
