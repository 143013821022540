import * as React from 'react';
import FieldGroup from './FieldGroup';
import IntField from './IntField';
import { ValidationState } from './fieldValidationState';

export type Props = {
  /** id for the input field */
  id: string;

  /** name for the input field */
  name: string;

  /** label for the field */
  label: string;

  /** units for the field */
  subLabel?: string;

  /** initial value of the field */
  value?: number;

  /** validation state for the field "success" | "warning" | "error" | null */
  validationState?: string | null;

  /** whether to show the error message block */
  withError?: boolean;

  /** error message to be shown for the field */
  error?: string;

  /** whether negative values should be allowed */
  allowNegative?: boolean;

  /** callback for when the value has been changed */
  onChange?: (name: string, value?: number) => void;

  /** custom layout style see FieldGroup component */
  customLayoutStyle?: string;
};

function IntFieldGroup(props: Props) {
  const {
    label,
    subLabel,
    value,
    validationState,
    error,
    allowNegative,
    customLayoutStyle,
    withError,
    id,
    ...remaining
  } = props;

  return (
    <FieldGroup
      id={`${id}-group`}
      label={label}
      subLabel={subLabel}
      validationState={validationState as ValidationState}
      error={error}
      customLayoutStyle={customLayoutStyle}
      withError={withError}
    >
      <IntField defaultValue={value} allowNegative={allowNegative} {...remaining} />
    </FieldGroup>
  );
}

IntFieldGroup.defaultProps = {
  subLabel: '',
  value: undefined,
  validationState: null,
  error: '',
  allowNegative: true,
  customLayoutStyle: undefined,
  withError: true,
  onChange: undefined
};

export default IntFieldGroup;
