// @ts-nocheck
/* eslint-disable react/jsx-props-no-spreading */

import React, { Component } from 'react';
import { Map, Set } from 'immutable';
import { List } from 'immutable';
import { connect } from 'react-redux';
import SelectionPlate from '../plateview/SelectionPlate';
import { setResultsWellsToHide } from '../../../actions/currentCfxRun_actions';
import {
  getExcludedWells,
  getWellReplicateNumberFormatter,
  getSelectedWells,
  getWellsToHide,
  getPlate
} from '../../../selectors/selectors';
import { ReplicateFormatterFunction } from '../../../selectors/current-cfx-run-selectors';

export type Props = {
  rows: number;
  cols: number;
  wells: Map;
  wellReplicateNumberFormatter: ReplicateFormatterFunction;
  selectedWells: Set;
  excludedWells: List;
  wellsToHide: List;
  changeWellsToHide: (arg0: Set) => any;
  plateWrapperSelector: string;
  isAnalysisView: boolean;
};

export class ResultsPlateImpl extends Component<Props> {
  handleSelectingWells = (wellsInSelectionBox: Set<string>): Set<string> => {
    const { selectedWells, wellsToHide, changeWellsToHide, wells, excludedWells } = this.props;

    const filteredSelection = wellsInSelectionBox.filter(
      wellId => wells.has(wellId) && !excludedWells.has(wellId)
    );

    const allWereSelected = filteredSelection.isSubset(selectedWells);
    const newWellsToHide = allWereSelected
      ? wellsToHide.union(wellsInSelectionBox)
      : wellsToHide.subtract(wellsInSelectionBox);
    changeWellsToHide(newWellsToHide);
  };

  render() {
    const { wells, wellsToHide, changeWellsToHide, isAnalysisView, ...passThroughProps } =
      this.props;

    return (
      <SelectionPlate
        {...passThroughProps}
        wells={wells}
        changeSelectedWells={this.handleSelectingWells}
        verticalOffset={63} // to handle overlapping nav bar when viewport height decreases
        isAnalysisView={isAnalysisView}
        colHeaderOffset={24}
        rowHeaderOffset={28}
        tableSpacingLeft={9}
        tableSpacingTop={7}
      />
    );
  }
}

function mapStateToProps(state) {
  const plate = getPlate(state);
  const layout = plate.get('layout');
  const selectedWells = getSelectedWells(state);
  const excludedWells = getExcludedWells(state);
  const wellsToHide = getWellsToHide(state);
  const wellReplicateNumberFormatter = getWellReplicateNumberFormatter(state);

  return {
    rows: layout.get('rows'),
    cols: layout.get('columns'),
    wells: plate.get('wells'),
    selectedWells,
    wellsToHide,
    wellReplicateNumberFormatter,
    excludedWells
  };
}

export default connect(mapStateToProps, {
  changeWellsToHide: setResultsWellsToHide
})(ResultsPlateImpl);
