import { Map, OrderedMap, Seq } from 'immutable';
import { ImmutableMap, InstrumentItem } from '../frontend-common-libs/src/common/types';

export default class InstrumentList {
  public static create(
    instruments: OrderedMap<string, Map<string, any>>,
    statuses: Map<string, Map<string, any>>
  ): Seq.Indexed<ImmutableMap<InstrumentItem>> {
    const instList = instruments.valueSeq().map(instrument => {
      const deviceId = instrument.get('deviceId');
      const deviceType = instrument.get('deviceType');
      const model = instrument.get('model');
      const name = instrument.get('name');
      const serial = instrument.get('serialNumber');
      const softwareVersion = instrument.get('ver');
      const details = instrument.get('details');
      const deviceStatus = statuses?.get(deviceId);
      let instrumentState;
      if (deviceStatus) {
        instrumentState = deviceStatus.getIn(['statusObject', 'state']);
      }

      return Map({
        id: deviceId,
        type: deviceType,
        model,
        name,
        serial,
        softwareVersion,
        details,
        instrumentState,
        deviceStatus
      }) as ImmutableMap<InstrumentItem>;
    });
    return instList;
  }
}
