import React, { Component } from 'react';
import { Map } from 'immutable';
import EntityRow from '../../frontend-common-libs/src/components/common/tables/EntityRow';
import { popupMenuCommands } from '../../frontend-common-libs/src/components/common/menus/PopupMenu';
import { PTCTempoFileIcon, PTCTempoFileRowType } from '../common';

export type Props = {
  id: string;
  item: Map<string, any>;
  tableBodyRef: any;
  restoreUserEntities: (...args: Array<any>) => any;
  onSelectedRow: (...args: Array<any>) => any;
  canEditProjectFiles?: boolean;
};

export default class PTCTempoArchiveRow extends Component<Props> {
  createMenuItemList = () => {
    const { restoreUserEntities, canEditProjectFiles } = this.props;
    const canEditFile = canEditProjectFiles == null || canEditProjectFiles;

    return [
      {
        menuItemKey: popupMenuCommands.restore,
        menuItemCallback: restoreUserEntities,
        menuItemTitle: 'Restore entity',
        menuItemDisabled: !canEditFile
      }
    ];
  };

  private get fileIcon(): JSX.Element {
    const { item } = this.props;
    const fileType = item.get('type');
    return <PTCTempoFileIcon type={fileType} />;
  }

  private get displayedFileType(): string {
    const { item } = this.props;
    const fileType = item.get('type');
    return PTCTempoFileRowType.getDisplayedFileType(fileType);
  }

  render() {
    const { item, tableBodyRef, id } = this.props;

    return (
      <EntityRow
        key={id}
        id={id}
        item={item}
        tableBodyRef={tableBodyRef}
        canOpen={false}
        popupItems={this.createMenuItemList()}
        linkedItemName="Entity"
        icon={this.fileIcon}
        displayedFileType={this.displayedFileType}
      />
    );
  }
}
