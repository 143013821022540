import React, { PureComponent } from 'react';
import { Map } from 'immutable';
import { Set } from 'immutable';
import IntField from '../../../frontend-common-libs/src/components/common/IntField';

type Props = {
  selectedWellIds: Set<string>;
  selectedWells: Map<string, any>;
  handleChanges: (...args: Array<any>) => any;
  handleDelete: (...args: Array<any>) => any;
  replicateErrors?: Map<string, any>;
};

class Replicate extends PureComponent<Props> {
  static defaultProps = {
    replicateErrors: Map()
  };

  static isAllEqual = (wells: Map<string, any>, wellKey: string, value: any) =>
    wells.every(well => well.get(wellKey) === value);

  getWellReplicate() {
    const { selectedWellIds, selectedWells } = this.props;
    const wellKey = 'replicate';

    if (selectedWells.size === 0) return { allEqual: true, value: undefined };

    if (selectedWellIds.size > selectedWells.size) {
      // there are mix of empty and non-empty wells
      // to be all equal, the non-empty wells should all not have replicate
      const allUndefined = Replicate.isAllEqual(selectedWells, wellKey, undefined);
      return { allEqual: allUndefined, value: undefined };
    }
    const value = selectedWells.first().get(wellKey);
    const allEqual = Replicate.isAllEqual(selectedWells, wellKey, value);

    return { allEqual, value: allEqual ? value : undefined };
  }

  changeReplicate = (target: string, value?: number) => {
    const { handleChanges, handleDelete } = this.props;
    const selected = Map({
      replicate: value
    });
    if (Number.isFinite(value)) {
      handleChanges(selected);
    } else {
      handleDelete('replicate');
    }
  };

  canEditReplicate = () => {
    const { selectedWells, selectedWellIds } = this.props;
    if (selectedWells.size === 0) return true;
    if (selectedWells.size < selectedWellIds.size) return false;
    const wellWithoutRepl = selectedWells.first().delete('replicate');
    return selectedWells.every(well => wellWithoutRepl.equals(well.delete('replicate')));
  };

  render() {
    const canEditReplicate = this.canEditReplicate();
    const { allEqual, value } = this.getWellReplicate();
    const { replicateErrors, selectedWellIds } = this.props;
    const errorMessage =
      allEqual && replicateErrors && replicateErrors.get(selectedWellIds.first());

    return (
      <>
        <p className="label-content">Replicate #</p>
        <div>
          <IntField
            id="replicate-number"
            name="replicate-number"
            // @ts-ignore
            placeholder={allEqual ? undefined : 'Multiple Values'}
            allowNegative={false}
            defaultValue={value}
            onChange={this.changeReplicate}
            disabled={!canEditReplicate}
            title={!canEditReplicate ? 'Editing requires all well contents to match.' : undefined}
          />
          {errorMessage && (
            <div id="replicate-error" className="error-message">
              {errorMessage}
            </div>
          )}
        </div>
      </>
    );
  }
}

export default Replicate;
