import React from 'react';
import ptcTempoIcon from '../assets/ptc-tempo-small.svg';
import ptcTempo4848BlockAIcon from '../assets/ptc-tempo-small-block-a.svg';
import ptcTempo4848BlockBIcon from '../assets/ptc-tempo-small-block-b.svg';
import { BlockEnum, InstrumentModelNameEnum } from '../common/InstrumentFacade';

export type Props = {
  type: string;
  modelName?: InstrumentModelNameEnum;
  block?: BlockEnum;
};

export default function ConventionalPCRInstrumentTypeIcon({ type, modelName, block }: Props) {
  const getInstrumentModelIcon = (): any => {
    switch (true) {
      case modelName === InstrumentModelNameEnum.PTCTempo4848 && block === BlockEnum.BlockA:
        return ptcTempo4848BlockAIcon;
      case modelName === InstrumentModelNameEnum.PTCTempo4848 && block === BlockEnum.BlockB:
        return ptcTempo4848BlockBIcon;
      default:
        return ptcTempoIcon;
    }
  };

  const icon = getInstrumentModelIcon();

  return <img src={icon} alt={type} />;
}
