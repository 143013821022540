import Queue from 'promise-queue';

export interface UploadInfo {
  id: string;
  url: string;
  headers: {
    [key: string]: string;
  };
}

export interface PostUploadResponse {
  data: UploadInfo;
}

export default class FileUploadQueue {
  private queue: Queue;

  constructor() {
    this.queue = new Queue(1, Infinity);
  }

  public async add(callback: () => Promise<PostUploadResponse>): Promise<PostUploadResponse> {
    return this.queue.add(callback);
  }
}
