import React, { Component, ComponentType, ComponentProps } from 'react';
import uuidv4 from './uuidv4';
import { NoOp } from './NoOp';

export type ComponentRenderer = (props: {}, id: string) => void;

type State = {
  isError: boolean;
  isComponentNotFound: boolean;
};

export default function withComponentRenderLoader(
  load: Promise<ComponentRenderer | undefined>,
  Loader: ComponentType<any>,
  idPrefix: string
): ComponentType<any> {
  return class PluginComponentLoader extends Component<ComponentProps<any>, State> {
    id: string;

    constructor(props: ComponentProps<any>) {
      super(props);

      this.id = `${idPrefix}-${uuidv4()}`;

      this.state = {
        isError: false,
        isComponentNotFound: false
      };
    }

    async componentDidMount() {
      try {
        const renderComponent = await load;

        if (!renderComponent) {
          this.setState({
            isError: false,
            isComponentNotFound: true
          });
        } else {
          renderComponent(this.props, this.id);
        }
      } catch (error: unknown) {
        this.setState({
          isError: true
        });
      }
    }

    render() {
      const { isError, isComponentNotFound } = this.state;

      if (isComponentNotFound) {
        return <NoOp />;
      }

      if (isError) {
        return <p>No component error</p>;
      }

      return (
        <div id={this.id}>
          <Loader />
        </div>
      );
    }
  };
}
