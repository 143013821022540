import React, { PureComponent } from 'react';
import { Map, List } from 'immutable';

import PCRProtocolTempStep from './pcr-protocol-temp-step/PCRProtocolTempStep';
import PCRProtocolGotoStep from './pcr-protocol-goto-step/PCRProtocolGotoStep';
import PCRProtocolGradientStep from './pcr-protocol-gradient-step/PCRProtocolGradientStep';
import PCRProtocolMeltStep from './pcr-protocol-melt-step/PCRProtocolMeltStep';
import { stepsForIndex, minGotoForIndex } from '../../../utils/protocolUtils';

export type Props = {
  protocol: List<any>;
  editable?: boolean;
  deleteStep?: (...args: Array<any>) => any;
  editStep?: (...args: Array<any>) => any;
  selectedIndex: number;
  setSelectedStepIndex?: (...args: Array<any>) => any;
  editingStepIndex: number;
  isAdd: boolean;
  saveEdit?: (...args: Array<any>) => any;
  cancelEdit?: (...args: Array<any>) => any;
  stepTypeChanged?: (...args: Array<any>) => any;
  isPcr?: boolean;
};

export class PCRProtocolTextImpl extends PureComponent<Props> {
  static defaultProps = {
    editable: false,
    deleteStep: undefined,
    editStep: undefined,
    selectedIndex: -1,
    setSelectedStepIndex: undefined,
    editingStepIndex: -1,
    isAdd: false,
    saveEdit: undefined,
    cancelEdit: undefined,
    stepTypeChanged: undefined,
    isPcr: false
  };

  getMinGotoIndex = () => {
    const { protocol, editingStepIndex } = this.props;
    return minGotoForIndex(protocol, editingStepIndex);
  };

  stepsList = () => {
    const { protocol, editingStepIndex, isPcr } = this.props;
    return stepsForIndex(protocol, editingStepIndex, isPcr);
  };

  renderStepEdit = () => {
    const { protocol, editingStepIndex } = this.props;

    if (editingStepIndex >= 0 && editingStepIndex < protocol.size) {
      return this.renderStepView(protocol.get(editingStepIndex), editingStepIndex, true);
    }
    return null;
  };

  renderStep = (step: Map<string, any>, index: number) => this.renderStepView(step, index, false);

  renderStepView = (step: Map<string, any>, index: number, editing: boolean) => {
    const {
      isAdd,
      editable,
      deleteStep,
      editStep,
      saveEdit,
      cancelEdit,
      stepTypeChanged,
      setSelectedStepIndex,
      selectedIndex,
      isPcr
    } = this.props;
    const props = {
      step,
      index,
      key: index.toString(),
      editing,
      isAdd,
      deleteAction: editable ? deleteStep : undefined,
      editAction: editable ? editStep : undefined,
      saveEditAction: editable ? saveEdit : undefined,
      cancelEditAction: editable ? cancelEdit : undefined,
      editStepTypeChangeAction: editable ? stepTypeChanged : undefined,
      setSelectedStepIndex,
      selectedIndex,
      stepsList: this.stepsList,
      isPcr
    };
    switch (step.get('type')) {
      case 'temp':
        // @ts-ignore
        return <PCRProtocolTempStep {...props} />;
      case 'gradient':
        // @ts-ignore
        return <PCRProtocolGradientStep {...props} />;
      case 'goto':
        return (
          // @ts-ignore
          <PCRProtocolGotoStep {...props} minGotoIndex={editStep ? this.getMinGotoIndex() : 0} />
        );
      case 'melt':
        // @ts-ignore
        return <PCRProtocolMeltStep {...props} />;
      default:
        break;
    }
    return null;
  };

  render() {
    const { protocol } = this.props;

    if (!protocol) {
      return <div />;
    }
    return (
      <div id="protocol-data" className="protocol-data-text">
        <div className=" protocol-text-scroll">
          <div id="protocol-text" className="flex-column-container">
            {protocol.map(this.renderStep)}
            <div id="step-end" className="step-item">
              END
            </div>
          </div>
        </div>
        {this.renderStepEdit()}
      </div>
    );
  }
}

export default PCRProtocolTextImpl;
