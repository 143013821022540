import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import notification from '../../frontend-common-libs/src/utils/notifications';
import {
  loadUserProfile as loadUserProfileAction,
  submitUserProfile as submitUserProfileAction
} from '../../auth/actions/auth_actions';
import Loader from '../../frontend-common-libs/src/components/common/Loader';
import UserProfileForm from './UserProfileForm';
import routes from '../../core/routes';
import { ReduxState } from '../../types';
import { getUserEmail } from '../../selectors/auth-selectors';

type UserProfileState = {
  profileLoadError: string | null | undefined;
};

type NotificationError = Error | { message: string };

export type UserProfileFormValues = {
  name: string;
  institution: string;
  lab: string;
};

export type Props = {
  email: string;
  profileLoaded: boolean;
  loadUserProfile: typeof loadUserProfileAction;
  submitUserProfile: typeof submitUserProfileAction;
} & RouteComponentProps;

export class UserProfileImpl extends Component<Props, UserProfileState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      profileLoadError: undefined
    };
  }

  componentDidMount() {
    const { profileLoaded } = this.props;
    if (!profileLoaded) {
      this.loadUserProfile();
    }
  }

  onSubmit = async (values: UserProfileFormValues) => {
    try {
      const { submitUserProfile, email } = this.props;
      const profile = { ...values, email };
      await submitUserProfile(profile);
      notification.success('Profile saved');
    } catch (e: unknown) {
      const error = e as NotificationError;
      const errorMessage = error.message || 'Unknown error saving user profile';
      notification.error(errorMessage);
    }
  };

  loadUserProfile = async () => {
    try {
      const { loadUserProfile } = this.props;
      await loadUserProfile();
    } catch (e: unknown) {
      const error = e as NotificationError;
      const errorMessage = error.message || 'Unknown error loading user profile';
      notification.error(errorMessage);
      this.setState({
        profileLoadError: errorMessage
      });
    }
  };

  renderAttributes = () => {
    const { profileLoaded } = this.props;
    const { profileLoadError } = this.state;
    if (!profileLoaded) {
      if (profileLoadError) {
        return (
          <div>
            <br />
            <br />
            {profileLoadError}
          </div>
        );
      }
      return (
        <div>
          <Loader />
        </div>
      );
    }
    return <UserProfileForm onSubmit={this.onSubmit} />;
  };

  render() {
    const { email } = this.props;
    return (
      <div className="profile-wrapper">
        <h2>Profile Information</h2>
        <br />
        <h4>
          Your email is{' '}
          <span id="email" className="text-bold">
            {email}
          </span>
        </h4>
        <Link id="change-pwd" to={routes.CHANGE_PASSWORD}>
          Change Password
        </Link>
        <br />
        <br />
        <h4 className="form-label">PROFILE PICTURE</h4>
        <img src="/img/user-profile-default.png" alt="profile" />
        {this.renderAttributes()}
      </div>
    );
  }
}
function mapStateToProps(state: ReduxState) {
  return {
    email: getUserEmail(state),
    profileLoaded: state.auth.get('profileLoaded')
  };
}

export default withRouter(
  connect(mapStateToProps, {
    loadUserProfile: loadUserProfileAction,
    submitUserProfile: submitUserProfileAction
    // @ts-ignore
  })(UserProfileImpl)
);
