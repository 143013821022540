import _ from 'lodash';
import Event from './Event';

// try making the sender of a type of a class that extends BaseViewModel
export type StateChangedCallBack = (sender: any) => any;

export default class BaseViewModel {
  public readonly stateChanged = new Event<BaseViewModel>();

  public registerStateChanged(stateChangedCallBack: StateChangedCallBack) {
    this.stateChanged.register(stateChangedCallBack);
  }

  public unregisterStateChanged(stateChangedCallBack: StateChangedCallBack) {
    this.stateChanged.unregister(stateChangedCallBack);
  }

  protected fireStateChanged = () => {
    this.stateChanged.fire(this);
  };

  protected notifyIfChanged(propName: string, value: any): boolean {
    const changed = _.get(this, propName) !== value;
    _.set(this, propName, value);
    if (changed) this.fireStateChanged();
    return changed;
  }
}
