// @ts-nocheck
import React, { PureComponent, ElementRef } from 'react';
import { Set, Map } from 'immutable';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighChartsNoDataToDisplay from 'highcharts/modules/no-data-to-display';
import HighchartsExporting from 'highcharts/modules/exporting';
import HighchartsOfflineExporting from 'highcharts/modules/offline-exporting';
import HighchartsExportCsv from 'highcharts/modules/export-data';
import Boost from 'highcharts/modules/boost';
import { targetMode } from './pcrDataUtils';
import AmpChartDropdown from '../../common/charts/AmpChartDropdown';
import CqData from '../../../selectors/CqData';
import MeltData from '../../../selectors/MeltData';
import getTraceColor from '../../../utils/chartUtils';

HighChartsNoDataToDisplay(Highcharts);
HighchartsExporting(Highcharts);
HighchartsOfflineExporting(Highcharts);
HighchartsExportCsv(Highcharts);
Boost(Highcharts);

export type ChartData = {
  stepData: Map;
  thresholdData: Map;
  targetsOrFluorsInRun: Set;
  mode: string;
  cycles: number;
  channelMap: Map;
  plateTargets: Map;
  getXAxisValue?: (...args: Array<any>) => any;
};

export type Props = {
  fileName: string;
  chartTitle: string;
  xAxisLabel: string;
  yAxisLabel: string;
  yAxisOptions?: {
    [key: string]: any;
  };
  resultsData: CqData | MeltData;
  wellsToShow: Set;
  targetsOrFluorsToShow: Set;
  groupMode: string;
};

class ResultsChart extends PureComponent<Props> {
  static defaultProps = { yAxisOptions: {} };

  chartRef: ElementRef<any>;

  constructor(props: Props) {
    super(props);
    this.chartRef = React.createRef();
  }

  getChartSeries = (
    chartData: ChartData
  ): Array<{
    [key: string]: any;
  }> => {
    const seriesList = [];
    chartData.stepData.keySeq().forEach(targetOrFluor => {
      const color = getTraceColor(
        targetOrFluor,
        chartData.plateTargets,
        chartData.mode,
        chartData.channelMap.get(targetOrFluor)
      );
      const targetsOrFluorsInRun = chartData.stepData.get(targetOrFluor);
      if (targetsOrFluorsInRun.size) {
        targetsOrFluorsInRun.keySeq().forEach(well => {
          const data = chartData.stepData
            .getIn([targetOrFluor, well])
            .toArray()
            .map((value, cycle) =>
              chartData.getXAxisValue
                ? {
                    x: chartData.getXAxisValue(cycle),
                    y: value
                  }
                : value
            );
          seriesList.push({
            name: `${targetOrFluor}-${well}`,
            data,
            color,
            pointStart: 1
          });
        });
        const threshold = chartData.thresholdData.get(targetOrFluor);
        if (threshold !== undefined) {
          const xStartPoint = chartData.getXAxisValue ? chartData.getXAxisValue(0) : 1;
          const xEndPoint = chartData.getXAxisValue
            ? chartData.getXAxisValue(chartData.cycles - 1)
            : chartData.cycles;
          seriesList.push({
            name: `${targetOrFluor}-threshold`,
            data: [
              [xStartPoint, threshold],
              [xEndPoint, threshold]
            ],
            color
          });
        }
      }
    });
    return seriesList;
  };

  handlePrint = () => {
    this.chartRef.current.chart.print();
  };

  handleCSVDownload = () => {
    this.chartRef.current.chart.downloadCSV();
  };

  handlePNGDownload = () => {
    this.chartRef.current.chart.exportChartLocal();
  };

  noDataMessage = () => {
    const { wellsToShow, targetsOrFluorsToShow, groupMode, resultsData } = this.props;

    if (!wellsToShow.size) return 'No wells selected.';

    if (!targetsOrFluorsToShow.size)
      return `No ${groupMode === targetMode ? 'targets' : 'fluorophores'} selected.`;

    if (!resultsData.hasData()) return 'No data to display';

    return `Selected well${wellsToShow.size > 1 ? 's do' : ' does'} not contain the ${
      groupMode === targetMode ? 'target' : 'fluorophore'
    }${targetsOrFluorsToShow.size > 1 ? 's' : ''} selected.`;
  };

  render() {
    const { fileName, chartTitle, xAxisLabel, yAxisLabel, yAxisOptions, resultsData } = this.props;

    const options = {
      noData: {
        style: {
          fontFamily: 'Rubik, sans-serif',
          fontWeight: '400',
          fontSize: '13px',
          color: '#3d3d3d'
        }
      },
      legend: { enabled: false },
      chart: {
        type: 'line',
        plotBorderColor: '#cccccc',
        plotBorderWidth: 1,
        spacing: [5, 1, 1, 1],
        height: '180px'
      },
      boost: { seriesThreshold: 20 },
      plotOptions: {
        series: {
          animation: false,
          pointStart: 0,
          pointInterval: 1,
          marker: { enabled: false, states: { hover: { enabled: false } } },
          tooltip: { headerFormat: undefined, valueDecimals: 0 }
        }
      },
      exporting: { enabled: false, filename: fileName },
      credits: { enabled: false },
      title: { text: '' },
      xAxis: {
        endOnTick: false,
        tickLength: 5,
        title: {
          text: xAxisLabel,
          style: {
            'font-family': 'Rubik, sans-serif',
            'font-size': '14px',
            'font-weight': '500',
            color: '#3d3d3d'
          }
        },
        labels: {
          style: { 'font-family': 'Rubik, sans-serif', color: '#3d3d3d' },
          y: 16
        },
        gridLineWidth: 1,
        gridLineDashStyle: 'longdash'
      },
      yAxis: {
        title: {
          text: yAxisLabel,
          style: {
            'font-family': 'Rubik, sans-serif',
            'font-size': '14px',
            'font-weight': '500',
            color: '#3d3d3d'
          }
        },
        labels: {
          x: -4,
          style: { 'font-family': 'Rubik, sans-serif', color: '#3d3d3d' }
        },
        gridLineWidth: 1,
        gridLineDashStyle: 'longdash',
        ...yAxisOptions
      }
    };

    const chartSeriesData = this.getChartSeries(resultsData.getChartData());
    const langOptions = !chartSeriesData.length ? { lang: { noData: this.noDataMessage() } } : {};

    const config = {
      ...options,
      ...langOptions,
      series: chartSeriesData
    };

    return (
      <>
        <div className="parent-flex line-series-chart-header">
          <div className="line-series-chart-title">{chartTitle}</div>
          <AmpChartDropdown
            handlePrint={this.handlePrint}
            handleCSVDownload={this.handleCSVDownload}
            handlePNGDownload={this.handlePNGDownload}
            zz
          />
        </div>
        <HighchartsReact highcharts={Highcharts} options={config} immutable ref={this.chartRef} />
      </>
    );
  }
}

export default ResultsChart;
