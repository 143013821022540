import React from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MicroFrontendEvent, ToastNotificationType } from '@biorad-lsg-tsc/common-event';

// Definition of the notification "namespace" for these utility methods.
// To use: import notification from 'notification';
// Call with: notification.success

const toastSpan = (msg: string, id: string) => <span id={id}>{msg}</span>;

const defaultProps = {
  position: toast.POSITION.TOP_CENTER,
  autoClose: 5000,
  hideProgressBar: true,
  pauseOnHover: true,
  draggable: false
};

const notification = {
  success(msg: string, additionalProps?: Record<string, unknown>) {
    toast.success(toastSpan(msg, 'toast-success'), {
      ...defaultProps,
      ...additionalProps,
      className: 'success-notify'
    });
  },

  error(msg: string, additionalProps?: Record<string, unknown>) {
    toast.error(toastSpan(msg, 'toast-error'), {
      ...defaultProps,
      ...additionalProps,
      className: 'error-notify'
    });
  },

  info(msg: string, additionalProps?: Record<string, unknown>) {
    toast.info(toastSpan(msg, 'toast-info'), {
      ...defaultProps,
      ...additionalProps,
      className: 'info-notify'
    });
  },

  registerEvent() {
    MicroFrontendEvent.registerEvent('toast-notification', (e: CustomEvent) => {
      const { type, msg, additionalProps } = e.detail;
      notification[type as ToastNotificationType](msg, additionalProps);
    });
  }
};

export default notification;
