import React from 'react';
import { sectionHeaderStyle } from './styles';
import { toHtmlId } from '../../../../../../frontend-common-libs/src/common/strings';

export type Props = { text: string };

export default function SectionHeader(props: Props) {
  const { text } = props;
  return (
    <span id={`section-header-${toHtmlId(text)}`} style={sectionHeaderStyle()}>
      {text}
    </span>
  );
}
