import { Map, List } from 'immutable';
import Plate from '../../../../helpers/Plate';
import SamplePlaceholderGenerator from './SamplePlaceholderGenerator';
import { WellType } from '../../../../utils/microplateUtils';
import { ProjectId } from '../../../../frontend-common-libs/src/common/project-management-types';

export default class RunTemplate {
  public projectId: ProjectId | undefined = undefined;

  constructor(
    public readonly name: string,
    public readonly protocolName: string,
    public readonly protocol: Map<any, any>,
    public readonly plate: Map<any, any>,
    public readonly analysisSettings: Map<any, any>,
    public readonly samplePlaceholders: List<any>
  ) {}

  public static createFromRun(run: Map<any, any>) {
    const name = `${run.get('name')}_template`;
    const protocolName = (run.getIn(['runInfo', 'protocolName']) || 'unnamed-protocol') as string;
    const plate = run.get('plate');
    const protocol = run.get('protocol');
    const samplePlaceholders = new SamplePlaceholderGenerator(plate).generate();
    const plateAfterRemovingSamples = RunTemplate.clearSamples(plate);
    return new RunTemplate(
      name,
      protocolName,
      protocol,
      plateAfterRemovingSamples,
      // @ts-ignore
      run.getIn(['settings', 'analysis']),
      samplePlaceholders
    );
  }

  toJS() {
    const runTemplate: { [key: string]: any } = {
      name: this.name,
      protocol: this.protocol.toJS(),
      plate: this.plate.toJS(),
      settings: {
        analysis: this.analysisSettings.toJS()
      },
      samplePlaceholders: this.samplePlaceholders.toJS(),
      device: Plate.size(this.plate) === 96 ? 'CFX96' : 'CFX384',
      runInfo: {
        protocolName: this.protocolName
      }
    };
    if (this.projectId != null) runTemplate.parentId = this.projectId;
    return runTemplate;
  }

  private static clearSamples(plate: Map<string, any>) {
    const wells = plate.get('wells').map((well: Map<string, any>) => {
      if (well.get('type') === WellType.UNKNOWN) {
        return well.delete('sample');
      }
      return well;
    });
    return plate.set('wells', wells);
  }
}
