import React, { PureComponent } from 'react';
import sprintfjs from 'sprintf-js';
import PCRProtocolStepSubSection from './PCRProtocolStepSubSection';

export type Props = { extTime?: number };

export default class PCRProtocolExtendTime extends PureComponent<Props> {
  static defaultProps = {
    extTime: undefined
  };

  render() {
    const { extTime } = this.props;
    if (extTime === undefined) {
      return null;
    }

    const extStr = sprintfjs.sprintf('+ Extend time by %d seconds per cycle', extTime);
    return <PCRProtocolStepSubSection>{extStr}</PCRProtocolStepSubSection>;
  }
}
