import React, { PureComponent } from 'react';
import InfiniteScrollSelect from '../../../frontend-common-libs/src/components/common/dropdown/InfiniteScrollSelect';
import {
  formatId,
  SelectMenuItem,
  NonSelectableMenuItem
} from '../../../frontend-common-libs/src/components/common/dropdown';
import TemplateEntity from './models/TemplateEntity';
import RunTemplates from './models/RunTemplates';
import { ProjectId } from '../../../frontend-common-libs/src/common/project-management-types';

export type TemplateSelectorProps = {
  handleSelection: (templateEntity: TemplateEntity) => void;
  selectedTemplateEntity?: TemplateEntity;
  disabled: boolean;
  projectId: ProjectId;
};

type TemplateSelectorState = {
  runTemplates: RunTemplates;
  isLoadingTemplateEntities: boolean;
  templateEntitiesLoadingErrored: boolean;
};

export default class TemplateSelector extends PureComponent<
  TemplateSelectorProps,
  TemplateSelectorState
> {
  constructor(props: TemplateSelectorProps) {
    super(props);
    this.state = TemplateSelector.createInitState(props.projectId);
  }

  async componentDidMount() {
    await this.fetchMoreData();
  }

  async componentDidUpdate(prevProps: TemplateSelectorProps) {
    const { projectId } = this.props;
    if (prevProps.projectId !== projectId) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState(TemplateSelector.createInitState(projectId), () => this.fetchMoreData());
    }
  }

  private static createInitState(projectId: string) {
    return {
      runTemplates: new RunTemplates(projectId),
      isLoadingTemplateEntities: false,
      templateEntitiesLoadingErrored: false
    };
  }

  private fetchMoreData = async () => {
    const { runTemplates } = this.state;
    this.setState({ isLoadingTemplateEntities: true, templateEntitiesLoadingErrored: false });
    try {
      const templates = await runTemplates.get();
      this.setState({ runTemplates: templates, isLoadingTemplateEntities: false });
    } catch (err) {
      this.setState({ isLoadingTemplateEntities: false, templateEntitiesLoadingErrored: true });
    }
  };

  private renderTemplateEntities = () => {
    const { runTemplates } = this.state;
    if (runTemplates.noTemplatesAvailable) {
      return <NonSelectableMenuItem>No templates available</NonSelectableMenuItem>;
    }

    return runTemplates.templateEntities.map((templateEntity: TemplateEntity) => {
      const { name, id } = templateEntity;
      return (
        <SelectMenuItem key={id} value={templateEntity} id={formatId('template-dropdown', id)}>
          {name}
        </SelectMenuItem>
      );
    });
  };

  private renderDropdown = () => {
    const { handleSelection, selectedTemplateEntity, disabled } = this.props;
    const { templateEntitiesLoadingErrored, isLoadingTemplateEntities, runTemplates } = this.state;

    return (
      <div className="run-template-selector-header">
        <div className="label-and-error">
          <span className="control-label large">SELECT A RUN TEMPLATE</span>
          {templateEntitiesLoadingErrored && (
            <span id="run-template-error" className="error-message">
              Error loading templates
            </span>
          )}
        </div>
        <div className="run-template-select">
          <div className="display-horizontal">
            <InfiniteScrollSelect
              placeholder="Choose a template for your run…"
              disabled={disabled}
              fetchMoreData={this.fetchMoreData}
              hasMoreData={runTemplates.hasMore}
              isLoading={isLoadingTemplateEntities}
              loadingErrored={templateEntitiesLoadingErrored}
              handleSelection={handleSelection}
              id="template-selector-dropdown"
              value={selectedTemplateEntity?.name}
            >
              {this.renderTemplateEntities()}
            </InfiniteScrollSelect>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return this.renderDropdown();
  }
}
