import { NullableString } from './nullable-types';

export default class EmailAddress {
  constructor(email: string) {
    // eslint-disable-next-line no-underscore-dangle
    this._domain = EmailAddress._getDomain(email);
  }

  _domain: NullableString;

  static _getDomain(email: string): NullableString {
    const [, domain] = email.split('@');
    return domain;
  }

  get domain(): NullableString {
    return this._domain;
  }
}
