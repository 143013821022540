import React from 'react';
import styles from './styles/instrument-details-item.module.scss';

export type Props = {
  value: string;
  label: string;
  id: string;
  valueClassName?: string;
};

export default function InstrumentDetailsItem({ value, label, id, valueClassName }: Props) {
  return (
    <tr className={styles.row}>
      <td className={styles.label}>{label}</td>
      <td
        id={id}
        className={valueClassName ? `${styles.value} ${valueClassName}` : `${styles.value}`}
        title={value}
      >
        {value}
      </td>
    </tr>
  );
}
