import { GradientTemperature, GradientTemperature96 } from '../../gradient-temperature';
import Gradient, { GradientLowerTemperature, GradientRange } from '../../Gradient';

export default class Gradient96 extends Gradient {
  constructor(lowerTemp: GradientLowerTemperature, range: GradientRange, isValid: boolean) {
    super(8, lowerTemp, range, isValid, Gradient96.offsetTable, Gradient96.scTable);
  }

  private static readonly offsetTable: Array<Array<number>> = [
    [30.112573, 40.085956, 50.059338, 60.033192, 69.975571, 79.963099, 89.987239, 100.01138],
    [30.057361, 40.050089, 50.042817, 60.044053, 70.036488, 80.063547, 90.110869, 100.15819],
    [30.040806, 40.037607, 50.034409, 60.041547, 70.06043, 80.101916, 90.147062, 100.192208],
    [30.050835, 40.035395, 50.019955, 60.036552, 70.060419, 80.093876, 90.13199, 100.170104],
    [30.048403, 40.034793, 50.021182, 60.030885, 70.046751, 80.079999, 90.122711, 100.165423],
    [30.056217, 40.03998, 50.023743, 60.028643, 70.041848, 80.074786, 90.129874, 100.184961],
    [30.068605, 40.04867, 50.028735, 60.028233, 70.011014, 80.024881, 90.069153, 100.113425],
    [30.111347, 40.072501, 50.033654, 60.010649, 69.940138, 79.90385, 89.922554, 99.941257]
  ];

  private static readonly scTable: Array<Array<number>> = [
    [0.613616, 0.615278, 0.61694, 0.619259, 0.617701, 0.618197, 0.617743, 0.61729],
    [0.533701, 0.535784, 0.537867, 0.53985, 0.539599, 0.540226, 0.539879, 0.539533],
    [0.380007, 0.38182, 0.383632, 0.385165, 0.384037, 0.383622, 0.383961, 0.3843],
    [0.139822, 0.140864, 0.141907, 0.141611, 0.139621, 0.138151, 0.138045, 0.137939],
    [-0.1383, -0.138534, -0.138768, -0.138612, -0.139737, -0.140298, -0.14062, -0.140943],
    [-0.375025, -0.375905, -0.376785, -0.378029, -0.379769, -0.381016, -0.382098, -0.383179],
    [-0.524758, -0.526301, -0.527844, -0.530729, -0.533506, -0.534561, -0.53603, -0.5375],
    [-0.602634, -0.604448, -0.606262, -0.609024, -0.612722, -0.612645, -0.613435, -0.614226]
  ];

  // eslint-disable-next-line class-methods-use-this
  protected createGradientTemperature(
    index: number,
    temperatureValue: number
  ): GradientTemperature {
    return new GradientTemperature96(index, temperatureValue);
  }
}
