export type CurrentCfxRunActionType =
  | 'current_cfx_run_init'
  | 'current_cfx_run_clear'
  | 'current_cfx_run_name'
  | 'current_cfx_run_notes'
  | 'current_cfx_run_plate'
  | 'current_cfx_run_plate_replace'
  | 'current_cfx_run_plate_id'
  | 'current_cfx_run_ptype'
  | 'current_cfx_run_protocol_is_updating'
  | 'current_cfx_run_protocol_update'
  | 'current_cfx_run_set_wells_to_hide'
  | 'current_cfx_run_set_selected_step'
  | 'current_cfx_run_set_analysis_mode'
  | 'current_cfx_run_set_melt_mode'
  | 'current_cfx_run_set_hide_targets'
  | 'current_cfx_run_set_hide_fluors'
  | 'current_cfx_run_baseline_subtraction'
  | 'current_cfx_run_drift_correction'
  | 'current_cfx_run_clear_step_error'
  | 'current_cfx_run_amp_scale_type'
  | 'current_cfx_run_plate_setup_done'
  | 'current_cfx_run_update_plate_targets'
  | 'current_cfx_run_cq_sort_order'
  | 'current_cfx_run_show_plate_setup'
  | 'current_cfx_run_update_threshold_settings'
  | 'current_cfx_run_update_amp_selection'
  | 'current_cfx_run_update_melt_selection'
  | 'current_cfx_run_show_log'
  | 'current_cfx_run_hide_log'
  | 'current_cfx_run_log_Loaded'
  | 'current_cfx_run_update_cycle_range_settings';

export const CURRENT_CFX_RUN_INIT: CurrentCfxRunActionType = 'current_cfx_run_init';
export const CURRENT_CFX_RUN_CLEAR: CurrentCfxRunActionType = 'current_cfx_run_clear';
export const CURRENT_CFX_RUN_SET_NAME: CurrentCfxRunActionType = 'current_cfx_run_name';
export const CURRENT_CFX_RUN_SET_NOTES: CurrentCfxRunActionType = 'current_cfx_run_notes';
export const CURRENT_CFX_RUN_SET_PLATE: CurrentCfxRunActionType = 'current_cfx_run_plate';
export const CURRENT_CFX_RUN_REPLACE_PLATE: CurrentCfxRunActionType =
  'current_cfx_run_plate_replace';
export const CURRENT_CFX_RUN_SET_PLATE_ID: CurrentCfxRunActionType = 'current_cfx_run_plate_id';
export const CURRENT_CFX_RUN_SET_PLATE_TYPE: CurrentCfxRunActionType = 'current_cfx_run_ptype';
export const CURRENT_CFX_RUN_PROTOCOL_IS_UPDATING: CurrentCfxRunActionType =
  'current_cfx_run_protocol_is_updating';
export const CURRENT_CFX_RUN_PROTOCOL_UPDATE: CurrentCfxRunActionType =
  'current_cfx_run_protocol_update';
export const CURRENT_CFX_RUN_SET_WELLS_TO_HIDE: CurrentCfxRunActionType =
  'current_cfx_run_set_wells_to_hide';
export const CURRENT_CFX_RUN_SET_SELECTED_STEP: CurrentCfxRunActionType =
  'current_cfx_run_set_selected_step';
export const CURRENT_CFX_RUN_SET_ANALYSIS_MODE: CurrentCfxRunActionType =
  'current_cfx_run_set_analysis_mode';
export const CURRENT_CFX_RUN_SET_MELT_MODE: CurrentCfxRunActionType =
  'current_cfx_run_set_melt_mode';
export const CURRENT_CFX_RUN_SET_HIDE_TARGETS: CurrentCfxRunActionType =
  'current_cfx_run_set_hide_targets';
export const CURRENT_CFX_RUN_SET_HIDE_FLUORS: CurrentCfxRunActionType =
  'current_cfx_run_set_hide_fluors';
export const CURRENT_CFX_RUN_BASELINE_SUBTRACTION: CurrentCfxRunActionType =
  'current_cfx_run_baseline_subtraction';
export const CURRENT_CFX_RUN_DRIFT_CORRECTION: CurrentCfxRunActionType =
  'current_cfx_run_drift_correction';
export const CURRENT_CFX_RUN_CLEAR_STEP_ERROR: CurrentCfxRunActionType =
  'current_cfx_run_clear_step_error';
export const CURRENT_CFX_RUN_AMPCHART_SCALE_TYPE: CurrentCfxRunActionType =
  'current_cfx_run_amp_scale_type';
export const CURRENT_CFX_RUN_PLATE_SETUP_DONE: CurrentCfxRunActionType =
  'current_cfx_run_plate_setup_done';
export const CURRENT_CFX_RUN_UPDATE_PLATE_TARGETS: CurrentCfxRunActionType =
  'current_cfx_run_update_plate_targets';
export const CURRENT_CFX_RUN_CQ_SORT_ORDER: CurrentCfxRunActionType =
  'current_cfx_run_cq_sort_order';
export const CURRENT_CFX_RUN_SHOW_PLATE_SETUP: CurrentCfxRunActionType =
  'current_cfx_run_show_plate_setup';
export const CURRENT_CFX_RUN_UPDATE_THRESHOLD_SETTINGS: CurrentCfxRunActionType =
  'current_cfx_run_update_threshold_settings';
export const CURRENT_CFX_RUN_UPDATE_AMP_SELECTION: CurrentCfxRunActionType =
  'current_cfx_run_update_amp_selection';
export const CURRENT_CFX_RUN_UPDATE_MELT_SELECTION: CurrentCfxRunActionType =
  'current_cfx_run_update_melt_selection';

export const CURRENT_CFX_RUN_UPDATE_CYCLE_RANGE_SETTINGS: CurrentCfxRunActionType =
  'current_cfx_run_update_cycle_range_settings';

export const CURRENT_CFX_RUN_SHOW_LOG: CurrentCfxRunActionType = 'current_cfx_run_show_log';
export const CURRENT_CFX_RUN_HIDE_LOG: CurrentCfxRunActionType = 'current_cfx_run_hide_log';
export const CURRENT_CFX_RUN_LOG_LOADED: CurrentCfxRunActionType = 'current_cfx_run_log_Loaded';
