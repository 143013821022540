import _ from 'lodash';
import { ProjectId } from '../../common/project-management-types';
import { getExtensionOfFile } from '../../utils/fileUtils';
import FileOperationEvent from '../FileOperationEvent';
import { getNextUploadId } from '../utils';
import {
  FILEUPLOAD_FAILED,
  FILEUPLOAD_PROCESSING,
  FILEUPLOAD_SIZE_EXCEEDED,
  FILEUPLOAD_STARTED,
  FILEUPLOAD_UNSUPPORTED,
  FILEUPLOAD_PROCESSING_COMPLETE
} from '../file_operation_types';
import { FileOperationType, UPLOAD_IN_PROGRESS } from '../../common/userfiles_common';
import { UserFile } from '../../common/types';
import { isNetworkError } from '../../utils/errorUtils';
import { UploadNetworkError } from '../messages';
import { Dispatch, GetState } from '../../../../types';

export default class ProtocolUpload {
  public readonly id: number;

  protected readonly file: File;

  protected readonly projectId: ProjectId;

  protected readonly dispatch: Dispatch<any, any>;

  protected readonly getState: GetState;

  private static readonly maximumUploadSize: number = 1000000; // 1 MB

  private static readonly prclExtension = 'prcl';

  constructor(file: File, projectId: ProjectId, dispatch: Dispatch<any, any>, getState: GetState) {
    this.file = file;
    this.projectId = projectId;
    this.dispatch = dispatch;
    this.getState = getState;
    this.id = getNextUploadId();
  }

  async start() {
    try {
      this.notifyStart();
      if (!this.validateFile()) return;
      this.notifyProcessing();
      const buffer = await this.convertFileToBuffer();
      const entity = await this.createProtocolFromFile(buffer);
      this.notifyProtocolCreated(entity);
      this.notifyProcessingComplete(entity);
    } catch (error) {
      this.handleError(error as Error);
    }
  }

  validateFile(): boolean {
    if (!this.validateFileExtension()) {
      this.notifyUnsupportedFile();
      return false;
    }
    if (!this.validateFileSize()) {
      this.notifyFileTooBig();
      return false;
    }
    return true;
  }

  validateFileExtension(): boolean {
    const { name } = this.file;
    const fileExtension = getExtensionOfFile(name);
    return fileExtension.toLowerCase() === ProtocolUpload.prclExtension;
  }

  validateFileSize(): boolean {
    const { size } = this.file;
    return size != null && size <= ProtocolUpload.maximumUploadSize;
  }

  async convertFileToBuffer(): Promise<Record<string, any>> {
    const fileBuffer = await this.file.arrayBuffer();
    const buffer = Buffer.from(fileBuffer);
    return buffer.toJSON();
  }

  getFileNameNoExtension(): string {
    const { name } = this.file;
    const extensionWithDot = `.${ProtocolUpload.prclExtension}`;
    if (name.endsWith(extensionWithDot))
      return name.substring(0, name.length - extensionWithDot.length);
    return name;
  }

  // eslint-disable-next-line class-methods-use-this
  async createProtocolFromFile(buffer: Record<string, any>): Promise<UserFile> {
    throw new Error(`createProtocolFromFile must be implemented, buffer=${buffer}`);
  }

  // eslint-disable-next-line class-methods-use-this,no-unused-vars,@typescript-eslint/no-unused-vars
  notifyProtocolCreated(entity: UserFile): void {
    // can be overridden by derived classes
  }

  handleError(error: Error) {
    const errMsg = _.get(error, 'response.data');
    if (errMsg != null) {
      if (errMsg === `unsupported file format`) {
        this.notifyFailed('Upload failed; BR.io does not support this format');
        return;
      }
    }
    if (isNetworkError(error)) {
      this.notifyFailed(UploadNetworkError);
      return;
    }
    this.notifyFailed();
  }

  notifyStart() {
    FileOperationEvent.notify(FILEUPLOAD_STARTED, {
      uploads: [
        {
          id: this.id,
          file: this.file,
          status: UPLOAD_IN_PROGRESS,
          fileOperationType: FileOperationType.upload
        }
      ]
    });
  }

  notifyProcessing() {
    FileOperationEvent.notify(FILEUPLOAD_PROCESSING, {
      id: this.id
    });
  }

  notifyProcessingComplete(entity: UserFile) {
    FileOperationEvent.notify(FILEUPLOAD_PROCESSING_COMPLETE, {
      entity,
      id: this.id
    });
  }

  notifyFailed(errorMessage?: string) {
    FileOperationEvent.notify(FILEUPLOAD_FAILED, { id: this.id, errorMessage });
  }

  notifyUnsupportedFile() {
    FileOperationEvent.notify(FILEUPLOAD_UNSUPPORTED, {
      ids: [this.id],
      errorMessage: 'BR.io supports PRCL files only'
    });
  }

  notifyFileTooBig() {
    FileOperationEvent.notify(FILEUPLOAD_SIZE_EXCEEDED, {
      ids: [this.id],
      errorMessage: 'File exceeds the 1MB limit'
    });
  }
}
