import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { matchPath } from 'react-router';
import classNames from 'classnames';
import { ProjectRoles } from '@biorad-lsg-tsc/organization';
import ProjectDropdown from './ProjectDropdown';
import '../../styles/org-project-selector.scss';
import '../../styles/project-selector.scss';
import {
  DEFAULT_PROJECT_ID,
  Project,
  ProjectId,
  USER_FILES
} from '../../../frontend-common-libs/src/common/project-management-types';
import changeProjectSelectionAction from '../../actions/change-project-selection';
import realTimePcrRoutes from '../../../real-time-pcr/routes';
import conventionalPcrRoutes from '../../../conventional-pcr-frontend/routes';
import coreRoutes from '../../../core/routes';
import { ReduxState } from '../../../types';
import ProjectMsgsSubscriber, {
  SelectProjectEventHandler
} from '../../../frontend-common-libs/src/system-event/project-management/ProjectMsgsSubscriber';
import { getProjectsIfNeeded as getProjectsAction } from '../../actions/get-projects';
import {
  isProjectsLoaded,
  getProjects as getProjectsSelector,
  getSelectedProjectId,
  getProjectUserToken,
  isProjectTokenLoading
} from '../../selectors/selectors';
import {
  getActiveOrg,
  getOrgUserToken
} from '../../../organization-management/selectors/selectors';
import OrgToken from '../../../organization-management/organizations/OrgToken';
import orgRoutes from '../../../organization-management/router/OrgRoutes';
import ProjectToken from '../ProjectToken';
import { ProjectRepository } from '../../repository';
import ProjectRole from './ProjectRole';
import { getRole } from '../../selectors/roles';
import FeatureFlags, {
  FeatureFlagKeys
} from '../../../frontend-common-libs/src/components/feature_flags';

export type Props = {
  changeProjectSelection: SelectProjectEventHandler;
  selectedProjectId: ProjectId;
  location: RouteComponentProps['location'];
  projectLoaded: boolean;
  projects: Project[];
  getProjects: typeof getProjectsAction;
  orgId: string;
  orgAccessToken?: string;
  showProjectName?: boolean;
  isTokenLoading: boolean;
  projectRole?: ProjectRoles;
};

let projectMsgSubscriber: ProjectMsgsSubscriber;

export class OrgProjectSelectorImpl extends PureComponent<Props> {
  constructor(props: Props) {
    super(props);
    projectMsgSubscriber = new ProjectMsgsSubscriber();
    projectMsgSubscriber.registerEvent(this.handleSetSelectedProjectEvent);
  }

  async componentDidMount() {
    await this.getOrgProjectsIfNeeded();
  }

  async getOrgProjects() {
    const { getProjects, orgId } = this.props;
    getProjects(orgId);
  }

  componentWillUnmount() {
    projectMsgSubscriber.unregisterEvent();
  }

  async componentDidUpdate(prevProps: Props) {
    await this.getOrgProjectsIfNeeded();
    await this.updateSelectedProjectIfNeeded(prevProps);
  }

  async updateSelectedProjectIfNeeded(prevProps: Props) {
    const { orgId, projects, selectedProjectId, changeProjectSelection, projectLoaded } =
      this.props;
    if (orgId === prevProps.orgId || !projects || !projectLoaded) return;
    const isValidSelectedProject = ProjectRepository.isProjectInProjects(
      selectedProjectId,
      projects
    );
    if (!isValidSelectedProject) changeProjectSelection(DEFAULT_PROJECT_ID);
  }

  async getOrgProjectsIfNeeded() {
    const { projectLoaded, orgAccessToken } = this.props;
    if (orgAccessToken != null && !projectLoaded) await this.getOrgProjects();
  }

  handleSetSelectedProjectEvent = (projectId: ProjectId) => {
    const { selectedProjectId, changeProjectSelection } = this.props;
    if (projectId == null || projectId === selectedProjectId) return;
    changeProjectSelection(projectId);
  };

  static canChangeProject(path: string): boolean {
    const allowedPaths = [
      coreRoutes.HOME,
      coreRoutes.CREATE_EXPERIMENT,
      coreRoutes.ARCHIVE,
      coreRoutes.FILE_LIST,
      `${coreRoutes.APP}${realTimePcrRoutes.QPCR_PROTOCOL_LIST}`,
      `${coreRoutes.APP}${conventionalPcrRoutes.PCR_PROTOCOL_LIST}`,
      orgRoutes.EDIT_ORGANIZATION
    ];
    return allowedPaths.some(p => matchPath(path, { path: p, exact: true }));
  }

  get isDisabled() {
    const { location } = this.props;
    return !OrgProjectSelectorImpl.canChangeProject(location.pathname);
  }

  handleProjectSelection = (selectedProjectId: ProjectId) => {
    const { changeProjectSelection } = this.props;
    changeProjectSelection(selectedProjectId);
  };

  showDropdown(): boolean {
    const { selectedProjectId, projectLoaded, projects } = this.props;
    const isValidSelectedProject = ProjectRepository.isProjectInProjects(
      selectedProjectId,
      projects
    );
    return projects && projectLoaded && isValidSelectedProject;
  }

  renderProjectDropdown() {
    const { selectedProjectId, projects, orgId } = this.props;
    return this.showDropdown() ? (
      <>
        <ProjectDropdown
          projects={projects}
          selectedProjectId={selectedProjectId}
          onSelection={this.handleProjectSelection}
          disabled={this.isDisabled}
        />
        <ProjectToken projectId={selectedProjectId} />
      </>
    ) : (
      <OrgToken orgId={orgId} />
    );
  }

  render() {
    const { showProjectName, selectedProjectId, isTokenLoading, projectRole } = this.props;
    const ffOn = FeatureFlags().get(FeatureFlagKeys.PROJECT_REVIEWER);
    return (
      <div className={ffOn ? 'project-selector' : 'org-project-selector'}>
        {ffOn && this.showDropdown() && (
          <ProjectRole
            selectedProjectId={selectedProjectId}
            isTokenLoading={isTokenLoading}
            projectRole={projectRole}
          />
        )}
        {!ffOn && <img src="/img/nav-projects.svg" className="project-img" alt="projects" />}
        <div
          className={classNames('project-dropdown-container', {
            'project-dropdown-collapsed': !showProjectName
          })}
        >
          {this.renderProjectDropdown()}
        </div>
      </div>
    );
  }
}

export function mapStateToProps(state: ReduxState): {
  [key: string]: any;
} {
  const orgId = getActiveOrg(state);
  // @ts-ignore
  let projects = getProjectsSelector(state, orgId).toJS() as Project[];
  const defaultProject = { id: DEFAULT_PROJECT_ID, name: USER_FILES };
  projects = [defaultProject, ...projects];
  const selectedProjectId = getSelectedProjectId(state);
  const projectAccessToken = getProjectUserToken(state, selectedProjectId);
  return {
    selectedProjectId,
    projectLoaded: isProjectsLoaded(state, orgId),
    projects,
    orgId,
    orgAccessToken: getOrgUserToken(state, orgId),
    isTokenLoading: isProjectTokenLoading(state, selectedProjectId),
    projectRole: getRole(projectAccessToken)
  };
}

export default withRouter(
  // @ts-ignore
  connect(mapStateToProps, {
    changeProjectSelection: changeProjectSelectionAction,
    getProjects: getProjectsAction
    // @ts-ignore
  })(OrgProjectSelectorImpl)
);
