export const FILETYPE_CFX_RUN = 'cfx-run';
export const FILETYPE_PENDING_CFX_RUN = 'cfx-pending-run';
export const FILETYPE_IN_PROGRESS_CFX_RUN = 'cfx-in-progress-run';
export const FILETYPE_CFX_RUN_TEMPLATE = 'cfx-run-template';
export const FILETYPE_CFX_PROTOCOL = 'qpcr-protocol';

export default class CFXOpusFileRowType {
  private static displayedFileTypes: { [key: string]: string } = {
    [FILETYPE_CFX_RUN]: 'CFX run: Completed',
    [FILETYPE_IN_PROGRESS_CFX_RUN]: 'CFX run: In progress',
    [FILETYPE_PENDING_CFX_RUN]: 'CFX run: Pending',
    [FILETYPE_CFX_RUN_TEMPLATE]: 'CFX run: Template',
    [FILETYPE_CFX_PROTOCOL]: 'CFX Protocol'
  };

  public static getDisplayedFileType(type: string): string {
    return CFXOpusFileRowType.displayedFileTypes[type] || type;
  }
}
