import React from 'react';
import RectangularOverflowSkeletonLoader from './RectangularOverflowSkeletonLoader';
import FileIconSkeletonLoader from './FileIconSkeletonLoader';

export default function () {
  return (
    <tr tabIndex={0}>
      <td>
        <FileIconSkeletonLoader />
      </td>
      <td>
        <RectangularOverflowSkeletonLoader />
      </td>
      <td>
        <RectangularOverflowSkeletonLoader />
      </td>
      <td>
        <RectangularOverflowSkeletonLoader />
      </td>
      <td />
    </tr>
  );
}
