import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { ScaleLinear } from '@visx/vendor/d3-scale';
import { List, Map } from 'immutable';

import Camera from './Camera';
import { TEMP, GRADIENT, MELT } from '../../../../utils/protocolUtils';

export type StepProps = {
  prev?: Map<string, any>;
  step: Map<string, any>;
  yScale: ScaleLinear<number, number>;
  stepWidth: number;
  index: number;
};

export type Margins = {
  top: number;
  bottom: number;
  right: number;
  left: number;
};
export type AxisPadding = {
  top: number;
  bottom: number;
};

export type Point = { x: number; y: number | undefined };

const makePathData = (data: Point[]) => {
  let first = true;
  return data
    .map(d => {
      if (first) {
        first = false;
        return `M${d.x} ${d.y}`;
      }
      return `L ${d.x} ${d.y}`;
    })
    .join(' ');
};

export const makePath = (data: Point[], index: number) => (
  <path d={makePathData(data)} className="temperature-path" key={index} fill="none" />
);

export const makeTextAboveLine = (x: number, y: number, text: string, id?: string) => (
  <text id={id} x={x} y={y - 6} className="temperature-label" dominantBaseline="baseline">
    {text}
  </text>
);

export const makeTextBelowLine = (
  x: number,
  y: number,
  text: string,
  id?: string,
  className = 'time-label'
) => (
  <text id={id} x={x} y={y + 6} className={className} dominantBaseline="hanging">
    {text}
  </text>
);

export const makeCamera = (
  stepIndex: number,
  temp: number,
  x: number,
  scale: ScaleLinear<number, number>,
  className?: string
) => (
  <Camera
    stepIndex={stepIndex}
    cx={x}
    cy={temp < 25 ? scale(95) : scale(2)}
    // @ts-ignore
    className={className}
  />
);

export function isMelt(step: Map<string, any>) {
  return step.get('type') === MELT;
}

export function meltStepCount(protocol: List<Map<string, any>>) {
  let count = 0;
  protocol.forEach(x => {
    if (isMelt(x)) {
      count += 1;
    }
  });
  return count;
}

export function endTemps(step?: Map<string, any>): Array<number> {
  if (!step) {
    return [25];
  }
  switch (step.get('type')) {
    case TEMP:
      return [step.get('temp')];
    case GRADIENT:
      return [step.get('temp'), step.get('temp') + step.get('grd')];
    case MELT:
      return [step.get('end')];
    default:
      return [];
  }
}
