import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getCurrentUserIdentityId } from '../../frontend-common-libs/src/utils/authUtils';
import { getRunDetailsUrl, isRunInProgressState } from '../utils/instrumentUtils';
import goToRunIcon from './assets/go-to-icon.svg';
import UtilityButton from '../../frontend-common-libs/src/components/common/buttons/UtilityButton';
import { unlinkInstrument as unlinkInstrumentAction } from '../../instruments/actions/instrument.actions';
import notification from '../../frontend-common-libs/src/utils/notifications';
import { NullableString } from '../../frontend-common-libs/src/common/nullable-types';
import { InstrumentStatusEnumValue } from '../../instruments/utils/instrumentUtils';

export type Props = {
  serial: string;
  instrumentId: string;
  status: InstrumentStatusEnumValue | NullableString;
  userId: string;
  runId: string;
  runName: string | null | undefined;
  step: number;
  steps: number;
  cycle: number;
  cycles: number;
  unlinkInstrument: (arg0: string) => void;
};

type State = {
  unlinkInProgress: boolean;
};

export class InstrumentDetailsImpl extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { unlinkInProgress: false };
  }

  handleUnlinkUserClick = async () => {
    this.setState({ unlinkInProgress: true });
    const { instrumentId } = this.props;
    try {
      const { unlinkInstrument } = this.props;
      await unlinkInstrument(instrumentId);
    } catch (error) {
      notification.error('Error unlinking instrument. Please try again.');
      this.setState({ unlinkInProgress: false });
    }
  };

  renderUserRunPanel = () => {
    const { runId, runName, step, steps, cycle, cycles } = this.props;

    return (
      <div className="run-panel run-panel-background">
        {/* @ts-ignore */}
        <div className="title-label" title={runName}>
          {runName}
        </div>
        <div className="run-details">
          <a id={`inst_run_${runId}`} className="link-action" href={getRunDetailsUrl(runId)}>
            <img className="link-img" src={goToRunIcon} alt="go to run" />
            Go To Run
          </a>
          {cycles !== 0 && <div className="category">Cycles</div>}
          {cycles !== 0 && (
            <div className="category-value separator">
              {cycle} of {cycles}
            </div>
          )}
          {steps !== 0 && <div className="category">Steps</div>}
          {steps !== 0 && (
            <div className="category-value">
              {step} of {steps}
            </div>
          )}
        </div>
      </div>
    );
  };

  renderRunPanel = () => {
    const { status, userId, runId } = this.props;
    // when status is not running or paused do not display run panel
    if (!isRunInProgressState(status)) return <div className="run-panel" />;
    // when the run doesn't belong to the user display notification. Also when there is no runId (it is a local run that was started directly on the instrument)
    const isRunOfCurrentUser = userId && userId === getCurrentUserIdentityId();
    if (!isRunOfCurrentUser || !runId)
      return (
        <div className="run-panel run-panel-background">
          <div className="title-label">IN USE BY ANOTHER USER</div>
        </div>
      );
    // when the run belongs to the user display run information
    return this.renderUserRunPanel();
  };

  render() {
    const { serial, instrumentId } = this.props;
    const { unlinkInProgress } = this.state;
    return (
      <>
        {this.renderRunPanel()}
        <div className="details-panel">
          <div className="title-label">INSTRUMENT DETAILS</div>
          <div className="details-line">
            <div className="category">Serial Number</div>
            <div className="category-value" title={serial}>
              {serial}
            </div>
          </div>
        </div>
        <div className="button-group right">
          <UtilityButton
            id={`unlink-instrument-button:${instrumentId}`}
            type="button"
            onClick={this.handleUnlinkUserClick}
            isInProgress={unlinkInProgress}
          >
            Unlink Instrument
          </UtilityButton>
        </div>
      </>
    );
  }
}

export default connect(null, { unlinkInstrument: unlinkInstrumentAction })(InstrumentDetailsImpl);
