import GradientTemperature from './GradientTemperature';
import InvalidGradientRowIndex from './InvalidGradientRowIndex';

export default class GradientTemperatureValid implements GradientTemperature {
  constructor(rowIndex: number, temperature: number, numRows: number) {
    if (rowIndex >= numRows) {
      throw new InvalidGradientRowIndex();
    }
    this.numRows = numRows;
    this.rowIndex = rowIndex;
    this.temperatureValue = temperature;
  }

  private readonly rowIndex: number;

  private readonly numRows: number;

  private readonly temperatureValue: number;

  public readonly textColor = '#ffffff';

  public get value(): string {
    return `${this.temperatureValue.toFixed(1)}`;
  }

  public get backgroundColor(): string {
    const backgroundColors = [
      '#fb0000',
      '#db0000',
      '#c00014',
      '#a10028',
      '#850338',
      '#670a45',
      '#490e53',
      '#2a1361'
    ];
    const colorIndex = Math.floor(this.rowIndex * (backgroundColors.length / this.numRows));
    return backgroundColors[colorIndex];
  }
}
