import React from 'react';
import cameraIcon from '../../../../img/camera.svg';

export type Props = {
  stepIndex: number;
  cx: number;
  cy: number;
};

function Camera(props: Props) {
  const { stepIndex, cx, cy } = props;
  return (
    <g id={`graph-cam-${stepIndex + 1}`} transform={`translate(${cx} ${cy})`}>
      <image x="-14" y="-14" height="24" width="30" xlinkHref={cameraIcon} />
    </g>
  );
}

Camera.displayName = 'Camera';

export default Camera;
