import * as React from 'react';

type ShowProps = {
  show: boolean;
};

const showOrNot = <Props extends {}>(
  WrappedComponent: React.ComponentType<Props>
): React.ComponentType<Props & ShowProps> =>
  function ShowOrNot(props: Props & ShowProps) {
    const { show, ...passThroughProps } = props;
    if (!show) {
      return null;
    }
    // @ts-ignore
    return <WrappedComponent {...passThroughProps} />;
  };

export default showOrNot;
