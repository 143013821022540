import React from 'react';
import './notifications.scss';

export type Props = {
  notificationsNumber: number;
  notificationsLimit?: number;
  tooltip?: string | null;
  className?: string;
};

export function NotificationIcon(props: Props) {
  const { notificationsNumber, notificationsLimit, className, tooltip } = props;
  if (!notificationsNumber) return null;
  let notificationNumberDisplayed = `${notificationsNumber}`;
  if (notificationsLimit && notificationsNumber > notificationsLimit)
    notificationNumberDisplayed = `${notificationsLimit}⁺`;
  return (
    <div
      id="notifications-number"
      className={`notification-icon ${className}`}
      // @ts-ignore
      title={tooltip !== undefined ? tooltip : `${notificationsNumber} notifications`}
    >
      {notificationNumberDisplayed}
    </div>
  );
}
