import { Dispatch, GetState } from '../../types';
import { isTokenExpiredError } from '../api/api-errors';

export type CbWithToken = (accessToken: string) => Promise<any>;

export type DispatchType = Dispatch<any, Record<string, any>>;

export abstract class WithRefreshToken {
  constructor(protected dispatch: DispatchType, protected getState: GetState) {}

  abstract getToken(): string;

  abstract getRefreshToken(): string;

  abstract dispatchTokenLoading(): void;

  abstract dispatchTokenLoaded(token: any): void;

  // @ts-ignore
  abstract async refreshAccessToken(refreshToken: string, accessToken: string): Promise<any>;

  async withRefreshToken(cb: CbWithToken): Promise<any> {
    const accessToken = this.getToken();
    try {
      return await cb(accessToken);
    } catch (e) {
      if (isTokenExpiredError(e)) {
        this.dispatchTokenLoading();
        // refresh the token
        const refreshToken = this.getRefreshToken();
        const loginInfo = await this.refreshAccessToken(refreshToken, accessToken);
        // store refreshed token
        this.dispatchTokenLoaded(loginInfo);
        // use refreshed token to call the call back again
        const accessTokenNew = this.getToken();
        return await cb(accessTokenNew);
      }
      throw e;
    }
  }
}
