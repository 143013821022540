import InstrumentShadowGroup from './InstrumentShadowGroup';
import CredentialsRetryProvider from './credentials/credentials-retry-provider';
import { InstrumentStatusCallback } from './types';
import IOTCredentialsProvider from './IOTCredentialsProvider';
import InstrumentGroupCredentials from './instrument-group-credentials';
import ReservationShadowWebsocketRepository from './ReservationShadowWebsocketRepository';

export default class StartInstrumentGroupAction {
  instrumentStatusCallback: InstrumentStatusCallback;

  credentialsRetryProvider: CredentialsRetryProvider;

  constructor(
    instrumentStatusCallback: InstrumentStatusCallback,
    iotCredentialsProvider: IOTCredentialsProvider
  ) {
    this.instrumentStatusCallback = instrumentStatusCallback;
    this.credentialsRetryProvider = new CredentialsRetryProvider(iotCredentialsProvider, 1);
  }

  cancel() {
    this.credentialsRetryProvider.cancel();
  }

  async execute() {
    const credentials = await this.credentialsRetryProvider.resolve();
    const { instrumentGroupCredentialsCollection } = credentials;
    ReservationShadowWebsocketRepository.instance.storeAllInstrumentCredentials(
      instrumentGroupCredentialsCollection as InstrumentGroupCredentials[]
    );
    const instrumentShadowGroupCollection = instrumentGroupCredentialsCollection.map(
      (instrumentGroupCredentials: any) =>
        new InstrumentShadowGroup(instrumentGroupCredentials, this.instrumentStatusCallback)
    );
    return {
      instrumentShadowGroupCollection,
      credentials
    };
  }
}
