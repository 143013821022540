import { ProjectRoles } from '@biorad-lsg-tsc/organization';
import { DispatchType, PROJECT_MEMBER_ROLE_CHANGED, PROJECT_TOKEN_REVOKED } from './action-types';
import { setProjectMemberRole } from '../api/project-api';
import { GetState, ReduxState } from '../../types';
import notification from '../../frontend-common-libs/src/utils/notifications';
import { getUserEmail } from '../../selectors/auth-selectors';
import {
  TrackedOrganizationEvents,
  trackEvent
} from '../../frontend-common-libs/src/user-analytics/trackedEvents';
import { isAtLeastOneAdminError } from '../../organization-management/api/api-errors';
import { withRefreshProjectToken } from './with-refresh-project-token';
import { CbWithToken } from '../../organization-management/actions/WithRefreshToken';

export function dispatchProjectRoleChanged(
  dispatch: DispatchType,
  state: ReduxState,
  projectId: string,
  userName: string,
  role: ProjectRoles
) {
  dispatch({
    type: PROJECT_MEMBER_ROLE_CHANGED,
    payload: { projectId, userName, role }
  });
  const email = getUserEmail(state);
  if (userName === email) {
    dispatch({
      type: PROJECT_TOKEN_REVOKED,
      payload: { projectId }
    });
  }
}
export function setProjectRole(projectId: string, userName: string, role: ProjectRoles) {
  return async (dispatch: DispatchType, getState: GetState) => {
    try {
      const state = getState();
      const setMemberRoleCb: CbWithToken = async (accessToken: string) => {
        return setProjectMemberRole(projectId, userName, role, accessToken);
      };
      await withRefreshProjectToken(dispatch, getState, projectId, setMemberRoleCb);
      dispatchProjectRoleChanged(dispatch, state, projectId, userName, role);
      trackEvent(TrackedOrganizationEvents.AssignProjectRole, {
        userName,
        role
      });
    } catch (error) {
      let errMsg = 'Error changing member role in the project';
      if (isAtLeastOneAdminError(error)) errMsg = 'A project must have at least one administrator.';
      notification.error(errMsg);
    }
  };
}
