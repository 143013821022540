import React, { ReactElement } from 'react';
import { Route, Switch } from 'react-router-dom';
import PCRProtocolFullPage from '../components/pcr/pcr-protocols/PCRProtocolFullPage';
import TemplateSelectorPage from '../pcr/components/template-selector/TemplateSelectorPage';
import ManageCfxProtocols from '../components/pcr/ManageCfxProtocols';
import PCRDataPage from '../components/pcr/analysis/PCRDataPage';
import PageNotFound from '../frontend-common-libs/src/components/pages/PageNotFound';
import routes, { ROUTE_NOT_FOUND } from './routes';

export type Props = {
  rootPath?: string;
};

export default function App(props: Props): ReactElement {
  const { rootPath } = props;
  const {
    QPCR_PROTOCOL_LIST,
    CREATE_QPCR_PROTOCOL,
    VIEW_QPCR_PROTOCOL,
    CREATE_QPCR_RUN_FROM_TEMPLATE,
    CREATE_QPCR,
    VIEW_QPCR
  } = routes;

  return (
    <Switch>
      <Route exact path={`${rootPath}${QPCR_PROTOCOL_LIST}`} component={ManageCfxProtocols} />
      <Route exact path={`${rootPath}${CREATE_QPCR_PROTOCOL}`} component={PCRProtocolFullPage} />
      <Route exact path={`${rootPath}${VIEW_QPCR_PROTOCOL}`} component={PCRProtocolFullPage} />
      <Route
        path={`${rootPath}${CREATE_QPCR_RUN_FROM_TEMPLATE}`}
        component={TemplateSelectorPage}
      />
      <Route path={`${rootPath}${CREATE_QPCR}`} component={PCRDataPage} />
      <Route path={`${rootPath}${VIEW_QPCR}`} component={PCRDataPage} />
      <Route path={ROUTE_NOT_FOUND} component={PageNotFound} />
    </Switch>
  );
}
