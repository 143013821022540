import React, { Component } from 'react';
import SelectMenuItem from './SelectMenuItem';

export type NonSelectableMenuItemProps = {
  id?: string;
  children: any;
  menuItemRef?: any;
  onClick?: (event: React.SyntheticEvent<HTMLButtonElement>) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLButtonElement>) => void;
};

export default class NonSelectableMenuItem extends Component<NonSelectableMenuItemProps> {
  static defaultProps = {
    id: undefined,
    menuItemRef: undefined,
    onClick: undefined,
    onKeyDown: undefined
  };

  static value = Symbol('non-selectable');

  render() {
    const { children, menuItemRef, id, onClick, onKeyDown } = this.props;
    return (
      <SelectMenuItem
        id={id}
        value={NonSelectableMenuItem.value}
        menuItemRef={menuItemRef}
        onClick={onClick}
        onKeyDown={onKeyDown}
      >
        {children}
      </SelectMenuItem>
    );
  }
}
