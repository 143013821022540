import React, { useState } from 'react';
import { Project } from '../../frontend-common-libs/src/common/project-management-types';
import '../styles/edit-project.scss';
import DetailsHeaderValue from '../../frontend-common-libs/src/components/common/run/DetailsHeaderValue';
import { toLongDateFormat } from '../../frontend-common-libs/src/common/display-formats';
import editIcon from '../../frontend-common-libs/src/img/protocol-edit-icon.svg';
import EditFileModal, {
  EditModalFormValues
} from '../../frontend-common-libs/src/components/common/dialogs/EditFileModal';
import { editFileStyleV2 } from '../../frontend-common-libs/src/components/common/dialogs/custom-modal-styles';

export type Props = {
  project: Project;
  created?: string;
  owner?: string;
  hasEditPermissions?: boolean;
  onRenameProject?: (newName: string) => Promise<void>;
};

export default function EditProjectHeader(props: Props) {
  const [renaming, setRenaming] = useState(false);
  const { project, created, owner, hasEditPermissions } = props;

  const onProjectRenamed = async (formValues: EditModalFormValues) => {
    const { name } = formValues;
    const { onRenameProject } = props;
    if (onRenameProject) await onRenameProject(name);
    setRenaming(false);
  };

  const renderRenameProjectPopup = () => {
    return (
      <EditFileModal
        show={renaming}
        // @ts-ignore
        title="Rename project"
        onSuccess={onProjectRenamed}
        onCancel={() => {
          setRenaming(false);
        }}
        okButtonText="Rename"
        name={project.name}
        initialValues={{ name: project.name }}
        customModalStyle={editFileStyleV2}
      />
    );
  };

  return (
    <div className="header">
      <div className="project-name">
        <span id="project-name" className="with-ellipsis" title={project.name}>
          {project.name}
        </span>
        {hasEditPermissions && (
          <button
            id="rename-project"
            onClick={() => {
              setRenaming(true);
            }}
            className="rename-button hover-image-button"
          >
            <img src={editIcon} alt="edit" />
          </button>
        )}
      </div>
      <div className="project-details">
        <DetailsHeaderValue header="Owner" value={owner} />
        <DetailsHeaderValue
          header="Created"
          value={toLongDateFormat(created)}
          className="project-created"
        />
      </div>
      {renderRenameProjectPopup()}
    </div>
  );
}
