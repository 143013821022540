import React, { Component } from 'react';
import './styles/tenant-list.scss';
import OrgRow from './OrgRow';
import { Organization } from '../actions/action-types';

export type Props = {
  orgs: Organization[];
  title: string;
  onRowClick: (org: Organization) => void;
  selectedOrganizationId?: string;
};

// eslint-disable-next-line react/prefer-stateless-function
export default class OrgList extends Component<Props> {
  render() {
    const { orgs, title, selectedOrganizationId, onRowClick } = this.props;
    return (
      <div className="tenant-list">
        <div id="org-list-title" className="header-line">
          {title}
        </div>
        <div className="tenant-rows">
          {orgs.map((org: Organization) => (
            <OrgRow
              org={org}
              key={org.id}
              onClick={onRowClick}
              isSelected={selectedOrganizationId != null && org.id === selectedOrganizationId}
              isActive={org.id === selectedOrganizationId}
            />
          ))}
        </div>
      </div>
    );
  }
}
