import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import PageNotFound from '../frontend-common-libs/src/components/pages/PageNotFound';
import AuthenticatedRedirect from './auth/AuthenticatedRedirect';
import LogoHeaderWithAuth from './auth/LogoHeaderWithAuth';
import Register from '../auth/components/register/Register';
import Login from '../auth/components/login/Login';
import Landing from './landing';
import ForgotPassword from '../auth/components/reset-password/forgot_password';
import PasswordReset from '../auth/components/reset-password/PasswordReset';
import routes, { ROUTE_NOT_FOUND } from '../core/routes';
import UnAuthedFooter from './auth/UnAuthedFooter';
import { ReduxState } from '../types';

export type Props = {
  shouldRedirectToVerifyPage: boolean;
};

export function PublicPagesImpl({ shouldRedirectToVerifyPage }: Props) {
  return (
    // @ts-ignore
    <AuthenticatedRedirect authenticated={false}>
      <>
        <LogoHeaderWithAuth className="content-container" />
        <Switch>
          <Route exact path={routes.LANDING} component={Landing} />
          <Route exact path={routes.REGISTER} component={Register} />
          <Route exact path={routes.LOGIN} component={Login} />
          <Route exact path={routes.FORGOT_PASSWORD} component={ForgotPassword} />
          <Route exact path={routes.RESET_PASSWORD} component={PasswordReset} />
          <Route path={ROUTE_NOT_FOUND} component={PageNotFound} />
        </Switch>
        <UnAuthedFooter className="content-container" />
        {shouldRedirectToVerifyPage && <Redirect to={routes.VERIFY_ACCOUNT} />}
      </>
    </AuthenticatedRedirect>
  );
}

const mapStateToProps = (state: ReduxState) => {
  return {
    shouldRedirectToVerifyPage: state.auth.get('shouldRedirectToVerifyPage')
  };
};

export default connect(mapStateToProps)(PublicPagesImpl as any);
