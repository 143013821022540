import { Map, OrderedMap } from 'immutable';
import { fromJS, Set } from 'immutable';

import { CURRENT_CFX_RUN_DRIFT_CORRECTION, CurrentCfxRunActionType } from './currentCfxRun_types';
import {
  CURRENT_CFX_RUN_BASELINE_SUBTRACTION,
  CURRENT_CFX_RUN_CLEAR,
  CURRENT_CFX_RUN_CLEAR_STEP_ERROR,
  CURRENT_CFX_RUN_INIT,
  CURRENT_CFX_RUN_PROTOCOL_IS_UPDATING,
  CURRENT_CFX_RUN_PROTOCOL_UPDATE,
  CURRENT_CFX_RUN_SET_ANALYSIS_MODE,
  CURRENT_CFX_RUN_SET_HIDE_FLUORS,
  CURRENT_CFX_RUN_SET_HIDE_TARGETS,
  CURRENT_CFX_RUN_UPDATE_THRESHOLD_SETTINGS,
  CURRENT_CFX_RUN_SET_NAME,
  CURRENT_CFX_RUN_SET_NOTES,
  CURRENT_CFX_RUN_SET_PLATE,
  CURRENT_CFX_RUN_SET_PLATE_ID,
  CURRENT_CFX_RUN_SET_PLATE_TYPE,
  CURRENT_CFX_RUN_SET_SELECTED_STEP,
  CURRENT_CFX_RUN_SET_WELLS_TO_HIDE,
  CURRENT_CFX_RUN_AMPCHART_SCALE_TYPE,
  CURRENT_CFX_RUN_UPDATE_PLATE_TARGETS,
  CURRENT_CFX_RUN_CQ_SORT_ORDER,
  CURRENT_CFX_RUN_SHOW_PLATE_SETUP,
  CURRENT_CFX_RUN_UPDATE_AMP_SELECTION,
  CURRENT_CFX_RUN_UPDATE_MELT_SELECTION,
  CURRENT_CFX_RUN_REPLACE_PLATE,
  CURRENT_CFX_RUN_SHOW_LOG,
  CURRENT_CFX_RUN_HIDE_LOG,
  CURRENT_CFX_RUN_LOG_LOADED,
  CURRENT_CFX_RUN_SET_MELT_MODE,
  CURRENT_CFX_RUN_UPDATE_CYCLE_RANGE_SETTINGS
} from './currentCfxRun_types';
import { getDefaultPlate } from '../utils/microplateUtils';
import { AnalysisModeEnum, Dispatch } from '../types';
import { getRunLogReq } from '../api/pcrData';
import { getUrlAsJson } from '../frontend-common-libs/src/utils/httpUtils';
import { formatRunLogTime } from '../frontend-common-libs/src/common/display-formats';
import { FILETYPE_PENDING_CFX_RUN } from '../frontend-common-libs/src/common/userfiles_common';
import {
  validateRunBarcode,
  validateFileName,
  validateRunNotes
} from '../frontend-common-libs/src/components/common/run/validations/run-validation';

/**
 * Initializes the currentCfxRun for the given entity.
 * To initialize a new CFX run, provide a falsy value for entity
 * @param {string} entityId - the entity to be viewed/edited
 * @param {Map | undefined} pcrData - optional parameter.
 */
export function initCurrentCfxRun(entityId?: string, pcrData?: Map<string, any>) {
  return (
    dispatch: Dispatch<
      CurrentCfxRunActionType,
      {
        [key: string]: any;
      } | void
    >
  ) => {
    if (entityId) {
      if (!pcrData) throw new Error('fatal exception');
      dispatch({
        type: CURRENT_CFX_RUN_INIT,
        payload: {
          id: entityId,
          run: pcrData
        }
      });
    } else {
      dispatch({
        type: CURRENT_CFX_RUN_INIT,
        payload: undefined
      });
    }
  };
}

export function initRunFromTemplate(template: Record<string, any>, runName: string) {
  return (dispatch: Dispatch<CurrentCfxRunActionType, Record<string, any> | void>) => {
    const { samplePlaceholders, ...run } = template;
    dispatch({
      type: CURRENT_CFX_RUN_INIT,
      payload: {
        run: { ...run, name: runName, type: FILETYPE_PENDING_CFX_RUN },
        samplePlaceholders
      }
    });
  };
}

export function clearCurrentCfxRun() {
  return (dispatch: Dispatch<CurrentCfxRunActionType, null>) => {
    dispatch({
      type: CURRENT_CFX_RUN_CLEAR,
      payload: null
    });
  };
}

export function setFileName(name: string) {
  return (dispatch: Dispatch<CurrentCfxRunActionType, { name: string; error?: string }>) => {
    dispatch({
      type: CURRENT_CFX_RUN_SET_NAME,
      payload: {
        name,
        error: validateFileName(name)
      }
    });
  };
}

export function setRunNotes(notes: string, originalNotesLength: number) {
  return (dispatch: Dispatch<CurrentCfxRunActionType, { notes: string; error?: string }>) => {
    dispatch({
      type: CURRENT_CFX_RUN_SET_NOTES,
      payload: {
        notes,
        error: validateRunNotes(notes, originalNotesLength)
      }
    });
  };
}

export function setRunBarcode(barcode: string) {
  return (dispatch: Dispatch<CurrentCfxRunActionType, { barcode: string; error?: string }>) => {
    dispatch({
      type: CURRENT_CFX_RUN_SET_PLATE_ID,
      payload: {
        barcode,
        error: validateRunBarcode(barcode)
      }
    });
  };
}

export function setPlate(scanMode: string, plateSize: number, plateType: string) {
  return (dispatch: Dispatch<CurrentCfxRunActionType, { plate: Map<string, any> }>) => {
    const typeForSize = plateSize === 384 ? 'BR White' : plateType;
    dispatch({
      type: CURRENT_CFX_RUN_SET_PLATE,
      payload: {
        // @ts-ignore
        plate: getDefaultPlate(plateSize, scanMode, typeForSize)
      }
    });
  };
}

export function setPlateType(plateType: string) {
  return (dispatch: Dispatch<CurrentCfxRunActionType, { type: string }>) => {
    dispatch({
      type: CURRENT_CFX_RUN_SET_PLATE_TYPE,
      payload: {
        type: plateType
      }
    });
  };
}

export function setProtocolIsUpdating(updating: boolean) {
  return (
    dispatch: Dispatch<
      CurrentCfxRunActionType,
      {
        [key: string]: any;
      }
    >
  ) => {
    dispatch({
      type: CURRENT_CFX_RUN_PROTOCOL_IS_UPDATING,
      payload: { updating }
    });
  };
}

export function updateProtocol(
  protocol: Map<string, any>,
  entity: Map<string, any> | null,
  protocolName: string | null | undefined
) {
  return (
    dispatch: Dispatch<
      CurrentCfxRunActionType,
      {
        [key: string]: any;
      }
    >
  ) => {
    dispatch({
      type: CURRENT_CFX_RUN_PROTOCOL_UPDATE,
      payload: { protocol, entity, protocolName }
    });
    dispatch(setProtocolIsUpdating(false));
  };
}

export function updatePlate(plate: Map<string, any>, plateErrors: Map<string, any>) {
  return (
    dispatch: Dispatch<
      CurrentCfxRunActionType,
      { plate: Map<string, any>; plateErrors: Map<string, any> }
    >
  ) => {
    dispatch({
      type: CURRENT_CFX_RUN_SET_PLATE,
      payload: { plate, plateErrors }
    });
  };
}

export function replacePlate(plate: Map<string, any>) {
  return (dispatch: Dispatch<CurrentCfxRunActionType, { plate: Map<string, any> }>) => {
    dispatch({
      type: CURRENT_CFX_RUN_REPLACE_PLATE,
      payload: { plate }
    });
  };
}

export function setResultsWellsToHide(wellsToHide: Set<string>) {
  return (
    dispatch: Dispatch<
      CurrentCfxRunActionType,
      {
        [key: string]: any;
      }
    >
  ) => {
    dispatch({
      type: CURRENT_CFX_RUN_SET_WELLS_TO_HIDE,
      payload: { wellsToHide }
    });
  };
}

export function setSelectedStep(step: number) {
  return (
    dispatch: Dispatch<
      CurrentCfxRunActionType,
      {
        [key: string]: any;
      }
    >
  ) => {
    dispatch({
      type: CURRENT_CFX_RUN_SET_SELECTED_STEP,
      payload: { step }
    });
  };
}

export function setAnalysisMode(mode: string) {
  return (
    dispatch: Dispatch<
      CurrentCfxRunActionType,
      {
        [key: string]: any;
      }
    >
  ) => {
    dispatch({
      type: CURRENT_CFX_RUN_SET_ANALYSIS_MODE,
      payload: { mode }
    });
  };
}

export function setMeltMode(mode: string) {
  return (dispatch: Dispatch<CurrentCfxRunActionType, string>) => {
    dispatch({
      type: CURRENT_CFX_RUN_SET_MELT_MODE,
      payload: mode
    });
  };
}

export function setTargetsToShow(targetsToShow: Set<string>, targetsInRun: Set<string>) {
  return (
    dispatch: Dispatch<
      CurrentCfxRunActionType,
      {
        [key: string]: any;
      }
    >
  ) => {
    dispatch({
      type: CURRENT_CFX_RUN_SET_HIDE_TARGETS,
      payload: {
        targetsToHide: targetsInRun.subtract(targetsToShow).toSet()
      }
    });
  };
}

export function setFluorsToShow(fluorsToShow: Set<string>, fluorsInRun: Set<string>) {
  return (
    dispatch: Dispatch<
      CurrentCfxRunActionType,
      {
        [key: string]: any;
      }
    >
  ) => {
    dispatch({
      type: CURRENT_CFX_RUN_SET_HIDE_FLUORS,
      payload: {
        fluorsToHide: fluorsInRun.subtract(fluorsToShow).toSet()
      }
    });
  };
}

export function setBaselineSubtraction(baselineSubtraction: boolean) {
  return (
    dispatch: Dispatch<
      CurrentCfxRunActionType,
      {
        [key: string]: any;
      }
    >
  ) => {
    dispatch({
      type: CURRENT_CFX_RUN_BASELINE_SUBTRACTION,
      payload: { baselineSubtraction }
    });
  };
}

export function setDriftCorrection(driftCorrection: boolean) {
  return (
    dispatch: Dispatch<
      CurrentCfxRunActionType,
      {
        [key: string]: any;
      }
    >
  ) => {
    dispatch({
      type: CURRENT_CFX_RUN_DRIFT_CORRECTION,
      payload: { driftCorrection }
    });
  };
}

export function clearStepError(entityId: string, step: number, groupMode: string) {
  return (
    dispatch: Dispatch<
      CurrentCfxRunActionType,
      {
        [key: string]: any;
      }
    >
  ) => {
    dispatch({
      type: CURRENT_CFX_RUN_CLEAR_STEP_ERROR,
      payload: { id: entityId, step, groupMode }
    });
  };
}

export function setAmpChartScaleType(scaleType: string) {
  return (
    dispatch: Dispatch<
      CurrentCfxRunActionType,
      {
        [key: string]: any;
      }
    >
  ) => {
    dispatch({
      type: CURRENT_CFX_RUN_AMPCHART_SCALE_TYPE,
      payload: { scaleType }
    });
  };
}

export function updatePlateTargets(newTarget: string, oldTarget: string) {
  return (
    dispatch: Dispatch<
      CurrentCfxRunActionType,
      {
        [key: string]: any;
      }
    >
  ) => {
    dispatch({
      type: CURRENT_CFX_RUN_UPDATE_PLATE_TARGETS,
      payload: { newTarget, oldTarget }
    });
  };
}

export function updateCqDataSortOrder(sortOrder: OrderedMap<string, any>) {
  return (
    dispatch: Dispatch<
      CurrentCfxRunActionType,
      {
        [key: string]: any;
      }
    >
  ) => {
    dispatch({
      type: CURRENT_CFX_RUN_CQ_SORT_ORDER,
      payload: { sortOrder }
    });
  };
}

export function showPlateSetup(showPlate: boolean) {
  return (
    dispatch: Dispatch<
      CurrentCfxRunActionType,
      {
        [key: string]: any;
      }
    >
  ) => {
    dispatch({
      type: CURRENT_CFX_RUN_SHOW_PLATE_SETUP,
      payload: { showPlate }
    });
  };
}

export function updateThresholdSettings(
  step: string,
  analysisMode: string,
  updatedCustomThresholds: Map<string, any>,
  updatedAutoThresholdSettings: Map<string, any>
) {
  return (
    dispatch: Dispatch<
      CurrentCfxRunActionType,
      {
        [key: string]: any;
      }
    >
  ) => {
    dispatch({
      type: CURRENT_CFX_RUN_UPDATE_THRESHOLD_SETTINGS,
      payload: {
        step,
        analysisMode,
        updatedCustomThresholds,
        updatedAutoThresholdSettings
      }
    });
  };
}

export function updateCycleRangeSettings(
  step: string,
  analysisMode: AnalysisModeEnum,
  customChanges: Map<string, any>
) {
  return (
    dispatch: Dispatch<
      CurrentCfxRunActionType,
      {
        [key: string]: any;
      }
    >
  ) => {
    dispatch({
      type: CURRENT_CFX_RUN_UPDATE_CYCLE_RANGE_SETTINGS,
      payload: {
        step,
        analysisMode,
        customChanges
      }
    });
  };
}

export const updateAmpSelection =
  () =>
  (
    dispatch: Dispatch<
      CurrentCfxRunActionType,
      {
        [key: string]: any;
      }
    >
  ) => {
    dispatch({
      type: CURRENT_CFX_RUN_UPDATE_AMP_SELECTION,
      payload: {}
    });
  };

export const updateMeltSelection =
  () =>
  (
    dispatch: Dispatch<
      CurrentCfxRunActionType,
      {
        [key: string]: any;
      }
    >
  ) => {
    dispatch({
      type: CURRENT_CFX_RUN_UPDATE_MELT_SELECTION,
      payload: {}
    });
  };

export function formatRunLogForDisplay(
  logsEntries:
    | Array<{
        [key: string]: any;
      }>
    | null
    | undefined
):
  | Array<{
      [key: string]: any;
    }>
  | null
  | undefined {
  if (logsEntries == null) return logsEntries;
  for (let i = 0; i < logsEntries.length; i += 1) {
    const logEntry = logsEntries[i];
    logEntry.ts = formatRunLogTime(logEntry.ts);

    // truncate long messages and add ellipsis
    const maxMsgLengh = 32768;
    if (logEntry.msg != null && logEntry.msg.length > maxMsgLengh)
      logEntry.msg = `${logEntry.msg.substring(0, maxMsgLengh)}...`;
  }
  return logsEntries;
}

export async function fetchRunLog(entityId: string) {
  let logsEntries = null;
  try {
    const logRes = await getRunLogReq(entityId);
    const { url } = logRes.data;
    const logData = await getUrlAsJson(url);
    logsEntries = logData.logsEntries;
  } catch (ex) {
    logsEntries = null;
  }
  return formatRunLogForDisplay(logsEntries);
}

export function showRunLog(
  entityId: string,
  logEntries:
    | Array<{
        [key: string]: any;
      }>
    | null
    | undefined
) {
  return async (dispatch: Dispatch<CurrentCfxRunActionType, any>) => {
    dispatch({
      type: CURRENT_CFX_RUN_SHOW_LOG,
      payload: undefined
    });
    let entries = logEntries;
    if (entries == null) {
      entries = await fetchRunLog(entityId);
    }
    dispatch({
      type: CURRENT_CFX_RUN_LOG_LOADED,
      payload: fromJS(entries)
    });
  };
}

export function hideRunLog() {
  return (dispatch: Dispatch<CurrentCfxRunActionType, any>) => {
    dispatch({
      type: CURRENT_CFX_RUN_HIDE_LOG,
      payload: undefined
    });
  };
}
