import AnonymizedString from '../../utils/AnonymizedString';
import EmailAddress from '../../common/EmailAddress';
import { NullableString } from '../../common/nullable-types';

export default class LaunchDarklyUser {
  constructor(email: NullableString) {
    if (email) {
      const passcode = LaunchDarklyUser.getPasscode(email);
      const { domain } = new EmailAddress(email);
      const anonymizedEmail = LaunchDarklyUser.getAnonymizedEmail(email);

      this._user = {
        key: anonymizedEmail,
        custom: {
          passcode,
          domain
        }
      };
    } else {
      this._user = {
        anonymous: true,
        key: 'anonymous'
      };
    }
  }

  _user: {
    [key: string]: any;
  };

  static getPasscode(email: string): NullableString {
    const results = email.match(/\+([^@-]*)/);
    return results ? results[1] : null;
  }

  static getAnonymizedEmail(email: string): NullableString {
    return new AnonymizedString(email).toString();
  }

  toJSON() {
    return this._user;
  }
}
