import Reservation, { ReservationShadowState } from './Reservation';
import Job from './Job';
import {
  DesiredBlock,
  IncomingMessage,
  Metadata,
  ReportedBlock,
  ReservationSection
} from './iot/incomingMessage';
import { TopicStub } from '../../frontend-common-libs/src/iot/InstrumentNamedShadow';

type ComponentCallback = (fleetInstrument: InstrumentReservation) => void;
type ReservationCallback = (topic: string, payload: unknown) => void;

export default class InstrumentReservation {
  public readonly userId: string;

  private readonly _instrumentId: string;

  private readonly componentCallback: ComponentCallback;

  private readonly reservation: Reservation;

  public reservationShadowState: ReservationShadowState | null = null;

  private desiredJob?: Job | null = undefined;

  private reportedJob?: Job | null = undefined;

  private _desiredProtocolName?: string | null = undefined;

  private _reportedProtocolName?: string | null = undefined;

  private _isLoaded = false;

  private _desiredJobIdTimestampStale = false;

  public constructor(userId: string, instrumentId: string, componentCallback: ComponentCallback) {
    this.userId = userId;
    this._instrumentId = instrumentId;
    this.componentCallback = componentCallback;
    this.reservation = new Reservation();
  }

  public async connect(): Promise<void> {
    try {
      const reservationCallback = this.createReservationCallback();
      await this.reservation.connect(this._instrumentId, reservationCallback);

      this.reservationShadowState = await Reservation.getCurrentReservation(this._instrumentId);
      if (this.isReservedByUser()) {
        const getProtocolNamePromises: Promise<void>[] = [];
        if (this.reservationShadowState?.desired?.jobId) {
          getProtocolNamePromises.push(
            this.updateDesiredJob(this.reservationShadowState.desired.jobId)
          );
        }
        if (this.reservationShadowState?.jobId) {
          getProtocolNamePromises.push(this.updateReportedJob(this.reservationShadowState.jobId));
        }
        if (getProtocolNamePromises.length > 0) {
          await Promise.all(getProtocolNamePromises);
        }
      }

      if (!this.reservationShadowState.desired) {
        this.reservationShadowState.desired = { metadata: {} };
      }

      this._isLoaded = true;
    } catch {
      console.error(`Failed to get reservation for instrument ${this._instrumentId}`);
    }
  }

  public async disconnect(topicStub: TopicStub): Promise<void> {
    await this.reservation.disconnect(this._instrumentId, topicStub);
    this._isLoaded = false;
  }

  public get isLoaded(): boolean {
    return this._isLoaded;
  }

  public async reserve(job?: { jobId: string }): Promise<void> {
    await this.reservation.create(this.instrumentId, this.userId, job ?? undefined);
  }

  public async unreserve(): Promise<void> {
    await this.reservation.unreserve(this.instrumentId);
  }

  public async assignProtocol(entityId: string, protocolName: string): Promise<void> {
    this._desiredProtocolName = protocolName;
    this._desiredJobIdTimestampStale = true;
    try {
      this.desiredJob = await Job.create(this.instrumentId, this.userId, entityId);
      // @ts-ignore possibly undefined
      await this.reservation.addJob(this.instrumentId, this.desiredJob?.jobId);
    } catch (error) {
      throw new Error(`Failed to create job for instrument ${this.instrumentId}`);
    }
  }

  public async unassignProtocol(): Promise<void> {
    if (this.desiredJob || this.reportedJob) {
      try {
        await this.reservation.removeJob(this.instrumentId);
      } catch (error) {
        throw new Error(`Failed to unassign protocol for instrument ${this.instrumentId}`);
      }
    }
  }

  public async rollbackAssignProtocol(): Promise<void> {
    if (this.reportedJob) {
      const { jobId } = this.reportedJob;
      if (jobId != null) {
        try {
          await this.reservation.addJob(this.instrumentId, jobId);
        } catch (error) {
          throw new Error(`Failed to reassign reported job for instrument ${this.instrumentId}`);
        }
      }
    } else {
      await this.unassignProtocol();
    }
    const prevDesiredJob = this.desiredJob;
    this._desiredProtocolName = this._reportedProtocolName;
    this.desiredJob = this.reportedJob;
    try {
      await prevDesiredJob?.delete();
    } catch (error) {
      console.error(
        `Failed to delete job ${prevDesiredJob?.jobId} for instrument ${this.instrumentId}`
      );
    }
  }

  public async rollbackUnassignProtocol(): Promise<void> {
    if (this.reportedJob) {
      const { jobId } = this.reportedJob;
      if (jobId != null) {
        try {
          await this.reservation.addJob(this.instrumentId, jobId);
        } catch (error) {
          throw new Error(`Failed to reassign reported job for instrument ${this.instrumentId}`);
        }
      }
    }
    this._desiredProtocolName = this._reportedProtocolName;
    this.desiredJob = this.reportedJob;
  }

  public get instrumentId(): string {
    return this._instrumentId;
  }

  private async removePreviousJobInDB(previousJobId: string): Promise<void> {
    try {
      const previousJob = new Job(this.userId, previousJobId);
      await previousJob.delete();
    } catch (error) {
      console.error(`Failed to delete job ${previousJobId} for user ${this.userId}`);
    }
  }

  private createReservationCallback(): ReservationCallback {
    return (topic: string, payload: unknown) => {
      const payloadJson: IncomingMessage = JSON.parse((payload as Buffer).toString());
      if (topic.includes(`/accepted`)) {
        this.updateShadowStateWithIncomingMessage(payloadJson);
        this.componentCallback(this);
      }
    };
  }

  private static getJobIdIfExists(job: { jobId: string }): string | null {
    return job?.jobId ?? null;
  }

  private async updateReportedJob(jobId: string): Promise<void> {
    this.reportedJob = new Job(this.userId, jobId);
    await this.getReportedProtocolName();
  }

  private async deleteReportedJob(): Promise<void> {
    let prevJob;
    try {
      prevJob = this.reportedJob;
      this.reportedJob = null;
      this._reportedProtocolName = null;
      await prevJob?.delete();
    } catch (error) {
      console.error(`Failed to delete job ${prevJob?.jobId} for user ${this.userId}`);
    }
  }

  private updateReportedShadowState(reportedBlock: ReportedBlock): void {
    const reportedInstrumentBlock = reportedBlock.instrument;
    const reportedReservationBlock = reportedBlock.reservation;

    if (this.reservationShadowState && reportedReservationBlock) {
      const { userID, reserved, job } = reportedReservationBlock;
      if (userID) {
        this.reservationShadowState.userId = userID;
      }
      if (reserved !== undefined) {
        this.reservationShadowState.reserved = reserved;
      }
      if (this.isReservedByUser() && job !== undefined) {
        const previousJobId = this.reservationShadowState.jobId;

        this.reservationShadowState.jobId = InstrumentReservation.getJobIdIfExists(job);
        const { jobId } = this.reservationShadowState;

        if (jobId === null) {
          this.deleteReportedJob();
        } else {
          this.deletePreviousJobIfNeeded(previousJobId, jobId);

          this.updateReportedJob(jobId);
        }
      }
    }
    if (this.reservationShadowState && reportedInstrumentBlock?.loggedIn !== undefined) {
      this.reservationShadowState.loggedIn = reportedInstrumentBlock.loggedIn;
    }
  }

  private deletePreviousJobIfNeeded(previousJobId: string | null, newJobId: string | null): void {
    if (previousJobId && previousJobId !== newJobId) {
      this.removePreviousJobInDB(previousJobId).then();
    }
  }

  private async updateDesiredJob(jobId: string): Promise<void> {
    this.desiredJob = new Job(this.userId, jobId);
    await this.getDesiredProtocolName();
  }

  private deleteDesiredJob(): void {
    this.desiredJob = null;
    this._desiredProtocolName = null;
  }

  private updateDesiredShadowState(desiredBlock: DesiredBlock, metadata: Metadata): void {
    const desiredReservationBlock = desiredBlock.reservation;
    const metadataDesiredReservationBlock = metadata.desired.reservation;

    if (
      this.reservationShadowState &&
      this.reservationShadowState.desired &&
      desiredReservationBlock
    ) {
      const { userID, reserved } = desiredReservationBlock;
      if (userID !== undefined) {
        this.reservationShadowState.desired.userId = userID;
        this.reservationShadowState.desired.metadata.userId =
          metadataDesiredReservationBlock?.userID.timestamp;
      }
      if (reserved !== undefined) {
        this.reservationShadowState.desired.reserved = reserved;
        this.reservationShadowState.desired.metadata.reserved =
          metadataDesiredReservationBlock?.reserved.timestamp;
        if (!reserved) {
          this._desiredJobIdTimestampStale = true;
          this.deleteDesiredJob();
        }
      }
      this.processDesiredJob(desiredReservationBlock, metadata);
    }
  }

  private processDesiredJob(desiredReservationBlock: ReservationSection, metadata: Metadata) {
    const { job } = desiredReservationBlock;
    if (
      this.isReservedByUser() &&
      job !== undefined &&
      this.reservationShadowState &&
      this.reservationShadowState.desired
    ) {
      const metadataDesiredReservationBlock = metadata.desired.reservation;
      this.reservationShadowState.desired.jobId = InstrumentReservation.getJobIdIfExists(job);
      const { jobId } = this.reservationShadowState.desired;

      if (jobId === null) {
        this.reservationShadowState.desired.metadata.job =
          metadataDesiredReservationBlock?.job?.timestamp;
        this.deleteDesiredJob();
      } else {
        this.reservationShadowState.desired.metadata.jobId =
          metadataDesiredReservationBlock?.job?.jobId?.timestamp;
        this._desiredJobIdTimestampStale = false;
        this.updateDesiredJob(jobId);
      }
    }
  }

  private updateShadowStateWithIncomingMessage(incomingMessage: IncomingMessage): void {
    const reportedBlock = incomingMessage.state?.reported;
    const desiredBlock = incomingMessage.state?.desired;
    const { metadata } = incomingMessage;

    if (reportedBlock) {
      this.updateReportedShadowState(reportedBlock);
    }
    if (desiredBlock) {
      this.updateDesiredShadowState(desiredBlock, metadata);
    }
  }

  public isReservedByUser(): boolean {
    return (
      this.reservationShadowState?.reserved === true &&
      this.reservationShadowState?.userId === this.userId
    );
  }

  public isReservationPending(): boolean {
    const isNotReserved = this.reservationShadowState?.reserved === false;
    const userWantsToReserveIt =
      this.reservationShadowState?.desired?.userId === this.userId &&
      this.reservationShadowState?.desired?.reserved === true;
    return isNotReserved && userWantsToReserveIt;
  }

  public get desiredJobId(): string | null | undefined {
    return this.desiredJob === null ? null : this.desiredJob?.jobId;
  }

  public get reportedJobId(): string | null | undefined {
    return this.reportedJob === null ? null : this.reportedJob?.jobId;
  }

  public async getDesiredProtocolName(): Promise<string | null> {
    if (this.desiredJob) {
      try {
        this._desiredProtocolName = await this.desiredJob?.getProtocolName();
      } catch (e) {
        console.error(`Failed to get desired protocol name for job ${this.desiredJob?.jobId}`);
      }
    } else {
      this._desiredProtocolName = null;
    }
    return this._desiredProtocolName ?? null;
  }

  public async getReportedProtocolName(): Promise<string | null> {
    if (this.reportedJob?.jobId) {
      try {
        this._reportedProtocolName = await this.reportedJob?.getProtocolName();
      } catch (e) {
        console.error(`Failed to get reported protocol name for job ${this.reportedJob?.jobId}`);
      }
    } else {
      this._reportedProtocolName = null;
    }
    return this._reportedProtocolName ?? null;
  }

  public get desiredJobIdTimestampStale(): boolean {
    return this._desiredJobIdTimestampStale;
  }

  public async startRun(): Promise<void> {
    await this.reservation.startReservationRun(this.instrumentId);
  }

  public async instrumentOpenLid(): Promise<void> {
    await this.reservation.openLid(this.instrumentId);
  }

  public async instrumentCloseLid(): Promise<void> {
    await this.reservation.closeLid(this.instrumentId);
  }

  public async stopRun(): Promise<void> {
    await this.reservation.stopReservationRun(this.instrumentId);
  }

  public async skipStep(): Promise<void> {
    await this.reservation.skipStep(this.instrumentId);
  }
}
