import React from 'react';
import { Project } from '../../frontend-common-libs/src/common/project-management-types';
import projectIcon from '../../../img/folder.svg';
import TenantRow from '../../organization-management/organizations/TenantRow';

export type Props = {
  project: Project;
  onClick: (project: Project) => void;
  isSelected?: boolean;
  isActive?: boolean;
};

export default function ProjectRow(props: Readonly<Props>) {
  const { project, isSelected, isActive, onClick } = props;
  return (
    <TenantRow
      onClick={onClick}
      isSelected={isSelected}
      isActive={isActive}
      tenant={project}
      tenantIcon={projectIcon}
    />
  );
}
