import ProtocolListRepository from '../protocol/repository/ProtocolListRepository';
import ProtocolRepository from '../protocol/repository/ProtocolRepository';
import LogoutEvent from '../../frontend-common-libs/src/system-event/LogoutEvent';
import {
  SystemEvent,
  SystemEventBroker,
  SystemEventSubscriber
} from '../../frontend-common-libs/src/system-event';
import RunRepository from '../completed-run/repository/RunRepository';

export default class CpcrMsgsSubscriber implements SystemEventSubscriber {
  // eslint-disable-next-line class-methods-use-this
  public notify(systemEvent: SystemEvent): void {
    if (LogoutEvent.isLogoutEvent(systemEvent)) {
      ProtocolListRepository.instance.clear();
      ProtocolRepository.instance.clear();
      RunRepository.instance.clear();
    }
  }

  public registerEvent() {
    SystemEventBroker.getInstance().subscribe(this);
  }
}
