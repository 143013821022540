import { fromJS, Map } from 'immutable';
import {
  COMPLETE_REGISTRATION_ERROR,
  COMPLETE_REGISTRATION_IN_PROGRESS,
  COMPLETE_REGISTRATION_SUCCESS,
  HIDE_VERIFIED_ACCOUNT_MESSAGE_ON_LOGIN,
  CompleteRegistrationActionType
} from '../actions/complete-registration/complete_registration_types';
import { ApiAction } from '../types';

// @ts-ignore
const INITIAL_STATE: Map<string, any> = fromJS({
  inProgress: false,
  errorMessage: undefined,
  succeeded: false,
  hideVerifiedAccountMessageOnLogin: false
});

const onCompleteRegistrationInProgress = (state: Map<string, any>) => {
  return state.set('inProgress', true);
};

const onCompleteRegistrationSuccess = (state: Map<string, any>) => {
  return state.set('succeeded', true).set('inProgress', false);
};

const onCompleteRegistrationError = (
  state: Map<string, any>,
  action: ApiAction<CompleteRegistrationActionType, any>
) => {
  return state.set('errorMessage', action.payload).set('inProgress', false);
};

const onHideVerifiedAccountMessageOnLogin = (state: Map<string, any>) => {
  return state.set('hideVerifiedAccountMessageOnLogin', true);
};

const actionMap = {
  [COMPLETE_REGISTRATION_IN_PROGRESS]: onCompleteRegistrationInProgress,
  [COMPLETE_REGISTRATION_SUCCESS]: onCompleteRegistrationSuccess,
  [COMPLETE_REGISTRATION_ERROR]: onCompleteRegistrationError,
  [HIDE_VERIFIED_ACCOUNT_MESSAGE_ON_LOGIN]: onHideVerifiedAccountMessageOnLogin
};

export default function completeRegistrationReducer(
  state: Map<string, any> = INITIAL_STATE,
  action: ApiAction<any, any> | undefined = undefined
): Map<string, any> {
  if (action) {
    return actionMap[action.type] ? actionMap[action.type](state, action) : state;
  }
  return state;
}
