import React from 'react';
import SettingsMenuItem from './SettingsMenuItem';
import styles from '../style/setting-page.module.scss';
import { MenuListItem } from './Settings';

export type Props = {
  menuItems: MenuListItem[];
  selectedMenuItem: string;
};

export default function SettingsNavBar({ selectedMenuItem, menuItems }: Props) {
  return (
    <div className={styles.settingNavbar} data-testid="setting-navbar">
      {menuItems.map((item: MenuListItem) => (
        <React.Fragment key={item.id}>
          <SettingsMenuItem
            text={item.title}
            id={item.id}
            isSelected={selectedMenuItem === item.id}
          />
        </React.Fragment>
      ))}
    </div>
  );
}
