import {
  InstrumentType,
  PluginComponentGetter,
  PluginComponents,
  PluginManifest,
  PluginName,
  PluginRootPath,
  RecentFileRowProps,
  FileRowProps
} from '@biorad-lsg-tsc/instrument-family-plugin';
import { ComponentType } from 'react';
import RemotePluginManifest from './RemotePluginManifest';
import RemotePluginComponents from './RemotePluginComponents';

export default abstract class BaseInstrumentFamilyPlugin {
  protected readonly manifest: PluginManifest | RemotePluginManifest;

  protected readonly components: PluginComponents | RemotePluginComponents;

  protected constructor(
    manifest: PluginManifest | RemotePluginManifest,
    components: PluginComponents | RemotePluginComponents
  ) {
    this.manifest = manifest;
    this.components = components;
  }

  public get basePath(): PluginRootPath {
    return this.manifest.basePath;
  }

  public get instrumentTypes(): InstrumentType[] {
    return this.manifest.instrumentTypes;
  }

  public get name(): PluginName {
    return this.manifest.name;
  }

  public getApp: PluginComponentGetter = () => {
    return this.components.app;
  };

  public getInstrumentTile(): ComponentType<any> {
    return this.components.instrumentTile;
  }

  public getInstrumentRow(): ComponentType<any> {
    return this.components.instrumentRow;
  }

  public getFileRow(): ComponentType<FileRowProps> {
    return this.components.fileRow;
  }

  public getRecentFileRow(): ComponentType<RecentFileRowProps> {
    return this.components.recentFileRow;
  }

  public getArchiveRow(): ComponentType<any> {
    return this.components.archiveRow;
  }

  public getUserPreferenceDetail(): ComponentType<any> {
    return this.components.userPreferenceDetail;
  }

  public getPageTitle(): ComponentType<any> {
    return this.components.pageTitle;
  }

  public getHelpLink(): ComponentType<any> {
    return this.components.helpLink;
  }

  public getFleetManagement(): ComponentType<any> {
    return this.components.fleetManagement;
  }
}
