import { NullableString } from '../../../../frontend-common-libs/src/common/nullable-types';
import {
  validateRunBarcode,
  validateFileName,
  validateRunNotes
} from '../../../../frontend-common-libs/src/components/common/run/validations/run-validation';
import BaseViewModel from '../../../../frontend-common-libs/src/common/mvvm/BaseViewModel';

export default class RunEdits extends BaseViewModel {
  public fileName: string;

  originalFileName: string;

  fileNameError: NullableString;

  runBarcodeError: NullableString;

  runBarcode: string;

  originalRunBarcode: string;

  originalNotes: string;

  runNotesError: NullableString;

  runNotes: string;

  constructor(fileName: string, barcode: string, notes: NullableString) {
    super();
    this.fileName = fileName;
    this.originalFileName = fileName;
    this.fileNameError = null;
    this.runBarcode = barcode;
    this.originalRunBarcode = barcode;
    this.runBarcodeError = null;
    const notesToUse = notes != null ? notes : '';
    this.originalNotes = notesToUse;
    this.runNotes = notesToUse;
    this.runNotesError = null;
  }

  setFileName = (name: string) => {
    this.fileName = name;
    this.fileNameError = validateFileName(name);
    this.fireStateChanged();
  };

  setBarcode = (barcode: string) => {
    this.runBarcode = barcode;
    this.runBarcodeError = validateRunBarcode(barcode);
    this.fireStateChanged();
  };

  setNotes = (notes: string) => {
    this.runNotes = notes;
    this.runNotesError = validateRunNotes(notes);
    this.fireStateChanged();
  };

  runSaved = () => {
    this.originalFileName = this.fileName;
    this.originalRunBarcode = this.runBarcode;
    this.originalNotes = this.runNotes;
    this.fireStateChanged();
  };

  hasErrors = () => {
    return this.fileNameError || this.runBarcodeError || this.runNotesError;
  };

  hasEdits = () => {
    return (
      this.fileName !== this.originalFileName ||
      this.runBarcode !== this.originalRunBarcode ||
      this.runNotes !== this.originalNotes
    );
  };

  isSaveDisable = () => {
    if (this.hasErrors() || !this.hasEdits()) return true;
    return false;
  };
}
