import React from 'react';
import RadioButtonGroup, {
  OptionProps
} from '../../../../common/radio_button_group/RadioButtonGroup';
import './styles/gradient-calculator-options.scss';
import { replaceWhitespace } from '../../../../../common/strings';

type OptionPropsWithoutIds = Omit<OptionProps, 'id'>;

export type Props = {
  onChange: (e: React.SyntheticEvent<HTMLInputElement>) => void;
  checkedValue: string;
  options: OptionPropsWithoutIds[];
};

export default function GradientCalculatorOptions({ onChange, checkedValue, options }: Props) {
  const name = 'plate-type';

  const optionsWithIds: OptionProps[] = options.map(option => {
    const id = replaceWhitespace(`${name}-${option.value}`);
    return { ...option, id };
  });

  return (
    <div className="plate-size-selection">
      <RadioButtonGroup
        name={name}
        onChange={onChange}
        checkedValue={checkedValue}
        options={optionsWithIds}
      />
    </div>
  );
}
