import React, { ReactElement } from 'react';
import {
  formatId,
  Select,
  SelectMenuItem
} from '../../../frontend-common-libs/src/components/common/dropdown';
import {
  Project,
  ProjectId
} from '../../../frontend-common-libs/src/common/project-management-types';

export type HandleProjectSelection = (selectedProjectId: ProjectId) => void;

export type ProjectDropdownProps = {
  onSelection: HandleProjectSelection;
  projects: Project[];
  selectedProjectId: ProjectId;
  disabled: boolean;
};

function ProjectDropdown({
  projects,
  selectedProjectId,
  onSelection,
  disabled
}: Readonly<ProjectDropdownProps>): ReactElement {
  const dropdownId = 'project-dropdown';
  const selectedProject = projects.find(project => project.id === selectedProjectId);
  if (selectedProject === undefined) {
    throw new Error('The selected project could not be found.');
  }
  return (
    <div title={selectedProject.name} id="selected-project-name">
      <Select
        id={dropdownId}
        value={selectedProject.name}
        handleSelection={onSelection}
        disabled={disabled}
      >
        {projects.map(({ name: projectName, id: projectId }: Project) => {
          const formattedId = formatId(dropdownId, projectId);
          return (
            <SelectMenuItem
              id={formattedId}
              key={formattedId}
              value={projectId}
              selected={projectId === selectedProjectId}
              title={projectName}
            >
              {projectName}
            </SelectMenuItem>
          );
        })}
      </Select>
    </div>
  );
}

export default ProjectDropdown;
