import React from 'react';
import classNames from 'classnames';
import { MenuItem } from './Dropdown';

export type SelectMenuItemProps = {
  children: any;
  value: any;
  id?: string;
  selected?: boolean;
  menuItemRef?: any;
  onClick?: (event: React.SyntheticEvent<HTMLButtonElement>) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLButtonElement>) => void;
  title?: string;
};

export default class SelectMenuItem extends React.Component<SelectMenuItemProps> {
  static defaultProps = {
    id: undefined,
    selected: undefined,
    menuItemRef: undefined,
    onClick: undefined,
    onKeyDown: undefined
  };

  render() {
    const { props } = this;

    const { children, value, id, selected, menuItemRef, onClick, onKeyDown, title } = props;
    let actionProps = {};
    if (onClick) {
      actionProps = { ...actionProps, onClick };
    }
    if (onKeyDown) {
      actionProps = { ...actionProps, onKeyDown };
    }

    return (
      <MenuItem
        className={classNames('br-select-menu-item', { selected })}
        id={id}
        key={id}
        value={value}
        ref={menuItemRef}
        title={title}
        {...actionProps}
      >
        {children}
      </MenuItem>
    );
  }
}
