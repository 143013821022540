import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { VerticalIconButton } from '../../../frontend-common-libs/src/components/common/buttons';
import quickSaveImg from '../../../../img/icons-save-run-black.svg';
import { isSaveInProgress as getIsSaveInProgress } from '../../../selectors/selectors';
import { ReduxState } from '../../../types';

type Props = {
  id: string;
  handleClick: (arg0: string) => Promise<void>;
  isLoading: boolean;
};

export class QuickSaveImpl extends PureComponent<Props> {
  handleClickWrapper = () => {
    const { handleClick } = this.props;
    handleClick('quickSave');
  };

  render() {
    const { id, handleClick, isLoading } = this.props;
    return (
      <VerticalIconButton
        className={classnames({ 'in-progress': isLoading })}
        id={id}
        iconSrc={quickSaveImg}
        alt="quick save button"
        onClick={this.handleClickWrapper}
        disabled={!handleClick || isLoading}
      >
        Quick Save
      </VerticalIconButton>
    );
  }
}

const mapStateToProps = (state: ReduxState) => {
  const isLoading = getIsSaveInProgress(state);
  return { isLoading };
};

export default connect(mapStateToProps)(QuickSaveImpl);
