import request from '../frontend-common-libs/src/utils/httpUtils';
import getEnvironmentConfig from '../frontend-common-libs/src/config/EnvironmentConfiguration';

const API_DOMAIN = getEnvironmentConfig().apiDomain;
const DOMAIN = `https://system-status.${API_DOMAIN}`;
const CORE_STATUS_PATH = '/api/system-status/core/';

export default function getCoreStatus(): Promise<any> {
  return request.unauthGet(`${DOMAIN}${CORE_STATUS_PATH}`);
}
