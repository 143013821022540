import axios, { CancelTokenSource, AxiosError } from 'axios';

const cancelTokens: { [key: number]: CancelTokenSource } = {};

async function uploadFile(
  url: string,
  headers: {
    [key: string]: any;
  },
  file: File,
  id: number,
  retryCount = 0
): Promise<any> {
  const cancelSource = axios.CancelToken.source();

  const options = {
    headers,
    cancelToken: cancelSource.token
  };

  cancelTokens[id] = cancelSource;

  const maxRetryCount = 1;
  try {
    const res = await axios.put(url, file, options);
    delete cancelTokens[id];
    return res;
  } catch (e: unknown) {
    if (axios.isAxiosError(e)) {
      const error = e as AxiosError;
      const errorResponse = error.response;

      if (errorResponse) {
        const errorStatusCode = errorResponse.status;
        const isRetryableError =
          errorStatusCode >= 500 && errorStatusCode <= 599 && retryCount < maxRetryCount;
        if (isRetryableError) {
          return uploadFile(url, headers, file, id, retryCount + 1);
        }
      }
    }

    delete cancelTokens[id];
    throw e;
  }
}

export default uploadFile;

export const browserDownloadFile = (fileName: string, url: string) => {
  const a = document.createElement('a');
  a.href = url;
  a.download = fileName;
  a.click();
};

export const browserDownloadFileFromText = (fileName: string, txt: string) => {
  const file = new Blob([txt], { type: 'text/plain' });
  const url = URL.createObjectURL(file);
  browserDownloadFile(fileName, url);
};

export function cancelUpload(id: number) {
  const cancelSource = cancelTokens[id];
  if (cancelSource) {
    cancelSource.cancel('Cancelled');
  }
}

export function cancelAllUploads() {
  Object.keys(cancelTokens).forEach(id => {
    cancelUpload(parseInt(id, 10));
  });
}
