import React, { ReactElement } from 'react';
import styles from './styles/ContextBar.module.scss';
import FileBreadcrumb from './FileBreadcrumb';

type ContextBarProps = {
  id: string;
  title: string;
  titleId: string;
  children?: ReactElement | null;
  projectName?: string;
};

function ContextBar(props: ContextBarProps) {
  const { children, id, title, titleId, projectName } = props;

  return (
    <div className={styles.contextBar} id={id}>
      <FileBreadcrumb id={titleId} filePath={title} projectName={projectName} />
      {children && <div className={styles.contextBarEvenly}>{children}</div>}
    </div>
  );
}

export { ContextBar, ContextBarProps };
