import React, { Component } from 'react';
import { Alert } from 'react-bootstrap';
import Loader from '../../frontend-common-libs/src/components/common/Loader';
import { errorCode, commonErrorMessage } from '../../frontend-common-libs/src/utils/errorUtils';

export type Props = {
  /** the name to be used for error messages.
   * when the component gets new props, it uses
   * displayName to determine if new data should
   * be loaded */
  displayName: string;

  /** function called on componentDidMount or on componentDidUpdate */
  getData: () => Promise<void> | void;

  /** if data needs to be loaded */
  shouldLoad: boolean;
  children:
    | number
    | string
    // @ts-ignore
    | React$Element<any>
    | Array<any>
    | null
    | ((...args: Array<any>) => any);
};

type State = {
  error?: string;
};

function Error({ error }: { error: string }) {
  return (
    <div className="container margin-top">
      <Alert id="data-error" bsStyle="danger">
        {error}
      </Alert>
    </div>
  );
}

class DeprecatedDataLoader extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps: Props) {
    const { displayName, shouldLoad } = this.props;
    if (displayName !== prevProps.displayName || (shouldLoad && !prevProps.shouldLoad)) {
      this.getData();
    }
  }

  getData = async () => {
    const { getData, shouldLoad, displayName } = this.props;
    this.setState({ error: undefined });
    if (shouldLoad) {
      try {
        await getData();
      } catch (err) {
        const status = errorCode(err);
        // @ts-ignore
        let errorMsg = err.message;
        if (status) {
          errorMsg = commonErrorMessage(status, displayName);
        }
        errorMsg = errorMsg || 'Load failed. Check your network connection.';
        this.setState({ error: errorMsg });
      }
    }
  };

  render() {
    const { error } = this.state;
    if (error) {
      return <Error error={error} />;
    }
    const { children, shouldLoad } = this.props;
    if (shouldLoad) {
      return <Loader />;
    }
    return typeof children === 'function' ? children() : children;
  }
}

export default DeprecatedDataLoader;
