import ModalDialogViewModel from '../../../frontend-common-libs/src/components/common/dialogs/ModalDialogViewModel';
import { UserFile } from '../../../frontend-common-libs/src/common/types';
import {
  ProjectWithRole,
  USER_FILES
} from '../../../frontend-common-libs/src/common/project-management-types';

export default class MoveFileToProjectVm extends ModalDialogViewModel {
  protected _projects: ProjectWithRole[] = [];

  public get projects() {
    return this._projects;
  }

  public set projects(projects: ProjectWithRole[]) {
    this.notifyIfChanged('_projects', projects);
  }

  protected _selectedProjectId: string | undefined = undefined;

  public get selectedProjectId() {
    return this._selectedProjectId;
  }

  public set selectedProjectId(projectId: string | undefined) {
    this.notifyIfChanged('_selectedProjectId', projectId);
  }

  public findProject(projectId: string | undefined) {
    return this.projects.find(p => p.id === projectId);
  }

  public canMove(): boolean {
    const project = this.findProject(this.selectedProjectId);
    if (!project) return false;
    return project.canEditProjectFiles;
  }

  public getWarningMessage(): string | undefined {
    const project = this.findProject(this.selectedProjectId);
    if (!project || project.isTokenLoading || !project.projectRole) return undefined;
    if (!project.canEditProjectFiles)
      return 'You are assigned as a Reviewer for this project and are not authorized to move files.';
    return `After you move the file to a project, you cannot return it to ${USER_FILES}. Click Move to move the file or Cancel to close the dialog.`;
  }

  file!: UserFile;

  title = 'Move file to project';

  okBtnText = 'Move';

  protected readonly moveFileToProject: (entity: UserFile, projectId: string) => any;

  constructor(moveFileToProject: (entity: UserFile, projectId: string) => any) {
    super();
    this.moveFileToProject = moveFileToProject;
  }

  onMove = async () => {
    this.saveInProgress = true;
    await this.moveFileToProject(this.file, this.selectedProjectId as string);
    this.saveInProgress = false;
    this.hideDialog();
  };

  showMoveFileToProject(file: UserFile) {
    this.file = file;
    this.selectedProjectId = undefined;
    this.showDialog();
  }
}
