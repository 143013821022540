import {
  TenantUpdate,
  TenantUpdateEvent,
  RoleAssignedEvent,
  UserAddedEvent,
  TenantEditedEvent,
  UserRemovedEvent
} from '@biorad-lsg-tsc/organization';
import { DispatchType } from '../actions/action-types';
import { GetState, ReduxState } from '../../types';
import { dispatchOrgRoleChanged } from '../actions/set-org-role';
import { dispatchOrgUserAdded } from '../actions/add-user-to-org';
import { getUserEmail } from '../../selectors/auth-selectors';
import { dispatchOrgAdded } from '../actions/create-org';
import { dispatchOrgEdited } from '../actions/edit-org';
import { dispatchOrgUserRemoved } from '../actions/remove-user-from-org';

export default class OrganizationIotHandler {
  static onIotMessage(dispatch: DispatchType, getState: GetState, event: TenantUpdateEvent) {
    const state = getState();
    const { action } = event;
    if (action === TenantUpdate.roleAssigned) {
      OrganizationIotHandler.dispatchOrgRoleChanged(dispatch, state, event);
    } else if (action === TenantUpdate.userAdded) {
      OrganizationIotHandler.dispatchOrgUserAdded(dispatch, state, event);
    } else if (action === TenantUpdate.userRemoved) {
      OrganizationIotHandler.dispatchOrgUserRemoved(dispatch, getState, event);
    } else if (action === TenantUpdate.edited) {
      OrganizationIotHandler.dispatchOrgEdited(dispatch, event);
    }
  }

  static dispatchOrgRoleChanged(
    dispatch: DispatchType,
    state: ReduxState,
    event: RoleAssignedEvent
  ) {
    const { tenantId, userEmail, userRole } = event;
    dispatchOrgRoleChanged(dispatch, state, tenantId, userEmail, userRole);
  }

  static dispatchOrgUserAdded(dispatch: DispatchType, state: ReduxState, event: UserAddedEvent) {
    const { tenantId, userEmail, userRole, tenantName } = event;
    dispatchOrgUserAdded(dispatch, tenantId, userEmail, userRole);
    const email = getUserEmail(state);
    if (userEmail !== email) return;
    const org = { name: tenantName as string, orgId: tenantId };
    dispatchOrgAdded(dispatch, org);
  }

  static dispatchOrgEdited(dispatch: DispatchType, event: TenantEditedEvent) {
    const { tenantId, name, description } = event;
    dispatchOrgEdited(dispatch, tenantId, name, description);
  }

  static dispatchOrgUserRemoved(
    dispatch: DispatchType,
    getState: GetState,
    event: UserRemovedEvent
  ) {
    const { tenantId, userEmail } = event;
    dispatchOrgUserRemoved(dispatch, getState, tenantId, userEmail);
  }
}
