import React from 'react';
import { ExternalAnchorLink } from '../../frontend-common-libs/src/components/common/buttons';
import HelpCenter, { docHelpPaths } from '../../utils/HelpCenter';

const currentYear = new Date().getFullYear();

type Props = {
  className?: string;
};

function Footer({ className }: Props) {
  const helpCenter = new HelpCenter();
  const footerMsg = `Copyright © ${currentYear} Bio-Rad Laboratories, Inc.`;
  return (
    <div id="footer-container">
      <footer className={className}>
        <ul>
          <li id="copyright">{footerMsg}</li>
          <li>
            <ExternalAnchorLink
              id="footer-terms-of-use"
              className="terms-of-use"
              href={helpCenter.helpUrl(docHelpPaths.Terms_Of_Use)}
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms of Use
            </ExternalAnchorLink>
          </li>
        </ul>
      </footer>
    </div>
  );
}

Footer.defaultProps = {
  className: undefined
};

export default Footer;
