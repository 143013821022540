import { Dispatch } from '../../types';
import { ProjectId } from '../../frontend-common-libs/src/common/project-management-types';
import { PROJECT_SELECTED, ProjectManagementActionTypes } from './action-types';
import { ProjectRepository } from '../repository';

function projectSelectedAction(projectId: ProjectId) {
  return {
    type: PROJECT_SELECTED,
    payload: { projectId }
  };
}

export default function changeProjectSelection(projectId: ProjectId) {
  return async (dispatch: Dispatch<ProjectManagementActionTypes, any>) => {
    ProjectRepository.setStoredSelectedProjectId(projectId);
    dispatch(projectSelectedAction(projectId));
  };
}
