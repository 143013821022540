import * as React from 'react';
import { Map, Set } from 'immutable';
import {
  getAvailableFluors,
  fluorToChannelMapping,
  defaultWell
} from '../../../utils/microplateUtils';
import FluorophoreSelector from './FluorophoreSelector';
import { replaceOrderedMapEntry } from '../../../frontend-common-libs/src/utils/immutableUtils';

export type Props = {
  updateWells: (...args: Array<any>) => any;
  selectedWellIds: Set<string>;
  selectedWells: Map<string, any>;
  plateSize: number;
  scanMode: string;
  targetErrors?: Map<string, any>;
  concentrationErrors?: Map<string, any>;
  keywords?: Set<string>;
};

export default class FluorophoreList extends React.PureComponent<Props> {
  static defaultProps = {
    targetErrors: undefined,
    concentrationErrors: undefined,
    keywords: undefined
  };

  static fluorsOnly = (fluors: Map<string, any>) =>
    fluors.map(fluor => fluor.delete('target').delete('concentration'));

  getCurrentWellFluorsMap = () => {
    const { selectedWells } = this.props;
    if (selectedWells.size === 0) return Map();
    return selectedWells.first().get('fluors');
  };

  updateTarget = (fluor: string, target: string) => {
    const { selectedWells } = this.props;
    const channel = fluorToChannelMapping[fluor];
    if (target) {
      return selectedWells.map(well => well.setIn(['fluors', channel, 'target'], target));
    }
    return selectedWells.map(well => well.deleteIn(['fluors', channel, 'target']));
  };

  addFluor = (fluor: string) => {
    const { selectedWellIds, selectedWells } = this.props;
    const channel = fluorToChannelMapping[fluor];
    return selectedWellIds.reduce((map, wellId) => {
      let well = selectedWells.get(wellId) || defaultWell();
      if (well.get('fluors').size > 0) {
        // if there are fluors, use the first fluor entry so as to transfer the concentration if it exists
        well = well.setIn(
          ['fluors', channel],
          well.get('fluors').first().set('name', fluor).delete('target')
        );
      } else {
        well = well.setIn(['fluors', channel, 'name'], fluor);
      }
      return map.set(wellId, well);
    }, Map());
  };

  replaceFluor = (prevFluor: string, newFluor: string) => {
    const { selectedWells } = this.props;
    const prevChannel = fluorToChannelMapping[prevFluor];
    const newChannel = fluorToChannelMapping[newFluor];
    return selectedWells.map(well =>
      well.set(
        'fluors',
        replaceOrderedMapEntry(
          well.get('fluors'),
          prevChannel,
          newChannel,
          well.getIn(['fluors', prevChannel]).set('name', newFluor)
        )
      )
    );
  };

  handleInputChange = (previousFluor: string, newFluor: string, target: string) => {
    const { updateWells } = this.props;
    const fluorsChanged = previousFluor !== newFluor;
    let wells;
    if (fluorsChanged) {
      if (!previousFluor) {
        // adding new fluor
        wells = this.addFluor(newFluor);
      } else {
        // replace fluor
        wells = this.replaceFluor(previousFluor, newFluor);
      }
    } else {
      // set target
      wells = this.updateTarget(newFluor, target);
    }
    updateWells(wells, fluorsChanged);
  };

  updateConcentration = (selectedFluor: string, concentration?: number | string) => {
    // updating all at once for now
    const { selectedWells, updateWells } = this.props;
    const wells = selectedWells.map(well =>
      well.set(
        'fluors',
        well
          .get('fluors')
          .map((channel: Map<string, any>) => channel.set('concentration', concentration))
      )
    );
    updateWells(wells);
  };

  removeFluor = (fluor: string) => {
    const channel = fluorToChannelMapping[fluor];
    const { selectedWells, updateWells } = this.props;
    // delete can alter the order of entries in a Map
    // to keep the order of entries, convert to an OrderedMap
    // delete the fluor and convert back to a Map
    const wells = selectedWells.map(well =>
      well.set('fluors', well.get('fluors').toOrderedMap().delete(channel).toMap())
    );
    updateWells(wells);
  };

  clearFluors = () => {
    const { selectedWells, updateWells } = this.props;
    const wells = selectedWells.map(well => well.set('fluors', Map()));
    updateWells(wells, true);
  };

  fluorOptions = (availableChToFluorsMap: Map<string, any>, skipChannel?: string) => {
    let filteredChToFluorsMap = availableChToFluorsMap;
    this.getCurrentWellFluorsMap()
      .keySeq()
      .forEach((channel: string) => {
        if (channel !== skipChannel) filteredChToFluorsMap = filteredChToFluorsMap.delete(channel);
      });
    return filteredChToFluorsMap
      .toList() // get all values - List<List>
      .flatten(); // List
  };

  hasEmptyWellsInSelection = () => {
    const { selectedWellIds, selectedWells } = this.props;
    return selectedWellIds.size > selectedWells.size;
  };

  areAllFluorsEqual = () => {
    const { selectedWells } = this.props;
    if (this.hasEmptyWellsInSelection()) {
      // when there is a mix of empty and non-empty wells
      return selectedWells.every(well => well.get('fluors').size === 0);
    }
    if (selectedWells.size <= 1) return true;
    const fluorsOnlyHash = FluorophoreList.fluorsOnly(
      selectedWells.first().get('fluors')
    ).hashCode();
    return selectedWells.every(
      x => FluorophoreList.fluorsOnly(x.get('fluors')).hashCode() === fluorsOnlyHash
    );
  };

  areTargetsEqual = (channel: string, target: string) => {
    const { selectedWells } = this.props;
    return selectedWells.every(well => well.getIn(['fluors', channel, 'target']) === target);
  };

  showConcentration = () => {
    const { selectedWells } = this.props;
    return (
      !this.hasEmptyWellsInSelection() &&
      selectedWells.every(well => well.get('type') === 'standard')
    );
  };

  areConcentrationsEqual = (channel: string, concentration?: number) => {
    const { selectedWells } = this.props;
    return selectedWells.every(
      well => well.getIn(['fluors', channel, 'concentration']) === concentration
    );
  };

  renderUnequalFluors = () => (
    <div className="flex-item">
      <div>Multiple Fluors</div>
      <button id="clear-fluors" type="button" className="btn-secondary" onClick={this.clearFluors}>
        Clear Fluors
      </button>
    </div>
  );

  renderFluorsRow = (showConcentration: boolean) => {
    if (!this.areAllFluorsEqual()) return this.renderUnequalFluors();

    const { plateSize, scanMode, selectedWellIds, targetErrors, concentrationErrors, keywords } =
      this.props;
    const fluorsInSelectedWell = this.getCurrentWellFluorsMap();
    const availableChToFluorsMap = getAvailableFluors(plateSize, scanMode);
    let index = -1;
    const allTargetsEqual = fluorsInSelectedWell.every(
      (fluorMap: Map<string, any>, channel: string) =>
        this.areTargetsEqual(channel, fluorMap.get('target'))
    );
    const wellId = selectedWellIds.first();
    const displayFluorsList = fluorsInSelectedWell
      .map((fluorMap: Map<string, any>, channel: string) => {
        const key = `FluorophoreSelector-${channel}`;
        index += 1;
        const isFirstEntry = index === 0;
        const targetsEqual = this.areTargetsEqual(channel, fluorMap.get('target'));
        const concentrationsEqual = this.areConcentrationsEqual(
          channel,
          fluorMap.get('concentration')
        );
        const concentration = concentrationsEqual ? fluorMap.get('concentration') : undefined;
        const targetError = ((targetsEqual &&
          targetErrors &&
          targetErrors.getIn([channel, wellId])) ||
          undefined) as string | undefined;
        const concentrationError = ((targetsEqual &&
          concentrationsEqual &&
          concentrationErrors &&
          concentrationErrors.getIn([wellId, channel])) ||
          undefined) as string | undefined;
        return (
          <FluorophoreSelector
            index={index}
            key={key}
            targetName={targetsEqual ? fluorMap.get('target') : undefined}
            targetPlaceHolder={targetsEqual ? undefined : 'Multiple Values'}
            selectedFluor={fluorMap.get('name')}
            concentration={concentration}
            isFirstEntry={isFirstEntry}
            inputChange={this.handleInputChange}
            handleConcentrationChange={this.updateConcentration}
            showConcentration={showConcentration}
            canEditConcentration={allTargetsEqual}
            concentrationPlaceHolder={concentrationsEqual ? undefined : 'Multiple Values'}
            removeFluors={this.removeFluor}
            fluorsOptions={this.fluorOptions(availableChToFluorsMap, channel)}
            targetError={targetError}
            concentrationError={concentrationError}
            keywords={keywords}
          />
        );
      })
      .toList();

    return fluorsInSelectedWell.size <= availableChToFluorsMap.size - 1
      ? displayFluorsList.push(
          <FluorophoreSelector
            index="add"
            isFirstEntry={false}
            showConcentration={false}
            key="add_fluor"
            inputChange={this.handleInputChange}
            handleConcentrationChange={this.updateConcentration}
            removeFluors={this.removeFluor}
            fluorsOptions={this.fluorOptions(availableChToFluorsMap)}
            keywords={keywords}
          />
        )
      : displayFluorsList;
  };

  render() {
    const showConcentration = this.showConcentration();
    return (
      <div id="fluor-list">
        <div className="flex-wrapper header">
          <p className="flex-item label-content">Fluorophore</p>
          <p className="flex-item label-content">Target Name</p>
          {showConcentration && <p className="flex-item label-content">Concentration</p>}
        </div>
        {this.renderFluorsRow(showConcentration)}
      </div>
    );
  }
}
