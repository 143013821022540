import React from 'react';
import ErrorBeaker from '../../img/error_beaker.svg';

export default function () {
  return (
    <div id="page-not-found">
      <div className="content">
        <img src={ErrorBeaker} alt="error icon" />
        <div className="description">
          <h1>404 - Page Not Found</h1>
          <p>The page you are looking for cannot be found.</p>
        </div>
      </div>
    </div>
  );
}
