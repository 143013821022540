import React from 'react';
import './styles/create-experiment-card.scss';
import CreateExperimentCardLinks from './CreateExperimentCardLinks';

export type LinkContent = { id: string; displayText: string; path: string; isCreate?: boolean };

export type Props = {
  id: string;
  header: string;
  img: string;
  primaryLinks?: LinkContent[];
  secondaryLinks?: LinkContent[];
  hasEditPermissions?: boolean;
};

export default function CreateExperimentCard(props: Props) {
  const { id, header, img, primaryLinks, secondaryLinks, hasEditPermissions } = props;

  return (
    <div className="create-experiment-card" id={id}>
      <span id={`${id}-card-header`} className="card-header">
        {header}
      </span>
      <div className="card-columns">
        <div className="image-col">
          <img src={img} alt="instrument" />
        </div>
        <div className="run-col links-column primary-links">
          {primaryLinks && (
            <CreateExperimentCardLinks
              id={id}
              links={primaryLinks}
              hasEditPermissions={hasEditPermissions}
            />
          )}
        </div>
        <div className="v-sep" />
        <div className="protocol-col links-column secondary-links">
          {secondaryLinks && (
            <CreateExperimentCardLinks
              id={id}
              links={secondaryLinks}
              hasEditPermissions={hasEditPermissions}
            />
          )}
        </div>
      </div>
    </div>
  );
}
