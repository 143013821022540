/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Route } from 'react-router-dom';

export type Props = {
  to: string;
  exact: boolean;
  path: string;
};

const ExternalRedirect = (props: Props) => {
  const { to, ...rest } = props;

  function redirect() {
    window.location.replace(to);
    return null;
  }

  return <Route {...rest} render={redirect} />;
};

export default ExternalRedirect;
