import React, { useRef, useState } from 'react';
import { Map } from 'immutable';
import { ImmutableMap, InstrumentItem } from '../../../frontend-common-libs/src/common/types';
import InstrumentReservation from '../InstrumentReservation';
import styles from './styles/create-reservations.module.scss';
import CreateReservationsTable from '../create-reservations-tab/create-reservations-table/CreateReservationsTable';
import PrimaryButton from '../../../frontend-common-libs/src/common/buttons';
import { FleetToggleOptionsEnum } from '../FleetManagementOptions';
import InstrumentFleetModal from '../create-reservations-tab/instrument-fleet-modal/InstrumentFleetModal';
import ReserveInstrumentsButtonClickEvent, {
  ReserveInstrumentsEventParams
} from '../../../user-analytics/fleet-management/ReserveInstrumentsButtonClickEvent';
import getUniqueInstrumentModels from '../../../user-analytics/fleet-management/util';

export type Props = {
  instruments: ImmutableMap<InstrumentItem>[];
  reservations: Map<string, InstrumentReservation>;

  setFleetToggleCallback: (e: string) => void;
};

export default function CreateReservations(props: Readonly<Props>) {
  const { instruments, reservations, setFleetToggleCallback } = props;

  const [selectedInstruments, setSelectedInstruments] = useState<Array<string>>([]);
  const [displayReservationModal, setDisplayReservationModal] = useState<boolean>(false);
  const numberOfReservedInstruments = useRef(0);

  const updateSelectedInstruments = (id: string, select: boolean): void => {
    const isInstrumentSelected = selectedInstruments.includes(id);
    setSelectedInstruments(previousSelection => {
      if (select && !isInstrumentSelected) {
        return [...previousSelection, id];
      }
      if (!select) {
        return previousSelection.filter(instrumentId => instrumentId !== id);
      }
      return previousSelection;
    });
  };

  const clearSelection = () => {
    setSelectedInstruments([]);
  };

  const trackEvent = () => {
    const models = getUniqueInstrumentModels(selectedInstruments, instruments);

    new ReserveInstrumentsButtonClickEvent().track({
      numberOfInstruments: selectedInstruments.length,
      deviceTypes: ['PTCTempo'],
      models
    } as unknown as ReserveInstrumentsEventParams);
  };

  const reserveSelectedInstruments = async (): Promise<void> => {
    if (selectedInstruments.length > 0) {
      setDisplayReservationModal(true);
      trackEvent();
      numberOfReservedInstruments.current = selectedInstruments.length;

      const reservePromises = selectedInstruments.map(instrumentId => {
        const reservation = reservations.get(instrumentId);
        return reservation?.reserve();
      });
      await Promise.all(reservePromises);
      clearSelection();
    }
  };

  const closeReservationModalCallback = () => {
    setDisplayReservationModal(false);
    setFleetToggleCallback(FleetToggleOptionsEnum.Manage);
  };

  const renderReservedModalDialog = () => {
    return (
      <InstrumentFleetModal
        onClose={closeReservationModalCallback}
        show={displayReservationModal}
        numberOfReservedInstruments={numberOfReservedInstruments.current}
      />
    );
  };

  const renderReserveButton = () => {
    const disableReserveButton = selectedInstruments.length === 0;

    return (
      <div className={styles.buttonContainer}>
        <PrimaryButton
          id="reserve-button"
          className={styles.reserveButton}
          type="submit"
          isInProgress={false}
          disabled={disableReserveButton}
          onClick={reserveSelectedInstruments}
        >
          Reserve
        </PrimaryButton>
      </div>
    );
  };

  return (
    <>
      <div
        className={`flex-column-container ${styles.createReservationsContainer}`}
        data-testid="create-reservations-container"
      >
        {renderReservedModalDialog()}
        <div className={styles.createReservationsTableContainer}>
          <CreateReservationsTable
            instruments={instruments}
            reservations={reservations}
            updateSelectedInstruments={updateSelectedInstruments}
          />
        </div>
      </div>
      {renderReserveButton()}
    </>
  );
}
