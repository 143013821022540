import React, { PureComponent, ReactElement } from 'react';
import edit from '../../../img/protocol-edit-icon.svg';
import remove from '../../../img/icons-options-menu-delete.svg';

export type Props = {
  index: number;
  editAction: (...args: Array<any>) => any;
  deleteAction: (...args: Array<any>) => any;
  children: number | string | ReactElement | Array<any>;
  selectedIndex: number;
};

export default class PCRProtocolSteps extends PureComponent<Props> {
  static defaultProps = {
    selectedIndex: 0
  };

  handleDelete = () => {
    const { deleteAction, index } = this.props;
    deleteAction(index);
  };

  handleEdit = () => {
    const { editAction, index } = this.props;
    editAction(index);
  };

  handleEnterPress =
    (cb: (...args: Array<any>) => any) => (e: React.KeyboardEvent<HTMLButtonElement>) => {
      if (e.key === 'Enter') {
        cb();
        e.preventDefault();
      }
    };

  renderActionLinks() {
    const { index, editAction, deleteAction } = this.props;
    const stepNum = index + 1;
    const handleEdit = this.handleEnterPress(this.handleEdit);
    const handleDelete = this.handleEnterPress(this.handleDelete);
    return (
      <>
        {/* @ts-ignore */}
        {editAction && (
          <div
            className="col-step-action edit-icon"
            id={`edit-step-${stepNum}`}
            role="button"
            onClick={this.handleEdit}
            // @ts-ignore
            onKeyDown={handleEdit}
            tabIndex={0}
          >
            <img src={edit} alt="Edit Button" />
          </div>
        )}
        {/* @ts-ignore */}
        {deleteAction && (
          <div
            className="edit-icon"
            id={`delete-step-${stepNum}`}
            role="button"
            onClick={this.handleDelete}
            // @ts-ignore
            onKeyDown={handleDelete}
            tabIndex={0}
          >
            <img src={remove} alt="Delete Button" />
          </div>
        )}
      </>
    );
  }

  render() {
    const { index, selectedIndex, children } = this.props;
    const stepNum = index + 1;
    return (
      <div
        className={`step-item hover-effect ${selectedIndex === index ? 'active' : ''}`}
        id={`step-${stepNum}`}
        key={stepNum}
      >
        <div className="col-step-num">{stepNum}</div>
        <div className="left-flex flex-grow-1 step-description">{children}</div>
        {this.renderActionLinks()}
      </div>
    );
  }
}
