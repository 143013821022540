import { Map } from 'immutable';
import { NullableString } from '../../frontend-common-libs/src/common/nullable-types';

export const InstrumentStatusEnum = {
  Offline: 'Offline',
  Unknown: 'Unknown',
  Connecting: 'Connecting...',
  Running: 'Running',
  Paused: 'Paused',
  Error: 'Error',
  Idle: 'Idle'
};

export const InstrumentModelEnum = {
  Opus96: 'CFX Opus 96',
  Opus96D: 'CFX Opus Deepwell',
  Opus384: 'CFX Opus 384',
  Legacy96: 'CFX 96',
  Legacy96D: 'CFX Deepwell',
  Legacy384: 'CFX 384',
  LegacyTouch96: 'CFX96 Touch',
  LegacyTouch96D: 'CFX Touch Deepwell',
  LegacyTouch384: 'CFX384 Touch'
};

export type InstrumentStatusEnumValue =
  | 'Offline'
  | 'Unknown'
  | 'Connecting...'
  | 'Running'
  | 'Paused'
  | 'Error'
  | 'Idle';

export function instrumentStatusToEnum(
  status: NullableString
): InstrumentStatusEnumValue | NullableString {
  if (!status) {
    return null;
  }
  switch (status.toLowerCase()) {
    case InstrumentStatusEnum.Running.toLowerCase():
      return InstrumentStatusEnum.Running;
    case InstrumentStatusEnum.Error.toLowerCase():
      return InstrumentStatusEnum.Error;
    case InstrumentStatusEnum.Idle.toLowerCase():
      return InstrumentStatusEnum.Idle;
    case InstrumentStatusEnum.Connecting.toLowerCase():
      return InstrumentStatusEnum.Connecting;
    case InstrumentStatusEnum.Offline.toLowerCase():
      return InstrumentStatusEnum.Offline;
    case InstrumentStatusEnum.Paused.toLowerCase():
      return InstrumentStatusEnum.Paused;
    default:
      return null;
  }
}

export function isCreatedByBrIo(createdBy: string | undefined | null) {
  if (createdBy) if (['opus', 'brio'].includes(createdBy.toLocaleLowerCase())) return 'BR.io';
  return null;
}

function classifyInstrument(model: string, serialNumber: string | undefined | null) {
  if (!serialNumber) return null;
  if (serialNumber.startsWith('CT')) {
    if (model.endsWith('96D')) return InstrumentModelEnum.LegacyTouch96D;
    if (model.includes('96')) return InstrumentModelEnum.LegacyTouch96;
    if (model.includes('384')) return InstrumentModelEnum.LegacyTouch384;
  } else {
    if (model.endsWith('96D')) return InstrumentModelEnum.Legacy96D;
    if (model.includes('96')) return InstrumentModelEnum.Legacy96;
    if (model.includes('384')) return InstrumentModelEnum.Legacy384;
  }
  return null;
}

export function instrumentModelToEnum(model: string, serialNumber: NullableString): NullableString {
  if (!model) {
    return null;
  }
  switch (model.toLowerCase()) {
    case 'Opus 96'.toLowerCase():
      return InstrumentModelEnum.Opus96;
    case 'Opus 96D'.toLowerCase():
      return InstrumentModelEnum.Opus96D;
    case 'Opus 384'.toLowerCase():
      return InstrumentModelEnum.Opus384;
    default:
      return classifyInstrument(model, serialNumber);
  }
}

export function filterBlocksFromStatusObjectDetails(statusDetails: Map<string, any>): string[] {
  // Filter blkA and blkB from object keys

  // return empty array because a newly linked instrument will not have a block the object
  if (statusDetails === undefined) return [];

  return [...statusDetails.keys()].filter(key => key === 'blkA' || key === 'blkB');
}

export function missingBlkInStatusObject(
  reduxStatusObjectDetails: Map<string, any>,
  iotStatusObjectDetails: Map<string, any>
): string | null {
  // reduxStatusBlocks resolves to
  // ['BlkA'] in a non-dual block instrument
  // ['BlkA', 'BlkB'] in a dual block instrument
  const reduxStatusBlocks = filterBlocksFromStatusObjectDetails(reduxStatusObjectDetails);

  // iotStatusBlocks is the block or blocks sent by IoT
  // i.e. resolves to ['BlkA'] in a non-dual block instrument
  // i.e. resolves to  ['BlkB'] in a dual-block instrument if only BlkB was updated
  const iotStatusBlocks = filterBlocksFromStatusObjectDetails(iotStatusObjectDetails);

  // determine missing block
  iotStatusBlocks.forEach(block => {
    const index = reduxStatusBlocks.indexOf(block);
    if (index !== -1) reduxStatusBlocks.splice(index, 1);
  });

  return reduxStatusBlocks.length === 0 ? null : reduxStatusBlocks[0];
}

export function updateIotMetadataDetails(
  reduxStatusObject: Map<string, any>,
  iotStatusObject: Map<string, any>,
  missingBlock: string
): Map<string, any> {
  // construct timestamp for missing block in iotStatusObject
  const iotStatusObjectTimestampDetails = iotStatusObject.getIn([
    'metadata',
    'reported',
    'details'
  ]) as Map<string, any>;

  const missingBlockPath = ['metadata', 'reported', 'details', missingBlock];

  const statusTimestampPath = ['status', 'timestamp'];

  const timeRemainingSecsTimestampPath = ['timeRemainingSecs', 'timestamp'];

  const missingBlockStatusTimestamp = reduxStatusObject.getIn([
    ...missingBlockPath,
    ...statusTimestampPath
  ]) as number;

  const missingTimeRemainingSecsTimestamp = reduxStatusObject.getIn([
    ...missingBlockPath,
    ...timeRemainingSecsTimestampPath
  ]) as number;

  const missingBlockTimestampObj = {
    status: { timestamp: missingBlockStatusTimestamp },
    timeRemainingSecs: { timestamp: missingTimeRemainingSecsTimestamp }
  };

  return iotStatusObjectTimestampDetails.set(missingBlock, missingBlockTimestampObj);
}

export function updateIotStateDetails(
  reduxStatusObject: Map<string, any>,
  iotStatusObject: Map<string, any>,
  missingBlock: string
): Map<string, any> {
  // construct status for missing block in iotStatusObject
  const iotStatusObjectStatusDetails = iotStatusObject.getIn([
    'state',
    'reported',
    'details'
  ]) as Map<string, any>;

  const missingBlockStatus = reduxStatusObject.getIn([
    'state',
    'reported',
    'details',
    missingBlock,
    'status'
  ]) as string;

  const missingBlockTimeRemainingSecs =
    (reduxStatusObject.getIn([
      'state',
      'reported',
      'details',
      missingBlock,
      'timeRemainingSecs'
    ]) as number) || 0;

  const missingBlockStatusObj = {
    status: missingBlockStatus,
    timeRemainingSecs: missingBlockTimeRemainingSecs
  };

  return iotStatusObjectStatusDetails.set(missingBlock, missingBlockStatusObj);
}
