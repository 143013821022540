import UserAnalyticsClient from '../../frontend-common-libs/src/user-analytics/UserAnalyticsClient';
import InstrumentTrackingToggle from './InstrumentTrackingToggle';
import AnonymizedString from '../../frontend-common-libs/src/utils/AnonymizedString';

export enum DeviceLinkingEvent {
  USE_CODE = 'device-flow-use-code',
  CONFIRM_DETAILS = 'confirm-details-for-linking'
}
export default class DeviceLinkingTrackingEvent {
  public static track(event: DeviceLinkingEvent, instrumentId: string, deviceType: string): void {
    const trackingParams = {
      instrumentId: new AnonymizedString(instrumentId).toString(),
      deviceType
    };
    const instrumentTrackingToggle = new InstrumentTrackingToggle(instrumentId);
    if (instrumentTrackingToggle.isEnabled())
      UserAnalyticsClient.getInstance().track(event, trackingParams);
  }
}
