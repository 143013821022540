import PlateSize from '../../PlateSize';
import Gradient96 from '../gradient-96/Gradient96';
import Gradient384 from '../gradient-384/Gradient384';
import Gradient, { GradientLowerTemperature, GradientRange } from '../../Gradient';
import Gradient96D from '../gradient-96D/Gradient96D';

export default class GradientFactory {
  public static getGradient(
    plateSize: PlateSize,
    lowerTemperature: GradientLowerTemperature,
    gradientRange: GradientRange,
    isValid: boolean
  ): Gradient {
    switch (plateSize) {
      case PlateSize.PlateSize96:
        return new Gradient96(lowerTemperature, gradientRange, isValid);
      case PlateSize.PlateSize96D:
        return new Gradient96D(lowerTemperature, gradientRange, isValid);
      case PlateSize.PlateSize384:
        return new Gradient384(lowerTemperature, gradientRange, isValid);
      default:
        throw new Error('Unknown Plate Size');
    }
  }
}
