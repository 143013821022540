import UserAnalyticsClient from '../frontend-common-libs/src/user-analytics/UserAnalyticsClient';
import { NewableAnalyticsEvent } from './types';
import {
  SystemEvent,
  SystemEventName,
  SystemEventSubscriber
} from '../frontend-common-libs/src/system-event';
import DuplicateSystemEventRegisteredError from './DuplicateSystemEventRegisteredError';

type AnalyticsEventLookupTable = { [key: string]: NewableAnalyticsEvent };

export default class UserAnalyticsSubscriber implements SystemEventSubscriber {
  private analyticsEventLookupTable: AnalyticsEventLookupTable = {};

  private static singleton: UserAnalyticsSubscriber | undefined;

  public static getInstance(): UserAnalyticsSubscriber {
    if (!UserAnalyticsSubscriber.singleton) {
      UserAnalyticsSubscriber.singleton = new UserAnalyticsSubscriber();
    }

    return UserAnalyticsSubscriber.singleton;
  }

  public notify(systemEvent: SystemEvent): void {
    const AnalyticsEvent = this.analyticsEventLookupTable[systemEvent.name];

    if (AnalyticsEvent) {
      const analyticsEvent = new AnalyticsEvent(systemEvent);

      UserAnalyticsClient.getInstance().track(analyticsEvent.name, analyticsEvent.params);
    }
  }

  public registerEvent(
    systemEventName: SystemEventName,
    analyticsEventName: NewableAnalyticsEvent
  ) {
    if (this.analyticsEventLookupTable[systemEventName]) {
      throw new DuplicateSystemEventRegisteredError(
        'Duplicate system event name. You can not register more than one analytics event with the same system event name.'
      );
    }

    this.analyticsEventLookupTable[systemEventName] = analyticsEventName;
  }

  public static reset(): void {
    UserAnalyticsSubscriber.singleton = undefined;
  }
}
