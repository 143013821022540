import * as LDClient from 'launchdarkly-js-client-sdk';
import LaunchDarklyUser from './LaunchDarklyUser';
import { NullableString } from '../../common/nullable-types';

export default class CloudFeatureFlagClient {
  constructor(email: NullableString) {
    this._client = undefined;
    this._initialize(email);
  }

  _client: LDClient.LDClient | null | undefined;

  _initializePromise: Promise<void> | null | undefined = undefined;

  _initialize(email: NullableString) {
    this._initializePromise = new Promise(resolve => {
      const client = LDClient.initialize(
        this.LAUNCH_DARKLY_TOKEN,
        new LaunchDarklyUser(email).toJSON()
      );
      client.on('ready', () => {
        this._client = client;
        resolve();
      });
    });
  }

  static async create(email: NullableString): Promise<CloudFeatureFlagClient> {
    const client = new CloudFeatureFlagClient(email);
    await client.waitForInitialized();
    return client;
  }

  async waitForInitialized(): Promise<void | null | undefined> {
    return this._initializePromise;
  }

  get(key: string): boolean {
    return (this._client && this._client.variation(key, false)) || false;
  }

  LAUNCH_DARKLY_TOKEN = '5f79695a2229060b440fbb34';
}
