// @ts-nocheck
// Store referrer url
// https://github.com/kevinrademan/react-referer/blob/master/src/isomorphic-referrer.js
const originalReferrer =
  (typeof window !== 'undefined' &&
    window !== null &&
    window.document &&
    window.document.referrer) ||
  undefined;

export default function getOriginalReferrer() {
  return originalReferrer;
}
