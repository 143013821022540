import { Map, List, fromJS } from 'immutable';
import { getNewFileName } from '../../../../utils/fileUtils';
import {
  getDefaultProtocol,
  defaultNewStep,
  GOTO,
  minGotoForIndex,
  plateReads,
  changeInReads
} from '../../../../utils/protocolUtils';
import { LidSettingCustom, QPCRProtocol, LidSetting } from '../../pcr_protocol_types';

export default class QPcrProtocol {
  map: Map<string, any>;

  constructor(
    options: {
      map?: Map<string, any>;
      name?: string;
      protocol?: Map<string, any>;
      templateId?: string;
      versionNumber?: number;
      projectId?: string;
    } = {}
  ) {
    if (options.map) {
      this.map = options.map;
    } else {
      const protocol = options.protocol || fromJS({ steps: [] });
      // @ts-ignore
      this.map = fromJS({
        originalName: options.name || '',
        name: options.name || '',
        versionNumber: options.versionNumber || 1,
        projectId: options.projectId || '',
        protocol,
        originalProtocol: protocol,
        templateId: options.templateId || '',
        // @ts-ignore
        reads: plateReads(protocol.get('steps'))
      });
    }
  }

  get protocol(): Map<string, any> {
    return this.map.get('protocol');
  }

  get templateId(): string {
    return this.map.get('templateId');
  }

  get name(): string {
    return this.map.get('name');
  }

  get versionNumber(): number {
    return this.map.get('versionNumber');
  }

  get projectId(): string {
    return this.map.get('projectId');
  }

  get steps(): List<Map<string, any>> {
    // @ts-ignore
    return this.map.getIn(['protocol', 'steps']);
  }

  get originalName(): string {
    return this.map.get('originalName');
  }

  get originalProtocol(): List<Map<string, any>> {
    return this.map.get('originalProtocol');
  }

  get previousSteps(): List<Map<string, any>> {
    return this.map.get('previousSteps');
  }

  get reads(): number {
    return this.map.get('reads');
  }

  get previousReads(): number {
    return this.map.get('previousReads');
  }

  get protocolWarning(): string {
    return this.reads === 0 && this.steps.size > 0
      ? 'Protocol does not contain a step with a plate read.'
      : '';
  }

  get volume(): number {
    // @ts-ignore
    return this.map.getIn(['protocol', 'vol']);
  }

  setVolume(vol?: number): QPcrProtocol {
    return new QPcrProtocol({
      map: this.map.setIn(['protocol', 'vol'], vol)
    });
  }

  get lidSetting(): LidSetting {
    // @ts-ignore
    return this.map.getIn(['protocol', 'lidTemp', 'mode']);
  }

  setLidSetting(setting: string) {
    return new QPcrProtocol({
      map: this.map
        .setIn(['protocol', 'lidTemp', 'mode'], setting)
        .deleteIn(['protocol', 'lidTemp', 'temp'])
    });
  }

  get lidTemp(): number {
    // @ts-ignore
    return this.map.getIn(['protocol', 'lidTemp', 'temp']);
  }

  setLidTemp(temp?: number) {
    if (this.lidSetting !== LidSettingCustom) {
      return this;
    }

    return new QPcrProtocol({
      map: this.map.setIn(['protocol', 'lidTemp', 'temp'], temp)
    });
  }

  hasChanges(): boolean {
    return this.originalName !== this.name || this.hasProtocolChanges();
  }

  hasProtocolChanges(): boolean {
    return !this.originalProtocol.equals(this.protocol);
  }

  setProtocol(protocol: Map<string, any>): QPcrProtocol {
    let map = this.map.set('protocol', protocol).set('reads', plateReads(protocol.get('steps')));
    // @ts-ignore
    if (this.originalProtocol.get('steps').size === 0) {
      map = map.set('originalProtocol', protocol);
    }
    return new QPcrProtocol({ map });
  }

  updateName(newName: string): QPcrProtocol {
    return new QPcrProtocol({
      map: this.map.set('name', newName)
    });
  }

  deleteStep(index: number): QPcrProtocol {
    if (index >= 0 && index < this.steps.size) {
      let deleted = 1;
      // @ts-ignore
      const readChange = changeInReads(this.steps.get(index));
      let newSteps = this.steps.delete(index);
      if (
        index < this.steps.size - 1 && // not the last step
        newSteps.getIn([index, 'type']) === GOTO &&
        newSteps.getIn([index, 'step']) === index
      ) {
        // delete immediate goto pointing to the deleted step
        newSteps = newSteps.delete(index);
        deleted += 1;
      }
      for (let i = index + 1; i < newSteps.size; i += 1) {
        // @ts-ignore
        if (newSteps.getIn([i, 'type']) === GOTO && newSteps.getIn([i, 'step']) > index) {
          // @ts-ignore
          const newStepVal = newSteps.getIn([i, 'step']) - deleted;
          newSteps = newSteps.setIn([i, 'step'], newStepVal);
        }
      }

      return new QPcrProtocol({
        map: this.map
          .setIn(['protocol', 'steps'], newSteps)
          .set('reads', this.reads + readChange)
          .set('previousSteps', null)
          .delete('previousReads')
      });
    }
    return this;
  }

  addStep(index: number): QPcrProtocol {
    if (this.previousSteps) {
      throw new Error('previous action not completed');
    }
    let newSteps = this.steps.insert(index, defaultNewStep('temp'));
    for (let i = index + 1; i < newSteps.size; i += 1) {
      // @ts-ignore
      if (newSteps.getIn([i, 'type']) === GOTO && newSteps.getIn([i, 'step']) >= index) {
        // @ts-ignore
        const newStepVal = newSteps.getIn([i, 'step']) + 1;
        newSteps = newSteps.setIn([i, 'step'], newStepVal);
      }
    }
    const map = this.map
      .set('previousSteps', this.steps)
      .set('previousReads', this.reads)
      .setIn(['protocol', 'steps'], newSteps);
    return new QPcrProtocol({ map });
  }

  changeStepType(index: number, stepType: string) {
    if (index >= 0 && index < this.steps.size) {
      let newStep = defaultNewStep(stepType);
      if (stepType === GOTO) {
        newStep = newStep.set('step', minGotoForIndex(this.steps, index));
      }
      let map = !this.previousSteps
        ? this.map.set('previousSteps', this.steps).set('previousReads', this.reads)
        : this.map;
      // @ts-ignore
      const readChange = changeInReads(this.steps.get(index), newStep);
      map = map
        .setIn(['protocol', 'steps'], this.steps.set(index, newStep))
        .set('reads', this.reads + readChange);
      return new QPcrProtocol({ map });
    }
    return this;
  }

  cancelEdit() {
    if (!this.previousSteps) {
      return this;
    }
    const map = this.map
      .setIn(['protocol', 'steps'], this.previousSteps)
      .set('reads', this.previousReads)
      .set('previousSteps', null)
      .delete('previousReads');
    return new QPcrProtocol({ map });
  }

  saveEdit(index: number, step: Map<string, any>) {
    // @ts-ignore
    const readChange = changeInReads(this.steps.get(index), step);
    const newSteps = this.steps.set(index, step);

    return new QPcrProtocol({
      map: this.map
        .setIn(['protocol', 'steps'], newSteps)
        .set('reads', this.reads + readChange)
        .set('previousSteps', null)
        .delete('previousReads')
    });
  }

  toJS(): QPCRProtocol {
    return this.protocol.toJS() as QPCRProtocol;
  }

  static empty() {
    return new QPcrProtocol();
  }

  static default() {
    return new QPcrProtocol({
      name: getNewFileName(),
      // @ts-ignore
      protocol: getDefaultProtocol()
    });
  }

  static fromEntity(entity: Map<string, any>, protocol: Map<string, any>) {
    const name = entity.get('name');
    const projectId = entity.get('parent_id');
    const versionNumber = entity.get('versionNumber');
    const templateId = entity.getIn(['meta', 'qpcrProtocol', 'protocol']);
    if (templateId) {
      // @ts-ignore
      return new QPcrProtocol({ name, protocol, templateId, versionNumber, projectId });
    }
    throw new Error('Invalid link.');
  }

  static runProtocol(protocol: Map<string, any>): QPcrProtocol {
    return new QPcrProtocol({ protocol });
  }
}
