import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { logoutUser as logoutUserAction } from '../../auth/actions/auth_actions';
import Loader from '../../frontend-common-libs/src/components/common/Loader';

type Props = {
  logoutUser: typeof logoutUserAction;
};

type State = {
  isLoggedOut: boolean;
};

export class LogoutImpl extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { isLoggedOut: false };
  }

  componentDidMount() {
    this.logoutUser();
  }

  logoutUser = async () => {
    const { logoutUser } = this.props;
    await logoutUser();
    this.setState({ isLoggedOut: true });
  };

  render() {
    const { isLoggedOut } = this.state;
    return isLoggedOut ? <Redirect to="/" /> : <Loader />;
  }
}

export default connect(null, { logoutUser: logoutUserAction })(LogoutImpl);
