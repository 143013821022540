/* eslint-disable react/jsx-props-no-spreading */

import React, { ComponentType, PureComponent } from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { ReduxState } from '../../types';

type Props = {
  component: ComponentType<any>;
  authenticated: boolean;
  path: string;
};

type StateProps = {
  authenticated: boolean;
};

type ChildProps = {};

export class PrivateRouteImpl extends PureComponent<Props> {
  renderRoute = (childProps: ChildProps) => {
    const { component: Component, authenticated } = this.props;
    return authenticated ? <Component {...childProps} /> : <Redirect to="/" />;
  };

  render() {
    const { component, authenticated, ...rest } = this.props;
    return <Route {...rest} render={this.renderRoute} />;
  }
}

function mapStateToProps(state: ReduxState): StateProps {
  return { authenticated: state.auth.get('authenticated') };
}

export default connect(mapStateToProps)(PrivateRouteImpl);
