import React, { HTMLProps, ReactElement, ReactNode } from 'react';
import classnames from 'classnames';
import styles from '../styles/auto-suggest.module.scss';

export interface AriaAutoSuggestDropdownItemProps {
  dropdownItemProps: HTMLProps<HTMLLIElement>;
  isHighlighted: boolean;
  children: ReactNode;
}

export default function AriaAutoSuggestDropdownItem({
  isHighlighted,
  dropdownItemProps,
  children
}: AriaAutoSuggestDropdownItemProps): ReactElement<AriaAutoSuggestDropdownItemProps> {
  const dropdownItemStyle = styles['aria-autosuggest-dropdown-item'];
  const dropdownItemHighlightedStyle = styles.highlighted;

  const ariaAutoSuggestDropdownStyles = classnames(dropdownItemStyle, {
    [dropdownItemHighlightedStyle]: isHighlighted
  });
  return (
    <li className={ariaAutoSuggestDropdownStyles} {...dropdownItemProps}>
      {children}
    </li>
  );
}
