import React from 'react';

import {
  endTemps,
  makePath,
  makeTextAboveLine,
  makeTextBelowLine,
  makeCamera,
  StepProps,
  Point
} from './common';
import QPcrStep from '../models/QPcrStep';

function TempStep(props: StepProps) {
  const { step, yScale, stepWidth, index, prev } = props;
  const { temp, tempAsString, timeAsString, read } = new QPcrStep(step);
  const y1 = yScale(temp);
  const x1 = 0.3;
  const x2 = 0.2 * stepWidth;
  const x3 = stepWidth - 0.5;
  const prevPoints = endTemps(prev).map(yScale);
  const lineData: Point[][] = [];
  prevPoints.forEach(p => {
    lineData.push([
      { x: x1, y: p },
      { x: x2, y: y1 },
      { x: x3, y: y1 }
    ]);
  });
  return (
    <>
      {lineData.map(makePath)}
      {makeTextAboveLine(stepWidth / 2, y1, `${tempAsString} ºC`, `graph-steptemp-${index + 1}`)}
      {makeTextBelowLine(stepWidth / 2, y1, timeAsString, `graph-steptime-${index + 1}`)}
      {read && makeCamera(index, temp, stepWidth / 2, yScale)}
    </>
  );
}

TempStep.defaultProps = {
  prev: undefined
};

TempStep.displayName = 'TempStep';

export default TempStep;
