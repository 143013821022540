// @ts-nocheck
import React from 'react';
import LogoHeader from '../../auth/LogoHeader';
import UnAuthedFooter from '../../auth/UnAuthedFooter';
import './maintenance-page.scss';
import MaintenanceScientist from './maintenance_scientist.svg';

const message = "Sorry, we're down for maintenance. We'll be back up shortly.";

export default () => (
  <>
    <LogoHeader className="content-container" />
    <main id="maintenance-container">
      <img src={MaintenanceScientist} alt="maintenance icon" />
      <h1>{message}</h1>
    </main>
    <UnAuthedFooter className="content-container" />
  </>
);
