import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import { ReduxState } from '../../../types';
import {
  getRunName,
  getRunNotes,
  getOriginalNotes,
  getRunNotesError,
  getRunNameError,
  getRunBarcode,
  isSaveInProgress as getIsSaveInProgress
} from '../../../selectors/selectors';
import { LinkButton } from '../../../frontend-common-libs/src/components/common/buttons';
import {
  setFileName as setRunNameAction,
  setRunNotes as setRunNotesAction,
  setRunBarcode as setRunBarcodeAction
} from '../../../actions/currentCfxRun_actions';
import CompletedRunDetails from './CompletedPcrRunDetails';
import { FileName } from '../../../frontend-common-libs/src/components/common/run/FileName';
import { RunBarcode } from '../../../frontend-common-libs/src/components/common/run/RunBarcode';
import { RunNotes } from '../../../frontend-common-libs/src/components/common/run/RunNotes';
import { getSelectedProjectId } from '../../../project-management';
import { canEditFilesInProject } from '../../../project-management/selectors/selectors';

export type Props = {
  runName: string;
  runBarcode: string;
  runNotes: string;
  originalNotes: string;
  runNameError: string;
  runBarcodeError: string;
  runNotesError: string;
  setRunName: (runName: string) => void;
  setRunNotes: (notes: string, originalNotesLength: number) => void;
  setRunBarcode: (barcode: string) => void;
  saveAction: (arg0: string, arg1: boolean) => Promise<void>;
  createTemplateAction: () => void;
  disableSaveButton?: boolean;
  isCompletedRun: boolean;
  isSaveInProgress?: boolean;
  canEditProjectFiles?: boolean;
};

export class PCRRunDetailsImpl extends PureComponent<Props> {
  static defaultProps = {
    disableSaveButton: true,
    isSaveInProgress: false
  };

  handleClick = () => {
    const { saveAction } = this.props;
    saveAction('saveRun', true);
  };

  handleCreateRunTemplate = () => {
    const { createTemplateAction } = this.props;
    createTemplateAction();
  };

  renderCreateRunTemplate = () => {
    const { isCompletedRun, canEditProjectFiles } = this.props;
    if (isCompletedRun) {
      return (
        <LinkButton
          id="create-template-btn"
          type="button"
          onClick={this.handleCreateRunTemplate}
          disabled={!canEditProjectFiles}
        >
          Create Run Template
        </LinkButton>
      );
    }
    return null;
  };

  render() {
    const { disableSaveButton, isCompletedRun, isSaveInProgress } = this.props;
    const {
      runName,
      runNameError,
      setRunName,
      runBarcode,
      runBarcodeError,
      setRunBarcode,
      originalNotes,
      setRunNotes,
      runNotes,
      runNotesError,
      canEditProjectFiles
    } = this.props;
    const disabled = !canEditProjectFiles;
    return (
      <div className="run-details-wrapper-rows">
        <div className="run-details-wrapper-columns">
          <div className="run-details-content">
            <FileName
              fileName={runName}
              fileNameError={runNameError}
              setFileName={setRunName}
              disabled={disabled}
            />
            <RunBarcode
              runBarcode={runBarcode}
              runBarcodeError={runBarcodeError}
              setRunBarcode={setRunBarcode}
              disabled={disabled}
            />
            <RunNotes
              originalNotes={originalNotes}
              setRunNotes={setRunNotes}
              runNotesError={runNotesError}
              runNotes={runNotes}
              disabled={disabled}
            />
            {this.renderCreateRunTemplate()}
          </div>
          <CompletedRunDetails isCompletedRun={isCompletedRun} />
        </div>
        <div className="run-details-footer">
          {!isCompletedRun && (
            <LinkButton
              id="save-run"
              type="button"
              onClick={!disableSaveButton ? this.handleClick : undefined}
              disabled={disabled || disableSaveButton}
              isInProgress={isSaveInProgress}
            >
              Save Run
            </LinkButton>
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: ReduxState) {
  const projectId = getSelectedProjectId(state);
  return {
    runName: getRunName(state),
    runNotes: getRunNotes(state),
    runBarcode: getRunBarcode(state),
    originalNotes: getOriginalNotes(state),
    isSaveInProgress: getIsSaveInProgress(state),
    runNotesError: getRunNotesError(state),
    runNameError: getRunNameError(state),
    runBarcodeError: (state.currentCfxRun as Map<string, any>).getIn(['errors', 'runBarcodeError']),
    canEditProjectFiles: canEditFilesInProject(state, projectId)
  };
}

export default connect(mapStateToProps, {
  setRunName: setRunNameAction,
  setRunNotes: setRunNotesAction,
  setRunBarcode: setRunBarcodeAction
  // @ts-ignore
})(PCRRunDetailsImpl);
