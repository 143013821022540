import { fromJS, List, Map } from 'immutable';
import { UserPermissions } from '@biorad-lsg-tsc/organization';
import {
  LOGIN_TOKEN_LOADED,
  ORGS_LOADING,
  ORGS_LOADED,
  ORG_ADDED,
  OrgManagementActionTypes,
  ORG_SELECTED,
  ORG_DETAILS_LOADED,
  ORG_DETAILS_LOADING,
  ORG_MEMBER_ROLE_CHANGED,
  ORG_USER_ADDED,
  ORG_TOKEN_LOADED,
  ORG_TOKEN_LOADING,
  ORG_TOKEN_REVOKED,
  LOGIN_TOKEN_LOADING,
  ORG_RENAMED,
  ORG_DESCRIPTION_CHANGED,
  ORG_USER_REMOVED,
  ORG_REMOVED
} from '../actions/action-types';
import { ApiAction } from '../../types';
import { UNAUTH_USER } from '../../auth/actions/auth_types';

const INITIAL_STATE: Map<string, any> = Map({});

const onLoginTokenLoading = (state: Map<string, any>) => {
  return state.set('loadingLoginAccessToken', true);
};

const onLoginTokenLoaded = (
  state: Map<string, any>,
  action: ApiAction<OrgManagementActionTypes, any>
) => {
  const { loginInfo, setActiveOrg } = action.payload;
  const { userToken } = loginInfo;
  const permissions = new UserPermissions(userToken.accessToken);
  const organizationId = permissions.tenantId;
  let resState = state
    .setIn(['loginInfo', 'userToken'], userToken)
    .set('loadingLoginAccessToken', false)
    .setIn(['orgDetails', organizationId, 'userPermissions'], permissions)
    .setIn(['orgDetails', organizationId, 'userToken'], userToken)
    .setIn(['orgDetails', organizationId, 'loadingOrgAccessToken'], false);
  if (setActiveOrg) resState = resState.setIn(['organizations', 'activeOrgId'], organizationId);
  return resState;
};

const onOrgTokenLoading = (
  state: Map<string, any>,
  action: ApiAction<OrgManagementActionTypes, any>
) => {
  const { organizationId } = action.payload;
  return state.setIn(['orgDetails', organizationId, 'loadingOrgAccessToken'], true);
};

const onOrgTokenLoaded = (
  state: Map<string, any>,
  action: ApiAction<OrgManagementActionTypes, any>
) => {
  const { loginInfo, organizationId } = action.payload;
  const { userToken } = loginInfo;
  const permissions = new UserPermissions(userToken.accessToken);
  return state
    .setIn(['orgDetails', organizationId, 'userPermissions'], permissions)
    .setIn(['orgDetails', organizationId, 'userToken'], userToken)
    .setIn(['orgDetails', organizationId, 'loadingOrgAccessToken'], false)
    .setIn(['loginInfo', 'userToken'], userToken);
};

const onOrgTokenRevoked = (
  state: Map<string, any>,
  action: ApiAction<OrgManagementActionTypes, any>
) => {
  const { orgId } = action.payload;
  return state
    .removeIn(['orgDetails', orgId, 'userPermissions'])
    .removeIn(['orgDetails', orgId, 'userToken'])
    .removeIn(['orgDetails', orgId, 'loadingOrgAccessToken']);
};

export const sortOrganizationsByName = (orgs: List<any>) => {
  if (!orgs) return orgs;
  return orgs.sortBy(o => o.get('name'));
};

const onOrganizationsLoading = (
  state: Map<string, any>,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _action: ApiAction<OrgManagementActionTypes, any>
) => {
  return state.setIn(['organizations', 'loading'], true);
};

const onOrganizationsLoaded = (
  state: Map<string, any>,
  action: ApiAction<OrgManagementActionTypes, any>
) => {
  const organizations = sortOrganizationsByName(fromJS(action.payload.organizations) as List<any>);
  return state
    .setIn(['organizations', 'loading'], false)
    .setIn(['organizations', 'organizations'], organizations)
    .setIn(['organizations', 'activeOrgId'], action.payload.activeOrg);
};

const onOrgSelected = (
  state: Map<string, any>,
  action: ApiAction<OrgManagementActionTypes, any>
) => {
  const { organizationId } = action.payload;
  return state.setIn(['organizations', 'activeOrgId'], organizationId);
};

const onOrgAdded = (state: Map<string, any>, action: ApiAction<OrgManagementActionTypes, any>) => {
  const { org } = action.payload;
  const resState = state.removeIn(['orgDetails', org.orgId, 'details']);
  const organizations = state.getIn(['organizations', 'organizations']) as List<any>;
  if (!organizations) return resState;
  const filteredOrgs = organizations.filter(o => o.get('orgId') !== org.orgId);
  const organizationsNew = sortOrganizationsByName(filteredOrgs.unshift(fromJS(org)));
  return resState.setIn(['organizations', 'organizations'], organizationsNew);
};

const onOrgRemoved = (
  state: Map<string, any>,
  action: ApiAction<OrgManagementActionTypes, any>
) => {
  const { orgId } = action.payload;
  const organizations = state.getIn(['organizations', 'organizations']) as List<any>;
  const activeOrgId = state.getIn(['organizations', 'activeOrgId']);
  let resState = state.removeIn(['orgDetails', orgId]);
  if (activeOrgId === orgId) resState = resState.setIn(['organizations', 'activeOrgId'], undefined);
  if (organizations) {
    const filteredOrgs = organizations.filter(org => org.get('orgId') !== orgId);
    resState = resState.setIn(['organizations', 'organizations'], filteredOrgs);
  }
  return resState;
};

export const sortOrgMembersByName = (orgDetails: Map<string, any>) => {
  if (!orgDetails) return orgDetails;
  let users = orgDetails.get('users') as List<Map<string, any>>;
  if (!users) return orgDetails;
  users = users.sortBy(u => u.get('userName'));
  return orgDetails.set('users', users);
};

const onOrgDetailsLoading = (
  state: Map<string, any>,
  action: ApiAction<OrgManagementActionTypes, any>
) => {
  const { orgId } = action.payload;
  return state.setIn(['orgDetails', orgId, 'loading'], true);
};

const onOrgDetailsLoaded = (
  state: Map<string, any>,
  action: ApiAction<OrgManagementActionTypes, any>
) => {
  const { orgId, details } = action.payload;
  const detailsMap = sortOrgMembersByName(fromJS(details) as Map<string, any>);
  return state
    .setIn(['orgDetails', orgId, 'loading'], false)
    .setIn(['orgDetails', orgId, 'details'], detailsMap);
};

const onOrgUserAdded = (
  state: Map<string, any>,
  action: ApiAction<OrgManagementActionTypes, any>
) => {
  const { orgId, userName, userRole } = action.payload;
  let detailsMap = state.getIn(['orgDetails', orgId, 'details']) as Map<string, any>;
  if (!detailsMap) return state;
  let orgUsers = detailsMap.get('users') as List<any>;
  if (!orgUsers) return state;
  orgUsers = orgUsers.filter(user => user.get('userName') !== userName);
  orgUsers = orgUsers.unshift(fromJS({ userName, userRole }));
  detailsMap = detailsMap.set('users', orgUsers);
  return state.setIn(['orgDetails', orgId, 'details'], sortOrgMembersByName(detailsMap));
};

const onOrgMemberRoleChanged = (
  state: Map<string, any>,
  action: ApiAction<OrgManagementActionTypes, any>
) => {
  const { orgId, userName, role } = action.payload;
  let users = state.getIn(['orgDetails', orgId, 'details', 'users']) as List<any>;
  if (!users) return state;
  users = users.map(user =>
    user.get('userName') === userName ? user.set('userRole', role) : user
  );
  return state.setIn(['orgDetails', orgId, 'details', 'users'], users);
};

const onOrgUserRemoved = (
  state: Map<string, any>,
  action: ApiAction<OrgManagementActionTypes, any>
) => {
  const { orgId, userName } = action.payload;
  const users = state.getIn(['orgDetails', orgId, 'details', 'users']) as List<any>;
  if (!users) return state;
  const filteredUsers = users.filter(user => user.get('userName') !== userName);
  return state.setIn(['orgDetails', orgId, 'details', 'users'], filteredUsers);
};

const onOrgRenamed = (
  state: Map<string, any>,
  action: ApiAction<OrgManagementActionTypes, any>
) => {
  const { orgId, name } = action.payload;
  const orgName = state.getIn(['orgDetails', orgId, 'details', 'name']);
  let resState = state;
  if (orgName) resState = resState.setIn(['orgDetails', orgId, 'details', 'name'], name);
  const organizations = state.getIn(['organizations', 'organizations']) as List<any>;
  if (organizations) {
    let organizationsNew = organizations.map(org =>
      org.get('orgId') === orgId ? org.set('name', name) : org
    );
    organizationsNew = sortOrganizationsByName(organizationsNew);
    resState = resState.setIn(['organizations', 'organizations'], organizationsNew);
  }
  return resState;
};

const onOrgDescriptionChanged = (
  state: Map<string, any>,
  action: ApiAction<OrgManagementActionTypes, any>
) => {
  const { orgId, description } = action.payload;
  const orgDetails = state.getIn(['orgDetails', orgId, 'details']);
  if (!orgDetails) return state;
  return state.setIn(['orgDetails', orgId, 'details', 'description'], description);
};

const resetState = () => INITIAL_STATE;

const actionMap = {
  [LOGIN_TOKEN_LOADING]: onLoginTokenLoading,
  [LOGIN_TOKEN_LOADED]: onLoginTokenLoaded,
  [ORG_TOKEN_LOADING]: onOrgTokenLoading,
  [ORG_TOKEN_LOADED]: onOrgTokenLoaded,
  [ORG_TOKEN_REVOKED]: onOrgTokenRevoked,
  [ORGS_LOADING]: onOrganizationsLoading,
  [ORGS_LOADED]: onOrganizationsLoaded,
  [ORG_ADDED]: onOrgAdded,
  [ORG_REMOVED]: onOrgRemoved,
  [ORG_DETAILS_LOADING]: onOrgDetailsLoading,
  [ORG_DETAILS_LOADED]: onOrgDetailsLoaded,
  [ORG_USER_ADDED]: onOrgUserAdded,
  [ORG_USER_REMOVED]: onOrgUserRemoved,
  [ORG_MEMBER_ROLE_CHANGED]: onOrgMemberRoleChanged,
  [ORG_RENAMED]: onOrgRenamed,
  [ORG_DESCRIPTION_CHANGED]: onOrgDescriptionChanged,
  [UNAUTH_USER]: resetState,
  [ORG_SELECTED]: onOrgSelected
};

export default function organizationReducer(
  state: Map<string, any> = INITIAL_STATE,
  action: ApiAction<any, any> | undefined = undefined
): Map<string, any> {
  if (action) {
    return actionMap[action.type] ? actionMap[action.type](state, action) : state;
  }
  return state;
}
