// @ts-nocheck
import React from 'react';

type Props = {
  id: string;
  title: string;
  children: any;
};

export default ({ id, title, children }: Props) => (
  <div id={id} className="box">
    <h2>{title}</h2>
    <p>{children}</p>
  </div>
);
