import React, { PureComponent } from 'react';
import { LinkButton } from '../../frontend-common-libs/src/components/common/buttons';
import { resendVerificationCode } from '../../frontend-common-libs/src/utils/authUtils';
import notification from '../../frontend-common-libs/src/utils/notifications';

interface State {
  isSendingCode: boolean;
}

export type Props = {
  username: string;
};

class ResendVerificationCode extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isSendingCode: false
    };
  }

  private handleResendCode = async () => {
    const { isSendingCode } = this.state;
    const { username } = this.props;
    if (!isSendingCode) {
      this.setState({
        isSendingCode: true
      });
      try {
        await resendVerificationCode(username);
        this.setState(
          {
            isSendingCode: false
          },
          () => {
            notification.success(
              `We've sent an email to ${username} to verify your email address and verify your account to BR.io.`
            );
          }
        );
      } catch (e) {
        this.setState(
          {
            isSendingCode: false
          },
          () => {
            notification.error(
              "Please, try again.\n We couldn't send the code. Please try again by clicking on the link below."
            );
          }
        );
      }
    }
  };

  render() {
    return (
      <LinkButton
        type="button"
        onClick={this.handleResendCode}
        className="regular-link"
        id="link-resend"
      >
        To re-send the verification link, please click here.
      </LinkButton>
    );
  }
}

export default ResendVerificationCode;
