import axios from 'axios';
import { getCpcrRun } from '../api/runs';
import { UserFile } from '../../../frontend-common-libs/src/common/types';

export type PtcRunInfoEditable = {
  plateId: string;
  notes: string;
};

export type PtcRunInfo = {
  id: string;
  entityId: string;
} & PtcRunInfoEditable;

export type PtcRun = {
  file: UserFile;
  runReport: Record<string, any>;
  runInfo: PtcRunInfo;
};

export default class RunRepository {
  private loadedRuns: Record<string, PtcRun> = {};

  private constructor() {
    // private constructor for singleton class
  }

  private static _instance: RunRepository | undefined = undefined;

  public static get instance(): RunRepository {
    if (this._instance == null) this._instance = new RunRepository();
    return this._instance;
  }

  private setLoadedRun(entityId: string, run: PtcRun) {
    this.loadedRuns[entityId] = run;
  }

  private getLoadedRun(entityId: string) {
    return this.loadedRuns[entityId];
  }

  public isLoaded(entityId: string): boolean {
    return this.getLoadedRun(entityId) != null;
  }

  public async getRun(entityId: string): Promise<PtcRun> {
    if (this.isLoaded(entityId)) return this.getLoadedRun(entityId);
    const { reportUrl, file, run } = await getCpcrRun(entityId);
    const { data } = await axios.get(reportUrl);
    const ptcRun: PtcRun = { file, runReport: data, runInfo: run };
    this.setLoadedRun(entityId, ptcRun);
    return ptcRun;
  }

  public runUpdated(file: UserFile) {
    const entityId = file.id;
    if (!this.isLoaded(entityId)) return;
    const ptcRun = this.getLoadedRun(entityId);
    ptcRun.file = file;
    this.setLoadedRun(entityId, ptcRun);
  }

  public runSaved(file: UserFile, runInfo: PtcRunInfoEditable) {
    const entityId = file.id;
    if (!this.isLoaded(entityId)) return;
    const ptcRun = this.getLoadedRun(entityId);
    ptcRun.file = file;
    ptcRun.runInfo = { ...ptcRun.runInfo, ...runInfo };
    this.setLoadedRun(entityId, ptcRun);
  }

  public clear() {
    this.loadedRuns = {};
  }
}
