import React from 'react';
import CircularLoader from '../../frontend-common-libs/src/components/common/CircularLoader';

export type Props = {
  title: string;
  contentMessage: string;
};

function AccountVerificationLoader({ title, contentMessage }: Props) {
  return (
    <div id="loader-container">
      <div className="center-content">
        <CircularLoader />
      </div>
      <p id="loader-title">{title}</p>
      <p id="loader-message">{contentMessage}</p>
    </div>
  );
}

AccountVerificationLoader.defaultProps = {
  title: '',
  contentMessage: ''
};

export default AccountVerificationLoader;
