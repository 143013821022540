// @ts-nocheck
import React, { PureComponent } from 'react';
import { fromJS, Map, OrderedMap } from 'immutable';
import classnames from 'classnames';
import TableHeader from './TableHeader';

export type Props = {
  updateSortOrder: (...args: Array<any>) => any;
  sortOrder: OrderedMap;
  sortedData: Map;
  tableHeaders: OrderedMap;
  tableName: string;
};

export default class AnalysisResultsTableImpl extends PureComponent<Props> {
  wellSortIndex: number;

  constructor(props: Props) {
    super(props);
    this.wellSortIndex = 0;
  }

  getSortClassName = (sortKey: string): string | null | undefined => {
    const { sortOrder } = this.props;
    const sortOrderValue = sortOrder.get(sortKey);
    return sortOrderValue && (sortOrderValue.get('isAscending') ? 'sort-up' : 'sort-down');
  };

  makeRow = (rowData: Map, rowIndex: number) => {
    const prefix = 'cq';
    const { tableHeaders } = this.props;

    return (
      <tr className="br-table-row" id={`${prefix}-tr-${rowIndex + 1}`} key={rowIndex}>
        {tableHeaders.keySeq().map((header, colIndex) => {
          const id = `${prefix}-td-${rowIndex + 1}-${colIndex + 1}`;
          return (
            <td id={id} key={id} className={`br-table-cell ${header}-column`}>
              {rowData.get(header)}
            </td>
          );
        })}
      </tr>
    );
  };

  makeRowHeaders = (header: string) => {
    const isWellHeader = header === 'well';
    const className = classnames(
      'sort-column',
      this.getSortClassName(header),
      isWellHeader ? this.getSortClassName('wellCol') : undefined
    );
    const id = `cq-table-header:${header}`;
    return (
      <TableHeader
        key={id}
        id={id}
        header={header}
        className={className}
        onClick={this.toggleSortHeader}
      >
        {header}
        {isWellHeader && this.renderWellContent()}
      </TableHeader>
    );
  };

  handleUpdateSortOrder = (key: string) => {
    const { sortOrder, updateSortOrder } = this.props;
    let updatedSortOrder;
    if (!sortOrder.has(key)) {
      updatedSortOrder = sortOrder.set(
        key,
        Map({
          key,
          isAscending: true
        })
      );
    } else if (sortOrder.getIn([key, 'isAscending'])) {
      updatedSortOrder = sortOrder.delete(key).set(key, Map({ key, isAscending: false }));
    } else {
      updatedSortOrder = sortOrder.delete(key);
    }
    updateSortOrder(updatedSortOrder);
  };

  handleUpdateWellSortOrder = () => {
    /*
        Sort order as user clicks on well header:
        0. type: row     isAscending: true // page load
        1. type: row     isAscending: false
        2. type: column  isAscending: true
        3. type: column  isAscending: false
        4. NONE <sort item is deleted>
        Back to 0...
        ...
      */

    const wellSortOrderList = fromJS([
      { key: 'well', isAscending: true },
      { key: 'well', isAscending: false },
      { key: 'wellCol', isAscending: true },
      { key: 'wellCol', isAscending: false }
    ]);

    const nextWellSortOrder = wellSortOrderList.get(this.wellSortIndex);
    const { sortOrder, updateSortOrder } = this.props;
    let newSortOrder = sortOrder.delete('well').delete('wellCol');
    if (nextWellSortOrder) {
      newSortOrder = newSortOrder.set(nextWellSortOrder.get('key'), nextWellSortOrder);
      this.wellSortIndex += 1;
    } else {
      this.wellSortIndex = 0;
    }
    updateSortOrder(newSortOrder);
  };

  toggleSortHeader = (event: React.SyntheticEvent<HTMLButtonElement>) => {
    const sortColumn = event.currentTarget.getAttribute('sort-key') || '';

    if (sortColumn === 'well') {
      this.handleUpdateWellSortOrder();
    } else {
      this.handleUpdateSortOrder(sortColumn);
    }
  };

  renderWellContent = () => {
    const { sortOrder } = this.props;
    const wellSortItem = sortOrder.get('well') || sortOrder.get('wellCol');
    return wellSortItem && (wellSortItem.get('key') === 'well' ? ' (by row)' : ' (by col) ');
  };

  render() {
    const { tableHeaders, sortedData, tableName } = this.props;

    return (
      <>
        <div className="table-title">{tableName}</div>
        <div className="table-wrapper">
          <table>
            <thead>
              <tr>{tableHeaders.valueSeq().map(header => this.makeRowHeaders(header))}</tr>
            </thead>
            <tbody className="table-content">
              {sortedData.map((rowData, index) => this.makeRow(rowData, index))}
            </tbody>
          </table>
        </div>
      </>
    );
  }
}
