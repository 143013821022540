import * as React from 'react';
import { ControlLabel, FormGroup, HelpBlock } from 'react-bootstrap';
import { ReactNode } from 'react';
import { ValidationState } from './fieldValidationState';

export type Props = {
  id: string;
  label: string;
  subLabel?: string;
  validationState?: ValidationState;
  withError?: boolean;
  error?: string;
  children: ReactNode;

  /**
   * The component has a container div to which the customLayoutStyle will be applied
   * The container div has two child divs
   * div.label-container has the label and the subLabel
   * div.control-container has the children and the error block
   * The customLayoutStyle will need to provide layout for .label-container and .control-container
   * ex: to get the label and control in the same row
   * .reaction-vol-group {
   *     display: flex;
   *     align-items: center;
   *     width: 205px;
   *     .label-container {
   *         flex: 1 0;
   *     }
   *     .control-container {
   *         flex: 0 0 60px;
   *     }
   * }
   */
  customLayoutStyle?: string;
};

function FieldGroup(props: Props) {
  const { id, label, subLabel, validationState, error, children, customLayoutStyle, withError } =
    props;

  return (
    <FormGroup bsClass="form-control-group" controlId={id} validationState={validationState}>
      <div className={customLayoutStyle || 'group-container'}>
        <div className="label-container">
          <ControlLabel bsClass="heading-title" id={`${id}-label`}>
            {label}
            <span>{subLabel}</span>
          </ControlLabel>
        </div>
        <div className="control-container">
          {children}
          {withError && (
            <HelpBlock bsClass="error-block" id={`${id}-error`}>
              {error}
            </HelpBlock>
          )}
        </div>
      </div>
    </FormGroup>
  );
}

FieldGroup.defaultProps = {
  subLabel: '',
  validationState: null,
  error: '',
  customLayoutStyle: undefined,
  withError: true
};

export default FieldGroup;
