import { ProjectId } from '../../../frontend-common-libs/src/common/project-management-types';
import ProjectProtocols from './ProjectProtocols';
import BaseViewModel from '../../../frontend-common-libs/src/common/mvvm/BaseViewModel';

export default class ProtocolListRepository extends BaseViewModel {
  private loadedProjectProtocols: Record<ProjectId, ProjectProtocols> = {};

  private constructor() {
    super();
    this.clear();
  }

  private static _instance: ProtocolListRepository | undefined = undefined;

  public static get instance(): ProtocolListRepository {
    if (this._instance == null) this._instance = new ProtocolListRepository();
    return this._instance;
  }

  public isLoaded(projectId: ProjectId): boolean {
    return this.loadedProjectProtocols[projectId] != null;
  }

  public getProjectProtocols(projectId: ProjectId): ProjectProtocols {
    if (this.isLoaded(projectId)) return this.loadedProjectProtocols[projectId];
    const projectProtocols = new ProjectProtocols(projectId, this.fireStateChanged);
    this.loadedProjectProtocols[projectId] = projectProtocols;
    return projectProtocols;
  }

  public clear() {
    this.loadedProjectProtocols = {};
    this.stateChanged.clear();
  }
}
