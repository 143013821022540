import UserAnalyticsClient from '../frontend-common-libs/src/user-analytics/UserAnalyticsClient';

export default class FileUploadTrackingEvent {
  private readonly filename: string;

  public constructor(filename: string) {
    this.filename = filename;
  }

  public track() {
    const params = {
      fileNameExtension: this.filename.split('.').pop()
    };

    UserAnalyticsClient.getInstance().track('file-upload-started', params);
  }
}
