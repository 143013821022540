import * as React from 'react';
import FieldGroup from './FieldGroup';
import FloatField from './FloatField';
import { ValidationState } from './fieldValidationState';

export type Props = {
  /** id for the input field */
  id: string;

  /** name for the input field */
  name: string;

  /** label for the field */
  label: string;

  /** units for the field */
  subLabel?: string;

  /** initial value of the field */
  value?: number;

  /** validation state for the field "success" | "warning" | "error" | null */
  validationState?: string | null;

  /** error message to be shown for the field */
  error?: string;

  /** whether negative values should be allowed */
  allowNegative: boolean;

  /** callback for when the value has been changed */
  onChange: (name: string, value?: number | string) => void;
  onBlur: (name: string, value?: number | string) => void;
};

function FloatFieldGroup(props: Props) {
  const { id, label, subLabel, value, validationState, error, allowNegative, ...remaining } = props;

  return (
    <FieldGroup
      id={`${id}-group`}
      label={label}
      subLabel={subLabel}
      validationState={validationState as ValidationState}
      error={error}
    >
      <FloatField defaultValue={value} allowNegative={allowNegative} {...remaining} />
    </FieldGroup>
  );
}

FloatFieldGroup.defaultProps = {
  subLabel: '',
  value: undefined,
  validationState: null,
  error: '',
  allowNegative: true
};

export default FloatFieldGroup;
