import * as React from 'react';
import { FormControl } from 'react-bootstrap';
import { toScientificNotation } from '../../common/numbers';

export type Props = {
  /** id for the input field */
  id?: string;

  /** name for the input field */
  name: string;

  /** initial value of the field. An integer value is expected.
   * If string it has to be a partial number or a number larger than Infinity */
  defaultValue?: number | string;

  /** whether to allow negative values as input */
  allowNegative: boolean;

  /** allow scientific notation to be entered into field */
  allowScientificNotation: boolean;

  /** only display value in scientific notation */
  displayInScientificNotationOnly: boolean;

  /** max number of digits after the decimal point */
  maxDecimalLength: number;

  /** callback for when the value has been changed */
  onChange: (name: string, value?: number | string) => void;

  /** callback for when the input field loses focus */
  onBlur: (name: string, value?: number | string) => void;

  /** callback for when the input get focus */
  onFocus?: (event: React.SyntheticEvent<HTMLInputElement>) => void;
  disabled?: boolean;
};

/**
 *  The FloatField component is a text input that allows users to input floating point numbers up to the maxDecimalLength defined in the props (defaults to 1).
 *  Non-numeric characters are prevented from being entered except (hyphen ('-') when allowNegative is true, decimal (.), and 'e' or 'E' when allowScientificNotation is true)
 *  Repeated or ill-placed hyphen and decimal characters are prevented
 *  All input after the first decimal place is stripped
 *
 *  onChange callback is called with
 *    onChange of the input
 *      undefined - when the user wishes to unset the value by making the input blank (or)
 *      string - with the cleaned user input
 *    onBlur of the input
 *      finite number - the numeric representation of the user input
 *      string - if the number is beyond Number.Infinity
 *      undefined - if the entry is a partial number ex: ., -, -., e3
 */
class FloatField extends React.Component<Props> {
  static countDots(str: string) {
    const dots = str.match(/\./g);
    if (dots) {
      return dots.length;
    }
    return 0;
  }

  static defaultProps = {
    defaultValue: undefined,
    id: undefined,
    allowNegative: true,
    maxDecimalLength: 1,
    allowScientificNotation: false,
    displayInScientificNotationOnly: false,
    onFocus: undefined,
    disabled: false
  };

  onChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
    const { onChange } = this.props;
    const currentTarget = e.currentTarget as any;
    const value = this.cleanupFloat(currentTarget.value);
    onChange(currentTarget.name, value === '' ? undefined : value);
  };

  onBlur = (e: React.SyntheticEvent<HTMLInputElement>) => {
    const { defaultValue, onBlur } = this.props;
    const { name, value } = e.currentTarget;
    const floatValue = parseFloat(value);
    let confirmedValue;
    if (Number.isNaN(floatValue)) {
      confirmedValue = undefined;
    } else if (Number.isFinite(floatValue)) {
      confirmedValue = floatValue;
    } else {
      confirmedValue = value;
    }

    if (confirmedValue !== defaultValue) {
      onBlur(name, confirmedValue);
    }
  };

  onFocus = (e: React.SyntheticEvent<HTMLInputElement>) => {
    const { onFocus } = this.props;
    if (onFocus) onFocus(e);
  };

  cleanupFloat = (value: string) => {
    if (value.length === 0) return '';
    const { allowNegative, allowScientificNotation, maxDecimalLength } = this.props;
    let cleanValue;

    // eslint-disable-next-line prefer-const
    let [coefficient, exponent] = value.toLowerCase().split('e');
    // remove illegal characters
    coefficient = coefficient.replace(/[^\d.-]/g, '');
    if (allowNegative) {
      // remove any dashes not in the first character
      coefficient = coefficient.substring(0, 1) + coefficient.substring(1).replace(/[-]/g, '');
    } else {
      // remove ALL dashes
      coefficient = coefficient.replace(/[-]/g, '');
    }

    // confirm coefficient is in correct format including limiting to one decimal point
    const coefficientRegexString = `^${allowNegative ? '([-])?' : ''}(\\d*(\\.\\d*)?)`;
    const coefficientRegex = new RegExp(coefficientRegexString);
    const cGroups = coefficient.length > 0 && coefficientRegex.exec(coefficient);
    cleanValue = (cGroups && cGroups[0]) || undefined;
    if (cleanValue) {
      const [whole, decimal] = cleanValue.split('.');
      // enforce maxDecimalLength
      if (decimal && decimal.length > maxDecimalLength) {
        cleanValue = `${whole}.${decimal.slice(0, maxDecimalLength)}`;
      }
    }

    if (allowScientificNotation) {
      cleanValue = this.cleanupScientificNotation(value, exponent, cleanValue);
    }
    return cleanValue;
  };

  cleanupScientificNotation = (
    value: string,
    exponentParsed: string,
    cleanValue: string | undefined
  ) => {
    let exponent = exponentParsed;
    // determine if value is in scientific notation by checking for e/E
    const eGroup = /[eE]/.exec(value); // get e from original value to preserve case
    const e = (eGroup && eGroup[0]) || undefined;
    if (e) {
      // just because there is an e does not mean there is a value after it, ie: e vs e+10
      if (exponent) {
        // if there is a value after e (the exponent, ie: +10 in e+10), clean it up
        // remove illegal characters
        exponent = exponent.replace(/[^\d\-+]/g, '');
        // remove any - or + not in the first character
        exponent = exponent.substring(0, 1) + exponent.substring(1).replace(/[-+]/g, '');
      }
      const exponentRegex = /^([+-])?(\d+)?/;
      const expGroups = exponentRegex.exec(exponent);
      return `${cleanValue || ''}${e}${(expGroups && expGroups[0]) || ''}`;
    }
    return cleanValue;
  };

  render() {
    const {
      defaultValue,
      allowNegative,
      onChange,
      maxDecimalLength,
      allowScientificNotation,
      displayInScientificNotationOnly,
      disabled,
      ...remaining
    } = this.props;
    let value;
    if (defaultValue === undefined) {
      value = '';
    } else if (typeof defaultValue !== 'number' || !Number.isFinite(defaultValue)) {
      value = defaultValue;
    } else if (displayInScientificNotationOnly) {
      value = toScientificNotation(defaultValue);
    } else {
      const valueStr = defaultValue.toString();
      let afterDec = '';
      if (FloatField.countDots(valueStr) > 0) {
        afterDec = valueStr.substring(valueStr.indexOf('.') + 1);
      }
      value =
        afterDec.length > maxDecimalLength ? defaultValue.toFixed(maxDecimalLength) : valueStr;
    }
    return (
      // @ts-ignore
      <FormControl
        value={value}
        type="text"
        {...remaining}
        onChange={this.onChange}
        onBlur={this.onBlur}
        onFocus={this.onFocus}
        disabled={disabled}
      />
    );
  }
}

export default FloatField;
