import React, { CSSProperties } from 'react';
import HorizontalLine from '../common/HorizontalLine';
import SectionHeader from '../common/SectionHeader';
import { Colors, getRubikFontWeight } from '../common/styles';
import RunDetailsParser from '../sections-parsing/RunDetailsParser';

type Props = { rows: RunDetailsParser };

export default function RunDetails(props: Props) {
  const rowStyle = { height: '30px', fontSize: '14px', color: Colors.dataText };
  const headerStyle = {
    ...rowStyle,
    lineHeight: 2.14,
    fontWeight: getRubikFontWeight()
  };
  const dataStyle = {
    ...rowStyle,
    lineHeight: 2.21,
    fontWeight: 'normal',
    wordBreak: 'break-word',
    verticalAlign: 'top'
  } as CSSProperties;
  const marginDataStyle = { ...dataStyle, paddingLeft: '25px' };
  const { rows } = props;
  const keyId = 'run-details';
  return (
    <div id={keyId}>
      <SectionHeader text="Run Details" />
      <table style={{ marginLeft: '24px', marginTop: '8px' }}>
        <thead>
          <tr style={{ borderBottom: `2px solid ${Colors.rowBorder}` }}>
            <th id={`${keyId}-header-repeat`} style={{ ...headerStyle, width: '119px' }}>
              Repeat
            </th>
            <th id={`${keyId}-header-step`} style={{ ...headerStyle, width: '115px' }}>
              Step #
            </th>
            <th id={`${keyId}-header-temp`} style={{ ...headerStyle, width: '151px' }}>
              Temp
            </th>
            <th id={`${keyId}-header-time`} style={{ ...headerStyle, width: '131px' }}>
              Time
            </th>
            <th id={`${keyId}-header-details`} style={{ ...headerStyle, width: '770px' }}>
              Details
            </th>
          </tr>
        </thead>
        <tbody>
          {rows.runDetails.map((row: any, index: any) => (
            <tr
              id={`${keyId}-body-index-${index}`}
              key={index}
              style={{
                borderBottom: `1px solid ${Colors.rowBorder}`,
                backgroundColor: index % 2 === 0 ? Colors.rowBackground : 'white'
              }}
            >
              <td style={marginDataStyle}>{row[0]}</td>
              <td style={marginDataStyle}>{row[1]}</td>
              <td style={dataStyle}>{row[2]}</td>
              <td style={dataStyle}>{row[3]}</td>
              <td style={dataStyle}>{row[4]}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <HorizontalLine styleOverride={{ marginTop: '26px' }} />
    </div>
  );
}
