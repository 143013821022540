export function toFixed(value: number): string {
  let x = value.toString();
  // handle 0 and check if value is in scientific notation
  if (value === 0 || value.toString().split('e').length === 1) return x;
  // handle scientific notation conversion
  if (Math.abs(value) < 1.0) {
    const e = parseInt(value.toString().split('e-')[1], 10);
    x = Number(value).toFixed(e);
  } else {
    let e = parseInt(value.toString().split('+')[1], 10);
    // this needs to be updated to better handle rounding error... ie: 1e+29
    if (e > 20) {
      e -= 20;
      x = (value / 10 ** e).toString();
      x += new Array(e + 1).join('0');
    }
    // else <20 will not be in scientific notation so returned above
  }
  return x;
}

export function toScientificNotation(number: number): string | null | undefined {
  // .toExponential(fractionDigits)
  // fractionDigits is the number of digits after the decimal,
  // Hardcoded to 2 for now since that is the standard display format
  // Defaults to as many digits as necessary to specify the number
  const snValue = number.toExponential(2);
  const snGroups = /^(-?)(\d{1,32}.\d{1,32})([e])([+|-])(\d{1,32})/.exec(snValue);
  if (snGroups) {
    const [, sign, coefficient, , expSign] = snGroups;
    let exp = snGroups[5];
    // Maestro formattings
    if (exp.length === 1) exp = `0${exp}`; // min 2 digit exponent
    return `${sign}${coefficient}E${expSign}${exp}`;
  }
  return undefined;
}

export function parseFloatIfNot(value?: number | string): number | typeof undefined {
  if (value === undefined || typeof value === 'number') return value;
  return parseFloat(value);
}

export function toFixedDecimals(number: number, numberOfDecimals: number): string {
  const [integer, decimal] = number.toString().split('.');
  if (decimal && decimal.length > numberOfDecimals) {
    const decimalArray = decimal.split('');
    decimalArray.splice(numberOfDecimals + 1, 0, '.');
    decimalArray.unshift(integer);
    const x = parseInt(decimalArray.join(''), 10);
    const r = x % 10;
    let y = x;
    if (r >= 5) {
      y = x + 1;
    }
    const d = 10 ** (numberOfDecimals + 1);
    return (y / d).toFixed(numberOfDecimals);
  }
  // @ts-ignore
  return parseFloat(number).toFixed(numberOfDecimals);
}
