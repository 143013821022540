import { Map } from 'immutable';
import { CognitoUserAttribute } from 'amazon-cognito-identity-js';
import {
  AUTH_USER,
  AUTH_ERROR,
  UNAUTH_USER,
  USER_SESSION_FOUND,
  USER_SESSION_NOT_FOUND,
  AUTH_IN_PROGRESS,
  FORGET_PWD_ERROR,
  FORGET_PWD_CONFIRM,
  LOAD_PROFILE,
  SUBMIT_PROFILE,
  USER_NOT_VERIFIED_ERROR,
  AuthActionType
} from './auth_types';
import {
  getCurrentUser,
  getUserSession,
  clearUser,
  forgotPassword,
  getUserProfile,
  setUserProfile,
  authenticateUser,
  updateCredentials
} from '../../frontend-common-libs/src/utils/authUtils';
import IOTConnection from '../../iot/iot-connection';
import { instrumentActions } from '../../instruments';
import { Dispatch, ThunkAction } from '../../types';
import UserAnalyticsClient from '../../frontend-common-libs/src/user-analytics/UserAnalyticsClient';
import { handleLogin, handleLogout } from '../../frontend-common-libs/src/components/feature_flags';
import CurrentUserBackupTask from './tasks/CurrentUserBackupTask';
import LogoutEvent from '../../frontend-common-libs/src/system-event/LogoutEvent';

export type ForgotInfo = {
  email: string;
};

export type LoginInfo = {
  email: string;
  password: string;
};

export type UserProfile = {
  [index: string]: string;
  email: string;
  name: string;
  institution: string;
  lab: string;
};

type AuthProcessType = 'login' | 'reg' | 'forgot' | 'reset';
type AuthErrorField = 'name' | 'email' | 'pwd';

type CognitoError = {
  code: string;
  message: string;
};

function errorPayload(section: AuthProcessType, field: AuthErrorField, msg: string) {
  const payload = Map();
  return payload.setIn([section, field, 'msg'], msg);
}

const forgotPwdErrLookup: Record<string, string> = {
  NotAuthorizedException: 'Invalid email address',
  ResourceNotFoundException: 'Invalid email address',
  InvalidParameterException: 'Unverified email address'
};

export function forgotPwd(
  forgotInfo: ForgotInfo
): ThunkAction<AuthActionType, string | Map<unknown, unknown>> {
  return async (dispatch: Dispatch<AuthActionType, string | Map<unknown, unknown>>) => {
    try {
      const email = await forgotPassword(forgotInfo.email);
      dispatch({
        type: FORGET_PWD_CONFIRM,
        payload: email
      });
    } catch (err) {
      const cognitoError = err as CognitoError;
      let msg = forgotPwdErrLookup[cognitoError.code];
      if (!msg) {
        msg = cognitoError.message;
      }
      dispatch({
        type: FORGET_PWD_ERROR,
        payload: errorPayload('forgot', 'email', msg)
      });
    }
  };
}

const loginUserErrorLookup: Record<string, [AuthErrorField, string]> = {
  PasswordResetRequiredException: ['pwd', 'Password must be reset'],
  NotAuthorizedException: ['email', 'Incorrect username or password'],
  ResourceNotFoundException: ['email', 'Incorrect username or password']
};

export function loginUser(
  loginInfo: LoginInfo,
  isNewUser: boolean
): ThunkAction<AuthActionType, string | Map<unknown, unknown>> {
  return async (dispatch: Dispatch<AuthActionType, string | Map<unknown, unknown>>) => {
    dispatch({
      type: AUTH_IN_PROGRESS,
      payload: ''
    });

    try {
      const email = await authenticateUser(loginInfo.email, loginInfo.password);
      const credentials = await updateCredentials();
      UserAnalyticsClient.getInstance().handleLogin(credentials.identityId, email, isNewUser);
      await handleLogin(email);

      const currentUserBackupTask = new CurrentUserBackupTask();
      const userSubId = await currentUserBackupTask.getUserSubId();
      currentUserBackupTask.execute();
      dispatch({
        type: AUTH_USER,
        // @ts-ignore
        payload: { email, userSubId }
      });
    } catch (err) {
      const cognitoError = err as CognitoError;
      let errVals = loginUserErrorLookup[cognitoError.code];
      if (!errVals) {
        errVals = ['email', cognitoError.message];
      }

      const isAccountUnverified = cognitoError.code === 'UserNotConfirmedException';
      const dispatchBody = isAccountUnverified
        ? {
            type: USER_NOT_VERIFIED_ERROR,
            payload: Map({ username: loginInfo.email, isNewUser })
          }
        : {
            type: AUTH_ERROR,
            payload: errorPayload('login', errVals[0], errVals[1])
          };
      dispatch(dispatchBody);
    }
  };
}

export function loginExistingUser(
  loginInfo: LoginInfo
): ThunkAction<AuthActionType, string | Map<unknown, unknown>> {
  return (dispatch: Dispatch<AuthActionType, string | Map<unknown, unknown>>) =>
    dispatch(loginUser(loginInfo, false));
}

function deleteCognitoLocalStorage() {
  const localStorageKeys = Object.keys(window.localStorage);
  const cognitoKeys = localStorageKeys.filter(key => key.toLowerCase().includes('cognito'));
  cognitoKeys.forEach(cognitoKey => window.localStorage.removeItem(cognitoKey));
}

export function logoutUser(): ThunkAction<AuthActionType, string> {
  return async (dispatch: Dispatch<AuthActionType, string>) => {
    const currentUser = getCurrentUser();
    if (currentUser != null) {
      currentUser.signOut();
    }
    UserAnalyticsClient.getInstance().handleLogout();
    clearUser();
    instrumentActions.clearInstrumentTimeouts();
    IOTConnection.stop();
    await handleLogout();
    deleteCognitoLocalStorage();
    dispatch({
      type: UNAUTH_USER,
      payload: ''
    });
    LogoutEvent.notify();
  };
}

export function loadUserSession(): ThunkAction<AuthActionType, string> {
  return async (dispatch: Dispatch<AuthActionType, string>) => {
    try {
      const { email } = await getUserSession();

      const credentials = await updateCredentials();
      UserAnalyticsClient.getInstance().handleLogin(credentials.identityId, email);

      await handleLogin(email);

      const currentUserBackupTask = new CurrentUserBackupTask();
      const userSubId = await currentUserBackupTask.getUserSubId();
      currentUserBackupTask.execute();

      // @ts-ignore
      dispatch({
        type: USER_SESSION_FOUND,
        payload: { email, userSubId }
      });
    } catch (err) {
      const message = err instanceof Error ? err.message : 'Unknown error';
      dispatch({
        type: USER_SESSION_NOT_FOUND,
        payload: message
      });
    }
  };
}

const profileToCognitoAttributeMap: UserProfile = {
  email: 'email',
  name: 'name',
  institution: 'custom:Attribute1',
  lab: 'custom:Attribute2'
};

function getProfileItemFromCognitoAttribute(
  attributes: Array<CognitoUserAttribute>,
  name: string
): string {
  const found = attributes.findIndex(
    attribute => attribute.getName() === profileToCognitoAttributeMap[name]
  );
  return found !== -1 ? attributes[found].getValue() : '';
}

export function convertCognitoAttributesArrayToUserProfile(
  attributes: Array<CognitoUserAttribute>
): UserProfile {
  const profile: UserProfile = { email: '', name: '', lab: '', institution: '' };
  Object.keys(profileToCognitoAttributeMap).forEach(key => {
    profile[key] = getProfileItemFromCognitoAttribute(attributes, key);
  });
  return profile;
}

export function loadUserProfile(): ThunkAction<AuthActionType, UserProfile> {
  return async (dispatch: Dispatch<AuthActionType, UserProfile>) => {
    const session = await getUserSession();
    const attributes = await getUserProfile(session.currentUser);
    dispatch({
      type: LOAD_PROFILE,
      payload: convertCognitoAttributesArrayToUserProfile(attributes)
    });
  };
}

function convertProfileToUserAttributesList(attributes: UserProfile): Array<CognitoUserAttribute> {
  const attributesArray: Array<CognitoUserAttribute> = [];
  Object.keys(profileToCognitoAttributeMap).forEach(key => {
    attributesArray.push(
      new CognitoUserAttribute({
        Name: profileToCognitoAttributeMap[key],
        Value: attributes[key]
      })
    );
  });
  return attributesArray;
}

export function submitUserProfile(
  attributes: UserProfile
): ThunkAction<AuthActionType, UserProfile> {
  return async (dispatch: Dispatch<AuthActionType, UserProfile>) => {
    const session = await getUserSession();
    await setUserProfile(session.currentUser, convertProfileToUserAttributesList(attributes));
    dispatch({
      type: SUBMIT_PROFILE,
      payload: attributes
    });
  };
}
