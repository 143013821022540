import SystemEvent from './SystemEvent';
import SystemEventSubscriber from './SystemEventSubscriber';
import DuplicateSubscriberError from './DuplicateSubscriberError';

export default class SystemEventBroker {
  private static singleton: SystemEventBroker | undefined;

  private subscribers: SystemEventSubscriber[] = [];

  public static getInstance(): SystemEventBroker {
    if (!SystemEventBroker.singleton) {
      SystemEventBroker.singleton = new SystemEventBroker();
    }

    return SystemEventBroker.singleton;
  }

  public subscribe(subscriber: SystemEventSubscriber): void {
    if (this.subscribers.includes(subscriber)) {
      throw new DuplicateSubscriberError(
        'Duplicate subscriber. A subscriber can only be subscribed once.'
      );
    }

    this.subscribers.push(subscriber);
  }

  public unsubscribe(subscriber: SystemEventSubscriber): void {
    const index = this.subscribers.indexOf(subscriber);
    if (index > -1) {
      this.subscribers.splice(index, 1);
    }
  }

  public notify(event: SystemEvent): void {
    this.subscribers.forEach(subscriber => {
      subscriber.notify(event);
    });
  }
}
