import { Set } from 'immutable';

// Increment to next character
function nextLetter(l: string) {
  const charCode = l.charCodeAt(0);

  return String.fromCharCode(charCode + 1);
}

// Decrement to previous character
function previousLetter(l: string) {
  const charCode = l.charCodeAt(0);

  return String.fromCharCode(charCode - 1);
}

// Checks if a well has a row adjacent cell
// will apply appropriate borders in each case
function getRowStyle(well: string, selectedWells: Set<string>) {
  const rightWell = `${well[0]}${parseInt(well.slice(1), 10) + 1}`;
  const leftWell = `${well[0]}${parseInt(well.slice(1), 10) - 1}`;
  const rowBorderStyle = [];

  if (!selectedWells.has(leftWell)) {
    rowBorderStyle.push('add-left-selection-border');
  }

  if (!selectedWells.has(rightWell)) {
    rowBorderStyle.push('add-right-selection-border');
  }

  return rowBorderStyle;
}

// Checks if a well has column adjacent cells
// will apply appropriate borders in each case
function getColumnStyle(well: string, selectedWells: Set<string>) {
  const topWell = `${previousLetter(well[0])}${well.slice(1)}`;
  const bottomWell = `${nextLetter(well[0])}${well.slice(1)}`;
  const colBorderStyle = [];

  if (!selectedWells.has(topWell)) {
    colBorderStyle.push('add-top-selection-border');
  }

  if (!selectedWells.has(bottomWell)) {
    colBorderStyle.push('add-bottom-selection-border');
  }

  return colBorderStyle;
}

export default function applySelectionOutline(well: string, selectedWells: Set<string>) {
  const colStyle = getColumnStyle(well, selectedWells);
  const rowStyle = getRowStyle(well, selectedWells);

  return [colStyle.join(' '), rowStyle.join(' ')];
}
