export default class InstrumentTrackingToggle {
  private readonly _enabled;

  public constructor(instrumentId: string) {
    this._enabled = !instrumentId.startsWith('sim-');
  }

  public isEnabled(): boolean {
    return this._enabled;
  }
}
