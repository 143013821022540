import React from 'react';
import classnames from 'classnames';

export interface Props {
  children: any;
  className?: string;
  id: string;
  href: string;
  target?: string;
  rel?: string;
  title?: string;
}

function ExternalAnchorLink({ className, children, href, target, id, rel, title }: Props) {
  return (
    <a
      className={classnames('new-btn link', className)}
      href={href}
      target={target}
      id={id}
      rel={rel}
      title={title}
    >
      {children}
    </a>
  );
}

ExternalAnchorLink.defaultProps = {
  className: undefined
};

export default ExternalAnchorLink;
