import { updateCpcrRun } from '../api/runs';
import { getSelectedProjectId } from '../../../project-management';
import {
  CbWithToken,
  DispatchType
} from '../../../organization-management/actions/WithRefreshToken';
import { GetState } from '../../../types';
import { withRefreshProjectToken } from '../../../project-management/actions/with-refresh-project-token';

// eslint-disable-next-line import/prefer-default-export
export function updateRun(entityId: string, body: any) {
  return async (dispatch: DispatchType, getState: GetState) => {
    const projectId = getSelectedProjectId(getState());
    const updateRunCb: CbWithToken = async (projectAccessToken: string) => {
      return updateCpcrRun(entityId, body, projectAccessToken);
    };
    return withRefreshProjectToken(dispatch, getState, projectId, updateRunCb);
  };
}
