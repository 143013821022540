import React, { Component, ReactNode } from 'react';

type Props = {
  height: number;
  width: number;
  index: number;
  xPos: number;
  yPos: number;
  isSelected: boolean;
  setSelectedStepIndex?: (...args: Array<any>) => any;
  children: ReactNode;
};

export const HEADER_HEIGHT = 30;

class StepBox extends Component<Props> {
  static defaultProps = {
    setSelectedStepIndex: undefined
  };

  selectIndex = () => {
    const { setSelectedStepIndex, index } = this.props;
    if (setSelectedStepIndex) {
      setSelectedStepIndex(index);
    }
  };

  render() {
    const { height, width, index, xPos, yPos, isSelected, children } = this.props;

    return (
      <g
        id={`graph-stepbox-${index + 1}`}
        className="graph-box"
        transform={`translate(${xPos} ${yPos})`}
      >
        {children}
        <rect
          x="0"
          y="0"
          width={width}
          height={HEADER_HEIGHT}
          className={isSelected ? 'step-box-num active' : 'step-box-num'}
        />
        <rect
          x="0"
          y={isSelected ? '1' : '0'}
          width={width}
          height={isSelected ? height - 1 : height}
          className={isSelected ? 'step-box active' : 'step-box'}
          onClick={isSelected ? undefined : this.selectIndex}
        />
        <text
          id={`graph-stepnum-${index + 1}`}
          x={width / 2}
          y={HEADER_HEIGHT / 2}
          className="step-num-text"
          alignmentBaseline="middle"
        >
          {index + 1}
        </text>
      </g>
    );
  }
}

export default StepBox;
