import React from 'react';
import '../../styles/project-role.scss';
import { ProjectRoles } from '@biorad-lsg-tsc/organization';
import { projectRolesDisplayNames } from '@biorad-lsg-tsc/organization/lib/roles-and-permissions';
import {
  DEFAULT_PROJECT_ID,
  ProjectId
} from '../../../frontend-common-libs/src/common/project-management-types';
import Spinner from '../../../frontend-common-libs/src/components/common/Spinner';
import { projectRoleIcon } from '../../selectors/roles';

export type Props = {
  selectedProjectId: ProjectId;
  isTokenLoading: boolean;
  projectRole?: ProjectRoles;
  id?: string;
};

export default function ProjectRole(props: Readonly<Props>) {
  const { selectedProjectId, isTokenLoading, projectRole, id } = props;
  if (selectedProjectId === DEFAULT_PROJECT_ID) return null;
  if (isTokenLoading) return <Spinner className="fa-fw loading-spinner" id={id} />;
  if (!projectRole) return null;
  const roleName = projectRolesDisplayNames[projectRole];
  const icon = projectRoleIcon[projectRole];
  if (!icon) return null;
  return <img src={icon} className="role-image" alt="role" title={roleName} id={id} />;
}
