import { fromJS, Map } from 'immutable';

// @ts-ignore
const colors: Map<string, any> = fromJS({
  red: {
    '1': { primary: '#ad1457', secondary: '#ffe0ed' },
    '2': { primary: '#e91e63', secondary: '#ffdfea' },
    '3': { primary: '#ff4081', secondary: '#ffe4ed' },
    '4': { primary: '#ff82ad', secondary: '#ffeff4' },
    '5': { primary: '#ffabc7', secondary: '#fff4f8' }
  },
  orange: {
    '1': { primary: '#f44336', secondary: '#ffe8e6' },
    '2': { primary: '#ff5252', secondary: '#ffeeee' },
    '3': { primary: '#ff5722', secondary: '#fff0eb' },
    '4': { primary: '#fb8c69', secondary: '#ffe6de' },
    '5': { primary: '#ffa88c', secondary: '#fff5f1' },
    '6': { primary: '#ff9800', secondary: '#fff8ee' }
  },
  dark_orange: {
    '1': { primary: '#c62828', secondary: '#ffe4e4' },
    '2': { primary: '#d84315', secondary: '#fff1ed' },
    '3': { primary: '#ef6c00', secondary: '#fff3e9' }
  },
  yellow: {
    '1': { primary: '#ffc107', secondary: '#fffaec' }
  },
  green: {
    '1': { primary: '#00695c', secondary: '#f0fffd' },
    '2': { primary: '#2e7d32', secondary: '#f2fff2' },
    '3': { primary: '#558b2f', secondary: '#f5ffee' },
    '4': { primary: '#4caf50', secondary: '#f7fff7' },
    '5': { primary: '#8bc34a', secondary: '#fbfff5' }
  },
  aqua: {
    '1': { primary: '#00838f', secondary: '#eefeff' },
    '2': { primary: '#009688', secondary: '#f4fffe' },
    '3': { primary: '#12b6a6', secondary: '#f4fffe' },
    '4': { primary: '#45d2c5', secondary: '#f2fffe' },
    '5': { primary: '#6fe8dd', secondary: '#f2fffe' }
  },
  blue: {
    '1': { primary: '#1565c0', secondary: '#eef6ff' },
    '2': { primary: '#0277bd', secondary: '#edf8ff' },
    '3': { primary: '#2196f3', secondary: '#f0f8ff' },
    '4': { primary: '#03a9f4', secondary: '#eaf8ff' },
    '5': { primary: '#69c9f4', secondary: '#f0faff' }
  },
  dark_blue: {
    '1': { primary: '#283593', secondary: '#f0f2ff' },
    '2': { primary: '#3f51b5', secondary: '#eff2ff' },
    '3': { primary: '#536dfe', secondary: '#eff2ff' },
    '4': { primary: '#448aff', secondary: '#edf4ff' },
    '5': { primary: '#78aaff', secondary: '#eef4ff' }
  },
  purple: {
    '1': { primary: '#4527a0', secondary: '#f6f2ff' },
    '2': { primary: '#673ab7', secondary: '#f6f0ff' },
    '3': { primary: '#7c4dff', secondary: '#f4efff' },
    '4': { primary: '#a787ff', secondary: '#f3efff' },
    '5': { primary: '#baa1ff', secondary: '#f7f4ff' }
  },
  violet: {
    '1': { primary: '#6a1b9a', secondary: '#faf1ff' },
    '2': { primary: '#9c28b0', secondary: '#fdf2ff' },
    '3': { primary: '#bd47d1', secondary: '#fdf4ff' },
    '4': { primary: '#e040fb', secondary: '#fef7ff' },
    '5': { primary: '#ed85ff', secondary: '#fdf2ff' }
  }
});

const customColorOrder = [
  'red',
  'aqua',
  'blue',
  'violet',
  'orange',
  'dark_blue',
  'dark_orange',
  'purple',
  'green'
];

const colorList = (function getColorsByOrder() {
  const colorPathsInOrder: string[][] = [];
  for (let i = 1; i <= 5; i += 1) {
    customColorOrder.forEach(color => {
      if (color === 'dark_orange' && i > 3) {
        colorPathsInOrder.push(i === 4 ? ['orange', '6'] : ['yellow', '1']);
      } else {
        colorPathsInOrder.push([color, i.toString()]);
      }
    });
  }
  return fromJS(colorPathsInOrder.map(entry => colors.getIn(entry)));
})();

export default function getColors() {
  return colors;
}

export function getColorsList() {
  return colorList;
}
