import React, { ReactElement, Component } from 'react';
import styles from '../style/api-portal.module.scss';
import ApiTokensTable, { ApiToken } from './ApiTokensTable';
import request from '../../frontend-common-libs/src/utils/httpUtils';
import { GATEWAY } from '../../frontend-common-libs/src/config/config';
import CreateAPITokenModal from './CreateAPITokenModal';
import CreateAPITokenForm from './CreateAPITokenForm';

const authServiceBaseURL = GATEWAY.AUTH_SERVICE_PATHS.BASE_URL;
const apiTokensPath = GATEWAY.AUTH_SERVICE_PATHS.API_TOKENS;

type Props = {};

export type ApiPortalState = {
  data: ApiToken[];
  loading: boolean;
  error: boolean;
  isModalOpen: boolean;
  isApiTokenCreated: boolean;
};
export default class ApiPortal extends Component<Props, ApiPortalState> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      data: [],
      loading: false,
      error: false,
      isModalOpen: false,
      isApiTokenCreated: false
    };
  }

  public async componentDidMount(): Promise<void> {
    await this.getApiTokens();
  }

  private static isActive(item: Record<string, unknown>): 'Active' | 'Expired' {
    const expirationDate = new Date(item.expirationDate as string);
    if (new Date() > new Date(expirationDate)) {
      return 'Expired';
    }
    return 'Active';
  }

  private static formatDate(date: string): string {
    const dateInstance = new Date(date);
    const month = `0${dateInstance.getMonth() + 1}`.slice(-2);
    const day = `0${dateInstance.getDate()}`.slice(-2);
    const year = dateInstance.getFullYear();
    const hours = `0${dateInstance.getHours()}`.slice(-2);
    const minutes = `0${dateInstance.getMinutes()}`.slice(-2);
    const seconds = `0${dateInstance.getSeconds()}`.slice(-2);

    return `${month}/${day}/${year} ${hours}:${minutes}:${seconds}`;
  }

  private static parseItemToApiToken(item: Record<string, unknown>): ApiToken {
    return {
      id: item.tokenId as string,
      description: item.description as string,
      issuedDate: ApiPortal.formatDate(item.issuedDate as string),
      expirationDate: ApiPortal.formatDate(item.expirationDate as string),
      status: ApiPortal.isActive(item)
    };
  }

  private async getApiTokens(): Promise<void> {
    this.setState({ loading: true });

    try {
      const response = await request.get(authServiceBaseURL, {}, apiTokensPath, {});
      this.setState({ data: response.data, error: false });
    } catch (e) {
      console.error(e);
      this.setState({ error: true });
    } finally {
      this.setState({ loading: false, isApiTokenCreated: false });
    }
  }

  private setApiTokenCreated = (isCreated: boolean) =>
    this.setState({ isApiTokenCreated: isCreated });

  private handleCloseModal = async () => {
    const { isApiTokenCreated } = this.state;
    this.setState({ isModalOpen: false });
    if (isApiTokenCreated) {
      await this.getApiTokens();
    }
  };

  private handleTokenDeleted = async () => {
    await this.getApiTokens();
  };

  private handleCreateAPITokenBtnClicked = () => this.setState({ isModalOpen: true });

  private renderLoading(): ReactElement | null {
    const { loading } = this.state;
    if (loading) {
      return (
        <div className={styles.flexCenter}>
          {/* classNames below comes from fontawesome, these are used to create spinner */}
          <i className="fa fa-spinner fa-spin fa-3x fa-fw" />
          <span className="sr-only" />
        </div>
      );
    }
    return null;
  }

  private renderError(): ReactElement | null {
    const { error, loading } = this.state;
    if (error && !loading) {
      return (
        <div className={styles.flexCenter}>
          <span>Error retrieving Api Tokens</span>
          <button type="button" className="new-btn primary" onClick={() => this.getApiTokens()}>
            Try Again
          </button>
        </div>
      );
    }
    return null;
  }

  private renderApiTokenTable(): ReactElement | null {
    const { error, loading, data } = this.state;
    if (!loading && !error) {
      const items = data.map(ApiPortal.parseItemToApiToken);
      return (
        <ApiTokensTable apiTokens={items} onApiTokenDeleted={() => this.handleTokenDeleted()} />
      );
    }
    return null;
  }

  private renderCreateAPITokenModal(): ReactElement {
    const { isModalOpen, isApiTokenCreated } = this.state;
    return (
      <CreateAPITokenModal
        onClose={this.handleCloseModal}
        show={isModalOpen}
        isCreated={isApiTokenCreated}
      >
        <CreateAPITokenForm setApiTokenCreated={this.setApiTokenCreated} />
      </CreateAPITokenModal>
    );
  }

  public render() {
    return (
      <div className={styles.apiPortalWrapper}>
        <div className={styles.apiPortalHeaderContainer}>
          <h4 id="api-portal-title">API Tokens</h4>
          <button id="create-new-api-token-button" onClick={this.handleCreateAPITokenBtnClicked}>
            Create New API Token
          </button>
        </div>
        {this.renderLoading()}
        {this.renderError()}
        {this.renderApiTokenTable()}
        {this.renderCreateAPITokenModal()}
      </div>
    );
  }
}
