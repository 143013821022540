import React, { PureComponent } from 'react';
import { fromJS } from 'immutable';
import { Set, Map } from 'immutable';
import { getWellTypes, getSampleTypeColor } from '../../../utils/microplateUtils';
import {
  formatId,
  Select,
  SelectMenuItem
} from '../../../frontend-common-libs/src/components/common/dropdown';
import Swatch from './Swatch';

export type Props = {
  handleChanges: (...args: Array<any>) => any;
  selectedWellIds: Set<string>;
  selectedWells: Map<string, any>;
};

class WellType extends PureComponent<Props> {
  getValue = (option: { [key: string]: any }): string => option.name;

  getDisplay = (option: any): any => (option ? option.descr : undefined);

  getCurrentWellType = () => {
    const { selectedWellIds, selectedWells } = this.props;
    if (selectedWells.size === 0) {
      // all wells empty
      return { allEqual: true, wellType: undefined };
    }
    if (selectedWellIds.size > selectedWells.size) {
      // when there is a mix of empty and non-empty wells
      return { allEqual: false, wellType: undefined };
    }
    const wellType = selectedWells.first().get('type');
    if (selectedWells.every(x => x.get('type') === wellType)) {
      return { allEqual: true, wellType };
    }
    return { allEqual: false, wellType: undefined };
  };

  handleSelect = (type: string) => {
    const { handleChanges } = this.props;
    const newType = fromJS({ type });
    handleChanges(newType);
  };

  renderWellOptions = (selectId: string, wellTypes: any) => {
    return wellTypes.map((wellType: any) => {
      const formattedId = formatId(selectId, this.getValue(wellType));
      return (
        <SelectMenuItem id={formattedId} value={this.getValue(wellType)} key={formattedId}>
          {this.getDisplay(wellType)}
        </SelectMenuItem>
      );
    });
  };

  render() {
    const { allEqual, wellType: name } = this.getCurrentWellType();
    const wellTypes = getWellTypes();
    const wellType = name && wellTypes.find(well => well.name === name);
    const wellColor = name && getSampleTypeColor(name);
    const backgroundColor = wellColor ? wellColor.get('primary') : undefined;
    const id = 'well-type';
    return (
      <>
        <p className="label-content">Sample Type</p>
        <div className="swatch-wrapper">
          <Swatch id={`${id}-swatch`} backgroundColor={backgroundColor} />
          <Select
            id={id}
            value={this.getDisplay(wellType)}
            placeholder={allEqual ? 'Select Sample Type' : 'Multiple Values'}
            handleSelection={this.handleSelect}
          >
            {this.renderWellOptions(id, wellTypes)}
          </Select>
        </div>
      </>
    );
  }
}
export default WellType;
