import { DispatchType, PROJECTS_LOADED, PROJECTS_LOADING } from './action-types';
import { GetState } from '../../types';
import { ProjectRepository } from '../repository';
import notification from '../../frontend-common-libs/src/utils/notifications';
import changeProjectSelection from './change-project-selection';
import { getIsProjectsLoading, isProjectsLoaded } from '../selectors/selectors';
import { CbWithToken } from '../../organization-management/actions/WithRefreshToken';
import { withRefreshOrgToken } from '../../organization-management/actions/with-refresh-org-token';

function getProjects(orgId: string) {
  return async (dispatch: DispatchType, getState: GetState) => {
    dispatch({
      type: PROJECTS_LOADING,
      payload: { orgId }
    });

    try {
      const getProjectsCb: CbWithToken = async (accessToken: string) => {
        return ProjectRepository.getProjectsWithRetry(orgId, accessToken);
      };
      const getProjectsResponse = await withRefreshOrgToken(
        dispatch,
        getState,
        orgId,
        getProjectsCb
      );
      const { projects, orgName } = getProjectsResponse;
      const selectedProjectId = ProjectRepository.getSelectedProjectId(projects);
      await changeProjectSelection(selectedProjectId)(dispatch);

      dispatch({
        type: PROJECTS_LOADED,
        payload: { orgId, projects, orgName }
      });
    } catch (error) {
      dispatch({
        type: PROJECTS_LOADED,
        payload: { orgId, projects: [] }
      });
      notification.error('Error retrieving projects. Please try again.');
    }
  };
}

// eslint-disable-next-line import/prefer-default-export
export function getProjectsIfNeeded(orgId: string) {
  return async (dispatch: DispatchType, getState: GetState) => {
    const state = getState();
    if (!isProjectsLoaded(state, orgId) && getIsProjectsLoading(state, orgId) !== true) {
      await getProjects(orgId)(dispatch, getState);
    }
  };
}
