import { List, Map } from 'immutable';
import axios from 'axios';
import { GATEWAY } from '../../frontend-common-libs/src/config/config';
import requests from '../../frontend-common-libs/src/utils/httpUtils';
import { StepAnalysisCompute } from '../../types';

export default class ComputeStepDataRequest {
  private readonly entityID: string;

  private readonly step: number;

  private readonly groupMode: string;

  private readonly plate: Map<string, any>;

  private readonly excludedWells: List<string>;

  private readonly perStepAnalysisSettings: Map<string, any>;

  private readonly isAmpMode: boolean;

  private readonly driftCorrection: boolean;

  constructor(stepAnalysisCompute: StepAnalysisCompute) {
    this.entityID = stepAnalysisCompute.entityId;
    this.step = stepAnalysisCompute.step;
    this.groupMode = stepAnalysisCompute.groupMode;
    this.plate = stepAnalysisCompute.plate;
    this.excludedWells = stepAnalysisCompute.excludedWells;
    this.perStepAnalysisSettings = stepAnalysisCompute.perStepAnalysisSettings;
    this.isAmpMode = stepAnalysisCompute.isAmpStepSelected;
    this.driftCorrection = stepAnalysisCompute.driftCorrection;
  }

  private get payload(): { [key: string]: any } {
    const payload: { [key: string]: any } = {
      plate: this.plate,
      groupMode: this.groupMode,
      driftCorrection: this.driftCorrection,
      computationSettings: {
        cqDetermination: 'singleThreshold',
        excludeWells: this.excludedWells
      }
    };

    if (this.perStepAnalysisSettings.size) {
      payload.computationSettings.perStep = {
        [this.groupMode === 'target' ? 'targets' : 'fluors']: this.perStepAnalysisSettings
      };
    }
    return payload;
  }

  private get path(): string {
    return this.isAmpMode
      ? GATEWAY.QPCRDATA_SERVICE_PATHS.POST_COMPUTE_AMP_STEP_DATA_URL(this.entityID, this.step)
      : GATEWAY.QPCRDATA_SERVICE_PATHS.POST_COMPUTE_MELT_STEP_DATA_URL(this.entityID, this.step);
  }

  private static get domain(): string {
    return GATEWAY.QPCRDATA_SERVICE_PATHS.BASE_URL;
  }

  public send(): Promise<any> {
    return this.requestComputeDataFromS3SavedResponse();
  }

  private requestComputeStepData(additionalParams: { [key: string]: any } = {}): Promise<any> {
    return requests.post(ComputeStepDataRequest.domain, {}, this.path, additionalParams, {
      ...this.payload
    });
  }

  private async requestComputeDataFromS3SavedResponse(): Promise<any> {
    const { data: preSignedURL } = await this.requestComputeStepData();
    return axios.get(preSignedURL);
  }
}
