import React, { Component } from 'react';
import ViewportIntersectionObserver from '../ViewportIntersectionObserver';
import NonSelectableMenuItem from './NonSelectableMenuItem';

export type Props = {
  isLoading: boolean;
  onFirstVisible: (...args: Array<any>) => any;
  menuItemRef?: any;
};

export default class LoadingMenuItem extends Component<Props> {
  static defaultProps = {
    menuItemRef: undefined
  };

  onIntersectionObserved = (): void => {
    const { onFirstVisible } = this.props;
    onFirstVisible();
  };

  renderSpinner = (setMarkerRef?: (elementToObserve: HTMLElement | null | undefined) => void) => {
    return <i ref={setMarkerRef} className="fa fa-spinner fa-spin fa-fw" />;
  };

  renderNonSelectableMenuItem = () => {
    const { isLoading, menuItemRef } = this.props;
    return (
      <NonSelectableMenuItem menuItemRef={menuItemRef}>
        {isLoading ? (
          this.renderSpinner()
        ) : (
          <ViewportIntersectionObserver
            render={this.renderSpinner}
            onViewportEntered={this.onIntersectionObserved}
          />
        )}
      </NonSelectableMenuItem>
    );
  };

  render() {
    return this.renderNonSelectableMenuItem();
  }
}
