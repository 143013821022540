export type UserPreferencesType =
  | 'notifications_settings_loading'
  | 'notifications_settings_loaded'
  | 'notifications_settings_error'
  | 'set_device_type'
  | 'add_notification_subscription_started'
  | 'add_notification_subscription_succeeded'
  | 'add_notification_subscription_error'
  | 'delete_notification_subscription_started'
  | 'delete_notification_subscription_succeeded'
  | 'delete_notification_subscription_error';

export const NOTIFICATIONS_SETTINGS_LOADING: UserPreferencesType = 'notifications_settings_loading';
export const NOTIFICATIONS_SETTINGS_LOADED: UserPreferencesType = 'notifications_settings_loaded';
export const NOTIFICATIONS_SETTINGS_ERROR: UserPreferencesType = 'notifications_settings_error';
export const SET_DEVICE_TYPE: UserPreferencesType = 'set_device_type';
export const ADD_NOTIFICATION_SUBSCRIPTION_STARTED: UserPreferencesType =
  'add_notification_subscription_started';
export const ADD_NOTIFICATION_SUBSCRIPTION_SUCCEEDED: UserPreferencesType =
  'add_notification_subscription_succeeded';
export const ADD_NOTIFICATION_SUBSCRIPTION_ERROR: UserPreferencesType =
  'add_notification_subscription_error';
export const DELETE_NOTIFICATION_SUBSCRIPTION_STARTED: UserPreferencesType =
  'delete_notification_subscription_started';
export const DELETE_NOTIFICATION_SUBSCRIPTION_SUCCEEDED: UserPreferencesType =
  'delete_notification_subscription_succeeded';
export const DELETE_NOTIFICATION_SUBSCRIPTION_ERROR: UserPreferencesType =
  'delete_notification_subscription_error';
