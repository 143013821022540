import GradientTemperature from '../../gradient-temperature/GradientTemperature';
import GradientTemperature384 from '../../gradient-temperature/GradientTemperature384';
import Gradient, { GradientLowerTemperature, GradientRange } from '../../Gradient';

export default class Gradient384 extends Gradient {
  constructor(lowerTemp: GradientLowerTemperature, range: GradientRange, isValid: boolean) {
    super(16, lowerTemp, range, isValid, Gradient384.offsetTable, Gradient384.scTable);
  }

  private static readonly offsetTable: Array<Array<number>> = [
    [30.069195, 40.048091, 50.026987, 60.030363, 69.970408, 79.969675, 89.998456, 100.027237],
    [30.034479, 40.025598, 50.016717, 60.029435, 69.991362, 80.003121, 90.042567, 100.082013],
    [30.032752, 40.022237, 50.011723, 60.034693, 70.023793, 80.058775, 90.113526, 100.168277],
    [30.031052, 40.023694, 50.016335, 60.041087, 70.040805, 80.078323, 90.134163, 100.190002],
    [30.009271, 40.011431, 50.013591, 60.042887, 70.060342, 80.105471, 90.170935, 100.2364],
    [30.002807, 40.007525, 50.012243, 60.037956, 70.058704, 80.100833, 90.16001, 100.219187],
    [30.018346, 40.018604, 50.018861, 60.043107, 70.057731, 80.097636, 90.157363, 100.217089],
    [30.026773, 40.026449, 50.026125, 60.045688, 70.057091, 80.093231, 90.148796, 100.204362],
    [30.023318, 40.026281, 50.029243, 60.041232, 70.047282, 80.082877, 90.136179, 100.189482],
    [30.031849, 40.032323, 50.032796, 60.045288, 70.052569, 80.091199, 90.144351, 100.197503],
    [30.023721, 40.020335, 50.016948, 60.030691, 70.036544, 80.075223, 90.131457, 100.187691],
    [30.021627, 40.018225, 50.014822, 60.037197, 70.045525, 80.089509, 90.153438, 100.217368],
    [30.029424, 40.015471, 50.001518, 60.022933, 70.019114, 80.054489, 90.107733, 100.160976],
    [30.030385, 40.015228, 50.00007, 60.03, 70.018174, 80.052492, 90.110245, 100.167997],
    [30.041352, 40.017268, 49.993183, 60.015385, 69.981655, 80.006076, 90.052762, 100.099449],
    [30.034464, 40.0086, 49.982735, 60.00168, 69.95008, 79.950114, 89.981612, 100.013109]
  ];

  private static readonly scTable: Array<Array<number>> = [
    [0.624205, 0.626339, 0.628472, 0.629326, 0.629284, 0.629655, 0.629357, 0.629059],
    [0.602953, 0.604858, 0.606763, 0.607508, 0.607346, 0.607814, 0.607327, 0.606841],
    [0.561788, 0.564334, 0.566879, 0.567689, 0.568032, 0.568062, 0.567658, 0.567253],
    [0.501928, 0.504372, 0.506815, 0.507661, 0.507782, 0.508214, 0.507987, 0.507761],
    [0.424374, 0.426204, 0.428034, 0.42895, 0.428939, 0.429275, 0.429087, 0.4289],
    [0.325486, 0.326637, 0.327788, 0.328582, 0.328155, 0.328275, 0.328156, 0.328038],
    [0.20592, 0.207046, 0.208172, 0.208955, 0.208523, 0.208717, 0.208574, 0.20843],
    [0.067211, 0.0682, 0.06919, 0.070667, 0.070498, 0.07097, 0.071225, 0.07148],
    [-0.082023, -0.081305, -0.080588, -0.078614, -0.07818, -0.077483, -0.076768, -0.076054],
    [-0.219479, -0.218573, -0.217667, -0.215506, -0.214961, -0.214284, -0.213138, -0.211992],
    [-0.336573, -0.3359, -0.335227, -0.33406, -0.334131, -0.333675, -0.33296, -0.332244],
    [-0.431567, -0.431154, -0.43074, -0.430919, -0.431827, -0.432032, -0.432049, -0.432067],
    [-0.506323, -0.506151, -0.505979, -0.507291, -0.508944, -0.509583, -0.509703, -0.509822],
    [-0.564566, -0.564272, -0.563978, -0.566357, -0.568345, -0.568883, -0.569162, -0.569442],
    [-0.603228, -0.602939, -0.60265, -0.605429, -0.607293, -0.608077, -0.608202, -0.608328],
    [-0.62145, -0.621461, -0.621473, -0.625068, -0.6273, -0.627588, -0.627587, -0.627586]
  ];

  // eslint-disable-next-line class-methods-use-this
  protected createGradientTemperature(
    index: number,
    temperatureValue: number
  ): GradientTemperature {
    return new GradientTemperature384(index, temperatureValue);
  }
}
