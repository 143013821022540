import React from 'react';
import classnames from 'classnames';
import { Button, ButtonProps } from './Button';

function LinkButton(props: ButtonProps) {
  const { className } = props;
  return <Button {...props} className={classnames('link', className)} />;
}

LinkButton.defaultProps = {
  isInProgress: false
};

export default LinkButton;
