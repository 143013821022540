import React, { Component, SyntheticEvent } from 'react';
import './styles/run-details.scss';
import { NullableString } from '../../../common/nullable-types';

export type Props = {
  fileName: string;
  fileNameError: NullableString;
  setFileName: (fileName: string) => void;
  disabled?: boolean;
};

export class FileName extends Component<Props> {
  updateFileName = (e: SyntheticEvent<HTMLInputElement>) => {
    const { setFileName } = this.props;
    const newFileName = e.currentTarget.value;
    setFileName(newFileName);
  };

  render() {
    const { fileName, fileNameError, disabled } = this.props;
    return (
      <div className={`input-group ${fileNameError ? 'error' : ''}`}>
        <div className="label-and-error">
          <span className="control-label" id="label-file-name">
            File Name
          </span>
          {fileNameError && (
            <span id="file-name-error" className="error-message">
              {fileNameError}
            </span>
          )}
        </div>
        <input
          id="value-file-name"
          type="text"
          value={fileName}
          onChange={this.updateFileName}
          disabled={disabled}
        />
      </div>
    );
  }
}
