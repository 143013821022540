import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { OrderedMap, Map } from 'immutable';
import * as instrumentActions from '../../actions/instrument.actions';
import Loader from '../../../frontend-common-libs/src/components/common/Loader';
import InstrumentTable from '../instrument-table';

import '../../styles/instruments.scss';
import { ReduxState } from '../../../types';

export type Props = {
  isLoading: boolean;
  instruments: OrderedMap<string, Map<string, any>>;
  statuses: Map<string, Map<string, any>>;
  errorMessage: string;
  loadInstrumentListIfNeeded: typeof instrumentActions.loadInstrumentListIfNeeded;
};

export class InstrumentListImpl extends PureComponent<Props> {
  componentDidMount() {
    const { instruments, loadInstrumentListIfNeeded } = this.props;
    if (!instruments.size) {
      loadInstrumentListIfNeeded();
    }
  }

  render() {
    const { instruments, isLoading, errorMessage, loadInstrumentListIfNeeded, statuses } =
      this.props;

    if (isLoading) {
      return <Loader />;
    }

    return (
      <div id="instrument-list">
        <div className="table-breadcrumb" id="breadcrumb-instruments">
          <span id="instrument-list-title">My Instruments ({instruments.size})</span>
        </div>
        <InstrumentTable
          // @ts-ignore
          className="flex-column-container"
          isLoading={isLoading}
          isError={!!errorMessage}
          fetchMoreData={loadInstrumentListIfNeeded}
          instruments={instruments}
          statuses={statuses}
        />
      </div>
    );
  }
}

function mapStateToProps(state: ReduxState) {
  return {
    isLoading: state.instruments.get('isLoading'),
    instruments: state.instruments.get('instruments'),
    errorMessage: state.instruments.get('error'),
    statuses: state.instruments.get('statuses')
  };
}

export default connect(mapStateToProps, {
  loadInstrumentListIfNeeded: instrumentActions.loadInstrumentListIfNeeded
  // @ts-ignore
})(InstrumentListImpl);
