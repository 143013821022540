import { Dispatch } from '../../types';
import { UserFilesActionType } from '../../file-management/actions/action-types';
import { PROTOCOL_ENTITY_TYPE } from '../../frontend-common-libs/src/common/protocol_common';
import { ProtocolTemplatesActionType } from '../protocolTemplate_types';
import ProtocolIotHandler from './ProtocolIotHandler';
import FilesIotHandler from './FilesIotHandler';

export default class FileArbiterIotHandler {
  static onIotMessage(dispatch: Dispatch<UserFilesActionType, any>, message: any) {
    const { event: entity } = message;
    if (!entity) return;
    const { type } = entity;
    if (type === PROTOCOL_ENTITY_TYPE) {
      ProtocolIotHandler.onIotMessage(
        entity,
        // prettier-ignore
        (dispatch as Dispatch<any, any>) as Dispatch<ProtocolTemplatesActionType, any>
      );
      return;
    }
    FilesIotHandler.onIotMessage(
      entity,
      // prettier-ignore
      (dispatch as Dispatch<any, any>) as Dispatch<UserFilesActionType, any>
    );
  }
}
