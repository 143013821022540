import AWSIot from 'aws-iot-device-sdk';
import { guid } from '../utils/commonUtils';
import { IOT_MESSAGE_BROKER } from '../config/config';
import InstrumentGroupCredentials from './instrument-group-credentials';
import { InstrumentStatusCallback } from './types';

export type TopicStub = 'get' | 'update' | 'delete';
export default class InstrumentShadowGroup {
  private thingShadow: AWSIot.thingShadow;

  private readonly instrumentStatusCallback: InstrumentStatusCallback;

  private instrumentCredentials: InstrumentGroupCredentials;

  constructor(
    instrumentCredentials: InstrumentGroupCredentials,
    instrumentStatusCallback: InstrumentStatusCallback
  ) {
    this.instrumentCredentials = instrumentCredentials;
    this.instrumentStatusCallback = instrumentStatusCallback;
    // @ts-ignore
    this.thingShadow = AWSIot.thingShadow({
      host: IOT_MESSAGE_BROKER.ENDPOINT,
      clientId: guid(),
      protocol: 'wss',
      maximumReconnectTimeMs: 8000,
      accessKeyId: instrumentCredentials.accessKeyId,
      secretKey: instrumentCredentials.secretKey,
      sessionToken: instrumentCredentials.sessionToken
    });
    this.thingShadow.on('connect', this.onConnect);
    this.thingShadow.on('status', this.onInitialStatus);
    this.thingShadow.on('foreignStateChange', this.onStatusUpdate);
  }

  private onConnect = (): void => {
    this.instrumentCredentials.instrumentIds.forEach((instrumentId: string) => {
      this.thingShadow.register(instrumentId, {}, () => {
        // To get initial statuses call thingShadows.get.
        this.thingShadow.get(instrumentId);
      });
    });
  };

  private onInitialStatus = (
    instrumentId: string,
    status: any,
    _clientToken: string,
    stateObject: any
  ): void => {
    let instrumentState = stateObject;
    if (status === 'rejected' && stateObject.code === 404) {
      // edge case - handle when instrument does not have a shadow,
      // ie: has not been registered. This is more to handle emulator devices
      // that don't yet have shadows.
      instrumentState = { state: { reported: {} }, metadata: {} };
    }
    this.instrumentStatusCallback(instrumentId, instrumentState);
  };

  private onStatusUpdate = (instrumentId: string, _operation: string, stateObject: any): void => {
    this.instrumentStatusCallback(instrumentId, stateObject);
  };

  public end = (): void => {
    this.thingShadow.end();
  };
}
