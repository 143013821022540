import {
  SystemEvent,
  SystemEventBroker,
  SystemEventSubscriber
} from '../../frontend-common-libs/src/system-event';
import FileOperationEvent from '../../frontend-common-libs/src/file-operations/FileOperationEvent';

export type FileOperationHandler = (e: FileOperationEvent) => void;

export default class FileOperationSubscriber implements SystemEventSubscriber {
  readonly dispatchFileOperation: FileOperationHandler;

  constructor(dispatchFileOperation: FileOperationHandler) {
    this.dispatchFileOperation = dispatchFileOperation;
  }

  public notify(systemEvent: SystemEvent): void {
    if (FileOperationEvent.isFileOperationEvent(systemEvent)) {
      this.dispatchFileOperation(systemEvent as FileOperationEvent);
    }
  }

  public subscribe() {
    SystemEventBroker.getInstance().subscribe(this);
  }

  public unsubscribe() {
    SystemEventBroker.getInstance().unsubscribe(this);
  }
}
