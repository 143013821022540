import React, { useEffect, useState } from 'react';
import { Map, Seq } from 'immutable';
import { FleetManagementTable } from '../../fleet-management-table';
import Checkbox from '../../../../frontend-common-libs/src/common/checkbox';
import TCell from '../../../../frontend-common-libs/src/components/common/tables/TCell';
import InstrumentReservation from '../../InstrumentReservation';
import { LoadableTBodyList } from '../../../../frontend-common-libs/src/components/common/tables/Types';
import InstrumentControlRow from '../instrument-control-row/InstrumentControlRow';
import styles from './styles/instrument-control-table.module.scss';
import { ImmutableMap, InstrumentItem } from '../../../../frontend-common-libs/src/common/types';

export type Props = {
  instruments: ImmutableMap<InstrumentItem>[];
  reservations: Map<string, InstrumentReservation>;
  updateSelectedInstruments: (id: string, select: boolean) => void;
  numberOfSelectedInstruments: number;
};

export default function InstrumentControlTable(props: Readonly<Props>) {
  const { instruments, reservations, updateSelectedInstruments, numberOfSelectedInstruments } =
    props;

  const [selectAllCheckbox, setSelectAllCheckbox] = useState<boolean>(false);

  const handleSelectAll = () => {
    setSelectAllCheckbox(!selectAllCheckbox);
  };

  useEffect(() => {
    if (numberOfSelectedInstruments === 0 && selectAllCheckbox) {
      handleSelectAll();
    }
  }, [numberOfSelectedInstruments]);

  const renderSelectAllCheckbox = () => (
    <TCell>
      <Checkbox
        id="checkbox-select-all"
        name="checkbox-select-all"
        value={selectAllCheckbox}
        checked={selectAllCheckbox}
        onChange={handleSelectAll}
      />
    </TCell>
  );

  const renderAdditionalHeaders = () => (
    <>
      <TCell>STATUS</TCell>
      <TCell>PROTOCOL NAME</TCell>
      <TCell>LID</TCell>
      <TCell>CYCLE</TCell>
      <TCell>STEP</TCell>
      <TCell>SAMPLE</TCell>
    </>
  );

  const renderInstrumentRows = (
    instrumentList: ImmutableMap<InstrumentItem>[]
  ): LoadableTBodyList => {
    return Seq(instrumentList)
      .valueSeq()
      .map((instrument: ImmutableMap<InstrumentItem>) => {
        const instrumentId = instrument.get('id');
        return (
          <InstrumentControlRow
            key={instrumentId}
            instrument={instrument}
            reservation={reservations.get(instrumentId) as InstrumentReservation}
            selectAllCheckbox={selectAllCheckbox}
            updateSelectedInstruments={updateSelectedInstruments}
            numberOfSelectedInstruments={numberOfSelectedInstruments}
          />
        );
      });
  };

  const renderTableBody = () => {
    return <tbody>{renderInstrumentRows(instruments)}</tbody>;
  };

  const isHidden = reservations.size === 0;

  return (
    <div className={styles.instrumentControlTable}>
      <FleetManagementTable isHidden={isHidden}>
        {renderSelectAllCheckbox()}
        {renderAdditionalHeaders()}
        {renderTableBody()}
      </FleetManagementTable>
    </div>
  );
}
