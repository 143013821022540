import React from 'react';
import { List, Map } from 'immutable';
import { NavLink } from 'react-router-dom';

type FooterLinkProps = {
  id: string;
  title: string;
  baseUrl: string;
  isDisabled: boolean;
};
type Props = {
  baseUrl: string;
  activeTab: string;
  links: List<Map<string, any>>;
  navButtons: Map<string, any>;
  showNextButton?: boolean;
};

function FooterLink({ id, title, baseUrl, isDisabled }: FooterLinkProps) {
  return (
    <NavLink
      id={id}
      title={title}
      className={`footer-nav-button ${isDisabled ? 'disabled' : ''}`}
      activeClassName="active-step"
      to={`${baseUrl}/${id}`}
      tabIndex={isDisabled ? -1 : 0}
    >
      <div className="step-text" title={title}>
        {title}
      </div>
    </NavLink>
  );
}

const setActionButtonLinks = (
  links: List<Map<string, any>>,
  activeTab: string,
  baseUrl: string
) => {
  const subPageIdx = links.findIndex((link: Map<string, any>) => link.get('id') === activeTab);
  const prevIdx = subPageIdx - 1;
  const nextIdx = subPageIdx + 1;
  const prevObj = prevIdx < 0 ? undefined : links.get(prevIdx);
  const nextObj = nextIdx > links.size ? undefined : links.get(nextIdx);
  return {
    prevLink: prevObj ? `${baseUrl}/${prevObj.get('id')}` : '#',
    nextLink: nextObj ? `${baseUrl}/${nextObj.get('id')}` : '#'
  };
};

const getActionButtonStyles = (isDisabled: boolean) => (isDisabled ? 'disabled' : '');

function FooterNav(props: Props) {
  const { baseUrl, activeTab, links, showNextButton, navButtons } = props;
  const prevBtn = navButtons.get('prevBtn');
  const nextBtn = navButtons.get('nextBtn');
  let { prevLink, nextLink } = setActionButtonLinks(links, activeTab, baseUrl);
  prevLink = prevBtn.get('isDisabled') ? '#' : prevLink;
  nextLink = nextBtn.get('isDisabled') ? '#' : nextLink;
  return (
    <div className="footer-bar">
      <div className="footer-nav-button action-button flex-end for-left-arrow">
        {!prevBtn.get('isDisabled') && (
          <NavLink
            id="prev-workflow-step"
            role="button"
            className="btn btn-arrow left-arrow"
            tabIndex={0}
            to={prevLink}
          />
        )}
      </div>

      {links.map(linkDetail => (
        <React.Fragment key={linkDetail.get('id')}>
          {/* @ts-ignore */}
          <FooterLink
            key={linkDetail.get('id')}
            {...linkDetail.toJS()}
            baseUrl={baseUrl}
            isDisabled={linkDetail.get('isDisabled')}
          />
        </React.Fragment>
      ))}
      <div className="footer-nav-button action-button flex-start for-right-arrow">
        {showNextButton && (
          <NavLink
            id="next-workflow-step"
            role="button"
            className={`btn btn-arrow right-arrow ${getActionButtonStyles(
              nextBtn.get('isDisabled')
            )}`}
            tabIndex={nextBtn.get('isDisabled') ? -1 : 0}
            to={nextLink}
          />
        )}
      </div>
    </div>
  );
}

FooterNav.defaultProps = {
  showNextButton: true
};
export default FooterNav;
