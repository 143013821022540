import React, { Component } from 'react';
import './styles/edit-organization.scss';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { OrgPermissions } from '@biorad-lsg-tsc/organization';
import Loader from '../../frontend-common-libs/src/components/common/Loader';
import { ReduxState } from '../../types';
import {
  getOrgUserToken,
  getOrgDetails,
  getOrgUserPermissions,
  isOrgDetailsLoaded
} from '../selectors/selectors';
import { getOrgDetailsIfNeeded as getOrgDetailsIfNeededAction } from '../actions/get-org-details';
import routes from '../router/OrgRoutes';
import OrgToken from './OrgToken';
import orgIcon from '../../../img/organization.svg';
import OrganizationTabs from './OrganizationTabs';
import ErrorAlert from '../../frontend-common-libs/src/components/common/ErrorAlert';
import editIcon from '../../frontend-common-libs/src/img/protocol-edit-icon.svg';
import { editOrganization } from '../actions/edit-org';
import EditOrgDialogVm from './edit-org-dialog/EditOrgDialogVm';
import EditOrgDialog from './edit-org-dialog/EditOrgDialog';

export type Props = {
  orgId: string;
  orgAccessToken?: string;
  loadingDetails?: boolean;
  details?: any;
  getOrgDetailsIfNeeded?: (orgId: string) => any;
  hasEditPermissions?: boolean;
  editOrg?: (orgId: string, name: string | undefined, description: string | undefined) => any;
  match: RouteComponentProps['match'];
};

export type State = {
  editOrgDialogVm: EditOrgDialogVm;
};

export class EditOrganizationImpl extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { editOrgDialogVm: new EditOrgDialogVm(this.onOrgEdited) };
  }

  componentDidMount() {
    this.getOrgDetailsIfNeeded();
  }

  async componentDidUpdate(prevProps: Props) {
    const { orgAccessToken } = this.props;
    if (orgAccessToken != null && prevProps.orgAccessToken == null) {
      this.getOrgDetailsIfNeeded();
    }
  }

  getOrgDetailsIfNeeded() {
    const { orgId, getOrgDetailsIfNeeded, orgAccessToken } = this.props;
    if (getOrgDetailsIfNeeded && orgAccessToken) getOrgDetailsIfNeeded(orgId);
  }

  onEditOrg = () => {
    const { editOrgDialogVm } = this.state;
    const { details } = this.props;
    editOrgDialogVm.showEditOrgDialog(details?.name as string, details?.description);
  };

  onOrgEdited = async (orgName?: string, orgDescription?: string) => {
    const { orgId, editOrg } = this.props;
    if (editOrg) await editOrg(orgId, orgName, orgDescription);
  };

  renderEditOrgPopup() {
    const { editOrgDialogVm } = this.state;
    return <EditOrgDialog editOrgDialogVm={editOrgDialogVm} />;
  }

  renderOrgDetails() {
    const { loadingDetails, details, orgId, hasEditPermissions } = this.props;
    const orgName = details?.name;
    const error = details?.error;
    const membersCount = details?.users?.length;

    if (loadingDetails) return <Loader />;
    if (error) return <ErrorAlert error="Organization does not exist." errorId="load-org-error" />;
    return (
      <div className="edit-organization">
        <div className="organization-name">
          <span id="organization-name" className="with-ellipsis" title={orgName}>
            {orgName}
          </span>
          {hasEditPermissions && (
            <button
              id="rename-organization"
              onClick={this.onEditOrg}
              className="rename-button hover-image-button"
            >
              <img src={editIcon} alt="edit" />
            </button>
          )}
        </div>
        <div className="org-info-row">
          <img id="org-img" className="org-img" src={orgIcon} alt="org" />
          <div className="org-description-column">
            <p id="organization-description" className="org-description">
              {details?.description}
            </p>
            {membersCount && (
              <span
                id="members-count"
                className="members-count"
              >{`${membersCount} people in organization`}</span>
            )}
          </div>
        </div>
        <OrganizationTabs orgId={orgId} />
      </div>
    );
  }

  render() {
    const { orgId } = this.props;
    return (
      <div className="edit-organization-page">
        <OrgToken orgId={orgId} />
        <Link id="orgs" to={routes.USER_ORGANIZATIONS} className="back-link">
          Back to Organizations
        </Link>
        {this.renderOrgDetails()}
        {this.renderEditOrgPopup()}
      </div>
    );
  }
}

export function mapStateToProps(
  state: ReduxState,
  ownProps: Props
): {
  [key: string]: any;
} {
  const { orgId } = (ownProps.match as Record<string, any>).params;
  const details = getOrgDetails(state, orgId)?.toJS();
  const orgAccessToken = getOrgUserToken(state, orgId);
  const orgUserPermissions = getOrgUserPermissions(state, orgId);
  const hasEditPermissions = orgUserPermissions?.hasPermissions([OrgPermissions.EditOrg]);
  return {
    orgId,
    loadingDetails: !isOrgDetailsLoaded(state, orgId),
    details,
    orgAccessToken,
    hasEditPermissions
  };
}

export default withRouter(
  // @ts-ignore
  connect(mapStateToProps, {
    getOrgDetailsIfNeeded: getOrgDetailsIfNeededAction,
    editOrg: editOrganization
  })(EditOrganizationImpl)
);
