import createCpcrJob, { deleteCpcrJob, getCpcrJobProtocol } from './api/jobs';

export default class Job {
  private readonly userId: string;

  public jobId: string | null = null;

  public constructor(userId: string, jobId: string | null) {
    this.userId = userId;
    this.jobId = jobId;
  }

  public static async create(instrumentId: string, userId: string, entityId: string): Promise<Job> {
    const response = await createCpcrJob(userId, instrumentId, entityId);
    const { data } = response;
    const { jobId } = data;
    return new Job(userId, jobId);
  }

  public async delete(): Promise<void> {
    if (!this.jobId) {
      return;
    }
    try {
      await deleteCpcrJob(this.userId, this.jobId);
      this.jobId = null;
      return;
    } catch (error) {
      console.log(`Error deleting job ${this.jobId} for user ${this.userId}, error is ${error}`);
      throw error;
    }
  }

  public async getProtocolName(): Promise<string | null> {
    if (!this.jobId) {
      return null;
    }

    try {
      const response = await getCpcrJobProtocol(this.userId, this.jobId);
      const { data } = response;
      return data.protocolName;
    } catch (error) {
      const { message } = error as Error;
      if (message.includes('403')) {
        return null;
      }
      console.log(
        `Error fetching protocol from job ${this.jobId} for user ${this.userId}, error is ${error}`
      );
      return null;
    }
  }
}
