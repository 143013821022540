import ModalDialogViewModel from '../../common/dialogs/ModalDialogViewModel';
import { UserFile } from '../../../common/types';
import { getFileHistory } from '../../../api/files-api';
import notification from '../../../utils/notifications';

export interface FileVersion extends UserFile {
  modified_by: string;
}

export default class FileHistoryVm extends ModalDialogViewModel {
  protected _loading = true;

  public get loading() {
    return this._loading;
  }

  public set loading(loading: boolean) {
    this.notifyIfChanged('_loading', loading);
  }

  protected _fileVersions: FileVersion[] | undefined;

  public get fileVersions() {
    return this._fileVersions;
  }

  public set fileVersions(fileVersions: FileVersion[] | undefined) {
    this.notifyIfChanged('_fileVersions', fileVersions);
  }

  file!: UserFile;

  showFileHistoryDialog(file: UserFile) {
    this.file = file;
    this.loadFileHistory();
    this.showDialog();
  }

  public async loadFileHistory() {
    try {
      this.loading = true;
      this.fileVersions = await getFileHistory(this.file.id);
    } catch (e) {
      notification.error('Error getting file history.');
    } finally {
      this.loading = false;
    }
  }
}
