import request from '../../frontend-common-libs/src/utils/httpUtils';
import { GATEWAY } from '../../frontend-common-libs/src/config/config';

const domain = GATEWAY.INSTRUMENT_PATHS.BASE_URL;

export const unlinkUserInstrument = (instrumentId: string) => {
  const path = GATEWAY.INSTRUMENT_PATHS.UNLINK_INSTRUMENT(instrumentId);
  return request.delete(domain, {}, path, {}, {});
};

export const getInstrumentList = (): Promise<any> => {
  const path = GATEWAY.INSTRUMENT_PATHS.GET_LIST;
  return request.get(domain, {}, path, {});
};
