import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import { getCompletedRunInfo, getEntityId, getRunLog } from '../../../selectors/selectors';
import { LinkButton } from '../../../frontend-common-libs/src/components/common/buttons';
import {
  showRunLog as showRunLogAction,
  hideRunLog as hideRunLogAction
} from '../../../actions/currentCfxRun_actions';
import {
  getRunDurationFormat,
  gmtStringToDateTimeFormat
} from '../../../frontend-common-libs/src/common/display-formats';
import opusIcon from '../../../../img/opus-icon-no-badge-no-margins.svg';
import { instrumentModelToEnum, isCreatedByBrIo } from '../../../instruments';
import RunLogTable from './RunLogTable';
import { ReduxState } from '../../../types';
import DetailsHeaderValue from '../../../frontend-common-libs/src/components/common/run/DetailsHeaderValue';
import { NullableString } from '../../../frontend-common-libs/src/common/nullable-types';
import Instrument from '../../../frontend-common-libs/src/components/common/run/Instrument';

type Props = {
  isCompletedRun: boolean;
  runInfo: Map<string, any>;
  entityId: string;
  runLog: Map<string, any>;
  showRunLog: typeof showRunLogAction;
  hideRunLog: typeof hideRunLogAction;
};

export class CompletedPcrRunDetailsImpl extends PureComponent<Props> {
  showRunLog = async () => {
    const { showRunLog, entityId, runLog } = this.props;
    let logEntries = null;
    if (runLog != null) {
      logEntries = runLog.getIn(['logsEntries']);
    }
    // @ts-ignore
    await showRunLog(entityId, logEntries);
  };

  hideRunLog = () => {
    const { hideRunLog } = this.props;
    hideRunLog();
  };

  renderCompletedRunDetails() {
    const { runInfo } = this.props;
    const runStartTimeGmt = runInfo.getIn(['runStartTime']);
    // @ts-ignore
    const runStartTime = gmtStringToDateTimeFormat(runStartTimeGmt);
    const runEndTimeGmt = runInfo.getIn(['runEndTime']);
    // @ts-ignore
    const runEndTime = gmtStringToDateTimeFormat(runEndTimeGmt);
    // @ts-ignore
    const timeElapsed = getRunDurationFormat(runStartTimeGmt, runEndTimeGmt);
    const username = runInfo.getIn(['username']) as NullableString;
    const createdBy = runInfo.getIn(['createdBy']) as NullableString;
    return (
      <div className="run-completed-content">
        <span className="control-label large" id="run-completed">
          RUN COMPLETED
        </span>
        <DetailsHeaderValue header="Time Started" value={runStartTime} />
        <DetailsHeaderValue header="Time Ended" value={runEndTime} />
        <DetailsHeaderValue header="Time Elapsed" value={timeElapsed} />
        <DetailsHeaderValue header="User" value={username} />
        <DetailsHeaderValue header="Software" value={isCreatedByBrIo(createdBy)} />
        <LinkButton
          type="button"
          className="show-log-button"
          onClick={this.showRunLog}
          id="button-show-log"
        >
          Run Log
        </LinkButton>
      </div>
    );
  }

  renderInstrumentDetails() {
    const { runInfo } = this.props;
    const serialNumber = runInfo.getIn(['baseSerialNumber']) as NullableString;
    const instrumentIconAndName = this.renderInstrumentIconModelAndName();
    if ((serialNumber == null || serialNumber === '') && !instrumentIconAndName) return null;
    return (
      <div className="instrument-details-content">
        <span className="control-label large" id="instrument">
          INSTRUMENT
        </span>
        {instrumentIconAndName}
        <DetailsHeaderValue header="Serial Number" value={serialNumber} />
      </div>
    );
  }

  renderInstrumentIconModelAndName() {
    const { runInfo } = this.props;
    const instrumentName = runInfo.getIn(['instrumentName']) as string | undefined;
    let instrumentModel = runInfo.getIn(['instrumentModel']) as string | undefined;
    const serialNumber = runInfo.getIn(['baseSerialNumber']);
    // @ts-ignore
    instrumentModel = instrumentModelToEnum(instrumentModel, serialNumber);
    if ((instrumentModel == null || instrumentModel === '') && instrumentName == null) return null;
    return <Instrument image={opusIcon} model={instrumentModel} name={instrumentName} />;
  }

  renderRunLog() {
    const { runLog } = this.props;
    const loadingLog = runLog.getIn(['loadingLog']);
    const logsEntries = runLog.getIn(['logsEntries']);
    return (
      <div className="run-log-content">
        <div className="log-panel-header-row">
          <span className="run-log-label control-label large" id="run-log-label">
            Run Log
          </span>
          <LinkButton
            type="button"
            onClick={this.hideRunLog}
            className="hide-log-button"
            id="button-close-log"
          >
            Back
          </LinkButton>
        </div>
        <div className="log-panel-body">
          {loadingLog && (
            <div className="loading-log">
              <i className="fa fa-spinner fa-spin fa-3x fa-fw" title="loading" id="loading-log" />
            </div>
          )}
          {!loadingLog && logsEntries != null && (
            // @ts-ignore
            <RunLogTable tableRows={logsEntries} />
          )}
          {!loadingLog && logsEntries == null && (
            <span id="no-log-label" className="error-message">
              Error opening run log
            </span>
          )}
        </div>
      </div>
    );
  }

  render() {
    const { isCompletedRun, runLog } = this.props;
    if (!isCompletedRun) return null;
    let showingLog = false;
    // @ts-ignore
    if (runLog != null) showingLog = runLog.getIn(['showingLog']);
    if (showingLog == null) showingLog = false;

    return (
      <>
        <div className="vertical-separator" id="vertical-separator" />
        {!showingLog && (
          <>
            {this.renderCompletedRunDetails()}
            {this.renderInstrumentDetails()}
          </>
        )}
        {showingLog && this.renderRunLog()}
      </>
    );
  }
}

function mapStateToProps(state: ReduxState) {
  return {
    runInfo: getCompletedRunInfo(state),
    runLog: getRunLog(state),
    entityId: getEntityId(state)
  };
}

export default connect(mapStateToProps, {
  showRunLog: showRunLogAction,
  hideRunLog: hideRunLogAction
  // @ts-ignore
})(CompletedPcrRunDetailsImpl);
