import ProtocolUpload from '../../../frontend-common-libs/src/file-operations/upload-protocol/ProtocolUpload';
import { UserFile } from '../../../frontend-common-libs/src/common/types';
import { creatQpcrProtocolFromFile } from '../../../api/pcrData';
import { PROTOCOL_TEMPLATES_ADD } from '../../../actions/protocolTemplate_types';
import {
  TrackedFilesEvents,
  trackEvent
} from '../../../frontend-common-libs/src/user-analytics/trackedEvents';
import { withRefreshProjectToken } from '../../../project-management/actions/with-refresh-project-token';
import { CbWithToken } from '../../../organization-management/actions/WithRefreshToken';

export default class QpcrProtocolUpload extends ProtocolUpload {
  async createProtocolFromFile(buffer: Record<string, any>): Promise<UserFile> {
    const filename = this.getFileNameNoExtension();
    const creatCb: CbWithToken = async (projectAccessToken: string) => {
      return creatQpcrProtocolFromFile(filename, buffer, this.projectId, projectAccessToken);
    };
    const res = await withRefreshProjectToken(
      this.dispatch,
      this.getState,
      this.projectId,
      creatCb
    );
    return res.faEntity;
  }

  notifyProtocolCreated(entity: UserFile) {
    this.dispatch({
      type: PROTOCOL_TEMPLATES_ADD,
      payload: {
        entity,
        projectId: this.projectId
      }
    });
    trackEvent(TrackedFilesEvents.CfxUploadProtocol, { fileName: entity.name });
  }
}
