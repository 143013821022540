import request from '../frontend-common-libs/src/utils/httpUtils';
import { GATEWAY } from '../frontend-common-libs/src/config/config';

const baseURL = GATEWAY.NOTIFICATIONS_PATHS.BASE_URL;

type NotificationSubscriptionRequestBody = {
  notificationId: string;
  userEmail: string;
};

export async function getNotificationsSettingsList(): Promise<any> {
  const path = GATEWAY.NOTIFICATIONS_PATHS.GET_NOTIFICATIONS_SETTINGS_URL;
  return request.get(baseURL, {}, path, {});
}

export async function getUserSubscriptions(): Promise<any> {
  const path = GATEWAY.NOTIFICATIONS_PATHS.GET_USER_SUBSCRIPTIONS_URL;
  return request.get(baseURL, {}, path, {});
}

export function subscribeToNotification(
  notificationSubscription: NotificationSubscriptionRequestBody
): Promise<any> {
  const { notificationId, userEmail } = notificationSubscription;
  const path = GATEWAY.NOTIFICATIONS_PATHS.POST_NOTIFICATION_SUBSCRIPTION_URL;
  return request.post(baseURL, {}, path, {}, { settingId: notificationId, userEmail });
}

export function unsubscribeFromNotification(id: string): Promise<any> {
  const path = GATEWAY.NOTIFICATIONS_PATHS.DELETE_NOTIFICATION_SUBSCRIPTION_URL(id);
  return request.delete(baseURL, {}, path, {}, {});
}
