import { Map } from 'immutable';
import {
  FILETYPE_CFX_RUN,
  FILETYPE_PENDING_CFX_RUN,
  FILETYPE_IN_PROGRESS_CFX_RUN,
  FILETYPE_CFX_RUN_TEMPLATE
} from '../common/userfiles_common';
import { PROTOCOL_ENTITY_TYPE } from '../common/protocol_common';
import { CPCR_PROTOCOL_ENTITY_TYPE } from '../conventional-pcr/protocol';
import { ImmutableMap, UserFile } from '../common/types';

function compareDates(firstDate: Date, secondDate: Date) {
  if (firstDate > secondDate) {
    return -1;
  }
  if (firstDate < secondDate) {
    return 1;
  }
  return 0;
}

export function sortEntitiesByLastUpdated(entities: Map<string, ImmutableMap<UserFile>>) {
  return entities.sort((entityA, entityB) => {
    const updatedDateFromEntityA = new Date(entityA.get('updated'));
    const updatedDateFromEntityB = new Date(entityB.get('updated'));
    return compareDates(updatedDateFromEntityA, updatedDateFromEntityB);
  });
}

export default function iconForEntityType(entityType: string) {
  switch (entityType) {
    case FILETYPE_CFX_RUN:
      return 'completed-run.svg';
    case FILETYPE_IN_PROGRESS_CFX_RUN:
      return 'in-progress-cfx-run.svg';
    case FILETYPE_PENDING_CFX_RUN:
      return 'pending-run.svg';
    case PROTOCOL_ENTITY_TYPE:
      return 'protocol.svg';
    case CPCR_PROTOCOL_ENTITY_TYPE:
      return 'ptc-protocol.svg';
    case FILETYPE_CFX_RUN_TEMPLATE:
      return 'cfx-run-template.svg';
    default:
      return 'generic-file.svg';
  }
}

export function updateSelectedEntities(
  selectedEntityIds: Array<string>,
  selectedEntityId: string
): Array<string> {
  if (selectedEntityIds.includes(selectedEntityId)) {
    return selectedEntityIds.filter(entity => entity !== selectedEntityId);
  }
  return [...selectedEntityIds, selectedEntityId];
}
