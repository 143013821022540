import {
  ArtifactURL,
  InstrumentType,
  PluginManifestUrl,
  PluginName,
  PluginNamespace,
  PluginRootPath,
  RemoteManifest,
  ThumbnailImagePath,
  PluginCreateExperimentCardProperties
} from '@biorad-lsg-tsc/instrument-family-plugin';
import requests from '../frontend-common-libs/src/utils/httpUtils';
import PluginCreateExperimentCard from './PluginCreateExperimentCard';

export default class RemotePluginManifest {
  private constructor(manifest: RemoteManifest, url: PluginManifestUrl) {
    this.manifest = manifest;
    this.url = new URL(url);

    const pluginCreateExperimentCardProperties = <PluginCreateExperimentCardProperties>(
      manifest.createExperimentCard
    );
    this.pluginCreateExperimentCard = pluginCreateExperimentCardProperties
      ? new PluginCreateExperimentCard(pluginCreateExperimentCardProperties)
      : undefined;
  }

  private readonly manifest: RemoteManifest;

  private readonly url: URL;

  private readonly pluginCreateExperimentCard: PluginCreateExperimentCard | undefined;

  private static async requestWithRetries(manifestUrl: string) {
    return requests.withRetry(requests.unauthGet)(manifestUrl);
  }

  private get artifactRootPath(): string {
    const { origin, pathname, href } = this.url;
    const filename = pathname.substring(pathname.lastIndexOf('/') + 1);
    // @ts-ignore
    const [, artifactRoot] = href.match(new RegExp(`${origin}(.*)${filename}`));

    return artifactRoot;
  }

  public get namespace(): PluginNamespace {
    return this.manifest.namespace;
  }

  public get basePath(): PluginRootPath {
    return this.manifest.basePath;
  }

  public get instrumentTypes(): InstrumentType[] {
    return this.manifest.instrumentTypes;
  }

  public get createExperimentCard(): PluginCreateExperimentCard | undefined {
    return this.pluginCreateExperimentCard;
  }

  public get name(): PluginName {
    return this.manifest.name;
  }

  public createAbsoluteArtifactPath(relativePath: string): ThumbnailImagePath {
    const { origin } = this.url;

    // Removes extra slash at the end of artifactRootPath, we don't need it since the instrumentThumbnail is a relative path
    const artifactRootPath = this.artifactRootPath.substring(0, this.artifactRootPath.length - 1);

    return `${origin}${artifactRootPath}${relativePath}`;
  }

  public get artifactURLs(): ArtifactURL[] {
    const pluginPath = `${this.url.origin}${this.artifactRootPath}`;
    const { artifacts } = this.manifest;
    return artifacts.map(artifact => `${pluginPath}${artifact}`);
  }

  // @ts-ignore
  // eslint-disable-next-line class-methods-use-this
  private forMock(): void {}

  private static async getManifest(url: PluginManifestUrl): Promise<RemoteManifest | undefined> {
    try {
      const { data: remoteManifest } = (await RemotePluginManifest.requestWithRetries(
        url
      )) as unknown as { data: RemoteManifest };
      return remoteManifest;
    } catch (err: any) {
      console.log(`Error when calling getManifest: ${err.message}`);
      return undefined;
    }
  }

  public static async create(url: PluginManifestUrl): Promise<RemotePluginManifest | undefined> {
    const manifest = await RemotePluginManifest.getManifest(url);

    return manifest ? new RemotePluginManifest(manifest, url) : undefined;
  }
}
