import { Map, List } from 'immutable';
import * as currentCfxRunSelectors from './current-cfx-run-selectors';
import { State } from '../types';
import { AnalysisResultsStep } from './current-cfx-run-types';

export const getEntityId = (state: State) =>
  currentCfxRunSelectors.getEntityId(state.currentCfxRun);

export const getAmpChartScale = (state: State) =>
  currentCfxRunSelectors.getAmpChartScale(state.currentCfxRun);

export const getAnalysisMode = (state: State) =>
  currentCfxRunSelectors.getAnalysisMode(state.currentCfxRun);

export const getMeltMode = (state: State) =>
  currentCfxRunSelectors.getMeltMode(state.currentCfxRun);

export const getSteps = (state: State): List<number> =>
  currentCfxRunSelectors.getSteps(state.currentCfxRun);

export const getSelectedStepNumber = (state: State) =>
  currentCfxRunSelectors.getSelectedStepNumber(state.currentCfxRun);

export const getSelectedStepNumberStr = (state: State) =>
  currentCfxRunSelectors.getSelectedStepNumberStr(state.currentCfxRun);

export const getSelectedStepData = (state: State) =>
  currentCfxRunSelectors.getSelectedStepData(state.currentCfxRun);

export const getSelectedStepProtocol = (state: State) =>
  currentCfxRunSelectors.getSelectedStepProtocol(state.currentCfxRun);

export const getPlateWells = (state: State) =>
  currentCfxRunSelectors.getPlateWells(state.currentCfxRun);

export const getSubtractBaseline = (state: State) =>
  currentCfxRunSelectors.getSubtractBaseline(state.currentCfxRun);

export const getDriftCorrection = (state: State) =>
  currentCfxRunSelectors.getDriftCorrection(state.currentCfxRun);

export const getWellsToHide = (state: State) =>
  currentCfxRunSelectors.getWellsToHide(state.currentCfxRun);

export const getExcludedWells = (state: State) =>
  currentCfxRunSelectors.getExcludedWells(state.currentCfxRun);

export const getSelectedWells = (state: State) =>
  currentCfxRunSelectors.getSelectedWells(state.currentCfxRun);

export const getRunName = (state: State) => currentCfxRunSelectors.getRunName(state.currentCfxRun);

export const getRunHasNewerVersion = (state: State, entityId: string): boolean => {
  return (state.qpcrdata.getIn([entityId, 'hasNewerVersion']) as boolean) || false;
};

export const getIsRunSaving = (state: State, entityId: string): boolean => {
  return (state.qpcrdata.getIn([entityId, 'isRunSaving']) as boolean) || false;
};

export const getRunNotes = (state: State) =>
  currentCfxRunSelectors.getRunNotes(state.currentCfxRun);

export const getCompletedRunInfo = (state: State) =>
  currentCfxRunSelectors.getCompletedRunInfo(state.currentCfxRun);

export const getRunLog = (state: State): Map<string, any> | null | undefined =>
  currentCfxRunSelectors.getRunLog(state.currentCfxRun);

export const getRunBarcode = (state: State) =>
  currentCfxRunSelectors.getRunBarcode(state.currentCfxRun);

export const getPlate = (state: State) => currentCfxRunSelectors.getPlate(state.currentCfxRun);

export const isSaveInProgress = (state: State) =>
  currentCfxRunSelectors.isSaveInProgress(state.currentCfxRun);

export const getOriginalNotes = (state: State) =>
  currentCfxRunSelectors.getOriginalNotes(state.currentCfxRun);

export const getRunNotesError = (state: State) =>
  currentCfxRunSelectors.getRunNotesError(state.currentCfxRun);

export const getRunNameError = (state: State) =>
  currentCfxRunSelectors.getRunNameError(state.currentCfxRun);

export const getPlateChannelMap = (state: State): Map<string, any> =>
  currentCfxRunSelectors.getPlateChannelMap(state.currentCfxRun);

export const getTargetsInRun = (state: State) =>
  currentCfxRunSelectors.getTargetsInRun(state.currentCfxRun);

export const getFluorsInRun = (state: State) =>
  currentCfxRunSelectors.getFluorsInRun(state.currentCfxRun);

export const getTargetsOrFluorsInRun = (state: State) =>
  currentCfxRunSelectors.getTargetsOrFluorsInRun(state.currentCfxRun);

export const getTargetsToShow = (state: State) =>
  currentCfxRunSelectors.getTargetsToShow(state.currentCfxRun);

export const getFluorsToShow = (state: State) =>
  currentCfxRunSelectors.getFluorsToShow(state.currentCfxRun);

export const getTargetsOrFluorsToShow = (state: State) =>
  currentCfxRunSelectors.getTargetsOrFluorsToShow(state.currentCfxRun);

export const getResultsStep = (state: State): AnalysisResultsStep | null | undefined =>
  currentCfxRunSelectors.getResultsStep(state.currentCfxRun);

export const shouldUpdateStep = (state: State) =>
  currentCfxRunSelectors.shouldUpdateStep(state.currentCfxRun);

export const stepCalculationErrored = (state: State) =>
  currentCfxRunSelectors.stepCalculationErrored(state.currentCfxRun);

export const hasStepGroupData = (state: State) =>
  currentCfxRunSelectors.hasStepGroupData(state.currentCfxRun);

export const hasDataSteps = (state: State) =>
  currentCfxRunSelectors.hasDataSteps(state.currentCfxRun);

export const runHasLocalEdits = (state: State) =>
  currentCfxRunSelectors.runHasLocalEdits(state.currentCfxRun);

export const isPlateValid = (state: State) =>
  currentCfxRunSelectors.isPlateValid(state.currentCfxRun);

export const isRunDetailsValid = (state: State) =>
  currentCfxRunSelectors.isRunDetailsValid(state.currentCfxRun);

export const isProtocolValid = (state: State) =>
  currentCfxRunSelectors.isProtocolValid(state.currentCfxRun);

export const isCompletedRun = (state: State) =>
  currentCfxRunSelectors.isCompletedRun(state.currentCfxRun);

export const isInProgressRun = (state: State) =>
  currentCfxRunSelectors.isInProgressRun(state.currentCfxRun);

export const isPendingRun = (state: State) =>
  currentCfxRunSelectors.isPendingRun(state.currentCfxRun);

export const isCreatePendingRun = (state: State) =>
  currentCfxRunSelectors.isCreatePendingRun(state.currentCfxRun);

export const getTargets = (state: State) => currentCfxRunSelectors.getTargets(state.currentCfxRun);

export const isRunValid = (state: State) => currentCfxRunSelectors.isRunValid(state.currentCfxRun);

export const hasValidLocalEdits = (state: State) =>
  currentCfxRunSelectors.hasValidLocalEdits(state.currentCfxRun);

export const getWellReplicateNumberFormatter = (state: State) =>
  currentCfxRunSelectors.getWellReplicateNumberFormatter(state.currentCfxRun);

export const getCqDataSortOrder = (state: State) =>
  currentCfxRunSelectors.getCqDataSortOrder(state.currentCfxRun);

export const getCqData = (state: State) => currentCfxRunSelectors.getCqData(state.currentCfxRun);

export const getMeltData = (state: State) =>
  currentCfxRunSelectors.getMeltData(state.currentCfxRun);

export const getShowPlateSetup = (state: State) =>
  currentCfxRunSelectors.getShowPlateSetup(state.currentCfxRun);

export const getShouldRedirect = (state: State) =>
  currentCfxRunSelectors.getShouldRedirect(state.currentCfxRun);

export const getRun = (state: State) => currentCfxRunSelectors.getRun(state.currentCfxRun);

export const selectedStepLoading = (state: State) =>
  currentCfxRunSelectors.selectedStepLoading(state.currentCfxRun);

export const selectedStepHasErrors = (state: State) =>
  currentCfxRunSelectors.selectedStepHasErrors(state.currentCfxRun);

export const defaultThresholds = (state: State): Map<string, any> =>
  currentCfxRunSelectors.defaultThresholds(state.currentCfxRun);

export const customThresholds = (state: State): Map<string, any> =>
  currentCfxRunSelectors.customThresholds(state.currentCfxRun);

export const autoThresholdSettings = (state: State): Map<string, any> =>
  currentCfxRunSelectors.autoThresholdSettings(state.currentCfxRun);

export const hasCustomThreshold = (state: State): boolean =>
  currentCfxRunSelectors.hasCustomThreshold(state.currentCfxRun);

export const getCurrentThresholds = (state: State): Map<string, any> =>
  currentCfxRunSelectors.getCurrentThresholds(state.currentCfxRun);

export const getPerStepAnalysisSettings = (state: State) =>
  currentCfxRunSelectors.getPerStepAnalysisSettings(state.currentCfxRun);

export const getNumberOfCyclesInStep = (state: State) =>
  currentCfxRunSelectors.getNumberOfCyclesInStep(state.currentCfxRun);

export const getComputationsPerWell = (state: State) =>
  currentCfxRunSelectors.getComputationsPerWell(state.currentCfxRun);

export const getAnalysisSettings = (state: State) =>
  currentCfxRunSelectors.getAnalysisSettings(state.currentCfxRun);

export const getPerStepAnalysisSettingsForStepGroupMode = (state: State) =>
  currentCfxRunSelectors.getPerStepAnalysisSettingsForStepGroupMode(state.currentCfxRun);

export const meltStepSelected = (state: State): boolean =>
  currentCfxRunSelectors.meltStepSelected(state.currentCfxRun);

export const ampStepSelected = (state: State): boolean =>
  currentCfxRunSelectors.ampStepSelected(state.currentCfxRun);

export const getMeltSteps = (state: State): List<number> =>
  currentCfxRunSelectors.getMeltSteps(state.currentCfxRun);

export const getIsRunFromTemplate = (state: State): boolean =>
  currentCfxRunSelectors.isRunFromTemplate(state.currentCfxRun);

export const getSamplePlaceholders = (state: State): List<Map<string, any>> =>
  currentCfxRunSelectors.getSamplePlaceholders(state.currentCfxRun);
