/* eslint-disable react/no-deprecated */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Map } from 'immutable';

// @ts-ignore
import { FormProps } from 'redux-form/lib/types.js.flow'; // https://github.com/erikras/redux-form/issues/3630

import './styles.scss';
import { forgotPwd as forgotPwdAction, ForgotInfo } from '../../../actions/auth_actions';
import { RenderField } from '../../../../frontend-common-libs/src/common/input-fields';
import {
  textRequired,
  emailValidForAuth
} from '../../../../frontend-common-libs/src/common/form_validators';

import { ReduxState } from '../../../../types';
import routes from '../../../../core/routes';
import { PrimaryButton } from '../../../../frontend-common-libs/src/components/common/buttons';
import commonStyles from '../../../../frontend-common-libs/src/scss/auth.module.scss';
import HelpCenter from '../../../../utils/HelpCenter';

const FORM_ERROR_CLASS = 'form-field-invalid form-field-invalid:focus';
const FORM_NO_ERROR_CLASS = '';

export type Props = {
  getConfirmation: boolean;
  confirmEmail: string;
  errorType: Map<string, string>;
  forgotPwd: typeof forgotPwdAction;
} & FormProps;

export type State = {
  emailError: string;
  emailClass: string;
};

export class ForgotPasswordImpl extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      emailError: '',
      emailClass: ''
    };
  }

  componentWillReceiveProps(nextProps: Props) {
    const { errorType } = this.props;
    if (nextProps.errorType === errorType) {
      return;
    }
    let inError = '';
    if (nextProps.errorType && nextProps.errorType.hasIn(['forgot', 'email'])) {
      inError = nextProps.errorType.getIn(['forgot', 'email', 'msg']);
    }
    this.setEmailError(inError);
  }

  setEmailError = (error: string) => {
    this.setState({ emailError: error });
  };

  resetEmailError = () => {
    this.setState({ emailError: '' });
  };

  emailErrorHighlight = () => {
    this.setState({ emailClass: FORM_ERROR_CLASS });
  };

  emailErrorNoHighlight = () => {
    this.setState({ emailClass: FORM_NO_ERROR_CLASS });
  };

  emailRequired = (value: string) => {
    const { dirty, touch } = this.props;
    this.resetEmailError();
    // Fixes an initial condition where typing a single character
    // does not mark the field as touched and so doesn't show the
    // error.
    if (!dirty && value) {
      touch('forgotPassword', 'email');
    }

    const validate = textRequired(value);
    // Don't show an error until they've started typing
    if (validate && dirty) {
      this.emailErrorHighlight();
    } else {
      this.emailErrorNoHighlight();
    }

    return validate;
  };

  emailValid = (value: string) => {
    this.resetEmailError();

    const validate = emailValidForAuth(value);
    if (validate) {
      this.emailErrorHighlight();
    } else {
      this.emailErrorNoHighlight();
    }

    return validate;
  };

  handleFormSubmit = (forgotInfo: ForgotInfo) => {
    const { forgotPwd } = this.props;
    forgotPwd(forgotInfo);
  };

  forgotPwdHelpURL = () => {
    const helpCenter = new HelpCenter();
    return helpCenter.getHelpUrl(routes.FORGOT_PASSWORD);
  };

  render() {
    const { getConfirmation, confirmEmail, handleSubmit, submitting, invalid } = this.props;
    const { emailError, emailClass } = this.state;
    const forgotPwdURL = this.forgotPwdHelpURL();
    if (getConfirmation) {
      return (
        <div className="flex-column-container">
          <p className="large-title-txt" id="change-password-message">
            {`Thanks! Please check (${confirmEmail}) for a link to reset your password.`}
          </p>
        </div>
      );
    }

    return (
      <div className="forgot-password">
        <div className="form-container">
          <form onSubmit={handleSubmit(this.handleFormSubmit)}>
            <Field
              label="EMAIL"
              name="email"
              type="email"
              extraError={emailError}
              additionalClass={`${emailClass} ${commonStyles.inputField}`}
              component={RenderField}
              validate={[this.emailRequired, this.emailValid]}
            />
            <PrimaryButton id="forgot-pwd-btn" type="submit" disabled={submitting || invalid}>
              Reset Password
            </PrimaryButton>
          </form>
          <div id="need-help-box" className="flex-wrapper">
            <a href={forgotPwdURL} target="_blank" id="forgot-pwd-help-btn" rel="noreferrer">
              Need help?
            </a>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: ReduxState, ownProps: any) {
  return {
    getConfirmation: state.auth.get('getConfirmation'),
    confirmEmail: state.auth.get('content'),
    errorType: state.auth.get('error'),
    initialValues: {
      email: ownProps.location.query ? ownProps.location.query : ''
    }
  };
}

export default connect(mapStateToProps, { forgotPwd: forgotPwdAction })(
  reduxForm({
    form: 'forgotPassword'
    // @ts-ignore
  })(ForgotPasswordImpl)
);
