import React, { Component } from 'react';

export type Props = {
  hasNewerVersion?: boolean;
  isRunSaving?: boolean;
  tooltip: string;
  iconStyle: string;
};

// eslint-disable-next-line react/prefer-stateless-function
export default class NewVersionIcon extends Component<Props> {
  render() {
    // @ts-ignore
    const { hasNewerVersion, tooltip, iconStyle, isRunSaving } = this.props;
    if (!hasNewerVersion || isRunSaving) return null;
    return (
      <img
        id="run-has-newer-version"
        src="../../../img/icons-user-conflict.svg"
        alt="newer version"
        title={tooltip}
        className={iconStyle}
      />
    );
  }
}
