import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import styles from './styles/instrument-fleet-modal.module.scss';
import CheckmarkGreenCircle from '../../../../../img/checkmark-green-circle.svg';

export interface InstrumentFleetModalProps {
  readonly onClose: () => void;
  readonly show: boolean;
  readonly numberOfReservedInstruments: number;
}

export default function InstrumentFleetModal(props: InstrumentFleetModalProps) {
  const { onClose, show, numberOfReservedInstruments } = props;

  const reservationModalBody = () => {
    const reservationWordContext = `reservation${numberOfReservedInstruments > 1 ? 's' : ''}`;
    const reservationCheckMark = (
      <img src={CheckmarkGreenCircle} alt="Green Circle CheckMark" width="47px" />
    );
    return (
      <div>
        <div>{reservationCheckMark}</div>
        <div className={styles.fleetReservationModalBodyText} id="confirmation-modal-body-text">
          {numberOfReservedInstruments} {reservationWordContext} requested.
        </div>
      </div>
    );
  };

  return (
    <Modal backdrop="static" className={styles.fleetReservationModal} onHide={onClose} show={show}>
      <Modal.Body className={styles.fleetReservationModalBody}>{reservationModalBody()}</Modal.Body>
      <Modal.Footer className={styles.fleetReservationModalFooter}>
        <Button onClick={onClose} aria-label="close" id="confirmation-modal-close-button">
          OK
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
