import getEnvironmentConfig from './frontend-common-libs/src/config/EnvironmentConfiguration';

type OauthResponseType = 'code' | 'token';

type AmplifyConfig = {
  Auth: {
    region: string;
    userPoolId: string;
    userPoolWebClientId: string;
    oauth: {
      domain: string;
      scope: Array<string>;
      redirectSignIn: string;
      redirectSignOut: string;
      responseType: OauthResponseType;
      options: {
        AdvancedSecurityDataCollectionFlag: true;
      };
    };
  };
};

class Amplify {
  static getConfig(): AmplifyConfig {
    const envConfig = getEnvironmentConfig();
    const { COGNITO_CONFIG } = envConfig.config;
    const cognitoDomain = `auth.${envConfig.apiDomain}`;
    const appUrl = `https://${envConfig.apiDomain}/app`;
    return {
      Auth: {
        region: COGNITO_CONFIG.REGION,
        userPoolId: COGNITO_CONFIG.USERPOOL_ID,
        userPoolWebClientId: COGNITO_CONFIG.APPCLIENT_ID,
        oauth: {
          domain: cognitoDomain,
          scope: ['openid', 'email', 'profile', 'aws.cognito.signin.user.admin'],
          redirectSignIn: `${appUrl}/home`,
          redirectSignOut: `${appUrl}/login`,
          responseType: 'code',
          options: {
            AdvancedSecurityDataCollectionFlag: true
          }
        }
      }
    };
  }
}

const amplifyConfig = Amplify.getConfig();
export default amplifyConfig;
