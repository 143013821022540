// @ts-nocheck
import React, { PureComponent } from 'react';
import { Set } from 'immutable';
import { Wrapper, Button, Menu, MenuItem } from 'react-aria-menubutton';
import { FormControl } from 'react-bootstrap';
import SelectionBox from './SelectionBox';

type Props = {
  /** all the items that need to be displayed */
  items: Set;

  /** the selected items */
  selectedItems: Set;

  /** handler for when the selectedItems changed, calles with the full set of selectedItems */
  onSelectionChange: (arg0: Set) => any;
  id: string;
  children: any;
  renderMenu: (...args: Array<any>) => any;
  title: string;
  disabled: boolean;
};

type State = {
  filterText: string;
  filteredItems: Set;
  items: Set;
};

/**
 * Component renders a multiselect dropdown with a filter input and a select all button
 * The filtering is case insensitive and does not ignore leading or trailing white spaces
 * Clicking the selectAll button toggles the currently displayed filtered items and calls the onSelectionChange
 * Rendering the child menuitems is the responsibility of the user component
 * If a checkbox is desired in the child menuitem, use the SelectionBox component
 */
class MultiSelect extends PureComponent<Props, State> {
  static initState(items: Set) {
    return {
      filterText: '',
      filteredItems: items,
      items
    };
  }

  static getDerivedStateFromProps(nextProps: Props, prevState: State) {
    if (prevState.items !== nextProps.items) {
      return MultiSelect.initState(nextProps.items);
    }
    return null;
  }

  static allSelected = (filteredItems: Set, selectedItems: Set): boolean =>
    filteredItems.every(item => selectedItems.has(item));

  navItemRefs: HTMLElement[] = [];

  constructor(props: Props) {
    super(props);
    const { items } = props;
    this.state = MultiSelect.initState(items);
  }

  handleMenuToggle = ({ isOpen }: { isOpen: boolean }) => {
    if (!isOpen) this.navItemRefs = [];
  };

  addNavItemRef = (ref: React$ElementRef<any> | null) => {
    if (ref) {
      const { node } = ref;
      if (node) {
        this.navItemRefs.push(node);
      } else {
        this.navItemRefs.push(ref);
      }
    }
  };

  handleKeyDown = (event: React.KeyboardEvent<>) => {
    const { key, target: activeNavItem } = event;
    const isArrowDown = key === 'ArrowDown';
    if (isArrowDown || key === 'ArrowUp') {
      const navItems = this.navItemRefs;
      const activeIndex = navItems.indexOf(activeNavItem);
      let focusItemIndex;
      if (isArrowDown) {
        focusItemIndex = activeIndex === navItems.length - 1 ? 0 : activeIndex + 1;
      } else {
        focusItemIndex = activeIndex === 0 ? navItems.length - 1 : activeIndex - 1;
      }
      navItems[focusItemIndex].focus();
      event.preventDefault();
    }
    // Enter, Space, Escape, and Tab keydown events handled by react-aria-menubutton menuItem and menu
  };

  handleFilterChange = ({
    target: { value: filterText }
  }: React.SyntheticEvent<HTMLInputElement>) => {
    const { items } = this.props;
    const filteredItems =
      (!filterText && items) ||
      items.filter(item => item.toLowerCase().includes(filterText.toLowerCase()));
    this.setState({
      filterText,
      filteredItems
    });
  };

  handleSelectAll = () => {
    const { selectedItems, onSelectionChange } = this.props;
    const { filteredItems } = this.state;
    let newSelectedItems;
    if (MultiSelect.allSelected(filteredItems, selectedItems)) {
      newSelectedItems = selectedItems.subtract(filteredItems);
    } else {
      newSelectedItems = selectedItems.union(filteredItems);
    }
    onSelectionChange(newSelectedItems);
  };

  handleSelectItem = (value: string) => {
    const { selectedItems, onSelectionChange } = this.props;
    const newSelectedItems = selectedItems.has(value)
      ? selectedItems.delete(value)
      : selectedItems.add(value);

    onSelectionChange(newSelectedItems);
  };

  render() {
    const { filterText, filteredItems } = this.state;
    const { id, selectedItems, children, renderMenu, title, disabled } = this.props;
    const menuId = `${id}-menu`;
    const allSelected = MultiSelect.allSelected(filteredItems, selectedItems);
    return (
      <Wrapper
        id={id}
        closeOnSelection={false}
        className="dropdown-wrapper"
        onMenuToggle={this.handleMenuToggle}
      >
        <Button id={`${id}-button`} title={title} className="new-btn-icon" disabled={disabled}>
          {children}
        </Button>
        <Menu id={menuId} className="custom-menu">
          <div onKeyDown={this.handleKeyDown} role="presentation">
            <FormControl
              autoFocus
              inputRef={this.addNavItemRef}
              type="text"
              placeholder="Find..."
              onChange={this.handleFilterChange}
              value={filterText}
              id={`${menuId}-input`}
              tabIndex={-1}
            />
            <div className="multi-select-filter">
              <div className="menu-item-divider" />
              <Wrapper onSelection={this.handleSelectAll}>
                <MenuItem
                  tabIndex={-1}
                  id={`${menuId}-select-all`}
                  disabled={filteredItems.size === 0}
                  ref={this.addNavItemRef}
                >
                  <div className="menu-item-text">
                    <SelectionBox selected={allSelected} />
                    Select All
                  </div>
                  <span className="total-item-count">{`(${filteredItems.size})`}</span>
                </MenuItem>
              </Wrapper>
              <div className="menu-item-divider" />
              <Wrapper className="menu-items-container" onSelection={this.handleSelectItem}>
                {renderMenu(filteredItems).map(menuItem =>
                  React.cloneElement(menuItem, { ref: this.addNavItemRef })
                )}
              </Wrapper>
            </div>
          </div>
        </Menu>
      </Wrapper>
    );
  }
}

export default MultiSelect;
