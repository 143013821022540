import React from 'react';
import ModalDialog from '../../common/dialogs/ModalDialog';
import { modalStyleV2 } from '../../common/dialogs/custom-modal-styles';
import Loader from '../../common/Loader';
import FileHistoryVm, { FileVersion } from './FileHistoryVm';
import './styles/file-history.scss';
import { formatRunLogTime } from '../../../common/display-formats';
import { ConnectViewModel } from '../../../common/mvvm/ConnectViewModel';

export type Props = {
  fileHistoryVm: FileHistoryVm;
};

export class FileHistoryImpl extends React.Component<Props> {
  static renderTd(content: any) {
    return <td title={content}>{content}</td>;
  }

  renderFileVersions() {
    const { fileHistoryVm } = this.props;
    const { fileVersions } = fileHistoryVm;
    return (
      <div className="br-data-table versions-table">
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th>updated</th>
              <th>name</th>
              <th>type</th>
              <th>status</th>
              <th>meta</th>
              <th>modified by</th>
              <th>project id</th>
            </tr>
          </thead>
          <tbody>
            {fileVersions?.map((version: FileVersion) => (
              <tr id="file-version-row" key={version.versionNumber}>
                {FileHistoryImpl.renderTd(version.versionNumber)}
                {FileHistoryImpl.renderTd(formatRunLogTime(version.updated as string))}
                {FileHistoryImpl.renderTd(version.name)}
                {FileHistoryImpl.renderTd(version.type)}
                {FileHistoryImpl.renderTd(version.status)}
                {FileHistoryImpl.renderTd(JSON.stringify(version.meta))}
                {FileHistoryImpl.renderTd(version.modified_by)}
                {FileHistoryImpl.renderTd(version.parent_id)}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }

  renderModalContent() {
    const { fileHistoryVm } = this.props;
    const { loading, file } = fileHistoryVm;
    if (loading) return <Loader />;

    return (
      <div className="file-history-panel">
        <span className="with-ellipsis file-name" title={file.name}>
          {file.name}
        </span>
        {this.renderFileVersions()}
      </div>
    );
  }

  render() {
    const { fileHistoryVm } = this.props;
    const { shouldBlock, onCancel } = fileHistoryVm;
    if (!shouldBlock) return null;
    const customModalStyle = { ...modalStyleV2, modalStyle: 'file-history' };
    return (
      <ModalDialog
        show={shouldBlock}
        title="File History"
        onSuccess={onCancel}
        onCancel={onCancel}
        customModalStyle={customModalStyle}
      >
        {this.renderModalContent()}
      </ModalDialog>
    );
  }
}

export function FileHistory(props: Readonly<Props>) {
  const { fileHistoryVm } = props;
  return (
    <ConnectViewModel vm={fileHistoryVm} vmPropKey="fileHistoryVm">
      <FileHistoryImpl {...props} />
    </ConnectViewModel>
  );
}
