import './styles/gradient-calculator-container.scss';
import React, { ReactElement } from 'react';

export type Props = {
  children: Array<ReactElement>;
};

export default function GradientCalculatorContainer(props: Props): ReactElement {
  const { children } = props;

  return (
    <div className="gradient-calculator-container">
      <span id="gradient-calculator-header">GRADIENT</span>
      {children}
    </div>
  );
}
