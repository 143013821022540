import conventionalPcrRoutes, { getRoute, getRouteName } from '../routes';

export default class HelpCenter {
  private readonly baseDomain: string;

  private readonly helpPaths = {
    PTC_Protocol_List: '/Output/PTC_Tempo/Content/CloudUA/Protocols.htm',
    PTC_Protocol_Creator: '/Output/PTC_Tempo/Content/CloudUA/PCR/Protocols/Protocols_Create.htm',
    PTC_Protocol_Editor: '/Output/PTC_Tempo/Content/CloudUA/PCR/Protocols/Protocols_Create.htm',
    PTC_Completed_Run_Details:
      '/Output/PTC_Tempo/Content/CloudUA/PCR/Experiments/CompletedRunDetails.htm',
    PTC_View_Run_Protocol: '/Output/PTC_Tempo/Content/CloudUA/Protocols.htm',
    default: '/Output/CloudUA_Next/Content/CloudUA/Welcome.htm',
    Managing_PTCTempo_Instruments:
      '/Output/PTC_Tempo/Content/CloudUA/PCR/Instruments/Managing_PTCTempo_Instruments.htm'
  };

  private readonly routesToHelpPaths = {
    [conventionalPcrRoutes.PCR_PROTOCOL_LIST]: this.helpPaths.PTC_Protocol_List,
    [conventionalPcrRoutes.CREATE_PCR_PROTOCOL]: this.helpPaths.PTC_Protocol_Creator,
    [conventionalPcrRoutes.EDIT_PCR_PROTOCOL]: this.helpPaths.PTC_Protocol_Editor,
    [conventionalPcrRoutes.VIEW_PCR_RUN_DETAILS]: this.helpPaths.PTC_Completed_Run_Details,
    [conventionalPcrRoutes.VIEW_PCR_RUN_PROTOCOL]: this.helpPaths.PTC_View_Run_Protocol
  };

  constructor(baseDomain: string) {
    this.baseDomain = baseDomain;
  }

  public getHelpUrl(path: string): string | undefined {
    let helpPath = this.routesToHelpPaths[path];

    if (!helpPath) {
      const routeName = getRouteName(path);
      const route = getRoute(routeName);
      helpPath = this.routesToHelpPaths[route];
    }

    return helpPath ? `${this.baseDomain}${helpPath}` : undefined;
  }
}
