import React from 'react';
import BlurbBox from './BlurbBox';

export default function () {
  const connectTitle = 'Cloud Connectivity';
  const connectText =
    'Experiments and data are transferred seamlessly from the instrument to the cloud via your internet connection. Using BR.io, you can open a browser and check the instrument status at any time, and the results are uploaded automatically upon completion. Connect your instrument directly to the cloud without the need for a desktop software.';

  const experimentTitle = 'Experiment in the Cloud';
  const experimentText =
    'The BR.io cloud platform is accessible from any web browser, providing you with the ability to set up and analyze experiments from your own computer. No proprietary software is needed.';

  const safeguardTitle = 'Safeguard Your Data';
  const safeguardText =
    'You can access your data after you sign in, adding protection against unauthorized changes or data loss, and transfer to data centers in a secured environment.';

  return (
    <div className="blurb-box-container">
      <BlurbBox id="connect-box" title={connectTitle}>
        {connectText}
      </BlurbBox>
      <BlurbBox id="experiment-box" title={experimentTitle}>
        {experimentText}
      </BlurbBox>
      <BlurbBox id="safeguard-box" title={safeguardTitle}>
        {safeguardText}
      </BlurbBox>
    </div>
  );
}
