import React from 'react';
import {
  InstrumentId,
  InstrumentName,
  InstrumentType
} from '../../frontend-common-libs/src/instruments/types';
import InstrumentTile from '../../frontend-common-libs/src/instruments/components/recent-instruments/instrument-tile/InstrumentTile';
import { getInstrumentIconByStatus, getModelName } from '../utils/instrumentUtils';

export type Props = {
  deviceId: InstrumentId;
  deviceType: InstrumentType;
  name: InstrumentName;
  status: string;
  model: string;
  instrument: any;
  timeoutHandler?: Function;
};

export default function RealTimePCRInstrumentTile(props: Props) {
  const { deviceId, name, status, deviceType, model } = props;
  const modelName = getModelName(deviceType, model);
  const icon = { src: getInstrumentIconByStatus(status), alt: `${deviceType} ${status}` };
  return status ? (
    <InstrumentTile id={deviceId} name={name} status={status} modelName={modelName} icon={icon} />
  ) : null;
}
