import { gmtStringToDateTimeSecFormat } from '../../../../../../frontend-common-libs/src/common/display-formats';

export default class RunDetailsParser {
  report: any;

  public readonly runDetails: any;

  constructor(runReport: any) {
    this.report = runReport;
    this.runDetails = this.getRunDetails();
  }

  private getRunDetails() {
    const rows = [];
    const { runDetails } = this.report;
    // eslint-disable-next-line no-restricted-syntax
    for (const protocolRunDetail of runDetails) {
      const rowDetail = [
        protocolRunDetail.repeat,
        protocolRunDetail.stepNumber,
        protocolRunDetail.stepSettings || '--',
        protocolRunDetail.duration || '--',
        gmtStringToDateTimeSecFormat(protocolRunDetail.dateTime)
      ];
      if (
        protocolRunDetail.additionalDetails !== undefined &&
        protocolRunDetail.additionalDetails !== ''
      ) {
        rowDetail[4] += ` ${protocolRunDetail.additionalDetails}`;
      }
      if (protocolRunDetail.type !== undefined && protocolRunDetail.type === 'GOTO') {
        rowDetail[2] = `${protocolRunDetail.type} step ${protocolRunDetail.step}`;
      }
      rows.push(rowDetail);
    }
    return rows;
  }
}
