import React from 'react';
import './styles/details-header-value.scss';
import classNames from 'classnames';
import { toHtmlId } from '../../../common/strings';

export type Props = {
  header: string | null | undefined;
  value: string | string[] | null | undefined;
  headerStyle?: string;
  valueIcon?: any;
  valueStyle?: Record<string, any>;
  className?: string;
};

function renderIcon(index: number, icon: any) {
  if (index === 0) return icon;
  return null;
}

function renderValue(props: Props) {
  const { value, valueIcon } = props;
  if (Array.isArray(value))
    return (
      <div className="status-direction">
        {value.map((txt: string, index: number) => (
          <span key={index} id={`status-line-${index}`}>
            {renderIcon(index, valueIcon)}
            {txt}
          </span>
        ))}
      </div>
    );

  return (
    <>
      {valueIcon}
      {value}
    </>
  );
}

export default function DetailsHeaderValue(props: Props) {
  const { header, value, headerStyle, valueStyle, className } = props;
  if (header == null || header === '' || value == null || value === '') return null;
  const keyId = toHtmlId(header);
  return (
    <div className={`details-header-value ${className}`}>
      {/* @ts-ignore */}
      <span className={classNames({ [headerStyle]: headerStyle }, 'header')} id={`label-${keyId}`}>
        {header}
      </span>
      <span style={valueStyle} className="value" id={`value-${keyId}`}>
        {renderValue(props)}
      </span>
    </div>
  );
}
