import React, { PureComponent } from 'react';
import PlateSize from '../PlateSize';
import { GradientLowerTemperature, GradientRange } from '../Gradient';
import GradientFactory from './gradient-factory/GradientFactory';
import GradientCalculatorOptions from '../gradient-calculator-options';
import GradientTemperature from '../gradient-temperature/GradientTemperature';
import GradientCalculatorTable from '../gradient-calculator-table';
import GradientCalculatorContainer from '../gradient-calculator-container';

export type Props = {
  lowerTemperature: GradientLowerTemperature;
  gradientRange: GradientRange;
  isValid: boolean;
};
export type State = {
  plateSize: PlateSize;
};

export type PlateSizeValue = '96' | '96D' | '384';

const plateSizeValueLookup: { [key in PlateSize]: PlateSizeValue } = {
  [PlateSize.PlateSize96]: '96',
  [PlateSize.PlateSize96D]: '96D',
  [PlateSize.PlateSize384]: '384'
};

const plateSizeEnumLookup = {
  '96': PlateSize.PlateSize96,
  '96D': PlateSize.PlateSize96D,
  '384': PlateSize.PlateSize384
};

function plateSizeToValue(plateSize: PlateSize): PlateSizeValue {
  return plateSizeValueLookup[plateSize];
}

const plateSizeLabelLookup: { [key in PlateSize]: PlateSizeValue } = {
  [PlateSize.PlateSize96]: '96',
  [PlateSize.PlateSize96D]: '96D',
  [PlateSize.PlateSize384]: '384'
};

export class GradientCalculatorImpl extends PureComponent<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = { plateSize: PlateSize.PlateSize96 };
  }

  private handlePlateSizeChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
    const plateSizeValue = e.currentTarget.value as unknown as PlateSizeValue;
    this.setState({
      plateSize: plateSizeEnumLookup[plateSizeValue]
    });
  };

  private createOption = (plateSize: PlateSize) => {
    const value = plateSizeToValue(plateSize);
    const label = plateSizeLabelLookup[plateSize];
    return {
      label,
      value,
      disabled: false
    };
  };

  private getRadioButtonOptions = () => {
    const plateSizeOptions = [
      PlateSize.PlateSize96,
      PlateSize.PlateSize96D,
      PlateSize.PlateSize384
    ];

    return plateSizeOptions.map(plateSize => this.createOption(plateSize));
  };

  private getTemperatures(): GradientTemperature[] {
    const { plateSize } = this.state;
    const { isValid, lowerTemperature, gradientRange } = this.props;
    const gradient = GradientFactory.getGradient(
      plateSize,
      lowerTemperature,
      gradientRange,
      isValid
    );
    return gradient.getTemperatures();
  }

  public render() {
    const { plateSize: currentPlateSize } = this.state;
    const options = this.getRadioButtonOptions();

    return (
      <GradientCalculatorContainer>
        <GradientCalculatorOptions
          onChange={this.handlePlateSizeChange}
          checkedValue={plateSizeToValue(currentPlateSize)}
          options={options}
        />
        <GradientCalculatorTable temperatures={this.getTemperatures()} />
      </GradientCalculatorContainer>
    );
  }
}

export default GradientCalculatorImpl;
