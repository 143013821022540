import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Amplify } from 'aws-amplify';
import ConnectedLoginForm from './LoginForm';
import { loginExistingUser as loginExistingUserAction } from '../../actions/auth_actions';
import amplifyConfig from '../../../Amplify';
import { ReduxState } from '../../../types';
import { hideVerifiedAccountMessageOnLogin as hideVerifiedAccountMessageOnLoginAction } from '../../../actions/complete-registration/complete-registration-actions';
import styles from './styles/login.module.scss';
import authStyles from '../styles/auth.module.scss';

export type Props = {
  completeRegistrationSucceeded: boolean;
  hideVerifiedAccountMessage: boolean;
  loginExistingUser: typeof loginExistingUserAction;
  hideVerifiedAccountMessageOnLogin: typeof hideVerifiedAccountMessageOnLoginAction;
};

export class LoginImpl extends PureComponent<Props> {
  componentDidMount() {
    Amplify.configure(amplifyConfig);
  }

  // eslint-disable-next-line class-methods-use-this
  renderNotificationAccountActivated() {
    return (
      <div
        className={`${authStyles.formContainer} ${styles.loginNotificationAccountActivation}`}
        id="login-notification-account-activation"
      >
        <img src="/img/circle-check.svg" alt="Check" width="67px" />
        <h2 id="verification-successful-title" className={styles.verificationSuccessfulTitle}>
          Verified!
        </h2>
        <p id="verification-successful-content">
          You have successfully verified your account.
          <br />
          Please login to use BR.io
        </p>
      </div>
    );
  }

  componentWillUnmount() {
    const { hideVerifiedAccountMessageOnLogin } = this.props;
    hideVerifiedAccountMessageOnLogin();
  }

  render() {
    const { loginExistingUser, hideVerifiedAccountMessage, completeRegistrationSucceeded } =
      this.props;
    return (
      <div className={styles.loginContainer}>
        {!hideVerifiedAccountMessage &&
          completeRegistrationSucceeded &&
          this.renderNotificationAccountActivated()}
        {/* @ts-ignore */}
        <ConnectedLoginForm submitThunkCreator={loginExistingUser} />
      </div>
    );
  }
}
const mapStateToProps = (state: ReduxState) => {
  return {
    completeRegistrationSucceeded: state.completeRegistration.get('succeeded'),
    hideVerifiedAccountMessage: state.completeRegistration.get('hideVerifiedAccountMessageOnLogin')
  };
};
export default connect(mapStateToProps, {
  loginExistingUser: loginExistingUserAction,
  hideVerifiedAccountMessageOnLogin: hideVerifiedAccountMessageOnLoginAction
})(LoginImpl);
