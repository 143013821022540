import React from 'react';
import { Link } from 'react-router-dom';
import loginArrow from '../../../img/login-arrow.svg';
import routes from '../../core/routes';
import SignUpUserClickEvent from '../../user-analytics/SignUpUserClickEvent';
import LogoHeader from './LogoHeader';

type Props = {
  className?: string;
};

function LogoHeaderWithAuth({ className }: Props) {
  const sendUserAnalyticsEvent = () => {
    new SignUpUserClickEvent().track();
  };
  return (
    <LogoHeader className={className}>
      <nav>
        <Link id="login-btn" to={routes.LOGIN} className="new-btn primary link">
          Login
          <img src={loginArrow} alt="login arrow icon" />
        </Link>
        <Link
          id="signup-btn"
          to={routes.REGISTER}
          onClick={sendUserAnalyticsEvent}
          className="new-btn primary"
        >
          Sign up
        </Link>
      </nav>
    </LogoHeader>
  );
}

LogoHeaderWithAuth.defaultProps = {
  className: undefined
};

export default LogoHeaderWithAuth;
