import { getData, getStatus } from '../../frontend-common-libs/src/utils/errorUtils';

export function isOrgCreateLimitError(error: any): boolean {
  return !!(
    getStatus(error) === 403 &&
    getData(error)?.includes('User has reached the maximum number of organizations allowed')
  );
}

export function isProjectCreateLimitError(error: any): boolean {
  return !!(
    getStatus(error) === 403 &&
    getData(error)?.includes('User has reached the maximum number of projects allowed')
  );
}

export function isAddUserNotInEmailDomainError(error: any): boolean {
  return (
    getStatus(error) === 403 &&
    getData(error) === 'Users in the organization must have the same email domain'
  );
}

export function isAddUserMaxOrgUsersError(error: any): boolean {
  return (
    getStatus(error) === 403 &&
    getData(error) === 'Organization has reached the maximum number of users'
  );
}

export function isAddUserMaxProjectUsersError(error: any): boolean {
  return (
    getStatus(error) === 403 && getData(error) === 'Project has reached the maximum number of users'
  );
}

export function isUserAlreadyInProjectError(error: any): boolean {
  return !!getData(error)?.includes('is already a member of the project');
}

export function isUserAlreadyInOrganizationError(error: any): boolean {
  return !!getData(error)?.includes('is already a member of the organization');
}

export function isAtLeastOneAdminError(error: any): boolean {
  return !!getData(error)?.includes('There must be at least one admin in the tenant');
}

export function isAddedUserNotAMemberOfTheOrganization(error: any): boolean {
  return getStatus(error) === 404 && getData(error) === 'Added user not found in the organization';
}

export function isTokenExpiredError(error: any): boolean {
  return !!(getStatus(error) === 401 && getData(error)?.includes('Token expired'));
}

export function isRemoveLastAdminError(error: any): boolean {
  return (
    getStatus(error) === 400 &&
    getData(error) === 'Can not remove the only admin in the organization'
  );
}

export function isUserMustLoginError(error: any): boolean {
  return getStatus(error) === 400 && getData(error) === 'The user must login to brio';
}

export function isLastProjectAdminError(error: any): boolean {
  return !!(
    getStatus(error) === 400 && getData(error)?.includes('Can not remove the only admin in project')
  );
}
