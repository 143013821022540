import classnames from 'classnames';
import React from 'react';
import { Button, ButtonProps } from '../../components/common/buttons/Button';

function PrimaryButton(props: ButtonProps) {
  const { className } = props;
  return <Button {...props} className={classnames('primary', className)} />;
}

const defaultProps = {
  disabled: false,
  isInProgress: false,
  onClick: undefined,
  onKeyDown: undefined,
  className: undefined,
  buttonRef: undefined,
  isSelected: false,
  tabIndex: 0
};

PrimaryButton.defaultProps = defaultProps;

export default PrimaryButton;
