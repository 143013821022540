import React from 'react';
import { flexRows, Colors } from './styles';
import { toHtmlId } from '../../../../../../frontend-common-libs/src/common/strings';

export type Props = { step: string; lines: string[] };

export default function ProtocolStepRow(props: Props) {
  const { step, lines } = props;
  const keyId = toHtmlId(step.replace(':', ''));
  return (
    <div style={{ display: 'flex', marginLeft: '24px', marginTop: '8px', fontSize: '16px' }}>
      <span id={keyId} style={{ color: Colors.headerText, marginRight: '7px', width: '65px' }}>
        {step}
      </span>
      <div style={flexRows}>
        {lines.map((line, index) => (
          <span id={`${keyId}-data-${index}`} style={{ color: Colors.dataText }} key={index}>
            {line}
          </span>
        ))}
      </div>
    </div>
  );
}
