import React from 'react';

type Props = {
  height: number;
  xPos: number;
  yPos: number;
};

const END_BAR_WIDTH = 25;

function EndBar(props: Props) {
  const { height, xPos, yPos } = props;

  return (
    <g transform={`translate(${xPos} ${yPos})`}>
      <rect className="end-bar" height={height + 1} width={END_BAR_WIDTH} x="0" y="-0.5" />
      <text className="end-bar-text" x={END_BAR_WIDTH / 2} y={height / 2}>
        end
      </text>
    </g>
  );
}

export default EndBar;
