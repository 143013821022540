import { fromJS } from 'immutable';
import {
  PCR_PROTOCOL_CLOSED,
  PCR_PROTOCOL_IS_SAVING,
  PcrProtocolActionType
} from './pcr_protocol_types';
import {
  PCR_PROTOCOL_ERROR,
  PCR_PROTOCOL_LOADED,
  PCR_PROTOCOL_LOADING
} from './pcr_protocol_types';
import { PROTOCOL_TEMPLATES_RENAMED, ProtocolTemplatesActionType } from './protocolTemplate_types';
import { PROTOCOL_TEMPLATES_ADD, PROTOCOL_TEMPLATES_DELETED } from './protocolTemplate_types';
import { getProtocol, postProtocol, putProtocol } from '../api/protocols';
import { commonErrorMessage, errorCode } from '../frontend-common-libs/src/utils/errorUtils';
import QPcrProtocol from '../frontend-common-libs/src/components/pcr/pcr-protocols/models/QPcrProtocol';
import { Dispatch, GetState, ThunkAction } from '../types';
import {
  ProjectId,
  DEFAULT_PROJECT_ID
} from '../frontend-common-libs/src/common/project-management-types';
import { getSelectedProjectId } from '../project-management';
import ArchivedEntityException from '../frontend-common-libs/src/components/pcr/pcr-protocols/models/ArchivedEntityException';
import { getProtocolTemplate } from './protocolTemplate_actions';
import { withRefreshProjectToken } from '../project-management/actions/with-refresh-project-token';
import { CbWithToken } from '../organization-management/actions/WithRefreshToken';

function protocolIsSaving(
  dispatch: Dispatch<PcrProtocolActionType, any>,
  entityId: string,
  isSaving: boolean
) {
  dispatch({
    type: PCR_PROTOCOL_IS_SAVING,
    payload: {
      faId: entityId,
      isSaving
    }
  });
}

export function createProtocol(
  fileName: string,
  protocol: QPcrProtocol,
  projectId: ProjectId = DEFAULT_PROJECT_ID
): ThunkAction<PcrProtocolActionType | ProtocolTemplatesActionType, Record<string, any>> {
  return async (
    dispatch: Dispatch<PcrProtocolActionType | ProtocolTemplatesActionType, Record<string, any>>,
    getState: GetState
  ) => {
    const createCb: CbWithToken = async (projectAccessToken: string) => {
      return postProtocol(fileName, protocol.toJS(), projectId, projectAccessToken);
    };
    const res = await withRefreshProjectToken(dispatch, getState, projectId, createCb);
    const { faEntity, ...protocolAndId } = res.data;
    dispatch({
      type: PCR_PROTOCOL_LOADED,
      payload: {
        protocol: { ...protocolAndId, versionNumber: faEntity.versionNumber },
        faId: faEntity.id
      }
    });
    dispatch({
      type: PROTOCOL_TEMPLATES_ADD,
      payload: {
        entity: faEntity,
        projectId
      }
    });
  };
}

function getPCRProtocol(
  entityId: string,
  protocolID: string,
  projectId: ProjectId,
  versionNumber: number
) {
  return async (
    dispatch: Dispatch<
      PcrProtocolActionType | ProtocolTemplatesActionType,
      Record<string, any> | string[]
    >
  ) => {
    dispatch({
      type: PCR_PROTOCOL_LOADING,
      payload: {
        faId: entityId
      }
    });
    try {
      const res = await getProtocol(entityId, protocolID);
      dispatch({
        type: PCR_PROTOCOL_LOADED,
        payload: {
          protocol: { ...res.data, versionNumber },
          faId: res.data.fa_id
        }
      });
      return fromJS(res.data);
    } catch (err) {
      dispatch({
        type: PCR_PROTOCOL_ERROR,
        payload: {
          faId: entityId
        }
      });
      const status = errorCode(err);

      if (status === 404) {
        dispatch({
          type: PROTOCOL_TEMPLATES_DELETED,
          payload: { fileList: [entityId], projectId }
        });
        throw Error(commonErrorMessage(404, 'Protocol'));
      }
      if (status === 409) throw new ArchivedEntityException('This protocol is already archived.');
      throw err;
    }
  };
}

export function getPCRProtocolIfNeeded(
  entityId: string,
  protocolID: string,
  versionNumber: number
) {
  return async (
    dispatch: Dispatch<
      PcrProtocolActionType | ProtocolTemplatesActionType,
      Record<string, any> | string[]
    >,
    getState: GetState
  ) => {
    const state = getState();
    const projectId = getSelectedProjectId(state);
    // Only fetch protocol if we don't have any protocol data for this ID
    // and we aren't loading and we haven't errored
    const protocolInfo = state.protocols.get(entityId);
    if (!protocolInfo || (!protocolInfo.get('loading') && !protocolInfo.get('protocol'))) {
      return dispatch(getPCRProtocol(entityId, protocolID, projectId, versionNumber));
    }
    return protocolInfo.get('protocol');
  };
}

export const protocolVersionConflict = 'The protocol was changed by another user';

export function updatePCRProtocol(entityId: string, protocolID: string, newProtocol: QPcrProtocol) {
  return async (
    dispatch: Dispatch<PcrProtocolActionType | ProtocolTemplatesActionType, Record<string, any>>,
    getState: GetState
  ) => {
    try {
      protocolIsSaving(dispatch, entityId, true);
      const state = getState();
      const projectId = getSelectedProjectId(state);
      const putProtocolCb: CbWithToken = async (projectAccessToken: string) => {
        return putProtocol(
          entityId,
          protocolID,
          newProtocol.toJS(),
          newProtocol.name,
          newProtocol.versionNumber,
          projectAccessToken
        );
      };
      const res = await withRefreshProjectToken(dispatch, getState, projectId, putProtocolCb);
      protocolIsSaving(dispatch, entityId, false);
      const faEntity = res.data;
      const protocolWithVersionNumber = newProtocol.protocol.set(
        'versionNumber',
        faEntity.versionNumber
      );
      dispatch({
        type: PCR_PROTOCOL_LOADED,
        payload: {
          protocol: protocolWithVersionNumber,
          faId: entityId
        }
      });
      dispatch({
        type: PROTOCOL_TEMPLATES_RENAMED,
        payload: {
          id: entityId,
          faEntity
        }
      });
    } catch (err) {
      protocolIsSaving(dispatch, entityId, false);
      const status = errorCode(err);
      if (status === 409) {
        throw new Error(protocolVersionConflict);
      }
      if (status === 404) {
        throw Error(commonErrorMessage(404, 'Protocol'));
      }
      throw err;
    }
  };
}

export function dispatchProtocolClosed(entityId: string) {
  return (
    dispatch: Dispatch<
      PcrProtocolActionType,
      {
        [key: string]: any;
      }
    >
  ) => {
    dispatch({
      type: PCR_PROTOCOL_CLOSED,
      payload: {
        id: entityId
      }
    });
  };
}

export async function getQpcrProtocolFromEntity(entityId: string): Promise<QPcrProtocol> {
  const res = await getProtocolTemplate(entityId);
  if (res.status === 'A') {
    throw new ArchivedEntityException('This protocol is already archived.');
  }
  // @ts-ignore
  return QPcrProtocol.fromEntity(fromJS(res));
}
